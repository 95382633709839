import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPreviewUrlKeyAction,
  toggleShowcaseActivationAction,
} from 'modules/showcase/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import Switcher from 'components/inputs/Switch/Switch';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import successToastr from 'libs/toastr/successToastr';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import classes from 'modules/dashboard/showcase/Showcase.module.scss';
import clsx from 'clsx';
import SetupShowcaseHint from './SetupShowcaseHint';

const ShowcaseActivator = () => {
  const dispatch = useDispatch();
  const showcase = useSelector((state) => state.showcase.current);
  const urlKey = useSelector((state) => state.showcase.previewUrlKey);

  const [switchState, setSwitchState] = useState(false);

  useEffect(() => {
    if (showcase) {
      setSwitchState(showcase.active);
      dispatch(setPreviewUrlKeyAction(showcase.urlKey));
    }
  }, [showcase, dispatch]);

  const handleSwitchChange = useCallback(
    async ({ target: { checked } }) => {
      try {
        await dispatch(toggleShowcaseActivationAction(Number(checked)));
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch]
  );

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(showcase?.shareUrl);
    successToastr('Success', 'Link successfully copied');
  }, [showcase]);

  return (
    <div className="background-white px-3 py-2 mb-2 position-relative">
      <div className={classes.step7}>
        <SetupShowcaseHint text="STEP 7: Activate Your Showcase after Adding 5+ Approved Inspiration Images and 5+ Approved Look Boards." />
      </div>
      <Switcher
        onChange={handleSwitchChange}
        name="switch"
        label={`${showcase?.active ? 'Deactivate' : 'Activate'} Showcase`}
        checked={switchState}
        disabled={!showcase}
      />
      {showcase?.active ? (
        <div className="flex-center justify-content-between mt-1">
          <div className="flex-center" onClick={copyToClipboard}>
            <IconButton style={{ backgroundColor: '#87bdc34d' }} size="sm">
              <SpriteIcon name="copy" size="sm" className="primary-color" />
            </IconButton>
            <Button
              className="font-italic font-light p-0 ml-1"
              inline
              color="grey"
              variant="default"
              size="xs"
            >
              Copy URL
            </Button>
          </div>
          <div>
            <Link
              to={`${routesByName.showcase.details(urlKey)}`}
              className="font-italic text-underline p-0 primary-color text-sm"
            >
              Go to Showcase page
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <span className={clsx(classes.label, 'mb-1')}>
            Activation Requires Adding 5+
          </span>
          <span className={classes.label}>
            Approved Inspiration Images and 5+ Approved Look Boards.
          </span>
        </div>
      )}
    </div>
  );
};

export default ShowcaseActivator;
