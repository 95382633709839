import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BasicModal from '../../BasicModal/BasicModal';
import classes from './SelectedStyles.module.scss';
import SpriteIcon from '../../../ui/SpriteIcon';
import Button from '../../../ui/Button/Button';

export const SelectedStyles = ({
  open,
  setOpen,
  selectedOptions,
  handleUnselectOption,
  toggleModal,
  clearSelectedOptions,
}) => {
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <div>
        <BasicModal classes={classes} onClose={onClose} open={open}>
          <div className={clsx('flex-fill d-flex flex-wrap')}>
            <Button
              className={clsx('mb-2', classes.stylesBtn)}
              onClick={toggleModal}
            >
              <div>Styles Selected ({selectedOptions.length})</div>
              <SpriteIcon name="arrow-down" size="sm" className="mx-1" />
            </Button>

            <div className={classes.content}>
              {selectedOptions.length > 0 && (
                <Button
                  inline
                  variant="outlined"
                  size="xs"
                  className={classes.clearBtn}
                  onClick={clearSelectedOptions}
                >
                  Clear All
                </Button>
              )}

              {selectedOptions.map(({ id, name }) => (
                <div className={classes.optionChip} key={id}>
                  <span className="text-sm font-italic mr-2">{name}</span>
                  <SpriteIcon
                    name="cross"
                    size="xs"
                    className="cursor-pointer"
                    data-id={id}
                    onClick={handleUnselectOption}
                  />
                </div>
              ))}
            </div>
          </div>
        </BasicModal>
      </div>
    </>
  );
};

SelectedStyles.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.shape({})),
  handleUnselectOption: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  clearSelectedOptions: PropTypes.func.isRequired,
};

SelectedStyles.defaultProps = {
  selectedOptions: [],
};
