import PropTypes from 'prop-types';
import { mediaShape } from 'modules/media/propTypes';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';

const approvalStatusEnum = ['pending', 'approved', 'rejected'];

const bannerTypeEnum = Object.values(bannerTypeKeys);

const bannerShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(bannerTypeEnum).isRequired,
  color: PropTypes.string,
  media: mediaShape,
});

export const showcaseShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  pageName: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isSubtitleActive: PropTypes.bool.isRequired,
  approval: PropTypes.oneOf(approvalStatusEnum),
  active: PropTypes.bool,
  description: PropTypes.string,
  metaTitle: PropTypes.string,
  facebook: PropTypes.string,
  instagram: PropTypes.string,
  pinterest: PropTypes.string,
  featuredImages: PropTypes.arrayOf(PropTypes.number).isRequired,
  featuredLookBoards: PropTypes.arrayOf(PropTypes.number).isRequired,
  banner: bannerShape,
  styles: PropTypes.arrayOf(PropTypes.number).isRequired,
});
