import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Field, Form } from 'react-final-form';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  accountTypes,
  businessTypeOptions,
  signUpFormFields,
} from 'modules/auth/signUp/constants';
import { userShape } from 'modules/currentUser/propTypes';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import ProfilePhoto from 'components/photoUploaders/ProfilePhoto';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import TextInput from 'components/inputs/TextInput/TextInput';
import ConfirmModal from 'components/modals/ConfirmModal';
import {
  addAffiliateCodeFields,
  changeEmailFormFields,
  changePasswordFormFields,
} from 'modules/account/constants';
import moment from 'moment';
import PasswordInputWrapper from 'components/finalFormWrappers/PasswordInputWrapper';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import changeEmailValidator from 'modules/account/profileAndPreferences/profile/changeEmailValidator';
import changePasswordValidator from 'modules/account/profileAndPreferences/profile/changePasswordValidator';
import changeProfileValidator from 'modules/account/profileAndPreferences/profile/changeProfileValidator';
import ToastrIconContainer from 'libs/toastr/ToastrIconContainer/ToastrIconContainer';
import { localStorageKeys } from 'modules/app/constants';
import AutoCompleteCustomWrapper from 'components/finalFormWrappers/AutoCompleteCustomWrapper';
import { Tooltip } from '@material-ui/core';
import affiliateCodeField from 'assets/img/affiliate-code-sample.png';
import classes from './Profile.module.scss';
import AffiliateCodes from './AffiliateCodes';
import addCodeValidator from './addCodeValidator';
import { getEnumAsSelectOptions } from '../../../app/store/selectors';

const sendEmailDelay = 59;

const ProfileComponent = ({
  isEdit,
  isEditEmail,
  isEditPassword,
  isAddAffiliateCode,
  onEnableEditMode,
  onEnableEditEmailMode,
  onEnableEditPasswordMode,
  onToggleAddAffiliateCodeMode,
  profileValues,
  user,
  avatarUrl,
  countriesOptions,
  languagesOptions,
  onChangeProfile,
  onChangeEmail,
  onChangePassword,
  onCancelEditProfile,
  onCancelEditEmail,
  onSendVerificationLink,
  onCancelEditPassword,
  onSelectNewImage,
  onDeactivateUser,
  confirmChangeEmailOpen,
  changeEmailModalOpen,
  handleChangeMembership,
  affiliateSites,
  addAffiliateCode,
  editAffiliateCode,
  deleteAffiliateCode,
}) => {
  const [deactivateAccountModalOpen, setDeactivateAccountModalOpen] = useState(
    false
  );

  const timeFromSendFromStorage = useMemo(() => {
    const time = JSON.parse(
      localStorage.getItem(localStorageKeys.disableVerifyLinkTime)
    );

    if (!time) {
      return null;
    }
    if (moment().unix() - time > sendEmailDelay) {
      localStorage.removeItem(localStorageKeys.disableVerifyLinkTime);
      return null;
    }
    return sendEmailDelay - (moment().unix() - time);
  }, []);

  const [timeFromSend, setTimeFromSend] = useState(
    timeFromSendFromStorage ?? 0
  );

  useEffect(() => {
    if (timeFromSend !== 0) {
      setTimeout(() => {
        setTimeFromSend((prevState) => prevState - 1);
      }, 1000);
    }
  }, [timeFromSend]);

  const handleDeactivateAccountModalOpen = useCallback(() => {
    setDeactivateAccountModalOpen(true);
  }, []);

  const handleDeactivateAccount = useCallback(
    (confirm) => {
      if (confirm) {
        onDeactivateUser();
      }
      setDeactivateAccountModalOpen(false);
    },
    [onDeactivateUser]
  );

  const handleSendVerificationLink = useCallback(() => {
    setTimeFromSend(sendEmailDelay);
    localStorage.setItem(
      localStorageKeys.disableVerifyLinkTime,
      moment().unix()
    );
    onSendVerificationLink();
  }, [onSendVerificationLink]);

  return (
    <>
      <section className={classes.section}>
        {!isEdit && (
          <Tooltip title="Edit Profile">
            <div className={classes.editBtn}>
              <IconButton color="primary" onClick={onEnableEditMode}>
                <SpriteIcon name="edit" size="sm" />
              </IconButton>
            </div>
          </Tooltip>
        )}
        <p className={classes.title}>Profile</p>
        <Form
          initialValuesEqual={(prevVal, currVal) => prevVal === currVal}
          initialValues={profileValues}
          onSubmit={onChangeProfile}
          validate={changeProfileValidator}
          render={({ values, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="d-flex">
                <div className="flex-fill">
                  <FormControl component="fieldset" disabled={!isEdit}>
                    <RadioGroup>
                      <div className="d-flex mb-3">
                        <FormControlLabel
                          label="Personal"
                          classes={{
                            disabled: classes.disabledInput,
                            root: 'mr-5',
                            label: clsx({
                              'font-semi-bold':
                                values.type === accountTypes.personal,
                            }),
                          }}
                          control={
                            <Field
                              name={signUpFormFields.type}
                              type="radio"
                              value={accountTypes.personal}
                              component={RadioInputWrapper}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Business"
                          classes={{
                            label: clsx({
                              'font-semi-bold':
                                values.type === accountTypes.business,
                            }),
                            disabled: classes.disabledInput,
                          }}
                          control={
                            <Field
                              name={signUpFormFields.type}
                              type="radio"
                              value={accountTypes.business}
                              component={RadioInputWrapper}
                            />
                          }
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div className="d-flex flex-wrap justify-content-between">
                    <div className={classes.halfWidthContainer}>
                      <Field
                        disabled={!isEdit}
                        required
                        label="First Name"
                        name={signUpFormFields.firstName}
                        component={TextInputWrapper}
                        InputProps={{
                          classes: { disabled: classes.disabledInput },
                        }}
                      />
                    </div>
                    <div className={classes.halfWidthContainer}>
                      <Field
                        disabled={!isEdit}
                        required
                        label="Last Name"
                        name={signUpFormFields.lastName}
                        component={TextInputWrapper}
                        InputProps={{
                          classes: { disabled: classes.disabledInput },
                        }}
                      />
                    </div>

                    {values.type === accountTypes.business && (
                      <>
                        <div className={classes.halfWidthContainer}>
                          <Field
                            disabled={!isEdit}
                            required
                            label="Company Name"
                            name={signUpFormFields.businessName}
                            component={TextInputWrapper}
                            InputProps={{
                              classes: { disabled: classes.disabledInput },
                            }}
                          />
                        </div>
                        <div className={classes.halfWidthContainer}>
                          <Field
                            disabled={!isEdit}
                            label="Business Type"
                            required
                            freeSolo
                            options={businessTypeOptions}
                            name={signUpFormFields.businessType}
                            component={AutoCompleteCustomWrapper}
                          />
                        </div>
                        <div className={classes.halfWidthContainer}>
                          <Field
                            disabled={!isEdit}
                            label="Website Url (Optional)"
                            name={signUpFormFields.businessUrl}
                            component={TextInputWrapper}
                            InputProps={{
                              classes: { disabled: classes.disabledInput },
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <Field
                    disabled={!isEdit}
                    label="Display Name (Optional)"
                    name={signUpFormFields.displayName}
                    component={TextInputWrapper}
                    InputProps={{
                      classes: { disabled: classes.disabledInput },
                      inputProps: {
                        maxLength: 30,
                      },
                    }}
                  />
                  <Field
                    disabled={!isEdit}
                    label="Profile Description (Optional)"
                    name={signUpFormFields.description}
                    multiline
                    rows={4}
                    component={TextInputWrapper}
                    InputProps={{
                      classes: { disabled: classes.disabledInput },
                    }}
                  />
                </div>
                <div className={classes.avatarContainer}>
                  <ProfilePhoto
                    firstName={user.firstName}
                    lastName={user.lastName}
                    displayName={user.displayName}
                    src={avatarUrl}
                    editMode={isEdit}
                    onSelectNewImage={onSelectNewImage}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className={classes.oneThirdWidthContainer}>
                  <Field
                    disabled={!isEdit}
                    required
                    label="Country"
                    name={signUpFormFields.countryId}
                    component={SelectInputWrapper}
                    options={countriesOptions}
                    classes={{ disabled: classes.disabledInput }}
                  />
                </div>
                <div className={classes.oneThirdWidthContainer}>
                  <Field
                    disabled={!isEdit}
                    required
                    label="Language"
                    name={signUpFormFields.languageId}
                    component={SelectInputWrapper}
                    options={languagesOptions}
                    classes={{ disabled: classes.disabledInput }}
                  />
                </div>
                <div className={classes.oneThirdWidthContainer}>
                  <Field
                    disabled={!isEdit}
                    label="Zip Code (Optional)"
                    name={signUpFormFields.zipCode}
                    component={TextInputWrapper}
                    InputProps={{
                      classes: { disabled: classes.disabledInput },
                    }}
                  />
                </div>
              </div>
              {isEdit && (
                <>
                  <FinalFormSubmitButton inline className="mr-2">
                    Save Changes
                  </FinalFormSubmitButton>
                  <Button
                    type="button"
                    inline
                    variant="outlined"
                    onClick={onCancelEditProfile}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </form>
          )}
        />
      </section>
      <section className={classes.section}>
        {!isEditEmail && (
          <Tooltip title="Change Email">
            <div className={classes.editBtn}>
              <IconButton color="primary" onClick={onEnableEditEmailMode}>
                <SpriteIcon name="edit" size="sm" />
              </IconButton>
            </div>
          </Tooltip>
        )}
        <p className={clsx(classes.title, 'mb-5')}>Email</p>
        {isEditEmail ? (
          <Form
            initialValues={{ [changeEmailFormFields.currentEmail]: user.email }}
            validate={changeEmailValidator}
            onSubmit={confirmChangeEmailOpen}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="d-flex justify-content-between">
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      disabled
                      label="Current Email"
                      name={changeEmailFormFields.currentEmail}
                      InputProps={{
                        classes: { disabled: classes.disabledInput },
                      }}
                      component={TextInputWrapper}
                    />
                  </div>
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="New Email"
                      name={changeEmailFormFields.newEmail}
                      component={TextInputWrapper}
                    />
                  </div>
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="Repeat New Email"
                      name={changeEmailFormFields.confirmNewEmail}
                      component={TextInputWrapper}
                    />
                  </div>
                </div>
                <FinalFormSubmitButton inline className="mr-2">
                  Change Email
                </FinalFormSubmitButton>
                <Button
                  type="button"
                  inline
                  variant="outlined"
                  onClick={onCancelEditEmail}
                >
                  Cancel
                </Button>
              </form>
            )}
          />
        ) : (
          <div className={classes.oneThirdWidthContainer}>
            <TextInput
              disabled
              label="Email"
              error={!user.hasVerifiedEmail}
              helperText={!user.hasVerifiedEmail ? '*Email not verified' : null}
              value={user.email}
              InputProps={{
                classes: { disabled: classes.disabledInput },
              }}
            />
          </div>
        )}
        {!user.hasVerifiedEmail && !isEditEmail && (
          <div className="d-flex justify-content-end align-items-center">
            {timeFromSend > 0 && (
              <div className={classes.timer}>
                {moment(timeFromSend, 'ss').format('mm:ss')}
              </div>
            )}
            <Button
              inline
              variant="default"
              size="sm"
              onClick={handleSendVerificationLink}
              disabled={timeFromSend !== 0}
            >
              Send Link To Verify
            </Button>
          </div>
        )}
      </section>
      {user.ambassador ? (
        <section className={classes.section}>
          <p className={clsx(classes.title, 'mb-5')}>Affiliate codes</p>
          <AffiliateCodes
            codes={user.affiliateCodeInfo}
            sites={affiliateSites}
            editAffiliateCode={editAffiliateCode}
            deleteAffiliateCode={deleteAffiliateCode}
            userId={user.id}
          />
          {isAddAffiliateCode ? (
            <Form
              validate={addCodeValidator}
              onSubmit={addAffiliateCode}
              render={({ handleSubmit, form, values }) => (
                <form
                  noValidate
                  onSubmit={(event) => {
                    event.preventDefault();
                    handleSubmit(values, form);
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div className={classes.halfWidthContainer}>
                      <Field
                        required
                        label="Select Affiliate site"
                        name={addAffiliateCodeFields.affiliateSite}
                        component={SelectInputWrapper}
                        options={getEnumAsSelectOptions(affiliateSites)}
                        classes={{ disabled: classes.disabledInput }}
                      />
                    </div>
                    <div
                      className={clsx(
                        classes.halfWidthContainer,
                        'd-flex align-items-center'
                      )}
                    >
                      <InfoIconPopover
                        iconProps={{
                          className: 'mb-1 mr-1',
                          variant: 'inverted-white',
                          size: 'xs',
                          color: 'primary',
                        }}
                        fullWidth
                      >
                        <p className="font-semi-bold mb-1 primary-color text-center font-normal text-lg">
                          HOW TO ENTER AN AFFILIATE CODE
                        </p>
                        <p className="text-sm text-underline font-normal">
                          Copy & Paste Your Affiliate Code into this field.
                        </p>
                        <img
                          src={affiliateCodeField}
                          alt="affiliateCodeField"
                        />
                        <p className="text-xs">
                          Pin the Look will add your Affiliate Code extension to
                          products from the Affiliate Site that you place in
                          Look Boards so that you can get credit for that
                          specific item.
                        </p>
                      </InfoIconPopover>
                      <Field
                        requried
                        name={addAffiliateCodeFields.affiliateCode}
                        label="Enter Affiliate code"
                        component={TextInputWrapper}
                      />
                    </div>
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      name={addAffiliateCodeFields.affiliateCouponCode}
                      label="Enter Coupon code (Optional)"
                      component={TextInputWrapper}
                    />
                  </div>
                  <FinalFormSubmitButton inline size="sm" className="mr-2">
                    Accept
                  </FinalFormSubmitButton>
                  <Button
                    type="button"
                    inline
                    size="sm"
                    variant="outlined"
                    onClick={onToggleAddAffiliateCodeMode}
                  >
                    Cancel
                  </Button>
                </form>
              )}
            />
          ) : (
            <div className={classes.oneThirdWidthContainer}>
              {!isAddAffiliateCode && (
                <Button
                  inline
                  size="sm"
                  className="mr-1"
                  onClick={onToggleAddAffiliateCodeMode}
                  disabled={!user.hasVerifiedEmail}
                >
                  Add Site
                </Button>
              )}
            </div>
          )}
        </section>
      ) : null}
      <section className={classes.section}>
        {!isEditPassword && (
          <Tooltip title="Change Password">
            <div className={classes.editBtn}>
              <IconButton color="primary" onClick={onEnableEditPasswordMode}>
                <SpriteIcon name="edit" size="sm" />
              </IconButton>
            </div>
          </Tooltip>
        )}
        <p className={clsx(classes.title, 'mb-5')}>Password</p>
        {isEditPassword ? (
          <Form
            validate={changePasswordValidator}
            onSubmit={onChangePassword}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="Current Password"
                      name={changePasswordFormFields.currentPassword}
                      component={PasswordInputWrapper}
                    />
                  </div>
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="New Password"
                      name={changePasswordFormFields.newPassword}
                      component={PasswordInputWrapper}
                    />
                  </div>
                  <div className={classes.oneThirdWidthContainer}>
                    <Field
                      required
                      label="Confirm Password"
                      name={changePasswordFormFields.confirmNewPassword}
                      component={PasswordInputWrapper}
                    />
                  </div>
                </div>
                <FinalFormSubmitButton inline className="mr-2">
                  Change Password
                </FinalFormSubmitButton>
                <Button
                  type="button"
                  inline
                  variant="outlined"
                  onClick={onCancelEditPassword}
                >
                  Cancel
                </Button>
              </form>
            )}
          />
        ) : (
          <div className={classes.oneThirdWidthContainer}>
            <TextInput
              disabled
              label="Password"
              type="password"
              value="************"
              InputProps={{
                classes: { disabled: classes.disabledInput },
              }}
            />
          </div>
        )}
      </section>
      <section className={classes.section}>
        <p className={clsx(classes.title, 'mb-4')}>
          Membership type: {user.ambassador ? 'Ambassador' : 'General'}
        </p>
        <Button inline onClick={handleChangeMembership}>
          {user.ambassador ? 'Nominate Ambassador' : 'Become an Ambassador'}
        </Button>
      </section>
      <section className={classes.section}>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <p className={classes.title}>Deactivate Account</p>
          <Button
            type="button"
            variant="default"
            color="default"
            inline
            className={classes.deactivateBtn}
            onClick={handleDeactivateAccountModalOpen}
          >
            Deactivate Account
          </Button>
        </div>
        <p className={classes.deactivateDescription}>
          To Deactivate your account, you can click on the red button. This will
          close access to your account, but any Inspiration Images added or Look
          Boards created may remain visible on Pin the Look.
        </p>
      </section>
      {!user.hasVerifiedEmail && (
        <div className={classes.verificationPopup}>
          <ToastrIconContainer type="error">
            <SpriteIcon name="cross" size="sm" />
          </ToastrIconContainer>
          <div className="flex-fill mx-5">
            <p className={classes.title}>Please, verify your email address</p>
            {/* <p className={classes.popupDescription}> */}
            {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do */}
            {/*  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut */}
            {/*  enim ad minim veniam, quis nostrud exercitation ullamco laboris */}
            {/*  nisi ut aliquip ex ea commodo consequat. */}
            {/* </p> */}
          </div>
        </div>
      )}
      <ConfirmModal
        open={deactivateAccountModalOpen}
        onClose={handleDeactivateAccount}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">deactivate</span> account?
          </>
        }
      />
      <ConfirmModal
        open={changeEmailModalOpen}
        onClose={onChangeEmail}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">change</span> email?
          </>
        }
      />
    </>
  );
};

ProfileComponent.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  isEditEmail: PropTypes.bool.isRequired,
  isEditPassword: PropTypes.bool.isRequired,
  isAddAffiliateCode: PropTypes.bool.isRequired,
  onEnableEditMode: PropTypes.func.isRequired,
  onEnableEditEmailMode: PropTypes.func.isRequired,
  onEnableEditPasswordMode: PropTypes.func.isRequired,
  onToggleAddAffiliateCodeMode: PropTypes.func.isRequired,
  user: userShape.isRequired,
  countriesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  languagesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  profileValues: PropTypes.shape({}).isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onCancelEditProfile: PropTypes.func.isRequired,
  onCancelEditEmail: PropTypes.func.isRequired,
  onSendVerificationLink: PropTypes.func.isRequired,
  onCancelEditPassword: PropTypes.func.isRequired,
  onSelectNewImage: PropTypes.func.isRequired,
  onDeactivateUser: PropTypes.func.isRequired,
  avatarUrl: PropTypes.string,
  confirmChangeEmailOpen: PropTypes.func.isRequired,
  changeEmailModalOpen: PropTypes.bool.isRequired,
  handleChangeMembership: PropTypes.func.isRequired,
  addAffiliateCode: PropTypes.func.isRequired,
  editAffiliateCode: PropTypes.func.isRequired,
  deleteAffiliateCode: PropTypes.func.isRequired,
  affiliateSites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ProfileComponent.defaultProps = {
  avatarUrl: null,
};

export default ProfileComponent;
