import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import buildResourceUrl from 'utils/buildResourceUrl';
import classes from 'modules/dashboard/showcase/components/BannerModal/BannerModal.module.scss';

const BannerImage = ({ active, mediaId, userId, hash, onChange }) => {
  const bannerUrlPlaceholders = useSelector(
    (state) => state.app.config.endpoints.media.showcaseBanner
  );

  const bannerUrl = buildResourceUrl(bannerUrlPlaceholders.small, userId, hash);

  const handleChange = useCallback(() => {
    onChange(mediaId);
  }, [onChange, mediaId]);

  return (
    <div
      className={clsx(classes.bannerImageContainer, {
        [classes.active]: active,
      })}
      onClick={handleChange}
    >
      <img src={bannerUrl} alt="" />
    </div>
  );
};

BannerImage.propTypes = {
  active: PropTypes.bool.isRequired,
  mediaId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BannerImage;
