import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getItemClassesAsSelectOptionsByIds } from 'modules/app/store/selectors';

const mapState = (itemsIds) => ({
  app: {
    enums: { itemClasses },
  },
}) => ({
  items: getItemClassesAsSelectOptionsByIds(itemClasses, itemsIds),
});

const useItemClassesByIds = (itemsIds = []) => {
  const { items } = useSelector(mapState(itemsIds));
  const itemClasses = useMemo(() => items, [items]);
  return itemClasses;
};

export default useItemClassesByIds;
