export const reportFormFields = {
  name: 'name',
  email: 'email',
  signature: 'signature',
  street: 'street',
  state: 'state',
  region: 'region',
  zip: 'zip',
  country: 'country',
  phone: 'phone',
  workDescription: 'workDescription',
  workUrl: 'workUrl',
  replaceUrl: 'replaceUrl',
  siteTitle: 'siteTitle',
  materialUrl: 'materialUrl',
  removeAll: 'removeAll',
  credit: 'credit',
  accurate: 'accurate',
  notAuthorized: 'notAuthorized',
  owner: 'owner',
};
