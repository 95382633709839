import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import classes from 'components/MixMatchThumbnail/MixMatchThumbnail.module.scss';

const MatchRating = ({ name, value, onChange }) => (
  <div className={classes.matchRatingWrapper}>
    <span className="text-sm font-semi-bold mr-1 text-center">
      Rate the Match
    </span>
    <Rating
      name={name}
      classes={{
        iconEmpty: classes.ratingIconEmpty,
        iconFilled: classes.ratingIconFilled,
      }}
      value={value}
      onChange={onChange}
    />
  </div>
);

MatchRating.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
};

MatchRating.defaultProps = {
  value: null,
};

export default MatchRating;
