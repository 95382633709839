export const transformFormValuesToRegisterData = (formValues) => {
  const { terms } = formValues;

  return {
    ...formValues,
    terms: Number(Boolean(terms)),
  };
};

export default {
  transformFormValuesToRegisterData,
};
