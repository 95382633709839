import React from 'react';
import { ambassadorsSliderConfig } from 'modules/home/constants';
import SliderContainer from 'modules/home/components/Ambassadors/Slider/SliderContainer';
import Banner from 'modules/home/components/Ambassadors/Banner/Banner';
import classes from './Ambassadors.module.scss';

const Ambassadors = () => (
  <section className={classes.root}>
    <div className="flex-center flex-column text-center">
      <h2 className={classes.title}>Meet our Ambassadors</h2>
      <p className={classes.description}>
        Ambassadors are decor lovers with a keen eye for design and a passion
        for home decorating. <br />
        Ambassadors have Showcase pages on Pin the Look featuring unique
        inspiration images and curated looks. <br /> When a user requests the
        Look of an image, Ambassadors specializing <br /> in their style will
        curate Look Boards to show different ways to Get the Look.
      </p>
    </div>
    <SliderContainer slidesData={ambassadorsSliderConfig} />
    <div className={classes.bannerWrapper}>
      <Banner />
    </div>
  </section>
);

export default Ambassadors;
