import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  myProductsSortOptions,
  myProductsTypeOptions,
} from 'modules/dashboard/constants';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import {
  productSortKeys,
  productTypeKeys,
} from 'constants/productSearchParams';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import productService from 'modules/product/productService';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import MyProductItem from 'modules/dashboard/myProducts/components/MyProductItem/MyProductItem';
import errorToastr from 'libs/toastr/errorToastr';
import useCancelToken from 'hooks/useCancelToken';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';
import infoToastr from '../../../libs/toastr/infoToastr';
import { PRODUCT_REMOVE_INSTANTLY_CREATED } from '../../product/store/constants';

// TODO: Get limit value from application config
const limit = 10;

const initialSearchParams = {
  type: productTypeKeys.my,
  sort: productSortKeys.newest,
  offset: 0,
};

const MyProductsContainer = () => {
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [productList, setProductList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createCancelToken, isCancelled] = useCancelToken();
  const dispatch = useDispatch();

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();
      const list = await productService.getProducts(searchParams, {
        cancelToken,
      });
      setProductList(list);
      if (list.length > 0) {
        setSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + list.length,
        }));
      }
      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [searchParams, createCancelToken, isCancelled]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    const cancelToken = createCancelToken();

    try {
      const list = await productService.getProducts(searchParams, {
        cancelToken,
      });
      setProductList((prevState) => [...prevState, ...list]);
      if (list.length > 0) {
        setSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + list.length,
        }));
      }
      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [searchParams, isCancelled, createCancelToken]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleChangeType = useCallback(({ target: { value: type } }) => {
    setSearchParams((prevState) => ({ ...prevState, type, offset: 0 }));
  }, []);

  const handleChangeSort = useCallback((sort) => {
    setSearchParams((prevState) => ({ ...prevState, sort, offset: 0 }));
  }, []);

  const handleUnlikeProduct = useCallback(
    async (productId) => {
      try {
        await productService.toggleLike(productId, 0);
        const updatedProductList = productList.filter(
          ({ id }) => id !== productId
        );
        setProductList(updatedProductList);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [productList]
  );

  const handleDeleteProduct = useCallback(
    async (productId) => {
      try {
        await productService.deleteProduct(productId);
        const updatedProductList = productList.filter(
          ({ id }) => id !== productId
        );
        dispatch({ type: PRODUCT_REMOVE_INSTANTLY_CREATED });
        setProductList(updatedProductList);
        infoToastr('Success', 'Product successfully deleted');
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [productList, dispatch]
  );

  const handleEditProduct = useCallback(async (productId) => {
    // TODO: Add backend integration when it will be ready
    // eslint-disable-next-line no-console
    console.log('Edit product with id:', productId);
  }, []);

  return (
    <div className={classes.section}>
      <div className="d-flex align-items-start justify-content-between mb-1">
        <CustomRadioGroup
          controls={myProductsTypeOptions}
          value={searchParams.type}
          onChange={handleChangeType}
        />
        <FilterInput
          additionalLabel="Sort by:"
          className={classes.sortTypeSelect}
          options={myProductsSortOptions}
          currentValue={searchParams.sort}
          onChange={handleChangeSort}
        />
      </div>
      {productList.length > 0 ? (
        <CustomInfiniteScroll
          loadMore={loadMore}
          isLoading={loading}
          hasMore={hasMore}
          initialLoad={false}
        >
          <div className="row">
            {productList.map((product) => (
              <div key={product.id} className="col-3 mb-2">
                <MyProductItem
                  currentType={searchParams.type}
                  product={product}
                  onUnlike={handleUnlikeProduct}
                  onDelete={handleDeleteProduct}
                  onEdit={handleEditProduct}
                />
              </div>
            ))}
          </div>
        </CustomInfiniteScroll>
      ) : (
        <div className="flex-fill flex-center font-title text-gray text-lg">
          List is Empty
        </div>
      )}
    </div>
  );
};

MyProductsContainer.propTypes = {};

export default MyProductsContainer;
