import React from 'react';
import PropTypes from 'prop-types';
import AffiliateCodeItem from './AffiliateCodeItem';

const AffiliateCodes = ({
  codes,
  sites,
  editAffiliateCode,
  userId,
  deleteAffiliateCode,
}) => {
  return (
    <>
      {codes.map((code) => (
        <AffiliateCodeItem
          code={code}
          editAffiliateCode={editAffiliateCode}
          deleteAffiliateCode={deleteAffiliateCode}
          sites={sites}
          userId={userId}
        />
      ))}
    </>
  );
};

AffiliateCodes.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editAffiliateCode: PropTypes.func.isRequired,
  deleteAffiliateCode: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};
export default AffiliateCodes;
