import React from 'react';
import PropTypes from 'prop-types';
import SubStylesSlider from 'components/PreferenceQuiz/SubStylesSlider/SubStylesSlider';
import Prompt from './Prompt/Prompt';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../../constants/mediaQueries';

const SubStylesFilter = ({
  options,
  currentValue,
  onChange,
  isPromptShow,
  promptText,
}) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  return (
    <div style={{ minHeight: !matchesMediaQuery ? 500 : 'none' }}>
      {options.length > 0 ? (
        <>
          <SubStylesSlider
            currentValue={currentValue}
            subStyles={options}
            onLike={onChange}
          />
          <Prompt open={isPromptShow} text={promptText} />
        </>
      ) : (
        <div
          className="flex-fill flex-center font-title text-gray text-lg text-center"
          style={{ height: 500, lineHeight: 1.4 }}
        >
          Any style selected.
          <br />
          To see sub styles options
          <br />
          please select some styles
        </div>
      )}
    </div>
  );
};

SubStylesFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  isPromptShow: PropTypes.bool,
  promptText: PropTypes.string,
};

SubStylesFilter.defaultProps = {
  isPromptShow: false,
  promptText: '',
};

export default SubStylesFilter;
