import {
  imagePageTypes,
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { getTheLookTabKeys } from 'modules/getTheLook/constants';
import {
  GTL_LIKE_LOOKBOARD,
  GTL_RESET_FILTER_VALUES,
  GTL_SET_ACTIVE_TAB,
  GTL_SET_IMAGES,
  GTL_SET_LIKED_IMAGES,
  GTL_SET_LIKED_LOOK_BOARDS,
  GTL_SET_SELECTED_IMAGE,
  GTL_UNLIKE_IMAGE,
  GTL_UPDATE_FILTER_VALUES,
  GTL_UPDATE_HAS_MORE_STATUS,
  GTL_UPDATE_IMAGES,
  GTL_UPDATE_LOADING_STATUS,
  GTL_UPDATE_LOOK_BOARDS_DATA,
  GTL_UPDATE_ALL_LOOK_BOARDS_DATA,
  GTL_UPDATE_MIX_MATCH_SORT,
  GTL_UPDATE_MIX_MATCH_SUBTYPE,
  GTL_UPDATE_MIX_MATCH_TYPE,
  GTL_UPDATE_SEARCH_PARAMS,
  GTL_VOTE_LOOKBOARD,
} from 'modules/getTheLook/store/constants';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';

export const initialFilterValues = {
  [filterTabKeys.vibes]: [],
  [filterTabKeys.styles]: [],
  [filterTabKeys.subStyles]: {},
  [filterTabKeys.roomTypes]: [],
  [filterTabKeys.colors]: [],
  [filterTabKeys.itemClasses]: [],
};

const initialSearchParams = {
  source: imageSourceKeys.all,
  type: imageTypeKeys.room,
  search: '',
  offset: 0,
  lbCount: null,
  pageType: imagePageTypes.getTheLook,
};

const initialState = {
  activeTab: getTheLookTabKeys.galleryView,
  filterValues: initialFilterValues,
  searchParams: initialSearchParams,
  imageList: [],
  limit: 10,
  hasMore: true,
  loading: false,
  lookBoardsData: {},
  allLookBoards: [],
  selectedImage: null,
  mixMatchType: 'curated',
  mixMatchSubtype: 'up24',
  mixMatchSort: 'bestMatch',
  likedImages: [],
  likedLookBoards: [],
  lbCount: null,
  pageType: imagePageTypes.getTheLook,
};

const getTheLookReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case GTL_SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    case GTL_UPDATE_SEARCH_PARAMS: {
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...payload,
        },
      };
    }
    case GTL_UPDATE_LOADING_STATUS: {
      return {
        ...state,
        loading: payload,
      };
    }
    case GTL_SET_IMAGES: {
      return {
        ...state,
        imageList: payload,
      };
    }
    case GTL_UPDATE_IMAGES: {
      return {
        ...state,
        imageList: [...state.imageList, ...payload],
      };
    }
    case GTL_UPDATE_HAS_MORE_STATUS: {
      return {
        ...state,
        hasMore: payload,
      };
    }
    case GTL_UPDATE_LOOK_BOARDS_DATA: {
      return {
        ...state,
        lookBoardsData: {
          ...state.lookBoardsData,
          ...payload,
        },
      };
    }
    case GTL_UPDATE_ALL_LOOK_BOARDS_DATA: {
      const lookBoardsDataArr = Object.values(payload).map(
        (obj) => obj.lookBoards
      );
      return {
        ...state,
        allLookBoards: [...[].concat(...lookBoardsDataArr)],
      };
    }
    case GTL_SET_SELECTED_IMAGE: {
      return {
        ...state,
        selectedImage: payload,
      };
    }
    case GTL_UPDATE_FILTER_VALUES: {
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          ...payload,
        },
      };
    }
    case GTL_RESET_FILTER_VALUES: {
      return {
        ...state,
        filterValues: { ...initialFilterValues },
      };
    }
    case GTL_LIKE_LOOKBOARD: {
      const { imageId, lookBoardId, isLiked } = payload;

      const currentImageCopy = { ...state.lookBoardsData[imageId] };
      const currentLookBoard = currentImageCopy.lookBoards.find(
        ({ id }) => id === lookBoardId
      );
      currentLookBoard.isLiked = isLiked;

      return {
        ...state,
        lookBoardsData: {
          ...state.lookBoardsData,
          [imageId]: currentImageCopy,
        },
      };
    }
    case GTL_VOTE_LOOKBOARD: {
      const { imageId, lookBoardId } = payload;

      const currentImageCopy = { ...state.lookBoardsData[imageId] };
      const currentLookBoard = currentImageCopy.lookBoards.find(
        ({ id }) => id === lookBoardId
      );
      currentLookBoard.isVoted = true;

      return {
        ...state,
        lookBoardsData: {
          ...state.lookBoardsData,
          [imageId]: currentImageCopy,
        },
      };
    }
    case GTL_UPDATE_MIX_MATCH_TYPE: {
      return {
        ...state,
        mixMatchType: payload,
      };
    }
    case GTL_UPDATE_MIX_MATCH_SUBTYPE: {
      return {
        ...state,
        mixMatchSubtype: payload,
      };
    }
    case GTL_UPDATE_MIX_MATCH_SORT: {
      return {
        ...state,
        mixMatchSort: payload,
      };
    }
    case GTL_SET_LIKED_IMAGES: {
      return {
        ...state,
        likedImages: payload,
      };
    }
    case GTL_UNLIKE_IMAGE: {
      const { id } = payload;

      return {
        ...state,
        likedImages: state.likedImages.filter((item) => item.id !== id),
      };
    }
    case GTL_SET_LIKED_LOOK_BOARDS: {
      return {
        ...state,
        likedLookBoards: payload,
      };
    }
    default:
      return state;
  }
};

export default getTheLookReducer;
