import React from 'react';
import verifiedImg from 'assets/img/ambassador-page/points-and-badges/verified.png';
import workspaceImg from 'assets/img/ambassador-page/points-and-badges/workspace.png';
import classes from './PointsAndBadges.module.scss';

// eslint-disable-next-line import/no-duplicates
// eslint-disable-next-line import/named,import/no-duplicates
import { badgesConfig, pointsConfig } from './pointsConfig';

const PointsAndBadges = () => {
  return (
    <>
      <h3 className={classes.sectionTitle}>earn points & badges</h3>
      <p className={classes.sectionDescription}>
        Your activity on Pin the Look will be celebrated. You will earn points
        that can go toward home decor, swag, gift cards for friends, or a Pin
        the Look sponsored charity. Once you reach point thresholds, you will
        get a badge on your profile and your showcase page will get more
        exposure.
      </p>
      <div className={classes.root}>
        <div className={classes.table}>
          <div className={classes.logo}>
            <img src={verifiedImg} alt="verified" />
            <span>Points</span>
          </div>
          <div className={classes.pointsTable}>
            <div className={classes.head}>
              <div className={classes.left} />
              <div className={classes.right}>points*</div>
            </div>
            {pointsConfig.map((item) => {
              return (
                <div className={classes.pointItem} key={item.id}>
                  <div className={classes.left}>
                    {item.title}
                    {item.description && (
                      <p className="primary-color">{item.description}</p>
                    )}
                  </div>
                  <div className={classes.right}>{item.points}</div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={classes.table}>
          <div className={classes.logo}>
            <img src={workspaceImg} alt="workspace" />
            <span>Badges</span>
          </div>
          <div className={classes.badgeTable}>
            {badgesConfig.map((item) => {
              return (
                <div className={classes.pointItem} key={item.id}>
                  <div className={classes.left}>
                    <img src={item.image} alt="icon" />
                    {item.title}
                  </div>
                  <div className={classes.right}>{item.points}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default PointsAndBadges;
