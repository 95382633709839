import React, { useState } from 'react';

export function useSectionRefs(sectionTitles) {
  const [sectionRefs, setSectionRefs] = useState({});

  if (Object.keys(sectionRefs).length === 0) {
    const refs = {};
    sectionTitles.forEach((id) => {
      refs[id] = React.createRef();
    });
    setSectionRefs(refs);
  }

  return sectionRefs;
}
