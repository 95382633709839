import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useEventListener from 'hooks/useEventListener';
import {
  infoMenuItemsArrayOf,
  infoMenuShape,
} from 'modules/layout/SideNav/propTypes';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import SpriteIcon from 'components/ui/SpriteIcon';
import InfoMenuItem from 'modules/layout/SideNav/InfoMenu/InfoMenuItem';
import classes from './InfoMenu.module.scss';

const InfoMenu = ({
  isOpen,
  onClose,
  config,
  isAmbassador,
  ambassadorConfig,
  mobile,
}) => {
  const itemsContainerRef = useRef(null);
  const [customScrollerHeight, setCustomScrollerHeight] = useState(0);
  const [beginClose, setBeginClose] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setBeginClose(true);
    setTimeout(() => {
      setBeginClose(false);
      onClose();
    }, 400); // animation time before close
  }, [onClose]);

  const handleEscClick = useCallback(
    ({ code }) => {
      if (code !== 'Escape') {
        return;
      }
      handleCloseMenu();
    },
    [handleCloseMenu]
  );

  useEventListener(window, 'keydown', handleEscClick);

  useEffect(() => {
    if (isOpen) {
      const refHeight = itemsContainerRef.current.clientHeight;

      setCustomScrollerHeight(Math.round(refHeight));
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.onClose]: beginClose,
        [classes.mobile]: mobile,
      })}
    >
      <button
        className={classes.closeBtn}
        onClick={handleCloseMenu}
        title="Close Info"
        type="button"
      >
        <SpriteIcon name="cross" />
      </button>

      <div className={classes.title}>
        <span>{config.title}</span>
      </div>
      {config.description && (
        <p className="font-italic mb-5">{config.description}</p>
      )}

      <div ref={itemsContainerRef} className="flex-fill overflow-hidden">
        <CustomScrollBar
          autoHeightMin={customScrollerHeight}
          autoHeightMax={customScrollerHeight}
          renderView={(props) => <div {...props} className="pr-2" />}
        >
          {isAmbassador &&
            ambassadorConfig &&
            ambassadorConfig.map(({ id, ...rest }) => (
              <InfoMenuItem key={id} {...rest} />
            ))}
          {config.items.map(({ id, ...rest }) => (
            <InfoMenuItem key={id} {...rest} />
          ))}
        </CustomScrollBar>
      </div>
    </div>
  );
};

InfoMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  config: infoMenuShape.isRequired,
  isAmbassador: PropTypes.bool,
  ambassadorConfig: infoMenuItemsArrayOf,
};

InfoMenu.defaultProps = {
  isAmbassador: false,
  mobile: false,
  ambassadorConfig: null,
};

export default InfoMenu;
