import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import classes from './ImageDetails.module.scss';

const ImageDetails = ({ imgUrl, url, title, showInfo, editRoute }) => (
  <div className={classes.root}>
    <div className="position-relative">
      <img className="w-100" src={imgUrl} alt="" />
      {showInfo && (
        <div className={classes.info}>
          <div className="font-semi-bold text-sm">Title</div>
          <div className={classes.title}>{title}</div>
          {url && (
            <div className="text-ellipsis">
              <ImageLink url={url} />
            </div>
          )}
        </div>
      )}
      {editRoute && (
        <Link className={classes.edit} to={editRoute}>
          <IconButton variant="inverted-grey" color="secondary" size="sm">
            <SpriteIcon name="edit" size="sm" />
          </IconButton>
        </Link>
      )}
    </div>
  </div>
);

ImageDetails.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  showInfo: PropTypes.bool,
  editRoute: PropTypes.string,
};

ImageDetails.defaultProps = {
  url: null,
  editRoute: null,
  showInfo: undefined,
};

export default ImageDetails;
