import React, { useCallback, useRef, useState } from 'react';
import buildResourceUrl from 'utils/buildResourceUrl';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import MoreOptionsPopover from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import moreOptionsClasses from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover.module.scss';
import { routesByName } from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import errorToastr from 'libs/toastr/errorToastr';
import { updateIIStatusLikeAction } from 'modules/inspirationImage/store/actions';
import {
  resetCanvasAction,
  selectImageAction as selectCTLImageAction,
} from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectRTLImageAction } from 'modules/requestTheLook/store/actions';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import {
  setClaimImageIdAction,
  toggleClaimImageModalAction,
} from '../../report/store/actions';
import classes from '../GetTheLook.module.scss';
import { publishStatusKeys } from '../../inspirationImage/constants';
import { GTL_UNLIKE_IMAGE } from '../store/constants';

const GalleryMobileItem = ({
  image: {
    id,
    isLiked,
    shareUrl,
    media: { userId, hash },
    source_url: sourceUrl,
    publish,
    slug,
  },
  isShowcase,
  onLikeSimilarImage,
  likedImages,
}) => {
  const { pathname } = useLocation();
  const imageRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const selectedImage = Boolean(
    useSelector((state) => state.curateTheLook.lookBoardData.inspirationImageId)
  );
  const inspirationImageUrl = useSelector(
    (state) => state.app.config.endpoints.media.inspirationImage
  );

  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const imgUrl = buildResourceUrl(inspirationImageUrl.medium, userId, hash);

  const handleMoreOptionsPopupOpen = useCallback((event) => {
    event.stopPropagation();
    setMoreOptionsAnchorEl(event.currentTarget);
    setIsOptionOpen(true);
    setScrollPosition(window.pageYOffset);
  }, []);

  // set default scroll position after closing "MoreOptionPopup"
  const handleMoreOptionsPopupClose = useCallback(() => {
    setMoreOptionsAnchorEl(null);
    setIsOptionOpen(false);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
  }, [scrollPosition]);

  const handleSelectImage = useCallback(() => {
    if (isShowcase) {
      if (publish === publishStatusKeys.publishedPlus)
        window.open(routesByName.getTheLook.details(id), '_blank');
      else
        window.open(routesByName.inspirationImage.details(id, slug), '_blank');
      return;
    }
    if (publish === publishStatusKeys.publishedPlus)
      history.push(routesByName.getTheLook.details(id));
    else history.push(routesByName.inspirationImage.details(id, slug));
  }, [history, id, isShowcase, publish, slug]);

  const handleOnClaimImage = useCallback(() => {
    dispatch(toggleClaimImageModalAction(true));
    dispatch(setClaimImageIdAction(id));
    handleMoreOptionsPopupClose();
  }, [dispatch, id, handleMoreOptionsPopupClose]);

  const handleMoveToRTL = useCallback(() => {
    dispatch(selectRTLImageAction(id));
    history.push(routesByName.requestTheLook.index);
  }, [dispatch, id, history]);

  const handleMoveToCTL = useCallback(() => {
    if (id !== selectedImage) {
      dispatch(resetCanvasAction());
    }
    if (pathname === routesByName.getTheLook.index) {
      dispatch(selectCTLImageAction(id));
      history.push(`${routesByName.curateTheLook.canvas}/?tips=active`);
    } else {
      dispatch(selectCTLImageAction(id));
      history.push(routesByName.curateTheLook.index);
    }
  }, [selectedImage, dispatch, id, history, pathname]);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const handleToggleLike = useCallback(
    async (event) => {
      event.stopPropagation();
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      if (onLikeSimilarImage) {
        try {
          await inspirationImageService.toggleLike(id, Number(!isLiked));
          onLikeSimilarImage();
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      } else {
        try {
          await inspirationImageService.toggleLike(id, Number(!isLiked));
          if (likedImages)
            dispatch({ type: GTL_UNLIKE_IMAGE, payload: { id } });
          else dispatch(updateIIStatusLikeAction(id, Number(!isLiked)));
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      }
    },
    [
      authenticated,
      onLikeSimilarImage,
      history,
      pathname,
      id,
      isLiked,
      likedImages,
      dispatch,
    ]
  );

  const getDomain = useCallback((url) => {
    try {
      const urlObject = new URL(url);
      let domain = urlObject.hostname;
      domain = domain.replace(/^www\./, '');
      return domain;
    } catch (error) {
      return null;
    }
  }, []);

  return (
    <div className={!isShowcase && 'p-2'}>
      <div
        className={clsx(
          'position-relative cursor-pointer',
          isShowcase && classes.featuredImage
        )}
      >
        <img
          alt=""
          draggable="false"
          src={imgUrl}
          ref={imageRef}
          className="object-fit-cover"
        />
      </div>
      <div className="background-white p-2">
        <Button onClick={handleSelectImage}>Select This Style</Button>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <div className="flex-fill text-ellipsis w-50">
            {sourceUrl && (
              <ImageLink label={getDomain(sourceUrl)} url={sourceUrl} />
            )}
          </div>
          <IconButton
            variant="inverted-grey"
            size="sm"
            className="mr-1"
            onClick={handleToggleLike}
          >
            <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
          </IconButton>
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <IconButton
            variant="inverted-grey"
            size="sm"
            onClick={handleMoreOptionsPopupOpen}
            onMouseEnter={handleMoreOptionsPopupOpen}
          >
            <SpriteIcon name="more-vertical" size="sm" />
          </IconButton>
        </div>
      </div>
      <MoreOptionsPopover
        open={isOptionOpen}
        onClose={handleMoreOptionsPopupClose}
        anchorEl={moreOptionsAnchorEl}
        scrollPosition={scrollPosition}
      >
        <Button
          className={`${moreOptionsClasses.btn} mr-1`}
          size="custom"
          color="default"
          onClick={handleMoveToRTL}
        >
          <SpriteIcon name="update-arrows" size="sm" className="mr-1" />
          <span>Request</span>
        </Button>
        <Button
          className={`${moreOptionsClasses.btn} mr-1`}
          size="custom"
          color="default"
          onClick={handleMoveToCTL}
        >
          <SpriteIcon name="living-room" size="sm" className="mr-1" />
          <span>Curate</span>
        </Button>
        <Button
          onClick={handleOnClaimImage}
          className={moreOptionsClasses.btn}
          size="custom"
          color="default"
          style={{ minWidth: 90 }}
        >
          <span>Claim Image</span>
        </Button>
      </MoreOptionsPopover>
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        shareUrl={shareUrl}
        directImgUrl={imgUrl}
      />
    </div>
  );
};

GalleryMobileItem.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    shareUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image_source_name: PropTypes.string,
    isLiked: PropTypes.number.isRequired,
    source_url: PropTypes.string.isRequired,
    media: PropTypes.shape({
      userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      hash: PropTypes.string.isRequired,
    }).isRequired,
    publish: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
  isShowcase: PropTypes.bool,
  onLikeSimilarImage: PropTypes.func,
  likedImages: PropTypes.bool,
};

GalleryMobileItem.defaultProps = {
  isShowcase: false,
  onLikeSimilarImage: null,
  likedImages: false,
  image: {
    image_source_name: '',
  },
};

export default GalleryMobileItem;
