import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SpriteIcon from 'components/ui/SpriteIcon';
import verifiedImg from 'assets/img/ambassador-page/points-and-badges/verified.png';
import badgesUserCard from 'assets/img/ambassador-page/points-and-badges/badges-user-card.png';
import Button from 'components/ui/Button/Button';
import workspaceImg from 'assets/img/ambassador-page/points-and-badges/workspace.png';
import { routesByName } from 'constants/routes';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import { Field, Form } from 'react-final-form';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import clsx from 'clsx';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import errorToastr from 'libs/toastr/errorToastr';
import currentUserService from 'modules/currentUser/currentUserService';
import successToastr from 'libs/toastr/successToastr';
import modalClasses from 'modules/dashboard/myPoints/MyPoints.module.scss';
import redeemPointsValidator from '../myPoints/redeemPointsValidator';
import { badgesConfig } from './PointsAndBadges/pointsConfig';

import classes from './AmbassadorManagement.module.scss';

const AmbassadorPointsBadges = () => {
  const history = useHistory();

  const pointsIconList = [
    {
      label: 'Add & Curate Inspiration Images',
      icon: 'scene',
    },
    {
      label: 'Curate Look Board Requests',
      icon: 'repeat',
    },
    {
      label: 'Nominate Active Ambassadors',
      icon: 'groups',
    },
    {
      label: 'Share Showcase & Look Boards',
      icon: 'share-icon',
    },
  ];

  const automaticPointsArr = [
    'Curating Look Boards: 2 Points',
    'Curating Requests: 5 Points',
    'Nominating Active Ambassador: 10 Points',
    'Sharing on Socials: Varies from 1 - 10 Points',
  ];

  const handleOpenMyPoints = () => {
    history.push(routesByName.dashboard.points);
  };
  const [redeemModalOpen, setRedeemModalOpen] = useState(false);
  const [stats, setStats] = useState(null);
  const [rewards, setRewards] = useState(null);

  const onRedeemModalClose = useCallback(() => {
    setRedeemModalOpen(false);
  }, []);

  const openRedeemModal = useCallback(() => {
    setRedeemModalOpen(true);
  }, []);

  const handleRedeemPoints = useCallback(
    async (formValues) => {
      const body = {};
      let sum = 0;
      rewards.forEach((reward) => {
        sum += formValues[reward.name] ? Number(formValues[reward.name]) : 0;
        body[reward.name] = {
          name: reward.name,
          value: formValues[reward.name]
            ? Number(formValues[reward.name])
            : null,
        };
      });
      if (!sum) {
        onRedeemModalClose();
        return;
      }
      if (sum > stats.remainingPoints) {
        errorToastr('Error', 'You dont have enough points');
        return;
      }
      try {
        await currentUserService.redeemPoints(body);
        successToastr('Success', "You've successfully redeemed your points");
        const newStats = await currentUserService.getUserStats();
        setStats(newStats);
        onRedeemModalClose();
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [stats, rewards, onRedeemModalClose]
  );

  useEffect(() => {
    window.scrollTo({ top: 0 });
    currentUserService.getUserStats().then(setStats);
    currentUserService.getRewards().then(setRewards);
  }, []);

  return (
    <section className={classes.root}>
      <div className={classes.banner}>
        <div className="d-flex align-items-center">
          <h2 className="ml-1 text-md text-uppercase font-semi-bold">
            Points & Badges
          </h2>
        </div>
      </div>
      <div className={classes.mainWrapper}>
        <div className={classes.earnPointsWrapper}>
          <div className={classes.pointsLeft}>
            <div className={classes.pointsList}>
              <div className={classes.pointsBadge}>
                <img src={verifiedImg} alt="verified" />
                Points
              </div>
              {pointsIconList.map((item) => (
                <div className="d-flex align-items-center mb-2">
                  <div className={classes.pointItemIcon}>
                    <SpriteIcon name={item.icon} size="xl" />
                  </div>
                  <p className={classes.pointItemLabel}>{item.label}</p>
                </div>
              ))}
            </div>
            <div className={classes.automaticPointsList}>
              <h6 className={classes.automaticPointsList__title}>
                Automatic points:
              </h6>
              <ul className={classes.automaticList}>
                {automaticPointsArr.map((item) => (
                  <li className={classes.automaticList__item}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classes.pointsRight}>
            <h6 className={classes.earnPointsTitle}>Earn points</h6>
            <p className="fw-600 font-italic mb-3 text-lg">
              Your activity on Pin the Look will be celebrated!
            </p>
            <p className="text-lg">
              You will automatically earn points on your activities on the
              platform, such as adding approved inspiration images, curating
              Look Boards, nominating friends who become active Ambassadors,
              sharing Look Boards and Pin the Look promotions on your Socials,
              and more.
            </p>
            <p className="primary-color fw-600 mt-3 text-lg">
              You can always view your accumulated points in the My Points
              section of your dashboard.
            </p>
            <div className={classes.earnPointsButton}>
              <Button size="lg" onClick={handleOpenMyPoints}>
                My Points
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.earnBadgesWrapper}>
          <div className={classes.earnBadgesInfo}>
            <h6 className={classes.earnBadgesInfo__title}>Earn Badges</h6>
            <p className={classes.earnBadgesInfo__description}>
              You will also earn badges based on the points you accumulate.
              Badges will give your showcase and Look Boards higher visibility
              on the platform and give you shoutouts on our Socials.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={badgesUserCard}
                className={classes.earnBadgesInfo__image}
                alt="badgesUserCard"
              />
            </div>
          </div>
          <div className={classes.badgesTableWrapper}>
            <div className={classes.pointsBadge}>
              <img src={workspaceImg} alt="workspace" />
              Badges
            </div>
            <div className={classes.badgeTable}>
              {badgesConfig.map((item) => {
                return (
                  <div className={classes.pointItem} key={item.id}>
                    <div className={classes.left}>
                      <img src={item.image} alt="icon" />
                      {item.title}
                    </div>
                    <div className={classes.right}>{item.points}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.redeemPointsWrapper}>
          <div className={classes.giftCardBlock}>
            <h5>My Look Shops</h5>
            <SpriteIcon name="gift-card" size="fullWidth" />
            <h5>Gift Card</h5>
          </div>
          <div className={classes.redeemPointsInfo}>
            <h4 className={classes.redeemPointsInfo__title}>Redeem Points</h4>
            <p className={classes.redeemPointsInfo__description}>
              You can redeem your points for Gift Cards that you can use to buy
              home furnishings on My Look Shops.
            </p>
            <p className={classes.redeemPointsInfo__description}>
              Every 100 points you earn can be redeemed for a $100 Gift Card
              that can be used for items priced at $250 or more in value.
            </p>
            <p className={classes.redeemPointsInfo__description}>
              You can also donate your points to an approved charity. Points can
              be redeemed or donated in the My Points section in your dashboard.
            </p>
            <div className={classes.redeemPointsInfo__btn}>
              <Button size="lg" onClick={openRedeemModal}>
                Redeem Points
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BasicModal
        open={redeemModalOpen}
        onClose={onRedeemModalClose}
        fullWidth
        maxWidth="md"
        classes={{ root: modalClasses.redeemModal }}
      >
        <h3>Redeem Points</h3>
        <span>
          Thank you being a part of decorating the world with Pin the Look!
        </span>

        <div className={modalClasses.available}>
          Available Redemption Points:{' '}
          <span>{Number(stats?.remainingPoints) || 0}</span>
        </div>

        <div className="d-flex justify-content-between">
          <Form
            validate={redeemPointsValidator}
            onSubmit={handleRedeemPoints}
            render={({ handleSubmit, errors }) => (
              <>
                <form onSubmit={handleSubmit} className="w-100" noValidate>
                  <div className="d-flex">
                    <div className={modalClasses.tableModal}>
                      <div className={modalClasses.tableTitle}>
                        Enter points to redeem in increments of 100:
                      </div>
                      {rewards.map((reward) => (
                        <div
                          className="d-flex justify-content-between align-items-center mb-2"
                          key={reward.id}
                        >
                          <span>{reward.name}</span>
                          <Field
                            name={reward.name}
                            className={modalClasses.input}
                            component={TextInputWrapper}
                            type="number"
                            InputProps={{
                              className:
                                errors[reward.name] && modalClasses.error,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <div
                      className={clsx(
                        modalClasses.tableModal,
                        modalClasses.tableModalSecond
                      )}
                    >
                      <div className={modalClasses.tableTitle}>
                        100 Points Value
                      </div>
                      {rewards.map((reward) => (
                        <div
                          className={modalClasses.pointsValue}
                          key={reward.id}
                        >
                          <span>{reward.name}</span>
                          <div>${Number(reward.cost)}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-center">
                    <FinalFormSubmitButton size="lg" inline>
                      Submit
                    </FinalFormSubmitButton>
                  </div>
                </form>
              </>
            )}
          />
        </div>
      </BasicModal>
    </section>
  );
};

export default AmbassadorPointsBadges;
