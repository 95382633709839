import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { pageDataFormFields } from 'modules/dashboard/showcase/constants';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import TextAreaWrapper from 'components/finalFormWrappers/TextAreaWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import classes from 'modules/dashboard/showcase/components/AddPageDataModal/AddPageDataModal.module.scss';

const StyleStoryModal = ({ open, onClose, description, onApply }) => {
  const [initialFormValues, setInitialFormValues] = useState({
    description,
  });

  useEffect(() => {
    if (!open) {
      setInitialFormValues({
        description,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <BasicModal open={open} onClose={onClose} fullWidth maxWidth="md">
      <div className="px-3">
        <h3 className={`${classes.sectionTitle} mb-1`}>
          Add/edit my style story
        </h3>
        <p className="primary-color font-italic mb-2">150 words or less</p>
        <Form
          initialValues={initialFormValues}
          onSubmit={onApply}
          render={({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Field
                required
                name={pageDataFormFields.description}
                component={TextAreaWrapper}
              />
              <div className="text-center">
                <FinalFormSubmitButton inline>Save</FinalFormSubmitButton>
              </div>
            </form>
          )}
        />
      </div>
    </BasicModal>
  );
};

StyleStoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string,
  onApply: PropTypes.func.isRequired,
};

StyleStoryModal.defaultProps = {
  description: '',
};

export default StyleStoryModal;
