import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Field, Form } from 'react-final-form';
import Button from 'components/ui/Button/Button';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import PropTypes from 'prop-types';
import currentUserService from 'modules/currentUser/currentUserService';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import classes from './MyPoints.module.scss';
import redeemPointsValidator from './redeemPointsValidator';
import { round10 } from './round';

const MyPoints = ({ activities }) => {
  const [redeemModalOpen, setRedeemModalOpen] = useState(false);
  const [stats, setStats] = useState(null);
  const [rewards, setRewards] = useState(null);

  const onRedeemModalClose = useCallback(() => {
    setRedeemModalOpen(false);
  }, []);

  const openRedeemModal = useCallback(() => {
    setRedeemModalOpen(true);
  }, []);

  const handleRedeemPoints = useCallback(
    async (formValues) => {
      const body = {};
      let sum = 0;
      rewards.forEach((reward) => {
        sum += formValues[reward.name] ? Number(formValues[reward.name]) : 0;
        body[reward.name] = {
          name: reward.name,
          value: formValues[reward.name]
            ? Number(formValues[reward.name])
            : null,
        };
      });
      if (!sum) {
        onRedeemModalClose();
        return;
      }
      if (sum > stats.remainingPoints) {
        errorToastr('Error', 'You dont have enough points');
        return;
      }
      try {
        await currentUserService.redeemPoints(body);
        successToastr('Success', "You've successfully redeemed your points");
        const newStats = await currentUserService.getUserStats();
        setStats(newStats);
        onRedeemModalClose();
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [stats, rewards, onRedeemModalClose]
  );

  const modifiedActivities = useMemo(() => {
    const seen = {};
    return activities.map((item) => {
      if (seen[item.activity_id]) {
        return { ...item, activity_name: '' };
      }
      seen[item.activity_id] = true;
      return item;
    });
  }, [activities]);

  useEffect(() => {
    currentUserService.getUserStats().then(setStats);
    currentUserService.getRewards().then(setRewards);
  }, []);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <h1 className={classes.title}>My points</h1>
        <div className="d-flex justify-content-end">
          <Button inline className="mb-3" onClick={openRedeemModal}>
            Redeem Points
          </Button>
        </div>
      </div>

      <table className={clsx('table', classes.pointsTable)}>
        <thead>
          <tr>
            <td>My activities</td>
            <td>#</td>
            <td>Points Per Activity</td>
            <td>Points*</td>
          </tr>
        </thead>
        <tbody>
          {modifiedActivities.map((activity) => (
            <tr key={activity.activity_id}>
              <td className={classes.activity}>{activity.activity_name}</td>
              <td className={classes.points}>{activity.activity_count}</td>
              <td>
                <div className={classes.pointsPerActivity}>
                  {Number(activity.activity_point_value)}
                </div>
              </td>
              <td className={classes.points}>
                {round10(
                  activity.activity_point_value * activity.activity_count,
                  -1
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 d-flex justify-content-between">
        <p className="primary-color font-semi-bold">
          *Points are earned for Approved images and Look Boards only.
        </p>
        <div className={classes.totalTable}>
          <div className={classes.mainPoints}>
            <div className={classes.pointItem}>
              <span>Total points earned</span>
              <div>{Number(stats?.pointsLeft) || 0}</div>
            </div>
            <div className={classes.pointItem}>
              <span>Remaining points</span>
              <div>{Number(stats?.remainingPoints) || 0}</div>
            </div>
          </div>
          <div className={classes.redeemPoints}>
            <div className={classes.pointItem}>
              <span>Points Redeemed</span>
              <div>{Number(stats?.pointsRedeemed) || 0}</div>
            </div>
            <div className={classes.pointItem}>
              <span>Value of Redeemed Points</span>
              <div>${Number(stats?.moneyRedeemed) || 0}</div>
            </div>
          </div>
        </div>
      </div>

      <BasicModal
        open={redeemModalOpen}
        onClose={onRedeemModalClose}
        fullWidth
        maxWidth="md"
        classes={{ root: classes.redeemModal }}
      >
        <h3>Redeem Points</h3>
        <span>
          Thank you being a part of decorating the world with Pin the Look!
        </span>

        <div className={classes.available}>
          Available Redemption Points:{' '}
          <span>{Number(stats?.remainingPoints) || 0}</span>
        </div>

        <div className="d-flex justify-content-between">
          <Form
            validate={redeemPointsValidator}
            onSubmit={handleRedeemPoints}
            render={({ handleSubmit, errors }) => (
              <>
                <form onSubmit={handleSubmit} className="w-100" noValidate>
                  <div className="d-flex">
                    <div className={classes.tableModal}>
                      <div className={classes.tableTitle}>
                        Enter points to redeem in increments of 100:
                      </div>
                      {rewards.map((reward) => (
                        <div
                          className="d-flex justify-content-between align-items-center mb-2"
                          key={reward.id}
                        >
                          <span>{reward.name}</span>
                          <Field
                            name={reward.name}
                            className={classes.input}
                            component={TextInputWrapper}
                            type="number"
                            InputProps={{
                              className: errors[reward.name] && classes.error,
                            }}
                          />
                        </div>
                      ))}
                    </div>
                    <div
                      className={clsx(
                        classes.tableModal,
                        classes.tableModalSecond
                      )}
                    >
                      <div className={classes.tableTitle}>100 Points Value</div>
                      {rewards.map((reward) => (
                        <div className={classes.pointsValue} key={reward.id}>
                          <span>{reward.name}</span>
                          <div>${Number(reward.cost)}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-center">
                    <FinalFormSubmitButton size="lg" inline>
                      Submit
                    </FinalFormSubmitButton>
                  </div>
                </form>
              </>
            )}
          />
        </div>
      </BasicModal>
    </>
  );
};

MyPoints.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      activity_id: PropTypes.number,
      activity_name: PropTypes.string,
      activity_count: PropTypes.number,
      activity_point_value: PropTypes.string,
    })
  ).isRequired,
};

export default MyPoints;
