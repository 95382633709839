import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getInspirationImgUrl,
  getRoomTypeName,
  getStyleNames,
} from 'modules/app/store/selectors';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';
import buildResourceUrl from 'utils/buildResourceUrl';
import useItemClassesByIds from 'modules/app/hooks/useItemClassesByIds';

const RequestDetailsModal = ({ open, onClose }) => {
  const {
    lookBoardData: { inspirationImageId },
    requestInfo: data,
  } = useSelector((state) => state.curateTheLook);
  const library = useSelector((state) => state.inspirationImage.library);
  const { enums, config } = useSelector((state) => state.app);
  const styles = useMemo(
    () => getStyleNames(library[inspirationImageId]?.styles ?? [], enums),
    [enums, inspirationImageId, library]
  );
  const requestImage = useMemo(() => library[inspirationImageId], [
    inspirationImageId,
    library,
  ]);
  const inspirationImageUrl = useMemo(() => getInspirationImgUrl(config), [
    config,
  ]);
  const roomType = useMemo(
    () => getRoomTypeName(library[inspirationImageId]?.roomTypeId, enums),
    [enums, inspirationImageId, library]
  );

  const itemClassesList = useItemClassesByIds(data?.priorityItems);
  const itemType = useMemo(
    () => (requestImage.type === imageTypeKeys.room ? 'Room' : 'Item'),
    [requestImage]
  );

  const imgUrl = useMemo(
    () =>
      buildResourceUrl(
        inspirationImageUrl.medium,
        requestImage.media.userId,
        requestImage.media.hash
      ),
    [inspirationImageUrl, requestImage]
  );
  return (
    <BasicModal
      classes={{ root: 'rounded-0 p-0' }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <div className="d-flex">
        <ImageDetails
          imgUrl={imgUrl}
          url={requestImage.url}
          title={requestImage.title}
        />
        <DetailsPreview
          title={data?.title}
          requestType={requestImage.type}
          styles={styles}
          priorityItems={itemClassesList}
          itemType={itemType}
          roomType={roomType}
          message={data?.message}
        />
      </div>
    </BasicModal>
  );
};

RequestDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RequestDetailsModal;
