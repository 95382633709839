export const requestSourceKeys = {
  my: 'my',
  pending: 'pending',
  completed: 'completed',
};

export const requestTypeKeys = {
  all: 'all',
  room: 'room',
  single: 'single',
};

export const requestSortKeys = {
  newest: 'newest',
  oldest: 'oldest',
  rated: 'rated',
};
