import React from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import womanImg from 'assets/img/trade-opportunities-woman.png';
import Button from 'components/ui/Button/Button';
import classes from './TradeOpportunitiesBanner.module.scss';

const TradeOpportunitiesBanner = () => (
  <div className={classes.root}>
    <div className={classes.grayPolygon} />
    <div className={classes.borderedPolygon} />
    <div className={classes.content}>
      <p className={classes.title}>
        Home Furnishing Retailers, Manufacturers, Artisans & Wholesalers, please
        click here to learn about Trade Opportunities
      </p>
      <Link to={routesByName.tradeOpportunities}>
        <Button inline color="secondary">
          Trade Opportunities
        </Button>
      </Link>
    </div>
    <img src={womanImg} alt="" />
  </div>
);

export default TradeOpportunitiesBanner;
