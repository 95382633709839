import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import { getProductImgUrl } from 'modules/app/store/selectors';
import { productShape } from 'modules/product/propTypes';
import { productTypeKeys } from 'modules/product/constants';
import buildResourceUrl from 'utils/buildResourceUrl';
import findObjectById from 'utils/findObjectById';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ConfirmModal from 'components/modals/ConfirmModal';
import { approvalStatusKeys } from 'modules/inspirationImage/constants';
import RejectedOverlay from 'modules/dashboard/myImages/components/AddedImageItem/RejectedOverlay';
import productThumbnailClasses from 'components/ProductThumbnail/ProductThumbnail.module.scss';
import classes from './MyProductItem.module.scss';
import infoToastr from '../../../../../libs/toastr/infoToastr';

const MyProductItem = ({
  currentType,
  product: {
    id,
    name,
    price,
    media,
    approval,
    rejectedReason,
    inLookBoards,
    createdAt,
  },
  onDelete,
  onEdit,
  onUnlike,
  productImgUrl,
  itemClasses,
  itemClassId,
}) => {
  const itemClass = useMemo(
    () => findObjectById(itemClassId, itemClasses)?.singular,
    [itemClassId, itemClasses]
  );

  const [productType] = useState(currentType);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const imgUrl = useMemo(
    () => buildResourceUrl(productImgUrl.medium, media.userId, media.hash),
    [media, productImgUrl]
  );

  const createdAtDate = useMemo(
    () => moment.unix(createdAt).format('MMM D, YYYY'),
    [createdAt]
  );

  const handleConfirmModalOpen = useCallback(() => {
    setConfirmModalOpen(true);
  }, []);

  const handleDeleteProduct = useCallback(
    (confirm) => {
      if (inLookBoards.length) {
        infoToastr(
          'Warning',
          'You cannot delete product that has already used in the lookboards '
        );
        setConfirmModalOpen(false);
        return;
      }
      if (confirm) {
        onDelete(id);
      }
      setConfirmModalOpen(false);
    },
    [inLookBoards.length, onDelete, id]
  );

  const handleUnlike = useCallback(() => {
    onUnlike(id);
  }, [onUnlike, id]);

  const handleEditProduct = useCallback(() => {
    onEdit(id);
  }, [onEdit, id]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.imgContainer}>
          <img alt={name} draggable="false" src={imgUrl} />
        </div>
        <div className="p-1">
          <div className="d-flex justify-content-between mb-1">
            <div className="text-gray text-ellipsis mr-2">
              <p
                title={name}
                className={`${productThumbnailClasses.productName} text-ellipsis text-sm font-semi-bold mb-1`}
              >
                {name}
              </p>
              <i className="text-xs text-ellipsis text-nowrap">{itemClass}</i>
            </div>
            <span className="primary-color text-sm font-semi-bold">
              {price ? `$${price}` : 'N/A'}
            </span>
          </div>
          <div className="text-sm text-gray text-capitalize">{approval}</div>
          <div className="text-sm text-gray text-capitalize">
            {createdAtDate}
          </div>
          <div className="d-flex justify-content-end">
            {productType === productTypeKeys.my ? (
              <IconButton
                size="sm"
                variant="inverted-grey"
                onClick={handleConfirmModalOpen}
              >
                <SpriteIcon name="trash" size="sm" />
              </IconButton>
            ) : (
              <IconButton
                size="sm"
                variant="inverted-grey"
                onClick={handleUnlike}
              >
                <SpriteIcon name="like-filled" size="sm" />
              </IconButton>
            )}
          </div>
        </div>
        {approval === approvalStatusKeys.rejected && (
          <div className={classes.staticOverlay}>
            <RejectedOverlay
              product
              onEdit={handleEditProduct}
              onDelete={handleConfirmModalOpen}
              reason={rejectedReason}
            />
          </div>
        )}
      </div>
      <ConfirmModal
        open={confirmModalOpen}
        onClose={handleDeleteProduct}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">delete</span> product?
          </>
        }
      />
    </>
  );
};

MyProductItem.propTypes = {
  currentType: PropTypes.string.isRequired,
  itemClassId: PropTypes.string.isRequired,
  product: productShape.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onUnlike: PropTypes.func.isRequired,
  productImgUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  itemClasses: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (
  {
    app: {
      config,
      enums: { itemClasses },
    },
  },
  { product: { itemClassId } }
) => ({
  productImgUrl: getProductImgUrl(config),
  itemClassId,
  itemClasses,
});

export default connect(mapStateToProps)(MyProductItem);
