import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { getRoomTypeName, getStyleNames } from 'modules/app/store/selectors';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { requestSourceKeys } from 'constants/requestSearchParams';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import useItemClassesByIds from 'modules/app/hooks/useItemClassesByIds';
import Button from 'components/ui/Button/Button';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';

const RequestDetailsModal = ({
  open,
  onClose,
  data: {
    requestId,
    requestTitle,
    imgUrl,
    activated,
    requestImage: { id, type, url, title },
    priorityItems,
    message,
    requesterFirstName,
    requesterLastName,
    requesterDisplayName,
    requesterImage,
  },
  styles,
  currentSource,
  onCurate,
  // onEdit,
  onDelete,
  roomType,
  // onMessage,
}) => {
  const priorityItemsList = useItemClassesByIds(priorityItems);

  const itemType = useMemo(
    () => (type === imageTypeKeys.room ? 'Room' : 'Item'),
    [type]
  );

  const inProgress = useMemo(
    () => !activated && currentSource === requestSourceKeys.my,
    [activated, currentSource]
  );

  const showActionButtons = useMemo(
    () => currentSource !== requestSourceKeys.completed,
    [currentSource]
  );

  const handleOnCurate = useCallback(() => {
    onCurate({
      imageId: id,
      requestId,
      message,
    });
  }, [id, onCurate, requestId, message]);

  // const handleEdit = useCallback(() => {
  //   onEdit({
  //     title: requestTitle,
  //     items: priorityItemsList,
  //     requestType: type,
  //     inspirationImageId: id,
  //   });
  // }, [onEdit, requestTitle, priorityItemsList, type, id]);

  return (
    <BasicModal
      classes={{ root: 'rounded-0 p-0' }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <div className="d-flex">
        <ImageDetails imgUrl={imgUrl} url={url} title={title} showInfo />
        <DetailsPreview
          title={requestTitle}
          requestType={type}
          styles={styles}
          priorityItems={priorityItemsList}
          itemType={itemType}
          roomType={roomType}
          message={message}
          requesterFirstName={requesterFirstName}
          requesterLastName={requesterLastName}
          requesterDisplayName={requesterDisplayName}
          requesterImage={requesterImage}
        >
          <div className="d-flex align-items-center mt-auto">
            {currentSource === requestSourceKeys.pending ? (
              <Button
                onClick={handleOnCurate}
                className="text-uppercase mr-1"
                inline
                size="sm"
              >
                Curate The Look
              </Button>
            ) : (
              Boolean(activated) && (
                <Link
                  to={routesByName.requestTheLook.recommendations(requestId)}
                >
                  <Button className="text-uppercase mr-1" inline size="sm">
                    View Recommendations
                  </Button>
                </Link>
              )
            )}
            {!inProgress && currentSource === requestSourceKeys.my && (
              <>
                {
                  // <IconButton
                  //  onClick = {onMessage}
                  //  className="mr-1"
                  //  variant="inverted-grey"
                  //  >
                  //  <SpriteIcon name="envelope" size="sm" />
                  //  </IconButton>
                  // <IconButton
                  //   onClick={handleEdit}
                  //   className="mr-1"
                  //   variant="inverted-grey"
                  // >
                  //   <SpriteIcon name="edit" size="sm" />
                  // </IconButton>
                }
              </>
            )}
            {!inProgress && showActionButtons && (
              <IconButton
                onClick={onDelete}
                data-id={requestId}
                className="mr-1"
                variant="inverted-grey"
              >
                <SpriteIcon name="trash" size="sm" />
              </IconButton>
            )}
          </div>
        </DetailsPreview>
      </div>
    </BasicModal>
  );
};

RequestDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    requestId: PropTypes.number,
    imgUrl: PropTypes.string,
    requestTitle: PropTypes.string,
    activated: PropTypes.number,
    requestImage: inspirationImageShape,
    priorityItems: PropTypes.arrayOf(PropTypes.number),
    message: PropTypes.string,
    requesterFirstName: PropTypes.string,
    requesterLastName: PropTypes.string,
    requesterDisplayName: PropTypes.string,
    requesterImage: PropTypes.string,
  }).isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentSource: PropTypes.string.isRequired,
  onCurate: PropTypes.func.isRequired,
  // onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  // onMessage: PropTypes.func.isRequired,
  roomType: PropTypes.string,
};

RequestDetailsModal.defaultProps = {
  roomType: null,
  // onMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ app: { enums } }, { data: { requestImage } }) => ({
  styles: getStyleNames(requestImage?.styles ?? [], enums),
  roomType: getRoomTypeName(requestImage.roomTypeId, enums),
});

export default connect(mapStateToProps)(RequestDetailsModal);
