import { changePasswordFormFields } from 'modules/account/constants';
import { errorMessages } from 'modules/auth/signUp/constants';

const changePasswordValidator = ({
  [changePasswordFormFields.currentPassword]: currentPassword,
  [changePasswordFormFields.newPassword]: newPassword,
  [changePasswordFormFields.confirmNewPassword]: confirmNewPassword,
}) => {
  const errors = {};
  function hasNumber(usersPassword) {
    return /\d/.test(usersPassword);
  }

  if (!currentPassword) {
    errors.currentPassword = errorMessages.required;
  }
  if (!newPassword) {
    errors.newPassword = errorMessages.required;
  } else if (newPassword.length < 8) {
    errors.newPassword = errorMessages.passwordInvalid;
  } else if (!hasNumber(newPassword)) {
    errors.newPassword = errorMessages.passwordWithoutNumber;
  }

  if (!confirmNewPassword) {
    errors.confirmNewPassword = errorMessages.required;
  } else if (confirmNewPassword.length < 8) {
    errors.confirmNewPassword = errorMessages.passwordInvalid;
  }

  if (newPassword !== confirmNewPassword) {
    errors.confirmNewPassword = errorMessages.passwordsShouldMatch;
  }

  return errors;
};

export default changePasswordValidator;
