import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import { toggleHowItWorksVideoModalAction } from 'modules/layout/store/actions';

import classes from './ButtonHowItWorks.module.scss';

const ButtonHowItWorks = ({ text, route, onClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleHowItWorksVideoModalOpen = () => {
    if (onClick) {
      onClick();
      return;
    }
    dispatch(toggleHowItWorksVideoModalAction(true));
  };
  const handleOpenRoute = () => {
    history.push(route);
  };

  return (
    <Button
      inline
      className={classes.howItWorksBtn}
      onClick={route ? handleOpenRoute : handleHowItWorksVideoModalOpen}
      size="sm"
      variant="outlined"
    >
      <p>{text}</p>
      HOW IT WORKS
      <SpriteIcon name="play-btn-filled" size="lg" className="ml-2" />
    </Button>
  );
};

ButtonHowItWorks.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  route: PropTypes.string,
};

ButtonHowItWorks.defaultProps = {
  route: null,
  onClick: null,
};

export default ButtonHowItWorks;
