import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import GalleryItem from 'modules/getTheLook/components/GalleryItem';
import showcaseClasses from 'modules/dashboard/showcase/Showcase.module.scss';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';

const FeaturedImagesSlider = ({ slidesToShow, featuredImages, noArrows }) => {
  const imageMap = useSelector((state) => state.inspirationImage.library);
  const sliderRef = useRef(null);
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleSlideChange = useCallback((oldIndex, newIndex) => {
    setActiveSlideIndex(newIndex);
  }, []);

  return (
    <div className={showcaseClasses.featuredImageSliderContainer}>
      {!noArrows && (
        <IconButton
          size="sm"
          onClick={handleSlidePrev}
          className={showcaseClasses.imagesPrevBtn}
          disabled={activeSlideIndex === 0}
        >
          <SpriteIcon name="prev-icon" size="xs" />
        </IconButton>
      )}
      <Slider
        className={showcaseClasses.featuredImageSlider}
        ref={sliderRef}
        arrows={false}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        beforeChange={handleSlideChange}
        infinite={false}
        dots={matchesMediaQuery}
        customPaging={(i) => <SpriteIcon name="dot" size="xs" key={i} />}
      >
        {featuredImages.map((imageId) => (
          <div key={imageId} className="px-1">
            {imageMap[imageId] ? (
              <GalleryItem image={imageMap[imageId]} isShowcase />
            ) : (
              'Loading...'
            )}
          </div>
        ))}
      </Slider>
      {!noArrows && (
        <IconButton
          size="sm"
          onClick={handleSlideNext}
          className={showcaseClasses.imagesNextBtn}
          disabled={
            matchesMediaQuery
              ? activeSlideIndex === featuredImages.length - 1
              : activeSlideIndex === featuredImages.length - 4
          }
        >
          <SpriteIcon name="next-icon" size="xs" />
        </IconButton>
      )}
    </div>
  );
};

FeaturedImagesSlider.propTypes = {
  slidesToShow: PropTypes.number.isRequired,
  featuredImages: PropTypes.arrayOf(PropTypes.number).isRequired,
  noArrows: PropTypes.bool,
};

FeaturedImagesSlider.defaultProps = {
  noArrows: false,
};
export default FeaturedImagesSlider;
