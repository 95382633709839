import React from 'react';
import PropTypes from 'prop-types';
import { recommendationsTabKeys } from 'modules/requestTheLook/recommendations/constants';
import LookBoardsList from 'modules/requestTheLook/recommendations/components/LookBoardsList/LookBoardsList';
import MixAndMatchView from 'modules/getTheLook/components/MixAndMatchView';
import ProductsStream from 'modules/getTheLook/components/ProductsStream';
import classes from 'modules/requestTheLook/recommendations/Recommendations.module.scss';

const RecommendationsComponent = ({
  lookBoards,
  products,
  users,
  handleToggleLikeLookBoard,
  handleToggleLikeProduct,
  onRateLookBoard,
  onRateProduct,
  activeTab,
  onSaveMixMatch,
  handleOpenHowToViewModal,
  handleOpenMessageModal,
}) =>
  lookBoards.length > 0 ? (
    <>
      <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
        <p className="font-italic font-semi-bold">
          Personalized Recommendations:
        </p>
        <button
          className={`${classes.detailBtn} primary-color text-sm link-underline cursor-pointer`}
          onClick={handleOpenHowToViewModal}
          type="button"
        >
          How to View Recommendations
        </button>
      </div>
      {activeTab === recommendationsTabKeys.mixAndMatch && (
        <MixAndMatchView
          products={products}
          onSave={onSaveMixMatch}
          onRateProduct={onRateProduct}
        />
      )}
      {activeTab === recommendationsTabKeys.lookBoardView && (
        <>
          <LookBoardsList
            lookBoards={lookBoards}
            products={products}
            users={users}
            onToggleLike={handleToggleLikeLookBoard}
            onRate={onRateLookBoard}
            handleOpenMessageModal={handleOpenMessageModal}
          />
        </>
      )}
      {activeTab === recommendationsTabKeys.productStream && (
        <div className="mt-3">
          <ProductsStream
            products={products}
            users={users}
            onToggleLikeProduct={handleToggleLikeProduct}
            onRateProduct={onRateProduct}
            withRating
          />
        </div>
      )}
    </>
  ) : (
    <div className="flex-fill flex-center font-title text-gray text-lg">
      List is Empty
    </div>
  );

RecommendationsComponent.propTypes = {
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.shape({}),
  users: PropTypes.shape({}),
  handleToggleLikeLookBoard: PropTypes.func.isRequired,
  handleToggleLikeProduct: PropTypes.func.isRequired,
  onRateLookBoard: PropTypes.func.isRequired,
  onRateProduct: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  onSaveMixMatch: PropTypes.func.isRequired,
  handleOpenHowToViewModal: PropTypes.func.isRequired,
  handleOpenMessageModal: PropTypes.func.isRequired,
};

RecommendationsComponent.defaultProps = {
  lookBoards: null,
  products: null,
  users: null,
};

export default RecommendationsComponent;
