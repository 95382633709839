import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import SpriteIcon from 'components/ui/SpriteIcon';
import supportService from 'modules/support/supportService';
import { supportFormFields, supportFormTypes } from 'modules/support/constants';
import SupportForm from 'modules/support/supportForm/SupportForm';
import bannerImg from 'assets/img/contact-page/contact-banner.png';
import Button from 'components/ui/Button/Button';
import { toggleHowItWorksModalAction } from 'modules/layout/store/actions';
import faqsPageClasses from 'modules/faqs/Faqs.module.scss';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import classes from './Contact.module.scss';

const ContactContainer = () => {
  const dispatch = useDispatch();
  const { firstName, lastName, email: userEmail } = useSelector(
    (state) => state.auth.user ?? {}
  );
  const userName = useMemo(
    () => (firstName ? `${firstName} ${lastName}` : null),
    [firstName, lastName]
  );

  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleSendContactMessage = useCallback(
    async (formValues, form) => {
      try {
        let reCaptchaToken;
        if (!executeRecaptcha) {
          reCaptchaToken = null;
        }
        reCaptchaToken = await executeRecaptcha(
          reCaptchaActions.customerSupport
        );
        await supportService.sendSupportMessage({
          [supportFormFields.type]: supportFormTypes.contact,
          ...formValues,
          reCaptchaToken,
        });

        successToastr('Success', 'Message successfully sent');

        // reset form
        Object.keys(formValues).forEach((key) => {
          form.change(key, undefined);
          form.resetFieldState(key);
        });
      } catch (e) {
        errorToastr(e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [executeRecaptcha]
  );

  const handleHowItWorksModalOpen = useCallback(() => {
    dispatch(toggleHowItWorksModalAction(true));
  }, [dispatch]);

  return (
    <section className={classes.root}>
      <div className={classes.container}>
        <div className={classes.panel}>
          <div className={classes.panelLine} />
          <Button
            className={`${faqsPageClasses.btn} d-none`}
            onClick={handleHowItWorksModalOpen}
            size="custom"
            color="default"
          >
            <SpriteIcon name="play-icon" size="lg" />
            <span className="ml-1">
              How &nbsp;<b>It Works</b>
            </span>
          </Button>
        </div>
        <div className="d-flex">
          <div className={classes.formContainer}>
            <h1 className={classes.title}>Contact us</h1>
            <SupportForm
              userName={userName}
              userEmail={userEmail}
              onSubmit={handleSendContactMessage}
              matchesMediaQuery={matchesMediaQuery}
            />
          </div>
          <div className={classes.imgContainer}>
            <img src={bannerImg} alt="" />
            <div className={classes.banner}>
              <div className="position-relative text-center mb-1">
                <span className={classes.bannerTitle}>Pin The Look</span>
                <div className={classes.crossline} />
              </div>
              <p className={classes.bannerSubtitle}>
                Let’s Decorate the World{' '}
                <span className="primary-color">Together</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactContainer;
