import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from 'modules/dashboard/showcase/components/BannerModal/BannerModal.module.scss';

const BannerColor = ({ color, active, onChange }) => {
  const handleChange = useCallback(() => {
    onChange(color);
  }, [onChange, color]);

  return (
    <div
      className={clsx(classes.bannerImageContainer, {
        [classes.active]: active,
      })}
      onClick={handleChange}
    >
      <div
        className={classes.colorPlaceholder}
        style={{ backgroundColor: color }}
      />
    </div>
  );
};

BannerColor.propTypes = {
  color: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BannerColor;
