import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import roomImg from 'assets/img/request-the-look-page/room.jpg';
import makeoverImage from 'assets/img/request-the-look-page/makeoverImage.png';
import lampImg from 'assets/img/request-the-look-page/lamp.jpg';

import requestPreviewImg from 'assets/img/home-page/sections-preview/request.svg';
import curatePreviewImg from 'assets/img/home-page/sections-preview/curate.svg';
import getPreviewImg from 'assets/img/home-page/sections-preview/get.svg';
import shopPreviewImg from 'assets/img/home-page/sections-preview/shop.svg';

import midChair from 'assets/img/home-page/furniture/chair-7.png';
import midTable from 'assets/img/home-page/furniture/table-6.png';
import midStool from 'assets/img/home-page/furniture/stool-5.png';
import midSofa from 'assets/img/home-page/furniture/sofa-5.png';

import centuryChair from 'assets/img/home-page/furniture/chair-5.png';
import centuryStool from 'assets/img/home-page/furniture/stool-2.png';
import centurySofa from 'assets/img/home-page/furniture/sofa-6.png';

export const infoMenuConfig = {
  title: 'Request the Look',
  description: 'Inspiration Database',
  items: [
    {
      id: 1,
      title: 'Inspiration Images Database',
      description:
        'Browse through popular Inspiration Images to use in Requesting the Look. Use the Search feature to pull up images by keywords or use the Filter feature to narrow your search by room type, style, and color. You can also click on the Pin icon to add a new Inspiration Image from your computer or the Web for your request.',
      icon: 'living-room',
      buttonLabel: 'All Images',
    },
    {
      id: 2,
      title: 'My Saved Images & Favorites',
      description:
        'In this section, you can select an Inspiration Image to use in requesting the look from the ones you have personally added or “liked”. Simply click on the My Added Images tab to select one that is saved in your dashboard. You can also click on the Add New Image tab to add a new image from your computer or the Web for your request.',
      icon: 'pictures',
      buttonLabel: 'My Added Images',
    },
  ],
};

export const imageTypeOptions = [
  {
    value: imageSourceKeys.my,
    label: 'My Added Images',
  },
  {
    value: imageSourceKeys.liked,
    label: 'My Liked Images',
  },
];
export const subTypeImageOptions = [
  {
    value: imageSourceKeys.all,
    label: 'All Images',
  },
  {
    value: imageSourceKeys.liked,
    label: 'Liked Images',
  },
];

export const imageSearchRadioOptions = [
  { value: imageTypeKeys.room, label: 'Room Images' },
  { value: imageTypeKeys.single, label: 'Single Items' },
];

export const requestTypes = {
  [imageTypeKeys.room]: 'Room Image',
  [imageTypeKeys.makeOver]: 'Makeover ideas',
  [imageTypeKeys.single]: 'Single Item',
};

export const requestTypesTitles = {
  [imageTypeKeys.room]: 'Get The Look: Room Image',
  [imageTypeKeys.makeOver]: 'Makeover ideas: Select Replacement Items',
};

export const requestTheLookTypes = {
  [imageTypeKeys.room]: {
    title: 'Request the Look of a Room Image',
    description: 'Get Look Boards with similar items to your image',
    imgUrl: roomImg,
  },

  [imageTypeKeys.single]: {
    title: 'Request the Look of a Single Item',
    description: 'Get Look Boards with similar items to your image',
    imgUrl: lampImg,
  },

  [imageTypeKeys.makeOver]: {
    title: 'Request Makeover Ideas for a Room',
    description: 'Get Ideas to Makeover a Room in Your Home',
    imgUrl: makeoverImage,
  },
};

export const requestSuccessBannerConfig = [
  {
    id: 1,
    title: 'Your Request Will Be Matched with Ambassadors',
    imgUrl: requestPreviewImg,
  },
  {
    id: 2,
    title: 'Ambassadors will Curate the Look',
    imgUrl: curatePreviewImg,
  },
  {
    id: 3,
    title: 'Look Board Recommendations will be Sent',
    imgUrl: getPreviewImg,
  },
  {
    id: 4,
    title: 'You can Review & Rate the Best Matches',
    imgUrl: shopPreviewImg,
  },
];

export const requestSuccessExamplesConfig = [
  {
    id: 1,
    title: 'Mid Century Living',
    images: [
      {
        id: 1,
        url: midChair,
      },
      {
        id: 2,
        url: midTable,
      },
      {
        id: 3,
        url: midStool,
      },
      {
        id: 4,
        url: midSofa,
      },
    ],
  },
  {
    id: 2,
    title: 'Modern Blue Room',
    images: [
      {
        id: 1,
        url: centuryChair,
      },
      {
        id: 2,
        url: midTable,
      },
      {
        id: 3,
        url: centuryStool,
      },
      {
        id: 4,
        url: centurySofa,
      },
    ],
  },
];

export const mobileViewStepsConfig = [
  { number: 1, description: 'Select Image Type' },
  { number: 2, description: 'Select Request Type' },
  { number: 3, description: 'Add Request Details' },
];
