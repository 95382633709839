import React from 'react';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';

const InActiveOverlay = () => (
  <div className="h-100 flex-center position-relative">
    <OverlayLabel
      text='Not Active'
    />
    <div className={classes.bottomControls}>
      <InfoIconPopover
        iconProps={{
          className: 'mr-1',
          variant: 'inverted-grey',
        }}
      >
        <p className="text-center font-normal">
          This image is currently <br/> not active.
          It will change <br/> status immediately after you <br/>
          <span className="primary-color">confirm your email address</span>
        </p>
      </InfoIconPopover>

    </div>
  </div>
);


export default InActiveOverlay;
