import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ItemThumbnail from 'components/lookBoardThumbnails/ItemThumbnail/ItemThumbnail';
import { mediaShape } from 'modules/media/propTypes';
import { routesByName } from 'constants/routes';
import BasicAvatar from 'components/ui/BasicAvatar';
import MatchRating from 'components/MixMatchThumbnail/MatchRating';
import lookBoardClasses from 'components/lookBoardThumbnails/LookBoard/LookBoard.module.scss';
import classes from './LookBoardItem.module.scss';

const LookBoardItem = ({
  lookBoard: {
    id,
    title,
    color,
    isLiked,
    products: productIds,
    averageRating,
    slug,
  },
  user,
  products,
  onToggleLike,
  onRate,
  onPreviewOpen,
  handleOpenMessageModal,
}) => {
  const userAvatarUrl = getUserAvatarUrl(
    useSelector((state) => state.app.config)
  );

  const containerRef = useRef(null);
  const [imgHeight, setImgHeight] = useState('50%');
  const [containerHeight, setContainerHeight] = useState(null);
  const [productList, setProductList] = useState([]);
  const [isRatingHover, setIsRatingHover] = useState(false);

  const history = useHistory();
  const lookBoardProducts = useMemo(
    () => productIds.map((productId) => products[productId]),
    [productIds, products]
  );

  const avatarUrl = useMemo(
    () =>
      user?.avatar &&
      buildResourceUrl(userAvatarUrl, user.avatar.userId, user.avatar.hash),
    [userAvatarUrl, user]
  );

  useEffect(() => {
    if (containerRef.current) {
      const refWidth = containerRef.current.clientWidth;
      const itemHeight =
        lookBoardProducts.length > 1 ? Math.round(refWidth / 2) : refWidth;

      setContainerHeight(refWidth);
      setImgHeight(itemHeight);
    }
  }, [lookBoardProducts]);

  useEffect(() => {
    const list = lookBoardProducts.slice(0, 4);
    switch (list.length) {
      case 2: {
        list.splice(1, 0, { id: uuidv4() }, { id: uuidv4() });
        break;
      }
      case 3: {
        list.push({ id: uuidv4() });
        break;
      }
      default: {
        break;
      }
    }
    setProductList(list);
  }, [lookBoardProducts]);

  const handleToggleLike = useCallback(() => {
    onToggleLike(id, Number(!isLiked));
  }, [onToggleLike, id, isLiked]);

  const handleRatingUpdate = useCallback(
    async (event, newValue) => {
      onRate(id, newValue);
    },
    [onRate, id]
  );

  const handleDetailsClick = useCallback(() => {
    history.push(routesByName.lookBoard.details(id, slug));
  }, [history, id, slug]);

  const handleRatingHover = useCallback(() => {
    setIsRatingHover(true);
  }, []);

  const handleRatingBlur = useCallback(() => {
    setIsRatingHover(false);
  }, []);

  const handleGoToShowcasePage = useCallback(() => {
    if (user?.shareUrl) window.open(user.shareUrl, '_blank');
  }, [user]);

  return (
    <div ref={containerRef} className={classes.root}>
      <div className={classes.hoverArea}>
        <div className={classes.title} style={{ backgroundColor: color }}>
          {title}
        </div>
        <div
          style={{ minHeight: containerHeight }}
          className={clsx(lookBoardClasses.imgContainer, classes.imgContainer)}
        >
          {productList.map(({ id: productId, media, itemClassId }) =>
            media ? (
              <ItemThumbnail
                key={productId}
                id={productId}
                src={media}
                itemClassId={itemClassId}
                className={
                  lookBoardProducts.length > 1
                    ? lookBoardClasses.double
                    : lookBoardClasses.single
                }
                height={imgHeight}
              />
            ) : (
              <div
                key={productId}
                className={clsx(
                  lookBoardClasses.placeholderWrapper,
                  classes.double
                )}
                style={{ height: imgHeight }}
              >
                <div className={lookBoardClasses.placeholder}>
                  <SpriteIcon
                    name="logo"
                    className={lookBoardClasses.placeholderIcon}
                  />
                </div>
              </div>
            )
          )}
        </div>
        <div className={classes.hoverOverlay}>
          <div className="w-100 d-flex justify-content-end">
            <IconButton size="sm" data-id={id} onClick={onPreviewOpen}>
              <SpriteIcon name="loupe" size="sm" />
            </IconButton>
          </div>
          <p onClick={handleDetailsClick} className={classes.hoverLabel}>
            Click to view look board details
          </p>
        </div>
      </div>
      <div
        className="p-1"
        onMouseEnter={handleRatingHover}
        onMouseLeave={handleRatingBlur}
      >
        <MatchRating
          name={String(id)}
          value={Number(averageRating)}
          onChange={handleRatingUpdate}
        />
      </div>
      <div className="position-relative flex-vertical-center justify-content-between p-1">
        <div
          className="flex-vertical-center cursor-pointer flex-grow-1"
          onClick={handleGoToShowcasePage}
        >
          <BasicAvatar
            className={classes.userAvatar}
            firstName={user.firstName}
            lastName={user.lastName}
            src={avatarUrl}
          />
          <p className="text-ellipsis text-xs font-semi-bold">
            {user.displayName ?? `${user.firstName} ${user.lastName}`}
          </p>
        </div>
        <SpriteIcon
          name="send-message"
          size="md"
          className={classes.messageButton}
          onClick={() => handleOpenMessageModal(user)}
        />

        <IconButton
          onClick={handleToggleLike}
          variant="inverted-grey"
          size="xs"
        >
          <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="xs" />
        </IconButton>
        <div
          className={clsx(classes.hoverRating, {
            [classes.hover]: isRatingHover,
          })}
        >
          Please rate how well the item(s) in the LookBoard match the item(s) in
          the Inspiration image
        </div>
      </div>
    </div>
  );
};

LookBoardItem.propTypes = {
  lookBoard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isLiked: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(PropTypes.number).isRequired,
    averageRating: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    shareUrl: PropTypes.string.isRequired,
    avatar: mediaShape,
  }).isRequired,
  products: PropTypes.shape({}).isRequired,
  onToggleLike: PropTypes.func.isRequired,
  onRate: PropTypes.func.isRequired,
  onPreviewOpen: PropTypes.func.isRequired,
  handleOpenMessageModal: PropTypes.func.isRequired,
};

export default LookBoardItem;
