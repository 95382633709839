import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import classes from './Switch.module.scss';

const Switch = ({ name, label, onChange, checked, type, disabled }) => (
  <label
    className={clsx(
      'd-flex flex-nowrap align-items-center py-1',
      classes.label,
      {
        [classes.checked]: checked,
        [classes.disabled]: disabled,
      }
    )}
  >
    <input
      className="d-none"
      type={type}
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <div
      className={clsx(classes.switch, 'mr-2', { 'cursor-pointer': !disabled })}
    >
      <div className={classes.thumb}>
        <CachedRoundedIcon />
      </div>
    </div>
    {label}
  </label>
);

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  disabled: PropTypes.bool.isRequired,
};

Switch.defaultProps = {
  type: 'checkbox',
};

export default Switch;
