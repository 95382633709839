import PropTypes from 'prop-types';

export const filterModalItemShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const filterModalTypeShape = PropTypes.shape({
  name: PropTypes.string,
  items: PropTypes.arrayOf(filterModalItemShape),
});

export const filterModalArrayOf = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    types: PropTypes.arrayOf(filterModalTypeShape),
  })
);
