import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import { typesInfoModalConfig } from 'components/modals/TypesInfoModal/constants';
import classes from './TypesInfoModal.module.scss';

const TypesInfoModal = ({ open, onClose }) => (
  <BasicModal
    open={open}
    onClose={onClose}
    classes={{ root: classes.root }}
    fullWidth
  >
    <h2 className={classes.title}>Look Board Views</h2>
    {typesInfoModalConfig.map(({ id, title, description, imgUrl }) => (
      <div key={id} className={`${classes.row} d-flex`}>
        <div className="col-6">
          <img className="w-100" src={imgUrl} alt={title} />
        </div>
        <div className="col-6">
          <div className="pl-2 pt-1">
            <h4 className="font-semi-bold mb-2">{title}</h4>
            <p className="text-sm" style={{ lineHeight: 1.5 }}>
              {description}
            </p>
          </div>
        </div>
      </div>
    ))}
  </BasicModal>
);

TypesInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TypesInfoModal;
