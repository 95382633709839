import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { productShape } from 'modules/product/propTypes';
import GTLSlider from 'modules/getTheLook/components/GTLSlider';
import useCallbackRef from 'hooks/useCallbackRef';
import ProductItem from 'modules/getTheLook/components/ProductItem';
import RecommendationsProductItem from 'modules/requestTheLook/recommendations/components/RecommendationsProductItem/RecommendationsProductItem';
import classes from './ProductsGroup.module.scss';

const ProductsGroup = ({
  itemClassId,
  groupName,
  products,
  users,
  onToggleLikeProduct,
  onRateProduct,
  onSelectGroup,
  withRating,
}) => {
  const [sliderNode, sliderRef] = useCallbackRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderNode.slickPrev();
  }, [sliderNode]);

  const handleSlideNext = useCallback(() => {
    sliderNode.slickNext();
  }, [sliderNode]);

  const handleViewAll = useCallback(() => {
    onSelectGroup(itemClassId);
  }, [onSelectGroup, itemClassId]);

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <span className="text-sm font-semi-bold mr-5">{groupName}</span>
        <div className={classes.itemClassLine} />
      </div>
      <GTLSlider
        sliderRef={sliderRef}
        slideLength={products.length}
        activeSlideIndex={activeSlideIndex}
        onSlidePrev={handleSlidePrev}
        onSlideNext={handleSlideNext}
        onSlideChange={handleSlideChange}
        onViewAll={handleViewAll}
      >
        {products.map((product) => (
          <div key={product.id} className="px-1 h-100">
            {withRating ? (
              <RecommendationsProductItem
                product={product}
                onToggleLike={onToggleLikeProduct}
                onRate={onRateProduct}
              />
            ) : (
              <ProductItem
                product={{ ...product }}
                user={users[product.userId]}
                onToggleLike={onToggleLikeProduct}
              />
            )}
          </div>
        ))}
      </GTLSlider>
    </>
  );
};

ProductsGroup.propTypes = {
  itemClassId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(productShape).isRequired,
  users: PropTypes.shape({}).isRequired,
  onToggleLikeProduct: PropTypes.func.isRequired,
  onRateProduct: PropTypes.func.isRequired,
  onSelectGroup: PropTypes.func.isRequired,
  withRating: PropTypes.bool.isRequired,
};

export default ProductsGroup;
