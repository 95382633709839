import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleYoutubeVideoModalAction } from 'modules/layout/store/actions';
import { routesByName } from 'constants/routes';
import BasicModal from '../BasicModal/BasicModal';
import classes from './YoutubeVideoModal.module.scss';

const YoutubeVideoModal = ({ videoUrl }) => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.layout.youtubeVideoModalOpen);
  const user = useSelector((state) => state.auth.user);

  const history = useHistory();

  const handleCloseModal = useCallback(() => {
    dispatch(toggleYoutubeVideoModalAction(false));
    if (!user) {
      const redirectUrl = `?${routesByName.auth.key}=${routesByName.auth.signUp}`;
      history.push(redirectUrl);
    }
  }, [dispatch, history, user]);

  const getYouTubeVideoId = useCallback((url) => {
    const regExp = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=))([^&?]+)?/;
    const match = url.match(regExp);
    if (match && match[1]) {
      return match[1];
    }
    return null;
  }, []);

  return (
    <BasicModal
      open={open}
      onClose={handleCloseModal}
      fullWidth
      classes={{ root: classes.root, closeBtn: classes.closeBtn }}
      scroll="body"
    >
      {videoUrl && getYouTubeVideoId(videoUrl) ? (
        <iframe
          className={classes.iframe}
          src={`https://www.youtube.com/embed/${getYouTubeVideoId(videoUrl)}`}
          allowFullScreen
          title="YouTube Video"
        />
      ) : (
        <div className="d-flex justify-content-center">
          Video cannot be played
        </div>
      )}
    </BasicModal>
  );
};

YoutubeVideoModal.propTypes = {
  videoUrl: PropTypes.string,
};

YoutubeVideoModal.defaultProps = {
  videoUrl: null,
};

export default YoutubeVideoModal;
