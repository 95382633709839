import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import SpriteIcon from 'components/ui/SpriteIcon';

const useStyles = makeStyles(({ palette, spacing }) => ({
  profileAvatar: {
    width: spacing(12),
    height: spacing(12),
    marginRight: spacing(2.5),
    backgroundColor: palette.grey[2],
    color: palette.grey[3],
  },
  uploadText: {
    fontWeight: 600,
    fontSize: 14,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const AmbassadorPhotoUpload = ({ avatarUrl, onChange }) => {
  const classes = useStyles();

  return (
    <label className="d-inline-flex align-items-center cursor-pointer">
      <input type="file" className="d-none" onChange={onChange} />
      <Avatar src={avatarUrl} className={classes.profileAvatar}>
        <SpriteIcon name="file-upload" size="lg" />
      </Avatar>
      <div className={classes.textWrapper}>
        <p className={classes.uploadText}>Upload Your Photo</p>
        <p className="text-color text-xs">JPG or PNG; 4 MB Max</p>
        <p className="text-xs">Your initials will show by default</p>
      </div>
    </label>
  );
};

AmbassadorPhotoUpload.propTypes = {
  avatarUrl: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

AmbassadorPhotoUpload.defaultProps = {
  avatarUrl: null,
};

export default AmbassadorPhotoUpload;
