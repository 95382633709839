import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import affiliateProgram from 'assets/img/ambassador-page/affiliate-program.jpg';
import step1 from 'assets/img/ambassador-page/step-image-1.png';
import step2 from 'assets/img/ambassador-page/step-image-2.png';
import step3 from 'assets/img/ambassador-page/step-image-3.png';
import promoteSocials from 'assets/img/ambassador-page/ambassador-steps/promote-socials-2.png';

import classes from './AmbassadorManagement.module.scss';
import SpriteIcon from '../../../components/ui/SpriteIcon';
import Button from '../../../components/ui/Button/Button';
import { routesByName } from '../../../constants/routes';

const followStepsConfig = [
  {
    title: 'ACTIVATE SHOWCASE',
    description1:
      'Once you have added five “approved” inspiration images and Look Boards, you can activate your showcase page, which will lead to an invitation to join our Affiliate Program.',
    buttonLabel: 'Ambassador Showcase',
    buttonUrl: routesByName.dashboard.ambassadorShowcase,
    icon: 'reload',
  },
  {
    title: 'SIGN UP AS AN AFFILIATE',
    description1: 'Start by registering on',
    description2:
      'then click the AFFILIATES button and register as an Affiliate.',
    buttonLabel: 'MyLookShops.com Affiliates',
    buttonUrl: 'https://af.uppromote.com/lookshops-9536/register',
    isExternal: true,
    icon: 'exit',
  },
  {
    title: 'ADD AFFILIATE CODE TO PIN THE LOOK',
    description1:
      'After being approved as an Affiliate, go to the Affiliate dashboard in',
    description2:
      'and copy and paste your Affiliate Link into your Pin the Look profile. This will add your Affiliate Link to',
    description3: 'products in your Look Boards.',
    buttonLabel: 'Pin the Look Profile',
    buttonUrl: routesByName.account.profile,
    icon: 'edit-profile',
  },
];

const AmbassadorProgram = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const handleLinkOpen = (buttonUrl, isExternal, step) => {
    if (isExternal) {
      window.open(buttonUrl, '_blank');
    } else if (step) {
      history.push({
        pathname: buttonUrl,
        search: `?videoUrl=${step}`,
      });
    } else {
      history.push(buttonUrl);
    }
  };
  const handleOpenImageLink = (link, isExternal) => {
    if (isExternal) {
      window.open(link, '_blank');
    } else {
      history.push(link);
    }
  };

  return (
    <>
      <section className={classes.root}>
        <div className={classes.banner}>
          <div className="d-flex align-items-center">
            <h2 className="ml-1 text-md text-uppercase font-semi-bold">
              AFFILIATE PROGRAMS
            </h2>
          </div>
        </div>
        <div className={classes.mainWrapper}>
          <div className={classes.content}>
            <div className={classes.imageWrapper}>
              <img src={affiliateProgram} alt="affiliateProgram" />
            </div>
            <div className={clsx(classes.contentBlock, classes.rightSide)}>
              <h2 className={classes.contentTitle}>
                JOIN OUR AFFILIATE PROGRAM
              </h2>
              <p className="font-italic mb-3">
                As an active Ambassador, you will have the opportunity to join
                our Affiliate program!
              </p>
              <p className={classes.contentDescription}>
                Once you activate your Showcase on Pin the Look, you will be
                invited to become an Affiliate on Pin the Look’s e-commerce
                store,
                <a
                  href="https://mylookshops.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  MyLookShops.com
                </a>
                . As an Affiliate, you can earn a commission when someone
                purchases a
                <a
                  href="https://mylookshops.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {' '}
                  MyLookShops.com{' '}
                </a>
                item after clicking on it from one of your Look Boards.
              </p>
              <p className={classes.contentDescription}>
                Over time, Pin the Look will add more Affiliate Partners
                specializing in unique products and styles. These partners will
                directly invite ambassadors to join their affiliate programs
                based on an Ambassadors’ showcase theme, number of Followers,
                and activity level on Pin the Look.
              </p>
              <h5 className={classes.listTitle}>
                Becoming an Affiliate is a great way to turn your passion for
                decorating into a lucrative side gig!
              </h5>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.contentBlock}>
              <h2 className={classes.contentTitle}>AFFILIATE SETUP STEPS</h2>
              <h3 className={classes.contentBoldTitle}>
                Follow these steps to become an affiliate:
              </h3>
              {followStepsConfig.map((item) => (
                <div className="d-flex align-items-start mb-4">
                  <div className={classes.iconWrapper}>
                    <SpriteIcon name={item.icon} size="xl" />
                  </div>
                  <div className={classes.stepText}>
                    <h4 className={classes.stepToBecomeTitle}>{item.title}</h4>
                    <p className={classes.stepToBecomeDesc}>
                      {item.description1}
                      {item.description2 && (
                        <a
                          href="https://MyLookShops.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          MyLookShops.com{' '}
                        </a>
                      )}
                      {item.description2}
                      {item.description3 && (
                        <a
                          href="https://MyLookShops.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          MyLookShops.com{' '}
                        </a>
                      )}
                      {item.description3}
                    </p>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="xs"
                      className={classes.stepToBecomeBtn}
                      onClick={() =>
                        handleLinkOpen(item.buttonUrl, item.isExternal)
                      }
                    >
                      {item.buttonLabel}
                    </Button>
                  </div>
                </div>
              ))}
              <h5 className={classes.listTitle}>
                Once you have followed these steps, you’ll be ready to curate
                Look Boards with Affiliate products.
              </h5>
            </div>

            <div className={classes.stepsTableWrapper}>
              <div className={clsx(classes.pointsBadge, classes.stepBadge)}>
                Steps
              </div>
              <div>
                <div className={classes.stepImageWrapper}>
                  <div className={classes.stepTitle}>
                    <p>1</p>
                    <h5>ACTIVATE SHOWCASE</h5>
                  </div>
                  <img
                    src={step1}
                    className={classes.stepImage}
                    alt="step1"
                    onClick={() =>
                      handleOpenImageLink(
                        routesByName.dashboard.ambassadorShowcase
                      )
                    }
                  />
                </div>
                <div className={classes.stepImageWrapper}>
                  <div className={classes.stepTitle}>
                    <p>3</p>
                    <h5>ADD AFFILIATE LINK</h5>
                  </div>
                  <img
                    src={step3}
                    className={classes.stepImage}
                    alt="step3"
                    onClick={() =>
                      handleOpenImageLink(routesByName.account.profile)
                    }
                  />
                </div>
              </div>
              <div className={classes.stepImageWrapper}>
                <div className={classes.stepTitle}>
                  <p>2</p>
                  <h5>REGISTER AS AFFILIATE</h5>
                </div>
                <img
                  src={step2}
                  className={classes.stepImage}
                  alt="step2"
                  onClick={() =>
                    handleOpenImageLink(
                      'https://af.uppromote.com/lookshops-9536/register',
                      true
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div
              className={clsx(
                classes.stepsTableWrapper,
                'flex-column p-5 mr-5'
              )}
            >
              <img src={promoteSocials} alt="promoteSocials" />
              <Button
                color="primary"
                size="md"
                onClick={() =>
                  handleLinkOpen(
                    routesByName.dashboard.ambassadorVideos,
                    false,
                    '6'
                  )
                }
                className={classes.watchBtn}
              >
                <SpriteIcon name="play-arrow" />
                Watch the Video
              </Button>
            </div>
            <div className={clsx(classes.contentBlock, classes.rightSide)}>
              <h2 className={classes.contentTitle}>
                EARN AFFILIATE COMMISSIONS
              </h2>
              <h3 className={classes.contentBoldTitle}>
                Build your brand to earn commissions!
              </h3>
              <div className="d-flex align-items-start mb-4">
                <div className={classes.iconWrapper}>
                  <SpriteIcon name="social-share" size="xl" />
                </div>
                <div className={classes.stepText}>
                  <h4 className={classes.stepToBecomeTitle}>
                    PROMOTE ON SOCIAL MEDIA
                  </h4>
                  <p className={classes.stepToBecomeDesc}>
                    <b>LOOK BOARDS: </b>
                    You can promote your Look Boards on social media. By showing
                    ways to “Get the Look” of beautiful images on your socials,
                    you can drive traffic to your Look Boards with
                    commission-based items and earn cash when purchases are
                    made.
                  </p>
                  <p className={classes.stepToBecomeDesc}>
                    <b>FAVORITE PRODUCTS: </b>
                    You can also promote specific
                    <a
                      href="https://mylookshops.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      MyLookShops.com{' '}
                    </a>
                    products on social media by including your Coupon Link in
                    your posts. You will earn a commission when someone
                    purchases the item using your coupon link.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start mb-4">
                <div className={classes.iconWrapper}>
                  <SpriteIcon name="scene" size="xl" />
                </div>
                <div className={classes.stepText}>
                  <h4 className={classes.stepToBecomeTitle}>CURATE REQUESTS</h4>
                  <p className={classes.stepToBecomeDesc}>
                    As an Ambassador, you can curate matched Requests with
                    Affiliate products to help others get the look of their
                    favorite images. This is a great way to gain followers and
                    earn commissions at the point when purchase intent is high.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmbassadorProgram;
