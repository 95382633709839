import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import {
  howToViewModalConfig,
  recommendationsTabsConfig,
} from 'modules/requestTheLook/recommendations/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import classes from './HowToViewModal.module.scss';

const HowToViewModal = ({ open, onClose }) => {
  return (
    <BasicModal
      onClose={onClose}
      open={open}
      classes={{ root: classes.modalRoot }}
    >
      <div className={classes.root}>
        <div className="text-center">
          <h2 className="text-uppercase mb-2 font-title">
            Recommendations Overview
          </h2>
          <h3 className="text-capitalize font-italic mb-5">
            There are three ways to view your recommendations:
          </h3>
          <p className="text-capitalize font-italic">
            <b>Use the buttons</b> at the top to select your View
          </p>
          <div className="d-flex mt-2 justify-content-center">
            {recommendationsTabsConfig.map(({ value, title, icon }) => (
              <button key={value} type="button" className={classes.tabBtn}>
                <SpriteIcon name={icon} size="sm" className="mr-1" />
                {title}
              </button>
            ))}
          </div>
        </div>
        <div className="d-flex mt-5 mb-4 justify-content-between">
          {howToViewModalConfig.map(
            ({ title, subTitle, img, defaultView = false }) => (
              <div className={classes.viewRoot} key={title}>
                <h3 className="mb-1">
                  <b>{title}</b>
                </h3>
                <p
                  className="mb-3 text-capitalize font-italic text-sm"
                  style={{ lineHeight: 1.8, whiteSpace: 'pre' }}
                >
                  {subTitle}
                </p>
                <img src={img} alt={title} />
                {defaultView && (
                  <div className={classes.defaultView}>Default View</div>
                )}
              </div>
            )
          )}
        </div>
        <div className="d-flex justify-content-center">
          <Button onClick={onClose} inline>
            View Recommendations
          </Button>
        </div>
      </div>
    </BasicModal>
  );
};

HowToViewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default HowToViewModal;
