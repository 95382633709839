import {
  imageTypeValuesMap,
  createImageFieldKeys,
  validationMessages,
  createProductFieldKeys,
} from 'modules/pinMedia/constants';

const descriptionMaxLength = 1000;
const urlPattern = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
);

const createImageValidator = (
  {
    [createImageFieldKeys.type]: type,
    [createImageFieldKeys.title]: title,
    [createImageFieldKeys.roomType]: roomType,
    [createImageFieldKeys.description]: description,
    [createImageFieldKeys.siteSourceUrl]: siteSourceUrl,
    [createImageFieldKeys.withDrop]: withDrop,
  },
  imageType
) => {
  const errors = {};

  let isDropActive = false;

  if (!title && !type && withDrop === undefined) {
    isDropActive = true;
  }

  // if (!type) {
  //   errors[createImageFieldKeys.type] = [validationMessages.required];
  // }

  if (!title) {
    errors[createImageFieldKeys.title] = [validationMessages.required];
  }

  if (imageType === imageTypeValuesMap.room && !roomType) {
    errors[createImageFieldKeys.roomType] = [validationMessages.required];
  }

  if (description?.length > descriptionMaxLength) {
    errors[createProductFieldKeys.description] = [
      validationMessages.maxLength(descriptionMaxLength),
    ];
  }

  if (isDropActive && withDrop && !siteSourceUrl) {
    errors[createImageFieldKeys.siteSourceUrl] = [validationMessages.required];
  } else if (isDropActive && withDrop && !urlPattern.test(siteSourceUrl)) {
    errors[createImageFieldKeys.siteSourceUrl] = [
      validationMessages.urlNotMatch,
    ];
  }

  return errors;
};

export default createImageValidator;
