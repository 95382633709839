import React from 'react';
import { toastr } from 'react-redux-toastr';
import SpriteIcon from 'components/ui/SpriteIcon';
import ToastrIconContainer from './ToastrIconContainer/ToastrIconContainer';

const multipleErrorToastr = (errors, title = 'Error') => {
  errors.forEach((error) => {
    toastr.error(title, error[0], {
      icon: (
        <ToastrIconContainer type="error">
          <SpriteIcon name="cross" size="sm" />
        </ToastrIconContainer>
      ),
      showCloseButton: true,
    });
  });
};

export default multipleErrorToastr;
