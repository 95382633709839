import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import ambassadorMessageImg from 'assets/img/woman.png';
import buildResourceUrl from 'utils/buildResourceUrl';
import { useHistory } from 'react-router-dom';
import {
  notificationTypes,
  queryTypes,
} from 'modules/layout/Header/Popovers/constants';
import classes from 'modules/layout/Header/NotificationItem/NotificationItem.module.scss';
import {
  getInspirationImgUrl,
  getProductImgUrl,
} from 'modules/app/store/selectors';
import { useSelector } from 'react-redux';
import mediaTypes from 'constants/mediaTypes';
import { routesByName } from '../../../../constants/routes';

const NotificationItem = ({
  link,
  title,
  message,
  time,
  media,
  type,
  notifiableId,
  onClosePopover,
}) => {
  const history = useHistory();
  const currentImgUrl = useRef();
  const config = useSelector((state) => state.app.config);
  const inspirationImgUrl = getInspirationImgUrl(config);
  const productImgUrl = getProductImgUrl(config);

  const imageBaseUrls = {
    [mediaTypes.inspirationImage]: inspirationImgUrl,
    [mediaTypes.product]: productImgUrl,
  };

  const setImageUrl = useCallback(() => {
    if (media) {
      currentImgUrl.current = buildResourceUrl(
        imageBaseUrls[media.type].small,
        media.userId,
        media.hash
      );
    }
  }, [imageBaseUrls, media]);

  setImageUrl();

  const handleLinkClick = useCallback(() => {
    switch (type) {
      case notificationTypes.youReMatch: {
        history.replace(`${queryTypes.youReMatch}${notifiableId}`);
        break;
      }
      case notificationTypes.lookBoardApproved: {
        history.replace(`${queryTypes.lookBoardApproved}${notifiableId}`);
        break;
      }
      case notificationTypes.recommendationsReady: {
        history.replace(`${queryTypes.recommendationsReady}${notifiableId}`);
        break;
      }
      case notificationTypes.youReAmbassador: {
        history.replace(`${queryTypes.youReAmbassador}`);
        break;
      }
      case notificationTypes.imageApproved: {
        history.replace(`${queryTypes.imageApproved}`);
        break;
      }
      case notificationTypes.lookBoardRejected: {
        history.replace(`${queryTypes.myLookBoards}`);
        break;
      }
      case notificationTypes.videoActivated: {
        history.replace(`${routesByName.dashboard.ambassadorShowcase}`);
        break;
      }
      case notificationTypes.videoDeactivated: {
        history.replace(`${routesByName.dashboard.ambassadorShowcase}`);
        break;
      }
      default:
        break;
    }
    if (onClosePopover) {
      onClosePopover();
    }
  }, [history, notifiableId, onClosePopover, type]);

  return (
    <div className={clsx(classes.root, 'd-flex align-items-center ml-1 mr-2')}>
      <div className={classes.imageContainer}>
        <img src={currentImgUrl.current || ambassadorMessageImg} alt="" />
      </div>
      <div className="d-flex flex-column w-100">
        <div className="d-flex justify-content-between">
          <h4>{title}</h4>
          <span className="text-gray text-xs">
            {moment(time).fromNow(true)}
          </span>
        </div>
        <div className={classes.textContent}>
          <p className={classes.itemName}>{message}</p>
        </div>
        <div className="d-flex justify-content-end">
          <p
            className={`primary-color text-underline text-sm ${classes.link}`}
            data-id={notifiableId}
            onClick={handleLinkClick}
          >
            {link}
          </p>
        </div>
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  message: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  media: PropTypes.shape({
    hash: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
    userId: PropTypes.number,
  }),
  type: PropTypes.string.isRequired,
  notifiableId: PropTypes.number,
  onClosePopover: PropTypes.func,
};

NotificationItem.defaultProps = {
  notifiableId: null,
  media: null,
  onClosePopover: null,
};

export default NotificationItem;
