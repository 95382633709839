import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import HoverOverlay from 'modules/getTheLook/components/HoverOverlay/HoverOverlay';
import { mediaShape } from 'modules/media/propTypes';
import { routesByName } from 'constants/routes';
import { lookBoardInfoConfig } from 'modules/getTheLook/constants';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import SpriteIcon from 'components/ui/SpriteIcon';
import { maxWidthMd } from 'constants/mediaQueries';
import useMediaQuery from '../../../hooks/useMediaQuery';
import VoteModal from './VoteModal/VoteModal';
import lookBoardService from '../../lookBoard/lookBoardService';
import classes from './LookBoardItem.module.scss';
import { selectImageAction } from '../../curateTheLook/store/actions';

const LookBoardItem = ({
  lookBoard: {
    id,
    title,
    color,
    isLiked,
    isVoted,
    banner,
    products: productIds,
    shareUrl,
    lookBoardSocialImage,
    lookBoardSocialImageFacebook,
    inspirationId,
    slug,
  },
  user,
  products,
  onToggleLike,
  onVote,
  isOnSlider,
  isGalleryView,
  onClick,
  hideLikeVote,
  isGalleryItem,
  onOpenModal,
  isFeaturedSlider,
}) => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const userAvatarUrl = getUserAvatarUrl(
    useSelector((state) => state.app.config)
  );
  const dispatch = useDispatch();

  const history = useHistory();
  const { pathname } = useLocation();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const [voteVisible, setVoteVisible] = useState(false);

  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const lookBoardProducts = useMemo(
    () =>
      typeof productIds[0] === 'object'
        ? productIds
        : productIds
            .map((productId) => products[productId])
            .filter((item) => item !== undefined),
    [productIds, products]
  );

  const avatarUrl = useMemo(
    () =>
      user &&
      user.avatar &&
      buildResourceUrl(userAvatarUrl, user.avatar.userId, user.avatar.hash),
    [userAvatarUrl, user]
  );

  const handleToggleLike = useCallback(() => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    onToggleLike(id, Number(!isLiked), inspirationId);
  }, [
    authenticated,
    onToggleLike,
    id,
    isLiked,
    inspirationId,
    history,
    pathname,
  ]);

  const showVoteModal = useCallback(() => setVoteVisible(true), []);
  const hideVoteModal = useCallback(() => setVoteVisible(false), []);

  const handleVote = useCallback(
    (voteValue) => {
      onVote(id, voteValue, inspirationId);
      hideVoteModal();
    },
    [onVote, id, inspirationId, hideVoteModal]
  );

  const moveToDetails = useCallback(() => {
    history.push(routesByName.lookBoard.details(id, slug));
  }, [history, id, slug]);

  const handleOpenSharePopover = useCallback(({ currentTarget }) => {
    setShareAnchorEl(currentTarget);
  }, []);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const handleShareClick = useCallback(async () => {
    await lookBoardService.shareLookBoard(id);
  }, [id]);

  const handleViewSimilar = useCallback(() => {
    history.push(routesByName.getTheLook.lookBoard.details(id));
  }, [history, id]);

  const handleGoMixMatch = useCallback(() => {
    history.push(routesByName.getTheLook.mixMatch.lookBoards.details(id));
  }, [history, id]);

  const handleCurateTheLook = useCallback(async () => {
    history.push(routesByName.curateTheLook.canvas);
    const { result: lookBoard } = await lookBoardService.getLookBoardById(id);
    if (lookBoard.inspirationId) {
      dispatch(selectImageAction(lookBoard.inspirationId, null, null, id));
    }
  }, [dispatch, history, id]);

  const handleOpenModal = useCallback(async () => {
    onOpenModal(id);
  }, [id, onOpenModal]);

  return (
    <>
      {matchesMediaQuery ? (
        <>
          <LookBoardPreview
            title={title}
            color={color}
            isLiked={isLiked}
            isVoted={isVoted}
            isOnSlider={isOnSlider}
            banner={banner}
            products={lookBoardProducts}
            onToggleLike={handleToggleLike}
            moveToDetails={moveToDetails}
            onVoteClick={showVoteModal}
            isGalleryItem={isGalleryItem}
            showLike={isFeaturedSlider}
            hoverOverlay={
              <HoverOverlay
                userId={user?.id}
                firstName={user?.firstName}
                lastName={user?.lastName}
                displayName={user?.displayName}
                avatarUrl={avatarUrl}
                isLiked={isLiked}
                isVoted={isVoted}
                isAmbassador={user?.ambassador}
                isOnSlider={isOnSlider}
                infoPopoverConfig={lookBoardInfoConfig}
                onToggleLike={handleToggleLike}
                onVote={handleVote}
                onClick={onClick ?? moveToDetails}
                onShare={handleOpenSharePopover}
                hideLikeVote={hideLikeVote}
                isGalleryItem={isGalleryItem}
                onOpenModal={handleOpenModal}
                onViewMixMatch={handleGoMixMatch}
                onViewSimilar={handleViewSimilar}
                onViewCurate={handleCurateTheLook}
              />
            }
          />
        </>
      ) : (
        <>
          <LookBoardPreview
            title={title}
            color={color}
            isLiked={isLiked}
            isVoted={isVoted}
            isOnSlider={isOnSlider}
            banner={banner}
            products={lookBoardProducts}
            onToggleLike={handleToggleLike}
            showLike={!hideLikeVote}
            hoverOverlay={
              <HoverOverlay
                userId={user?.id}
                firstName={user?.firstName}
                lastName={user?.lastName}
                displayName={user?.displayName}
                shareUrl={user?.shareUrl}
                avatarUrl={avatarUrl}
                isLiked={isLiked}
                isVoted={isVoted}
                isAmbassador={user?.ambassador}
                isOnSlider={isOnSlider}
                infoPopoverConfig={lookBoardInfoConfig}
                onToggleLike={handleToggleLike}
                onVote={handleVote}
                onClick={onClick ?? moveToDetails}
                onShare={handleOpenSharePopover}
                isGalleryView={isGalleryView}
                hideLikeVote={hideLikeVote}
                onOpenModal={onOpenModal ? handleOpenModal : null}
                onViewMixMatch={handleGoMixMatch}
                onViewSimilar={handleViewSimilar}
                onViewCurate={handleCurateTheLook}
              />
            }
            isGalleryView={isGalleryView}
          />

          {!!shareAnchorEl && (
            <SpriteIcon
              name="cross"
              size="md"
              onClick={handleCloseSharePopover}
              className={classes.closeCross}
            />
          )}
          <SharePopover
            anchorEl={shareAnchorEl}
            open={Boolean(shareAnchorEl)}
            onClose={handleCloseSharePopover}
            placement="bottom"
            shareUrl={shareUrl}
            directImgUrl={lookBoardSocialImage}
            directImgUrlFacebook={lookBoardSocialImageFacebook}
            onShareClick={handleShareClick}
            isLookBoard
            lookBoardId={id}
          />
        </>
      )}

      {matchesMediaQuery && voteVisible && (
        <VoteModal
          isVoted={isVoted}
          onVote={handleVote}
          closeModal={hideVoteModal}
        />
      )}
    </>
  );
};

LookBoardItem.propTypes = {
  lookBoard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isLiked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    lookBoardSocialImage: PropTypes.string,
    lookBoardSocialImageFacebook: PropTypes.string,
    isVoted: PropTypes.bool,
    banner: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.object])
    ).isRequired,
    shareUrl: PropTypes.string.isRequired,
    inspirationId: PropTypes.number,
    slug: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: mediaShape,
    ambassador: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
      .isRequired,
  }).isRequired,
  products: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  onToggleLike: PropTypes.func,
  onVote: PropTypes.func,
  isOnSlider: PropTypes.bool,
  isGalleryView: PropTypes.bool,
  onClick: PropTypes.func,
  hideLikeVote: PropTypes.bool,
  isGalleryItem: PropTypes.bool,
  onOpenModal: PropTypes.func,
  isFeaturedSlider: PropTypes.bool,
};

LookBoardItem.defaultProps = {
  products: {},
  isOnSlider: false,
  isGalleryView: false,
  onVote: () => {},
  onToggleLike: () => {},
  hideLikeVote: false,
  onClick: null,
  isGalleryItem: false,
  isFeaturedSlider: false,
  onOpenModal: null,
  lookBoard: {
    inspirationId: null,
  },
};

export default LookBoardItem;
