import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRoomTypeName, getStyleNames } from 'modules/app/store/selectors';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import useItemClassesByIds from 'modules/app/hooks/useItemClassesByIds';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';

const RequestDetailsModal = ({
  open,
  onClose,
  request: { title: requestTitle, priorityItems, message },
  imgUrl,
  requestImage: { type, url, title },
  styles,
  roomType,
}) => {
  const priorityItemsList = useItemClassesByIds(priorityItems);

  const itemType = useMemo(
    () => (type === imageTypeKeys.room ? 'Room' : 'Item'),
    [type]
  );

  return (
    <BasicModal
      classes={{ root: 'rounded-0 p-0' }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <div className="d-flex">
        <ImageDetails imgUrl={imgUrl} url={url} title={title} showInfo />
        <DetailsPreview
          title={requestTitle}
          requestType={type}
          styles={styles}
          priorityItems={priorityItemsList}
          itemType={itemType}
          roomType={roomType}
          message={message}
        />
      </div>
    </BasicModal>
  );
};

RequestDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imgUrl: PropTypes.string.isRequired,
  request: PropTypes.shape({
    title: PropTypes.string.isRequired,
    priorityItems: PropTypes.arrayOf(PropTypes.number).isRequired,
    message: PropTypes.string,
  }).isRequired,
  requestImage: inspirationImageShape.isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  roomType: PropTypes.string,
};
RequestDetailsModal.defaultProps = {
  roomType: null,
};
const mapStateToProps = ({ app: { enums } }, { requestImage }) => ({
  styles: getStyleNames(requestImage?.styles ?? [], enums),
  roomType: getRoomTypeName(requestImage?.roomTypeId, enums),
});

export default connect(mapStateToProps)(RequestDetailsModal);
