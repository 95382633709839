import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { registerTimeoutConfig } from 'modules/getTheLook/constants';
import { routesByName } from 'constants/routes';

export const useRegisterEffect = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const currentUser = useSelector((state) => state.auth.user);
  const timeout = useRef(null);

  const openRegisterModal = useCallback(() => {
    history.push(
      `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
      {
        reopen: true,
        timeout: registerTimeoutConfig.second,
        title: 'REGISTER NOW',
      }
    );
  }, [history, pathname]);

  useEffect(() => {
    if (!currentUser) {
      timeout.current = setTimeout(() => {
        openRegisterModal();
      }, registerTimeoutConfig.first);
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [currentUser, openRegisterModal]);
};
