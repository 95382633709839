import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';
import { filterTabKeys } from '../FiltersModal/constants';

const ShowQuizResultModal = ({ open, onClose, handleViewResults }) => {
  const styleQuis = useSelector((state) => state.layout.styleQuizResult);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleView = useCallback(() => {
    const savedSubStyles = styleQuis.data[filterTabKeys.subStyles] ?? [];
    const preparedSubStyles = savedSubStyles.reduce((accum, currValue) => {
      /* eslint-disable no-param-reassign */
      accum[currValue] = 1;

      return accum;
    }, {});

    handleViewResults({
      ...styleQuis.data,
      [filterTabKeys.subStyles]: preparedSubStyles,
    });
    handleViewResults();
  }, [handleViewResults, styleQuis.data]);

  return (
    <BasicModal open={open} onClose={handleCancel} fullWidth>
      <p
        className="font-title text-lg text-uppercase text-center mb-3"
        style={{ lineHeight: 1.4 }}
      >
        Thank you for Registering with Pin the Look!
      </p>
      <div className="d-flex justify-content-center mb-1">
        <Button
          type="button"
          inline
          size="sm"
          variant="outlined"
          className="mr-2"
          onClick={handleView}
        >
          VIEW STYLE QUIZ RESULTS
        </Button>
      </div>
    </BasicModal>
  );
};

ShowQuizResultModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleViewResults: PropTypes.func.isRequired,
};

export default ShowQuizResultModal;
