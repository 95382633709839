import React, { useCallback, useEffect, useState } from 'react';
import { profileTabKeys, profileTabsConfig } from 'modules/account/constants';
import { ADD_PREFERENCES } from 'constants/customEventNames';
import useCustomEventListener from 'hooks/useCustomEventListener';
import Tab from 'components/ui/Tab/Tab';
import ProfileContainer from 'modules/account/profileAndPreferences/profile/ProfileContainer';
import PreferencesContainer from 'modules/account/profileAndPreferences/preferences/PreferencesContainer';
import accountClasses from 'modules/account/Account.module.scss';
import GetStartedModal from 'components/modals/GetStartedModal/GetStartedModal';
import { useLocation } from 'react-router-dom';

const ProfileAndPreferencesContainer = () => {
  const { state } = useLocation();
  const [isGetStartedModalOpen, setGetStartedModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    state?.toPreferences ? profileTabKeys.preferences : profileTabKeys.profile
  );
  const [isEdit, setIsEdit] = useState(false);

  useCustomEventListener(ADD_PREFERENCES, () => {
    setActiveTab(profileTabKeys.preferences);
    setIsEdit(true);
  });

  const handleOpenGetStartedModal = useCallback(() => {
    setGetStartedModalOpen(true);
  }, []);

  const handleCloseGetStartedModal = useCallback(() => {
    setGetStartedModalOpen(false);
  }, []);

  const handleTabChange = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const handleEnableEditMode = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEdit(false);
    setTimeout(() => {
      setGetStartedModalOpen(true);
    }, 2000);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <p className={accountClasses.title}>Profile & Preferences</p>
        <div className="d-flex">
          {profileTabsConfig.map(({ name, title }) => (
            <Tab
              key={name}
              title={title}
              isActive={name === activeTab}
              name={name}
              onToggle={handleTabChange}
            />
          ))}
        </div>
      </div>
      {activeTab === profileTabKeys.profile && <ProfileContainer />}
      {activeTab === profileTabKeys.preferences && (
        <PreferencesContainer
          isEdit={isEdit}
          onEnableEdit={handleEnableEditMode}
          onCancelEdit={handleCancelEdit}
          handleOpenGetStartedModal={handleOpenGetStartedModal}
        />
      )}
      <GetStartedModal
        onClose={handleCloseGetStartedModal}
        open={isGetStartedModalOpen}
      />
    </>
  );
};

export default ProfileAndPreferencesContainer;
