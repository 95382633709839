export const ambassadorInfoSteps = [
  {
    title: 'Curate the Request Now -',
    body:
      'If you would like to accept this request, click on the Curate Now button and send your first Look Board(s) within 24 hours. If you have questions about the request, Click on the email icon to start a dialog',
  },
  {
    title: 'Curate the Request Later -',
    body:
      ' You can also click on “Save for Later” and fulfill this Request in the next 24 hours.',
  },
  {
    title: 'Decline the Request -',
    body:
      'If you do not want to fulfill this Request please click on Decline Request now.',
  },
];
