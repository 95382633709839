import React from 'react';
import PropTypes from 'prop-types';
import InputSkeleton from 'components/inputs/InputSkeleton/InputSkeleton';
import classes from './CustomTextInput.module.scss';

const CustomTextInput = ({
  type,
  placeholder,
  inputWrapperClassName,
  ...rest
}) => {
  return (
    <InputSkeleton
      {...rest}
      input={<input type={type} placeholder={placeholder} />}
      inputClasses={{
        inputWrapper: `${inputWrapperClassName} ${classes.inputWrapper}`,
        input: classes.input,
      }}
    />
  );
};
CustomTextInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
};

CustomTextInput.defaultProps = {
  type: 'text',
  placeholder: '',
  inputWrapperClassName: null,
};

export default CustomTextInput;
