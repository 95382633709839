import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { pageDataFormFields } from 'modules/dashboard/showcase/constants';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import TextAreaWrapper from 'components/finalFormWrappers/TextAreaWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import addPageDataModalValidator from './addPageDataModalValidator';
import classes from './AddPageDataModal.module.scss';

const AddPageDataModal = ({
  open,
  currentData: {
    pageName,
    subtitle,
    isSubtitleActive,
    metaTitle,
    urlKey,
    metaDescription,
    facebook,
    instagram,
    pinterest,
    linkedin,
    twitter,
  },
  openTitlePreviewModal,
  onClose,
  onApply,
  user,
}) => {
  const [initialFormValues, setInitialFormValues] = useState({
    pageName,
    subtitle,
    isSubtitleActive: !isSubtitleActive,
    metaTitle,
    urlKey,
    metaDescription,
    facebook,
    instagram,
    pinterest,
    linkedin,
    twitter,
    displayName: user?.displayName ?? `${user?.firstName} ${user?.lastName}`,
  });

  useEffect(() => {
    if (!open) {
      setInitialFormValues({
        pageName,
        subtitle,
        isSubtitleActive: !isSubtitleActive,
        metaTitle,
        urlKey,
        metaDescription,
        facebook,
        instagram,
        pinterest,
        linkedin,
        twitter,
        displayName:
          user?.displayName ?? `${user?.firstName} ${user?.lastName}`,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <h3 className={`${classes.sectionTitle} mb-3`}>
        Add/edit Showcase Page Data
      </h3>
      <Form
        initialValues={initialFormValues}
        onSubmit={onApply}
        validate={addPageDataModalValidator}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-5">
                <div className="d-flex align-items-center">
                  <div className="text-sm text-gray mr-1">
                    Showcase Page Name
                  </div>
                  <InfoIconPopover
                    iconProps={{
                      variant: 'inverted-grey',
                      size: 'xs',
                    }}
                    fullWidth
                  >
                    <p className="font-semi-bold mb-1">Showcase title</p>
                    <p className="text-xs">
                      Input the name of your Showcase page. his name will be
                      displayed on the banner. Use up to 30 characters. Example:
                      Modern Farmhouse Look
                    </p>
                  </InfoIconPopover>
                </div>
                <p className="text-xs font-italic text-gray mb-2">
                  (will be on the top of the banner{' '}
                  <span
                    className="primary-color cursor-pointer"
                    onClick={openTitlePreviewModal}
                  >
                    see how
                  </span>
                  )
                </p>
                <Field
                  required
                  name={pageDataFormFields.pageName}
                  component={TextInputWrapper}
                />
              </div>
              <div className="col-1" />
              <div className="col-6">
                <div className="d-flex align-items-center mb-2">
                  <div className="text-sm text-gray mr-1 pb-1">URL Key</div>
                </div>
                <Field
                  required
                  disabled
                  name={pageDataFormFields.urlKey}
                  component={TextInputWrapper}
                />
              </div>
              <div className="col-5">
                <div className="mb-2">
                  <div className="d-flex align-items-center">
                    <div className="text-sm text-gray mr-1">Display Name</div>
                  </div>
                  <Field
                    required
                    name={pageDataFormFields.displayName}
                    component={TextInputWrapper}
                    InputProps={{
                      inputProps: {
                        maxLength: 30,
                      },
                    }}
                  />
                </div>

                <div className="mb-2">
                  <div className="d-flex align-items-center mb-2">
                    <div className="text-sm text-gray mr-1">Meta Title</div>
                    <InfoIconPopover
                      iconProps={{
                        variant: 'inverted-grey',
                        size: 'xs',
                      }}
                      fullWidth
                    >
                      <p className="font-semi-bold mb-1">Showcase Meta Title</p>
                      <p className="text-xs">
                        Your Meta Title should use keywords to describe your
                        showcase page, using natural langauge. <br />
                        <br />
                        <b>Example:</b> modern farmhouse furniture and decor
                      </p>
                    </InfoIconPopover>
                  </div>
                  <Field
                    required
                    name={pageDataFormFields.metaTitle}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="d-flex align-items-center mb-2">
                  <div className="text-sm text-gray mr-1">Meta Description</div>
                  <InfoIconPopover
                    iconProps={{
                      variant: 'inverted-grey',
                      size: 'xs',
                    }}
                    fullWidth
                  >
                    <p className="font-semi-bold mb-1">
                      Showcase Meta Description
                    </p>
                    <p className="text-xs">
                      Your Meta Description description will be used to find
                      your Showcase page on Pin the Look and in search engines,
                      so make sure that it uses relevant keywords and describes
                      any special features. Your description can be up to 160
                      characters, and is best between 50 - 160 characters. Use
                      natural language and do not repeat keywords. For best
                      results, include your style focus areas, room and item
                      types and unique characteristics.
                    </p>
                  </InfoIconPopover>
                </div>
                <Field
                  required
                  name={pageDataFormFields.metaDescription}
                  rows={3}
                  component={TextAreaWrapper}
                />
              </div>
              <div className="col-1" />
              <div className="col-6">
                <div className="d-flex align-items-center mb-2">
                  <div className="text-sm text-gray mr-1">
                    Links to your Accounts on Social Networks
                  </div>
                </div>
                <Field
                  required
                  name={pageDataFormFields.facebook}
                  InputProps={{
                    startAdornment: (
                      <div className={classes.iconContainer}>
                        <SpriteIcon name="facebook" size="sm" />
                      </div>
                    ),
                  }}
                  placeholder="facebook.com/yourAccount"
                  component={TextInputWrapper}
                />
                <Field
                  required
                  name={pageDataFormFields.instagram}
                  InputProps={{
                    startAdornment: (
                      <div className={classes.iconContainer}>
                        <SpriteIcon name="instagram" size="sm" />
                      </div>
                    ),
                  }}
                  placeholder="instagram.com/yourAccount"
                  component={TextInputWrapper}
                />
                <Field
                  required
                  name={pageDataFormFields.pinterest}
                  InputProps={{
                    startAdornment: (
                      <div className={classes.iconContainer}>
                        <SpriteIcon name="pinterest" size="sm" />
                      </div>
                    ),
                  }}
                  placeholder="pinterest.com/yourAccount"
                  component={TextInputWrapper}
                />
                <Field
                  required
                  name={pageDataFormFields.twitter}
                  InputProps={{
                    startAdornment: (
                      <div className={classes.iconContainer}>
                        <SpriteIcon name="twitter" size="sm" />
                      </div>
                    ),
                  }}
                  placeholder="twitter.com/yourAccount"
                  component={TextInputWrapper}
                />
                <Field
                  required
                  name={pageDataFormFields.linkedin}
                  InputProps={{
                    startAdornment: (
                      <div className={classes.iconContainer}>
                        <SpriteIcon name="linkedin" size="sm" />
                      </div>
                    ),
                  }}
                  placeholder="linkedin.com/yourAccount"
                  component={TextInputWrapper}
                />
              </div>

              <div className="col-12 text-center">
                <FinalFormSubmitButton inline>Save</FinalFormSubmitButton>
              </div>
            </div>
          </form>
        )}
      />
    </BasicModal>
  );
};

AddPageDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  currentData: PropTypes.shape({
    pageName: PropTypes.string,
    subtitle: PropTypes.string,
    isSubtitleActive: PropTypes.bool,
    metaTitle: PropTypes.string,
    urlKey: PropTypes.string,
    metaDescription: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    pinterest: PropTypes.string,
    linkedin: PropTypes.string,
    twitter: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  openTitlePreviewModal: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default AddPageDataModal;
