import { nominateAmbassadorFieldKeys } from 'modules/ambassadors/constants';
import { errorMessages } from 'modules/auth/signUp/constants';

const emailPattern = /.+@.+..+/;

const nominateAmbassadorValidator = ({
  [nominateAmbassadorFieldKeys.fromFirst]: fromFirst,
  [nominateAmbassadorFieldKeys.fromLast]: fromLast,
  [nominateAmbassadorFieldKeys.toFirst]: toFirst,
  [nominateAmbassadorFieldKeys.toLast]: toLast,
  [nominateAmbassadorFieldKeys.email]: email,
  [nominateAmbassadorFieldKeys.confirmEmail]: confirmEmail,
  [nominateAmbassadorFieldKeys.subject]: subject,
  [nominateAmbassadorFieldKeys.message]: message,
}) => {
  const errors = {};

  if (!fromFirst) {
    errors.fromFirst = errorMessages.required;
  }

  if (!fromLast) {
    errors.fromLast = errorMessages.required;
  }

  if (!toFirst) {
    errors.toFirst = errorMessages.required;
  }

  if (!toLast) {
    errors.toLast = errorMessages.required;
  }

  if (!email) {
    errors.email = errorMessages.required;
  } else if (!emailPattern.test(email)) {
    errors.email = errorMessages.emailInvalid;
  }

  if (!confirmEmail) {
    errors.confirmEmail = errorMessages.required;
  } else if (confirmEmail !== email) {
    errors.confirmEmail = errorMessages.emailsShouldMatch;
  }

  if (!subject) {
    errors.subject = errorMessages.required;
  }

  if (!message) {
    errors.message = errorMessages.required;
  }

  return errors;
};

export default nominateAmbassadorValidator;
