import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { addAffiliateCodeFields } from 'modules/account/constants';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import affiliateCodeField from 'assets/img/affiliate-code-sample.png';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import Button from 'components/ui/Button/Button';
import TextInput from 'components/inputs/TextInput/TextInput';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import addCodeValidator from '../addCodeValidator';
import classes from '../Profile.module.scss';

const AffiliateCodeItem = ({
  code,
  editAffiliateCode,
  sites,
  userId,
  deleteAffiliateCode,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const updateAffiliateCodes = async (values) => {
    const { affiliateCode, affiliateSite, affiliateCouponCode } = values;
    const editedCode = {
      affiliate_code: affiliateCode,
      site: affiliateSite,
      coupon_code: affiliateCouponCode || '',
      user_id: userId,
    };
    await editAffiliateCode(editedCode, code.id);
    setIsEdit(false);
  };

  const handleDelete = () => {
    deleteAffiliateCode(code.id);
  };

  return (
    <div className="mb-5">
      {isEdit ? (
        <Form
          initialValues={{
            affiliateSite: sites?.find((site) => code.site === site.name).id,
            affiliateCode: code.code,
            affiliateCouponCode: code.coupon || '',
          }}
          validate={addCodeValidator}
          onSubmit={updateAffiliateCodes}
          render={({ handleSubmit, values }) => (
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(values);
              }}
            >
              <div className="d-flex justify-content-between">
                <div className={classes.halfWidthContainer}>
                  <Field
                    required
                    label="Select Affiliate site"
                    name={addAffiliateCodeFields.affiliateSite}
                    component={SelectInputWrapper}
                    options={getEnumAsSelectOptions(sites)}
                    classes={{ disabled: classes.disabledInput }}
                  />
                </div>
                <div
                  className={clsx(
                    classes.halfWidthContainer,
                    'd-flex align-items-center'
                  )}
                >
                  <InfoIconPopover
                    iconProps={{
                      className: 'mb-1 mr-1',
                      variant: 'inverted-white',
                      size: 'xs',
                      color: 'primary',
                    }}
                    fullWidth
                  >
                    <p className="font-semi-bold mb-1 primary-color text-center font-normal text-lg">
                      HOW TO ENTER AN AFFILIATE CODE
                    </p>
                    <p className="text-sm text-underline font-normal">
                      Copy & Paste Your Affiliate Code into this field.
                    </p>
                    <img src={affiliateCodeField} alt="affiliateCodeField" />
                    <p className="text-xs">
                      Pin the Look will add your Affiliate Code extension to
                      products from the Affiliate Site that you place in Look
                      Boards so that you can get credit for that specific item.
                    </p>
                  </InfoIconPopover>
                  <Field
                    requried
                    name={addAffiliateCodeFields.affiliateCode}
                    label="Enter Affiliate code"
                    component={TextInputWrapper}
                  />
                </div>
              </div>
              <div className={classes.halfWidthContainer}>
                <Field
                  name={addAffiliateCodeFields.affiliateCouponCode}
                  label="Enter Coupon code (Optional)"
                  component={TextInputWrapper}
                />
              </div>
              <FinalFormSubmitButton inline size="sm">
                Accept
              </FinalFormSubmitButton>
            </form>
          )}
        />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className={classes.halfWidthContainer}>
              {sites.length ? (
                <TextInput
                  disabled
                  label="Select Affiliate site"
                  value={code.site}
                  InputProps={{
                    classes: { disabled: classes.disabledInput },
                  }}
                />
              ) : null}
            </div>
            <div className={classes.halfWidthContainer}>
              <TextInput
                disabled
                label="Enter Affiliate code"
                value={code.code}
                InputProps={{
                  classes: { disabled: classes.disabledInput },
                }}
              />
            </div>
          </div>
          <div className={classes.halfWidthContainer}>
            <TextInput
              disabled
              label="Enter Coupon code (Optional)"
              value={code.coupon}
              InputProps={{
                classes: { disabled: classes.disabledInput },
              }}
            />
          </div>
          <Button
            inline
            size="sm"
            className="mr-1"
            onClick={() => setIsEdit(true)}
          >
            Change code
          </Button>
          <Button
            type="button"
            variant="default"
            color="default"
            inline
            className={classes.deactivateBtn}
            size="sm"
            onClick={handleDelete}
          >
            Remove code
          </Button>
        </>
      )}
    </div>
  );
};

AffiliateCodeItem.propTypes = {
  code: PropTypes.shape({
    id: PropTypes.number.isRequired,
    code: PropTypes.string.isRequired,
    site: PropTypes.number.isRequired,
    coupon: PropTypes.number.isRequired,
  }).isRequired,
  editAffiliateCode: PropTypes.func.isRequired,
  deleteAffiliateCode: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userId: PropTypes.number.isRequired,
};
export default AffiliateCodeItem;
