import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './VerificationLinkModal.module.scss';

const VerificationLinkModal = ({ open, onClose, email, onContactSupport }) => (
  <BasicModal open={open} onClose={onClose} fullWidth maxWidth="sm">
    <div className="p-3">
      <h3 className={`${classes.title} mb-5`}>
        Verification Link have been <br /> sent to your email
      </h3>
      <div className="px-4 mb-5">
        <div className={classes.emailFakeInput}>
          <input readOnly value={email} />
        </div>
      </div>
      <p
        className="font-italic text-sm text-center"
        style={{ lineHeight: 1.8 }}
      >
        Didn&apos;t get email?{' '}
        <span
          className="primary-color cursor-pointer"
          onClick={onContactSupport}
        >
          Click here
        </span>{' '}
        to <br /> contact support
      </p>
    </div>
  </BasicModal>
);

VerificationLinkModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  onContactSupport: PropTypes.func.isRequired,
};

export default VerificationLinkModal;
