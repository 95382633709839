import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import myImagesClasses from 'modules/dashboard/myImages/MyImages.module.scss';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import {
  lookBoardStatusRadioOptions,
  myLookBoardsSortOptions,
  myLookBoardsTypeOptions,
} from 'modules/dashboard/constants';
import { approvalStatusKeys } from 'modules/inspirationImage/constants';
import {
  lookBoardSourceKeys,
  lookBoardStatusKeys,
} from 'constants/lookBoardSearchParams';
import { routesByName } from 'constants/routes';
import CustomRadioGroup from 'modules/curateTheLook/createLookBoard/components/CustomRadioGroup';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import ConfirmModal from 'components/modals/ConfirmModal';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import RejectedLookBoardOverlay from 'modules/dashboard/myLookBoards/RejectedLookBoardOverlay';
import {
  excludeProductsAction,
  restoreCurateStateAction,
} from 'modules/curateTheLook/store/actions';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import errorToastr from 'libs/toastr/errorToastr';
import classes from './MyLookBoards.module.scss';
import SharePopover from '../../../components/modals/SharePopover/SharePopover';

const MyLookBoardsComponent = ({
  loading,
  hasMore,
  loadMore,
  lookBoardList,
  currentSource,
  currentType,
  currentSort,
  currentStatus,
  onChangeSearchParams,
  onDeleteLookBoard,
  onUnlikeLookBoard,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [selectedLookBoard, setSelectedLookBoard] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [unlikeModalOpen, setUnlikeModalOpen] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  const statusValue = useMemo(
    () =>
      currentStatus
        ? lookBoardStatusKeys.drafts
        : lookBoardStatusKeys.published,
    [currentStatus]
  );

  const handleOpenSharePopover = useCallback(
    ({ currentTarget }, id) => {
      const currentLookBoard = lookBoardList.find(
        ({ id: innerId }) => innerId === Number.parseInt(id, 10)
      );
      setSelectedLookBoard(currentLookBoard);
      setShareAnchorEl(currentTarget);
    },
    [lookBoardList]
  );

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const handleChangeType = useCallback(
    (type) => {
      onChangeSearchParams({ type });
    },
    [onChangeSearchParams]
  );

  const handleChangeSort = useCallback(
    (sort) => {
      onChangeSearchParams({ sort });
    },
    [onChangeSearchParams]
  );

  const handleChangeStatus = useCallback(
    ({ target: { value } }) => {
      onChangeSearchParams({
        draft: value === lookBoardStatusKeys.drafts ? 1 : 0,
      });
    },
    [onChangeSearchParams]
  );

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) {
      return;
    }
    loadMore();
  }, [loading, hasMore, loadMore]);

  const handlePreviewModalOpen = useCallback(
    ({ currentTarget }) => {
      const lookBoardId = currentTarget.getAttribute('data-id');
      const currentLookBoard = lookBoardList.find(
        ({ id }) => id === Number.parseInt(lookBoardId, 10)
      );

      setSelectedLookBoard(currentLookBoard);
      setPreviewModalOpen(true);
    },
    [lookBoardList]
  );

  const handlePreviewModalClose = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  const setRedirectRoute = useCallback((newPath) => {
    history.push(newPath);
    // eslint-disable-next-line
  }, []);

  const handleEditBoard = useCallback(
    async (products, boardId) => {
      try {
        const productIds = products.map(({ id: productId }) => productId);
        const currentBoard = lookBoardList.find(({ id }) => id === boardId);
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          boardId
        );

        const lookBoardData = {
          inspirationImageId: lookBoard.inspirationId,
          title: lookBoard.title,
          color: lookBoard.color,
          products: productIds,
          columns: lookBoard.columns,
          shareUrl: lookBoard.shareUrl,
        };

        const formValues = {
          title: lookBoard.title,
          type: lookBoard.inspirationType,
          itemClassId: lookBoard.itemClasses.length
            ? lookBoard.itemClasses[0]
            : null,
          roomTypeId: lookBoard.roomTypeId,
          styleId: lookBoard.styles.length ? lookBoard.styles[0] : null,
          description: lookBoard.description,
        };

        const editState = {
          lookBoardId: lookBoard.id,
          isDraft: Boolean(currentBoard.draft),
        };
        dispatch(excludeProductsAction(productIds));

        // TODO: pass lookBoard status to restore function
        await dispatch(
          restoreCurateStateAction(lookBoardData, formValues, editState)
        );

        history.push(routesByName.curateTheLook.canvas);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch, lookBoardList, history]
  );

  const handleClick = useCallback(
    ({ currentTarget }) => {
      const { id: lookBoardId, slug: lookBoardSlug } = currentTarget.dataset;
      setRedirectRoute(
        routesByName.lookBoard.details(lookBoardId, lookBoardSlug)
      );
    },
    [setRedirectRoute]
  );

  const handleClickDelete = useCallback(({ currentTarget }) => {
    const { id: lookBoardId } = currentTarget.dataset;
    setSelectedBoardId(lookBoardId);
    setConfirmModalOpen(true);
  }, []);

  const handleDeleteLookBoard = useCallback(
    (confirm) => {
      if (confirm) {
        onDeleteLookBoard(selectedBoardId);
      }
      setConfirmModalOpen(false);
      setSelectedBoardId(null);
    },
    [onDeleteLookBoard, selectedBoardId]
  );

  const handleOpenUnlikeModal = useCallback(({ currentTarget }) => {
    const { id: lookBoardId } = currentTarget.dataset;
    setSelectedBoardId(lookBoardId);
    setUnlikeModalOpen(true);
  }, []);

  const handleUnlikeLookBoard = useCallback(
    (confirm) => {
      if (confirm) {
        onUnlikeLookBoard(selectedBoardId);
      }
      setUnlikeModalOpen(false);
    },
    [onUnlikeLookBoard, selectedBoardId]
  );

  return (
    <>
      <div className={myImagesClasses.section}>
        <div className="d-flex justify-content-between mb-3">
          <FilterInput
            className={classes.typeSelect}
            options={myLookBoardsTypeOptions}
            currentValue={currentType}
            onChange={handleChangeType}
          />
          <FilterInput
            additionalLabel="Sort by:"
            className={classes.sortSelect}
            options={myLookBoardsSortOptions}
            currentValue={currentSort}
            onChange={handleChangeSort}
          />
        </div>
        {currentSource !== lookBoardSourceKeys.liked && (
          <CustomRadioGroup
            controls={lookBoardStatusRadioOptions}
            value={statusValue}
            onChange={handleChangeStatus}
          />
        )}
        {lookBoardList.length > 0 ? (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleLoadMore}
            initialLoad={false}
          >
            <div className="row">
              {lookBoardList.map(
                ({
                  id,
                  title,
                  color,
                  isLiked,
                  products,
                  approval,
                  rejectedReason,
                  draft,
                  editUnderReview,
                  slug,
                }) => (
                  <div key={id} className="col-3 mb-2">
                    <LookBoardPreview
                      id={id}
                      title={title}
                      color={color}
                      isLiked={isLiked}
                      products={products}
                      showLike={
                        currentSource !== lookBoardSourceKeys.my &&
                        !currentStatus
                      }
                      showStaticOverlay={
                        editUnderReview ||
                        (!draft && approval !== approvalStatusKeys.approved)
                      }
                      staticOverlay={
                        approval === approvalStatusKeys.rejected &&
                        !editUnderReview ? (
                          <RejectedLookBoardOverlay
                            id={id}
                            onEdit={() => handleEditBoard(products, id)}
                            onDelete={handleClickDelete}
                            reason={rejectedReason}
                          />
                        ) : (
                          <div className="h-100 flex-center position-relative flex-column">
                            <OverlayLabel text="under review" />
                            <div className={classes.infoIcons}>
                              <IconButton
                                className="mr-1"
                                size="sm"
                                variant="inverted-grey"
                                data-show-edit
                                onClick={() => handleEditBoard(products, id)}
                              >
                                <SpriteIcon name="edit" size="sm" />
                              </IconButton>
                              <IconButton
                                className="mr-1"
                                size="sm"
                                variant="inverted-grey"
                                data-id={id}
                                onClick={handleClickDelete}
                              >
                                <SpriteIcon name="trash" size="sm" />
                              </IconButton>
                              <InfoIconPopover
                                iconProps={{
                                  variant: 'inverted-grey',
                                }}
                              >
                                <p className="text-center font-normal">
                                  This look board is currently <br /> under
                                  review. We will notify you <br /> of the{' '}
                                  <span className="primary-color">
                                    approval status
                                  </span>
                                  , usually <br />{' '}
                                  <span className="primary-color">
                                    within 48 hours
                                  </span>
                                </p>
                              </InfoIconPopover>
                            </div>
                          </div>
                        )
                      }
                      disableHover={
                        editUnderReview ||
                        (!draft && approval !== approvalStatusKeys.approved)
                      }
                      hoverOverlay={
                        <div className={classes.hoverOverlay}>
                          <div />
                          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                            <IconButton
                              className="mb-2"
                              color="secondary"
                              size="sm"
                              data-id={id}
                              onClick={handlePreviewModalOpen}
                            >
                              <SpriteIcon name="loupe" size="sm" />
                            </IconButton>
                            {!currentStatus && (
                              <p
                                className={classes.hoverLabel}
                                data-id={id}
                                data-slug={slug}
                                onClick={handleClick}
                              >
                                Click to view look board details
                              </p>
                            )}
                          </div>

                          <div className="w-100 d-flex justify-content-end">
                            {currentSource !== lookBoardSourceKeys.liked && (
                              <>
                                <IconButton
                                  variant="inverted-grey"
                                  color="secondary"
                                  size="sm"
                                  className="mr-1"
                                  data-id={id}
                                  onClick={handleClickDelete}
                                >
                                  <SpriteIcon name="trash" size="sm" />
                                </IconButton>
                                <IconButton
                                  variant="inverted-grey"
                                  color="secondary"
                                  size="sm"
                                  onClick={() => handleEditBoard(products, id)}
                                >
                                  <SpriteIcon name="edit" size="sm" />
                                </IconButton>
                              </>
                            )}
                            {currentSource !== lookBoardSourceKeys.my &&
                              !currentStatus && (
                                <IconButton
                                  variant="inverted-grey"
                                  color="secondary"
                                  size="sm"
                                  className="ml-1 mr-2"
                                  data-id={id}
                                  onClick={handleOpenUnlikeModal}
                                >
                                  <SpriteIcon
                                    name={isLiked ? 'like-filled' : 'like'}
                                    size="sm"
                                  />
                                </IconButton>
                              )}
                            <IconButton
                              variant="inverted-grey"
                              size="sm"
                              color="secondary"
                              className="ml-1"
                              onClick={(event) =>
                                handleOpenSharePopover(event, id)
                              }
                            >
                              <SpriteIcon name="share" size="sm" />
                            </IconButton>
                          </div>
                        </div>
                      }
                    />
                  </div>
                )
              )}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex-fill flex-center font-title text-gray text-lg">
            List is Empty
          </div>
        )}
      </div>
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        placement="bottom"
        shareUrl={selectedLookBoard?.shareUrl}
        directImgUrl={selectedLookBoard?.lookBoardSocialImage}
        directImgUrlFacebook={selectedLookBoard?.lookBoardSocialImageFacebook}
        // onShareClick={() => handleShareClick(id)}
        isLookBoard
        lookBoardId={selectedLookBoard?.id}
      />
      <BasicModal
        open={previewModalOpen}
        onClose={handlePreviewModalClose}
        fullWidth
        maxWidth="md"
        scroll="body"
        classes={{ root: 'p-0' }}
      >
        <LookBoard
          title={selectedLookBoard?.title}
          color={selectedLookBoard?.color}
          columns={selectedLookBoard?.columns}
          items={selectedLookBoard?.products}
          collapsed={false}
          interactive={false}
        />
      </BasicModal>
      <ConfirmModal
        open={confirmModalOpen}
        onClose={handleDeleteLookBoard}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">delete</span> look board?
          </>
        }
      />
      <ConfirmModal
        open={unlikeModalOpen}
        onClose={handleUnlikeLookBoard}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">unlike</span> look board?
          </>
        }
      />
    </>
  );
};

MyLookBoardsComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  lookBoardList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentSource: PropTypes.oneOf(Object.values(lookBoardSourceKeys)).isRequired,
  currentType: PropTypes.string.isRequired,
  currentSort: PropTypes.string.isRequired,
  currentStatus: PropTypes.number.isRequired,
  onChangeSearchParams: PropTypes.func.isRequired,
  onDeleteLookBoard: PropTypes.func.isRequired,
  onUnlikeLookBoard: PropTypes.func.isRequired,
  colors: PropTypes.shape({}),
};

MyLookBoardsComponent.defaultProps = {
  colors: {},
};

export default MyLookBoardsComponent;
