import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';
import transformArrayToMap from 'utils/transformArrayToMap';

const urlPrefix = '/v1';

const inspirationImageService = {
  getImages(params, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/search`,
      params,
      config
    ).then(({ result: list }) => [transformArrayToMap(list), list]);
  },

  getSimilarImages(params, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/similar`,
      params,
      config
    ).then(({ result: list }) => [transformArrayToMap(list), list]);
  },

  getImagesByIds(images) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/inspiration-images`, {
      images,
    }).then(({ result: list }) => [transformArrayToMap(list), list]);
  },

  getImageById(id) {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/inspiration-image/${id}`);
  },

  getLookBoardDetails(id, config = null) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/inspiration-image/${id}/view-look-boards`,
      config
    );
  },
  createInspirationImage(data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image`,
      data
    ).then(({ result }) => result);
  },

  changeImagePermission(imageId, permissions) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/${imageId}/permissions`,
      { permissions }
    );
  },

  toggleLike(imageId, isLiked) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/${imageId}/like`,
      { isLiked }
    );
  },

  deleteAddedImage(imageId) {
    return AuthorizedAxiosInstance.delete(
      `${urlPrefix}/inspiration-image/${imageId}`
    );
  },

  updateImage(imageId, params) {
    return AuthorizedAxiosInstance.put(
      `${urlPrefix}/inspiration-image/${imageId}`,
      params
    );
  },

  reportImage(imageId, params) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/${imageId}/report`,
      params
    );
  },
  getImagesWithLookBoard(params, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/inspiration-image/search-get-the-look`,
      params,
      config
    ).then(({ result: list }) => [transformArrayToMap(list), list]);
  },
};

export default inspirationImageService;
