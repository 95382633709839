import lookBoardViewImg from 'assets/img/request-the-look-page/recommendations/lookboard-view.png';
import mixMatchViewImg from 'assets/img/request-the-look-page/recommendations/mix-match-view.jpg';
import productStreamViewImg from 'assets/img/request-the-look-page/recommendations/product-stream-view.png';

export const recommendationsTabKeys = {
  lookBoardView: 'lookBoardView',
  productStream: 'productStream',
  mixAndMatch: 'mixAndMatch',
};

export const contentTitlesConfig = {
  [recommendationsTabKeys.lookBoardView]: 'Curated Looks',
  [recommendationsTabKeys.mixAndMatch]: 'Mix & Match',
  [recommendationsTabKeys.productStream]: 'Product Stream View',
};

export const recommendationsTabsConfig = [
  {
    value: recommendationsTabKeys.lookBoardView,
    title: contentTitlesConfig.lookBoardView,
    icon: 'dashboard',
  },
  {
    value: recommendationsTabKeys.mixAndMatch,
    title: contentTitlesConfig.mixAndMatch,
    icon: 'shuffle-icon',
  },
  // {
  //   value: recommendationsTabKeys.productStream,
  //   title: contentTitlesConfig.productStream,
  //   icon: 'living-room',
  // },
];

export const howToViewModalConfig = [
  {
    title: 'Product Stream View',
    subTitle: `View recommendations by \n product type`,
    img: productStreamViewImg,
  },
  {
    title: 'Look Board View',
    subTitle: 'View recommendations in Look \n Board format',
    img: lookBoardViewImg,
    defaultView: true,
  },
  {
    title: 'Mix & Match',
    subTitle:
      'Create the perfect Look Board with the \n product recommendations you like best.',
    img: mixMatchViewImg,
  },
];
