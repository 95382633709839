import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TextAreaInput from 'components/inputs/TextAreaInput/TextAreaInput';
import Button from 'components/ui/Button/Button';
import Message from 'modules/dashboard/messages/components/Message/Message';
import { ChatBox } from '@rznv/components/dist/bundle';
import DateDelimiter from 'modules/dashboard/messages/components/ConversationArea/DateDelimeter';
import { messagesTabKeys } from 'modules/dashboard/messages/constants';
import classes from './ConversationArea.module.scss';

const chatBoxClasses = {
  root: classes.chatBox,
  wrapper: classes.chatBoxWrapper,
};

const ConversationArea = ({
  messages,
  sendMessage,
  isConversationChoosen,
  subjectTitle,
  hasMore,
  loadMore,
  activeTab,
}) => {
  const currentUser = useSelector((state) => state.auth.user);

  const [inputValue, setInputValue] = useState('');

  const handleChangeMessageValue = useCallback(({ target: { value } }) => {
    setInputValue(value);
  }, []);

  const handleSendMessage = useCallback(() => {
    if (!inputValue) return;
    sendMessage(inputValue);
    setInputValue('');
  }, [inputValue, sendMessage]);

  const onKeyPress = useCallback(
    (e) => {
      const keyCode = e.which || e.keyCode;

      if (keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  return (
    <div className={classes.root}>
      {subjectTitle && (
        <div className={classes.subjectArea}>
          <p className="font-semi-bold text-lg mr-1">Subject:</p>
          <p className="text-sm">{subjectTitle}</p>
        </div>
      )}
      <div className="flex-fill mb-3 h-100">
        {isConversationChoosen ? (
          <ChatBox
            messages={messages}
            classes={chatBoxClasses}
            hasNextPage={hasMore}
            myId={currentUser.id}
            loadMore={loadMore}
            overloadFactor={30}
            components={{ Message, DateDelimiter }}
          />
        ) : null}
        {activeTab === messagesTabKeys.conversations &&
        !isConversationChoosen ? (
          <div className="text-center mt-5">Please, choose a conversation</div>
        ) : null}
        {activeTab === messagesTabKeys.contacts && !isConversationChoosen && (
          <div className="text-center mt-5">
            Select user to view conversations with him
          </div>
        )}
      </div>
      <div className={classes.newMessageContainer}>
        <TextAreaInput
          className={classes.newMessageInput}
          placeholder="Write a message"
          value={inputValue}
          onChange={handleChangeMessageValue}
          onKeyPress={onKeyPress}
          rows={3}
        />
      </div>
      <div className="px-1 mb-2">
        <Button
          inline
          size="xs"
          style={{ minWidth: 100 }}
          onClick={handleSendMessage}
          disabled={!isConversationChoosen}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

ConversationArea.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sendMessage: PropTypes.func.isRequired,
  isConversationChoosen: PropTypes.bool.isRequired,
  subjectTitle: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default ConversationArea;
