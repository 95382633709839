import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classes from './ConversationArea.module.scss';

const DateDelimiter = ({ date }) => {
  const dateDelimeter = useMemo(() => moment(date).format('MMM D, YYYY'), [
    date,
  ]);

  return <div className={classes.dateDelimeter}>{dateDelimeter}</div>;
};

DateDelimiter.propTypes = {
  date: PropTypes.number.isRequired,
};

export default DateDelimiter;
