import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SpeedDialAction } from '@material-ui/lab';
import { EmailShareButton } from 'react-share';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Radio } from '@material-ui/core';
import clsx from 'clsx';
import transformArrayToMap from 'utils/transformArrayToMap';
import { routesByName } from 'constants/routes';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import showcaseService from 'modules/showcase/showcaseService';
import currentUserService from 'modules/currentUser/currentUserService';
import ambassadorService from 'modules/ambassadors/ambassadorService';
import buildResourceUrl from 'utils/buildResourceUrl';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import BasicAvatar from 'components/ui/BasicAvatar';
import Button from 'components/ui/Button/Button';
import RequestModal from 'modules/ambassadors/components/RequestModal/RequestModal';
import RequestSentModal from 'modules/ambassadors/components/RequestSentModal/RequestSentModal';
import errorToastr from 'libs/toastr/errorToastr';
import YoutubeVideoModal from 'components/modals/YoutubeVideoModal';
import showcaseClasses from 'modules/dashboard/showcase/Showcase.module.scss';
import useFollowings from 'hooks/useFollowings';
import ConfirmModal from 'components/modals/ConfirmModal';
import { messageFieldKeys } from 'components/modals/SendMessageModal/constants';
import SendMessageModal from 'components/modals/SendMessageModal/SendMessageModal';
import GalleryItem from 'modules/getTheLook/components/GalleryItem';
import {
  toggleVerificationModalAction,
  toggleYoutubeVideoModalAction,
} from 'modules/layout/store/actions';
import useUserStatus from 'hooks/useUserStatus';
import findObjectById from 'utils/findObjectById';
import FeaturedImagesSlider from 'modules/showcase/FeaturedImagesSlider';
import { maxWidthMd } from 'constants/mediaQueries';
import classes from './ShowcaseDetails.module.scss';
import useMediaQuery from '../../hooks/useMediaQuery';
import { updateIILibraryAction } from '../inspirationImage/store/actions';
import LookBoardItem from '../getTheLook/components/LookBoardItem';
import {
  toggleLikeAmbassadorLookBoardAction,
  voteAmbassadorLookBoardAction,
} from '../getTheLook/ambassadorPage/store/actions';
import successToastr from '../../libs/toastr/successToastr';
import InactiveShowcaseModal from '../../components/modals/InactiveShowcaseModal';
import CustomSpeedDial from '../../components/ui/SpeedDial/CustomSpeedDial';
import InfoIconPopover from '../../components/ui/InfoIconPopover/InfoIconPopover';
import showcaseProfile from '../../assets/img/dashboard-page/showcase/pin-card.jpg';
import { tooltipShareText } from '../../components/LookBoardShare/constants';
import AmbassadorCard from '../../components/userThumbnails/AmbassadorCard/AmbassadorCard';
import appConfig from '../../constants/appConfig';
import HeaderTooltip from '../layout/Header/HeaderTooltip';
import FeaturedLookBoardsSlider from './FeaturedLookBoardsSlider';

let imagesToShow = 4;
const radioButtons = {
  inspirations: 'Inspirations',
  lookBoards: 'Look Boards',
};

const ShowcaseDetails = ({ onGetTheLookSection, ambassadorUrlKey }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user);
  const styles = useSelector((state) => state.app.enums.styles);
  const library = useSelector((state) => state.inspirationImage.library);
  const bannerUrlPlaceholder = useSelector(
    (state) => state.app.config.endpoints.media.showcaseBanner.large
  );
  const userAvatarThumbUrl = useSelector(
    (state) => state.app.config.endpoints.media.userAvatar.thumb
  );

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  useEffect(() => {
    if (matchesMediaQuery) {
      imagesToShow = 1;
    } else {
      imagesToShow = 4;
    }
  }, [matchesMediaQuery]);

  const history = useHistory();
  const { pathname } = useLocation();
  const { urlKey } = useParams();
  const descriptionRef = useRef(null);
  const descriptionBtnRef = useRef(null);

  const [showcase, setShowcase] = useState(null);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestSentModalOpen, setRequestSentModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [lookBoardsLibrary, setLookBoardsLibrary] = useState({});
  const [styleStoryOpen, setStyleStoryOpen] = useState(false);
  const [radioWrapperHide, setRadioWrapperHide] = useState(false);
  const [mobileView, setMobileView] = useState(radioButtons.inspirations);

  const [
    isFeaturedImagesSliderEnabled,
    setIsFeaturedImagesSliderEnabled,
  ] = useState(true);
  const status = useUserStatus(showcase?.user?.badge);

  const [followed, handleFollowChange, confirmModalOpen] = useFollowings({
    followUserId: showcase?.userId,
    followUserName: `${showcase?.user.firstName} ${showcase?.user.lastName}`,
  });

  const initialFormValues = useMemo(
    () => ({
      [messageFieldKeys.name]: showcase
        ? showcase.user.displayName ??
          `${showcase.user.firstName} ${showcase.user.lastName}`
        : '',
      [messageFieldKeys.userId]: showcase?.user?.id ?? null,
    }),
    [showcase]
  );

  const handleCloseMessageModal = useCallback(() => {
    setMessageModalOpen(false);
  }, []);

  const handleCloseInactiveModal = useCallback(() => {
    history.push(routesByName.getTheLook.index);
  }, [history]);

  useEffect(() => {
    (async () => {
      try {
        const result = await showcaseService.getShowcaseByUrlKey(
          urlKey || ambassadorUrlKey
        );
        const user = await currentUserService.getUserById(result.userId);
        const currentShowcase = {
          ...result,
          user: { ...user, lookBoardsCount: result.user.lookBoards },
        };
        setShowcase(currentShowcase);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();
  }, [ambassadorUrlKey, urlKey]);

  const avatarUrl = useMemo(() => {
    if (showcase && showcase.user.avatar) {
      const { userId: showcaseUserId, hash: avatarHash } = showcase.user.avatar;
      return buildResourceUrl(userAvatarThumbUrl, showcaseUserId, avatarHash);
    }
    return null;
  }, [showcase, userAvatarThumbUrl]);

  const showcaseInActive = useMemo(() => {
    if (showcase) {
      return !showcase.active && showcase.user.id !== currentUser?.id;
    }
    return false;
    // eslint-disable-next-line
  }, [currentUser, showcase]);

  const showcaseSocials = useMemo(() => {
    const socialMediaPlatforms = [
      'instagram',
      'pinterest',
      'linkedin',
      'twitter',
      'facebook',
    ];
    const socialArr = [];
    if (showcase) {
      socialMediaPlatforms.forEach((platform) => {
        if (showcase[platform]) {
          socialArr.push({
            link: showcase[platform],
            icon: platform,
            toolTip: `Open in ${
              platform.charAt(0).toUpperCase() + platform.slice(1)
            }`,
          });
        }
      });
    }
    return socialArr;
    // eslint-disable-next-line
  }, [showcase]);

  const openSocialMediaUrl = (url) => {
    window.open(url, '_blank');
  };

  const bannerUrl = useMemo(() => {
    if (!showcase) return null;
    const {
      banner: { media, type: bannerType },
    } = showcase;
    return bannerType === bannerTypeKeys.image && media
      ? buildResourceUrl(bannerUrlPlaceholder, media.userId, media.hash)
      : null;
  }, [bannerUrlPlaceholder, showcase]);

  const loadFeaturedImages = useCallback(async () => {
    try {
      const imagesIds = showcase.featuredImages;
      const showcaseId = showcase.id;
      if (!imagesIds.length) return;
      const imagesList = await showcaseService.getImages(showcaseId, imagesIds);
      const imageListMap = transformArrayToMap(imagesList);
      dispatch(updateIILibraryAction(imageListMap));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [showcase, dispatch]);

  const loadFeaturedLookBoards = useCallback(async () => {
    try {
      const lookBoardsIds = showcase.featuredLookBoards;
      const showcaseId = showcase.id;
      if (!lookBoardsIds.length) return;
      const lookBoardsList = await showcaseService.getLookBoards(
        showcaseId,
        lookBoardsIds
      );
      setLookBoardsLibrary((prevState) => ({
        ...prevState,
        ...transformArrayToMap(lookBoardsList),
      }));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [showcase]);

  const initialLoadData = useCallback(async () => {
    await loadFeaturedImages();
    await loadFeaturedLookBoards();
  }, [loadFeaturedImages, loadFeaturedLookBoards]);

  useEffect(() => {
    if (showcase) {
      (async () => {
        await initialLoadData();
      })();
    }
    //  eslint-disable-next-line
  }, [showcase]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goToLookBoardDetailsMobile = useCallback(
    ({ currentTarget }) => {
      if (matchesMediaQuery) {
        const { id, slug } = currentTarget.dataset;
        history.push(routesByName.lookBoard.details(id, slug));
      }
    },
    [history, matchesMediaQuery]
  );

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleCloseRequestSentModal = useCallback(() => {
    setRequestSentModalOpen(false);
  }, []);

  const handleCloseStyleStory = useCallback(() => {
    setStyleStoryOpen(false);
  }, []);

  const handleToggleStyleStory = useCallback(() => {
    setStyleStoryOpen((prev) => !prev);
    // setRadioWrpperOpen((prev) => !prev);
  }, []);

  const handleToggleRadioWrapper = useCallback((value) => {
    setRadioWrapperHide(value);
  }, []);

  const handleSendRequest = useCallback(async (values) => {
    try {
      await ambassadorService.requestAccess(values);
      setRequestModalOpen(false);
      setRequestSentModalOpen(true);
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  const handleOpenMessageModal = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser?.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }

    setMessageModalOpen(true);
  }, [history, currentUser, pathname, dispatch]);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      try {
        await dispatch(
          toggleLikeAmbassadorLookBoardAction(lookBoardId, likeStatus)
        );
      } catch (e) {
        errorToastr('Error', e.message);
      }
      setLookBoardsLibrary((prevState) => ({
        ...prevState,
        [lookBoardId]: { ...prevState[lookBoardId], isLiked: likeStatus },
      }));
    },
    [dispatch]
  );

  const handleVoteLookBoard = useCallback(
    async (lookBoardId, voteValue) => {
      try {
        await dispatch(voteAmbassadorLookBoardAction(lookBoardId, voteValue));
        setLookBoardsLibrary((prevState) => ({
          ...prevState,
          [lookBoardId]: { ...prevState[lookBoardId], isVoted: true },
        }));
      } catch (e) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
        // errorToastr('Error', e.message);
      }
    },
    [history, dispatch]
  );
  const handleCopyUrl = useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    navigator.clipboard.writeText(location.href);
    successToastr('Success', 'Link successfully copied');
  }, []);

  const handlePlayVideo = useCallback(() => {
    dispatch(toggleYoutubeVideoModalAction(true));
  }, [dispatch]);

  const handleClickOutDescription = useCallback(
    (e) => {
      if (
        e.composedPath().includes(descriptionRef.current) ||
        e.composedPath().includes(descriptionBtnRef.current)
      )
        return;
      handleCloseStyleStory();
    },
    [handleCloseStyleStory]
  );

  useEffect(() => {
    return () => {
      const metaTags = document.querySelectorAll(`meta[id="helmetMeta"]`);
      const metaTitle = document.querySelector(`title[id="helmetMeta"]`);
      if (metaTitle) {
        metaTitle.textContent = 'PinTheLook';
      }
      if (metaTags) {
        metaTags.forEach((tag) => tag.remove());
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutDescription);
    return () =>
      document.removeEventListener('click', handleClickOutDescription);
    // eslint-disable-next-line
  }, []);

  const targetRef = useRef(null);

  useEffect(() => {
    if (!matchesMediaQuery && targetRef.current) {
      const elementRect = targetRef.current.getBoundingClientRect();
      const offset = 80;
      const newScrollTop = window.scrollY + elementRect.top + offset;
      window.scrollTo({ top: newScrollTop, behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [targetRef.current]);

  const handleDownloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.href = showcaseProfile;
    link.download = 'showcaseProfile.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const handleDownloadImageByLink = useCallback(() => {
    const link = document.createElement('a');
    link.href = `${appConfig.apiUrl}/v1/showcase/download/${showcase.userId}`;
    link.download = `${showcase.pageName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [showcase]);

  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(showcase.shareUrl);
    successToastr('Success', 'Link was successfully copied');
  }, [showcase]);

  const renderMeta = useCallback(
    () => (
      <Helmet>
        <title id="helmetMeta">{showcase?.metaTitle || 'PinTheLook'}</title>
        <meta
          id="helmetMeta"
          name="keywords"
          content={showcase?.metaTitle || 'PinTheLook'}
        />
        <meta
          id="helmetMeta"
          name="description"
          content={showcase?.metaDescription || ''}
        />
        <meta
          id="helmetMeta"
          property="og:image"
          content="https://dp9tl72fvj0ab.cloudfront.net/showcase-seo-preview.jpg"
        />
        <meta
          id="helmetMeta"
          property="twitter:image"
          content="https://dp9tl72fvj0ab.cloudfront.net/showcase-seo-preview.jpg"
        />
      </Helmet>
    ),
    [showcase]
  );

  const currentAmbassadorStyles = useMemo(
    () =>
      showcase?.styles.map((styleId) => findObjectById(styleId, styles)?.name),
    [showcase, styles]
  );

  const toggleFeaturedImagesSlider = useCallback(() => {
    setIsFeaturedImagesSliderEnabled((prevState) => !prevState);
  }, []);

  const sliderToggleText = useMemo(() => {
    return isFeaturedImagesSliderEnabled ? 'View all' : 'View less';
  }, [isFeaturedImagesSliderEnabled]);

  const toggleMobileView = useCallback((view) => {
    setMobileView(view);
  }, []);

  const showMobileView = useCallback(
    (view) => (matchesMediaQuery && mobileView === view) || !matchesMediaQuery,
    [matchesMediaQuery, mobileView]
  );

  return (
    <>
      <div
        className={clsx(classes.contentWrapper, {
          [classes.widerContentWrapper]:
            onGetTheLookSection || matchesMediaQuery,
          [classes.inactive]: showcaseInActive,
        })}
      >
        <div className={classes.showcaseContainer}>
          {!onGetTheLookSection &&
            (matchesMediaQuery ? (
              <div
                className={clsx(
                  'mt-2 mb-3 d-flex align-items-center text-xs',
                  matchesMediaQuery && 'px-2'
                )}
                onClick={handleGoBack}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <SpriteIcon
                    name="left-arrow"
                    size="sm"
                    className="mr-1 primary-color"
                  />
                  <span>Back</span>
                </div>
              </div>
            ) : null)}
          <div
            className={clsx(
              'd-flex justify-content-between mb-3 align-items-center',
              matchesMediaQuery && 'px-2'
            )}
          >
            <h1 className={classes.title}>Showcase Page</h1>
            <div>
              <Button
                variant="outlined"
                inline
                size="sm"
                onClick={handleCopyUrl}
              >
                <span className="px-2">Copy Page URL</span>
              </Button>
            </div>
          </div>
          {showcase && (
            <>
              {renderMeta()}
              <div
                ref={targetRef}
                className={showcaseClasses.bannerContainer}
                style={{
                  background:
                    showcase.banner.type === bannerTypeKeys.color
                      ? showcase.banner.color
                      : 'none',
                }}
              >
                <div className={showcaseClasses.mask} />
                {showcase.banner.type === bannerTypeKeys.image && (
                  <img src={bannerUrl} alt="" />
                )}
                <div className={showcaseClasses.bannerTitleBlock}>
                  <h2 className={`${showcaseClasses.mainTitle} mb-3`}>
                    {showcase.pageName}
                  </h2>
                  {showcase.isSubtitleActive && (
                    <p className={showcaseClasses.subtitle}>
                      {showcase.subtitle}
                    </p>
                  )}
                </div>
              </div>
              <div className={showcaseClasses.userCard}>
                <div className="d-flex">
                  <div className="flex-fill pl-0 pl-md-3">
                    <div
                      className={clsx(
                        'd-flex align-items-center',
                        matchesMediaQuery && 'flex-column'
                      )}
                    >
                      <BasicAvatar
                        firstName={showcase.user.firstName}
                        lastName={showcase.user.lastName}
                        src={avatarUrl}
                        className={showcaseClasses.avatar}
                      />
                      <div className="d-flex flex-column flex-lg-row align-items-center w-100">
                        <p
                          className={`${showcaseClasses.fullName} flex-fill text-ellipsis text-nowrap mr-1`}
                        >
                          {showcase.user.displayName ??
                            `${showcase.user.firstName} ${showcase.user.lastName}`}
                        </p>
                        {status && (
                          <div className={showcaseClasses.status}>
                            <img src={status.icon} alt="status" />
                          </div>
                        )}
                        {currentUser?.id !== showcase.userId && (
                          <Button
                            variant={followed ? 'contained' : 'outlined'}
                            className={clsx(
                              classes.followBtn,
                              {
                                [classes.followed]: followed,
                              },
                              'ml-1'
                            )}
                            size={matchesMediaQuery ? 'sm' : 'md'}
                            onClick={handleFollowChange}
                          >
                            {followed && (
                              <SpriteIcon
                                name="rounded-check"
                                size={matchesMediaQuery ? 'xs' : 'sm'}
                                className="mr-1"
                              />
                            )}
                            {followed ? 'Following' : 'Follow'}
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className={clsx(classes.socialBlock)}>
                      <div className="d-flex align-items-center w-100">
                        <div
                          ref={descriptionBtnRef}
                          className={matchesMediaQuery && 'w-100'}
                        >
                          <Button
                            inline
                            size="md"
                            onClick={handleToggleStyleStory}
                            className={matchesMediaQuery && 'w-100'}
                          >
                            My Style Story
                          </Button>
                        </div>
                        {showcase.videoUrl && !!showcase.videoUrlIsActive ? (
                          <div className={showcaseClasses.videoIcon}>
                            <SpriteIcon
                              onClick={handlePlayVideo}
                              color="#D9CAB6"
                              className="h-auto w-100 cursor-pointer"
                              name="play-btn-filled"
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className={classes.shareWrapper}>
                        <CustomSpeedDial
                          mainIcon={
                            <HeaderTooltip
                              text={tooltipShareText.download}
                              placement="top"
                              className={classes.copyLinkToolTip}
                            >
                              <IconButton
                                variant="inverted-white"
                                color="secondary"
                                size="md"
                                className="mt-auto"
                              >
                                <SpriteIcon name="share-icon" size="md" />
                              </IconButton>
                            </HeaderTooltip>
                          }
                          customHandler={handleToggleRadioWrapper}
                        >
                          <SpeedDialAction
                            key="showcaseCardShare"
                            icon={
                              <InfoIconPopover
                                iconProps={{
                                  className: '',
                                  size: 'md',
                                  color: 'primary',
                                }}
                                size="md"
                                vertAlignment="top"
                                customIcon="pin-icon"
                              >
                                <img
                                  src={showcaseProfile}
                                  alt="showcase"
                                  style={{ width: '400px' }}
                                />
                                <div
                                  className={classes.showcasePopOverText}
                                  onClick={handleDownloadImage}
                                >
                                  <SpriteIcon
                                    name="download-arrow"
                                    width="25"
                                    height="25"
                                    className={classes.showcasePopOverIcon}
                                  />
                                  Download Pin the Look Card
                                </div>
                              </InfoIconPopover>
                            }
                            tooltipTitle={tooltipShareText.pinIcon}
                          />
                          <SpeedDialAction
                            key="ambassadorCardShare"
                            icon={
                              <InfoIconPopover
                                iconProps={{
                                  className: '',
                                  size: 'md',
                                  color: 'primary',
                                }}
                                size="md"
                                vertAlignment="top"
                                customIcon="user"
                              >
                                <AmbassadorCard
                                  {...showcase}
                                  {...showcase?.user}
                                  bannerUrl={bannerUrl}
                                  avatarUrl={avatarUrl}
                                  lookBoardsCount={
                                    showcase?.user.lookBoardsCount
                                  }
                                  title={showcase?.pageName}
                                  styles={currentAmbassadorStyles}
                                  disableHover
                                  isShare
                                />
                                <div
                                  className={classes.showcasePopOverText}
                                  onClick={handleDownloadImageByLink}
                                >
                                  <SpriteIcon
                                    name="download-arrow"
                                    width="25"
                                    height="25"
                                    className={classes.showcasePopOverIcon}
                                  />
                                  Download Showcase Card
                                </div>
                              </InfoIconPopover>
                            }
                            tooltipTitle={tooltipShareText.profileIcon}
                          />
                          <SpeedDialAction
                            key="ambassadorEmail"
                            icon={
                              <EmailShareButton
                                url={showcase?.shareUrl}
                                subject="Check Out My Pin the Look Look Board"
                              >
                                <IconButton color="primary" size="md">
                                  <SpriteIcon name="envelope" size="md" />
                                </IconButton>
                              </EmailShareButton>
                            }
                            tooltipTitle={tooltipShareText.emailLinkShowcase}
                          />
                        </CustomSpeedDial>
                        {!!showcaseSocials.length && (
                          <CustomSpeedDial
                            mainIcon={
                              <HeaderTooltip
                                text={tooltipShareText.socialAccounts}
                                placement="top"
                                className={classes.copyLinkToolTip}
                              >
                                <IconButton
                                  variant="inverted-white"
                                  color="secondary"
                                  size="md"
                                  className="mt-auto"
                                >
                                  <SpriteIcon name="social-share" size="md" />
                                </IconButton>
                              </HeaderTooltip>
                            }
                            customHandler={handleToggleRadioWrapper}
                          >
                            {showcaseSocials.map((item) => {
                              return (
                                <SpeedDialAction
                                  key={item.icon}
                                  icon={
                                    <IconButton
                                      color="primary"
                                      size="md"
                                      onClick={() =>
                                        openSocialMediaUrl(item.link)
                                      }
                                    >
                                      <SpriteIcon name={item.icon} size="md" />
                                    </IconButton>
                                  }
                                  tooltipTitle={item.toolTip}
                                />
                              );
                            })}
                          </CustomSpeedDial>
                        )}
                        <HeaderTooltip
                          text={tooltipShareText.copyLink}
                          placement="top"
                          className={classes.copyLinkToolTip}
                        >
                          <IconButton
                            variant="inverted-white"
                            color="secondary"
                            size="md"
                            className={classes.copyLinkButton}
                            onClick={handleCopyToClipboard}
                          >
                            <SpriteIcon name="copy-link" size="md" />
                          </IconButton>
                        </HeaderTooltip>

                        {currentUser?.id !== showcase.userId ? (
                          <HeaderTooltip
                            text={tooltipShareText.sendMessage}
                            placement="top"
                            className={classes.copyLinkToolTip}
                          >
                            <IconButton
                              variant="inverted-white"
                              color="secondary"
                              size="md"
                              className={classes.messageButton}
                              onClick={handleOpenMessageModal}
                            >
                              <SpriteIcon name="send-message" size="md" />
                            </IconButton>
                          </HeaderTooltip>
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {styleStoryOpen && (
                  <div
                    ref={descriptionRef}
                    className={showcaseClasses.styleStoryPopup}
                  >
                    <h2
                      className={`${showcaseClasses.mainTitle} mb-3 text-center`}
                    >
                      My Style Story
                    </h2>
                    <div className="d-flex flex-column flex-md-row mb-2">
                      <p
                        className="text-lg font-semi-bold d-inline mr-1"
                        style={{ lineHeight: 2.2 }}
                      >
                        Favorite Styles:
                      </p>
                      <div className="d-flex flex-wrap align-items-center">
                        {showcase.styles.slice(0, 3).map((styleId) => (
                          <div
                            key={styleId}
                            className={showcaseClasses.styleChip}
                          >
                            {findObjectById(styleId, styles)?.name}
                          </div>
                        ))}
                        {showcase.styles.length > 3 && (
                          <SpriteIcon
                            size="sm"
                            color="#87bdc3"
                            name="plus"
                            style={{ marginBottom: 4 }}
                          />
                        )}
                      </div>
                    </div>
                    <p className="text-gray">{showcase.description}</p>
                  </div>
                )}
              </div>
              <div
                className={clsx(
                  'background-white px-2 py-3',
                  matchesMediaQuery && 'mx-2',
                  classes.radioWrapper,
                  (radioWrapperHide || styleStoryOpen) &&
                    classes.hiddenRadioWrapper
                )}
              >
                {matchesMediaQuery && (
                  <div className="mb-1">
                    {Object.keys(radioButtons).map((radioBtn) => (
                      <FormControlLabel
                        control={
                          <Radio
                            checked={mobileView === radioButtons[radioBtn]}
                            onClick={() =>
                              toggleMobileView(radioButtons[radioBtn])
                            }
                            color="secondary"
                          />
                        }
                        label={
                          <div className="fw-500 text-sm">
                            {radioButtons[radioBtn]}
                          </div>
                        }
                      />
                    ))}
                  </div>
                )}
                Swipe Right to Scroll through Images
              </div>
              {showMobileView(radioButtons.inspirations) &&
                ((onGetTheLookSection &&
                  showcase.featuredImages.length === 0) || (
                  <div
                    className={clsx(
                      'background-white px-5 pb-3 mb-5',
                      matchesMediaQuery && 'mx-2'
                    )}
                    style={{
                      paddingLeft: matchesMediaQuery && '0',
                      paddingRight: matchesMediaQuery && '0',
                    }}
                  >
                    {!matchesMediaQuery && (
                      <p className={`${classes.title} mb-3`}>
                        Featured inspirations
                      </p>
                    )}
                    {showcase.featuredImages.length > 0 ? (
                      <>
                        {isFeaturedImagesSliderEnabled &&
                        showcase.featuredImages.length > imagesToShow ? (
                          <FeaturedImagesSlider
                            slidesToShow={imagesToShow}
                            featuredImages={showcase.featuredImages}
                            noArrows={matchesMediaQuery}
                          />
                        ) : (
                          <div
                            className={showcaseClasses.featuredImagesContainer}
                          >
                            {showcase.featuredImages.map((imageId) => {
                              const imageMap = library;
                              return (
                                <div key={imageId}>
                                  {imageMap[imageId] ? (
                                    <GalleryItem
                                      image={imageMap[imageId]}
                                      isShowcase
                                    />
                                  ) : (
                                    'Loading...'
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {!matchesMediaQuery &&
                          showcase.featuredImages.length > imagesToShow && (
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className={classes.link}
                                onClick={toggleFeaturedImagesSlider}
                              >
                                <span className="mr-1">{sliderToggleText}</span>
                                <SpriteIcon name="right-arrow" size="sm" />
                              </button>
                            </div>
                          )}
                      </>
                    ) : (
                      <div
                        className="flex-center font-title text-gray text-lg"
                        style={{ height: 250 }}
                      >
                        List is Empty
                      </div>
                    )}
                  </div>
                ))}
              {showMobileView(radioButtons.lookBoards) &&
                ((onGetTheLookSection &&
                  showcase.featuredLookBoards.length === 0 &&
                  showcase.lookBoards.length === 0) || (
                  <div
                    className={clsx(
                      'background-white px-5 py-3 mb-5',
                      matchesMediaQuery && 'mx-2'
                    )}
                    style={{
                      paddingLeft: matchesMediaQuery && 0,
                      paddingRight: matchesMediaQuery && 0,
                    }}
                  >
                    {!matchesMediaQuery && (
                      <p className={`${classes.title} mb-3`}>Look Boards</p>
                    )}
                    {matchesMediaQuery && (
                      <FeaturedLookBoardsSlider
                        lookBoardsLibrary={lookBoardsLibrary}
                        onToggleLike={handleToggleLikeLookBoard}
                        onVote={handleVoteLookBoard}
                        showcase={showcase}
                        noArrows={matchesMediaQuery}
                        slidesToShow={imagesToShow}
                      />
                    )}
                    {!matchesMediaQuery &&
                      ((onGetTheLookSection &&
                        showcase.featuredLookBoards.length === 0) || (
                        <div
                          className={clsx(
                            showcaseClasses.featuredLookBoards,
                            'row'
                          )}
                        >
                          {showcase.featuredLookBoards.length > 0 ? (
                            showcase.featuredLookBoards.map((lookBoardId) => (
                              <div
                                key={lookBoardId}
                                data-id={lookBoardId}
                                data-slug={lookBoardsLibrary[lookBoardId]?.slug}
                                onClick={goToLookBoardDetailsMobile}
                                className="col-md-3 py-1"
                              >
                                {lookBoardsLibrary[lookBoardId] ? (
                                  <LookBoardItem
                                    lookBoard={lookBoardsLibrary[lookBoardId]}
                                    user={showcase.user}
                                    products={
                                      lookBoardsLibrary[lookBoardId].products
                                    }
                                    onToggleLike={handleToggleLikeLookBoard}
                                    onVote={handleVoteLookBoard}
                                    hideLikeVote
                                  />
                                ) : null}
                              </div>
                            ))
                          ) : (
                            <div
                              className="flex-center font-title text-gray text-lg mb-3 w-100"
                              style={{ height: 250 }}
                            >
                              No Featured Look Boards
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
      <RequestModal
        open={requestModalOpen}
        onClose={handleCloseRequestModal}
        onSubmit={handleSendRequest}
      />
      <RequestSentModal
        open={requestSentModalOpen}
        onClose={handleCloseRequestSentModal}
      />
      <ConfirmModal
        onClose={handleFollowChange}
        open={confirmModalOpen}
        title="Are you sure you want to unfollow user?"
      />
      <SendMessageModal
        open={messageModalOpen}
        onClose={handleCloseMessageModal}
        initialValues={initialFormValues}
      />
      <YoutubeVideoModal videoUrl={showcase?.videoUrl} />
      <InactiveShowcaseModal
        open={showcaseInActive}
        onClose={handleCloseInactiveModal}
      />
    </>
  );
};

ShowcaseDetails.propTypes = {
  userId: PropTypes.number,
  onGetTheLookSection: PropTypes.bool,
  ambassadorUrlKey: PropTypes.string,
};

ShowcaseDetails.defaultProps = {
  onGetTheLookSection: false,
  ambassadorUrlKey: null,
  userId: null,
};

export default ShowcaseDetails;
