import { useCallback, useRef, useEffect } from 'react';
import axios from 'axios';

const useCancelToken = () => {
  const cancelToken = useRef(null);

  const createCancelToken = useCallback(() => {
    cancelToken.current = axios.CancelToken.source();
    return cancelToken.current.token;
  }, [cancelToken]);

  const handleCancelRequest = useCallback(() => {
    if (cancelToken && cancelToken.current) {
      cancelToken.current.cancel();
    }
  }, [cancelToken]);

  const isCancelled = useCallback(
    (error) => axios.isCancel(error.cancelError),
    []
  );

  useEffect(() => {
    return () => {
      handleCancelRequest();
    };
  }, [handleCancelRequest]);

  return [createCancelToken, isCancelled, handleCancelRequest];
};

export default useCancelToken;
