import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import canvasClasses from 'modules/curateTheLook/createLookBoard/components/CreateLookBoardCanvas/CreateLookBoardCanvas.module.scss';
import useEventListener from 'hooks/useEventListener';
import classes from './EditableLookBoardHeader.module.scss';

const EditableLookBoardHeader = ({ color, title, onDisableEditMode }) => {
  const inputRef = useRef(null);

  const [inputValue, setInputValue] = useState(title);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChangeInputValue = useCallback(({ currentTarget: { value } }) => {
    if (value.length < 60) {
      setInputValue(value);
    }
  }, []);

  const handleClick = useCallback(
    ({ target }) => {
      if (!inputRef.current.contains(target)) {
        onDisableEditMode({
          title: inputValue || title,
        });
      }
    },
    [inputRef, onDisableEditMode, inputValue, title]
  );

  useEventListener(document, 'mousedown', handleClick);

  useEffect(() => {
    if (inputRef.current) {
      const { value } = inputRef.current;
      inputRef.current.value = '';
      inputRef.current.value = value;
    }
  }, []);

  return (
    <div
      className={canvasClasses.title}
      style={{ backgroundColor: color, position: 'relative' }}
    >
      <textarea
        ref={inputRef}
        value={inputValue}
        placeholder={title}
        onChange={handleChangeInputValue}
        className={classes.titleInput}
        rows={1}
      />
    </div>
  );
};

EditableLookBoardHeader.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onDisableEditMode: PropTypes.func.isRequired,
};

export default EditableLookBoardHeader;
