import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import BasicAvatar from 'components/ui/BasicAvatar';
import warningToastr from 'libs/toastr/warningToastr';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import votedIcon from 'assets/icons/voted.svg';
import doneIcon from 'assets/icons/done.svg';
import clsx from 'clsx';
import Button from 'components/ui/Button/Button';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import classes from './HoverOverlay.module.scss';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../../constants/mediaQueries';
import showcaseService from '../../../showcase/showcaseService';

const HoverOverlay = ({
  userId,
  firstName,
  lastName,
  avatarUrl,
  isLiked,
  isVoted,
  isAmbassador,
  infoPopoverConfig,
  onToggleLike,
  onVote,
  onClick,
  onShare,
  isGalleryView,
  onOpenModal,
  onViewSimilar,
  onViewMixMatch,
  onViewCurate,
  displayName,
  shareUrl,
}) => {
  const history = useHistory();
  const [isVoteOpen, setIsVoteOpen] = useState(false);
  const [selectedVote, setSelectedVote] = useState(null);
  const cardRef = useRef();
  const dropDownWrapper = useRef();
  const votedOptions = [
    'Best Match',
    'Most Stylish',
    'Most Unique',
    'Best Combo',
  ];
  const buttonStyles = {
    background: '#bdbdbd',
    borderRadius: '10px',
    padding: '6px',
    border: 'none',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12px',
    textTransform: 'uppercase',
  };
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const handleVoteOpen = useCallback(() => {
    setIsVoteOpen(true);

    const slickSlider = cardRef.current.closest('.slick-slider');
    const slickList = cardRef.current.closest('.slick-list');

    if (slickSlider) {
      slickSlider.style.height = `${slickSlider.clientHeight}px`;
      slickList.style.zIndex = 1;
      slickList.style.height = `${slickList.scrollHeight}px`;
      slickList.style.marginBottom = `-351px`;
    }
  }, []);

  const handleBlur = useCallback(() => {
    setIsVoteOpen(false);

    const slickSlider = cardRef.current.closest('.slick-slider');
    const slickList = cardRef.current.closest('.slick-list');

    if (slickSlider) {
      slickList.style.zIndex = 0;
    }
  }, []);

  const selectVoteHandler = useCallback((value) => {
    setSelectedVote(value);
  }, []);

  const handleUserClick = useCallback(async () => {
    if (isAmbassador) {
      try {
        const showcases = await showcaseService.searchShowcase({
          colors: [],
          offset: 0,
          profession: '',
          roomTypes: [],
          search: `${firstName} ${lastName}`,
          styles: [],
          subStyles: {},
        });
        const selectedUser = showcases.find(
          (showcase) => showcase.userId === userId
        );
        if (!selectedUser) throw new Error('Selected user not found');
        // await dispatch(findAmbassadorUserAction(firstName, lastName, userId));
      } catch (error) {
        warningToastr('Warning', 'The showcase of this user is deactivated');
        return;
      }
      window.open(shareUrl, '_blank');
    } else {
      history.push(routesByName.getTheLook.users.details(userId));
    }
  }, [firstName, history, isAmbassador, lastName, userId, shareUrl]);

  return (
    <div
      className={clsx(classes.lookBoardOverlay, classes.darkOverlay)}
      ref={cardRef}
      onMouseLeave={handleBlur}
    >
      <div className="w-100 d-flex justify-content-end p-1">
        {infoPopoverConfig && (
          <InfoIconPopover
            iconProps={{
              className: 'mr-1',
              variant: 'inverted-grey',
            }}
          >
            <div className={classes.infoBlock}>
              <p className="font-normal font-semi-bold">
                {infoPopoverConfig.title}
              </p>
              <p className="font-normal text-sm">
                {infoPopoverConfig.description}
              </p>
            </div>
          </InfoIconPopover>
        )}
        <IconButton variant="inverted-grey" size="sm" onClick={onShare}>
          <SpriteIcon name="share" size="sm" />
        </IconButton>
      </div>
      <div className="d-flex flex-column flex-fill flex-center">
        {onOpenModal && (
          <IconButton
            color="secondary"
            size="sm"
            onClick={() => onOpenModal()}
            className="mb-1"
          >
            <SpriteIcon name="loupe" size="sm" />
          </IconButton>
        )}
        <span className={classes.overlayTitle} onClick={onClick}>
          Select Look Board
        </span>
      </div>
      {isGalleryView && (
        <div className={classes.buttonWrapper}>
          <Button
            size="sm"
            inline
            className="mt-3"
            onClick={onViewSimilar}
            style={buttonStyles}
          >
            <SpriteIcon name="dashboard" size="sm" className="mr-1" />
            <span>
              View <br /> Similar
            </span>
          </Button>
          <Button
            size="sm"
            inline
            className="mt-3"
            onClick={onViewMixMatch}
            style={buttonStyles}
          >
            <SpriteIcon name="shuffle-icon" size="sm" className="mr-1" />
            <span>
              Mix & <br /> Match
            </span>
          </Button>
          <Button
            size="sm"
            inline
            className="mt-3"
            onClick={onViewCurate}
            style={buttonStyles}
          >
            <SpriteIcon name="dashboard" size="sm" className="mr-1" />
            <span>
              Curate <br /> Similar
            </span>
          </Button>
        </div>
      )}
      <div className={clsx(classes.bottomBar, classes.transparentBar)}>
        <div
          className={clsx(classes.userBlock, userId && 'cursor-pointer')}
          onClick={userId && handleUserClick}
        >
          <BasicAvatar
            firstName={firstName}
            lastName={lastName}
            src={avatarUrl}
            className="mr-2"
          />
          <p className="text-ellipsis text-sm font-semi-bold text-white">
            {displayName ?? `${firstName} ${lastName}`}
          </p>
        </div>
        <div className="w-100 d-flex justify-content-end py-1 position-relative">
          <IconButton
            className={clsx(userId && 'mr-1')}
            variant="inverted-grey"
            size="sm"
            onClick={onToggleLike}
          >
            <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
          </IconButton>
          {onVote && (
            <>
              {isVoted ? (
                <div
                  className="cursor-pointer"
                  style={{ width: '32px' }}
                  onClick={handleVoteOpen}
                >
                  <img src={votedIcon} alt="voted" />
                </div>
              ) : (
                <IconButton
                  variant="inverted-grey"
                  size="sm"
                  onMouseEnter={handleVoteOpen}
                  className={classes.vote}
                >
                  <span>Vote</span>
                </IconButton>
              )}
            </>
          )}
          <div
            ref={dropDownWrapper}
            className={classes.dropdownWrapper}
            style={
              isVoteOpen
                ? { opacity: '1', top: matchesMediaQuery ? '-270px' : '' }
                : {}
            }
            onMouseLeave={handleBlur}
          >
            {isVoted ? (
              <div className={classes.thanksForVote}>
                <div
                  className={clsx(
                    classes.thanksForVoteImage,
                    'd-flex align-items-center justify-content-center mb-3'
                  )}
                >
                  <img src={doneIcon} alt="done" />
                </div>
                <span className="text-center">
                  Thanks <br /> for your vote!
                </span>
              </div>
            ) : (
              <>
                <div className="pr-4">
                  <div className={classes.dropdownTitle}>Vote for One:</div>
                  {votedOptions.map((voteItem) => {
                    return (
                      <li
                        key={voteItem}
                        className="d-flex align-items-center"
                        onClick={() => selectVoteHandler(voteItem)}
                      >
                        <div
                          className={clsx(
                            classes.dropdownSelector,
                            'd-flex align-items-center justify-content-center'
                          )}
                        >
                          {selectedVote === voteItem && (
                            <img src={doneIcon} alt="done" />
                          )}
                        </div>
                        <div>{voteItem}</div>
                      </li>
                    );
                  })}
                </div>
                <Button
                  inline
                  variant="contained"
                  size="custom"
                  onClick={() => onVote(selectedVote)}
                  disabled={!selectedVote}
                  className="py-1 w-100"
                >
                  Vote
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

HoverOverlay.propTypes = {
  userId: PropTypes.number,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  isLiked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  isVoted: PropTypes.bool,
  isAmbassador: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    .isRequired,
  infoPopoverConfig: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  onToggleLike: PropTypes.func.isRequired,
  onVote: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
  isGalleryView: PropTypes.bool,
  onOpenModal: PropTypes.func,
  onViewSimilar: PropTypes.func,
  onViewMixMatch: PropTypes.func,
  onViewCurate: PropTypes.func,
  displayName: PropTypes.string,
  shareUrl: PropTypes.string,
};

HoverOverlay.defaultProps = {
  avatarUrl: null,
  infoPopoverConfig: null,
  isVoted: false,
  userId: null,
  isGalleryView: false,
  displayName: null,
  shareUrl: null,
  onOpenModal: () => {},
  onViewSimilar: () => {},
  onViewMixMatch: () => {},
  onViewCurate: () => {},
};

export default HoverOverlay;
