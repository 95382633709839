import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import BasicAvatar from 'components/ui/BasicAvatar';
import { mediaShape } from 'modules/media/propTypes';
import buildResourceUrl from 'utils/buildResourceUrl';
import classes from './MessageItem.module.scss';

const MessageItem = ({
  text,
  date,
  user: { firstName, lastName, avatar },
  userAvatarUrl,
  id,
  handleSelect,
}) => {
  const avatarUrl = useMemo(
    () => avatar && buildResourceUrl(userAvatarUrl, avatar.userId, avatar.hash),
    [userAvatarUrl, avatar]
  );

  const onSelect = useCallback(
    ({ currentTarget }) => {
      const convId = currentTarget.dataset.id;
      handleSelect(convId);
    },
    [handleSelect]
  );
  return (
    <div
      className={clsx(classes.root, 'd-flex align-items-center mr-2 pr-2 ml-1')}
      data-id={id}
      onClick={onSelect}
    >
      <div className="mr-2">
        <BasicAvatar
          className={classes.avatar}
          firstName={firstName}
          lastName={lastName}
          src={avatarUrl}
        />
      </div>
      <div className={clsx(classes.textContent, 'text-xs text-ellipsis')}>
        <p className="text-ellipsis text-nowrap mb-1">{text}</p>
        <span className="text-gray">{moment(date).fromNow(true)}</span>
      </div>
    </div>
  );
};

MessageItem.propTypes = {
  text: PropTypes.string.isRequired,
  date: PropTypes.number.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: mediaShape,
    lastMessageDate: PropTypes.number,
  }).isRequired,
  userAvatarUrl: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default MessageItem;
