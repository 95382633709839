import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { uniq } from 'lodash-es';
import { routesByName } from 'constants/routes';
import {
  myRequestsTabsConfig,
  myRequestsAmbassadorTabsConfig,
} from 'modules/dashboard/myRequests/constants';
import {
  requestSourceKeys,
  requestTypeKeys,
  requestSortKeys,
} from 'constants/requestSearchParams';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import {
  updateRequestDataAction as updateDataRTL,
  unselectImageAction as resetDataRTL,
} from 'modules/requestTheLook/store/actions';
import {
  selectImageAction as selectImageCTL,
  updateLookBoardDataAction,
  updateCurateStateAction,
} from 'modules/curateTheLook/store/actions';
import {
  // toggleUnderConstructionModalAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import requestService from 'modules/request/requestService';
import currentUserService from 'modules/currentUser/currentUserService';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import Tab from 'components/ui/Tab/Tab';
import Button from 'components/ui/Button/Button';
import MyRequestsComponent from 'modules/dashboard/myRequests/MyRequestsComponent';
import ConfirmModal from 'components/modals/ConfirmModal';
import RequestDetailsModal from 'modules/dashboard/myRequests/components/RequestDetailsModal/RequestDetailsModal';
import accountClasses from 'modules/account/Account.module.scss';
import buildResourceUrl from '../../../utils/buildResourceUrl';
import { getUserAvatarUrl } from '../../app/store/selectors';

// TODO: Get limit value from application config
const limit = 10;

const initialSearchParams = {
  source: requestSourceKeys.my,
  offset: 0,
  sort: requestSortKeys.newest,
  type: requestTypeKeys.all,
};

const MyRequestsContainer = ({
  isAmbassador,
  // toggleUnderConstructionModal,
  hasVerifiedEmail,
}) => {
  const dispatch = useDispatch();
  const requesterAvatar = getUserAvatarUrl(
    useSelector((state) => state.app.config)
  );

  const history = useHistory();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [requestList, setRequestList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [requestTarget, setRequestTarget] = useState(null);
  const [requestDetailsData, setRequestDetailsData] = useState(null);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [requestEditData, setRequestEditData] = useState(null);

  const axiosCancelToken = useRef(null);

  const loadMore = useCallback(async () => {
    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel();
    }
    axiosCancelToken.current = axios.CancelToken.source();

    setLoading(true);

    try {
      const { source, ...params } = searchParams;
      const preparedSearchParams = {
        ...params,
        type: params.type === requestTypeKeys.all ? undefined : params.type,
      };

      const list =
        source === requestSourceKeys.my
          ? await currentUserService.getRequests(
              preparedSearchParams,
              axiosCancelToken.current.token
            )
          : await requestService.getRequests(
              source,
              preparedSearchParams,
              axiosCancelToken.current.token
            );

      if (list.length > 0) {
        const imagesIds = uniq(list.map(({ imageId }) => imageId));
        const [imagesList] = await inspirationImageService.getImagesByIds(
          imagesIds
        );
        dispatch(updateIILibraryAction(imagesList));

        setRequestList((prevState) => [...prevState, ...list]);
        setSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + list.length,
        }));
      }

      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [searchParams, dispatch, axiosCancelToken]);

  const loadFirst = useCallback(async () => {
    if (axiosCancelToken.current) {
      axiosCancelToken.current.cancel();
    }
    axiosCancelToken.current = axios.CancelToken.source();

    setLoading(true);

    try {
      const { source, ...params } = searchParams;
      const preparedSearchParams = {
        ...params,
        type: params.type === requestTypeKeys.all ? undefined : params.type,
      };

      const list =
        source === requestSourceKeys.my
          ? await currentUserService.getRequests(
              preparedSearchParams,
              axiosCancelToken.current.token
            )
          : await requestService.getRequests(
              source,
              preparedSearchParams,
              axiosCancelToken.current.token
            );

      if (list.length > 0) {
        const imagesIds = uniq(list.map(({ imageId }) => imageId));
        const [imagesList] = await inspirationImageService.getImagesByIds(
          imagesIds
        );
        dispatch(updateIILibraryAction(imagesList));

        setRequestList(list);
        setSearchParams((prevState) => ({
          ...prevState,
          offset: list.length,
        }));
      }

      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (!axios.isCancel(e.cancelError)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    if (!hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams, hasVerifiedEmail]);

  const handleTabChange = useCallback(
    (source) => {
      if (searchParams.source === source) return;
      setSearchParams((prevState) => ({ ...prevState, source, offset: 0 }));
      setRequestList([]);
      setHasMore(true);
    },
    [searchParams.source]
  );

  const handleChangeRequestType = useCallback(
    (type) => {
      if (searchParams.type === type) return;
      setSearchParams((prevState) => ({ ...prevState, type, offset: 0 }));
      setRequestList([]);
      setHasMore(true);
    },
    [searchParams.type]
  );

  const handleChangeSortType = useCallback(
    (sort) => {
      if (searchParams.sort === sort) return;
      setSearchParams((prevState) => ({ ...prevState, sort, offset: 0 }));
      setRequestList([]);
      setHasMore(true);
    },
    [searchParams.sort]
  );

  const handleOpenDeleteModal = useCallback((requestId) => {
    setRequestTarget(Number(requestId));
    setDeleteModalOpen(true);
  }, []);

  const handleDeleteRequest = useCallback(
    async (confirm) => {
      if (confirm) {
        try {
          if (searchParams.source === requestSourceKeys.my) {
            await requestService.deleteRequest(requestTarget);
          } else if (searchParams.source === requestSourceKeys.pending) {
            await requestService.deletePendingRequest(requestTarget);
          }
          successToastr('Info', 'The Request Have Been Removed');
          setRequestList((prevState) =>
            prevState.filter(({ id }) => id !== requestTarget)
          );
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      }

      setDeleteModalOpen(false);
      if (detailModalOpen && confirm) {
        setDetailModalOpen(false);
      }
    },
    [searchParams.source, requestTarget, detailModalOpen]
  );

  const handleOpenDetail = useCallback(async (data) => {
    const requestUser = await currentUserService.getUserById(data.requesterId);
    const dataWithRequesterInfo = { ...data };
    if (requestUser.avatar) {
      dataWithRequesterInfo.requesterImage = buildResourceUrl(
        requesterAvatar,
        requestUser.avatar.userId,
        requestUser.avatar.hash
      );
    }
    dataWithRequesterInfo.requesterFirstName = requestUser.firstName;
    dataWithRequesterInfo.requesterLastName = requestUser.lastName;
    dataWithRequesterInfo.requesterDisplayName = requestUser.displayName;
    setRequestDetailsData(dataWithRequesterInfo);
    setDetailModalOpen(true);
  }, [requesterAvatar]);

  const handleDetailModalClose = useCallback(() => {
    setDetailModalOpen(false);
  }, []);

  const handleOnCurate = useCallback(
    ({ imageId, requestId, message }) => {
      dispatch(selectImageCTL(imageId));
      dispatch(updateLookBoardDataAction({ requestId }));
      dispatch(
        updateCurateStateAction({
          requestInfo: {
            title: requestDetailsData.requestTitle,
            priorityItems: requestDetailsData.priorityItems,
            requesterId: requestDetailsData.requesterId,
            message,
          },
        })
      );
      history.push(routesByName.curateTheLook.index);
    },
    [history, dispatch, requestDetailsData]
  );

  // const handleUnderConstructionModalOpen = useCallback(() => {
  //   toggleUnderConstructionModal(true);
  // }, [toggleUnderConstructionModal]);

  const handleOpenEditModal = useCallback((data) => {
    setRequestEditData(data);
    setEditModalOpen(true);
  }, []);

  const handleEditRequest = useCallback(
    (confirm) => {
      if (confirm) {
        dispatch(resetDataRTL());
        const {
          inspirationImageId,
          title,
          requestType,
          items,
        } = requestEditData;
        const requestData = { inspirationImageId, title, requestType };
        if (items.length > 0) {
          requestData.items = items;
        }
        dispatch(updateDataRTL(requestData));
        history.push(routesByName.requestTheLook.submit);
      }

      setEditModalOpen(false);
    },
    [history, requestEditData, dispatch]
  );

  useEffect(() => {
    return () => {
      if (axiosCancelToken.current) {
        axiosCancelToken.current.cancel();
      }
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className={accountClasses.title}>My Requests</p>
        <Link to={routesByName.requestTheLook.index}>
          <Button size="sm" inline>
            Create New Request
          </Button>
        </Link>
      </div>
      <div className="d-flex">
        {myRequestsTabsConfig.map(({ name, title }) => (
          <Tab
            key={name}
            title={title}
            isActive={name === searchParams.source}
            name={name}
            onToggle={handleTabChange}
          />
        ))}
        {isAmbassador &&
          myRequestsAmbassadorTabsConfig.map(({ name, title }) => (
            <Tab
              key={name}
              title={title}
              isActive={name === searchParams.source}
              name={name}
              onToggle={handleTabChange}
            />
          ))}
      </div>
      <MyRequestsComponent
        loading={loading}
        hasMore={hasMore}
        loadMore={loadMore}
        requestList={requestList}
        currentSource={searchParams.source}
        currentRequestType={searchParams.type}
        currentSortType={searchParams.sort}
        onChangeRequestType={handleChangeRequestType}
        onChangeSortType={handleChangeSortType}
        onDelete={handleOpenDeleteModal}
        onEdit={handleOpenEditModal}
        // onMessage={handleUnderConstructionModalOpen}
        onOpenDetail={handleOpenDetail}
      />
      <ConfirmModal
        open={deleteModalOpen}
        onClose={handleDeleteRequest}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">to remove</span> request?
          </>
        }
      />
      <ConfirmModal
        open={editModalOpen}
        onClose={handleEditRequest}
        title={
          <>
            To Edit Request You Will <br />
            Go to the{' '}
            <span className="primary-color">Request The Look Section</span>
          </>
        }
      />
      {requestDetailsData && (
        <RequestDetailsModal
          open={detailModalOpen}
          onClose={handleDetailModalClose}
          data={requestDetailsData}
          currentSource={searchParams.source}
          onCurate={handleOnCurate}
          onEdit={handleOpenEditModal}
          onDelete={handleOpenDeleteModal}
          // onMessage={handleUnderConstructionModalOpen}
        />
      )}
    </>
  );
};

MyRequestsContainer.propTypes = {
  isAmbassador: PropTypes.bool.isRequired,
  // toggleUnderConstructionModal: PropTypes.func.isRequired,
  hasVerifiedEmail: PropTypes.bool.isRequired,
};

export default MyRequestsContainer;
