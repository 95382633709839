import React from 'react';
import PropTypes from 'prop-types';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from 'react-share';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './SocialShare.module.scss';

const sizeToClass = {
  xs: classes.xs,
  sm: classes.sm,
};

const packageIconSizes = {
  xs: 24,
  sm: 32,
};

const SocialShare = ({
  iconSize,
  pageUrl,
  directImgUrl,
  directImgUrlFacebook,
  onShareClick,
}) => (
  <div className="d-flex">
    <div className={classes.shareBtnContainer}>
      <FacebookShareButton url={pageUrl} onClick={onShareClick}>
        <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
          <SpriteIcon name="facebook" size={iconSize} />
        </div>
      </FacebookShareButton>
    </div>
    <div className={classes.shareBtnContainer}>
      <TwitterShareButton url={pageUrl} onClick={onShareClick}>
        <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
          <SpriteIcon name="twitter" size={iconSize} />
        </div>
      </TwitterShareButton>
    </div>
    <div className={classes.shareBtnContainer}>
      <LinkedinShareButton
        url={directImgUrlFacebook || pageUrl}
        onClick={onShareClick}
      >
        <LinkedinIcon
          size={packageIconSizes[iconSize]}
          round
          bgStyle={{ fill: '#C1C2C6' }}
        />
      </LinkedinShareButton>
    </div>
    {directImgUrl && (
      <div className={classes.shareBtnContainer}>
        <PinterestShareButton
          url={pageUrl}
          media={directImgUrl}
          onClick={onShareClick}
        >
          <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
            <SpriteIcon name="pinterest" size={iconSize} />
          </div>
        </PinterestShareButton>
      </div>
    )}
    <div className={classes.shareBtnContainer}>
      <EmailShareButton
        url={pageUrl}
        subject="Test subject"
        onClick={onShareClick}
      >
        <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
          <SpriteIcon name="envelope" size={iconSize} />
        </div>
      </EmailShareButton>
    </div>
  </div>
);

SocialShare.propTypes = {
  pageUrl: PropTypes.string.isRequired,
  directImgUrl: PropTypes.string,
  onShareClick: PropTypes.func,
  directImgUrlFacebook: PropTypes.string,
  iconSize: PropTypes.oneOf(Object.keys(sizeToClass)),
};

SocialShare.defaultProps = {
  directImgUrl: undefined,
  directImgUrlFacebook: undefined,
  iconSize: 'xs',
  onShareClick: () => null,
};

export default SocialShare;
