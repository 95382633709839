import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button/Button';
import SubStylesFilter from 'components/modals/FiltersModal/components/SubStylesFilter';

const SubStylesStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSaveSubStyles,
}) => {
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(currentValue)) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleLikeSubStyle = useCallback(
    (subStyle, checked) => {
      let valueCopy = [...inputValue];

      if (!checked) {
        valueCopy = valueCopy.filter((item) => item !== subStyle);
      } else {
        valueCopy.push(subStyle);
      }
      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveSubStyles = useCallback(() => {
    onSaveSubStyles(inputValue);
  }, [onSaveSubStyles, inputValue]);

  return (
    <>
      <SubStylesFilter
        options={options}
        currentValue={inputValue}
        onChange={handleLikeSubStyle}
      />
      <div className="d-flex justify-content-center pt-3">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveSubStyles}
          disabled={isLoading}
        >
          Next: Room Types
        </Button>
        {onCancelEdit && (
          <Button
            inline
            variant="outlined"
            onClick={onCancelEdit}
            disabled={isLoading}
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};

SubStylesStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onCancelEdit: PropTypes.func,
  onSaveSubStyles: PropTypes.func.isRequired,
};

SubStylesStep.defaultProps = {
  currentValue: undefined,
  onCancelEdit: undefined,
};

export default SubStylesStep;
