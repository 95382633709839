import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const requestService = {
  submitRequest(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/request`, data);
  },

  getRequestById(id) {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/request/${id}`).then(
      ({ result }) => result
    );
  },

  getRequests(source, params, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/request/${source}`,
      params,
      config
    ).then(({ result }) => result);
  },

  deleteRequest(id) {
    return AuthorizedAxiosInstance.delete(`${urlPrefix}/request/${id}`);
  },

  deletePendingRequest(id) {
    return AuthorizedAxiosInstance.delete(`${urlPrefix}/request/${id}/pending`);
  },

  getRecommendations(id) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/request/${id}/recommendations`
    ).then(({ result }) => result);
  },
};

export default requestService;
