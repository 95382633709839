import React from 'react';
import youMatch from 'assets/img/ambassador-page/ambassador-steps/you-match.png';
import promoteSocials from 'assets/img/ambassador-page/ambassador-steps/promoteSocials.png';
import earnPoints from 'assets/img/ambassador-page/ambassador-steps/earn-points.png';
import affiliateProgram from 'assets/img/ambassador-page/ambassador-steps/affiliate-program.png';
import facebookGroup from 'assets/img/ambassador-page/ambassador-steps/facebook-group.png';
import { routesByName } from '../../../../constants/routes';
import { extensionAddress } from '../../../pinMedia/constants';

export const stepsConfig = [
  {
    id: 1,
    title: 'Setup Your Showcase',
    description: (
      <>
        Get Started by Watching our Video Series on Setting Up Your Showcase
        Page
      </>
    ),
    items: [
      {
        title: 'Picking a Theme',
        icon: 'tips-updates',
        buttonText: 'Browse Showcases',
        buttonUrl: routesByName.getTheLook.ambassadors.index,
        videoUrl: '3',
      },
      {
        title: 'Pinning Inspo Images',
        icon: 'image',
        buttonText: 'Get the Chrome Pin',
        buttonUrl: extensionAddress,
        isExternal: true,
        videoUrl: '0',
      },
      {
        title: 'Curating Look Boards',
        icon: 'scene',
        buttonText: 'Go to Curate the Look',
        buttonUrl: routesByName.curateTheLook.canvas,
        videoUrl: '4',
      },
      {
        title: 'Setting Up showcase',
        icon: 'web',
        buttonText: 'Go to Showcase Set Up',
        buttonUrl: routesByName.dashboard.ambassadorShowcase,
        videoUrl: '5',
      },
    ],
    buttonLabel: 'Watch the Videos',
    buttonUrl: '5',
    buttonIcon: 'play-arrow',
    isVideo: true,
  },
  {
    id: 2,
    title: 'Join our community',
    description: <>Join a community of decor lovers!</>,
    image: facebookGroup,
    buttonLabel: 'Join',
    buttonUrl: 'https://www.facebook.com/groups/pinthelookinnercircle',
    buttonIcon: 'arrow-forward',
    isExternal: true,
  },
  {
    id: 3,
    title: 'Curate Matched Requests',
    description: (
      <>Get Tips on Curating Amazing Look Boards for your Matched Requests</>
    ),
    image: youMatch,
    buttonLabel: 'Watch the Video',
    buttonUrl: '1',
    buttonIcon: 'play-arrow',
    isVideo: true,
  },
  {
    id: 4,
    title: 'Promote On Social Media',
    description: (
      <>
        Learn how to Promote your Showcase & Look Boards on Social Media to
        Build a Following
      </>
    ),
    image: promoteSocials,
    buttonLabel: 'Watch the Video',
    buttonUrl: '2',
    buttonIcon: 'play-arrow',
    isVideo: true,
  },
  {
    id: 5,
    title: 'Earn Points & Badges',
    description: (
      <>
        Learn How to Earn Points & badges through Participation on Pin the Look
      </>
    ),
    image: earnPoints,
    buttonLabel: 'View More',
    buttonUrl: routesByName.dashboard.ambassadorPointsBadges,
    buttonIcon: 'arrow-forward',
  },
  {
    id: 6,
    title: 'Join our affiliate programs',
    description: (
      <>
        Join our Affiliate Programs to Earn Commissions on Your Look Board Sales
      </>
    ),
    image: affiliateProgram,
    buttonLabel: 'View More',
    buttonUrl: routesByName.dashboard.ambassadorPrograms,
    buttonIcon: 'arrow-forward',
  },
];
