import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import FeaturedImage from 'modules/dashboard/showcase/components/FeaturedImage';
import { dropItemType } from 'modules/dashboard/showcase/constants';
import {
  addEmptyImageAction,
  buildFeaturedItemsDndState,
  deleteEmptyImageAction,
  deleteImageAction,
} from 'modules/showcase/store/actions';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'modules/dashboard/showcase/Showcase.module.scss';
import SetupShowcaseHint from './components/SetupShowcaseHint';

const initialItemsToShow = 4;
const maxItemsToShow = 16;

const FeaturedImages = ({ handleOpenPreviewModal, imageLibrary, dndState }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(buildFeaturedItemsDndState(initialItemsToShow));
    // eslint-disable-next-line
  }, []);

  const handleAddImageToShow = useCallback(() => {
    dispatch(addEmptyImageAction());
  }, [dispatch]);

  const handleDeleteEmptyImage = useCallback(
    ({ currentTarget }) => {
      const { column } = currentTarget.dataset;
      dispatch(deleteEmptyImageAction(column));
    },
    [dispatch]
  );

  const handleItemDelete = useCallback(
    (columnId) => {
      dispatch(deleteImageAction(columnId));
    },
    [dispatch]
  );

  const getStyle = (styles) => {
    return {
      ...styles,
      height: 0,
      transitionDuration: '0.001s',
    };
  };

  return (
    <div className="background-white p-5 mb-5 overflow-hidden">
      <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
        <div>
          <p className={classes.sectionTitle}>Featured inspiration images</p>
          <p className={classes.sectionSubtitle}>
            Drag & Drop up to 16 Images in the order you would like them to be
            displayed.
          </p>
        </div>
        <SetupShowcaseHint text="STEP 5: Drag & Drop up to 16 Images for your Theme." />
      </div>
      <div className={classes.imagesRoot}>
        {dndState.map(({ id: columnId, item: imageId }, idx, array) => (
          <Droppable
            key={columnId}
            type={dropItemType.inspirationImages}
            droppableId={`${dropItemType.inspirationImages}_${columnId}`}
          >
            {(
              { innerRef, droppableProps, placeholder },
              { draggingFromThisWith, isDraggingOver }
            ) => {
              return (
                <div className="position-relative">
                  <div
                    ref={innerRef}
                    {...droppableProps}
                    style={{
                      border:
                        isDraggingOver || draggingFromThisWith
                          ? 'rgb(135, 189, 195) 1px solid'
                          : 'none',
                      height: 369,
                      overflow: 'hidden',
                    }}
                    className={classes.nonTransform}
                  >
                    {imageLibrary[imageId] ? (
                      <Draggable
                        draggableId={`${dropItemType.inspirationImages}_${imageId}`}
                        index={0}
                        key={imageId}
                      >
                        {({
                          draggableProps,
                          dragHandleProps,
                          innerRef: draggableRef,
                        }) => (
                          <div
                            {...draggableProps}
                            {...dragHandleProps}
                            style={getStyle(draggableProps.style)}
                            ref={draggableRef}
                          >
                            <div className={clsx(classes.dragEmptyBlock)}>
                              <FeaturedImage
                                sourceName={
                                  // eslint-disable-next-line camelcase
                                  imageLibrary[imageId]?.image_source_name
                                }
                                media={imageLibrary[imageId]?.media}
                                onOpenPreview={handleOpenPreviewModal}
                                column={columnId}
                                onDelete={handleItemDelete}
                                title={imageLibrary[imageId]?.title}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      <div
                        className={clsx(
                          'h-100 d-flex justify-content-between align-items-center',
                          classes.emptyImageCard
                        )}
                      >
                        <p className={classes.dragText}>
                          Drag & Drop
                          <br /> Inspiration Image <br /> Here
                        </p>
                        {array.length > initialItemsToShow && (
                          <IconButton
                            className={classes.deleteBtn}
                            color="secondary"
                            size="sm"
                            data-column={columnId}
                            onClick={handleDeleteEmptyImage}
                          >
                            <SpriteIcon name="trash" size="sm" />
                          </IconButton>
                        )}
                      </div>
                    )}
                    {placeholder}
                  </div>
                  {array.length - 1 === idx && array.length < maxItemsToShow && (
                    <div
                      className={clsx(
                        classes.addEmptyBlockBtn,
                        isDraggingOver && 'd-none'
                      )}
                      onClick={handleAddImageToShow}
                    >
                      <SpriteIcon name="plus" color="#828282" />
                    </div>
                  )}
                </div>
              );
            }}
          </Droppable>
        ))}
      </div>
    </div>
  );
};

FeaturedImages.propTypes = {
  handleOpenPreviewModal: PropTypes.func.isRequired,
  imageLibrary: PropTypes.shape({}).isRequired,
  dndState: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      item: PropTypes.number,
    })
  ).isRequired,
};

export default FeaturedImages;
