import { imageTypeKeys } from 'constants/inspirationImageSearchParams';

export const requestFormFields = {
  title: 'title',
  requestColor: 'requestColor',
  requestStyle: 'requestStyle',
};

export const errorMessages = {
  required: 'This field is required',
};

export const requestFormLabels = {
  [imageTypeKeys.room]: 'Room Images',
  [imageTypeKeys.single]: 'Single Item',
};

export const requestTargetRoomTypes = [
  'Bedroom',
  'Dining Room',
  'Living Room or Entryway',
];

export const defaultPriorityItem = { label: '', value: null };
