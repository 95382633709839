import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import classes from 'modules/dashboard/showcase/Showcase.module.scss';
import { routesByName } from 'constants/routes';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';

const ShowcaseLookBoardItem = ({
  lookBoard: { id, title, color, products, slug },
  column,
  onDelete,
}) => {
  const history = useHistory();

  const goToLookBoardDetails = useCallback(
    ({ currentTarget }) => {
      const { id: lookBoardId, slug: lookBoardSlug } = currentTarget.dataset;
      history.push(routesByName.lookBoard.details(lookBoardId, lookBoardSlug));
    },
    [history]
  );

  const handleDeleteLookBoard = useCallback(() => {
    if (onDelete && column) {
      onDelete(column);
    }
  }, [onDelete, column]);

  return (
    <LookBoardPreview
      title={title}
      shortTitle
      color={color}
      showLike={false}
      products={products}
      hoverOverlay={
        <div className={`${classes.hoverOverlay}`}>
          <div className={classes.lookboardDetailsView}>
            <p
              className="text-center cursor-pointer"
              data-id={id}
              data-slug={slug}
              onClick={goToLookBoardDetails}
            >
              Click to view look board details
            </p>
            {onDelete && (
              <IconButton
                className={classes.deleteBtn}
                color="secondary"
                size="sm"
                onClick={handleDeleteLookBoard}
              >
                <SpriteIcon name="trash" size="sm" />
              </IconButton>
            )}
          </div>
        </div>
      }
    />
  );
};
ShowcaseLookBoardItem.propTypes = {
  lookBoard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  column: PropTypes.string,
  onDelete: PropTypes.func,
};

ShowcaseLookBoardItem.defaultProps = {
  column: null,
  onDelete: null,
};

export default ShowcaseLookBoardItem;
