import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  communityTabKeys,
  communityTabsConfig,
  communityTypeKeys,
  communityTypeOptions,
  communitySortOptions,
} from 'modules/dashboard/constants';
import Tab from 'components/ui/Tab/Tab';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import nomineesService from 'modules/dashboard/nominees/nomineesService';
import errorToastr from 'libs/toastr/errorToastr';
import CommunityCard from 'components/userThumbnails/CommunityCard/CommunityCard';
import accountClasses from 'modules/account/Account.module.scss';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import OptionsPopover from 'modules/dashboard/community/OptionsPopover/OptionsPopover';
import SendMessageModal from 'components/modals/SendMessageModal/SendMessageModal';
import { messageFieldKeys } from 'components/modals/SendMessageModal/constants';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import InfiniteScroll from 'react-infinite-scroller';
import useCancelToken from 'hooks/useCancelToken';
import { communitySortKeys } from 'constants/communitySearchParams';

const loadUsersLimit = 20;

const initialSearchParams = {
  source: communityTabKeys.following,
  type: communityTypeKeys.all,
  sort: communitySortKeys.newest,
  search: '',
  offset: 0,
  limit: loadUsersLimit,
};

const CommunityContainer = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);

  const inputRef = useRef(null);
  const [searchInputOpen, setSearchInputOpen] = useState(false);
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(initialSearchParams.source);
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const initialFormValues = useMemo(
    () => ({
      [messageFieldKeys.name]: selectedUser
        ? `${selectedUser.firstName} ${selectedUser.lastName}`
        : '',
      [messageFieldKeys.userId]: selectedUser?.id ?? null,
    }),
    [selectedUser]
  );

  const [createCancelToken, isCancelled] = useCancelToken();

  const loadFirst = useCallback(() => {
    setLoading(true);

    (async () => {
      try {
        const cancelToken = createCancelToken();
        const users = await nomineesService.getCommunity(searchParams, {
          cancelToken,
        });
        setUserList(users);

        if (users.length) {
          setSearchParams((prevState) => ({
            ...prevState,
            offset: users.length,
          }));
        }
        setHasMore(users.length === loadUsersLimit);
        setLoading(false);
      } catch (e) {
        if (!isCancelled(e)) {
          errorToastr('Error', e.generalError);
        }
      }
    })();
  }, [searchParams, isCancelled, createCancelToken]);

  const loadMore = useCallback(() => {
    setLoading(true);
    (async () => {
      try {
        const cancelToken = createCancelToken();

        const users = await nomineesService.getCommunity(searchParams, {
          cancelToken,
        });
        setUserList((prevState) => [...prevState, ...users]);

        if (users.length) {
          setSearchParams((prevState) => ({
            ...prevState,
            offset: prevState.offset + users.length,
          }));
        }
        setHasMore(users.length === loadUsersLimit);
        setLoading(false);
      } catch (e) {
        if (!isCancelled(e)) {
          errorToastr('Error', e.generalError);
        }
      }
    })();
  }, [searchParams, isCancelled, createCancelToken]);

  useEffect(() => {
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleTabChange = useCallback(
    (tab) => {
      setActiveTab(tab);
      if (tab !== searchParams.source) {
        setSearchParams((prevState) => ({
          ...prevState,
          source: tab,
          offset: 0,
        }));
      }
    },
    [searchParams.source]
  );

  const openSearchInput = useCallback(() => {
    setSearchInputOpen(true);
    inputRef.current.focus();
  }, []);

  const closeSearchInput = useCallback(() => {
    setSearchInputOpen(false);
  }, []);

  const handleChangeCommunityType = useCallback((type) => {
    setSearchParams((prevState) => ({ ...prevState, type, offset: 0 }));
  }, []);

  const handleChangeSortType = useCallback((sort) => {
    setSearchParams((prevState) => ({ ...prevState, sort, offset: 0 }));
  }, []);

  const handleChangeSearchQuery = useCallback((search) => {
    setSearchParams((prevState) => ({ ...prevState, search, offset: 0 }));
  }, []);

  const handleMoreOptionsPopupOpen = useCallback(
    ({ currentTarget }) => {
      const { id: userId } = currentTarget.dataset;
      const user = userList.find(({ id }) => id === +userId);
      setSelectedUser(user);
      setMoreOptionsAnchorEl(currentTarget);
    },
    [userList]
  );

  const handleMoreOptionsPopupClose = useCallback(() => {
    setMoreOptionsAnchorEl(null);
  }, []);

  const handleOpenMessageModal = useCallback(() => {
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    setMessageModalOpen(true);
    setMoreOptionsAnchorEl(null);
  }, [currentUser, dispatch]);

  const handleCloseMessageModal = useCallback(() => {
    setMessageModalOpen(false);
  }, []);

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) {
      return;
    }
    loadMore();
  }, [loading, hasMore, loadMore]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className={accountClasses.title}>Community</p>
      </div>
      <div className="d-flex">
        {communityTabsConfig.map(({ name, title }) => (
          <Tab
            key={name}
            title={title}
            isActive={name === activeTab}
            name={name}
            onToggle={handleTabChange}
          />
        ))}
      </div>
      <div className={classes.section}>
        <div className="d-flex justify-content-between mb-3">
          <FilterInput
            className={classes.imgTypeSelect}
            options={communityTypeOptions}
            currentValue={searchParams.type}
            onChange={handleChangeCommunityType}
          />
          <div className="position-relative d-flex align-items-center">
            <IconButton
              variant="inverted-grey"
              className="mr-1 d-none"
              size="sm"
              onClick={openSearchInput}
            >
              <SpriteIcon name="loupe" size="sm" />
            </IconButton>
            <div
              className={clsx(classes.searchInput, {
                [classes.active]: searchInputOpen,
              })}
            >
              <SearchInput
                inputRef={inputRef}
                value={searchParams.search}
                placeholder="Search by keywords"
                onChange={handleChangeSearchQuery}
                onCrossClick={closeSearchInput}
              />
            </div>
            <FilterInput
              additionalLabel="Sort by:"
              className={classes.sortTypeSelect}
              options={communitySortOptions}
              currentValue={searchParams.sort}
              onChange={handleChangeSortType}
            />
          </div>
        </div>
        {userList.length > 0 ? (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleLoadMore}
            initialLoad={false}
          >
            <div className="row">
              {userList.map((user) => (
                <div className="col-4 mt-2" key={user.id}>
                  <CommunityCard
                    user={user}
                    onClickOptions={handleMoreOptionsPopupOpen}
                    canFollow
                    followed={
                      searchParams.source === communityTabKeys.following
                        ? true
                        : user.followed
                    }
                  />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex-fill flex-center font-title text-gray text-lg">
            List is Empty
          </div>
        )}
      </div>
      <OptionsPopover
        open={Boolean(moreOptionsAnchorEl)}
        onClose={handleMoreOptionsPopupClose}
        anchorEl={moreOptionsAnchorEl}
        onClickInfo={() => {}}
        onSendMessage={handleOpenMessageModal}
      />
      <SendMessageModal
        open={messageModalOpen}
        onClose={handleCloseMessageModal}
        initialValues={initialFormValues}
      />
    </>
  );
};

export default CommunityContainer;
