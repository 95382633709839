import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'styles/index.scss';
import appConfig from 'constants/appConfig';
import { ReactComponent as Sprite } from 'assets/sprite.svg';
import store, { history } from 'store/store';
import App from 'App';
import theme from 'materialUi/theme';
import ErrorBoundary from 'components/ErrorBoundary';
import EchoProvider from 'libs/echo/Provider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <EchoProvider>
    <GoogleReCaptchaProvider reCaptchaKey={appConfig.reCaptchaSiteKeyV3}>
      <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ErrorBoundary>
              <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <Sprite />
                  <App />
                </ThemeProvider>
              </StylesProvider>
            </ErrorBoundary>
          </MuiPickersUtilsProvider>
        </ConnectedRouter>
      </Provider>
    </GoogleReCaptchaProvider>
  </EchoProvider>,
  document.getElementById('root')
);

if (appConfig.isDev && module.hot) {
  module.hot.accept();
}
