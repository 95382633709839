import React from 'react';
import PropTypes from 'prop-types';
import { ambassadorInfoSteps } from 'modules/requestTheLook/ambassadorMatch/components/AmbassadorInfo/config';
import BasicAvatar from 'components/ui/BasicAvatar';
import clsx from 'clsx';
import classes from './AmbassadorInfo.module.scss';

const AmbassadorInfo = ({ firstName, lastName, avatarUrl, displayName }) => (
  <div className={classes.root}>
    <div
      className={clsx(classes.ambassadorInfo, 'd-flex mb-3 align-items-center')}
    >
      <BasicAvatar
        className={classes.avatar}
        firstName={firstName}
        lastName={lastName}
        src={avatarUrl}
      />
      <div className="ml-3">
        <h4 className="text-sm font-semi-bold">
          {displayName ?? `${firstName} ${lastName}`}
        </h4>

        <p className={`${classes.description} mt-1 text-xs`}>
          You are a match with this Request <br /> based on your decor
          preferences
        </p>
      </div>
    </div>
    <div className={classes.acceptInfo}>
      To Accept this Request, <b>Start Curating or Save this Request</b> for
      Later
    </div>
    <div className={classes.steps}>
      <div className="font-title text-lg">Next Steps:</div>
      <ul className={classes.stepsList}>
        {ambassadorInfoSteps.map(({ title, body }) => (
          <li key={title}>
            <div className="text-uppercase">
              <b>{title}</b>
            </div>
            <div>{body}</div>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

AmbassadorInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string,
};

AmbassadorInfo.defaultProps = {
  avatarUrl: null,
  displayName: null,
};

export default AmbassadorInfo;
