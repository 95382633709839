import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import classes from './FilterModal.module.scss';

const FilterTabs = ({ onReset, children }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = useCallback((e) => {
    setSelectedTab(+e.target.dataset.index);
  }, []);

  return (
    <div className={classes.tabs}>
      <div className="d-flex mb-5">
        {children.map(({ props: { title } }, index) => (
          <button
            className={clsx(
              classes.btn,
              'text-sm text-uppercase cursor-pointer',
              {
                [classes.btnActive]: index === selectedTab,
              }
            )}
            key={title}
            onClick={handleChange}
            data-index={index}
            type="button"
          >
            {title}
          </button>
        ))}
        {onReset && (
          <Button
            onClick={onReset}
            className={classes.resetBtn}
            variant="outlined"
            size="sm"
            inline
          >
            <SpriteIcon name="trash" size="sm" className="mr-1" />
            Reset Everything
          </Button>
        )}
      </div>
      {children[selectedTab]}
    </div>
  );
};

FilterTabs.propTypes = {
  onReset: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

FilterTabs.defaultProps = {
  onReset: undefined,
};

export default FilterTabs;
