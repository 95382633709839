import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import BasicAvatar from 'components/ui/BasicAvatar';
import Button from 'components/ui/Button/Button';
import CalendarIcon from 'assets/icons/calendar.svg';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import moment from 'moment';
import useUserStatus from 'hooks/useUserStatus';
import classes from './AmbassadorCard.module.scss';
import SpriteIcon from '../../ui/SpriteIcon';

const AmbassadorCard = ({
  badge,
  firstName,
  lastName,
  lookBoardsCount,
  title,
  styles,
  className,
  avatarUrl,
  banner,
  bannerUrl,
  handleViewShowcase,
  disableHover,
  hoverOverlay,
  curatedLookBoards,
  showMoreInfo,
  onShowcasePage,
  assignAmbassadorAt,
  videoUrl,
  videoUrlIsActive,
  isShare,
  displayName,
  handlePlayVideo,
}) => {
  const interactionDate = useMemo(
    () => moment(assignAmbassadorAt).format('L'),
    [assignAmbassadorAt]
  );
  const status = useUserStatus(badge);

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.disableHover]: disableHover,
        [classes.shareRoot]: isShare,
      })}
    >
      {isShare && <Logo width={150} className={classes.shareLogo} />}
      <div
        className={classes.bannerContainer}
        style={{
          background:
            banner.type === bannerTypeKeys.color ? banner.color : 'none',
        }}
      >
        <div className={classes.mask} />
        {banner.type === bannerTypeKeys.image && <img src={bannerUrl} alt="" />}
      </div>

      <BasicAvatar
        firstName={firstName}
        lastName={lastName}
        src={avatarUrl}
        className={clsx(classes.avatar, className, {
          [classes.shareAvatar]: isShare,
        })}
      />

      <div className={classes.ambassadorInfo}>
        {status && (
          <div className={classes.status}>
            <img src={status.icon} alt="status" />
          </div>
        )}
        <div className={classes.nameContainer}>
          <b>{displayName ?? `${firstName} ${lastName}`}</b>
        </div>

        <div className="text-xs font-italic text-center text-gray">
          {lookBoardsCount === 1
            ? `${lookBoardsCount} Look Board`
            : `${lookBoardsCount} Look Boards`}
        </div>
        {!isShare && videoUrl && !!videoUrlIsActive ? (
          <div className={classes.videoIcon}>
            <SpriteIcon
              onClick={handlePlayVideo}
              color="#87bdc3"
              className="h-auto w-100 cursor-pointer"
              name="play-btn-filled"
            />
          </div>
        ) : null}
        <ul className={classes.list}>
          <li className={classes.listItem}>
            <span className={classes.listItemTitle}>Title: </span> {title}
          </li>
          <li className={classes.listItem}>
            <span className={classes.listItemTitle}>Styles:</span>
            <span className={classes.stylesList}>{styles.join(', ')}</span>
            {styles.length > 2 && (
              <div className={classes.stylesMoreIcon}>+{styles.length - 2}</div>
            )}
          </li>
        </ul>
        {showMoreInfo && (
          <div className="d-flex justify-content-around align-items-center mt-4">
            {assignAmbassadorAt && (
              <>
                <div className={classes.additionalInfoBlock}>
                  <div className={classes.iconContainer}>
                    <SpriteIcon name="calendar" size="md" />
                  </div>
                  <strong className="my-1">{interactionDate}</strong>
                  <span className="text-gray">Joined</span>
                </div>
                <div className={classes.verticalDivider} />
              </>
            )}
            <div className={classes.additionalInfoBlock}>
              <div className={classes.iconContainer}>
                <SpriteIcon name="look-board-icon" size="md">
                  {CalendarIcon}
                </SpriteIcon>
              </div>
              <strong className="my-1">{curatedLookBoards} Curated</strong>
              <span className="text-gray">
                {curatedLookBoards === 1 ? `Look Board` : `Look Boards`}
              </span>
            </div>
          </div>
        )}
        <div className={clsx('p-1', classes.overlay, classes.hoverOverlay)}>
          {hoverOverlay}
        </div>
        {!isShare && (
          <div className={classes.showCaseBtn}>
            <Button
              size="md"
              inline
              onClick={handleViewShowcase}
              disabled={onShowcasePage}
            >
              Go to Showcase
              <SpriteIcon name="right-arrow" size="sm" className="ml-2 white" />
            </Button>
          </div>
        )}
        {isShare && (
          <div className={classes.showCaseViewBtn}>View My Showcase</div>
        )}
      </div>
    </div>
  );
};

AmbassadorCard.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  banner: PropTypes.shape({ type: PropTypes.string, color: PropTypes.string })
    .isRequired,
  lookBoardsCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  badge: PropTypes.number,
  className: PropTypes.string,
  avatarUrl: PropTypes.string,
  bannerUrl: PropTypes.string,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleViewShowcase: PropTypes.func,
  hoverOverlay: PropTypes.node,
  curatedLookBoards: PropTypes.number,
  showMoreInfo: PropTypes.bool,
  disableHover: PropTypes.bool,
  onShowcasePage: PropTypes.bool,
  assignAmbassadorAt: PropTypes.string,
  videoUrl: PropTypes.string,
  isShare: PropTypes.bool,
  displayName: PropTypes.string,
  videoUrlIsActive: PropTypes.number,
  handlePlayVideo: PropTypes.func,
};

AmbassadorCard.defaultProps = {
  className: null,
  bannerUrl: null,
  badge: null,
  avatarUrl: null,
  handleViewShowcase: null,
  hoverOverlay: null,
  showMoreInfo: false,
  curatedLookBoards: 0,
  disableHover: true,
  onShowcasePage: false,
  assignAmbassadorAt: null,
  videoUrl: null,
  isShare: false,
  displayName: null,
  videoUrlIsActive: 0,
  handlePlayVideo: null,
};
export default AmbassadorCard;
