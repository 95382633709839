import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { nominateAmbassadorFieldKeys } from 'modules/ambassadors/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import TextAreaWrapper from 'components/finalFormWrappers/TextAreaWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import nominateAmbassadorValidator from 'modules/ambassadors/nominateAmbassador/nominateAmbassadorValidator';
import emailImg from 'assets/img/nomination_email_example.png';
import ambassadorService from 'modules/ambassadors/ambassadorService';
import classes from 'modules/ambassadors/nominateAmbassador/NominateAmbassador.module.scss';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';

const NominateAmbassadorContainer = () => {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [initialFormValues] = useState({
    [nominateAmbassadorFieldKeys.fromFirst]: user.firstName,
    [nominateAmbassadorFieldKeys.fromLast]: user.lastName,
  });

  const nominateAmbassador = useCallback(
    async (formValues) => {
      try {
        await ambassadorService.nominateAmbassador(formValues);
        successToastr('Success', 'Message successfully sent');
        history.push(routesByName.dashboard.ambassadorTeam);
      } catch (e) {
        errorToastr('Error', e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [history]
  );

  return (
    <div className={classes.contentWrapper}>
      <div className="d-flex mb-3">
        <div className={classes.formSection}>
          <h1 className={classes.title}>My Nominated Ambassador Team</h1>
        </div>
        <div className="flex-fill">
          <p className="font-semi-bold">
            Your Nominee will receive an email that looks like this:
          </p>
        </div>
      </div>
      <div className="d-flex pb-5">
        <div className={classes.formSection}>
          <div className="background-white p-5">
            <Form
              initialValues={initialFormValues}
              validate={nominateAmbassadorValidator}
              onSubmit={nominateAmbassador}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <div className="row">
                    <div className="col-5">
                      <Field
                        label="Your First Name"
                        name={nominateAmbassadorFieldKeys.fromFirst}
                        className="mb-5"
                        component={TextInputWrapper}
                      />
                    </div>
                    <div className="col-2" />
                    <div className="col-5">
                      <Field
                        label="Your Last Name"
                        name={nominateAmbassadorFieldKeys.fromLast}
                        className="mb-5"
                        component={TextInputWrapper}
                      />
                    </div>
                  </div>
                  <Field
                    label="To First Name"
                    name={nominateAmbassadorFieldKeys.toFirst}
                    className="mb-5"
                    component={TextInputWrapper}
                  />
                  <Field
                    label="To Last Name"
                    name={nominateAmbassadorFieldKeys.toLast}
                    className="mb-5"
                    component={TextInputWrapper}
                  />
                  <Field
                    label="To Email Address"
                    type="email"
                    name={nominateAmbassadorFieldKeys.email}
                    className="mb-5"
                    component={TextInputWrapper}
                  />
                  <Field
                    label="Confirm Email Address (Nominee's email)"
                    type="email"
                    name={nominateAmbassadorFieldKeys.confirmEmail}
                    className="mb-5"
                    component={TextInputWrapper}
                  />
                  <Field
                    label="Subject"
                    initialValue="Join Our Team"
                    name={nominateAmbassadorFieldKeys.subject}
                    className="mb-5"
                    component={TextInputWrapper}
                  />
                  <Field
                    name={nominateAmbassadorFieldKeys.message}
                    initialValue="Become a Pin the Look Ambassador!"
                    placeholder="Write a message"
                    component={TextAreaWrapper}
                  />
                  <div className="text-center">
                    <FinalFormSubmitButton inline>Submit</FinalFormSubmitButton>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
        <div className="flex-fill">
          <img style={{ maxWidth: '100%' }} src={emailImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default NominateAmbassadorContainer;
