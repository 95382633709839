import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import classes from './RequestTypeInfoModal.module.scss';

const FreeLeftSide = ({ roomRequest }) => {
  return (
    <>
      <div className={clsx(classes.stepsInfo, 'pb-5')}>
        <h4 className={classes.stepsTitle}>Get the Look Request</h4>
        <p className={classes.stepsSubTitle}>
          Quickly find the look of room and product images you love!
        </p>
        <p className={classes.phrase}>It’s free & easy!</p>
        <ul className={classes.stepsList}>
          <li>
            {roomRequest
              ? 'Select or upload an image and request the look of your priority items'
              : 'Select or upload a "single item image" and request the look.'}
          </li>
          <li>
            Receive recommendations from our Ambassador community of decor
            lovers
          </li>
        </ul>
      </div>
    </>
  );
};

FreeLeftSide.propTypes = {
  roomRequest: PropTypes.bool.isRequired,
};

export default FreeLeftSide;
