import { useState, useCallback, useEffect } from 'react';

const useLoadMoreCallback = (limit = 6, totalLength) => {
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(limit);

  const loadMore = useCallback(() => {
    setLoading(true);
    setVisible((prev) => prev + limit);
    setLoading(false);
  }, [limit]);

  useEffect(() => {
    setHasMore(visible < totalLength);
  }, [visible, totalLength]);

  return [hasMore, loading, visible, loadMore];
};

export default useLoadMoreCallback;
