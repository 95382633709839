import lookBoardImg from 'assets/img/look-board-view.png';
import mixAndMatchImg from 'assets/img/mix-match-view.png';
import showcaseView from 'assets/img/showcase-look-view.png';

export const typesInfoModalConfig = [
  {
    id: 1,
    title: 'Curated Looks',
    description:
      'Browse Inspiration Images and Look Boards in your favorite styles. Use the Filters to view images by style, room type, and color.',
    imgUrl: lookBoardImg,
  },
  {
    id: 2,
    title: 'Mix & Match Looks',
    description:
      'Scroll through all items recommended to Get the Look of an Inspiration Image and sort by Best Match and Price.',
    imgUrl: mixAndMatchImg,
  },
  {
    id: 3,
    title: 'Showcase Looks',
    description:
      'View Ambassador Showcase pages to find truly unique inspiration images and Look Boards.',
    imgUrl: showcaseView,
  },
];
