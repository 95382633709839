import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import SpriteIcon from 'components/ui/SpriteIcon';
import { Field, Form } from 'react-final-form';
import classes from 'modules/pinMedia/UploadImageModal/UploadImageModal.module.scss';
import pinMediaValidator from 'modules/pinMedia/pinMediaValidator';
import CustomTextInputWrapper from 'components/finalFormWrappers/CustomTextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import IconButton from 'components/ui/IconButton/IconButton';
import UploadImagePopover from './UploadImagePopover';

const UploadImageModal = ({
  open,
  onClose,
  onAcceptImg,
  onCreateMedia,
  setWithDrop,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        localStorage.removeItem('parsedData');
        const img = acceptedFiles[0];
        onAcceptImg({
          file: img,
          url: URL.createObjectURL(img),
        });
        setWithDrop(true);
      }
    },
    [onAcceptImg, setWithDrop]
  );

  const onUrlEnter = useCallback(
    (imageUrl) => {
      onCreateMedia(imageUrl);
    },
    [onCreateMedia]
  );

  const handleOpenInfoPopover = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleCloseInfoPopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/webp',
    multiple: false,
    maxSize: 10485760, // 10mb
    noClick: false,
    maxFiles: 1,
  });

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      classes={{ root: classes.root }}
      {...rest}
    >
      <>
        <h2>MANUAL-ENTRY</h2>
        <p className="font-italic font-light mb-4">
          Upload Image or Add Image URL, then Enter Data Manually.
        </p>

        <span className="primary-color font-semi-bold font-main mb-2">
          Option 1: Upload Image
        </span>
        <div className={classes.dropZone} {...getRootProps()}>
          <input {...getInputProps()} />
          <SpriteIcon
            name="image-upload"
            className={`${classes.uploadIcon} mb-3`}
          />
          <p className={classes.uploadText}>Drag and drop the image</p>
          <p className="text-sm text-gray mt-1">
            Only PNG and JPEG file formats are supported
          </p>
        </div>
        <div className="d-flex align-items-center mb-2">
          <span className="primary-color font-semi-bold font-main">
            Option 2: Copy & Paste the Page and Image URL below
          </span>
          <IconButton
            variant="inverted-white"
            color="secondary"
            size="xs"
            onClick={handleOpenInfoPopover}
            className={classes.infoBtn}
          >
            <SpriteIcon name="info" size="sm" />
          </IconButton>
        </div>
        <Form
          onSubmit={onUrlEnter}
          validate={pinMediaValidator}
          render={({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit} style={{ width: '80%' }}>
              <div className="d-flex w-100 justify-content-between align-items-center mt-1">
                <div className="w-25 mb-3">
                  <p className="primary-color font-semi-bold font-main">
                    Page URL:
                  </p>
                  <p className={classes.smallText}>
                    URL of page where <br /> the image was found
                  </p>
                </div>
                <Field
                  name="siteSourceUrl"
                  type="url"
                  pattern="(http:|https:)+[^\s]+[\w]"
                  component={CustomTextInputWrapper}
                  className="w-100"
                />
              </div>
              <div className="d-flex w-100 justify-content-between align-items-center mb-3">
                <div className="w-25 mb-3">
                  <p className="primary-color font-semi-bold font-main">
                    Image URL:
                  </p>
                  <p className={classes.smallText}>
                    Right-Click on Image <br /> to get the Image URL
                  </p>
                </div>
                <Field
                  name="url"
                  type="url"
                  pattern="(http:|https:)+[^\s]+[\w]"
                  component={CustomTextInputWrapper}
                  className="w-100"
                />
              </div>
              <FinalFormSubmitButton
                variant="outlined"
                className={classes.uploadBtn}
              >
                Next
              </FinalFormSubmitButton>
            </form>
          )}
        />
        <UploadImagePopover
          classes={{ root: classes.infoPopup }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseInfoPopover}
        />
      </>
    </BasicModal>
  );
};

UploadImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAcceptImg: PropTypes.func.isRequired,
  onCreateMedia: PropTypes.func.isRequired,
  setWithDrop: PropTypes.func.isRequired,
};

export default UploadImageModal;
