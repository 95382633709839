import ConfirmModal from 'components/modals/ConfirmModal';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import nomineesService from 'modules/dashboard/nominees/nomineesService';
import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import BasicAvatar from 'components/ui/BasicAvatar';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from './CommunityCard.module.scss';

const CommunityCard = ({
  user: { id, firstName, lastName, avatarUrl, followersCount },
  onClickOptions,
  canFollow,
  followed: followedFirstly,
}) => {
  const [followed, setFollowed] = useState(followedFirstly);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleFollowChange = useCallback(
    async (confirm) => {
      if (followed) {
        if (confirm === true) {
          try {
            await nomineesService.unFollowUser(id);
            setFollowed(false);
            setConfirmModalOpen(false);
          } catch (e) {
            errorToastr('Error', e.generalError);
          }
        } else if (confirmModalOpen) {
          setConfirmModalOpen(false);
        } else {
          setConfirmModalOpen(true);
        }
      } else {
        try {
          await nomineesService.followUser(id);
          setFollowed(true);
          successToastr(`You are now following ${firstName} ${lastName}`);
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      }
    },
    [firstName, lastName, followed, id, confirmModalOpen]
  );

  return (
    <div className={classes.root}>
      <div className="flex-vertical-center">
        <BasicAvatar
          className={classes.avatar}
          lastName={firstName}
          firstName={lastName}
          src={avatarUrl}
        />
      </div>
      <div className={classes.userInfo}>
        <h3
          className={`${classes.title} text-ellipsis`}
        >{`${firstName} ${lastName}`}</h3>
        <p className={`${classes.description} text-ellipsis`}>
          <span className="primary-color">{followersCount} users</span> follow{' '}
          {firstName}
        </p>
      </div>
      <div
        className={clsx({
          'd-flex flex-column justify-content-between align-items-end': canFollow,
        })}
      >
        <IconButton
          size="sm"
          variant="inverted-grey"
          className="mb-2"
          data-id={id}
          onClick={onClickOptions}
        >
          <SpriteIcon name="more-vertical" size="sm" />
        </IconButton>
        {canFollow && (
          <Button
            variant={followed ? 'contained' : 'outlined'}
            className={clsx(classes.followBtn, {
              [classes.followed]: followed,
            })}
            size="xs"
            onClick={handleFollowChange}
          >
            {followed && (
              <SpriteIcon name="rounded-check" size="sm" className="mr-1" />
            )}
            {followed ? 'Following' : 'Follow'}
          </Button>
        )}
      </div>
      <ConfirmModal
        onClose={handleFollowChange}
        open={confirmModalOpen}
        title="Are you sure you want to unfollow user?"
      />
    </div>
  );
};

CommunityCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    followersCount: PropTypes.number,
    followed: PropTypes.bool,
  }).isRequired,
  onClickOptions: PropTypes.func.isRequired,
  canFollow: PropTypes.bool,
  followed: PropTypes.bool,
};

CommunityCard.defaultProps = {
  canFollow: false,
  followed: null,
};

export default CommunityCard;
