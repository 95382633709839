import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import { routesByName } from 'constants/routes';
import { Link } from 'react-router-dom';
import classes from './ProfileMenu.module.scss';

const ProfileMenu = ({ open, onClose, anchorEl, onMouseEnter }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const user = useSelector((state) => state.auth.user);

  const theme = createMuiTheme({});

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        onClose(e);
      },
      onMouseEnter,
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Menu
        anchorEl={anchorEl}
        open={open}
        id="profileMenu"
        onClose={onClose}
        getContentAnchorEl={null}
        classes={{ list: classes.menuList }}
        PopoverClasses={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onClose} className={classes.menuItem}>
          <Link
            to={{
              state: { toPreferences: false },
              pathname: matchesMediaQuery
                ? routesByName.unavailableTool
                : routesByName.account.index,
            }}
          >
            Profile
          </Link>
        </MenuItem>
        <MenuItem onClick={onClose} className={classes.menuItem}>
          <Link
            to={{
              state: { toPreferences: true },
              pathname: matchesMediaQuery
                ? routesByName.unavailableTool
                : routesByName.account.index,
            }}
          >
            Preferences
          </Link>
        </MenuItem>
        {user && (
          <div>
            {user.ambassador ? (
              <MenuItem onClick={onClose} className={classes.menuItem}>
                <a
                  href={user?.shareUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  My Showcase
                </a>
              </MenuItem>
            ) : null}
            {/*    <MenuItem onClick={onClose} className={classes.menuItem}>
              <Link
                to={{
                  pathname: matchesMediaQuery
                    ? routesByName.unavailableTool
                    : routesByName.getTheLook.users.details(user?.id),
                }}
              >
                My Look Boards
              </Link>
            </MenuItem> */}
          </div>
        )}
      </Menu>
    </ThemeProvider>
  );
};

ProfileMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

ProfileMenu.defaultProps = {
  anchorEl: null,
};

export default ProfileMenu;
