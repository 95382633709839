import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { reportFormFields } from 'modules/report/constants';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import CheckboxInputWrapper from 'components/finalFormWrappers/CheckboxInputWrapper';
import copyrightInfringementValidator from 'modules/report/components/CopyrightInfringement/copyrightInfringementValidator';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import ReportImageContainer from 'modules/report/components/ReportImageContainer';
import classes from 'modules/report/Report.module.scss';

const CopyrightInfringement = ({ currentImage, onReport }) => (
  <div className={classes.contentWrapper}>
    <div className="d-flex">
      <div className="flex-fill background-white p-5">
        <h1 className={`${classes.title} mb-4`}>IMAGE REMOVAL REQUEST</h1>
        <p className="font-italic mb-5" style={{ lineHeight: 1.6 }}>
          Use this form to identify content on Pin the Look that you want
          removed based on alleged infringement of your copyrights.
        </p>
        <Form
          validate={copyrightInfringementValidator}
          onSubmit={onReport}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <p className={`${classes.subTitle} mb-1`}>Contact Information</p>
              <p className="font-italic mb-3" style={{ lineHeight: 1.6 }}>
                (For person reporting content)
              </p>
              <div className="row mb-3">
                <div className="col-7">
                  <Field
                    required
                    label="Name"
                    name={reportFormFields.name}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-12" />
                <div className="col-3">
                  <Field
                    required
                    label="Street"
                    name={reportFormFields.street}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-3">
                  <Field
                    required
                    label="State"
                    name={reportFormFields.state}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-3">
                  <Field
                    required
                    label="Region"
                    name={reportFormFields.region}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-3">
                  <Field
                    required
                    label="Zip"
                    name={reportFormFields.zip}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Country"
                    name={reportFormFields.country}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Phone"
                    type="tel"
                    name={reportFormFields.phone}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Email"
                    type="email"
                    name={reportFormFields.email}
                    component={TextInputWrapper}
                  />
                </div>
              </div>
              <p className={`${classes.subTitle} mb-3`}>
                Tell us more about this image
              </p>
              <div className="row mb-3">
                <div className="col-7">
                  <Field
                    required
                    label="Identify the allegedly infringing material on Pin the Look"
                    name={reportFormFields.materialUrl}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Please describe your work"
                    name={reportFormFields.workDescription}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Please tell us where we can see your work on your own website"
                    name={reportFormFields.workUrl}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="What is the title of your site?"
                    name={reportFormFields.siteTitle}
                    component={TextInputWrapper}
                  />
                </div>
              </div>
              <p className={`${classes.subTitle} mb-3`}>
                Tell Us What Action to Take
              </p>
              <div className="row mb-3">
                <div className="col-7 mb-3">
                  <FormControlLabel
                    label="Remove"
                    classes={{ label: 'font-semi-bold text-uppercase' }}
                    control={
                      <Field
                        type="checkbox"
                        color="primary"
                        name={reportFormFields.removeAll}
                        component={CheckboxInputWrapper}
                      />
                    }
                  />
                  <p className={classes.helperText}>
                    Remove this image from Pin the Look. Note that we will
                    remove the image associated with this link. If the image is
                    on our site from other links, please report each one. Any
                    cropped or altered copies to not apply.
                  </p>
                </div>
                <div className="col-7">
                  <FormControlLabel
                    label="Get credit"
                    classes={{ label: 'font-semi-bold text-uppercase' }}
                    control={
                      <Field
                        type="checkbox"
                        color="primary"
                        name={reportFormFields.credit}
                        component={CheckboxInputWrapper}
                      />
                    }
                  />
                  <p className={classes.helperText}>
                    Assign credit for this image to the website indicated above
                    and keep on Belle Escape to increase visibility and
                    backlinks to my site.
                  </p>
                </div>
              </div>
              <p className={`${classes.subTitle} mb-3`}>
                By checking the following boxes, I confirm:
              </p>
              <div className="row mb-3">
                <div className="col-7 mb-2">
                  <FormControlLabel
                    label="The information in this notice is accurate."
                    classes={{ label: classes.termsLabel }}
                    required
                    control={
                      <Field
                        type="checkbox"
                        color="primary"
                        name={reportFormFields.accurate}
                        component={CheckboxInputWrapper}
                      />
                    }
                  />
                </div>
                <div className="col-7 mb-2">
                  <FormControlLabel
                    label="I have a good faith belief that the disputed use of the copyrighted material is not authorized by the copyright owner, its agent, or the law (e.g., as a fair use)."
                    classes={{ label: classes.termsLabel }}
                    required
                    control={
                      <Field
                        type="checkbox"
                        color="primary"
                        name={reportFormFields.notAuthorized}
                        component={CheckboxInputWrapper}
                      />
                    }
                  />
                </div>
                <div className="col-7 mb-2">
                  <FormControlLabel
                    label="I state under penalty of perjury that I am the owner, or authorized to act on behalf of the owner, of the copyright or of an exclusive right under the copyright that is allegedly infringed."
                    classes={{ label: classes.termsLabel }}
                    required
                    control={
                      <Field
                        type="checkbox"
                        color="primary"
                        name={reportFormFields.owner}
                        component={CheckboxInputWrapper}
                      />
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-9">
                  <p className={`${classes.subTitle} mb-3`}>
                    Typing your name in this box acts as your electronic
                    signature
                  </p>
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Your Name"
                    name={reportFormFields.signature}
                    component={TextInputWrapper}
                  />
                </div>
              </div>
              <FinalFormSubmitButton
                inline
                disabled={
                  !values.accurate || !values.notAuthorized || !values.owner
                }
              >
                Submit
              </FinalFormSubmitButton>
            </form>
          )}
        />
      </div>
      <ReportImageContainer currentImage={currentImage} />
    </div>
  </div>
);

CopyrightInfringement.propTypes = {
  currentImage: inspirationImageShape,
  onReport: PropTypes.func.isRequired,
};

CopyrightInfringement.defaultProps = {
  currentImage: null,
};

export default CopyrightInfringement;
