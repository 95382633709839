import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './InspirationImage.module.scss';
import { publishStatusKeys } from '../../../modules/inspirationImage/constants';

const InspirationImage = ({
  className,
  classes: { hoverOverlay: hoverOverlayClasses },
  id,
  imgUrl,
  blurred,
  disableHover,
  showStaticOverlay,
  staticOverlay,
  hoverOverlay,
  onSelect,
  onLoad,
  title,
  imageSource,
  isProduct,
  isOverlayTransparent,
  isAffiliate,
  publish,
}) => {
  const imgRef = useRef(null);
  const currentUser = useSelector((state) => state.auth.user);

  const handleSelectImage = useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  const handleLoadImage = useCallback(() => {
    const { naturalWidth, naturalHeight } = imgRef.current;
    onLoad(naturalWidth, naturalHeight);
  }, [onLoad, imgRef]);

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.disableHover]: disableHover,
      })}
      onClick={onSelect && handleSelectImage}
    >
      <img
        ref={imgRef}
        alt=""
        draggable="false"
        src={imgUrl}
        className={clsx({ [classes.blurred]: blurred })}
        onLoad={handleLoadImage}
      />
      {isProduct && !!currentUser?.ambassador && isAffiliate && (
        <div className={classes.affiliateStar}>
          <SpriteIcon name="star" size="xs" />
        </div>
      )}
      {!isProduct && publish !== publishStatusKeys.publishedPlus && (
        <div className={classes.selectedImageInfo}>
          <span
            className={clsx(classes.selectedImageInfoTitle, 'text-ellipsis')}
          >
            {title}
          </span>
          <div className={classes.selectedImageInfoSubTitle}>{imageSource}</div>
        </div>
      )}
      {showStaticOverlay && (
        <div
          className={clsx(
            classes.overlay,
            isOverlayTransparent && classes.overlayTransparent
          )}
        >
          {staticOverlay}
        </div>
      )}
      <div
        className={clsx(
          'p-1',
          classes.overlay,
          classes.hoverOverlay,
          hoverOverlayClasses
        )}
      >
        {hoverOverlay}
      </div>
    </div>
  );
};

InspirationImage.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func,
  imgUrl: PropTypes.string.isRequired,
  hoverOverlay: PropTypes.node.isRequired,
  blurred: PropTypes.bool,
  disableHover: PropTypes.bool,
  showStaticOverlay: PropTypes.bool,
  staticOverlay: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.shape({
    hoverOverlay: PropTypes.string,
  }),
  onLoad: PropTypes.func,
  title: PropTypes.string,
  publish: PropTypes.string,
  imageSource: PropTypes.string,
  isProduct: PropTypes.bool,
  isOverlayTransparent: PropTypes.bool,
  isAffiliate: PropTypes.bool,
};

InspirationImage.defaultProps = {
  blurred: false,
  disableHover: false,
  showStaticOverlay: false,
  className: '',
  staticOverlay: null,
  onSelect: undefined,
  classes: {},
  onLoad: () => {},
  title: null,
  imageSource: null,
  isProduct: false,
  isOverlayTransparent: false,
  isAffiliate: false,
  publish: '',
};

export default InspirationImage;
