import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import { maxWidthMd } from 'constants/mediaQueries';
import classes from './ColorSelect.module.scss';
import ColorPoint from './ColorPoint/ColorPoint';
import useMediaQuery from '../../hooks/useMediaQuery';

const ColorSelect = ({ colorList, selectedColors, onChange }) => {
  const colorGroups = useSelector((state) => state.app.enums.colorGroups);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  return (
    <div className={classes.root}>
      {Object.values(colorGroups).map((group, index) => {
        const colors = colorList.filter(
          (color) => color.color_group_id === group.id
        );
        if (!colors.length) return <></>;
        return (
          <div
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            className={clsx('d-flex flex-column', classes.group, {
              'align-items-center': !matchesMediaQuery,
              'align-items-start': matchesMediaQuery,
            })}
          >
            <FormControlLabel
              className={clsx('flex-column mx-0', {
                'align-items-start': matchesMediaQuery,
              })}
              control={
                <h3 className="d-flex align-items-center mb-1">
                  <Checkbox
                    id={String(group.id)}
                    checked={selectedColors.includes(group.id)}
                    onChange={onChange}
                    size="medium"
                    className={classes.checkbox}
                  />
                  <span className={classes.title}>{group.name}</span>
                </h3>
              }
              label={
                <div className="d-flex">
                  {colors.map(({ id, code }, idx) => (
                    <ColorPoint
                      color={code}
                      id={id}
                      size={matchesMediaQuery ? 'md' : 'lg'}
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={idx}
                      className={clsx(idx !== colors.length - 1 && 'mr-1')}
                    />
                  ))}
                </div>
              }
            />
          </div>
        );
      })}
    </div>
  );
};

ColorSelect.propTypes = {
  colorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
  selectedColors: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorSelect;
