import React from 'react';
import PropTypes from 'prop-types';
import classes from './BannerButton.module.scss';

const BannerButton = ({ title, onClick, subTitle }) => (
  <div className={classes.root} onClick={onClick}>
    <div className={classes.textInner}>
      <p className={classes.title}>{title}</p>
      <p className={classes.subTitle}>{subTitle}</p>
    </div>
    <button className={classes.btn} type="button">
      Go
    </button>
  </div>
);

BannerButton.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BannerButton;
