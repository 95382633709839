import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import classes from './TemplateSelect.module.scss';

const TemplateSelect = ({
  className,
  id: templateId,
  title,
  subTitle,
  columnCount,
  imageList,
  isDefault,
  selected,
  onSelect,
}) => {
  const colCount = useMemo(() => 12 / columnCount, [columnCount]);

  const handleSelect = useCallback(() => {
    onSelect(templateId);
  }, [templateId, onSelect]);

  return (
    <div>
      <div
        className={clsx(
          classes.root,
          className,
          'mb-1',
          selected ? classes.selected : null
        )}
        onClick={handleSelect}
      >
        <div className={classes.title}>{title}</div>
        <div className={classes.imgContainer}>
          <div className="row h-100">
            {imageList.map(({ id: imgId, src }) => (
              <div
                className={clsx(
                  `col-${colCount}`,
                  'h-100 d-flex justify-content-center'
                )}
                key={imgId}
              >
                <img src={src} alt="" className={classes.templateImg} />
              </div>
            ))}
          </div>
          <div className={classes.overlay}>
            <span
              className={clsx(classes.defaultChip, {
                [classes.visible]: isDefault,
              })}
            >
              Default
            </span>
            <div
              className={clsx(classes.checkedIcon, {
                [classes.visible]: selected,
              })}
            >
              <CheckRoundedIcon fontSize="small" />
            </div>
          </div>
        </div>
      </div>
      <p className="font-semi-bold font-italic text-sm">{subTitle}</p>
    </div>
  );
};

TemplateSelect.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  columnCount: PropTypes.number.isRequired,
  imageList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  isDefault: PropTypes.bool,
  selected: PropTypes.bool,
  className: PropTypes.string,
};

TemplateSelect.defaultProps = {
  className: '',
  isDefault: false,
  selected: false,
};

export default TemplateSelect;
