import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import ItemThumbnail from 'components/lookBoardThumbnails/ItemThumbnail/ItemThumbnail';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'components/lookBoardThumbnails/LookBoard/LookBoard.module.scss';
import votedIcon from 'assets/icons/voted.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const LookBoardPreview = ({
  className,
  title,
  color,
  isLiked,
  isVoted,
  banner,
  isOnSlider,
  products,
  showStaticOverlay,
  staticOverlay,
  hoverOverlay,
  showLike,
  disableHover,
  children,
  shortTitle,
  onToggleLike,
  moveToDetails,
  onVoteClick,
  isGalleryView,
  isPreviewModal,
}) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const containerRef = useRef(null);
  const [imgHeight, setImgHeight] = useState('50%');
  const [containerHeight, setContainerHeight] = useState(null);
  const [productList, setProductList] = useState([]);
  const [hover, setHover] = useState(false);

  const hiddenItemsCount = useMemo(() => products.length - productList.length, [
    products,
    productList,
  ]);

  const updateHeight = useCallback(() => {
    if (containerRef.current) {
      const refWidth = containerRef.current.clientWidth;
      const itemHeight =
        products.length > 1 && !isPreviewModal
          ? Math.round(refWidth / 2)
          : refWidth;
      setContainerHeight(refWidth);
      setImgHeight(itemHeight);
    }
  }, [isPreviewModal, products.length]);

  useEffect(() => {
    updateHeight();
    // eslint-disable-next-line
  }, [products]);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const list = products.slice(0, 4);
    switch (list.length) {
      case 2: {
        list.splice(1, 0, { id: uuidv4() }, { id: uuidv4() });
        break;
      }
      case 3: {
        list.push({ id: uuidv4() });
        break;
      }
      default: {
        break;
      }
    }
    setProductList(list);
  }, [products]);

  const handleHover = useCallback(() => {
    if (!disableHover) {
      setHover(true);
    }
  }, [disableHover]);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <div
      ref={containerRef}
      className={clsx(classes.root, className)}
      onMouseEnter={!matchesMediaQuery ? handleHover : null}
      onMouseLeave={!matchesMediaQuery ? handleBlur : null}
    >
      <div
        className={clsx(classes.title, { [classes.shortTitle]: shortTitle })}
        style={{ backgroundColor: color }}
      >
        {title}
      </div>
      <div
        style={{ minHeight: containerHeight }}
        className={clsx(classes.imgContainer)}
        onClick={moveToDetails}
      >
        {productList.map(({ id: productId, media, itemClassId }) =>
          media ? (
            <ItemThumbnail
              key={productId}
              id={productId}
              src={media}
              className={products.length > 1 ? classes.double : classes.single}
              itemClassId={itemClassId}
              height={imgHeight}
            />
          ) : (
            <div
              key={productId}
              className={clsx(classes.placeholderWrapper, classes.double)}
              style={{ height: imgHeight }}
            >
              <div className={classes.placeholder}>
                <SpriteIcon name="logo" className={classes.placeholderIcon} />
              </div>
            </div>
          )
        )}
      </div>
      <div
        className={clsx(
          classes.bottomControls,
          !isGalleryView && classes.outCardControls,
          !showLike && 'd-none'
        )}
      >
        {hiddenItemsCount > 0 && (
          <IconButton
            variant="inverted-grey"
            color="primary"
            size="sm"
            className="mr-1"
          >
            +{hiddenItemsCount}
          </IconButton>
        )}
        {banner && (
          <div
            className={clsx(classes.banner, {
              [classes.hidden]: hover && !matchesMediaQuery,
            })}
            style={isOnSlider ? { bottom: '0' } : {}}
          >
            <span>{banner}</span>
          </div>
        )}
        {showLike && (
          <>
            <IconButton
              className="mr-1"
              variant="inverted-grey"
              color="primary"
              size="sm"
              onClick={onToggleLike}
            >
              <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
            </IconButton>
            {isVoted ? (
              <div className="cursor-pointer" style={{ width: '32px' }}>
                <img src={votedIcon} alt="voted" />
              </div>
            ) : (
              <IconButton
                variant="inverted-grey"
                size="sm"
                className={classes.vote}
                onClick={onVoteClick}
              >
                <span>Vote</span>
              </IconButton>
            )}
          </>
        )}
      </div>
      <>
        {showStaticOverlay && (
          <div className={classes.staticOverlay}>{staticOverlay}</div>
        )}
        <div
          className={clsx(classes.overlay, {
            [classes.hidden]: !hover && !matchesMediaQuery,
            [classes.mobileHover]: matchesMediaQuery,
          })}
        >
          {hoverOverlay}
        </div>
      </>
      {children}
    </div>
  );
};

LookBoardPreview.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  isLiked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isVoted: PropTypes.bool,
  banner: PropTypes.string,
  isOnSlider: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  hoverOverlay: PropTypes.node,
  showStaticOverlay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  disableHover: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  staticOverlay: PropTypes.node,
  showLike: PropTypes.bool,
  children: PropTypes.node,
  shortTitle: PropTypes.bool,
  onToggleLike: PropTypes.func,
  moveToDetails: PropTypes.func,
  onVoteClick: PropTypes.func,
  isGalleryView: PropTypes.bool,
  isPreviewModal: PropTypes.bool,
};

LookBoardPreview.defaultProps = {
  isLiked: 0,
  isVoted: false,
  banner: '',
  isOnSlider: false,
  className: '',
  showStaticOverlay: false,
  staticOverlay: null,
  showLike: true,
  children: null,
  disableHover: false,
  shortTitle: false,
  isGalleryView: false,
  onToggleLike: () => {},
  moveToDetails: () => {},
  onVoteClick: () => {},
  isPreviewModal: false,
  hoverOverlay: null,
};
export default LookBoardPreview;
