import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button/Button';
import clsx from 'clsx';
import classes from './NoLookBoardsModal.module.scss';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../../constants/mediaQueries';

const NoLookBoardsModal = ({ onCreateLookBoard, onCreateRequest }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  return (
    <div className={classes.root}>
      <div className={classes.border} />
      <div className="flex-center flex-column mx-3">
        <p className={`${classes.title} mb-5`}>
          There are no Look Boards
          <br />
          created for this image yet
        </p>
        <div
          className={clsx({
            'd-flex flex-column w-100': matchesMediaQuery,
          })}
        >
          <Button
            inline
            variant="outlined"
            className={clsx({
              'mr-3': !matchesMediaQuery,
              'w-100 mb-2': matchesMediaQuery,
            })}
            onClick={onCreateLookBoard}
          >
            Create look board
          </Button>
          <Button
            className={clsx({ 'w-100': matchesMediaQuery })}
            variant="outlined"
            inline
            onClick={onCreateRequest}
          >
            Request the look
          </Button>
        </div>
      </div>
      <div className={classes.border} />
    </div>
  );
};

NoLookBoardsModal.propTypes = {
  onCreateLookBoard: PropTypes.func.isRequired,
  onCreateRequest: PropTypes.func.isRequired,
};

export default NoLookBoardsModal;
