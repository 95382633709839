import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailAction } from 'modules/auth/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import { routesByName } from 'constants/routes';
import successToastr from 'libs/toastr/successToastr';
import customEvent from 'utils/customEvent';
import { OPEN_REGISTERED_MODAL } from 'constants/customEventNames';

const EmailVerificationContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId, token } = useParams();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(verifyEmailAction(userId, token));
        history.push(routesByName.getTheLook.index);
        successToastr('Success', 'Email verified successfully');
        if (user && !user?.preferences) {
          setTimeout(() => {
            customEvent.trigger(OPEN_REGISTERED_MODAL);
          }, 0);
        }
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return null;
};

export default EmailVerificationContainer;
