import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import bannerBg from 'assets/img/customer-support-bg.png';
import Button from 'components/ui/Button/Button';
import CustomerSupportModal from 'modules/account/components/CustomerSupportModal/CustomerSupportModal';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import supportService from 'modules/support/supportService';
import { supportFormFields, supportFormTypes } from 'modules/support/constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import classes from './CustomerSupportBanner.module.scss';

const CustomerSupportBanner = () => {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { firstName, lastName, email } =
    useSelector((state) => state.auth.user) ?? {};

  const initialFormValues = useMemo(
    () => ({ name: `${firstName} ${lastName}`, email }),
    [email, firstName, lastName]
  );

  const handleOpenSupportModal = useCallback(() => {
    setSupportModalOpen(true);
  }, []);

  const handleCloseSupportModal = useCallback(() => {
    setSupportModalOpen(false);
  }, []);

  const handleSendSupportMessage = useCallback(
    async (formValues) => {
      try {
        let reCaptchaToken;
        if (!executeRecaptcha) {
          reCaptchaToken = null;
        }
        reCaptchaToken = await executeRecaptcha(
          reCaptchaActions.customerSupport
        );
        await supportService.sendSupportMessage({
          [supportFormFields.type]: supportFormTypes.support,
          ...formValues,
          reCaptchaToken,
        });
        successToastr('Success', 'Message successfully sent');
        setSupportModalOpen(false);
      } catch (e) {
        errorToastr('Error', e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [executeRecaptcha]
  );

  return (
    <>
      <div className={classes.root}>
        <img src={bannerBg} alt="" />
        <div className={classes.content}>
          <p className={classes.title}>Questions?</p>
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={handleOpenSupportModal}
          >
            Contact Customer Support
          </Button>
        </div>
      </div>
      <CustomerSupportModal
        open={supportModalOpen}
        onClose={handleCloseSupportModal}
        initialValues={initialFormValues}
        onSubmit={handleSendSupportMessage}
      />
    </>
  );
};

export default CustomerSupportBanner;
