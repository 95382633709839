import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { requestSourceKeys } from 'constants/requestSearchParams';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  approvalStatusKeys,
  // publishStatusKeys,
} from 'modules/request/constants';
// import { myRequestsPublishTypes } from 'modules/dashboard/myRequests/constants';
import useItemClassesByIds from 'modules/app/hooks/useItemClassesByIds';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import RejectedOverlay from 'modules/dashboard/myImages/components/AddedImageItem/RejectedOverlay';
import Button from 'components/ui/Button/Button';
import { updateCurateStateAction } from 'modules/curateTheLook/store/actions';
import classes from './RequestItem.module.scss';

const RequestItem = ({
  id,
  currentSource,
  activated,
  approval,
  imageId,
  // publish,
  title,
  message,
  rejectedReason,
  requestImage,
  inspirationImageUrl,
  onOpenPreview,
  onOpenDetail,
  priorityItems,
  userId,
  onEdit,
  onDelete,
  // onMessage,
  curatedCount,
  updateCurateState,
}) => {
  const inProgress = useMemo(
    () =>
      approval === approvalStatusKeys.pending &&
      currentSource === requestSourceKeys.my,
    [currentSource, approval]
  );

  const noRecommendations = useMemo(
    () =>
      approval === approvalStatusKeys.approved &&
      !activated &&
      currentSource === requestSourceKeys.my,
    [approval, currentSource, activated]
  );

  const rejected = useMemo(() => approval === approvalStatusKeys.rejected, [
    approval,
  ]);

  // const isPersonalShopperRequest = useMemo(
  //   () => publish === publishStatusKeys.personal,
  //   [publish]
  // );
  const showActionButtons = useMemo(
    () => currentSource !== requestSourceKeys.completed,
    [currentSource]
  );
  const imgUrl = useMemo(
    () =>
      requestImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        requestImage.media.userId,
        requestImage.media.hash
      ),
    [inspirationImageUrl, requestImage]
  );

  const priorityItemsAsOptions = useItemClassesByIds(priorityItems);
  const handleOpenDetail = useCallback(() => {
    onOpenDetail({
      requestId: id,
      imgUrl,
      requestTitle: title,
      requestImage,
      priorityItems,
      activated,
      message,
      requesterId: userId,
    });
  }, [
    id,
    onOpenDetail,
    imgUrl,
    requestImage,
    priorityItems,
    title,
    activated,
    message,
    userId,
  ]);

  const handleEdit = useCallback(() => {
    onEdit({
      title,
      items: priorityItemsAsOptions,
      requestType: requestImage.type,
      inspirationImageId: imageId,
    });
  }, [onEdit, title, priorityItemsAsOptions, requestImage, imageId]);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [onDelete, id]);

  const handleAddRequestData = useCallback(() => {
    updateCurateState({
      requestInfo: {
        title,
        priorityItems,
      },
    });
  }, [title, priorityItems, updateCurateState]);

  return (
    <div className="position-relative mb-3">
      {
        // <IconButton
        //   className={clsx(classes.requestTypeIcon, {
        //     [classes.requestTypePersonal]: isPersonalShopperRequest,
        //   })}
        //   size="sm"
        //   component="span"
        // >
        //   <SpriteIcon name={myRequestsPublishTypes[publish].icon} size="sm" />
        // </IconButton>
      }
      <InspirationImage
        id={id}
        className="mb-2"
        classes={{
          hoverOverlay: clsx({ [classes.backgroundNone]: inProgress }),
        }}
        imgUrl={imgUrl}
        hoverOverlay={
          <div className="h-100 d-flex flex-column justify-content-between p-1">
            <div className="d-flex justify-content-between">
              {
                // <div
                //   className={clsx(classes.requestTypeLabel, {
                //     [classes.requestTypePersonal]: isPersonalShopperRequest,
                //   })}
                // >
                //   {myRequestsPublishTypes[publish].label}
                // </div>
              }
            </div>
            <div className="d-flex justify-content-center">
              <IconButton
                color="secondary"
                size="sm"
                data-user-id={requestImage?.media.userId}
                data-hash={requestImage?.media.hash}
                onClick={onOpenPreview}
              >
                <SpriteIcon name="loupe" size="sm" />
              </IconButton>
            </div>
            <div className="d-flex justify-content-end">
              {!inProgress && showActionButtons && (
                <IconButton
                  onClick={handleDelete}
                  className="mr-1"
                  variant="inverted-white"
                  size="sm"
                >
                  <SpriteIcon name="trash" size="sm" />
                </IconButton>
              )}
              {!inProgress && currentSource === requestSourceKeys.my && (
                <>
                  {
                    // <IconButton
                    // onClick={onMessage}
                    //  className="mr-1"
                    //   variant="inverted-white"
                    //   size="sm"
                    //  >
                    //    <SpriteIcon name="envelope" size="sm" />
                    //  </IconButton>
                    // <IconButton
                    //   onClick={handleEdit}
                    //   className="mr-1"
                    //   variant="inverted-white"
                    //   size="sm"
                    // >
                    //   <SpriteIcon name="edit" size="sm"/>
                    // </IconButton>
                  }
                </>
              )}
              <IconButton
                onClick={handleOpenDetail}
                variant="inverted-white"
                size="sm"
              >
                <SpriteIcon name="eye" />
              </IconButton>
            </div>
          </div>
        }
        showStaticOverlay={inProgress || rejected}
        disableHover={inProgress || rejected}
        staticOverlay={
          rejected ? (
            <RejectedOverlay
              onDelete={handleDelete}
              onEdit={handleEdit}
              reason={rejectedReason}
            />
          ) : (
            <div className="h-100 flex-center">
              <OverlayLabel text={<span>In Progress</span>} />
            </div>
          )
        }
      />
      <h4
        className="text-capitalize text-ellipsis text-nowrap font-italic mb-1"
        title={title}
      >
        <b>Title:</b> {title}
      </h4>
      {currentSource === requestSourceKeys.pending && (
        <Link
          className="link-underline text-sm primary-color"
          to={routesByName.requestTheLook.ambassadorMatch(id)}
          onClick={handleAddRequestData}
        >
          {!curatedCount ? (
            'Curate Request'
          ) : (
            <Button inline size="sm">
              {curatedCount}/5 Curate Again
            </Button>
          )}
        </Link>
      )}
      {currentSource !== requestSourceKeys.pending && noRecommendations && (
        <span className="text-sm text-gray">Recommendations Pending</span>
      )}
      {currentSource !== requestSourceKeys.pending &&
        !noRecommendations &&
        !inProgress && (
          <Link
            className="link-underline text-sm primary-color"
            to={routesByName.requestTheLook.recommendations(id)}
          >
            View Recommendations
          </Link>
        )}
    </div>
  );
};
RequestItem.propTypes = {
  id: PropTypes.number.isRequired,
  currentSource: PropTypes.string.isRequired,
  activated: PropTypes.number.isRequired,
  approval: PropTypes.string.isRequired,
  imageId: PropTypes.number.isRequired,
  priorityItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  // publish: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rejectedReason: PropTypes.string,
  requestImage: inspirationImageShape,
  curatedCount: PropTypes.number.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onOpenDetail: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  // onMessage: PropTypes.func.isRequired,
  updateCurateState: PropTypes.func.isRequired,
  message: PropTypes.string,
  userId: PropTypes.number.isRequired,
};

RequestItem.defaultProps = {
  requestImage: null,
  rejectedReason: null,
  message: null,
};

const mapStateToProps = (
  { app: { config }, inspirationImage: { library } },
  { imageId }
) => ({
  inspirationImageUrl: getInspirationImgUrl(config),
  requestImage: library[imageId],
});

const mapDispatchToProps = {
  updateCurateState: updateCurateStateAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestItem);
