import { reportFormFields } from 'modules/report/constants';
import { errorMessages } from 'modules/auth/signUp/constants';

const emailPattern = /.+@.+..+/;

const getCreditValidator = ({
  [reportFormFields.name]: name,
  [reportFormFields.street]: street,
  [reportFormFields.state]: state,
  [reportFormFields.region]: region,
  [reportFormFields.zip]: zip,
  [reportFormFields.country]: country,
  [reportFormFields.phone]: phone,
  [reportFormFields.email]: email,
  [reportFormFields.workDescription]: workDescription,
  [reportFormFields.workUrl]: workUrl,
  [reportFormFields.replaceUrl]: replaceUrl,
  [reportFormFields.signature]: signature,
}) => {
  const errors = {};

  if (!name) {
    errors.name = errorMessages.required;
  }

  if (!street) {
    errors.street = errorMessages.required;
  }

  if (!state) {
    errors.state = errorMessages.required;
  }

  if (!region) {
    errors.region = errorMessages.required;
  }

  if (!zip) {
    errors.zip = errorMessages.required;
  }

  if (!country) {
    errors.country = errorMessages.required;
  }

  if (!phone) {
    errors.phone = errorMessages.required;
  }

  if (!email) {
    errors.email = errorMessages.required;
  } else if (!emailPattern.test(email)) {
    errors.email = errorMessages.emailInvalid;
  }

  if (!workDescription) {
    errors.workDescription = errorMessages.required;
  }

  if (!workUrl) {
    errors.workUrl = errorMessages.required;
  }

  if (!replaceUrl) {
    errors.replaceUrl = errorMessages.required;
  }

  if (!signature) {
    errors.signature = errorMessages.required;
  }

  return errors;
};

export default getCreditValidator;
