import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import { extensionAddress } from 'modules/pinMedia/constants';
import classes from './UploadExtensionModal.module.scss';

const UploadExtensionModal = ({ open, onClose, ...rest }) => {
  const [isExtensionInstalled, setExtensionInstalled] = useState(false);

  useEffect(() => {
    if (document.querySelector('.pinButton')) {
      setExtensionInstalled(true);
    }
  }, []);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      classes={{ root: classes.root }}
      {...rest}
    >
      <>
        <h2>AUTO-CAPTURE</h2>
        <p className="font-italic font-light mb-4">
          Add the Chrome Browser Extension
        </p>

        <span className={classes.bordered}>
          THIS IS THE FASTEST METHOD TO ADD IMAGES
        </span>
        <p className="text-sm mt-2 mb-5">
          Click the <span className="font-semi-bold">Add Extension</span> button
          below to add the extension to your Chrome browser.
        </p>
        <div className={classes.paragraphWrapper}>
          <div className={classes.iconContainer}>
            <SpriteIcon name="pin-filled" className={classes.icon} />
          </div>
          <div className="ml-1">
            <p className={classes.paragraph}>
              Once added, go to the product page of any online store and click
              on the PIN icon in your browser to automatically capture the image
              and data.
            </p>
          </div>
        </div>
        {!isExtensionInstalled ? (
          <div className={classes.linkContainer}>
            <a
              href={extensionAddress}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClose}
              className={classes.extensionLink}
            >
              Add Extension
            </a>
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center mb-2">
              <div className={classes.infoIcon}>
                <SpriteIcon name="info" size="sm" />
              </div>
              <span className="font-italic">You have got an extension.</span>
            </div>
            <div className={classes.buttonContainer}>
              <Button size="md" variant="outlined" onClick={onClose}>
                Ok
              </Button>
            </div>
          </>
        )}
      </>
    </BasicModal>
  );
};

UploadExtensionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadExtensionModal;
