import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const nomineesService = {
  getNominees(config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/invitation/my-nominate-ambassadors`,
      undefined,
      config
    );
  },

  getCommunity(params, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/followers/list`,
      params,
      config
    ).then(({ result }) => result);
  },

  followUser(params) {
    return AuthorizedAxiosInstance.put(
      `${urlPrefix}/followers/follow/${params}`
    );
  },
  unFollowUser(params) {
    return AuthorizedAxiosInstance.delete(
      `${urlPrefix}/followers/unfollow/${params}`
    );
  },
};

export default nomineesService;
