import { routesByName } from 'constants/routes';
import {
  imageSortKeys,
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import {
  lookBoardSortKeys,
  lookBoardSourceKeys,
  lookBoardStatusKeys,
  lookBoardTypeKeys,
} from 'constants/lookBoardSearchParams';
import addImg from 'assets/img/dashboard-page/add-images.png';
import curateImg from 'assets/img/dashboard-page/curate-images.png';
import setupImg from 'assets/img/dashboard-page/setup-showcase.png';
import { productTypeKeys } from 'modules/product/constants';
import { productSortKeys } from 'constants/productSearchParams';
import { communitySortKeys } from 'constants/communitySearchParams';

export const navLinksConfig = [
  {
    route: routesByName.dashboard.images,
    title: 'My Images',
    icon: 'pictures',
    width: '38px',
    height: '34px',
  },
  {
    route: routesByName.dashboard.lookBoards,
    title: 'My Look Boards',
    icon: 'sofa',
    width: '38px',
    height: '40px',
  },
  {
    route: routesByName.dashboard.requests,
    title: 'My Requests',
    icon: 'exchange',
  },
  {
    route: routesByName.dashboard.community,
    title: 'Community',
    icon: 'followers',
  },
  {
    route: routesByName.dashboard.messages,
    title: 'Messages',
    icon: 'envelope',
    miniInfo: 'messages',
  },
];

export const myProductsTabKey = 'myProducts';

export const myMixAndMatchTabKey = 'myMixAndMatch';

export const myImagesTabsConfig = [
  {
    name: imageSourceKeys.my,
    title: 'My Added Images',
  },
  {
    name: imageSourceKeys.liked,
    title: 'My Liked Images',
  },
  {
    name: myProductsTabKey,
    title: 'My Products',
  },
];

export const myLookBoardsTabsConfig = [
  {
    name: lookBoardSourceKeys.my,
    title: 'My Curated Look Boards',
  },
  {
    name: lookBoardSourceKeys.liked,
    title: 'My Liked Look Boards',
  },
  {
    name: myMixAndMatchTabKey,
    title: 'Mix & Match',
  },
];

export const myImageTypeOptions = [
  {
    label: 'All Images',
    value: imageTypeKeys.all,
  },
  {
    label: 'Room Images',
    value: imageTypeKeys.room,
  },
  {
    label: 'Single Item Images',
    value: imageTypeKeys.single,
  },
];

export const myProductsTypeOptions = [
  { label: 'My Added Products', value: productTypeKeys.my },
  { label: 'My Liked Products', value: productTypeKeys.liked },
];

export const imageTypes = {
  [imageTypeKeys.room]: 'Room Image',
  [imageTypeKeys.single]: 'Single Item',
};

export const myLookBoardsTypeOptions = [
  {
    label: 'All Look Boards',
    value: lookBoardTypeKeys.all,
  },
  {
    label: 'Room Image Look Boards',
    value: lookBoardTypeKeys.room,
  },
  {
    label: 'Single Item Look Boards',
    value: lookBoardTypeKeys.single,
  },
];

export const myImagesSortOptions = [
  {
    label: 'Newest',
    value: imageSortKeys.newest,
  },
  {
    label: 'Oldest',
    value: imageSortKeys.oldest,
  },
  {
    label: 'Highest rated',
    value: imageSortKeys.highest,
  },
];

export const communitySortOptions = [
  {
    label: 'Newest',
    value: communitySortKeys.newest,
  },
  {
    label: 'Oldest',
    value: communitySortKeys.oldest,
  },
  {
    label: 'Highest rated',
    value: communitySortKeys.rated,
  },
];

export const myProductsSortOptions = [
  { label: 'Newest', value: productSortKeys.newest },
  { label: 'Highest rated', value: productSortKeys.highest },
];

export const myLookBoardsSortOptions = [
  {
    label: 'Newest',
    value: lookBoardSortKeys.newest,
  },
  {
    label: 'Oldest',
    value: lookBoardSortKeys.oldest,
  },
  {
    label: 'Highest rated',
    value: lookBoardSortKeys.highest,
  },
];

export const lookBoardStatusRadioOptions = [
  { value: lookBoardStatusKeys.published, label: 'Published' },
  { value: lookBoardStatusKeys.drafts, label: 'Drafts' },
];

export const ambassadorNavLinksConfig = [
  {
    route: routesByName.dashboard.ambassadorManagement,
    title: 'Ambassador overview',
    miniTitle: 'Ambassadors',
    icon: 'ambassador',
  },
  {
    route: routesByName.dashboard.ambassadorShowcase,
    title: 'Setup or Edit Showcase Page',
    icon: 'browser',
  },
  {
    route: routesByName.dashboard.ambassadorTeam,
    title: 'My Nominated Ambassador Team',
    icon: 'follow',
  },
  {
    route: routesByName.dashboard.points,
    title: 'My Points',
    icon: 'borderedStar',
  },
];

export const ambassadorInfoMenuItemsConfig = [
  {
    id: 1,
    title: 'Ambassador Overview',
    description:
      'This section provides information on getting started as an Ambassador.',
    icon: 'ambassador',
  },
  {
    id: 2,
    title: 'Showcase Setup',
    description:
      "This section provides a simple template to set up your own Showcase page featuring the room images you've added and Look Boards you've created. ",
    icon: 'browser',
  },
  {
    id: 3,
    title: 'Nominations',
    description:
      'This section allows you to nominate friends to become Ambassadors.',
    icon: 'follow',
  },
];

export const infoMenuConfig = {
  title: 'Information',
  items: [
    {
      id: 1,
      title: 'My Images',
      description:
        'This section includes room and product images that you personally added to Pin the Look. In addition, it includes Look Boards from Pin the Look that you “liked”.',
      icon: 'pictures',
    },
    {
      id: 2,
      title: 'My Look Boards',
      description:
        'This section includes Look Boards that you personally curated as well as Mix & Match Look Boards. In addition, it includes Look Boards created by others on Pin the Look that you “liked”.',
      icon: 'sofa',
    },
    {
      id: 3,
      title: 'My Requests',
      description:
        'This section includes your Requests to get the look of room images and home furnishing items.',
      icon: 'exchange',
    },
    {
      id: 4,
      title: 'Community',
      description:
        'This section includes your Pin the Look followers and those following you.',
      icon: 'followers',
    },
    {
      id: 5,
      title: 'Messages',
      description:
        'This section includes messages related to Requests and Look Boards.',
      icon: 'envelope',
    },
  ],
};

export const getStartedSidebarConfig = [
  {
    route: routesByName.dashboard.images,
    title: 'Add images',
    description: 'Add Your Favorite Room Images',
    image: addImg,
  },
  {
    route: routesByName.curateTheLook.index,
    title: 'Curate images',
    description: 'Curate Requests and your Favorite Room Images',
    image: curateImg,
  },
  {
    route: routesByName.dashboard.ambassadorShowcase,
    title: 'Showcase Page',
    description: 'Setup Your Showcase Page',
    image: setupImg,
  },
];

export const communityTabKeys = {
  following: 'following',
  followers: 'followers',
};

export const communityTabsConfig = [
  {
    name: communityTabKeys.following,
    title: 'Following',
  },
  {
    name: communityTabKeys.followers,
    title: 'Followers',
  },
];

export const communityTypeKeys = {
  all: 'all',
  ambassadors: 'ambassadors',
};

export const communityTypeOptions = [
  { value: communityTypeKeys.all, label: 'All Users' },
  { value: communityTypeKeys.ambassadors, label: 'Ambassadors Only' },
];
