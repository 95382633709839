import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ProductThumbnail from 'components/ProductThumbnail/ProductThumbnail';
import productThumbnailClasses from 'components/ProductThumbnail/ProductThumbnail.module.scss';
import { productShape } from 'modules/product/propTypes';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import classes from './ProductItemDetails.module.scss';
import Button from '../../../components/ui/Button/Button';

const ProductItemDetails = ({
  product: { id, name, media, itemClassId, price, isLiked },
  onToggleLike,
  onClick,
}) => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));

  const history = useHistory();
  const { pathname } = useLocation();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const handleToggleLikeProduct = useCallback(() => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    onToggleLike(id, Number(!isLiked));
  }, [authenticated, onToggleLike, id, isLiked, history, pathname]);

  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <ProductThumbnail
      name={name}
      media={media}
      itemClassId={itemClassId}
      price={price}
      isLiked={isLiked}
      onClick={matchesMediaQuery ? handleClick : null}
      handleToggleLikeProduct={
        matchesMediaQuery ? handleToggleLikeProduct : null
      }
      hoverOverlay={
        matchesMediaQuery ? null : (
          <div className="h-100 d-flex flex-column">
            <div className="flex-fill flex-center">
              <span
                className="text-capitalize text-center cursor-pointer"
                onClick={handleClick}
              >
                View item details
              </span>
            </div>
            <div className={clsx('d-flex flex-column', classes.bottomBar)}>
              <div
                className={clsx(
                  'd-flex justify-content-between align-items-center',
                  classes.productLabel
                )}
              >
                <div className="py-1">
                  <p
                    title={name}
                    className={`${productThumbnailClasses.productName} text-ellipsis text-sm text-gray font-semi-bold mb-1`}
                  >
                    {name}
                  </p>
                  <span className="primary-color text-sm font-semi-bold">
                    {price ? `$${price}` : 'N/A'}
                  </span>
                </div>
                <Button
                  variant="contained"
                  inline
                  size="xs"
                  onClick={handleClick}
                >
                  <span className="px-2">VIEW</span>
                </Button>
              </div>
              <div className="d-flex justify-content-end align-items-center py-1">
                <IconButton
                  size="sm"
                  variant="inverted-grey"
                  onClick={handleToggleLikeProduct}
                >
                  <SpriteIcon
                    name={isLiked ? 'like-filled' : 'like'}
                    size="sm"
                  />
                </IconButton>
              </div>
            </div>
          </div>
        )
      }
    />
  );
};

ProductItemDetails.propTypes = {
  product: productShape.isRequired,
  onToggleLike: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProductItemDetails;
