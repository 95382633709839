import { errorMessages } from './constants';

const redeemPointsValidator = (values) => {
  const errors = {};

  Object.entries(values).forEach(([key, value]) => {
    if (value % 100 !== 0 || value < 0)
      errors[key] = errorMessages.multipleHundred;
  });

  return errors;
};

export default redeemPointsValidator;
