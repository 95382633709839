import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import StylesCard from 'components/PreferenceQuiz/StylesCard/StylesCard';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from 'components/PreferenceQuiz/steps/steps.module.scss';
import { useSelector } from 'react-redux';
import Prompt from './Prompt/Prompt';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../../constants/mediaQueries';

const StylesFilter = ({
  vibes,
  isQuiz,
  options,
  currentValue,
  onChange,
  isPromptShow,
  promptText,
}) => {
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const vibesEnum = useSelector((state) => state.app.enums.vibes);

  const handleOpenImgModal = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  return (
    <>
      {isQuiz ? (
        <>
          {vibes.map((vibe, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="mb-3" key={idx}>
              <h2 className={classes.stylesTitle}>
                {vibesEnum[vibe].publicName}
              </h2>
              <div className={clsx('row', classes.stylesContainer)}>
                {options
                  .filter((option) => option.vibeId === vibe)
                  .map((option) =>
                    matchesMediaQuery ? (
                      <div className="w-100 mb-3" key={option.id}>
                        <StylesCard
                          option={option}
                          selected={currentValue.includes(option.id)}
                          onChange={onChange}
                          onOpenPreviewModal={handleOpenImgModal}
                          isQuiz={isQuiz}
                        />
                      </div>
                    ) : (
                      <div className="col-4 mb-3" key={option.id}>
                        <StylesCard
                          option={option}
                          selected={currentValue.includes(option.id)}
                          onChange={onChange}
                          onOpenPreviewModal={handleOpenImgModal}
                          isQuiz={isQuiz}
                        />
                      </div>
                    )
                  )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className={clsx('row', classes.stylesContainer)}>
          {options.map((option) => (
            <div className="col-4 mb-3" key={option.id}>
              <StylesCard
                option={option}
                selected={currentValue.includes(option.id)}
                onChange={onChange}
                onOpenPreviewModal={handleOpenImgModal}
              />
            </div>
          ))}
        </div>
      )}
      <Prompt open={isPromptShow} text={promptText} />
      <BasicModal
        onClose={handleImgModalClose}
        open={imgModalOpen}
        classes={{ root: classes.imgPreviewModal }}
        maxWidth="md"
      >
        <img src={imgPreviewUrl} alt="" />
      </BasicModal>
    </>
  );
};

StylesFilter.propTypes = {
  isQuiz: PropTypes.bool,
  vibes: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  isPromptShow: PropTypes.bool,
  promptText: PropTypes.string,
};

StylesFilter.defaultProps = {
  isPromptShow: false,
  promptText: '',
  isQuiz: false,
  vibes: [],
};

export default StylesFilter;
