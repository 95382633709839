import {
  SET_ACTIVE_TAB,
  SET_SHOWCASE_ACTIVE_TAB,
} from 'modules/dashboard/store/constants';

export const setDashboardActiveTab = (tabName) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_TAB, payload: tabName });
};

export const setShowcaseActiveTab = (tabName) => (dispatch) => {
  dispatch({ type: SET_SHOWCASE_ACTIVE_TAB, payload: tabName });
};
