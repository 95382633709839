export const tooltipShareText = {
  download: 'Download Hi-Res Image to Share on Social Media',
  copyLink: 'Copy to Share the Image Link on Social Media',
  emailLink: 'Email Link to the Look Board Details Page',
  emailLinkShowcase: 'Email Link to the Showcase Page',
  pinIcon: 'Download Pin the Look Card.',
  profileIcon: 'Download Showcase Card.',
  sendMessage: 'Send a message to this showcase owner',
  socialAccounts: 'Visit my social accounts',
};
