import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import currentUserService from '../../currentUser/currentUserService';
import {
  findAmbassadorUserAction,
  setAmbassadorsActiveTabAction,
} from './store/actions';
import AmbassadorShowcaseView from './AmbassadorShowcaseView';
import { getTheLookAmbassadorsTabKeys } from './config';

const SelectedAmbassador = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const selectedAmbassador = useSelector(
    (state) => state.ambassadorsPage.selectedAmbassador
  );

  const loadShowCase = useCallback(async () => {
    const { firstName, lastName } = await currentUserService.getUserById(
      userId
    );
    await dispatch(
      findAmbassadorUserAction(firstName, lastName, Number(userId))
    );
    dispatch(
      setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.showcase)
    );
  }, [dispatch, userId]);

  useEffect(() => {
    loadShowCase();
  }, [loadShowCase]);

  if (!selectedAmbassador) return 'Loading...';

  return <AmbassadorShowcaseView />;
};

export default SelectedAmbassador;
