import PropTypes from 'prop-types';
import { mediaShape } from 'modules/media/propTypes';

export const productShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number,
  isLiked: PropTypes.number.isRequired,
  itemClassId: PropTypes.number.isRequired,
  media: mediaShape.isRequired,
  shareUrl: PropTypes.string.isRequired,
  averageRating: PropTypes.string,
});
