import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import BasicAvatar from 'components/ui/BasicAvatar';
import ImageUploadHandler from 'components/photoUploaders/ImageUploadHandler/ImageUploadHandler';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    minWidth: '200px',
  },
  profileAvatar: {
    width: spacing(12),
    height: spacing(12),
    fontSize: 36,
    fontWeight: 600,
    marginBottom: spacing(2),
  },
  fullName: {
    fontSize: 18,
    fontWeight: 600,
    maxWidth: 200,
  },
}));

const ProfilePhoto = ({
  firstName,
  lastName,
  displayName,
  src,
  editMode,
  onSelectNewImage,
}) => {
  const classes = useStyles();

  const fullName = useMemo(() => displayName ?? `${firstName} ${lastName}`, [
    firstName,
    lastName,
    displayName,
  ]);

  return (
    <div
      className={clsx(
        'd-inline-flex flex-column align-items-center',
        classes.root
      )}
    >
      <BasicAvatar
        firstName={firstName}
        lastName={lastName}
        src={src}
        className={classes.profileAvatar}
      />
      <p className={`${classes.fullName} mb-1 text-center text-ellipsis`}>
        {fullName}
      </p>
      {editMode && <ImageUploadHandler onChange={onSelectNewImage} />}
    </div>
  );
};

ProfilePhoto.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  onSelectNewImage: PropTypes.func.isRequired,
  src: PropTypes.string,
  editMode: PropTypes.bool,
  displayName: PropTypes.string,
};

ProfilePhoto.defaultProps = {
  src: undefined,
  editMode: false,
  displayName: null,
};

export default ProfilePhoto;
