import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import classes from './MobileFilter.module.scss';
import { useDropdownData } from '../FilterDropdown/useDropdownData';

const MobileFilter = ({ currentFilters, onChange, activeTab }) => {
  const scrollBarRef = useRef(null);
  const transformedData = useDropdownData(currentFilters);
  const [filterData, setFilterData] = useState(transformedData);

  const toggleExpand = useCallback(
    (node) => {
      setFilterData((prev) =>
        prev.map((current) => {
          return {
            ...current,
            extended:
              node.name === current.name
                ? !node.extended
                : current.children.some((child) => node.name === child.name),
          };
        })
      );
    },
    [setFilterData]
  );

  const toggleParentChecked = useCallback(
    (node) => {
      setFilterData((prev) =>
        prev
          .map((current) => ({
            ...current,
            children: current.children?.map((child) => ({
              ...child,
              ...(node.name === child.name ? { checked: !child.checked } : {}),
            })),
          }))
          .map((current) => ({
            ...current,
            children: current.children?.map((child) => {
              const disabled = node.name === child.name && !child.checked;
              return {
                ...child,
                extended:
                  node.name === child.name ? !child.extended : child.extended,
                children: !disabled
                  ? child.children
                  : child.children?.map((nestedChild) => {
                      onChange(nestedChild.type, nestedChild.id, false);
                      return {
                        ...nestedChild,
                        checked: false,
                      };
                    }),
              };
            }),
          }))
      );
      onChange(node.type, node.id, !node.checked);
    },
    [onChange, setFilterData]
  );

  const toggleChildChecked = useCallback(
    (node) => {
      setFilterData((prev) =>
        prev.map((current) => ({
          ...current,
          children: current.children.map((child) => ({
            ...child,
            children: child.children?.map((nestedChild) => ({
              ...nestedChild,
              ...(node.name === nestedChild.name &&
              node.styleId === nestedChild.styleId
                ? { checked: !nestedChild.checked }
                : {}),
            })),
          })),
        }))
      );
      onChange(node.type, node.id, !node.checked);
    },
    [onChange, setFilterData]
  );

  const showScrollBar = useCallback(
    (children) => {
      if (activeTab === 'roomTypes') return children.length >= 7;
      const extendedFields = children.filter((child) => child.extended).length;
      if (extendedFields >= 4) return true;
      return children.length >= 20;
    },
    [activeTab]
  );

  return (
    <div className={classes.body}>
      {filterData.map((drop) => (
        <>
          <div
            className="d-flex align-items-center justify-content-between cursor-pointer py-1"
            onClick={() => toggleExpand(drop)}
          >
            <p className={classes.dropdownTitle}>{drop.name}</p>
            <SpriteIcon
              name="arrow-up"
              size="xs"
              className={clsx(
                classes.titleArrow,
                drop.extended && classes.active
              )}
            />
          </div>
          {showScrollBar(drop.children) ? (
            <CustomScrollBar
              scrollBarRef={scrollBarRef}
              autoHeightMax={activeTab === 'roomTypes' ? 300 : 600}
            >
              {drop.extended &&
                drop.children?.map((child) => (
                  <div>
                    <div
                      className="d-flex align-items-center my-1 cursor-pointer"
                      onClick={() => toggleParentChecked(child)}
                    >
                      <Checkbox className="p-0" checked={child.checked} />
                      <p className={classes.inputLabel}>{child.name}</p>
                    </div>

                    {child.extended &&
                      child.children?.map((nestedChild) => (
                        <div
                          className="pl-3 d-flex align-items-center my-1 cursor-pointer"
                          onClick={() => toggleChildChecked(nestedChild)}
                        >
                          <Checkbox
                            className="p-0"
                            checked={nestedChild.checked}
                          />
                          <p className={classes.inputLabel}>
                            {nestedChild.name}
                          </p>
                        </div>
                      ))}
                  </div>
                ))}
            </CustomScrollBar>
          ) : (
            <div>
              {drop.extended &&
                drop.children?.map((child) => (
                  <>
                    <div
                      className="d-flex align-items-center my-1 cursor-pointer"
                      onClick={() => toggleParentChecked(child)}
                    >
                      <Checkbox className="p-0" checked={child.checked} />
                      <p className={classes.inputLabel}>{child.name}</p>
                    </div>
                    {child.extended &&
                      child.children?.map((nestedChild) => (
                        <div
                          className="pl-3 d-flex align-items-center my-1 cursor-pointer"
                          onClick={() => toggleChildChecked(nestedChild)}
                        >
                          <Checkbox
                            className="p-0"
                            checked={nestedChild.checked}
                          />
                          <p className={classes.inputLabel}>
                            {nestedChild.name}
                          </p>
                        </div>
                      ))}
                  </>
                ))}
            </div>
          )}
        </>
      ))}
    </div>
  );
};

export default MobileFilter;

MobileFilter.propTypes = {
  activeTab: PropTypes.string.isRequired,
  currentFilters: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};
