import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import sampleShowcaseImg from 'assets/img/sample-showcase-page.png';

const SampleModal = ({ open, onClose }) => (
  <BasicModal open={open} onClose={onClose} maxWidth="md" scroll="body">
    <h3 className="font-title text-lg text-uppercase mb-3">
      Sample Showcase Page
    </h3>
    <img src={sampleShowcaseImg} width="100%" alt="" />
  </BasicModal>
);

SampleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SampleModal;
