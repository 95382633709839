import React from 'react';
import PropTypes from 'prop-types';
import digitalCardImg from 'assets/img/showcase-digital-card.jpg';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from 'modules/dashboard/showcase/Showcase.module.scss';

const DigitalBusinessModal = ({ open, onClose }) => (
  <BasicModal open={open} onClose={onClose} maxWidth="lg" scroll="body">
    <h3
      className="font-title text-capitalize text-center mb-3"
      style={{ fontSize: 36 }}
    >
      My Digital Business Card
    </h3>
    <p className={`${classes.businessModalSubTitle} mb-3`}>
      You and other users can share your account in the Social Media. You can
      check how your digital business card will look!
    </p>
    <div className="px-5 my-5">
      <img
        src={digitalCardImg}
        alt="Showcase Digital Card"
        style={{ maxWidth: 700 }}
      />
    </div>
  </BasicModal>
);

DigitalBusinessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DigitalBusinessModal;
