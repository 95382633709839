import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { createProductFieldKeys } from 'modules/pinMedia/constants';
import { getItemClassesWithItemTypes } from 'modules/app/store/selectors';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import AutoCompleteInput from 'components/inputs/AutoCompleteInput/AutoCompleteInput';
import classes from 'modules/pinMedia/CreateImageForm/CreateImageForm.module.scss';
import appConfig from '../../../constants/appConfig';

const CreateProductForm = ({
  validate,
  onSubmit,
  initialValues,
  noImageFind,
  withExtension,
  withManual,
  extensionData,
}) => {
  const {
    itemClasses: itemClassesEnum,
    itemTypes: itemTypesEnum,
  } = useSelector((state) => state.app.enums);
  const itemClasses = useMemo(
    () =>
      getItemClassesWithItemTypes({
        itemTypes: itemTypesEnum,
        itemClasses: itemClassesEnum,
      }),
    [itemTypesEnum, itemClassesEnum]
  );
  const user = useSelector((state) => state.auth.user);

  const isProductCount = useMemo(
    () =>
      Boolean(
        !user?.isPTLAmbassador &&
          user?.productCount >= appConfig.productCountMax
      ),
    [user]
  );

  const [autoCompleteValue, setAutoCompleteValue] = useState(itemClasses[0]);
  const [initialFormValues, setInitialFormValues] = useState({
    ...initialValues,
    itemClassId: itemClasses[0].value,
  });

  useEffect(() => {
    setInitialFormValues({
      ...initialValues,
      itemClassId: itemClasses[0].value,
    });
  }, [initialValues, itemClasses]);

  const handleChangeItemClass = useCallback(
    (option, formValues) => {
      if (option?.value) {
        setAutoCompleteValue(option);
        setInitialFormValues(() => ({
          ...formValues,
          [createProductFieldKeys.itemClassId]: option.value,
          imageUrl: extensionData.imageUrl,
        }));
      }
    },
    [extensionData.imageUrl]
  );
  return (
    <>
      <Form
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (
          <form
            className=" h-100 d-flex flex-column"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="flex-fill">
              <Field
                label="Name"
                required
                initialValue={extensionData?.imageTitle}
                name={createProductFieldKeys.name}
                component={TextInputWrapper}
                disabled
              />
              <Field
                name={createProductFieldKeys.itemClassId}
                render={() => {
                  return (
                    <AutoCompleteInput
                      groupBy={(option) => option.typeName}
                      value={autoCompleteValue}
                      options={itemClasses}
                      handleChange={handleChangeItemClass}
                      formValues={values}
                      placeholder="Item Class"
                      label="Item Class (Optional)"
                    />
                  );
                }}
              />
              <Field
                label="Product URL"
                required
                disabled={!withManual}
                initialValue={extensionData?.url}
                name={createProductFieldKeys.url}
                component={TextInputWrapper}
                InputProps={{
                  classes: { disabled: classes.disabledInput },
                }}
                classes={{ root: classes.productField }}
              />
              <Field
                label="Description (optional)"
                name={createProductFieldKeys.description}
                multiline
                rows={5}
                component={TextInputWrapper}
              />

              {!withExtension && !withManual && (
                <Field
                  label="Size (optional)"
                  name={createProductFieldKeys.size}
                  component={TextInputWrapper}
                />
              )}
            </div>
            <div className="d-flex justify-content-center">
              <div className={classes.submitBtnContainer}>
                <FinalFormSubmitButton
                  color="secondary"
                  size="sm"
                  disabled={noImageFind || isProductCount}
                >
                  Select
                </FinalFormSubmitButton>
              </div>
            </div>
          </form>
        )}
      />
    </>
  );
};

CreateProductForm.propTypes = {
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  extensionData: PropTypes.shape({
    imageUrl: PropTypes.string,
    imageTitle: PropTypes.string,
    productPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    url: PropTypes.string,
  }),
  noImageFind: PropTypes.bool,
  withExtension: PropTypes.bool,
  withManual: PropTypes.bool,
};

CreateProductForm.defaultProps = {
  extensionData: {
    imageUrl: null,
    imageTitle: null,
    productPrice: null,
    url: null,
  },
  withExtension: false,
  withManual: false,
  noImageFind: false,
  initialValues: {},
};

export default CreateProductForm;
