import React from 'react';
import clsx from "clsx";
import background from "assets/img/get-the-look-page/background.jpg";
import Button from "components/ui/Button/Button";
import BasicModal from "components/modals/BasicModal/BasicModal";
import PropTypes from "prop-types";
import CompareSectionSliders from "modules/home/components/HowItWorks/CompareSectionSliders/CompareSectionSliders";
import { mixMatchSteps } from "../../constants";
import classes from "./MixMatchModal.module.scss";

const MixMatchModal = ({ visible, onClose }) => {
  return (
    <BasicModal open={visible} onClose={onClose} classes={{ root: classes.mixMatchModal }}>
      <h2 className={classes.mixMatchTitle}>MIX & MATCH THE LOOK</h2>
      <p className={classes.mixMatchSubtitle}>
        Use our Mix & Match Tool to Get the Look of your Favorite Room Images Your Way!
      </p>
      <div className='d-flex align-items-center justify-content-end'>
        <div className={classes.mixMatchImagesWrapper}>
          <CompareSectionSliders/>
        </div>
        <div className='pr-5'>
          <ul className={clsx(classes.mixMatchList, 'p-0', 'm-0')}>
            {mixMatchSteps.map(step => (
              <li key={step.id} className={clsx('d-flex align-items-start', classes.mixMatchListItem)}>
                <div
                  className={clsx(classes.mixMatchListNumber, 'd-flex', 'align-items-center', 'justify-content-center')}>{step.id}</div>
                <p className={classes.mixMatchListText}>{step.title}</p>
              </li>
            ))}
          </ul>
          <Button
            onClick={onClose}
            variant="contained"
            size="md"
            type="button"
            className={classes.mixMatchButton}
          >
            Get Started
          </Button>
        </div>
      </div>

      <div className={clsx(classes.mixMatchOverlay, 'd-flex', 'position-absolute')}>
        <img src={background} alt="" className='w-50'/>
        <div className={clsx('w-50', classes.mixMatchBackground)}/>
      </div>
    </BasicModal>
  );
};

export default MixMatchModal;

MixMatchModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}