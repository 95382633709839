export const stepsConfig = [
  {
    number: 1,
    title: 'Your Description',
    subtitle: 'What Best Describes You',
    selectDescription: 'Select one',
    progress: 0,
  },
  {
    number: 2,
    title: 'Favorite Styles',
    subtitle: 'What Rooms do you like best',
    selectDescription: 'Select up to 10',
    progress: 20,
  },
  {
    number: 3,
    title: 'Sub Styles',
    subtitle: 'What Rooms do you like best',
    selectDescription:
      'Indicate which room images you like by clicking on the heart',
    progress: 40,
  },
  {
    number: 4,
    title: 'Room Types',
    subtitle: 'Which Room Types to you Prefer to Decorate',
    selectDescription: 'Scroll through to select up to 3',
    progress: 60,
  },
  {
    number: 5,
    title: 'Favorite Colors',
    subtitle: 'What colors do you like best',
    selectDescription: 'Select up to 5 Colors',
    progress: 80,
  },
  // {
  //   number: 6,
  //   title: 'Price Range',
  //   subtitle: 'What is your Price Range',
  //   selectDescription: 'Select all that apply',
  //   progress: 85,
  // },
];
