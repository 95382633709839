import {
  accountTypes,
  errorMessages,
  signUpFormFields,
} from 'modules/auth/signUp/constants';

const changeProfileValidator = ({
  [signUpFormFields.type]: type,
  [signUpFormFields.businessType]: businessType,
  [signUpFormFields.businessName]: businessName,
  [signUpFormFields.firstName]: firstName,
  [signUpFormFields.lastName]: lastName,
  [signUpFormFields.languageId]: languageId,
}) => {
  const errors = {};

  if (!firstName) {
    errors.firstName = errorMessages.required;
  }
  if (!lastName) {
    errors.lastName = errorMessages.required;
  }
  if (!languageId) {
    errors.languageId = errorMessages.required;
  }

  if (type === accountTypes.business && !businessName) {
    errors.businessName = errorMessages.required;
  }
  if (type === accountTypes.business && !businessType) {
    errors.businessType = errorMessages.required;
  }
  return errors;
};

export default changeProfileValidator;
