import React from 'react';
import AutoCompleteInput from 'modules/requestTheLook/requestDetails/components/AutoCompleteInput/AutoCompleteInput';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PriorityItemsOption from 'modules/requestTheLook/requestDetails/components/PriorityItemsOption/PriorityItemsOption';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';
import classes from '../../requestForm/RequestForm.module.scss';

const SingleItemRequest = ({
  requestPriorityItems,
  handleChangeSingleType,
  optionsList,
}) => (
  <>
    <div className="d-flex flex-column mb-5">
      <div className="text-sm font-semi-bold mr-1">Item Type</div>
      <div className={clsx(classes.singleItemType, 'mt-3 d-flex')}>
        <PriorityItemsOption
          value={requestPriorityItems[0]?.value}
          label={requestPriorityItems[0]?.label}
          selected
        />
      </div>
    </div>
    <div className="d-flex flex-column mb-2">
      <div className="text-sm font-semi-bold mr-1">
        To change the item type, you can search all items type here:
      </div>
      <div className="mt-3 col-4">
        <AutoCompleteInput
          value={{ label: '', value: null }}
          handleChange={handleChangeSingleType}
          options={optionsList}
          addAnotherItemEnable
        />
      </div>
    </div>
  </>
);

SingleItemRequest.propTypes = {
  requestPriorityItems: PropTypes.arrayOf(priorityItemShape).isRequired,
  handleChangeSingleType: PropTypes.func.isRequired,
  optionsList: PropTypes.arrayOf(priorityItemShape).isRequired,
};
export default SingleItemRequest;
