import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import RoomTypeOption from 'components/PreferenceQuiz/RoomTypeOption/RoomTypeOption';
import classes from 'components/PreferenceQuiz/steps/steps.module.scss';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../../constants/mediaQueries';

const RoomTypesFilter = ({ options, currentValue, onChange }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  return (
    <div className={clsx('row', classes.roomTypeContainer)}>
      {options.map((option) => (
        <div
          className={classes.roomTypeItem}
          style={{ width: !matchesMediaQuery ? '20%' : '100%' }}
          key={option.id}
        >
          <RoomTypeOption
            option={option}
            selected={currentValue.includes(option.id)}
            onChange={onChange}
          />
        </div>
      ))}
    </div>
  );
};

RoomTypesFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RoomTypesFilter;
