import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { getSubStyleImgUrl } from 'modules/app/store/selectors';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './SubStyleCard.module.scss';

const SubStyleCard = ({
  id,
  name,
  media: { hash },
  onLike,
  onSkip,
  selected,
  showLike,
  showLabel,
  styleName,
}) => {
  const subStyleImgUrl = getSubStyleImgUrl(
    useSelector((state) => state.app.config)
  );

  const handleLike = useCallback(() => {
    onLike(id, !selected);
  }, [onLike, id, selected]);

  return (
    <div className={classes.root}>
      <img
        src={subStyleImgUrl.replace(urlPlaceholders.hash, hash)}
        alt={name}
      />
      {showLabel && styleName && (
        <div className={clsx(classes.label)}>{name}</div>
      )}
      {showLike && (
        <>
          <div className={clsx('d-flex', classes.btnContainer)}>
            <div className={classes.likeHover}>
              <IconButton
                className={clsx(classes.likeBtn, {
                  [classes.selected]: selected,
                })}
                onClick={handleLike}
              >
                <SpriteIcon name="like-filled" size="sm" />
              </IconButton>
            </div>
            {onSkip && (
              <div className={classes.skipHover}>
                <IconButton className={clsx(classes.likeBtn)} onClick={onSkip}>
                  Skip
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.bottomGradient} />
        </>
      )}
    </div>
  );
};

SubStyleCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  media: PropTypes.shape({
    hash: PropTypes.string.isRequired,
  }).isRequired,
  onLike: PropTypes.func.isRequired,
  onSkip: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  showLike: PropTypes.bool.isRequired,
  showLabel: PropTypes.bool,
  styleName: PropTypes.string,
};

SubStyleCard.defaultProps = {
  onSkip: null,
  showLabel: false,
  styleName: null,
};

export default SubStyleCard;
