import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import { routesByName } from 'constants/routes';
import {
  ambassadorInfoMenuItemsConfig,
  ambassadorNavLinksConfig,
  infoMenuConfig,
  navLinksConfig,
} from 'modules/dashboard/constants';
import AmbassadorRoute from 'components/AmbassadorRoute';
import SideNav from 'modules/layout/SideNav/SideNav';
import CustomerSupportBanner from 'modules/account/components/CustomerSupportBanner/CustomerSupportBanner';
import MyImagesContainer from 'modules/dashboard/myImages/MyImagesContainer';
import AmbassadorManagement from 'modules/dashboard/ambassadorManagement/AmbassadorManagement';
import NomineesContainer from 'modules/dashboard/nominees/NomineesContainer';
import TipsBanner from 'modules/dashboard/components/TipsBanner/TipsBanner';
import MyLookBoardsContainer from 'modules/dashboard/myLookBoards/MyLookBoardsContainer';
import MyRequestsContainer from 'modules/dashboard/myRequests/MyRequestsContainer';
import CommunityContainer from 'modules/dashboard/community/CommunityContainer';
import AmbassadorEdit from 'modules/dashboard/components/AmbassadorEdit/AmbassadorEdit';
import NominateAmbassador from 'modules/dashboard/components/NominateAmbassador/NominateAmbassador';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import DashboardMobilePage from 'modules/dashboard/DashboardMobilePage';
import ShowcaseContainer from 'modules/dashboard/showcase/ShowcaseContainer';
import infoToastr from 'libs/toastr/infoToastr';
import ShowcaseActivator from 'modules/dashboard/showcase/components/ShowcaseActivator';
import MessagesContainer from 'modules/dashboard/messages/MessagesContainer';
import classes from 'modules/account/Account.module.scss';
import MyPoints from './myPoints/MyPoints';
import AmbassadorLevel from './components/AmbassadorLevel/AmbassadorLevel';
import currentUserService from '../currentUser/currentUserService';
import RightDragPanel from './showcase/components/RightDragPanel';
import {
  dropImageAction,
  dropLookBoardAction,
} from '../showcase/store/actions';
import { dropItemType } from './showcase/constants';
import { approvalStatusKeys } from '../inspirationImage/constants';
import warningToastr from '../../libs/toastr/warningToastr';
import AmbassadorVideos from './ambassadorManagement/AmbassadorVideos';
import AmbassadorPointsBadges from './ambassadorManagement/AmbassadorPointsBadges';
import AmbassadorPrograms from './ambassadorManagement/AmbassadorPrograms';
import AmbassadorFaqs from './ambassadorManagement/AmbassadorFaqs/AmbassadorFaqs';

const DashboardContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const unreadConversations = useSelector(
    (state) => state.conversation.unreadConversations
  );
  const inspirationImageLibrary = useSelector(
    (state) => state.inspirationImage.library
  );
  const lookBoardsLibrary = useSelector(
    (state) => state.showcase.lookBoardsLibrary
  );
  const hasVerifiedEmail = useMemo(() => Boolean(user?.hasVerifiedEmail), [
    user,
  ]);
  const isAmbassador = useMemo(() => Boolean(user?.ambassador), [user]);
  const unreadConversationsCount = useMemo(() => unreadConversations.length, [
    unreadConversations,
  ]);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const [activities, setActivities] = useState([]);
  const [stats, setStats] = useState(null);

  const { pathname } = useLocation();

  const isAmbassadorManagementPage = useMemo(
    () =>
      pathname === routesByName.dashboard.ambassadorManagement ||
      pathname === routesByName.dashboard.ambassadorVideos ||
      pathname === routesByName.dashboard.ambassadorPrograms ||
      pathname === routesByName.faqs ||
      pathname === routesByName.dashboard.ambassadorPointsBadges,
    [pathname]
  );

  const isAmbassadorShowcasePage = useMemo(
    () => pathname === routesByName.dashboard.ambassadorShowcase,
    [pathname]
  );

  const isAmbassadorPointsPage = useMemo(
    () => pathname === routesByName.dashboard.points,
    [pathname]
  );

  const handleDropItem = useCallback(
    (e) => {
      if (!e.destination) return;
      const [droppableKey] = e.destination.droppableId.split('_');
      const [draggableKey, draggableId] = e.draggableId.split('_');

      let draggableItem = null;

      if (draggableKey === dropItemType.inspirationImages) {
        draggableItem = inspirationImageLibrary[draggableId];
      } else draggableItem = lookBoardsLibrary[draggableId];

      if (droppableKey !== draggableKey) {
        const message =
          droppableKey === dropItemType.inspirationImages
            ? 'You can only drop inspiration images here'
            : 'You can only drop lookboards here';
        infoToastr('Warning', message);
        return;
      }

      if (draggableItem?.approval !== approvalStatusKeys.approved) {
        warningToastr('Warning', 'You can drop only approved items');
        return;
      }

      if (draggableKey === dropItemType.inspirationImages)
        dispatch(dropImageAction({ ...e, draggableId }));
      else dispatch(dropLookBoardAction({ ...e, draggableId }));
    },
    [dispatch, inspirationImageLibrary, lookBoardsLibrary]
  );

  useEffect(() => {
    currentUserService.getUserActivities().then(setActivities);
    currentUserService.getUserStats().then(setStats);
  }, []);

  const totalPoints = useMemo(() => {
    return activities.reduce(
      (accum, activity) =>
        accum + activity.activity_point_value * activity.activity_count,
      0
    );
  }, [activities]);

  return matchesMediaQuery ? (
    <DashboardMobilePage />
  ) : (
    <div className="flex-fill d-flex">
      <SideNav
        isAmbassador={isAmbassador}
        navLinks={navLinksConfig}
        ambassadorNavLinks={ambassadorNavLinksConfig}
        infoMenuConfig={infoMenuConfig}
        ambassadorInfoMenuItems={ambassadorInfoMenuItemsConfig}
        unreadConversationsCount={unreadConversationsCount}
      />
      <div className="flex-fill">
        <div className={classes.contentWrapper}>
          <DragDropContext onDragEnd={handleDropItem}>
            <div className={classes.routeContainer}>
              <Switch>
                <Redirect
                  exact
                  from={routesByName.dashboard.index}
                  to={routesByName.dashboard.images}
                />
                <AmbassadorRoute
                  path={routesByName.dashboard.ambassadorManagement}
                >
                  <AmbassadorManagement />
                </AmbassadorRoute>
                <AmbassadorRoute path={routesByName.dashboard.ambassadorVideos}>
                  <AmbassadorVideos />
                </AmbassadorRoute>
                <AmbassadorRoute
                  path={routesByName.dashboard.ambassadorPrograms}
                >
                  <AmbassadorPrograms />
                </AmbassadorRoute>
                <AmbassadorRoute
                  path={routesByName.dashboard.ambassadorPointsBadges}
                >
                  <AmbassadorPointsBadges />
                </AmbassadorRoute>
                <AmbassadorRoute path={routesByName.faqs}>
                  <AmbassadorFaqs />
                </AmbassadorRoute>
                <AmbassadorRoute
                  path={routesByName.dashboard.ambassadorShowcase}
                >
                  <ShowcaseContainer />
                </AmbassadorRoute>
                <AmbassadorRoute path={routesByName.dashboard.ambassadorTeam}>
                  <NomineesContainer />
                </AmbassadorRoute>
                <AmbassadorRoute path={routesByName.dashboard.points}>
                  <MyPoints activities={activities} totalPoints={totalPoints} />
                </AmbassadorRoute>
                <Route path={routesByName.dashboard.images}>
                  <MyImagesContainer />
                </Route>
                <Route path={routesByName.dashboard.lookBoards}>
                  <MyLookBoardsContainer />
                </Route>
                <Route path={routesByName.dashboard.requests}>
                  <MyRequestsContainer
                    isAmbassador={isAmbassador}
                    hasVerifiedEmail={hasVerifiedEmail}
                  />
                </Route>
                <Route path={routesByName.dashboard.community}>
                  <CommunityContainer />
                </Route>
                <Route path={routesByName.dashboard.messages}>
                  <MessagesContainer />
                </Route>
              </Switch>
            </div>

            {!isAmbassadorManagementPage && (
              <div className={classes.rightSection}>
                <>
                  <AmbassadorRoute
                    exact
                    path={routesByName.dashboard.ambassadorShowcase}
                  >
                    <ShowcaseActivator />
                  </AmbassadorRoute>
                  {isAmbassador ? (
                    <>
                      {isAmbassadorShowcasePage ? (
                        <div className="d-flex flex-column" style={{ flex: 1 }}>
                          <AmbassadorEdit />
                          <RightDragPanel />
                        </div>
                      ) : (
                        <>
                          {isAmbassadorPointsPage && (
                            <div className="mb-4">
                              <AmbassadorLevel stats={stats} />
                            </div>
                          )}
                          <div className="mb-4">
                            <AmbassadorEdit />
                            <TipsBanner />
                          </div>

                          <div className="mb-4">
                            <NominateAmbassador />
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div className={classes.bannersContainer}>
                      <CustomerSupportBanner />
                    </div>
                  )}
                </>
              </div>
            )}
          </DragDropContext>
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;
