import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routesByName } from 'constants/routes';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import { infoMenuShape } from 'modules/layout/SideNav/propTypes';
import InfoMenu from 'modules/layout/SideNav/InfoMenu/InfoMenu';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/layout/SideNav/SideNav.module.scss';
import { SidebarTooltip } from './SidebarTooltip';

const SideBar = ({
  currentSource,
  onUpdateSearchParams,
  unselectImage,
  infoMenuConfig,
}) => {
  const currentUser = useSelector((state) => state.auth.user);
  const { lookBoardId } = useSelector(
    (state) => state.curateTheLook.lookBoardData
  );

  const history = useHistory();
  const { pathname } = useLocation();
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  const handleOpenInfoPopup = useCallback(() => {
    setInfoPopupOpen(true);
  }, []);

  const handleCloseInfoPopup = useCallback(() => {
    setInfoPopupOpen(false);
  }, []);

  const handleChangeImgSource = useCallback(
    ({ currentTarget }) => {
      const source = currentTarget.getAttribute('data-source');
      if (!currentUser && source === imageSourceKeys.my) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      if (!infoPopupOpen || currentSource !== source) {
        onUpdateSearchParams({ source });
        unselectImage();
      }
      setInfoPopupOpen(false);
    },
    [
      currentUser,
      pathname,
      history,
      currentSource,
      unselectImage,
      onUpdateSearchParams,
      infoPopupOpen,
    ]
  );

  return (
    <div className={classes.root}>
      <SidebarTooltip text="Info">
        <div
          className={clsx(classes.navLink, {
            [classes.activeTabInfo]: infoPopupOpen,
          })}
          onClick={handleOpenInfoPopup}
        >
          <SpriteIcon name="info" />
        </div>
      </SidebarTooltip>
      <SidebarTooltip text="Inspiration Images Database">
        <div
          className={clsx(classes.navLink, {
            [classes.activeTab]:
              currentSource === imageSourceKeys.all && !lookBoardId,
          })}
          data-source={imageSourceKeys.all}
          onClick={handleChangeImgSource}
        >
          <SpriteIcon name="living-room" size="lg" />
        </div>
      </SidebarTooltip>
      {!!lookBoardId && (
        <SidebarTooltip text="Curate Similar Look Boards">
          <div
            className={clsx(classes.navLink, {
              [classes.activeTab]: lookBoardId,
            })}
            data-source={imageSourceKeys.all}
            onClick={handleChangeImgSource}
          >
            <SpriteIcon name="dashboard" size="lg" />
          </div>
        </SidebarTooltip>
      )}
      <InfoMenu
        config={infoMenuConfig}
        isOpen={infoPopupOpen}
        onClose={handleCloseInfoPopup}
      />
    </div>
  );
};

SideBar.propTypes = {
  currentSource: PropTypes.string.isRequired,
  onUpdateSearchParams: PropTypes.func.isRequired,
  unselectImage: PropTypes.func.isRequired,
  infoMenuConfig: infoMenuShape.isRequired,
};

export default SideBar;
