import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EmptyPreferencesView from 'modules/account/profileAndPreferences/preferences/EmptyPreferencesView';
import PreferenceQuiz from 'components/PreferenceQuiz/PreferenceQuiz';
import PreferencesView from 'modules/account/profileAndPreferences/preferences/PreferencesView';
import classes from './Preferences.module.scss';

const PreferencesContainer = ({
  isEdit,
  onEnableEdit,
  onCancelEdit,
  handleOpenGetStartedModal,
}) => {
  const { preferences } = useSelector(
    (state) => state.auth.user || { preferences: null }
  );

  return (
    <div className={classes.section}>
      {isEdit && (
        <PreferenceQuiz onCancelEdit={onCancelEdit} onComplete={onCancelEdit} />
      )}
      {!isEdit && preferences && (
        <PreferencesView
          onEnableEditMode={onEnableEdit}
          handleOpenGetStartedModal={handleOpenGetStartedModal}
        />
      )}
      {!isEdit && !preferences && (
        <EmptyPreferencesView onEnableEditMode={onEnableEdit} />
      )}
    </div>
  );
};

PreferencesContainer.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onEnableEdit: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  handleOpenGetStartedModal: PropTypes.func.isRequired,
};

export default PreferencesContainer;
