import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accountTypes } from 'modules/auth/signUp/constants';
import CustomerSupportBanner from 'modules/account/components/CustomerSupportBanner/CustomerSupportBanner';
import PreferenceQuiz from 'components/PreferenceQuiz/PreferenceQuiz';
import ProfileForm from 'modules/ambassadors/becomeAmbassador/ProfileForm';
import TradeOpportunitiesBanner from 'modules/ambassadors/becomeAmbassador/tradeOpportunitiesBanner/TradeOpportunitiesBanner';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import currentUserService from 'modules/currentUser/currentUserService';
import errorToastr from 'libs/toastr/errorToastr';
import { syncCurrentUserAction } from 'modules/auth/store/actions';
import classes from './BecomeAmbassador.module.scss';
import Button from '../../../components/ui/Button/Button';

const BecomeAmbassadorContainer = () => {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.user.type);
  const currentUser = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [quizCompleted, setQuizCompleted] = useState(false);

  const onCompleteQuiz = useCallback(async () => {
    try {
      const { result } = await currentUserService.finishPreferences();
      if (result === 1) {
        await dispatch(syncCurrentUserAction());
        history.push(routesByName.dashboard.ambassadorManagement);
      } else {
        setQuizCompleted(true);
      }
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [history, dispatch]);

  return (
    <div className={classes.contentWrapper}>
      <h3 className={classes.title}>Set Ambassador design preferences</h3>
      <p className="font-italic mb-5">
        To get started, please answer these questions to set your preferences or
        use existing preferences.
      </p>
      {!!currentUser?.preferences && !quizCompleted && (
        <Button
          size="md"
          className={classes.usePrefButton}
          onClick={onCompleteQuiz}
        >
          Use Existing Preferences
        </Button>
      )}
      {quizCompleted ? (
        <ProfileForm />
      ) : (
        <div className="d-flex mb-5">
          <div className={classes.section}>
            <PreferenceQuiz onComplete={onCompleteQuiz} />
          </div>
          <div className={classes.rightSection}>
            <div className="mb-4">
              <CustomerSupportBanner />
            </div>
            {userType === accountTypes.business && <TradeOpportunitiesBanner />}
          </div>
        </div>
      )}
    </div>
  );
};
export default BecomeAmbassadorContainer;
