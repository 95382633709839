export const productTypeKeys = {
  all: 'all',
  my: 'my',
  liked: 'liked',
};

export const productSortKeys = {
  newest: 'newest',
  highest: 'highest',
};
