import React from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import previewImg from 'assets/img/dashboard-page/nominate-banner.png';
import classes from './NominateAmbassador.module.scss';

const NominateAmbassador = () => (
  <div className={classes.root}>
    <img src={previewImg} alt="" />
    <div className={classes.text}>
      <h3 className={classes.title}>
        Nominate a Friend <br /> to Join Us!
      </h3>
      <Link to={routesByName.nominateAmbassador}>
        <Button color="secondary" variant="outlined">
          Nominate Ambassador
        </Button>
      </Link>
    </div>
  </div>
);

export default NominateAmbassador;
