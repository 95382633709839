import React from 'react';
import PropTypes from 'prop-types';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';

const RejectedOverlay = ({ product, image, reason, onEdit, onDelete }) => (
  <div className="h-100 flex-center position-relative">
    <OverlayLabel text={image ? 'Rejected' : 'Edits Required'} />
    <div className={classes.bottomControls}>
      <InfoIconPopover
        fullWidth
        iconProps={{
          className: 'mr-1',
          variant: 'inverted-grey',
        }}
      >
        <p className="font-semi-bold mb-1">Reject reason:</p>
        <p>{reason}</p>
      </InfoIconPopover>
      {!product && !image && (
        <IconButton
          className="mr-1"
          size="sm"
          variant="inverted-grey"
          data-show-edit
          onClick={onEdit}
        >
          <SpriteIcon name="edit" size="sm" />
        </IconButton>
      )}
      <IconButton size="sm" variant="inverted-grey" onClick={onDelete}>
        <SpriteIcon name="trash" size="sm" />
      </IconButton>
    </div>
  </div>
);

RejectedOverlay.propTypes = {
  product: PropTypes.bool,
  image: PropTypes.bool,
  reason: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

RejectedOverlay.defaultProps = {
  product: false,
  image: false,
  reason: 'This item was rejected. Please edit information',
};

export default RejectedOverlay;
