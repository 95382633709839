export const filterTabKeys = {
  vibes: 'vibes',
  styles: 'styles',
  subStyles: 'subStyles',
  roomTypes: 'roomTypes',
  colors: 'colors',
  itemClasses: 'itemClasses',
  videos: 'videos',
};

export const filterTabsConfig = [
  {
    value: filterTabKeys.vibes,
    title: 'Vibes',
  },
  {
    value: filterTabKeys.styles,
    title: 'Styles',
  },
  {
    value: filterTabKeys.subStyles,
    title: 'Sub Styles',
  },
  {
    value: filterTabKeys.roomTypes,
    title: 'Room Types',
  },
  {
    value: filterTabKeys.colors,
    title: 'Colors',
  },
  {
    value: filterTabKeys.itemClasses,
    title: 'Items',
  },
];

export const modalSubtitles = {
  [filterTabKeys.styles]: 'Select Styles for your Search',
  [filterTabKeys.subStyles]: 'Select Sub Styles for your Search',
  [filterTabKeys.roomTypes]: 'Select Room Type(s)',
  [filterTabKeys.colors]: 'Select Primary Color(s) (optional)',
  [filterTabKeys.itemClasses]: 'Select Item Type(s) (optional)',
};

export const styleQuizSubtitles = {
  [filterTabKeys.vibes]: 'How do you want your room to feel?',
  [filterTabKeys.styles]: 'Which Room do you like best?',
  [filterTabKeys.subStyles]: 'Indicate which room images you like',
  [filterTabKeys.roomTypes]: 'What Room(s) are you decorating?',
  [filterTabKeys.colors]: 'What colors do you want to see in your room?',
  [filterTabKeys.itemClasses]: 'Indicate which item you like',
};

export const styleQuizDescriptions = {
  [filterTabKeys.vibes]: 'Select Up to One Vibe',
  [filterTabKeys.styles]: 'Select one',
  [filterTabKeys.subStyles]:
    'Indicate which room images you like best by clicking on the heart',
  [filterTabKeys.roomTypes]: 'Select all that apply',
  [filterTabKeys.colors]: 'Select all that apply',
};
