import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import classes from 'modules/dashboard/showcase/Showcase.module.scss';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { mediaShape } from 'modules/media/propTypes';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';

const FeaturedImage = ({
  media: { userId, hash },
  column,
  onOpenPreview,
  onDelete,
  sourceName,
  title,
}) => {
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, userId, hash),
    [inspirationImageUrl, userId, hash]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  const handleOpenPreviewMobile = useCallback(() => {
    if (matchesMediaQuery) {
      onOpenPreview(imgUrl);
    }
  }, [onOpenPreview, imgUrl, matchesMediaQuery]);

  const handleDeleteImage = useCallback(() => {
    onDelete(column);
  }, [column, onDelete]);

  return (
    <div className={classes.featuredImage} onClick={handleOpenPreviewMobile}>
      <img src={imgUrl} alt="" />
      {!matchesMediaQuery && (
        <IconButton
          className={classes.previewBtn}
          color="secondary"
          size="sm"
          data-user-id={userId}
          data-hash={hash}
          onClick={handleOpenPreview}
        >
          <SpriteIcon name="loupe" size="sm" />
        </IconButton>
      )}
      {onDelete && (
        <IconButton
          className={classes.deleteBtn}
          color="secondary"
          size="sm"
          data-user-id={userId}
          data-hash={hash}
          onClick={handleDeleteImage}
        >
          <SpriteIcon name="trash" size="sm" />
        </IconButton>
      )}
      <div className={classes.imageInfo}>
        <span className={clsx(classes.imageTitle, 'text-ellipsis')}>
          {title}
        </span>
        <div className={classes.imageSubTitle}>{sourceName}</div>
      </div>
    </div>
  );
};

FeaturedImage.propTypes = {
  media: mediaShape.isRequired,
  column: PropTypes.string.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  sourceName: PropTypes.string,
  title: PropTypes.string,
};

FeaturedImage.defaultProps = {
  sourceName: '',
  title: '',
  onDelete: null,
};

export default FeaturedImage;
