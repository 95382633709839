import React from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import bannerBg from 'assets/img/home-page/banner_mobile.png';
import Button from 'components/ui/Button/Button';
import classes from './MobileHomePage.module.scss';

const MobileHomePage = () => (
  <div className={classes.root}>
    <img className="w-100" src={bannerBg} alt="" />
    <div className={classes.titleContainer}>
      <h1 className={classes.title}>Get Curated Looks</h1>
      <p className={classes.subTitle}>For your home</p>
    </div>
    <div className={classes.btnContainer}>
      <div className={classes.useDesktop}>— Use PC to Request the Look —</div>
      <div className={classes.takeStyleQuiz}>
        <Button component={Link} to={routesByName.styleQuiz}>
          Take The Style Quiz
        </Button>
      </div>
    </div>
  </div>
);

export default MobileHomePage;
