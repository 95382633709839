import React from 'react';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import { Link } from 'react-router-dom';
import singleChairImg from 'assets/img/request-the-look-page/single-chair.jpg';
import singleLookBoardImg from 'assets/img/request-the-look-page/single-lookboard.jpg';
import classes from './RequestTypeInfoModal.module.scss';

const SingleRightSide = () => (
  <>
    <div className="text-center mb-5">
      <h4 className={classes.detailsTitle}>Free request</h4>
      <p className={clsx(classes.detailsSubTitle, classes.decorLines)}>
        Request Details
      </p>
    </div>

    <p
      className="mb-5 primary-color text-center text-sm"
      style={{ lineHeight: '26px' }}
    >
      Request the look of a single home furnishing <br /> item and get similar
      options.
    </p>

    <div className="text-center">
      <div className={clsx(classes.recommendationTitle, classes.decorLines)}>
        Look Board Recommendations
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className={classes.requestTheLook}>
          <img
            src={singleChairImg}
            alt="chair"
            className={classes.shadowBottom}
          />
          <Link
            to={routesByName.requestTheLook.index}
            className="primary-color text-sm"
          >
            Request the Look
          </Link>
        </div>
        <div className={classes.getTheLook}>
          <img
            src={singleLookBoardImg}
            alt="Lookboard"
            className={classes.shadowBottom}
          />
          <Link
            to={routesByName.getTheLook.index}
            className="primary-color text-sm"
          >
            Get the Look
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default SingleRightSide;
