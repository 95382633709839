import React from 'react';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
// import SpriteIcon from 'components/ui/SpriteIcon';
import previewImg from 'assets/img/ambassador-page/AmbassadorFAQs.jpg';
import classes from './TipsBanner.module.scss';

const TipsBanner = () => (
  <div className={classes.root}>
    <Link to={routesByName.ambassador.faqs}>
      <img src={previewImg} alt="" />
    </Link>
    {/* <div className={classes.text}> */}
    {/*   <h3 className={classes.title}> */}
    {/*     10 Tips to Enhance <br /> your Visibility */}
    {/*   </h3> */}
    {/*   <Link className={classes.link} to={routesByName.howItworks}> */}
    {/*     <SpriteIcon name="play-btn-icon" /> */}
    {/*   </Link> */}
    {/* </div> */}
  </div>
);

export default TipsBanner;
