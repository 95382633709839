import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './ImageLink.module.scss';

const ImageLink = ({ url, color, label }) => {
  const handleOpenLink = (e) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  return (
    <button
      rel="noopener noreferrer"
      className={classes.imageLinkBtn}
      type="button"
      onClick={handleOpenLink}
    >
      <IconButton color={color} size="xs" className="mr-1">
        <SpriteIcon name="link" size="sm" />
      </IconButton>
      <i
        className="text-xs text-ellipsis text-nowrap"
        style={{ lineHeight: 1.2 }}
      >
        {label || url}
      </i>
    </button>
  );
};

ImageLink.propTypes = {
  url: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
};

ImageLink.defaultProps = {
  url: null,
  color: 'secondary',
  label: '',
};

export default ImageLink;
