import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toggleHowItWorksVideoModalAction } from 'modules/layout/store/actions';
import { routesByName } from 'constants/routes';
import BasicModal from '../BasicModal/BasicModal';
import classes from './HowItWorksVideoModal.module.scss';

const HowItWorksVideoModal = ({ video, posterImg }) => {
  const dispatch = useDispatch();

  const open = useSelector((state) => state.layout.howItWorksVideoModalOpen);
  const user = useSelector((state) => state.auth.user);

  const videoRef = useRef();
  const history = useHistory();

  const handleCloseModal = useCallback(() => {
    dispatch(toggleHowItWorksVideoModalAction(false));
    if (!user) {
      const redirectUrl = `?${routesByName.auth.key}=${routesByName.auth.signUp}`;
      history.push(redirectUrl);
    }
  }, [dispatch, history, user]);

  const handlePause = useCallback((e) => e.target.blur(), []);

  return (
    <BasicModal
      open={open}
      onClose={handleCloseModal}
      fullWidth
      classes={{ root: classes.root, closeBtn: classes.closeBtn }}
      scroll="body"
      style={{ zIndex: 1301 }}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        src={video}
        preload="metadata"
        onPause={handlePause}
        controls
        className={classes.video}
        poster={posterImg}
        autoPlay
      />
    </BasicModal>
  );
};

HowItWorksVideoModal.propTypes = {
  video: PropTypes.string.isRequired,
  posterImg: PropTypes.string.isRequired,
};

export default HowItWorksVideoModal;
