import React from 'react';
import { Popover } from '@material-ui/core';
import BasicModal from 'components/modals/BasicModal/BasicModal';

const UploadImagePopover = (props) => (
  <BasicModal
    component={Popover}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  >
    <p className="primary-color font-semi-bold font-main">Page URL:</p>
    <p className="text-xs mb-3">
      Copy and paste the Source URL of the page where you found the image.
    </p>

    <p className="primary-color font-semi-bold font-main">Image URL:</p>
    <p className="text-xs mb-3">
      Right-click on the image your want to add, and select &apos;Open Image in
      new Tab&apos;. Then copy and paste the Image URL.
    </p>
  </BasicModal>
);

export default UploadImagePopover;
