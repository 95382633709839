import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filter from 'lodash-es/filter';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  getInspirationImgUrl,
  getRoomTypeName,
  getStyleNames,
} from 'modules/app/store/selectors';
import { resetRequestStateAction } from 'modules/requestTheLook/store/actions';
import { routesByName } from 'constants/routes';
import { Link, useHistory } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import SuccessBanner from 'modules/requestTheLook/requestSuccess/components/SuccessBanner/SuccessBanner';
import AsideBanner from 'modules/requestTheLook/requestSuccess/components/AsideBanner/AsideBanner';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';
import { updateFilterValuesAction } from 'modules/getTheLook/store/actions';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import classes from './RequestSuccess.module.scss';
import findObjectById from '../../../utils/findObjectById';

const RequestSuccessContainer = () => {
  const dispatch = useDispatch();

  const enums = useSelector((state) => state.app.enums);
  const library = useSelector((state) => state.inspirationImage.library);
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const {
    inspirationImageId,
    title,
    requestType,
    items: priorityItems,
    message,
    requestColor,
    requestStyle,
    roomType: requestRoomType,
  } = useSelector((state) => state.requestTheLook.requestData);

  const selectedImage = useMemo(() => library[inspirationImageId], [
    library,
    inspirationImageId,
  ]);
  const styles = useMemo(
    () => getStyleNames(selectedImage?.styles ?? [], enums),
    [enums, selectedImage]
  );
  const roomType = useMemo(() => getRoomTypeName(requestRoomType, enums), [
    enums,
    requestRoomType,
  ]);
  const { colors: listColors, styles: listStyles } = enums;

  const [state, setState] = useState({});

  const history = useHistory();

  const handleViewSimilar = useCallback(() => {
    const preparedSubStyles = state.selectedImage?.subStyles
      .map((subStyleId) => findObjectById(subStyleId, enums.subStyles))
      .filter(({ styleId }) => state.selectedImage?.subStyles.includes(styleId))
      .reduce((accum, { id: currValue }) => {
        /* eslint-disable no-param-reassign */
        accum[currValue] = 1;
        return accum;
      }, {});

    const itemClasses = [];
    const roomTypes = [];

    if (state.selectedImage.itemClassId) {
      itemClasses.push(state.selectedImage.itemClassId);
    }
    if (state.selectedImage.roomTypeId) {
      roomTypes.push(state.selectedImage.roomTypeId);
    }

    const filterValues = {
      [filterTabKeys.styles]: state.selectedImage?.styles ?? [],
      [filterTabKeys.subStyles]: preparedSubStyles ?? [],
      [filterTabKeys.roomTypes]: roomTypes,
      [filterTabKeys.itemClasses]: itemClasses,
      [filterTabKeys.colors]: state.selectedImage?.colors ?? [],
    };
    dispatch(updateFilterValuesAction(filterValues));

    history.push(routesByName.getTheLook.index);
  }, [history, enums, dispatch, state]);

  const itemType = useMemo(
    () => (state.requestType === imageTypeKeys.room ? 'Room' : 'Item'),
    [state]
  );

  useEffect(() => {
    const imgUrl = buildResourceUrl(
      inspirationImageUrl.medium,
      selectedImage?.media.userId,
      selectedImage?.media.hash
    );

    setState({
      imgUrl,
      selectedImage,
      title,
      requestType,
      styles,
      priorityItems: filter(priorityItems, 'value'),
      roomType,
      message,
      requestColor,
      requestStyle,
    });
    dispatch(resetRequestStateAction());
    // eslint-disable-next-line
  }, []);

  return state.selectedImage ? (
    <section className={classes.root}>
      <div className="mb-5">
        <Link to={routesByName.requestTheLook.index}>
          <div className="d-inline-flex align-items-center mb-3">
            <IconButton variant="inverted-white" size="sm">
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="primary-color text-sm ml-1">Back</span>
          </div>
        </Link>
      </div>
      <div className="flex-center flex-column mb-3">
        <h1 className={`${classes.title} font-semi-bold`}>Congratulations!</h1>
        <p className={`${classes.description} font-italic`}>
          Your request has been submitted
        </p>
      </div>
      <div className="mb-5">
        <SuccessBanner />
      </div>
      <div className="d-flex">
        <ImageDetails
          imgUrl={state.imgUrl}
          url={state.selectedImage.url}
          title={state.selectedImage.title}
          showInfo
        />
        <DetailsPreview
          title={state.title}
          requestType={state.requestType}
          styles={state.styles}
          priorityItems={state.priorityItems}
          itemType={itemType}
          roomType={state.roomType}
          message={state.message}
          requestColor={state.requestColor}
          requestStyle={state.requestStyle}
          colors={listColors}
          requestStyles={listStyles}
          colorGroups={enums.colorGroups}
        >
          <button
            className={classes.btn}
            onClick={handleViewSimilar}
            type="button"
          >
            View Look Boards based <br /> on similar images
          </button>
        </DetailsPreview>
        <div className={classes.aside}>
          <AsideBanner />
        </div>
      </div>
    </section>
  ) : null;
};

export default RequestSuccessContainer;
