import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';

const TextInput = ({ className, underLabel, ...rest }) => (
  <div className={clsx('mb-3', className)}>
    <TextField fullWidth {...rest} />
    {underLabel && (
      <div className="text-xs mt-1 primary-color">{underLabel}</div>
    )}
  </div>
);

TextInput.propTypes = {
  className: PropTypes.string,
  underLabel: PropTypes.string,
};

TextInput.defaultProps = {
  className: '',
  underLabel: undefined,
};

export default TextInput;
