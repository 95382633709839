import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import transformArrayToMap from 'utils/transformArrayToMap';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import productService from 'modules/product/productService';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import errorToastr from 'libs/toastr/errorToastr';
import { routesByName } from 'constants/routes';
import useCancelToken from 'hooks/useCancelToken';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import LookBoardItem from 'modules/getTheLook/components/LookBoardItem';
import SelectedLookBoardBlock from 'modules/getTheLook/components/SelectedLookBoardBlock';
import {
  loadFirstAction,
  updateSelectedImageAction,
} from 'modules/getTheLook/store/actions';
import classes from '../GetTheLook.module.scss';
import LookBoardPreviewModal from '../../../components/modals/LookBoardPreviewModal/LookBoardPreviewModal';

const LookBoardPage = ({ match: { params } }) => {
  const config = useSelector((state) => state.app.config);
  const { searchParams } = useSelector((state) => state.getTheLook);
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const dispatch = useDispatch();
  const { lookBoardId } = params;
  const containerRef = useRef(null);
  const mainContainerRef = useRef(null);
  const [currentLookBoard, setCurrentLookBoard] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [imagesSimilarLookBoards, setImagesSimilarLookBoards] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lookBoardProducts, setLookBoardProducts] = useState([]);
  const { pathname } = useLocation();
  const history = useHistory();
  const [createCancelToken, isCancelled] = useCancelToken();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const [lookBoardForModal, setLookBoardForModal] = useState(null);
  const [imgModalOpen, setImgModalOpen] = useState(false);

  const inspirationImageUrl = useMemo(() => getInspirationImgUrl(config), [
    config,
  ]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(loadFirstAction(createCancelToken));
      } catch (e) {
        if (!isCancelled(e)) {
          errorToastr('Error', e.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          lookBoardId
        );
        const productsArr = await productService.getProductsByIds(
          lookBoard.products
        );
        const productsMap = transformArrayToMap(productsArr);
        const preparedProductList = lookBoard.products.map(
          (id) => productsMap[id]
        );

        if (lookBoard.inspirationId) {
          const {
            result: inspirationImage,
          } = await inspirationImageService.getImageById(
            lookBoard.inspirationId
          );
          // await dispatch(syncInspirationImageAction(lookBoard.inspirationId));
          await dispatch(
            updateIILibraryAction({ [inspirationImage.id]: inspirationImage })
          );
          await dispatch(updateSelectedImageAction(inspirationImage));

          const { subStyles } = inspirationImage;

          const preparedSubStyles = {};
          subStyles.forEach((subStyle) => {
            preparedSubStyles[subStyle] = 1;
          });
          setCurrentImage(inspirationImage);
        }

        setCurrentLookBoard(lookBoard);
        setLookBoardProducts(preparedProductList);

        setLoading(false);
        // await getSimilarProducts(productsArr, lookBoard);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();

    // eslint-disable-next-line
  }, [lookBoardId]);

  const mergedSimilarLookBoards = useMemo(() => {
    const allLookBoardsDataArr = {
      lookBoards: [],
      products: [],
      users: [],
    };
    if (imagesSimilarLookBoards) {
      imageList.map(({ id: similarImageId }) => {
        return imagesSimilarLookBoards[similarImageId]?.lookBoards.map(
          (similarLookBoard) => {
            const allProducts =
              imagesSimilarLookBoards[similarImageId].products;
            const allUsers = imagesSimilarLookBoards[similarImageId].users;
            const idProductsArr = similarLookBoard.products;
            const productsArr = allProducts.filter((obj) =>
              idProductsArr.includes(obj.id)
            );
            allLookBoardsDataArr.lookBoards.push(similarLookBoard);
            allLookBoardsDataArr.products.push(...productsArr);
            allLookBoardsDataArr.users.push(...allUsers);
          }
        );
      });
    }
    return allLookBoardsDataArr;
  }, [imageList, imagesSimilarLookBoards]);

  const handleToggleLikeLookBoard = useCallback(
    async (id, likeStatus) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      try {
        await lookBoardService.toggleLike(id, likeStatus);
      } catch (e) {
        errorToastr('Error', e.message);
      }
      if (currentLookBoard?.id === id) {
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          id
        );
        setCurrentLookBoard(lookBoard);
      } else {
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          id
        );
        const updatedObjects = imagesSimilarLookBoards[
          lookBoard.inspirationId
        ].lookBoards.map((obj) => {
          if (obj.id === id) {
            return { ...obj, isLiked: Number(!obj.isLiked) };
          }
          return obj;
        });

        setImagesSimilarLookBoards({
          ...imagesSimilarLookBoards,
          [lookBoard.inspirationId]: {
            ...imagesSimilarLookBoards[lookBoard.inspirationId],
            lookBoards: updatedObjects,
          },
        });
      }
    },
    [
      authenticated,
      currentLookBoard,
      history,
      pathname,
      imagesSimilarLookBoards,
    ]
  );

  const goBack = useCallback(() => {
    history.push(routesByName.getTheLook.index);
  }, [history]);

  const handleVoteLookBoard = useCallback(
    async (id, voteValue) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }

      try {
        await lookBoardService.voteHandler(id, voteValue);
      } catch (e) {
        errorToastr('Error', e.message);
      }
      if (currentLookBoard?.id === id) {
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          id
        );
        setCurrentLookBoard(lookBoard);
      } else {
        const { result: lookBoard } = await lookBoardService.getLookBoardById(
          id
        );
        const updatedObjects = imagesSimilarLookBoards[
          lookBoard.inspirationId
        ].lookBoards.map((obj) => {
          if (obj.id === id) {
            return { ...obj, isVoted: Number(!obj.isVoted) };
          }
          return obj;
        });

        setImagesSimilarLookBoards({
          ...imagesSimilarLookBoards,
          [lookBoard.inspirationId]: {
            ...imagesSimilarLookBoards[lookBoard.inspirationId],
            lookBoards: updatedObjects,
          },
        });
      }
    },
    [
      authenticated,
      currentLookBoard,
      history,
      pathname,
      imagesSimilarLookBoards,
    ]
  );

  const handleImgModalOpen = useCallback(() => {
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const imgPreviewUrl = useMemo(() => {
    if (!currentImage) {
      return null;
    }

    const {
      media: { userId, hash },
    } = currentImage;

    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [currentImage, inspirationImageUrl]);

  const closeLookBoardForModal = useCallback(() => {
    setLookBoardForModal(null);
  }, []);

  const onOpenLookBoardModal = useCallback(
    (id) => {
      const lookBoardsData = mergedSimilarLookBoards?.lookBoards || [];
      const lookBoard = lookBoardsData.find((obj) => obj.id === id);
      const productsModal = mergedSimilarLookBoards.products.filter((item) =>
        lookBoard.products.includes(item.id)
      );
      setLookBoardForModal({ ...lookBoard, products: productsModal });
    },
    [mergedSimilarLookBoards]
  );

  const handleSelectLookBoard = useCallback(
    (similarId) => {
      history.push(`${pathname.replace(/\d/g, '')}${similarId}`);
    },
    [history, pathname]
  );

  const handleLookBoardsImages = useCallback((lookBoardsData) => {
    setImagesSimilarLookBoards(lookBoardsData);
  }, []);

  return loading ? (
    <div className="text-center p-5">Loading...</div>
  ) : (
    <>
      <div className="pt-4">
        <div className="d-flex">
          <div
            className=""
            ref={containerRef}
            style={{
              marginTop: '-150px',
              display: matchesMediaQuery ? 'none' : 'block',
            }}
          >
            <div className={classes.leftPanelWrapper}>
              <SelectedLookBoardBlock
                inspirationImageId={Number.parseInt(
                  currentLookBoard.inspirationId,
                  10
                )}
                searchParams={searchParams}
                onBack={goBack}
                onOpenPreview={handleImgModalOpen}
                lookBoard={currentLookBoard}
                lookBoardProducts={lookBoardProducts}
                mainContainerRef={mainContainerRef}
                handleSelectLookBoard={handleSelectLookBoard}
                handleLookBoardsData={handleLookBoardsImages}
                handleSetImageList={setImageList}
              />
            </div>
          </div>
          <div className={clsx(classes.mainContainer)}>
            {matchesMediaQuery && (
              <>
                <div className={classes.mobileTitle}>
                  Look Boards in Selected Style
                </div>
                <div className={classes.mobileDescr}>
                  Scroll Down to View All
                </div>
              </>
            )}
            <div className="row mb-3 mt-2" ref={mainContainerRef}>
              {mergedSimilarLookBoards.lookBoards.map((lookBoard) => {
                if (lookBoard.id !== currentLookBoard.id) {
                  return (
                    <div
                      key={lookBoard.id}
                      className={clsx('px-1 pb-5', {
                        'col-4': !matchesMediaQuery,
                        'w-100': matchesMediaQuery,
                      })}
                    >
                      <LookBoardItem
                        lookBoard={lookBoard}
                        user={
                          transformArrayToMap(mergedSimilarLookBoards.users)[
                            lookBoard.userId
                          ]
                        }
                        products={transformArrayToMap(
                          mergedSimilarLookBoards.products
                        )}
                        onToggleLike={handleToggleLikeLookBoard}
                        onVote={handleVoteLookBoard}
                        onOpenModal={onOpenLookBoardModal}
                        hideLikeVote
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <LookBoardPreviewModal
        open={Boolean(lookBoardForModal)}
        onClose={closeLookBoardForModal}
        lookBoard={lookBoardForModal}
      />
    </>
  );
};
LookBoardPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      lookBoardId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  searchParams: PropTypes.shape({}),
  backRef: PropTypes.shape({}),
  products: PropTypes.shape({}),
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }),
};

LookBoardPage.defaultProps = {
  products: {},
  searchParams: null,
  inspirationImageUrl: null,
  backRef: null,
};

export default LookBoardPage;
