import React from 'react';
import classes from './Privacy.module.scss';

const Privacy = () => (
  <div className="container py-5">
    <h3 className={classes.title}>Pin The Look Privacy</h3>
    <p className={classes.text}>
      This is the Website of Pin the Look, and the following is the privacy
      policy regarding the use of information gathered by us, or submitted by
      visitors to this Website. Pin the Look knows you care deeply about how
      information about you is used and shared, we sincerely appreciate the
      trust you have placed in us by visiting this site.
    </p>
    <p className={classes.text}>
      This notice describes our privacy policy, and by visiting this Website you
      are accepting the practices described in this Privacy Notice.
    </p>
    <p className={classes.text}>
      <span className="fw-600">
        Personal Information About Customers Gathered by Pin the Look:
      </span>{' '}
      The personal information we gather from our customers helps us personalize
      and continually improve their shopping experience. Here are the types of
      information we may gather when you visit or make a purchase from Pin the
      Look.
    </p>
    <p className={classes.text}>
      <span className="fw-600">Information You Provide Us:</span> We receive and
      store any information you enter on our Web site or give us in any other
      way. You can choose not to provide certain information, but then you might
      not be able to take advantage of many of our features. We use the
      information that you provide for such purposes as responding to your
      requests, fulfilling orders, customizing future shopping for you,
      improving our store features, and communicating with you.
    </p>
    <p className={classes.text}>
      <span className="fw-600">Automatic Information:</span> We receive and
      store certain types of information whenever you interact with our website.
      For example, like many websites, we sometimes use &quot;cookies&quot;, and
      we obtain certain types of information when your Web browser accesses our
      website.
    </p>
    <p className={classes.text}>
      <span className="fw-600">E-mail Communications:</span> If you sign up for
      our newsletter we will send you newsletters regarding our products and
      services, notifications of changes to our policies, and order
      confirmations in order to improve our customer service and your personal
      shopping experience. If you do not want to receive e-mail from us any
      longer, other than order notifications and order receipts, please adjust
      your &quot;Newsletter Subscription&quot; preferences by logging into your
      account or simply &quot;unsubscribe&quot; to an email we send.
    </p>
    <p className={classes.subTitle}>What About Cookies?</p>
    <p className={classes.text}>
      Cookies are alphanumeric identifier text files that we transfer to your
      computer hard drive through your Web browser to enable our systems to
      recognize your browser and to provide features such as storage of items in
      your Shopping Cart between visits.
    </p>
    <p className={classes.text}>
      <span className="fw-600">
        Does Pin the Look Share the Information It Receives?:
      </span>{' '}
      We respect your privacy and appreciate your business. At no time do we
      ever provide your email address, account contact, or payment information
      to any third-party vendor, associate, or service provider unless compelled
      to do so by a court of law as part of a legal proceeding.
    </p>
    <p className={classes.text}>
      <span className="fw-600">How Secure Is Information About Me?:</span> We
      protect the security of your information during transmission by using
      Secure Sockets Layer (SSL) software, which encrypts information you
      provide to us when purchasing products, services, or submitting any
      personal information on this Website. If required to confirm your order we
      will reveal only the last four digits of your credit card numbers to do
      so. Of course, we transmit the entire credit card number to process your
      payment. However, this is done using industry-standard SSL encryption
      technology to encrypt your personal information when it is transmitted to
      the appropriate credit card company.
    </p>
    <p className={classes.text}>
      <span className="fw-600">What Information Can I Access?:</span>
      Pin the Look gives you access to a broad range of information about your
      account and your interactions with us for the limited purpose of viewing
      and, in certain cases, updating that information.
    </p>
    <p className={classes.text}>
      <span className="fw-600">Automatic Information:</span> For security
      purposes Pin the Look sometimes collects and analyzes the Internet
      protocol (IP) address used to connect your computer to the Internet, and
      to our website. This is done for your protection for tracking purposes in
      the unfortunate event that someone gains access to your personal or
      financial information and opens an account, or makes purchases under your
      name.
    </p>
    <p className={classes.text}>
      <span className="fw-600">
        Additional Questions and Contact Information:
      </span>{' '}
      If you have questions regarding any of the policies posted on this site
      you may Contact Us via email or call us at the toll-free number:
      877-223-6321
    </p>
  </div>
);

export default Privacy;
