import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';

const CustomRadioGroup = ({ value, onChange, controls, disableMb }) => (
  <RadioGroup
    row
    className={clsx(!disableMb && 'mb-1')}
    value={value}
    onChange={onChange}
  >
    {controls.map(({ value: controlValue, label }) => (
      <FormControlLabel
        key={controlValue}
        value={controlValue}
        label={label}
        control={<Radio />}
        classes={{ label: controlValue === value && 'font-semi-bold' }}
      />
    ))}
  </RadioGroup>
);

CustomRadioGroup.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disableMb: PropTypes.bool,
};

CustomRadioGroup.defaultProps = {
  onChange: null,
  disableMb: false,
};

export default CustomRadioGroup;
