import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ColorPicker from 'components/colorSelectors/ColorPicker/ColorPicker';
import { IconButton, Paper, Popper } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './SelectColorPopper.module.scss';
import Button from '../../ui/Button/Button';

const SelectColorPopper = ({
  currentColor,
  onColorChange,
  open,
  anchorEl,
  onClose,
  onSave,
}) => {
  const colors = useSelector((state) => state.app.enums.colors);

  const [boardColor, setBoardColor] = useState(currentColor);

  const handleChangeColor = useCallback(
    (color) => {
      onColorChange(color.hex);
    },
    [onColorChange]
  );

  const handleCancel = useCallback(() => {
    onColorChange(boardColor);
    onClose();
  }, [onClose, onColorChange, boardColor]);

  const handleSave = useCallback(() => {
    setBoardColor(currentColor);
    onSave();
  }, [onSave, setBoardColor, currentColor]);

  useEffect(() => {
    setBoardColor(currentColor);
    // eslint-disable-next-line
  }, []);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      placement="top-start"
      disablePortal
      style={{ zIndex: 1300 }}
    >
      <Paper className={`${classes.root} position-relative`}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeBtn}
        >
          <SpriteIcon name="cross" size="sm" />
        </IconButton>
        <h4 className="mb-3">Background Color</h4>
        <ColorPicker
          colorList={Object.values(colors)}
          selectedColor={currentColor}
          onChange={onColorChange}
        />

        <ChromePicker
          disableAlpha
          color={currentColor}
          onChange={handleChangeColor}
        />
        <div className="flex-center p-2">
          <Button
            inline
            size="sm"
            variant="outlined"
            className="mr-1"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button inline size="sm" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Paper>
    </Popper>
  );
};

SelectColorPopper.propTypes = {
  currentColor: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

SelectColorPopper.defaultProps = {
  anchorEl: null,
};

export default SelectColorPopper;
