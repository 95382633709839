import React, { useState, useCallback } from 'react';
import DetailModal from 'modules/home/components/GetTheLookYouLove/DetailModal/DetailModal';
import classes from './GetTheLookYouLove.module.scss';
import Button from '../../../../components/ui/Button/Button';
import VisualizeIdeas from '../VisualizeIdeas/VisualizeIdeas';

const GetTheLookYouLove = () => {
  const [detailModalOpen, setDetailModalOpen] = useState(false);

  const handleOpenDetailModal = useCallback(() => {
    setDetailModalOpen(true);
  }, []);

  const handleCloseDetailModal = useCallback(() => {
    setDetailModalOpen(false);
  }, []);

  return (
    <>
      <section className={classes.root}>
        <div className="d-flex align-items-center flex-column">
          <h2 className={classes.title}>Get the Look you Love</h2>
          <Button className={classes.detail} onClick={handleOpenDetailModal}>
            SITE OVERVIEW
          </Button>
        </div>
      </section>
      <DetailModal open={detailModalOpen} onClose={handleCloseDetailModal} />
      <VisualizeIdeas />
    </>
  );
};

export default GetTheLookYouLove;
