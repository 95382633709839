import React, { useMemo } from 'react';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import PropTypes from 'prop-types';
import womanImg from 'assets/img/request-the-look-page/woman.png';
import FreeLeftSide from 'modules/requestTheLook/components/RequestTypeInfoModal/FreeLeftSide';
import SingleRightSide from 'modules/requestTheLook/components/RequestTypeInfoModal/SingleRightSide';
import RoomRightSide from 'modules/requestTheLook/components/RequestTypeInfoModal/RoomRightSide';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import classes from './RequestTypeInfoModal.module.scss';

const RequestTypeInfoModal = ({ open, type, onClose }) => {
  const [roomRequest, singleRequest] = useMemo(() => {
    const room = type === imageTypeKeys.room;
    const single = type === imageTypeKeys.single;

    return [room, single];
  }, [type]);
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      classes={{ root: classes.root }}
      maxWidth="lg"
    >
      <div className="d-flex">
        <div className={classes.steps}>
          <FreeLeftSide roomRequest={roomRequest} />
          <div className={classes.stepsFooter}>
            <div className={classes.stepsFooterContent}>
              <img src={womanImg} alt="Steps" className={classes.woomanImg} />
            </div>
          </div>
        </div>
        <div className={classes.details}>
          {singleRequest && <SingleRightSide />}
          {roomRequest && <RoomRightSide />}
        </div>
      </div>
    </BasicModal>
  );
};

RequestTypeInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
};

RequestTypeInfoModal.defaultProps = {
  type: null,
};

export default RequestTypeInfoModal;
