import posterImg1 from 'assets/img/affiliate-posters/poster1.png';
import posterImg2 from 'assets/img/affiliate-posters/poster2.png';
import posterImg3 from 'assets/img/affiliate-posters/poster3.png';
import posterImg4 from 'assets/img/affiliate-posters/poster4.png';
import posterImg5 from 'assets/img/affiliate-posters/poster5.png';
import posterImg6 from 'assets/img/affiliate-posters/poster6.png';

export const ambassadorPerks = [
  'Join an exclusive community of fellow décor enthusiasts',
  'Feature & promote your knack for interior decorating on your personal Showcase Page',
  'Enhance your visibility and following on social media',
  'Earn points that can be redeemed for home furnishing gift cards',
];

export const videoInfoData = [
  {
    title: 'SELECTING A THEME',
    textItalic: 'Get Inspiration Ideas for a Showcase Theme you’ll Love!',
    textMain:
      'Are you ready to dive into the exciting realm of inspiration? Your showcase page is the first step into the ultimate stage of your creativity, and it all begins with selecting the perfect theme.',
    textList: '',
    poster: posterImg1,
    videoId: 3,
    list: [
      {
        label: 'Favorite Style:',
        text:
          'Choose a theme that resonates with your preferred design aesthetic, from modern minimalism to rustic charm',
      },
      {
        label: 'Room Type:',
        text:
          'Focus on a specific room, like a cozy living room, a tranquil bedroom, or a sophisticated dining room, to direct your creative efforts.',
      },
      {
        label: 'Nature:',
        text:
          'Whether you love the beach, the mountains, or fields of flowers, you can create designs inspired by the natural beauty in the world.',
      },
      {
        label: 'Fashion:',
        text:
          'Draw inspiration from the fashion world, whether vintage vibes, haute couture, or everyday chic.',
      },
      {
        label: 'Art:',
        text:
          'Let your showcase be inspired by your favorite art movements, artists, or specific artworks that move you.',
      },
    ],
    endText:
      "Join us as we explore a plethora of theme ideas guaranteed to set your showcase page on fire. From infusing it with the essence of an international style to crafting a catchy title that screams YOU, the sky's the limit!",
  },
  {
    title: 'PINNING INSPO IMAGES',
    textItalic: 'Effortlessly Pin your Inspo images with Pin the Look!',
    textMain:
      'Ready to take your inspiration to the next level? Get tips on finding beautiful inspo images and avoiding images that will not be approved.',
    textList: '',
    poster: posterImg2,
    videoId: 0,
    list: [
      {
        label: '',
        text: 'Where to find Inspo Images',
      },
      {
        label: '',
        text: 'How to avoid copyright infringement',
      },
      {
        label: '',
        text: 'How to Pin Images to Pin the Look',
      },
    ],
    endText:
      'Unlock the potential with Pin the Look – where inspiration meets innovation. Start pinning your design dreams today!',
  },
  {
    title: 'CURATING LOOKS',
    textItalic: 'Master the art of Curating the Look with Pin the Look!',
    textMain:
      "Are you ready to transform your inspiration into curated perfection? Let us introduce you to Curate the Look – your gateway to creating stunning visual masterpieces! Learn the best practices in finding products online and creating visually appealing Look Boards. Here's how it works:",
    textList: 'How Can I Curate the Look of an Image?',
    poster: posterImg3,
    videoId: 4,
    list: [
      {
        label: 'Select an Image:',
        text:
          "Choose an image from our vast database or one you've pinned or uploaded yourself. This image will serve as the foundation for your curated look.",
      },
      {
        label: 'Explore Thousands of Products:',
        text:
          "Dive into our extensive database of vetted products, where you'll find everything you need to bring your vision to life. Utilize our handy Filters to search by product type, style, and color, ensuring every detail aligns with your aesthetic. You will also learn best practices for pinning images online to differentiate your Look Boards.",
      },
      {
        label: 'Drag and Drop:',
        text:
          "Once you've discovered the perfect products, simply drag and drop them into the Look Board template alongside your chosen image. Watch as your vision takes shape before your eyes!",
      },
      {
        label: 'Customize and Personalize:',
        text:
          'Make your curated look truly yours by customizing the banner title and adding a description that encapsulates its essence. Your creativity knows no bounds!',
      },
      {
        label: 'Create Without Limits:',
        text:
          'Notably, you can also curate a Look Board without starting with an inspirational image, allowing you to explore endless possibilities and see how products complement each other seamlessly.',
      },
      {
        label: 'Save and Showcase: ',
        text:
          'Your meticulously curated Look Boards will be safely stored in your Dashboard, ready to be admired, shared, and revisited whenever inspiration strikes.',
      },
    ],
    endText:
      'Unleash your creativity and curate the look of your dreams with Pin the Look. Start crafting your visual stories today!',
  },
  {
    title: 'SETTING UP SHOWCASE',
    textItalic: 'Get Detailed Instructions on Setting Up Your Showcase!',
    textMain:
      'Welcome to the world of Showcase Pages!! Dive into this video to learn how to set up your very own Showcase Page  - curated portfolios brought to life by you!',
    textList: 'Key features on a Showcase Page include:',
    poster: posterImg4,
    videoId: 5,
    list: [
      {
        label: 'Featured Inspiration Images:',
        text:
          "Select your favorite images from those you've personally added to spark creativity among your fellow ambassadors and followers.",
      },
      {
        label: 'Featured Look Boards:',
        text:
          'Immerse yourself in the beautifully curated collections that you and your peers have assembled. Use these to ignite your imagination and inspire your next design project.',
      },
      {
        label: 'Ambassador’s Style Story:',
        text:
          'Share your unique style and vision with the community. Dive into the design philosophies and inspirations that drive your creativity, and learn from the diverse experiences of your fellow ambassadors.',
      },
      {
        label: 'Ambassador Video Intro (optional):',
        text:
          'Introduce yourself through a personal video. This is your opportunity to share your passion for design and your journey as a Home Stylist Ambassador with your peers.',
      },
      {
        label: 'Downloadable Showcase Images:',
        text:
          'Share your favorite showcase images easily on social media. Use this tool to spread beauty and inspiration with just a click, enhancing your presence and influence online.',
      },
      {
        label: 'Links to Ambassador’s Social Media Pages:',
        text:
          'Expand your network by connecting with fellow ambassadors on social media. Explore their design journeys and engage with their content to foster deeper connections and collaboration.',
      },
    ],
    endText:
      'Embark on a journey of creativity and inspiration as you set up your Showcase Page. Let your design dreams take flight with Pin the Look!',
  },
  {
    title: 'CURATING REQUESTS',
    textItalic: 'Help Others Get the Look by Curating Requests!',
    textMain:
      'Embark on a journey of personalized design as we unveil the magic of Curating Requests on Pin the Look! When users request the look of an image, the enchantment begins, connecting them with Ambassadors whose styles perfectly match their preferences.',
    textList: "Here's how it works:",
    poster: posterImg5,
    videoId: 1,
    list: [
      {
        label: 'Matching Styles:',
        text:
          "When users request the look of an image, Pin the Look works its magic, directing the request to Ambassadors whose styles align seamlessly. It's the beginning of a beautiful collaboration.",
      },
      {
        label: 'Curating the Request:',
        text:
          'As an Ambassador, you have the option to curate the request, igniting their creativity and setting the stage for a personalized design journey. With the goal of fulfillment within 48 hours, the excitement builds.',
      },
      {
        label: 'Easy Drag & Drop:',
        text:
          "With our intuitive drag & drop tools in the Curate the Look section, you can craft Look Boards with products akin to those found in the requested image. It's effortless creativity at your fingertips.",
      },
      {
        label: 'Web Pinning and Product Database:',
        text:
          "You can also source products directly from the web or select from our extensive product database, ensuring every detail aligns with the requester's vision. (Please note: Products cannot be uploaded; they must be active e-commerce items that can be purchased online.)",
      },
      {
        label: 'Five Look Boards per Request:',
        text:
          'You can create up to five unique Look Boards per request, each a testament to their expertise and creativity.',
      },
      {
        label: 'Approval Process:',
        text:
          'Look Boards will be reviewed, classified, and approved or sent back for changes, generally within 24 hours.',
      },
      {
        label: 'Review and Feedback:',
        text:
          'Each approved Look Board is sent to the requester and compiled on a Recommendations page, where they can rate and compare ideas, explore product details, and message Ambassadors.',
      },
    ],
    endText:
      'Experience the thrill of personalized design as you curate requests and bring visions to life on Pin the Look!',
  },
  {
    title: 'PROMOTING YOUR SHOWCASE & LOOK BOARDS ON SOCIAL MEDIA',
    textItalic: 'Learn How to Build your Brand on Social Media!',
    textMain:
      "Ready to take your design journey to the next level? Join us as we unveil the secrets to promoting your Showcase and Look Board with flair on social media! Whether you're a seasoned design enthusiast or just dipping your toes into the world of home decor, this video is your guide to shining bright in the digital realm.",
    textList: "Here's how to do it:",
    poster: posterImg6,
    videoId: 2,
    list: [
      {
        label: 'Showcase Your Style:',
        text:
          'Your Showcase and Look Board are reflections of your unique style and creativity. Let them sparkle on social media platforms by sharing captivating images and stories that showcase your passion for design.',
      },
      {
        label: 'Visual Appeal:',
        text:
          'Use design platforms like Canva to create stunning visuals that highlight the best features of your Showcase and Look Board. Experiment with different angles, lighting, and compositions to create eye-catching content that stands out in feeds.',
      },
      {
        label: 'Engaging Captions:',
        text:
          'Pair your visuals with engaging captions that invite your audience to explore further. Share insights about your design process, inspiration behind your creations, or tips for achieving similar looks in their own spaces.',
      },
      {
        label: 'Hashtags:',
        text:
          'Expand your reach by including relevant hashtags in your posts. Research popular design hashtags and incorporate them strategically to attract users interested in home decor and interior styling.',
      },
      {
        label: 'Tagging and Mentions:',
        text:
          "Don't forget to tag Pin the Look and relevant brands or collaborators in your posts. Tagging helps increase visibility and engagement, while mentions foster connections and potential partnerships within the design community.",
      },
      {
        label: 'Call to Action:',
        text:
          'Encourage your followers to engage with your content by including a clear call to action. Invite them to like, comment, share, or visit your Showcase and Look Board on Pin the Look for more inspiration.',
      },
      {
        label: 'Consistency is Key:',
        text:
          "Maintain a consistent posting schedule to keep your audience engaged and excited about your latest designs. Whether it's a weekly showcase feature or daily sneak peeks, consistency helps build brand awareness and loyalty over time.",
      },
    ],
    endText:
      "With these tips in your toolkit, you're ready to dazzle the digital world with your Showcase and Look Board on social media. Let your creativity shine and inspire others to elevate their spaces with Pin the Look!",
  },
];
