import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { messageFieldKeys } from 'components/modals/SendMessageModal/constants';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import TextAreaWrapper from 'components/finalFormWrappers/TextAreaWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import sendMessageValidator from 'components/modals/SendMessageModal/sendMessageValidator';
import classes from 'modules/account/profileAndPreferences/profile/Profile.module.scss';
import messagesService from 'modules/dashboard/messages/messagesService';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';

const SendMessageModal = ({ open, onClose, initialValues }) => {
  const handleSendMessage = useCallback(
    async (formValues) => {
      const { subject, text, userId } = formValues;
      try {
        const participants = [userId];
        const { id } = await messagesService.createConversation(
          participants,
          subject
        );
        await messagesService.sendMessage(id, text);
        successToastr('Success', 'Your message has been send');
      } catch (e) {
        errorToastr('Error', e.message);
      }
      onClose();
    },
    [onClose]
  );

  return (
    <BasicModal open={open} onClose={onClose} fullWidth maxWidth="md">
      <div className="p-3">
        <Form
          initialValues={initialValues}
          validate={sendMessageValidator}
          onSubmit={handleSendMessage}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Field
                disabled
                label="Name"
                name={messageFieldKeys.name}
                component={TextInputWrapper}
                InputProps={{
                  classes: { disabled: classes.disabledInput },
                }}
              />
              <Field
                label="Subject"
                name={messageFieldKeys.subject}
                className="mb-5"
                component={TextInputWrapper}
              />
              <Field
                name={messageFieldKeys.text}
                placeholder="Write a message"
                component={TextAreaWrapper}
              />
              <div className="text-center">
                <FinalFormSubmitButton inline>Send</FinalFormSubmitButton>
              </div>
            </form>
          )}
        />
      </div>
    </BasicModal>
  );
};

SendMessageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

SendMessageModal.defaultProps = {
  initialValues: {},
};

export default SendMessageModal;
