import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { defaultPriorityItem } from 'modules/requestTheLook/requestDetails/requestForm/constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput from 'components/inputs/TextInput/TextInput';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { RequestFormContext } from 'modules/requestTheLook/requestDetails/requestForm/RequestFormContainer';
import classes from './AutoCompleteInput.module.scss';

const autoCompleteClasses = {
  root: 'position-relative',
  inputRoot: classes.inputRoot,
  input: classes.input,
  clearIndicator: classes.clearIndicator,
  option: classes.option,
};

const getOptionSelected = () => true;

const getOptionLabel = ({ label }) => label;

const AutoCompleteInput = ({
  handleChange,
  value,
  addAnotherItemEnable,
  ...rest
}) => {
  const onChange = useCallback(
    (_, option) => {
      const values = option ?? { ...defaultPriorityItem };
      if (addAnotherItemEnable) {
        handleChange(values);
      }
    },
    [handleChange, addAnotherItemEnable]
  );

  const handleFilterModalOpen = useContext(RequestFormContext);
  return (
    <Autocomplete
      {...rest}
      value={value?.value ? value : undefined}
      onChange={onChange}
      classes={autoCompleteClasses}
      fullWidth
      popupIcon={false}
      closeIcon={
        <SpriteIcon
          className={classes.clearIcon}
          name="trash-filled"
          size="xs"
        />
      }
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => (
        <>
          <SpriteIcon
            className={`${classes.icon} primary-color`}
            name="search"
            size="sm"
          />
          <TextInput {...params} placeholder="Click to start Search Item" />
          {!value?.value && (
            <IconButton
              onClick={handleFilterModalOpen}
              className={classes.filterBtn}
              size="xs"
              variant="inverted-grey"
              type="button"
            >
              <SpriteIcon name="filters" size="xs" />
            </IconButton>
          )}
        </>
      )}
    />
  );
};

AutoCompleteInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  addAnotherItemEnable: PropTypes.bool.isRequired,
};

AutoCompleteInput.defaultProps = {
  value: null,
};

export default AutoCompleteInput;
