import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  myRequestsSortOptions,
  myRequestsTypeOptions,
} from 'modules/dashboard/myRequests/constants';
import { requestItemShape } from 'modules/dashboard/myRequests/propTypes';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import RequestItem from 'modules/dashboard/myRequests/components/RequestItem/RequestItem';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import myImagesClasses from 'modules/dashboard/myImages/MyImages.module.scss';
import { requestSourceKeys } from 'constants/requestSearchParams';

const MyRequestsComponent = ({
  loading,
  hasMore,
  loadMore,
  requestList,
  currentSource,
  currentRequestType,
  currentSortType,
  onChangeRequestType,
  onChangeSortType,
  onDelete,
  onOpenDetail,
  onEdit,
  // onMessage,
}) => {
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );

  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [sortOptions, setSortOptions] = useState(myRequestsSortOptions);

  const handleOpenPreview = useCallback(
    ({ currentTarget }) => {
      const userId = currentTarget.getAttribute('data-user-id');
      const hash = currentTarget.getAttribute('data-hash');
      const imgUrl = buildResourceUrl(inspirationImageUrl.medium, userId, hash);

      setImgPreviewUrl(imgUrl);
      setImgModalOpen(true);
    },
    [inspirationImageUrl.medium]
  );

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) {
      return;
    }
    loadMore();
  }, [loading, hasMore, loadMore]);

  const prepareSortOptions = useCallback(() => {
    const myRequestsSortOptionsCopy = [...myRequestsSortOptions];
    if (currentSource === requestSourceKeys.completed) {
      myRequestsSortOptionsCopy.pop();
    }
    setSortOptions(myRequestsSortOptionsCopy);
  }, [currentSource]);

  useEffect(() => {
    prepareSortOptions();
  }, [prepareSortOptions]);

  return (
    <>
      <div className={myImagesClasses.section}>
        <div className="d-flex justify-content-between mb-3">
          <FilterInput
            className={myImagesClasses.imgTypeSelect}
            options={myRequestsTypeOptions}
            currentValue={currentRequestType}
            onChange={onChangeRequestType}
          />
          <FilterInput
            additionalLabel="Sort by:"
            className={myImagesClasses.sortTypeSelect}
            options={sortOptions}
            currentValue={currentSortType}
            onChange={onChangeSortType}
          />
        </div>
        {requestList.length > 0 ? (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleLoadMore}
            initialLoad={false}
          >
            <div className="row">
              {requestList.map(
                ({
                  id,
                  activated,
                  approval,
                  imageId,
                  publish,
                  title,
                  priorityItems,
                  rejectedReason,
                  curatedCount,
                  userId,
                  message,
                }) => (
                  <div key={id} className="col-3">
                    <RequestItem
                      id={id}
                      userId={userId}
                      currentSource={currentSource}
                      activated={activated}
                      approval={approval}
                      imageId={imageId}
                      publish={publish}
                      title={title}
                      message={message}
                      rejectedReason={rejectedReason}
                      priorityItems={priorityItems}
                      curatedCount={curatedCount}
                      onOpenPreview={handleOpenPreview}
                      onOpenDetail={onOpenDetail}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      // onMessage={onMessage}
                    />
                  </div>
                )
              )}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex-fill flex-center font-title text-gray text-lg">
            {loading ? 'Loading...' : 'List is Empty'}
          </div>
        )}
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
    </>
  );
};

MyRequestsComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  requestList: PropTypes.arrayOf(requestItemShape).isRequired,
  currentSource: PropTypes.string.isRequired,
  currentRequestType: PropTypes.string.isRequired,
  currentSortType: PropTypes.string.isRequired,
  onChangeRequestType: PropTypes.func.isRequired,
  onChangeSortType: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpenDetail: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  // onMessage: PropTypes.func.isRequired,
};

export default MyRequestsComponent;
