import React from 'react';
import classes from './SidebarTooltip.module.scss';

// eslint-disable-next-line react/prop-types
export const SidebarTooltip = ({ children, text, ...rest }) => {
  return (
    <div className={classes.root}>
      <div className={classes.text}>{text}</div>
      <div {...rest}>{children}</div>
    </div>
  );
};
