import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { productTypeKeys } from 'modules/product/constants';
import itemOne from 'assets/img/chair-1.png';
import itemTwo from 'assets/img/lamp-1.png';
import itemThree from 'assets/img/chair-2.png';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';

export const imageTypeOptions = [
  {
    value: imageSourceKeys.my,
    label: 'My Added Images',
  },
  {
    value: imageSourceKeys.liked,
    label: 'My Liked Images',
  },
  {
    value: imageSourceKeys.requests,
    label: 'Requests',
  },
];

export const productTypeOptions = [
  {
    value: productTypeKeys.all,
    label: 'All Products',
  },
  {
    value: productTypeKeys.affiliate,
    label: 'Affiliate Products',
  },
  {
    value: productTypeKeys.my,
    label: 'My Added Products',
  },
  {
    value: productTypeKeys.liked,
    label: 'My Liked Products',
  },
];

export const productTypeOptionsRegular = [
  {
    value: productTypeKeys.all,
    label: 'All Products',
  },
  {
    value: productTypeKeys.my,
    label: 'My Added Products',
  },
  {
    value: productTypeKeys.liked,
    label: 'My Liked Products',
  },
];

export const stepTwoTabKeys = {
  templates: 'templates',
  products: 'products',
};

export const stepTwoColumnViewKeys = {
  single: 'single',
  double: 'double',
};

export const createLookBoardStepKeys = {
  selectImage: 'selectImage',
  addProducts: 'addProducts',
};

export const lookBoardStatusKeys = {
  public: 'public',
  private: 'private',
};

export const userTypes = {
  ambassador: 'ambassador',
  regular: 'regular',
  followedAmbassadors: 'followed-ambassadors',
};

export const lookBoardTemplatesConfig = {
  1: {
    id: 1,
    columnCount: 1,
    title: 'One Column Template',
    subTitle: '1 Column (1-4 Items)',
    imageList: [
      {
        id: 1,
        src: itemOne,
      },
    ],
  },
  2: {
    id: 2,
    columnCount: 2,
    title: 'Two Column Template',
    subTitle: '2 Column (2-8 Items)',
    isDefault: true,
    imageList: [
      {
        id: 1,
        src: itemOne,
      },
      {
        id: 2,
        src: itemTwo,
      },
    ],
  },
  3: {
    id: 3,
    columnCount: 3,
    title: 'Three Column Template',
    subTitle: '3 Column (3-12 Items)',
    imageList: [
      {
        id: 1,
        src: itemOne,
      },
      {
        id: 2,
        src: itemTwo,
      },
      {
        id: 3,
        src: itemThree,
      },
    ],
  },
};

export const imageSearchRadioOptions = [
  { value: imageTypeKeys.room, label: 'Room Images' },
  { value: imageTypeKeys.single, label: 'Single Items' },
];

export const ambassadorImageSearchRadioOptions = [
  { value: userTypes.ambassador, label: 'Ambassadors' },
  { value: userTypes.followedAmbassadors, label: 'Followed Ambassadors' },
  // { value: userTypes.regular, label: 'All Profiles' },
];

export const ambassadorSearchNonLoginOptions = [
  { value: userTypes.ambassador, label: 'Ambassadors' },
];

export const columnViewRadioOptions = [
  { value: stepTwoColumnViewKeys.double, label: '2 Column View' },
  { value: stepTwoColumnViewKeys.single, label: '1 Column View' },
];

export const infoMenuConfig = {
  title: 'Curate the Look',
  description: 'Curate the Look Section Guide',
  items: [
    {
      id: 1,
      title: 'Inspiration Images Database',
      description:
        'Browse through popular Inspiration Images to use in curating a new Look Board. Use the Search feature to pull up images by keywords or use the Filter feature to narrow your search by room type, style, and color. You can also click on the Pin icon to add a new Inspiration Image from your computer or the Web.',
      icon: 'living-room',
      buttonLabel: 'All Images',
    },
    {
      id: 2,
      title: 'My Added Images',
      description:
        'In this section, you can select an Inspiration Image to use in curating a Look Board from the ones you have personally added or “liked”. Simply click on the My Added Images tab to select one that is saved in your dashboard. You can also click on the Add New Image tab to add a new image from your computer or the Web.',
      icon: 'added-image',
      buttonLabel: 'My Added Images',
    },
  ],
};

export const lookBoardDetailsFormFields = {
  title: 'title',
  type: 'type',
  roomTypeId: 'roomTypeId',
  itemClassId: 'itemClassId',
  styleId: 'styleId',
  description: 'description',
  message: 'message',
};

export const lookBoardStatusRadioOptions = [
  { value: lookBoardStatusKeys.public, label: 'Public' },
  { value: lookBoardStatusKeys.private, label: 'Keep Private' },
];

export const errorMessages = {
  required: 'This field is required',
};

export const stepsBannerCongif = [
  {
    icon: 'save',
    title: 'Look Board',
    action: 'Saved to File',
  },
  {
    icon: 'search-list',
    title: 'Look Board',
    action: 'Reviewed',
  },
  {
    icon: 'approval',
    title: 'Look Board',
    action: 'Approved',
  },
  {
    icon: 'email',
    title: 'Look Board',
    action: 'Sent',
  },
  {
    icon: 'publish',
    title: 'Approved Look',
    action: 'Board Published',
  },
];

export const mobileViewStepsConfig = [
  { number: 1, description: 'Select Image Type' },
  { number: 2, description: 'Curate the Look with Products' },
  { number: 3, description: 'Customize Look Board' },
];

export const titleInfoConfig = {
  title: 'Look Board Title',
  description:
    "Your Title will be used to find your Look Board on Pin the Look and in search engines, so make sure that it includes keywords that best describe your Look Board. Your title can be up to 60 characters. Try to put the most important works first and make sure it's a natural title, and not one that uses the same keywords multiple times. Examples: - Modern Living Room Furniture with Green Sofa - Coastal White Kitchen Ideas with Gold Accents",
  examplesTitle: 'Examples:',
  examplesDescription:
    'Get the Look of a modern living room with a green velvet mid century sofa, white acrylic coffee table and gold metal spoke chandelier.',
};

export const productFilterModalDescriptions = {
  [filterTabKeys.styles]: 'Select all that apply',
  [filterTabKeys.colors]: 'Select All Similar Colors for Best Results',
  [filterTabKeys.itemClasses]: 'Select One Item Type',
};

export const productFilterModalTabKeys = {
  [filterTabKeys.itemClasses]: filterTabKeys.itemClasses,
  [filterTabKeys.styles]: filterTabKeys.styles,
  [filterTabKeys.subStyles]: filterTabKeys.subStyles,
  [filterTabKeys.roomTypes]: filterTabKeys.roomTypes,
  [filterTabKeys.colors]: filterTabKeys.colors,
};

export const productFilterModalTabConfig = [
  {
    value: filterTabKeys.itemClasses,
    title: 'Items',
  },
  {
    value: filterTabKeys.styles,
    title: 'Styles',
  },
  {
    value: filterTabKeys.subStyles,
    title: 'Sub Styles',
  },
  {
    value: filterTabKeys.roomTypes,
    title: 'Room Types',
  },
  {
    value: filterTabKeys.colors,
    title: 'Colors',
  },
];

export const subTypeImageOptions = [
  {
    value: imageSourceKeys.all,
    label: 'All Images',
  },
  {
    value: imageSourceKeys.liked,
    label: 'Liked Images',
  },
];
export const subTypeImageOptionsAmbassador = [
  {
    value: imageSourceKeys.all,
    label: 'All Images',
  },
  {
    value: imageSourceKeys.liked,
    label: 'Liked Images',
  },
  {
    value: imageSourceKeys.requests,
    label: 'My requests',
  },
];
