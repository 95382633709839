import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { shareViaEmailFormFields } from 'modules/dashboard/showcase/constants';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import TextAreaWrapper from 'components/finalFormWrappers/TextAreaWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import shareViaEmailValidator from 'modules/dashboard/showcase/components/ShareViaEmailModal/shareViaEmailValidator';
import classes from 'modules/dashboard/showcase/components/ShareModal/ShareModal.module.scss';

const ShareViaEmailModal = ({ open, onClose, onSubmit }) => {
  const user = useSelector((state) => state.auth.user);

  const [initialFormValues] = useState({
    [shareViaEmailFormFields.firstName]: user.firstName,
    [shareViaEmailFormFields.lastName]: user.lastName,
    [shareViaEmailFormFields.subject]:
      "Please Look my Page. I'm sure you will like it",
  });
  return (
    <BasicModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      classes={{ root: classes.root }}
    >
      <h2 className={`${classes.title} mb-3`}>
        Share Your Page’s link via email
      </h2>
      <Form
        initialValues={initialFormValues}
        validate={shareViaEmailValidator}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-5">
                <Field
                  required
                  label="First Name"
                  name={shareViaEmailFormFields.firstName}
                  component={TextInputWrapper}
                />
              </div>
              <div className="col-2" />
              <div className="col-5">
                <Field
                  required
                  label="Last Name"
                  name={shareViaEmailFormFields.lastName}
                  component={TextInputWrapper}
                />
              </div>
            </div>
            <Field
              required
              label="To First Name"
              name={shareViaEmailFormFields.toFirstName}
              component={TextInputWrapper}
            />
            <Field
              required
              label="To email Address"
              type="email"
              name={shareViaEmailFormFields.toEmailAddress}
              component={TextInputWrapper}
            />
            <Field
              required
              label="Subject"
              name={shareViaEmailFormFields.subject}
              component={TextInputWrapper}
            />
            <Field
              label="Private Message (Optional)"
              name={shareViaEmailFormFields.message}
              component={TextAreaWrapper}
            />
            <div className="text-center pt-2">
              <FinalFormSubmitButton inline>Send</FinalFormSubmitButton>
            </div>
          </form>
        )}
      />
    </BasicModal>
  );
};

ShareViaEmailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ShareViaEmailModal;
