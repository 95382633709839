import React from 'react';
import backgroundImage from 'assets/img/home-page/visualize-bg.png';
import { visualizeSectionConfig } from 'modules/home/constants';
import Tabs from 'modules/home/components/VisualizeIdeas/Tabs/Tabs';
import MixMatchTab from 'modules/home/components/VisualizeIdeas/MixMatchTab';
import LookBoardViewTab from 'modules/home/components/VisualizeIdeas/LookBoardViewTab';
import classes from './VisualizeIdeas.module.scss';

const VisualizeIdeas = () => {
  const styles = {
    backgroundImage: `url(${backgroundImage})`,
  };

  const { lookBoardTabData, mixMatchData } = visualizeSectionConfig;

  // TODO Change Tabs implementation
  return (
    <section className={classes.root}>
      <div className={classes.image} style={styles} />
      <div className="flex-center">
        <h2 className={classes.title}>
          Visualize Ideas <br /> Your Way
        </h2>
      </div>
      <div className={classes.tabsWrapper}>
        <Tabs selected={0}>
          <LookBoardViewTab
            title="Curated Looks"
            icon="dashboard"
            items={lookBoardTabData}
          />
          <MixMatchTab
            title="Mix & Match Looks"
            icon="shuffle-icon"
            items={mixMatchData}
          />
        </Tabs>
      </div>
    </section>
  );
};

export default VisualizeIdeas;
