import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import errorToastr from 'libs/toastr/errorToastr';
import { imageSourceKeys } from 'constants/inspirationImageSearchParams';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import {
  approvalStatusKeys,
  ownershipStatusKeys,
  permissionStatusKeys,
  publishStatusKeys,
} from 'modules/inspirationImage/constants';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import buildResourceUrl from 'utils/buildResourceUrl';
import PermissionsSwitch from 'modules/dashboard/components/PermissionsSwitch/PermissionsSwitch';
import PendingOverlay from 'modules/dashboard/myImages/components/AddedImageItem/PendingOverlay';
import RejectedOverlay from 'modules/dashboard/myImages/components/AddedImageItem/RejectedOverlay';

const AddedImageItem = ({
  id,
  title,
  url,
  type,
  description,
  roomTypeId,
  itemClassId,
  userId,
  hash,
  currentSource,
  approval,
  publish,
  ownership,
  rejectedReason,
  permission,
  editUnderReview,
  showPermissionSwitch,
  onUnlike,
  onOpenPreview,
  onDeleteAddedImage,
  onOpenDetail,
}) => {
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const [currentPermission, setCurrentPermission] = useState(permission);

  const switchIsVisible = useMemo(
    () =>
      showPermissionSwitch &&
      approval === approvalStatusKeys.approved &&
      publish === publishStatusKeys.published &&
      ownership === ownershipStatusKeys.selfOwned,
    [showPermissionSwitch, approval, publish, ownership]
  );

  const editMode = useMemo(
    () =>
      approval !== approvalStatusKeys.pending &&
      currentSource === imageSourceKeys.my,
    [approval, currentSource]
  );

  const handleChangePermission = useCallback(
    async (isPublic) => {
      const newValue = isPublic
        ? permissionStatusKeys.open
        : permissionStatusKeys.exclusive;
      try {
        await inspirationImageService.changeImagePermission(id, newValue);
        setCurrentPermission(newValue);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [id]
  );

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, userId, hash),
    [inspirationImageUrl, userId, hash]
  );

  const handleOpenDetail = useCallback(
    ({ currentTarget }) => {
      const { showEdit } = currentTarget.dataset;
      onOpenDetail({
        id,
        title,
        url,
        type,
        description,
        roomTypeId,
        itemClassId,
        imgUrl,
        editMode,
        showEdit: Boolean(showEdit),
      });
    },
    [
      onOpenDetail,
      id,
      title,
      url,
      type,
      description,
      roomTypeId,
      itemClassId,
      imgUrl,
      editMode,
    ]
  );

  const handleDeleteImage = useCallback(() => {
    onDeleteAddedImage(id);
  }, [onDeleteAddedImage, id]);

  return (
    <InspirationImage
      id={id}
      className="mb-3"
      imgUrl={imgUrl}
      showStaticOverlay={
        approval !== approvalStatusKeys.approved ||
        (editUnderReview && currentSource === imageSourceKeys.my)
      }
      disableHover={
        approval !== approvalStatusKeys.approved ||
        (editUnderReview && currentSource === imageSourceKeys.my)
      }
      staticOverlay={
        approval === approvalStatusKeys.pending ||
        (editUnderReview && currentSource === imageSourceKeys.my) ? (
          <PendingOverlay editUnderReview={editUnderReview} />
        ) : (
          <RejectedOverlay
            image
            onEdit={handleOpenDetail}
            onDelete={handleDeleteImage}
            reason={rejectedReason}
          />
        )
      }
      hoverOverlay={
        <div className="h-100 flex-column flex-vertical-center justify-content-between">
          <div />
          <div className="w-100 d-flex justify-content-center align-self-center">
            <IconButton
              color="secondary"
              size="sm"
              data-user-id={userId}
              data-hash={hash}
              onClick={onOpenPreview}
            >
              <SpriteIcon name="loupe" size="sm" />
            </IconButton>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center">
            <div className="flex-fill">
              {switchIsVisible && (
                <PermissionsSwitch
                  checked={currentPermission === permissionStatusKeys.open}
                  onChange={handleChangePermission}
                />
              )}
            </div>
            {currentSource === imageSourceKeys.my ? (
              <IconButton
                variant="inverted-grey"
                size="sm"
                data-img-id={id}
                onClick={handleDeleteImage}
              >
                <SpriteIcon name="trash" size="sm" />
              </IconButton>
            ) : (
              <IconButton
                variant="inverted-grey"
                size="sm"
                data-img-id={id}
                onClick={onUnlike}
              >
                <SpriteIcon name="like-filled" size="sm" />
              </IconButton>
            )}
            {editMode && (
              <IconButton
                onClick={handleOpenDetail}
                data-show-edit
                className="ml-1"
                variant="inverted-white"
                size="sm"
              >
                <SpriteIcon name="edit" size="sm" />
              </IconButton>
            )}
            <IconButton
              onClick={handleOpenDetail}
              className="ml-1"
              variant="inverted-white"
              size="sm"
            >
              <SpriteIcon name="eye" />
            </IconButton>
          </div>
        </div>
      }
    />
  );
};
AddedImageItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  roomTypeId: PropTypes.number,
  itemClassId: PropTypes.number,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  currentSource: PropTypes.string.isRequired,
  approval: PropTypes.string.isRequired,
  publish: PropTypes.string.isRequired,
  ownership: PropTypes.string.isRequired,
  rejectedReason: PropTypes.string,
  permission: PropTypes.string.isRequired,
  showPermissionSwitch: PropTypes.bool.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onUnlike: PropTypes.func.isRequired,
  onDeleteAddedImage: PropTypes.func.isRequired,
  onOpenDetail: PropTypes.func.isRequired,
  editUnderReview: PropTypes.bool,
};
AddedImageItem.defaultProps = {
  url: null,
  description: null,
  roomTypeId: null,
  itemClassId: null,
  rejectedReason: null,
  editUnderReview: false,
};

export default AddedImageItem;
