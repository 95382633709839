import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsersLibraryAction } from 'modules/users/store/actions';
import messagesService from 'modules/dashboard/messages/messagesService';
import {
  setConversationsAction,
  updateConversationsAction,
} from 'modules/conversation/store/actions';
import currentUserService from 'modules/currentUser/currentUserService';
import errorToastr from 'libs/toastr/errorToastr';
import ConversationItem from 'components/conversationThumbnail/ConversationItem';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import useCancelToken from 'hooks/useCancelToken';

const ConversationList = ({
  scrollHeight,
  handleSelectConversation,
  updateScrollHeight,
  searchParams,
  updateSearchParams,
}) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user);
  const usersLibrary = useSelector((state) => state.users.library);
  const {
    conversationsList: conList,
    conversations,
    currentConversationId,
    unreadConversationsById,
  } = useSelector((state) => state.conversation);
  const conversationsList = conList.map((id) => conversations[id]);

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [createCancelToken, isCancelled] = useCancelToken();
  const [createCancelTokenForUsers] = useCancelToken();

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();

      const list = await messagesService.getConversationList(searchParams, {
        cancelToken,
      });

      if (!searchParams.interlocutor) {
        const usersIdList = list
          .map(({ participants }) => {
            return participants.find(({ userId }) => userId !== currentUser.id)
              .userId;
          })
          .filter((searchUserId) => !usersLibrary[searchUserId]);

        if (usersIdList.length > 0) {
          const cancelTokenForUsers = createCancelTokenForUsers();
          const userList = await currentUserService.getUsersById(
            [...new Set(usersIdList)],
            { cancelToken: cancelTokenForUsers }
          );
          dispatch(updateUsersLibraryAction(userList));
        }
      }

      if (list.length > 0) {
        updateSearchParams({
          offset: searchParams.offset + list.length,
        });
      }
      dispatch(updateConversationsAction(list));

      setHasMore(list.length === searchParams.limit);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.message);
      }
    }

    setLoading(false);
  }, [
    searchParams,
    currentUser,
    dispatch,
    setLoading,
    updateSearchParams,
    usersLibrary,
    isCancelled,
    createCancelToken,
    createCancelTokenForUsers,
  ]);

  const loadFirst = useCallback(async () => {
    setLoading(true);
    try {
      const cancelToken = createCancelToken();

      const list = await messagesService.getConversationList(searchParams, {
        cancelToken,
      });

      if (!searchParams.interlocutor) {
        const usersIdList = list
          .map(({ participants }) => {
            return participants.find(({ userId }) => userId !== currentUser.id)
              .userId;
          })
          .filter((searchUserId) => !usersLibrary[searchUserId]);

        if (usersIdList.length > 0) {
          const cancelTokenForUsers = createCancelTokenForUsers();

          const userList = await currentUserService.getUsersById(
            [...new Set(usersIdList)],
            { cancelToken: cancelTokenForUsers }
          );
          dispatch(updateUsersLibraryAction(userList));
        }
      }

      if (list.length > 0) {
        updateSearchParams({ offset: list.length });
      }
      dispatch(setConversationsAction(list));
      updateScrollHeight();

      setHasMore(list.length === searchParams.limit);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.message);
      }
    }

    setLoading(false);
  }, [
    currentUser,
    dispatch,
    setHasMore,
    updateSearchParams,
    setLoading,
    searchParams,
    updateScrollHeight,
    usersLibrary,
    isCancelled,
    createCancelToken,
    createCancelTokenForUsers,
  ]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  return (
    <CustomInfiniteScroll
      parentHeight={scrollHeight}
      isLoading={loading}
      hasMore={hasMore}
      loadMore={loadMore}
      useWindow={false}
    >
      {conversationsList.length > 0 &&
        conversationsList.map(
          ({ id, name, interlocutor: { userId }, updatedAt }) => {
            return (
              <div key={id}>
                <ConversationItem
                  id={id}
                  user={usersLibrary[userId]}
                  title={name}
                  selected={id === currentConversationId}
                  handleSelect={handleSelectConversation}
                  unread={Boolean(unreadConversationsById[id])}
                  lastMessageDate={updatedAt}
                />
              </div>
            );
          }
        )}
      {!conversationsList.length && !loading ? (
        <div className="text-center mt-2">
          You have not any conversations yet
        </div>
      ) : null}
      {loading && <div className="text-center mt-2">Loading...</div>}
    </CustomInfiniteScroll>
  );
};

ConversationList.propTypes = {
  scrollHeight: PropTypes.number.isRequired,
  usersLibrary: PropTypes.shape({}).isRequired,
  handleSelectConversation: PropTypes.func.isRequired,
  updateScrollHeight: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
    interlocutor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  updateSearchParams: PropTypes.func.isRequired,
  unreadConversationsById: PropTypes.shape({}).isRequired,
};

export default ConversationList;
