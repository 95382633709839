export const messageFieldKeys = {
  name: 'name',
  subject: 'subject',
  text: 'text',
  userId: 'userId',
};

export default {
  messageFieldKeys,
};
