export const transformUserToProfileFormValues = ({
  type,
  firstName,
  lastName,
  email,
  description,
  countryId,
  languageId,
  zipCode,
  business,
  displayName,
  // avatar,
}) => {
  let businessName;
  let businessType;
  let businessUrl;

  if (business) {
    businessName = business.name;
    businessType = business.type;
    businessUrl = business.url;
  }
  return {
    type,
    firstName,
    lastName,
    email,
    description,
    countryId,
    languageId,
    zipCode,
    businessName,
    businessType,
    businessUrl,
    displayName,
  };
};
