import React from 'react';
import PropTypes from 'prop-types';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';

const PendingOverlay = ({ product, editUnderReview, onDelete }) => (
  <div className="h-100 flex-center position-relative">
    <OverlayLabel
      text={
        editUnderReview ? (
          <span>
            Edits are <br /> under review
          </span>
        ) : (
          <span>
            Under <br /> review
          </span>
        )
      }
    />
    <div className={classes.bottomControls}>
      <InfoIconPopover
        iconProps={{
          className: 'mr-1',
          variant: 'inverted-grey',
        }}
      >
        <p className="text-center font-normal">
          This {product ? 'product' : 'image'} is currently <br /> under review.
          We will notify you <br /> of the{' '}
          <span className="primary-color">approval status</span>, usually <br />{' '}
          <span className="primary-color">within 48 hours</span>
        </p>
      </InfoIconPopover>
      {product && (
        <IconButton size="sm" variant="inverted-grey" onClick={onDelete}>
          <SpriteIcon name="trash" size="sm" />
        </IconButton>
      )}
    </div>
  </div>
);

PendingOverlay.propTypes = {
  product: PropTypes.bool,
  editUnderReview: PropTypes.bool,
  onDelete: PropTypes.func,
};

PendingOverlay.defaultProps = {
  product: false,
  editUnderReview: false,
  onDelete: () => {},
};

export default PendingOverlay;
