import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Faqs from 'modules/ambassadors/faqs/Faqs';
import GetStartedContainer from 'modules/ambassadors/getStarted/GetStartedContainer';
import BecomeAmbassadorContainer from 'modules/ambassadors/becomeAmbassador/BecomeAmbassadorContainer';
import PrivateRoute from 'components/PrivateRoute';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import AmbassadorMobilePage from 'modules/ambassadors/AmbassadorMobilePage';

const AmbassadorsContainer = () => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  return matchesMediaQuery ? (
    <AmbassadorMobilePage />
  ) : (
    <Switch>
      <Route exact path={routesByName.ambassador.index}>
        <GetStartedContainer />
      </Route>
      <PrivateRoute exact path={routesByName.ambassador.becomeAmbassador}>
        <BecomeAmbassadorContainer />
      </PrivateRoute>
      <Route path={routesByName.ambassador.faqs}>
        <Faqs />
      </Route>
    </Switch>
  );
};

export default AmbassadorsContainer;
