import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import {
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import { routesByName } from 'constants/routes';
import { filterTabKeys } from 'components/modals/FiltersModal/constants';
import findObjectById from 'utils/findObjectById';
import {
  createLookBoardStepKeys,
  subTypeImageOptions,
  subTypeImageOptionsAmbassador,
} from 'modules/curateTheLook/constants';
import featureNameKeys from 'constants/featureNames';
import FiltersModal from 'components/modals/FiltersModal/FiltersModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import {
  resetCanvasAction,
  resetImageFilterValuesAction,
  unselectImageAction,
  updateImageFilterValuesAction,
} from 'modules/curateTheLook/store/actions';
import { createImageInstantlyForAction } from 'modules/inspirationImage/store/actions';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import imagesSearchParamsBlockClasses from 'modules/curateTheLook/createLookBoard/components/ImagesSearchParamsBlock/ImagesSearchParamsBlock.module.scss';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import classes from './ImagesSearchParamsBlock.module.scss';

const MAX_FILTER_COUNT = 2;
const ImagesSearchParamsBlock = ({
  currentSource,
  selectedImage,
  isFirstStepSkipped,
  onChangeStep,
  onUpdateSearchParams,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const {
    inspirationImageSearchParams: searchParams,
    imageFilterValues: filterValues,
  } = useSelector((state) => state.curateTheLook);
  const { styles, colorGroups, itemClasses } = useSelector(
    (state) => state.app.enums
  );
  const history = useHistory();
  const { pathname } = useLocation();
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [clearAllModalOpen, setClearAllModalOpen] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [subTypeImage, setSubTypeImage] = useState(imageSourceKeys.all);
  const [activeTypeTab, setActiveTypeTab] = useState(imageSourceKeys.all);
  const [maxFiltersCount, setMaxFiltersCount] = useState(MAX_FILTER_COUNT);

  const subTitle = useMemo(
    () =>
      currentSource === imageSourceKeys.all
        ? 'Inspiration Images Database'
        : 'My Files',
    [currentSource]
  );

  const showControls = useMemo(() => !isFirstStepSkipped && !selectedImage, [
    isFirstStepSkipped,
    selectedImage,
  ]);

  const handleAddImageModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(createImageInstantlyForAction(featureNameKeys.curate));
  }, [currentUser, dispatch, pathname, history]);

  const handleChangeStep = useCallback(
    ({ currentTarget }) => {
      const step = currentTarget.getAttribute('data-step');
      onChangeStep(step);

      dispatch(resetCanvasAction(4));
    },
    [onChangeStep, dispatch]
  );

  const handleGoStep = useCallback(
    ({ currentTarget }) => {
      const step = currentTarget.getAttribute('data-step');
      onChangeStep(step);
      dispatch(unselectImageAction());
    },
    [onChangeStep, dispatch]
  );

  const handleOpenFiltersModal = useCallback(() => {
    setFiltersModalOpen(true);
  }, []);

  const handleCloseFiltersModal = useCallback(() => {
    setFiltersModalOpen(false);
  }, []);

  const handleApplyFilters = useCallback(
    (filters) => {
      dispatch(updateImageFilterValuesAction(filters));
    },
    [dispatch]
  );

  useEffect(() => {
    setActiveTypeTab(
      searchParams.source === imageSourceKeys.my
        ? imageSourceKeys.my
        : imageSourceKeys.all
    );
  }, [searchParams]);

  useEffect(() => {
    if (history.location.search.includes('update=true')) {
      history.push(history.location.pathname);
      const parsedData = JSON.parse(localStorage.getItem('parsedData'));
      const type = parsedData ? parsedData.type : null;

      onUpdateSearchParams({ type });
      const updatedFilter = {};
      if (type === imageTypeKeys.single) {
        updatedFilter[filterTabKeys.roomTypes] = [];
      }
      dispatch(updateImageFilterValuesAction(updatedFilter));
    }
    // eslint-disable-next-line
  }, [history.location.search]);
  const handleOpenClearAllModal = useCallback(() => {
    setClearAllModalOpen(true);
  }, []);

  const handleClearAllFilters = useCallback(
    (confirm) => {
      if (confirm) {
        onUpdateSearchParams({ search: '' });
        dispatch(resetImageFilterValuesAction());
      }
      setClearAllModalOpen(false);
    },
    [dispatch, onUpdateSearchParams]
  );

  const handleOpenResetModal = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const handleResetFilters = useCallback(
    (confirm) => {
      if (confirm) {
        onUpdateSearchParams({ search: '' });
        dispatch(resetImageFilterValuesAction());
      }
      setResetModalOpen(false);
    },
    [onUpdateSearchParams, dispatch]
  );
  const handleChangeSubType = useCallback(
    (subType) => {
      setActiveTypeTab(imageSourceKeys.all);
      setSubTypeImage(subType);
      onUpdateSearchParams({ search: '', source: subType });
    },
    [onUpdateSearchParams]
  );

  const isTabActive = useCallback(
    (tab) => {
      return activeTypeTab === tab;
    },
    [activeTypeTab]
  );

  const handleChangeActiveTab = async (tab) => {
    setActiveTypeTab(tab);
    onUpdateSearchParams({ search: '', source: tab });
  };

  const combinedFiltersArr = useMemo(() => {
    setMaxFiltersCount(MAX_FILTER_COUNT);
    const { styles: filterStyles, colors } = filterValues;
    const filterArr = [];
    filterStyles.forEach((value) => {
      filterArr.push({ value, source: 'styles' });
    });
    colors.forEach((value) => {
      if (colorGroups[value]) filterArr.push({ value, source: 'colors' });
    });
    return filterArr;
  }, [colorGroups, filterValues]);

  const handleToggleFiltersCount = () => {
    setMaxFiltersCount((prevState) =>
      prevState === MAX_FILTER_COUNT
        ? combinedFiltersArr.length
        : MAX_FILTER_COUNT
    );
  };

  const getFilterOption = (option) => {
    const { value, source } = option;
    switch (source) {
      case filterTabKeys.styles: {
        return findObjectById(value, styles)?.name;
      }
      case filterTabKeys.colors: {
        return colorGroups[value]?.name;
      }
      case filterTabKeys.itemClasses: {
        return findObjectById(value, itemClasses)?.name;
      }
      default:
        return null;
    }
  };

  return (
    <>
      <div className="position-relative d-flex mb-2">
        <div className={classes.filtersBlock}>
          <h3 className="text-lg font-semi-bold mb-1">Curate The Look</h3>
          <p className="text-xs font-italic">{subTitle}</p>
        </div>
      </div>
      <div className={`${createLookBoardClasses.stepTitle} mb-3`}>
        {selectedImage && (
          <SpriteIcon name="checked" size="sm" className="mr-1 primary-color" />
        )}
        <span className="flex-fill mr-1">Step 1: Select inspiration image</span>
        {isFirstStepSkipped && (
          <Button
            size="sm"
            inline
            style={{ minWidth: 100 }}
            data-step={createLookBoardStepKeys.selectImage}
            onClick={handleGoStep}
          >
            Go
          </Button>
        )}
        {showControls && currentSource === imageSourceKeys.all && (
          <Button
            className={imagesSearchParamsBlockClasses.pinBtn}
            size="xs"
            inline
            style={{ minWidth: 130 }}
            variant="outlined"
            onClick={handleAddImageModalOpen}
          >
            <div className="d-flex align-items-center">
              or Add New Image
              <div className={imagesSearchParamsBlockClasses.pinBtnIcon}>
                <SpriteIcon name="pin" size="xs" />
              </div>
            </div>
          </Button>
        )}
      </div>
      {showControls && (
        <>
          <div className="d-flex justify-content-end mb-2">
            <Button
              size="sm"
              inline
              style={{ minWidth: 100 }}
              data-step={createLookBoardStepKeys.addProducts}
              onClick={handleChangeStep}
            >
              Skip step
            </Button>
          </div>
          <Button
            className={imagesSearchParamsBlockClasses.pinImageBtn}
            size="xs"
            style={{ minWidth: 130 }}
            variant="outlined"
            onClick={handleAddImageModalOpen}
          >
            <div className="d-flex align-items-center justify-content-center">
              Pin a new Image
              <div className={imagesSearchParamsBlockClasses.pinBtnIcon}>
                <SpriteIcon name="pin" size="md" />
              </div>
            </div>
          </Button>
          <div className={classes.myAndLikedImages}>
            <FilterInput
              className={clsx(classes.sortTypeSelect, {
                [classes.active]: isTabActive(imageSourceKeys.all),
              })}
              options={
                currentUser?.ambassador
                  ? subTypeImageOptionsAmbassador
                  : subTypeImageOptions
              }
              currentValue={subTypeImage}
              onChange={handleChangeSubType}
            />
            <div
              className={clsx(
                'd-flex flex-column align-items-center',
                classes.tabBtnWrapper
              )}
            >
              <button
                type="button"
                className={clsx(classes.tabBtn, {
                  [classes.active]: isTabActive(imageSourceKeys.my),
                })}
                data-tab={imageSourceKeys.my}
                onClick={() => handleChangeActiveTab(imageSourceKeys.my)}
              >
                My Added Images
              </button>
            </div>
          </div>
          <div className="text-xs font-italic py-1 mb-1">
            Use filters for Best Results
          </div>
          <div className={classes.resetArea}>
            <Button
              inline
              variant="outlined"
              onClick={handleOpenFiltersModal}
              className="ml-1"
              size="sm"
            >
              <SpriteIcon name="filters" size="sm" className="mr-1" />
              <span>Filters</span>
            </Button>
            <Button
              inline
              variant="default"
              size="xs"
              className="d-flex align-items-center"
              onClick={handleOpenResetModal}
            >
              <SpriteIcon name="processing" size="sm" className="mr-1" />
              <span>Reset</span>
            </Button>
            <div className={classes.decorLine} />
            <Button
              variant="default"
              size="custom"
              onClick={handleOpenClearAllModal}
            >
              <div className={classes.spriteBg}>
                <SpriteIcon name="trash" size="sm" className="mr-1" />
              </div>
              <span>Clear All</span>
            </Button>
          </div>
          <div className="d-flex flex-wrap pb-1">
            {combinedFiltersArr.slice(0, maxFiltersCount).map((filterItem) => (
              <div className={classes.filterOption} key={filterItem.value}>
                {getFilterOption(filterItem)}
              </div>
            ))}
            {combinedFiltersArr.length > MAX_FILTER_COUNT && (
              <div
                className={classes.filterOption}
                onClick={handleToggleFiltersCount}
              >
                {maxFiltersCount === combinedFiltersArr.length ? '-' : '+'}
              </div>
            )}
          </div>
          {/*
            <CustomRadioGroup
              controls={imageSearchRadioOptions}
              value={currentImgType}
              onChange={handleChangeImgType}
            />
          */}
        </>
      )}
      <FiltersModal
        showQuickFilter
        disabledFilters={[
          filterTabKeys.vibes,
          filterTabKeys.itemClasses,
          filterTabKeys.videos,
        ]}
        open={filtersModalOpen}
        onClose={handleCloseFiltersModal}
        imageType={searchParams.type}
        filterValues={filterValues}
        onReset={(e) => dispatch(resetImageFilterValuesAction(e))}
        onApply={handleApplyFilters}
      />
      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetFilters}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">reset</span> filters to default
            {currentUser?.preferences && (
              <span>
                <br />
                preferences
              </span>
            )}
            ?
          </>
        }
      />
      <ConfirmModal
        onClose={handleClearAllFilters}
        open={clearAllModalOpen}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">clear</span> all filters?
          </>
        }
      />
    </>
  );
};

ImagesSearchParamsBlock.propTypes = {
  currentSource: PropTypes.string.isRequired,
  selectedImage: PropTypes.number,
  isFirstStepSkipped: PropTypes.bool.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  onUpdateSearchParams: PropTypes.func.isRequired,
};

ImagesSearchParamsBlock.defaultProps = {
  selectedImage: null,
};

export default ImagesSearchParamsBlock;
