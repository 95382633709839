import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useDropdownData = (currentFilters) => {
  const enums = useSelector((state) => state.app.enums);

  const stylesToShow = useMemo(() => {
    return Object.values(enums.styles).map((style) => ({
      ...style,
      type: 'styles',
      extended: currentFilters.styles.includes(style.id),
      checked: currentFilters.styles.includes(style.id),
      children: Object.values(enums.subStyles)
        .filter((subStyle) => subStyle.styleId === style.id)
        .map((subStyle) => ({
          ...subStyle,
          type: 'subStyles',
          checked: currentFilters.subStyles.includes(subStyle.id),
        })),
    }));
  }, [
    currentFilters.styles,
    currentFilters.subStyles,
    enums.styles,
    enums.subStyles,
  ]);

  const roomsToShow = useMemo(() => {
    const shownRooms = [1, 2, 4];
    return Object.values(enums.roomTypes)
      .filter((room) => shownRooms.includes(room.id))
      .map((room) => ({
        ...room,
        type: 'roomTypes',
        checked: currentFilters.roomTypes.includes(room.id),
      }));
  }, [currentFilters.roomTypes, enums.roomTypes]);

  const colorsToShow = useMemo(() => {
    return Object.values(enums.colorGroups).map((color) => ({
      ...color,
      type: 'colors',
      checked: currentFilters.colors.includes(color.id),
    }));
  }, [enums.colorGroups, currentFilters.colors]);

  return useMemo(() => {
    return [
      {
        id: 1,
        name: 'Styles',
        extended: false,
        children: stylesToShow,
      },
      {
        id: 2,
        name: 'Rooms',
        extended: false,
        children: roomsToShow,
      },
      {
        id: 3,
        name: 'Colors',
        extended: false,
        children: colorsToShow,
      },
    ];
  }, [stylesToShow, roomsToShow, colorsToShow]);
};
