import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import GTLSlider from 'modules/getTheLook/components/GTLSlider';
import useCallbackRef from '../../hooks/useCallbackRef';
// eslint-disable-next-line
const CustomSlider = ({ children, sliderLength }) => {
  const [sliderNode, sliderRef] = useCallbackRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderNode.slickPrev();
  }, [sliderNode]);

  const handleSlideNext = useCallback(() => {
    sliderNode.slickNext();
  }, [sliderNode]);

  return (
    <GTLSlider
      sliderRef={sliderRef}
      slideLength={sliderLength}
      activeSlideIndex={activeSlideIndex}
      onSlidePrev={handleSlidePrev}
      onSlideNext={handleSlideNext}
      onSlideChange={handleSlideChange}
      // onViewAll={handleViewAllLookBoards}
    >
      {children}
    </GTLSlider>
  );
};

CustomSlider.propTypes = {
  sliderLength: PropTypes.number,
};

CustomSlider.defaultProps = {
  sliderLength: 0,
};
export default CustomSlider;
