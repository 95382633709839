import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'components/MixMatchThumbnail/MixMatchThumbnail.module.scss';

const SliderControls = ({
  items,
  activeSlideIndex,
  onSlidePrev,
  onSlideNext,
  // onDotClick,
  hideArrows,
}) => (
  <div
    className={clsx(
      'd-flex align-items-center',
      hideArrows ? 'justify-content-center py-2' : 'justify-content-between'
    )}
  >
    {!hideArrows && (
      <button
        type="button"
        className={clsx(
          classes.slideControl,
          activeSlideIndex === 0 && classes.inActive
        )}
        onClick={onSlidePrev}
      >
        <SpriteIcon name="arrow-down" size="sm" className={classes.prevSlide} />
      </button>
    )}
    {/* <div className={clsx('d-flex', classes.dots)}> */}
    {/*  {items.map(({ id }, index) => ( */}
    {/*    <div */}
    {/*      key={id} */}
    {/*      className={clsx(classes.dot, { */}
    {/*        [classes.active]: index === activeSlideIndex, */}
    {/*      })} */}
    {/*      data-index={index} */}
    {/*      onClick={onDotClick} */}
    {/*    /> */}
    {/*  ))} */}
    {/* </div> */}
    {!hideArrows && (
      <button
        type="button"
        className={clsx(
          classes.slideControl,
          activeSlideIndex === items.length - 1 && classes.inActive
        )}
        onClick={onSlideNext}
      >
        <SpriteIcon name="arrow-down" size="sm" className={classes.nextSlide} />
      </button>
    )}
  </div>
);

SliderControls.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  activeSlideIndex: PropTypes.number.isRequired,
  onSlidePrev: PropTypes.func.isRequired,
  onSlideNext: PropTypes.func.isRequired,
  // onDotClick: PropTypes.func.isRequired,
  hideArrows: PropTypes.bool,
};

SliderControls.defaultProps = {
  hideArrows: false,
};

export default SliderControls;
