import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import PinImage from 'assets/img/pin-image-img.svg';
import PinProduct from 'assets/img/pin-product-img.svg';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import mediaTypes from 'constants/mediaTypes';
import classes from './ChooseMethodModal.module.scss';

const ChooseMethodModal = ({
  open,
  onClose,
  onImageUpload,
  onMediaPin,
  type,
}) => {
  let shownBlocks = [type];
  if (type === 'both') {
    shownBlocks = ['product', 'room'];
  }

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={onClose}>
      {shownBlocks.includes('room') && (
        <div
          className={`${classes.pinCard} ${classes.pinCard__wider} mr-4`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img src={PinImage} alt="" className={classes.icon} />
          <p className={`${classes.title} mb-3`}>
            <span>Add Room Image</span>
            <br />
            <b>as Inspiration Image</b>
            <br />
          </p>
          <div className="d-flex justify-content-around w-100">
            <div className={classes.variantBlock}>
              <IconButton
                size="lg"
                color="secondary"
                className="mb-2"
                data-media-type={mediaTypes.inspirationImage}
                onClick={onMediaPin}
              >
                <SpriteIcon name="pin-filled" size="md" />
              </IconButton>
              <b>AUTO-CAPTURE</b>
              <p>
                Add Chrome Extension to
                <br /> capture Image & Data
              </p>
              <span>Fastest</span>
            </div>
            <div className={classes.variantBlock}>
              <IconButton
                size="lg"
                color="secondary"
                className="mb-2"
                data-media-type={mediaTypes.inspirationImage}
                onClick={onImageUpload}
              >
                <SpriteIcon name="image-upload" size="md" />
              </IconButton>
              <b>MANUAL-ENTRY</b>
              <p>
                Upload or Pin Image URL <br /> & Manually Add Data
              </p>
            </div>
          </div>
        </div>
      )}
      {shownBlocks.includes('product') && (
        <div
          className={`${classes.pinCard} ${classes.pinCard__wider}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img src={PinProduct} alt="" className={classes.icon} />
          <p className={`${classes.title} mb-3`}>
            <span>Add Product Image</span>
            <br />
            <b>as Inspiration or Curation Image</b>
          </p>
          <div className="d-flex justify-content-around w-100">
            <div className={classes.variantBlock}>
              <IconButton
                size="lg"
                className="mb-2"
                color="secondary"
                data-media-type={mediaTypes.product}
                onClick={onMediaPin}
              >
                <SpriteIcon name="pin-filled" size="md" />
              </IconButton>
              <b>AUTO-CAPTURE</b>
              <p>
                Add Chrome Extension to
                <br /> capture Image & Data
              </p>
              <span>Fastest</span>
            </div>
            <div className={classes.variantBlock}>
              <IconButton
                size="lg"
                color="secondary"
                className="mb-2"
                data-media-type={mediaTypes.product}
                onClick={onImageUpload}
              >
                <SpriteIcon name="image-upload" size="md" />
              </IconButton>
              <b>MANUAL-ENTRY</b>
              <p>
                Upload or Pin Image URL <br /> & Manually Add Data
              </p>
            </div>
          </div>
        </div>
      )}
    </Backdrop>
  );
};

ChooseMethodModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onMediaPin: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ChooseMethodModal;
