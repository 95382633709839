import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import GalleryItem from 'modules/getTheLook/components/GalleryItem';
import { inspirationImageShape } from '../../inspirationImage/propTypes';

const InspirationsModal = ({ open, onClose, images, currentImage }) => {
  const library = useSelector((state) => state.inspirationImage.library);

  return (
    <BasicModal
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="lg"
      classes={{ root: 'w-100', closeBtn: '' }}
    >
      <div>
        <h3 className="text-xl text-center font-semi-bold text-uppercase">
          Inspirations
        </h3>
        <div className="d-flex flex-wrap">
          {currentImage && (
            <div className="col-4 mb-2 d-flex align-items-center">
              <GalleryItem image={currentImage} />
            </div>
          )}
          {images
            .filter((imageId) => imageId !== currentImage.id)
            .map(
              (image) =>
                library[image] && (
                  <div
                    key={image}
                    className="col-4 mb-2 d-flex align-items-center"
                  >
                    <GalleryItem image={library[image]} />
                  </div>
                )
            )}
        </div>
      </div>
    </BasicModal>
  );
};

InspirationsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentImage: inspirationImageShape.isRequired,
};
export default InspirationsModal;
