import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr/lib/ReduxToastr';
import { routesByName } from 'constants/routes';
import PrivateRoute from 'components/PrivateRoute';
import Page404 from 'modules/pages/Page404';
import UnderConstruction from 'modules/pages/UnderConstruction';
import Header from 'modules/layout/Header/Header';
import Footer from 'modules/layout/Footer/Footer';
import PinMediaArea from 'modules/pinMedia/PinMediaContainer';
import { initAppAction } from 'modules/app/store/actions';
import EmailVerificationContainer from 'modules/auth/emailVerification/EmailVerificationContainer';
import AccountContainer from 'modules/account/AccountContainer';
import DashboardContainer from 'modules/dashboard/DashboardContainer';
import CurateTheLookContainer from 'modules/curateTheLook/CurateTheLookContainer';
import UnderConstructionModal from 'components/modals/UnderConstructionModal';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import HomeContainer from 'modules/home/HomeContainer';
import AmbassadorsContainer from 'modules/ambassadors/AmbassadorsContainer';
import ContactContainer from 'modules/contact/ContactContainer';
import RequestTheLookContainer from 'modules/requestTheLook/RequestTheLookContainer';
import GetTheLookContainer from 'modules/getTheLook/GetTheLookContainer';
import LookBoardDetails from 'modules/lookBoard/LookBoardDetails';
import ProductDetails from 'modules/product/ProductDetails';
import ClaimImageModal from 'modules/layout/ClaimImageModal/ClaimImageModal';
import UncompletedUserRedirect from 'modules/app/UncompletedUserRedirect';
import TermsModal from 'components/modals/TermsModal/TermsModal';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import UnavailableToolMobilePage from 'components/UnavailableToolMobilePage/UnavailableToolMobilePage';
import ShowcaseDetails from 'modules/showcase/ShowcaseDetails';
import ReportContainer from 'modules/report/ReportContainer';
import AboutContainer from 'modules/about/AboutContainer';
import HowItWorksModal from 'components/modals/HowItWorksModal/HowItWorksModal';
import NominateAmbassadorContainer from 'modules/ambassadors/nominateAmbassador/NominateAmbassadorContainer';
import AmbassadorRoute from 'components/AmbassadorRoute';
import TradeOpportunitiesContainer from 'modules/ambassadors/TradeOpportunities/TradeOpportunitiesContainer';
import VerificationRemindModal from 'modules/auth/emailVerification/VerificationRemindModal';
import ShowcasePreview from 'modules/showcase/ShowcasePreview';
import Terms from 'modules/pages/Terms';
import Privacy from 'modules/pages/privacy/Privacy';
import ConversationController from './modules/app/ConversationController';
import GetThePin from './modules/getThePin/GetThePin';
import StyleQuizPage from './modules/styleQuiz/StyleQuizPage';
import { useStylePreferencesEffect } from './hooks/useStylePreferencesEffect';
import PreferencesRemindModal from './modules/getTheLook/preferencesRemindModal';
import InspirationImageDetails from './modules/inspirationImage/InspirationImageDetails';
import Community from './modules/pages/community/Community';
import ComingSoon from './modules/pages/comingSoon';
import ComingSoonPassword from './modules/pages/comingSoon/ComingSoonPassword';
import AmbassadorFaqs from './modules/dashboard/ambassadorManagement/AmbassadorFaqs/AmbassadorFaqs';

// TODO: Create welcome screen
const App = () => {
  const dispatch = useDispatch();

  const inited = useSelector((state) => state.app.inited);
  const underConstructionModalOpen = useSelector(
    (state) => state.layout.underConstructionModalOpen
  );

  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const user = useSelector((state) => state.auth.user);
  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      await dispatch(initAppAction());
    })();
    // eslint-disable-next-line
  }, []);

  useStylePreferencesEffect();

  useEffect(() => {
    if (user) {
      if (pathname !== routesByName.home) {
        localStorage.setItem('isRedirect', 'redirected');
      } else {
        localStorage.removeItem('isRedirect');
      }
    } else {
      localStorage.setItem('isRedirect', 'redirected');
    }
    // eslint-disable-next-line
  }, [user]);

  const captcha = document.querySelector('.grecaptcha-badge');
  if (captcha) {
    captcha.style.zIndex = '2';
  }

  const handleUnderConstructionModalClose = useCallback(() => {
    dispatch(toggleUnderConstructionModalAction(false));
  }, [dispatch]);

  const showHeader = useMemo(() => {
    const routes = [
      routesByName.comingSoon,
      routesByName.contact,
      routesByName.comingSoonPassword,
    ];
    return !routes.includes(pathname);
  }, [pathname]);

  return inited ? (
    <>
      <UncompletedUserRedirect />
      <ConversationController />
      {showHeader && <Header />}
      <div className="flex-fill d-flex flex-column">
        <Switch>
          <Route exact path={routesByName.comingSoon} component={ComingSoon} />
          {!localStorage.getItem('isAccess') && showHeader && (
            <Redirect to={routesByName.comingSoon} />
          )}
          <Route
            exact
            path={routesByName.comingSoonPassword}
            component={ComingSoonPassword}
          />
          <Route exact path={routesByName.home}>
            <HomeContainer />
          </Route>
          <Route exact path={routesByName.auth.emailVerification}>
            <EmailVerificationContainer />
          </Route>
          <PrivateRoute path={routesByName.account.index}>
            <AccountContainer />
          </PrivateRoute>
          <PrivateRoute path={routesByName.dashboard.index}>
            <DashboardContainer />
          </PrivateRoute>
          <Route path={routesByName.getTheLook.index}>
            <GetTheLookContainer />
          </Route>
          <Route path={routesByName.curateTheLook.index}>
            <CurateTheLookContainer />
          </Route>
          <Route path={routesByName.requestTheLook.index}>
            <RequestTheLookContainer />
          </Route>
          <Route exact path={routesByName.lookBoard.details()}>
            <LookBoardDetails />
          </Route>
          <Route exact path={routesByName.product.details()}>
            <ProductDetails />
          </Route>
          <Route exact path={routesByName.inspirationImage.details()}>
            <InspirationImageDetails />
          </Route>
          <Route exact path={routesByName.contact}>
            <ContactContainer />
          </Route>
          <Route path={routesByName.ambassador.index}>
            <AmbassadorsContainer />
          </Route>
          <Route exact path={routesByName.showcase.preview}>
            <ShowcasePreview />
          </Route>
          <Route exact path={routesByName.showcase.details()}>
            <ShowcaseDetails userId={user?.id} />
          </Route>
          <Route path={routesByName.report.index}>
            <ReportContainer />
          </Route>
          <Route exact path={routesByName.about}>
            <AboutContainer />
          </Route>
          <Route path={routesByName.faqs}>
            <AmbassadorFaqs />
          </Route>
          <AmbassadorRoute exact path={routesByName.nominateAmbassador}>
            <NominateAmbassadorContainer />
          </AmbassadorRoute>
          <Route exact path={routesByName.tradeOpportunities}>
            <TradeOpportunitiesContainer />
          </Route>
          <Route exact path={routesByName.unavailableTool}>
            <UnavailableToolMobilePage />
          </Route>
          <Route exact path={routesByName.blog} component={UnderConstruction} />
          <Route exact path={routesByName.getThePin} component={GetThePin} />
          <Route exact path={routesByName.terms}>
            <Terms />
          </Route>
          <Route exact path={routesByName.privacy}>
            <Privacy />
          </Route>
          <Route exact path={routesByName.community}>
            <Community />
          </Route>
          <Route exact path={routesByName.styleQuiz}>
            <StyleQuizPage />
          </Route>
          <Route
            exact
            path={routesByName.howItworks}
            component={UnderConstruction}
          />
          <Route
            exact
            path={routesByName.getStartedNow}
            component={UnderConstruction}
          />
          <Route exact path="*" component={Page404} />
        </Switch>
      </div>
      {!matchesMediaQuery && showHeader && <PinMediaArea />}
      {showHeader && <Footer />}
      <ReduxToastr position="bottom-left" preventDuplicates />
      <UnderConstructionModal
        open={underConstructionModalOpen}
        onClose={handleUnderConstructionModalClose}
      />
      <ClaimImageModal />
      <TermsModal />
      <HowItWorksModal />
      <VerificationRemindModal />
      <PreferencesRemindModal />
    </>
  ) : (
    <div>Loading...</div>
  );
};

export default App;
