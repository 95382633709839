import {
  createProductFieldKeys,
  validationMessages,
} from 'modules/pinMedia/constants';

const descriptionMaxLength = 1000;

const createProductValidator = ({
  [createProductFieldKeys.name]: name,
  [createProductFieldKeys.description]: description,
  [createProductFieldKeys.price]: price,
}) => {
  const errors = {};

  const priceRegExp = new RegExp('^\\d+?\\d*(\\.\\d{2})?$');

  if (price) {
    const isPriceMatch = priceRegExp.test(price);

    if (isPriceMatch === false) {
      errors[createProductFieldKeys.price] = [
        validationMessages.incorrectValue,
      ];
    }
  }

  if (!name) {
    errors[createProductFieldKeys.name] = [validationMessages.required];
  }

  if (price > 2147483648) {
    errors[createProductFieldKeys.price] = [validationMessages.tooHighValue];
  }

  if (description?.length > descriptionMaxLength) {
    errors[createProductFieldKeys.description] = [
      validationMessages.maxLength(descriptionMaxLength),
    ];
  }
  return errors;
};

export default createProductValidator;
