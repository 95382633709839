import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';

const ScrollableDatabase = ({
  scrollBarRef,
  loading,
  hasMore,
  loadMore,
  children,
  initialHeight,
  setInitialHeight,
}) => {
  const imgContainerRef = useRef(null);

  useEffect(() => {
    if (initialHeight === 0) {
      setInitialHeight(Math.round(document.documentElement.clientHeight + 50));
    }
  }, [initialHeight, setInitialHeight]);

  return (
    <div className="flex-fill" ref={imgContainerRef}>
      <CustomScrollBar
        scrollBarRef={scrollBarRef}
        autoHeightMin={initialHeight}
        autoHeightMax={initialHeight}
        renderView={(props) => <div {...props} className="pr-1" />}
      >
        <CustomInfiniteScroll
          parentHeight={initialHeight}
          isLoading={loading}
          hasMore={hasMore}
          loadMore={loadMore}
          initialLoad={false}
          useWindow={false}
        >
          {children}
        </CustomInfiniteScroll>
      </CustomScrollBar>
    </div>
  );
};

ScrollableDatabase.propTypes = {
  scrollBarRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  initialHeight: PropTypes.number.isRequired,
  setInitialHeight: PropTypes.func.isRequired,
};

export default ScrollableDatabase;
