import React from 'react';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import { Link } from 'react-router-dom';
import roomLookBoardImg from 'assets/img/request-the-look-page/room-lookboard.jpg';
import roomRequestImg from 'assets/img/request-the-look-page/room-request.jpg';
import classes from './RequestTypeInfoModal.module.scss';

const RoomRightSide = () => (
  <>
    <div className="text-center mb-5">
      <h4 className={classes.detailsTitle}>Free request</h4>
      <p className={clsx(classes.detailsSubTitle, classes.decorLines)}>
        Request Details
      </p>
    </div>
    <div className="text-left">
      <div className="text-uppercase text-lg font-title mb-1">
        Modern Blue Room
      </div>
      <div className="font-semi-bold text-sm mb-2">Priority Items</div>
      <ul className={clsx(classes.itemList, 'list-dotted mb-4')}>
        <li>Accent Chair</li>
        <li>Sofa</li>
        <li>Coffee Table</li>
        <li>Counter Stools</li>
      </ul>
    </div>
    <div className="text-center">
      <div className={clsx(classes.recommendationTitle, classes.decorLines)}>
        Look Board Recommendations
      </div>
      <div className="d-flex justify-content-between mt-3">
        <div className={classes.requestTheLook}>
          <img src={roomRequestImg} alt="" className="mb-2" />
          <Link
            to={routesByName.requestTheLook.index}
            className="primary-color text-sm"
          >
            Request the Look
          </Link>
        </div>
        <div className={classes.getTheLook}>
          <img
            src={roomLookBoardImg}
            alt="Room"
            className={classes.shadowBottom}
          />
          <Link
            to={routesByName.getTheLook.index}
            className="primary-color text-sm"
          >
            Get the Look
          </Link>
        </div>
      </div>
    </div>
  </>
);

export default RoomRightSide;
