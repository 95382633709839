import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';
import successToastr from 'libs/toastr/successToastr';
import classes from './ShareModal.module.scss';

const ShareModal = ({ open, onClose, shareUrl }) => {
  const inputRef = useRef(null);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(shareUrl);
    successToastr('Success', 'Link successfully copied');
  }, [shareUrl]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      classes={{ root: classes.root }}
    >
      <h2 className={`${classes.title} mb-4`}>Share Your Page</h2>
      <div className={`${classes.linkInput} mb-2`}>
        <input ref={inputRef} type="text" readOnly value={shareUrl} />
        <Button inline size="sm" onClick={copyToClipboard}>
          Copy Link
        </Button>
      </div>
    </BasicModal>
  );
};

ShareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shareUrl: PropTypes.string.isRequired,
};

export default ShareModal;
