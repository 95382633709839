import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import {
  filterTabKeys,
  filterTabsConfig,
  modalSubtitles,
} from 'components/modals/FiltersModal/constants';
import {
  getItemClassesByStructure,
  getSubStylesBySelectedStylesForFilters,
} from 'modules/app/store/selectors';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import ColorSelect from 'components/colorSelectors/ColorSelect';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import ConfirmModal from 'components/modals/ConfirmModal';
import StylesFilter from 'components/modals/FiltersModal/components/StylesFilter';
import RoomTypesFilter from 'components/modals/FiltersModal/components/RoomTypesFilter';
import SubStylesGrid from 'components/modals/FiltersModal/components/SubStylesGrid';
import ProfessionFilter from 'components/modals/FiltersModal/components/ProfessionFilter';
import {
  ambassadorFilterTabKeys,
  ambassadorModalSubtitles,
  professionOptions,
} from 'modules/getTheLook/ambassadorPage/config';
import transformArrayToMap from 'utils/transformArrayToMap';
import findObjectById from 'utils/findObjectById';
import useMediaQuery from 'hooks/useMediaQuery';
import warningToastr from 'libs/toastr/warningToastr';
import { maxWidthMd } from 'constants/mediaQueries';
import MobileFilter from './components/MobileFilter/MobileFilter';
import FilterDropdown from './components/FilterDropdown/FilterDropdown';
import ItemClassesFilter from './components/ItemClassesFilter';
import classes from './FiltersModal.module.scss';

export const transformSubStylesFilterValues = (filterValues) => ({
  ...filterValues,
  [filterTabKeys.colors]: Object.values(filterValues.colors),
  [filterTabKeys.subStyles]: Object.keys(
    filterValues[filterTabKeys.subStyles]
  ).map((subStyleId) => Number.parseInt(subStyleId, 10)),
});

const sortItems = (items) =>
  Object.values(items).sort((a, b) => a.name.localeCompare(b.name));

const MAX_ITEM_CLASSES = 1;
const FiltersModal = ({
  imageType,
  filterValues,
  disabledFilters,
  open,
  onClose,
  onReset,
  onClearAll,
  onApply,
  descriptions,
  customTabsConfig,
  customTabKeys,
  isAmbassadorsFilter,
  disableRoomTypeFilter,
  showQuickFilter,
  handleUpdateSearchParams,
  maxSelections,
}) => {
  const currentUser = useSelector((state) => state.auth.user);
  const enums = useSelector((state) => state.app.enums);
  const itemClassesConfig = useMemo(
    () =>
      getItemClassesByStructure({
        categories: sortItems(enums.categories),
        itemTypes: sortItems(enums.itemTypes),
        itemClasses: sortItems(enums.itemClasses),
      }),
    [enums]
  );

  const tabKeys = useMemo(() => {
    const copyTabKeys = { ...filterTabKeys };
    delete copyTabKeys.vibes;
    return customTabKeys ?? copyTabKeys;
  }, [customTabKeys]);
  const tabsConfig = useMemo(() => customTabsConfig ?? filterTabsConfig, [
    customTabsConfig,
  ]);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const firstTab = useMemo(() => Object.values(tabKeys)[0], [tabKeys]);
  const [activeTab, setActiveTab] = useState(firstTab);
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [clearAllModalOpen, setClearAllModalOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(
    transformSubStylesFilterValues(filterValues)
  );

  const selectedOptions = useMemo(() => {
    const transformedProfessionOptions = transformArrayToMap(professionOptions);
    if (activeTab === filterTabKeys.colors) {
      return currentFilters[activeTab]
        ?.map((id) => enums.colorGroups[id])
        .filter((colorItem) => colorItem !== undefined)
        .filter(
          (col, idx, arr) => arr.findIndex((c) => c?.id === col?.id) === idx
        );
    }
    if (activeTab === ambassadorFilterTabKeys.styles) {
      return currentFilters[activeTab]?.map((id) =>
        findObjectById(id, enums[activeTab])
      );
    }
    if (
      !isAmbassadorsFilter &&
      activeTab !== ambassadorFilterTabKeys.profession
    ) {
      return currentFilters[activeTab]?.map((id) =>
        findObjectById(id, enums[activeTab])
      );
    }
    return currentFilters[activeTab]?.map((id) =>
      activeTab === ambassadorFilterTabKeys.profession
        ? transformedProfessionOptions[id]
        : findObjectById(id, enums[activeTab])
    );
  }, [isAmbassadorsFilter, currentFilters, activeTab, enums]);

  const subStyleOptions = useMemo(
    () =>
      getSubStylesBySelectedStylesForFilters(
        enums[tabKeys.styles],
        enums[tabKeys.subStyles],
        currentFilters[tabKeys.styles]
      ),
    [enums, currentFilters, tabKeys]
  );

  // TODO: return all other room types

  const mainRoomTypes = useMemo(
    () =>
      Object.values(enums[tabKeys.roomTypes])
        .slice(0, 4)
        .filter((e) => e.name !== 'Kitchen'),
    [enums, tabKeys.roomTypes]
  );

  useEffect(() => {
    if (isAmbassadorsFilter) {
      setActiveTab(tabKeys.profession);
    } else {
      setActiveTab(firstTab);
    }
  }, [firstTab, isAmbassadorsFilter, tabKeys]);

  useEffect(() => {
    setCurrentFilters(transformSubStylesFilterValues(filterValues));
  }, [filterValues]);

  useEffect(() => {
    if (open) {
      setActiveTab(firstTab);
    }
  }, [open, isAmbassadorsFilter, tabKeys, firstTab]);

  const handleChangeActiveTab = useCallback(({ currentTarget }) => {
    const { tab } = currentTarget.dataset;
    setActiveTab(tab);
  }, []);

  const handleUnselectOption = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;
      const updatedFilterValue = currentFilters[activeTab].filter(
        (value) => value !== Number(id)
      );
      setCurrentFilters((prevState) => ({
        ...prevState,
        [activeTab]: updatedFilterValue,
      }));
    },
    [currentFilters, activeTab]
  );

  const handleChangeFilterValue = useCallback(
    (id, checked) => {
      let currentValuesCopy = [...currentFilters[activeTab]];

      if (checked) {
        currentValuesCopy.push(Number(id));
      } else {
        currentValuesCopy = currentValuesCopy.filter(
          (value) => value !== Number(id)
        );
      }

      if (maxSelections && currentValuesCopy.length > maxSelections) {
        warningToastr('Warning', `Please select up to ${maxSelections}`);
      } else if (
        activeTab === filterTabKeys.itemClasses &&
        currentValuesCopy.length > MAX_ITEM_CLASSES
      ) {
        setCurrentFilters((prevState) => ({
          ...prevState,
          [activeTab]: [Number(id)], // for items
        }));
      } else {
        setCurrentFilters((prevState) => ({
          ...prevState,
          [activeTab]: currentValuesCopy,
        }));
      }
    },
    [activeTab, currentFilters, maxSelections]
  );

  const handleChangeProfessionFilter = useCallback(
    (title, checked) => {
      handleChangeFilterValue(title, checked);
    },
    [handleChangeFilterValue]
  );

  const handleChangeItemClassFilter = useCallback(
    ({ target: { value, checked } }) => {
      handleChangeFilterValue(value, checked);
    },
    [handleChangeFilterValue]
  );

  const handleChangeStyleFilter = useCallback(
    (id, checked) => {
      handleChangeFilterValue(id, checked);
    },
    [handleChangeFilterValue]
  );

  const handleChangeColorFilter = useCallback(
    ({ target: { id, checked } }) => {
      handleChangeFilterValue(id, checked);
    },
    [handleChangeFilterValue]
  );

  const handleOpenResetModal = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const handleOpenClearAllModal = useCallback(() => {
    setClearAllModalOpen(true);
  }, []);

  const handleResetFilters = useCallback(
    (confirm) => {
      if (confirm) {
        onReset();
      }
      setResetModalOpen(false);
    },
    [onReset]
  );

  const handleClearAllFilters = useCallback(
    (confirm) => {
      if (confirm) {
        onClearAll();
        if (matchesMediaQuery) {
          onClose();
        }
      }
      setClearAllModalOpen(false);
    },
    [matchesMediaQuery, onClearAll, onClose]
  );

  const handleApplyFilters = useCallback(() => {
    const preparedSubStyles = currentFilters[tabKeys.subStyles]
      .map((subStyleId) => findObjectById(subStyleId, enums.subStyles))
      .filter(({ styleId }) => currentFilters[tabKeys.styles].includes(styleId))
      .reduce((accum, { id: currValue }) => {
        /* eslint-disable no-param-reassign */
        accum[currValue] = 1;
        return accum;
      }, {});

    let preparedFilters = {
      ...currentFilters,
      [tabKeys.subStyles]: preparedSubStyles,
    };
    if (currentFilters.profession) {
      preparedFilters = {
        ...currentFilters,
        [tabKeys.subStyles]: preparedSubStyles,
      };
    }

    handleUpdateSearchParams({ search: '' });
    onApply(preparedFilters);

    let nextTab = null;

    if (matchesMediaQuery) {
      onClose();
      return;
    }

    const filterKeys = Object.keys(tabKeys).filter((key) => {
      return disabledFilters.indexOf(key) === -1;
    });

    filterKeys.forEach((key, index) => {
      if (tabKeys[key] === activeTab) {
        if (
          currentFilters[tabKeys.styles].length === 0 &&
          activeTab === tabKeys.styles &&
          disabledFilters.indexOf(tabKeys.subStyles) === -1
        ) {
          nextTab = filterKeys[index + 2];
        } else {
          nextTab = filterKeys[index + 1];
        }
      }
    });

    if (nextTab) {
      setActiveTab(nextTab);
    } else {
      onClose();
    }
  }, [
    currentFilters,
    tabKeys,
    handleUpdateSearchParams,
    onApply,
    matchesMediaQuery,
    enums.subStyles,
    onClose,
    disabledFilters,
    activeTab,
  ]);

  const handleDropdownChange = useCallback(
    (type, id, checked) => {
      let currentValuesCopy = [...currentFilters[type]];
      if (checked) {
        currentValuesCopy.push(Number(id));
      } else {
        currentValuesCopy = currentValuesCopy.filter(
          (value) => value !== Number(id)
        );
      }
      setCurrentFilters((prevState) => {
        const newFilters = {
          ...prevState,
          [type]: currentValuesCopy,
        };
        const preparedSubStyles = newFilters[tabKeys.subStyles]
          .map((subStyleId) => findObjectById(subStyleId, enums.subStyles))
          .filter(({ styleId }) => newFilters[tabKeys.styles].includes(styleId))
          .reduce((accum, { id: currValue }) => {
            /* eslint-disable no-param-reassign */
            accum[currValue] = 1;
            return accum;
          }, {});

        let preparedFilters = {
          ...newFilters,
          [tabKeys.subStyles]: preparedSubStyles,
        };

        if (newFilters.profession) {
          const professionOption = professionOptions.find(
            (option) => option.id === newFilters.profession[0]
          );
          const preparedProfessionFilter = professionOption?.value || '';

          preparedFilters = {
            ...newFilters,
            [tabKeys.subStyles]: preparedSubStyles,
            [ambassadorFilterTabKeys.profession]: preparedProfessionFilter,
          };
        }

        onApply(preparedFilters);
        return newFilters;
      });
    },
    [
      currentFilters,
      enums.subStyles,
      onApply,
      tabKeys.styles,
      tabKeys.subStyles,
    ]
  );

  return (
    <>
      <BasicModal
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        scroll="body"
        classes={classes}
      >
        {matchesMediaQuery ? (
          <>
            <h3 className="text-lg font-semi-bold mb-4">Filters</h3>
            <MobileFilter
              name="Quick Filters"
              currentFilters={currentFilters}
              activeTab={activeTab}
              onChange={handleDropdownChange}
            />
            <div className="mt-3 d-flex">
              <Button
                variant="outlined"
                size="custom"
                className="w-50 mr-2"
                onClick={handleOpenClearAllModal}
              >
                Clear All
              </Button>
              <div className="text-center w-50">
                <Button
                  className="w-100"
                  inline
                  size="sm"
                  onClick={handleApplyFilters}
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex">
              {tabsConfig.map(({ value, title }) => {
                if (disableRoomTypeFilter && value === tabKeys.roomTypes) {
                  return null;
                }
                if (!isAmbassadorsFilter && value === tabKeys.profession) {
                  return null;
                }
                if (isAmbassadorsFilter && value === tabKeys.itemClasses) {
                  return null;
                }
                if (disabledFilters.indexOf(value) !== -1) {
                  return null;
                }
                if (
                  imageType === imageTypeKeys.single &&
                  value === tabKeys.roomTypes
                ) {
                  return null;
                }

                return (
                  <button
                    key={value}
                    type="button"
                    className={clsx(classes.tabBtn, {
                      [classes.active]: value === activeTab,
                    })}
                    data-tab={value}
                    disabled={
                      value === tabKeys.subStyles &&
                      currentFilters[filterTabKeys.styles].length === 0
                    }
                    onClick={handleChangeActiveTab}
                  >
                    {title}
                  </button>
                );
              })}
              {showQuickFilter && (
                <FilterDropdown
                  name="Quick Filters"
                  currentFilters={currentFilters}
                  onChange={handleDropdownChange}
                  activeTab={activeTab}
                />
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p className={`${classes.modalSubtitle} mb-1`}>
                  {isAmbassadorsFilter
                    ? ambassadorModalSubtitles[activeTab]
                    : modalSubtitles[activeTab]}
                </p>
                <p className="text-sm font-italic mb-3">
                  {maxSelections && `Select up to ${maxSelections}`}
                  {!maxSelections &&
                    activeTab !== 'profession' &&
                    (descriptions
                      ? descriptions[activeTab]
                      : 'Select all that apply')}
                  {!maxSelections &&
                    activeTab === 'profession' &&
                    'Select All That Apply'}
                </p>
              </div>
              <div className={classes.resetArea}>
                {onClearAll ? (
                  <>
                    <Button
                      variant="default"
                      size="custom"
                      onClick={handleOpenResetModal}
                    >
                      <div className={classes.spriteBg}>
                        <SpriteIcon name="processing" size="sm" />
                      </div>
                      Reset to{' '}
                      {currentUser?.preferences ? 'Preferences' : 'Default'}
                    </Button>
                    <div className={classes.decorLine} />
                    <Button
                      variant="default"
                      size="custom"
                      onClick={handleOpenClearAllModal}
                    >
                      <div className={classes.spriteBg}>
                        <SpriteIcon name="trash" size="sm" />
                      </div>
                      Clear All
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleOpenResetModal}
                  >
                    <SpriteIcon name="trash" size="sm" className="mr-1" />
                    Reset Everything
                  </Button>
                )}
              </div>
            </div>
            <div className="d-flex mb-4">
              <div className="flex-fill d-flex flex-wrap mr-5">
                {selectedOptions?.map(({ id, name }) => (
                  <div className={classes.optionChip} key={id}>
                    <span className="text-sm font-italic mr-2">{name}</span>
                    <SpriteIcon
                      name="cross"
                      size="xs"
                      className="cursor-pointer"
                      data-id={id}
                      onClick={handleUnselectOption}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4 overflow-hidden">
              {isAmbassadorsFilter && activeTab === tabKeys.profession && (
                <ProfessionFilter
                  currentValue={currentFilters[tabKeys.profession]}
                  options={professionOptions}
                  onChange={handleChangeProfessionFilter}
                />
              )}
              {activeTab === tabKeys.styles && (
                <StylesFilter
                  options={Object.values(enums[tabKeys.styles])}
                  currentValue={currentFilters[tabKeys.styles]}
                  onChange={handleChangeStyleFilter}
                />
              )}
              {activeTab === tabKeys.subStyles &&
                disabledFilters.indexOf(tabKeys.subStyles) === -1 && (
                  <SubStylesGrid
                    options={subStyleOptions}
                    currentValue={currentFilters[tabKeys.subStyles]}
                    onChange={handleChangeStyleFilter}
                  />
                )}
              {activeTab === tabKeys.roomTypes &&
                !disableRoomTypeFilter &&
                disabledFilters.indexOf(tabKeys.roomTypes) === -1 && (
                  <RoomTypesFilter
                    options={mainRoomTypes}
                    currentValue={currentFilters[tabKeys.roomTypes]}
                    onChange={handleChangeStyleFilter}
                  />
                )}
              {activeTab === tabKeys.colors && (
                <ColorSelect
                  colorList={Object.values(enums[tabKeys.colors])}
                  selectedColors={currentFilters[tabKeys.colors]}
                  onChange={handleChangeColorFilter}
                />
              )}
              {!isAmbassadorsFilter && activeTab === tabKeys.itemClasses && (
                <ItemClassesFilter
                  options={itemClassesConfig}
                  currentValue={currentFilters[tabKeys.itemClasses]}
                  onChange={handleChangeItemClassFilter}
                />
              )}
            </div>

            <div className="text-center">
              <Button inline size="lg" onClick={handleApplyFilters}>
                Apply
              </Button>
            </div>
          </>
        )}
      </BasicModal>
      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetFilters}
        title={
          onClearAll ? (
            <>
              Are you sure you want to <br />
              <span className="primary-color">reset</span> filters to default
              {currentUser?.preferences && (
                <span>
                  <br />
                  preferences
                </span>
              )}
              ?
            </>
          ) : (
            <>
              Are you sure you want to <br />
              <span className="primary-color">reset</span> filters?
            </>
          )
        }
      />
      <ConfirmModal
        onClose={handleClearAllFilters}
        open={clearAllModalOpen}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">clear</span> all filters?
          </>
        }
      />
    </>
  );
};

FiltersModal.propTypes = {
  imageType: PropTypes.oneOf(Object.values(imageTypeKeys)),
  showQuickFilter: PropTypes.bool,
  filterValues: PropTypes.shape({}).isRequired,
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  onClearAll: PropTypes.func,
  descriptions: PropTypes.shape({}),
  customTabsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  customTabKeys: PropTypes.shape({}),
  isAmbassadorsFilter: PropTypes.bool,
  disableRoomTypeFilter: PropTypes.bool,
  handleUpdateSearchParams: PropTypes.func,
  maxSelections: PropTypes.number,
};

FiltersModal.defaultProps = {
  disabledFilters: [],
  imageType: null,
  onClearAll: null,
  descriptions: null,
  customTabsConfig: null,
  customTabKeys: null,
  isAmbassadorsFilter: false,
  disableRoomTypeFilter: false,
  showQuickFilter: false,
  handleUpdateSearchParams: () => {},
  maxSelections: null,
};

export default FiltersModal;
