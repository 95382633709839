import { changeEmailFormFields } from 'modules/account/constants';
import { errorMessages } from 'modules/auth/signUp/constants';

const emailPattern = /.+@.+..+/;

const changeEmailValidator = ({
  [changeEmailFormFields.newEmail]: newEmail,
  [changeEmailFormFields.confirmNewEmail]: confirmNewEmail,
}) => {
  const errors = {};

  if (!newEmail) {
    errors.newEmail = errorMessages.required;
  } else if (!emailPattern.test(newEmail)) {
    errors.newEmail = errorMessages.emailInvalid;
  }

  if (newEmail && !confirmNewEmail) {
    errors.confirmNewEmail = errorMessages.required;
  } else if (newEmail !== confirmNewEmail) {
    errors.confirmNewEmail = errorMessages.emailsShouldMatch;
  }

  return errors;
};

export default changeEmailValidator;
