import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import doneIcon from 'assets/icons/done.svg';
import Button from 'components/ui/Button/Button';
import PropTypes from 'prop-types';
import classes from './VoteModal.module.scss';

const votedOptions = [
  'Best Match',
  'Most Stylish',
  'Most Unique',
  'Best Combo',
];
const VoteModal = ({ isVoted, onVote, closeModal }) => {
  const [selectedVote, setSelectedVote] = useState(null);

  const selectVoteHandler = useCallback((value) => {
    setSelectedVote(value);
  }, []);
  const handleRootClick = useCallback(
    (e) => {
      if (e.target === e.currentTarget) closeModal();
    },
    [closeModal]
  );
  return (
    <div className={classes.root} onClick={handleRootClick}>
      <div className={classes.dropdownWrapper}>
        {isVoted ? (
          <div className={classes.thanksForVote}>
            <div
              className={clsx(
                classes.thanksForVoteImage,
                'd-flex align-items-center justify-content-center mb-3'
              )}
            >
              <img src={doneIcon} alt="done" />
            </div>
            <span className="text-center">
              Thanks <br /> for your vote!
            </span>
          </div>
        ) : (
          <>
            <div className="pr-4">
              <div className={classes.dropdownTitle}>Vote for One:</div>
              {votedOptions.map((voteItem) => {
                return (
                  <li
                    key={voteItem}
                    className="d-flex align-items-center"
                    onClick={() => selectVoteHandler(voteItem)}
                  >
                    <div
                      className={clsx(
                        classes.dropdownSelector,
                        'd-flex align-items-center justify-content-center'
                      )}
                    >
                      {selectedVote === voteItem && (
                        <img src={doneIcon} alt="done" />
                      )}
                    </div>
                    <div>{voteItem}</div>
                  </li>
                );
              })}
            </div>
            <Button
              inline
              variant="contained"
              size="custom"
              onClick={() => onVote(selectedVote)}
              disabled={!selectedVote}
              className="py-1 w-100"
            >
              Vote
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

VoteModal.propTypes = {
  isVoted: PropTypes.bool.isRequired,
  onVote: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default VoteModal;
