import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import ambassadorService from 'modules/ambassadors/ambassadorService';
import CodeModal from 'modules/ambassadors/components/CodeModal/CodeModal';
import RequestSentModal from 'modules/ambassadors/components/RequestSentModal/RequestSentModal';
import { codeModalFields } from 'modules/ambassadors/components/CodeModal/constants';
import { loginByInvitationAction } from 'modules/auth/store/actions';
import RequestModal from 'modules/ambassadors/components/RequestModal/RequestModal';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import Button from 'components/ui/Button/Button';
import ambassadorOverviewVideo from 'assets/video/ambassador-overview.mp4';
import ambassadorPreview from 'assets/img/ambassador-page/ambassador-video-preview.mp4.png';
import womanImg from 'assets/img/about-us-page/woman.png';
import roomsImg from 'assets/img/about-us-page/rooms.png';
import errorToastr from 'libs/toastr/errorToastr';
import infoToastr from 'libs/toastr/infoToastr';
import useQuery from 'hooks/useQuery';
import classes from './GetStarted.module.scss';

const bannerBgStyle = {
  backgroundImage: `url(${womanImg})`,
};
const GetStartedContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQuery();
  const { pathname } = useLocation();
  const user = useSelector((state) => state.auth.user);
  const videoRef = useRef();
  const [activationCode, setActivationCode] = useState(null);
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestSentModalOpen, setRequestSentModalOpen] = useState(false);

  const isAmbassador = useMemo(() => Boolean(user?.ambassador), [user]);
  const authenticated = useMemo(() => Boolean(user), [user]);

  useEffect(() => {
    const code = queryParams.get(codeModalFields.code);
    if (code) {
      setActivationCode(code);
      setCodeModalOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleOpenCodeModal = useCallback(() => {
    setCodeModalOpen(true);
  }, []);

  const handleCloseCodeModal = useCallback(() => {
    setCodeModalOpen(false);
  }, []);

  const handleOpenRequestModal = useCallback(() => {
    // if (!hasVerifiedEmail) {
    //   toggleVerificationModal(true);
    //   return;
    // }
    setRequestModalOpen(true);
  }, []);

  const handleApplyNow = useCallback(() => {
    if (!authenticated) {
      const redirectUrl = `?${routesByName.auth.key}=${routesByName.auth.signUp}`;
      history.push(redirectUrl);
      return;
    }
    if (user?.ambassador) {
      infoToastr('Warning', 'You are already ambassador.');
      return;
    }
    if (!user?.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    setRequestModalOpen(true);
  }, [authenticated, dispatch, history, user]);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleCloseRequestSentModal = useCallback(() => {
    setRequestSentModalOpen(false);
  }, []);

  const handleSendRequest = useCallback(async (values) => {
    try {
      await ambassadorService.requestAccess(values);
      setRequestModalOpen(false);
      setRequestSentModalOpen(true);
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  const handleSendCode = useCallback(
    async (values) => {
      try {
        await dispatch(loginByInvitationAction(values));
        setCodeModalOpen(false);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch]
  );

  const handlePause = useCallback((e) => e.target.blur(), []);

  return (
    <>
      <section className={classes.root}>
        <div className="mb-4 d-flex justify-content-between">
          <div>
            <h1 className={classes.title}>BECOME A HOME STYLIST AMBASSADOR</h1>
            <p className={classes.description}>
              Join a Community of Decor Lovers and Decorate the World with Us!
            </p>
          </div>
          <div className="d-flex">
            {isAmbassador && (
              <Link to={routesByName.dashboard.ambassadorManagement}>
                <Button className="mr-2" inline size="sm">
                  Ambassador Management
                </Button>
              </Link>
            )}
            <Link to={routesByName.ambassador.faqs}>
              <Button inline variant="outlined" size="sm">
                Ambassador FAQs
              </Button>
            </Link>
          </div>
        </div>
        <div className={classes.container}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className="w-50" style={{ minWidth: '60%' }}>
                <div className="text-center mb-2">
                  <h2 className={classes.mainBannerTitle}>
                    HEY DECOR LOVERS, JOIN US!
                  </h2>
                </div>
                <div className="text-center px-1 mb-2">
                  <p className={classes.bannerDescription}>
                    Pin the Look’s Home Stylist Ambassadors form a passionate
                    community of decor lovers who showcase unique ways to Get
                    the Look of the world’s most inspiring images!
                  </p>
                </div>
                <div className="flex-center mb-5">
                  {!isAmbassador && !authenticated && (
                    <Button
                      onClick={handleOpenCodeModal}
                      className={classes.btnCode}
                      inline
                      size="sm"
                    >
                      <div className="d-flex flex-column">
                        <span className={classes.btnCodeTitle}>
                          Get Started Now!
                        </span>{' '}
                        <span>Code Required</span>
                      </div>
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex-fill pl-2 d-flex position-relative">
                <Button
                  className={classes.bannerButton}
                  inline
                  size="lg"
                  onClick={handleApplyNow}
                >
                  APPLY NOW
                </Button>
              </div>
            </div>
            <div className={classes.mainContent}>
              <div className="d-flex">
                <div className={`${classes.leftColumn} col-6`}>
                  <div className={classes.bannerBg} style={bannerBgStyle} />
                  <div className={classes.bannerContent}>
                    <img className="w-100" src={roomsImg} alt="" />
                    <div className="d-flex justify-content-center">
                      <div>
                        <div
                          className={classes.bannerTitle}
                          style={{ lineHeight: 1.5 }}
                        >
                          DECOR ENTHUSIASTS
                        </div>
                        <div
                          className={classes.bannerTitle}
                          style={{ lineHeight: 1.5 }}
                        >
                          DESIGN PROFESSIONALS
                        </div>
                        <div
                          className={classes.bannerTitle}
                          style={{ lineHeight: 1.5 }}
                        >
                          INFLUENCERS & BLOGGERS
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.listContainer}>
                  <h2 className={classes.listTitle}>What ambassadors do</h2>
                  <ul className={classes.list}>
                    <li>Pin the World’s Most Inspiring Images</li>
                    <li>Showcase Unique Ways to Get the Look</li>
                    <li>Curate Look Board Requests</li>
                    <li>Share Inspirations on Social Media</li>
                  </ul>
                  <h2 className={classes.listTitle}>What ambassadors get</h2>
                  <ul className={classes.list}>
                    <li>Community Membership</li>
                    <li>Showcase Page on Pin the Look</li>
                    <li>Featured Content on Social Media</li>
                    <li>Reward Points Redeemable for Gift Cards</li>
                    <li>Exclusive Access to our Affiliate Programs</li>
                  </ul>
                </div>
              </div>
              <div className={classes.video}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={videoRef}
                  src={ambassadorOverviewVideo}
                  preload="metadata"
                  onPause={handlePause}
                  controls
                  className="w-100"
                  poster={ambassadorPreview}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <CodeModal
        open={codeModalOpen}
        onClose={handleCloseCodeModal}
        authenticated={authenticated}
        pathname={pathname}
        initialValues={{ [codeModalFields.code]: activationCode }}
        onSubmit={handleSendCode}
        onOpenRequestModal={handleOpenRequestModal}
      />
      <RequestModal
        open={requestModalOpen}
        onClose={handleCloseRequestModal}
        onSubmit={handleSendRequest}
      />
      <RequestSentModal
        open={requestSentModalOpen}
        onClose={handleCloseRequestSentModal}
      />
    </>
  );
};

export default GetStartedContainer;
