import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import { imageEditFormFields } from 'modules/dashboard/myImages/components/ImageEditForm/constants';
import imageEditFormValidator from 'modules/dashboard/myImages/components/ImageEditForm/imageEditFormValidator';
import Button from 'components/ui/Button/Button';
import imageDetailsClasses from 'modules/dashboard/myImages/components/ImageDetails/ImageDetails.module.scss';

const ImageEditForm = ({ onSubmit, onCancel, initialValues }) => (
  <>
    <h2
      className={`${imageDetailsClasses.title} font-title text-uppercase mb-4`}
    >
      Edit image information
    </h2>
    <Form
      initialValues={initialValues}
      validate={imageEditFormValidator}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          className="flex-fill d-flex flex-column"
          onSubmit={handleSubmit}
          noValidate
        >
          <Field
            required
            label="Title"
            name={imageEditFormFields.title}
            component={TextInputWrapper}
          />
          <Field
            name={imageEditFormFields.description}
            label="Description (optional)"
            multiline
            rows={4}
            component={TextInputWrapper}
          />
          <div className="text-center mt-auto">
            <Button
              onClick={onCancel}
              variant="outlined"
              className="mx-1"
              inline
            >
              Cancel
            </Button>
            <FinalFormSubmitButton className={imageDetailsClasses.btn} inline>
              Save
            </FinalFormSubmitButton>
          </div>
        </form>
      )}
    />
  </>
);

ImageEditForm.propTypes = {
  initialValues: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ImageEditForm;
