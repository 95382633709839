import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import buildResourceUrl from 'utils/buildResourceUrl';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import { getProductImgUrl } from 'modules/app/store/selectors';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import productService from 'modules/product/productService';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import { routesByName } from 'constants/routes';
import classes from './ProductItem.module.scss';

const ProductItem = ({
  id,
  isLiked,
  url,
  userId,
  hash,
  isDragging,
  isAffiliate,
}) => {
  const productImgUrl = getProductImgUrl(
    useSelector((state) => state.app.config)
  );
  const authenticated = Boolean(useSelector((state) => state.auth.user));

  const history = useHistory();
  const { pathname } = useLocation();
  const [likedStatus, setLikedStatus] = useState(false);

  const handleToggleLikeProduct = useCallback(async () => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    const newLikedStatus = Number(!likedStatus);
    setLikedStatus(Boolean(newLikedStatus));
    await productService.toggleLike(id, newLikedStatus);
  }, [authenticated, likedStatus, id, history, pathname]);

  useEffect(() => {
    setLikedStatus(isLiked);
  }, [isLiked]);

  return (
    <>
      <InspirationImage
        id={id}
        className={classes.root}
        imgUrl={buildResourceUrl(productImgUrl.medium, userId, hash)}
        hoverOverlay={
          <div className="h-100 flex-center">
            <p
              className="text-center text-capitalize font-semi-bold text-sm"
              style={{ lineHeight: 1.3 }}
            >
              Drag & Drop item into template
            </p>
            <div className={classes.productLink}>
              <ImageLink url={url} />
            </div>
          </div>
        }
        isProduct
        isAffiliate={isAffiliate}
      />
      <div
        className={clsx('d-flex justify-content-end', {
          invisible: isDragging,
        })}
      >
        <IconButton
          className={classes.productLikeBtn}
          variant="inverted-grey"
          color="primary"
          size="xs"
          onClick={handleToggleLikeProduct}
        >
          <SpriteIcon name={likedStatus ? 'like-filled' : 'like'} size="xs" />
        </IconButton>
      </div>
    </>
  );
};

ProductItem.propTypes = {
  id: PropTypes.number.isRequired,
  isLiked: PropTypes.number.isRequired,
  url: PropTypes.string,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  isDragging: PropTypes.bool,
  isAffiliate: PropTypes.bool,
};

ProductItem.defaultProps = {
  url: null,
  isDragging: false,
  isAffiliate: false,
};

export default ProductItem;
