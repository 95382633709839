import React from 'react';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import GalleryItem from 'modules/getTheLook/components/GalleryItem';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthLg } from 'constants/mediaQueries';
import classes from './ImagesPopover.module.scss';
import SpriteIcon from '../../ui/SpriteIcon';

const ImagesPopover = ({ anchorEl, open, onClose, images, currentImage }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthLg);
  const library = useSelector((state) => state.inspirationImage.library);

  return (
    <Popover
      classes={{
        paper: clsx(classes.imagePopOverRoot),
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.imagePopOverWrapper}>
        <SpriteIcon
          name="cross"
          size="sm"
          className={classes.crossClose}
          onClick={onClose}
        />
        <h3 className="text-xl text-center font-semi-bold text-uppercase">
          Inspirations
        </h3>
        <div className="d-flex flex-wrap">
          {currentImage && (
            <div
              className={clsx(
                matchesMediaQuery ? 'col-6' : 'col-4',
                'mb-2 d-flex align-items-center'
              )}
            >
              <GalleryItem image={currentImage} />
            </div>
          )}
          {images
            .filter((imageId) => imageId !== currentImage.id)
            .map(
              (image) =>
                library[image] && (
                  <div
                    key={image}
                    className={clsx(
                      matchesMediaQuery ? 'col-6' : 'col-4',
                      'mb-2 d-flex align-items-center'
                    )}
                  >
                    <GalleryItem image={library[image]} />
                  </div>
                )
            )}
        </div>
      </div>
    </Popover>
  );
};

ImagesPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentImage: inspirationImageShape.isRequired,
};
ImagesPopover.defaultProps = {
  anchorEl: null,
};
export default ImagesPopover;
