import React, { useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import ambassadorImg from 'assets/img/ambassador-form-image.png';
import { routesByName } from 'constants/routes';
import {
  accountTypes,
  businessTypeKeys,
  businessTypeOptions,
  signUpFormFields,
} from 'modules/auth/signUp/constants';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import AmbassadorPhotoUpload from 'components/photoUploaders/AmbassadorPhotoUpload';
import PasswordInputWrapper from 'components/finalFormWrappers/PasswordInputWrapper';
import CheckboxInputWrapper from 'components/finalFormWrappers/CheckboxInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import profileFormValidator from 'modules/ambassadors/becomeAmbassador/profileFormValidator';
import { confirmUserAction } from 'modules/currentUser/actions';
import mediaTypes from 'constants/mediaTypes';
import mediaService from 'modules/media/mediaService';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import DatePickerInputWrapper from 'components/finalFormWrappers/DatePickerInputWrapper';
import { toggleTermsModalAction } from 'modules/layout/store/actions';
import PasswordTooltip from 'modules/auth/signUp/components/PasswordTooltip';
import AutoCompleteCustomWrapper from 'components/finalFormWrappers/AutoCompleteCustomWrapper';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import classes from 'modules/ambassadors/becomeAmbassador/BecomeAmbassador.module.scss';
import { reCaptchaActions } from 'constants/V3CaptchaActions';

const ProfileForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { countries: countriesEnum, languages: languagesEnum } = useSelector(
    (state) => state.app.enums
  );
  const countries = useMemo(() => getEnumAsSelectOptions(countriesEnum), [
    countriesEnum,
  ]);
  const languages = useMemo(() => getEnumAsSelectOptions(languagesEnum), [
    languagesEnum,
  ]);

  const history = useHistory();
  const [newImage, setNewImage] = useState(null);
  const [initialFormValues] = useState({
    [signUpFormFields.type]: accountTypes.personal,
    [signUpFormFields.firstName]: user?.firstName,
    [signUpFormFields.lastName]: user?.lastName,
    [signUpFormFields.email]: user?.email,
    [signUpFormFields.countryId]: user?.countryId,
    [signUpFormFields.languageId]: user?.languageId,
    [signUpFormFields.yearStarted]: new Date(),
    [signUpFormFields.businessType]: businessTypeKeys.designer,
  });
  const { executeRecaptcha } = useGoogleReCaptcha();

  const newImageUrl = useMemo(() => {
    return newImage ? URL.createObjectURL(newImage) : newImage;
  }, [newImage]);

  const handleSelectNewImage = useCallback(({ target: { files } }) => {
    if (!files.length) return;
    if (files[0].type.startsWith('image')) {
      const imageSize = files[0].size / (1024 * 1024);
      if (imageSize > 4) {
        errorToastr('Error', 'Max image size is 4MB');
        return;
      }
      setNewImage(files[0]);
    }
    setNewImage(files[0]);
  }, []);

  const handleConfirmUser = useCallback(
    async (formValues) => {
      let reCaptchaToken;
      if (!executeRecaptcha) {
        reCaptchaToken = null;
      }
      reCaptchaToken = await executeRecaptcha(
        reCaptchaActions.becomeAmbassadorForm
      );
      const profileData = {
        ...formValues,
        [signUpFormFields.yearStarted]: moment(formValues.yearStarted).year(),
        reCaptchaToken,
      };

      try {
        let avatarId = null;
        if (newImage) {
          const formData = new FormData();

          formData.append('type', mediaTypes.userAvatar);
          formData.append('file', newImage);

          const {
            result: { id },
          } = await mediaService.uploadMedia(formData);
          avatarId = id;
        }
        await dispatch(confirmUserAction({ ...profileData, avatarId }));
        history.push(routesByName.dashboard.ambassadorManagement);
        successToastr('Success', 'You successfully confirmed invite');
      } catch (e) {
        errorToastr('Error', e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [executeRecaptcha, newImage, dispatch, history]
  );

  const handleOpenTermsModal = useCallback(() => {
    dispatch(toggleTermsModalAction(true));
  }, [dispatch]);

  return (
    <div className={`${classes.formContainer} mb-5`}>
      <div className="d-flex">
        <div className={classes.section}>
          <h3 className={classes.formTitle}>Great Job!</h3>
          <p className="text-sm font-italic mb-3">
            Please fill out this form to complete your registration
          </p>
          <Form
            initialValues={initialFormValues}
            validate={profileFormValidator}
            onSubmit={handleConfirmUser}
            render={({ values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl component="fieldset">
                  <RadioGroup>
                    <div className="d-flex mb-3">
                      <FormControlLabel
                        label="Personal"
                        classes={{
                          root: 'mr-5',
                          label: clsx({
                            'font-semi-bold':
                              values.type === accountTypes.personal,
                          }),
                        }}
                        control={
                          <Field
                            name={signUpFormFields.type}
                            type="radio"
                            value={accountTypes.personal}
                            component={RadioInputWrapper}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Business"
                        classes={{
                          label: clsx({
                            'font-semi-bold':
                              values.type === accountTypes.business,
                          }),
                        }}
                        control={
                          <Field
                            name={signUpFormFields.type}
                            type="radio"
                            value={accountTypes.business}
                            component={RadioInputWrapper}
                          />
                        }
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
                <div className="mb-4">
                  <AmbassadorPhotoUpload
                    avatarUrl={newImageUrl}
                    onChange={handleSelectNewImage}
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-between mb-3">
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="First Name"
                      name={signUpFormFields.firstName}
                      component={TextInputWrapper}
                    />
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Last Name"
                      name={signUpFormFields.lastName}
                      component={TextInputWrapper}
                    />
                  </div>
                  {values.type === accountTypes.business && (
                    <>
                      <div className={classes.halfWidthContainer}>
                        <Field
                          required
                          label="Company Name"
                          name={signUpFormFields.businessName}
                          component={TextInputWrapper}
                        />
                      </div>
                      <div className={classes.halfWidthContainer}>
                        <Field
                          label="Website Url (Optional)"
                          name={signUpFormFields.businessUrl}
                          component={TextInputWrapper}
                        />
                      </div>
                      <div className={`${classes.halfWidthContainer}`}>
                        <Field
                          label="Year Started"
                          name={signUpFormFields.yearStarted}
                          views={['year']}
                          disableFuture
                          fullWidth
                          component={DatePickerInputWrapper}
                        />
                      </div>
                      <div className={`${classes.halfWidthContainer}`}>
                        <Field
                          required
                          freeSolo
                          label="Business Type"
                          name={signUpFormFields.businessType}
                          options={businessTypeOptions}
                          component={AutoCompleteCustomWrapper}
                        />
                      </div>
                    </>
                  )}
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      disabled
                      label="Email"
                      name={signUpFormFields.email}
                      component={TextInputWrapper}
                    />
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Country"
                      name={signUpFormFields.countryId}
                      component={SelectInputWrapper}
                      options={countries}
                    />
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Language"
                      name={signUpFormFields.languageId}
                      component={SelectInputWrapper}
                      options={languages}
                    />
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Zip Code"
                      name={signUpFormFields.zipCode}
                      component={TextInputWrapper}
                    />
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Password"
                      name={signUpFormFields.password}
                      component={PasswordInputWrapper}
                      InputProps={{
                        endAdornment: <PasswordTooltip />,
                      }}
                    />
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Confirm Password"
                      name={signUpFormFields.passwordRepeat}
                      component={PasswordInputWrapper}
                      InputProps={{
                        endAdornment: <PasswordTooltip />,
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between mb-5">
                  <FormControlLabel
                    label={
                      <>
                        I agree to{' '}
                        <button
                          onClick={handleOpenTermsModal}
                          className="secondary-color link-underline btn-clear cursor-pointer text-sm"
                          type="button"
                        >
                          Pin The Look&apos;s terms
                        </button>
                      </>
                    }
                    control={
                      <Field
                        type="checkbox"
                        name={signUpFormFields.terms}
                        component={CheckboxInputWrapper}
                      />
                    }
                  />
                </div>
                <div className="text-center">
                  <FinalFormSubmitButton
                    size="sm"
                    disabled={!values.terms}
                    inline
                  >
                    Sign Up
                  </FinalFormSubmitButton>
                </div>
              </form>
            )}
          />
        </div>
        <img src={ambassadorImg} alt="" className={classes.formImg} />
      </div>
    </div>
  );
};

export default ProfileForm;
