import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';

import classes from './LookBoardPreviewModal.module.scss';

const LookBoardPreviewModal = ({ open, onClose, lookBoard }) => (
  <BasicModal
    open={open}
    onClose={onClose}
    classes={{ root: classes.root, closeBtn: classes.closeBtn }}
    maxWidth="sm"
  >
    {lookBoard ? (
      <LookBoardPreview
        title={lookBoard.title}
        color={lookBoard.color}
        isLiked={lookBoard.isLiked}
        isVoted={lookBoard.isVoted}
        banner={lookBoard.banner}
        products={lookBoard.products}
        showLike={false}
        isPreviewModal
      />
    ) : (
      <span>LookBoard Preview is not found</span>
    )}
  </BasicModal>
);

LookBoardPreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lookBoard: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
    banner: PropTypes.string,
    isLiked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    isVoted: PropTypes.bool,
    products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
};

LookBoardPreviewModal.defaultProps = {
  lookBoard: null,
};

export default LookBoardPreviewModal;
