import React from 'react';
import classes from './Community.module.scss';

const Community = () => (
  <div className="container py-5">
    <h3 className={classes.title}>Pin The Look Community Guidelines</h3>
    <p className={classes.text}>
      This is the Website of Pin the Look, and the following is the privacy
      policy regarding the use of information gathered by us, or submitted by
      visitors to this Website. Pin the Look knows you care deeply about how
      information about you is used and shared, we sincerely appreciate the
      trust you have placed in us by visiting this site.
    </p>
    <h3 className={classes.subTitle}>Pin The Look Community Guidelines</h3>
    <p className={classes.text}>
      On Pin the Look, we encourage you to add beautiful images that will
      inspire creativity in decorating. Any inspiration image added to our site
      must be approved before being viewed publicly. Images not related to the
      acceptable image types, specifications, and sources will not be approved.
    </p>
    <h4 className={classes.listTitle}>Acceptable Image Types:</h4>
    <ul>
      <li>Room designs</li>
      <li>Nature scenes</li>
      <li>Architectural concepts</li>
      <li>Fashion Ideas</li>
      <li>Motifs and Patterns</li>
      <li>Art Pieces</li>
    </ul>
    <h4 className={classes.listTitle}>Acceptable Image Specifications:</h4>
    <ul>
      <li>Images must be at least 400 pixels long on the shortest side</li>
      <li>Images cannot exceed 2000 pixels on the longest side</li>
      <li>Images must be hi-resolution; Blurry images will not be approved</li>
      <li>Images cannot contain watermarks</li>
      <li>
        Images cannot contain logos (unless you have an approved business
        account)
      </li>
    </ul>
    <h4 className={classes.listTitle}>Acceptable Sources:</h4>
    <ul>
      <li>Room designs that are widely shared online</li>
      <li>
        Images owned by designers, architects, or photographers who have granted
        you written permission to use their images on Pin the Look
      </li>
      <li>
        Images you license from sites like Shutterstock, Freepik, Adobe Stock,
        etc
      </li>
      <li>Photos taken and owned by you</li>
      <li>Open access art and photographs (Wikimedia Commons)</li>
      <li>AI images created and owned by you</li>
    </ul>
    <h4 className={classes.listTitle}>Non-Acceptable Images:</h4>
    <ul>
      <li>Photos of people (except fashion runway photos)</li>
      <li>
        Inappropriate photos (adult content, illegal activities, graphic
        violence, etc.)
      </li>
      <li>Political propaganda</li>
      <li>Misinformation (signs or messages)</li>
    </ul>
    <p className={classes.text}>
      <span className="fw-600">The bottom line:</span> We will reject any images
      that do not meet the requirements above or that we deem unacceptable or
      inappropriate.
    </p>
    <h3 className={classes.subTitle}>PIN LINKS</h3>
    <ul>
      <li>Pin images from the original source whenever possible</li>
      <li>Pin images only from high-quality, appropriate sites</li>
      <li>Do not redirect pins to inappropriate sites</li>
    </ul>
    <h3 className={classes.subTitle}>PROFILE IMAGES & DISPLAY NAMES</h3>
    <p className={classes.text}>
      On Pin the Look, you can upload a Profile image and select a Display Name.
      The image and name must be appropriate. If something is deemed
      inappropriate or violates the above Image Requirements, your account will
      be put on hold until changes are made. If the changes are not made, your
      account may be canceled.
    </p>
    <h4 className={classes.listTitle}>Image and Display Name Requirements:</h4>
    <ul>
      <li>Do not add photos or names that impersonate another person</li>
      <li>
        Do not add logos or company names unless you have an approved business
        account
      </li>
      <li>Do not use lewd, misleading, or political language</li>
      <li>
        Do not add profile images that violate the above Image Requirements
      </li>
    </ul>
    <h4 className={classes.listTitle}>Messages and Descriptions</h4>
    <ul>
      <li>Do not create irrelevant content or messages</li>
      <li>Do not spam or send unsolicited commercial messages</li>
      <li>
        Do not use inappropriate, suggestive, or political language in
        descriptions or messages.
      </li>
    </ul>
    <h3 className={classes.subTitle}>INTELLECTUAL PROPERTY</h3>
    <p>To respect the rights of people on and off Pin the Look, please:</p>
    <ul>
      <li>
        Do not infringe on anyone`s intellectual property, privacy, or other
        rights.
      </li>
      <li>
        Do not do anything or post any content that violates laws or
        regulations.
      </li>
      <li>
        Do not use Pin the Look’s name, logo, or trademark in a way that
        confuses people
      </li>
    </ul>
    <h3 className={classes.subTitle}>SITE SECURITY & ACCESS</h3>
    <p>To protect the Pin the Look platform:</p>
    <ul>
      <li>
        Do not access, use, or tamper with our systems or our technical
        providers’ systems.
      </li>
      <li>
        Do not break or circumvent our security measures or test the
        vulnerability of our systems or networks.
      </li>
      <li>
        Do not use any undocumented or unsupported method to access, search,
        scrape, download, or change any part of Pin the Look.
      </li>
      <li>Do not try to reverse-engineer our software.</li>
      <li>
        Do not try to interfere with people on Pin the Look or our hosts or
        networks, like sending a virus, overloading, spamming, or mail-bombing.
      </li>
      <li>
        Do not collect or store personally identifiable information from Pin the
        Look or people on Pin the Look without permission.
      </li>
      <li>
        Do not share your password, let anyone access your account, or do
        anything that might put your account at risk.
      </li>
      <li>
        Do not attempt to buy or sell access to your account, boards, or
        usernames or otherwise transfer account features for compensation.
      </li>
    </ul>
  </div>
);

export default Community;
