import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import debounce from 'lodash-es/debounce';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './SearchInput.module.scss';

const SearchInput = ({
  inputRef,
  inputClasses,
  value: searchQuery,
  placeholder,
  onChange,
  onCrossClick,
}) => {
  const [inputText, setInputText] = useState(searchQuery);

  useEffect(() => {
    setInputText(searchQuery);
  }, [searchQuery]);

  const triggerOnChange = useCallback(
    debounce((query) => onChange(query), 500),
    [onChange]
  );

  const onInputChange = useCallback(
    ({ target: { value } }) => {
      setInputText(value);
      triggerOnChange(value);
    },
    [triggerOnChange]
  );

  return (
    <div className={`${classes.inputWrapper} ${inputClasses.wrapper}`}>
      <SpriteIcon
        className={clsx(classes.searchIcon, 'mr-2')}
        name="loupe"
        size="md"
      />
      <input
        ref={inputRef}
        className={`${classes.input} ${inputClasses.input}`}
        value={inputText}
        onChange={onInputChange}
        type="text"
        placeholder={placeholder}
      />
      {inputText.length > 0 && onCrossClick && (
        <SpriteIcon
          className={clsx(classes.closeIcon, 'ml-1')}
          name="cross"
          size="md"
          onClick={onCrossClick}
        />
      )}
    </div>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onCrossClick: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  inputClasses: PropTypes.shape({
    wrapper: PropTypes.string,
    input: PropTypes.string,
  }),
  inputRef: PropTypes.oneOfType([PropTypes.object]),
};

SearchInput.defaultProps = {
  value: '',
  placeholder: '',
  inputClasses: {},
  inputRef: null,
  onCrossClick: null,
};

export default SearchInput;
