import React from 'react';
import PropTypes from 'prop-types';
import { slidesArray } from 'modules/home/components/Ambassadors/Slider/propTypes';
import clsx from 'clsx';
import Slider from 'react-slick';
import classes from './Slider.module.scss';

const ImageSlider = ({
  sliderRef,
  items,
  onChange,
  settings,
  onSlideClick,
  activeSlideIndex,
}) => (
  <Slider ref={sliderRef} {...settings} beforeChange={onChange}>
    {items.map(({ id, imgUrl }, index) => (
      <div className={classes.slideWrapper} key={id}>
        <div
          onClick={() => {
            onSlideClick(index);
          }}
          className={clsx(classes.slide, {
            [classes.active]: index === activeSlideIndex,
          })}
        >
          <div className={classes.slideImage}>
            <img src={imgUrl} alt="" />
          </div>
        </div>
      </div>
    ))}
  </Slider>
);

ImageSlider.propTypes = {
  sliderRef: PropTypes.oneOfType([PropTypes.object]).isRequired,
  items: slidesArray.isRequired,
  onChange: PropTypes.func.isRequired,
  onSlideClick: PropTypes.func.isRequired,
  settings: PropTypes.oneOfType([PropTypes.object]).isRequired,
  activeSlideIndex: PropTypes.number.isRequired,
};

export default ImageSlider;
