import {
  CTL_INSERT_PRODUCT_TO_TOP,
  CTL_RESET_CANVAS,
  CTL_RESET_IMAGE_FILTER_VALUES,
  CTL_RESET_PRODUCT_FILTER_VALUES,
  CTL_RESET_STATE,
  CTL_SET_EDIT_STATE,
  CTL_SET_FORM_VALUES,
  CTL_SET_IMAGES,
  CTL_SET_LOOK_BOARD_STATUS,
  CTL_SET_PRODUCTS,
  CTL_SET_SELECTED_TEMPLATE,
  CTL_SET_STEP,
  CTL_UPDATE_DND_STATE,
  CTL_UPDATE_II_SEARCH_PARAMS,
  CTL_UPDATE_IMAGE_FILTER_VALUES,
  CTL_UPDATE_IMAGES,
  CTL_UPDATE_LOOK_BOARD_DATA,
  CTL_UPDATE_PRODUCT_FILTER_VALUES,
  CTL_UPDATE_PRODUCT_SEARCH_PARAMS,
  CTL_UPDATE_PRODUCTS,
  CTL_UPDATE_STATE,
} from 'modules/curateTheLook/store/constants';
import { updateProductsLibraryAction } from 'modules/product/store/actions';
import {
  createLookBoardStepKeys,
  lookBoardStatusKeys,
  lookBoardTemplatesConfig,
} from 'modules/curateTheLook/constants';
import customEvent from 'utils/customEvent';
import { SET_INITIAL_SCROLLER_HEIGHT } from 'constants/customEventNames';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import transformArrayToMap from 'utils/transformArrayToMap';
import findDifferentFields from 'utils/findDifferentFields';
import { PRODUCT_UPDATE_INSTANTLY_CREATED } from 'modules/product/store/constants';
import { productTypeKeys } from 'modules/product/constants';
import productService from 'modules/product/productService';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';

const removeDuplicates = (originalList, arrayToCheck) => {
  const filteredList = originalList.filter((id) => !arrayToCheck.includes(id));

  return [...new Set(filteredList)];
};

const getDroppedProductIds = (dynamicColumns) =>
  Object.keys(dynamicColumns)
    .filter((column) => dynamicColumns[column].product)
    .map((column) => dynamicColumns[column].product.id);

const getProductIds = (dynamicColumns) =>
  Object.keys(dynamicColumns)
    // .filter((column) => dynamicColumns[column].product)
    .map((column) =>
      dynamicColumns[column].product ? dynamicColumns[column].product.id : null
    );

export const updateLookBoardDataAction = (payload) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_LOOK_BOARD_DATA, payload });
};

export const removeItemFromCanvasAction = (columnId) => (
  dispatch,
  getState
) => {
  const {
    curateTheLook: { dndState },
  } = getState();
  const sourceColumnCopy = { ...dndState.dynamicColumns[columnId] };
  const movedProductId = sourceColumnCopy.product.id;
  const productListCopy = { ...dndState.productList };

  // don't add rejected product to productList
  if (sourceColumnCopy.product.approval === 'rejected') {
    productListCopy.productIds = [...productListCopy.productIds];
  } else {
    productListCopy.productIds = [
      movedProductId,
      ...productListCopy.productIds,
    ];
  }

  sourceColumnCopy.product = null;

  const columnsCopy = {
    ...dndState.dynamicColumns,
    [columnId]: sourceColumnCopy,
  };
  const droppedProductIds = getProductIds(columnsCopy);
  const lookBoardData = { products: droppedProductIds };

  dispatch({
    type: CTL_UPDATE_DND_STATE,
    payload: {
      productList: productListCopy,
      dynamicColumns: columnsCopy,
    },
  });
  dispatch(updateLookBoardDataAction(lookBoardData));
};

const buildEmptyCells = (count, startNumber) => {
  const arrayFromColumnCount = Array.from(
    Array(count),
    (_, i) => i + startNumber + 1
  );

  return arrayFromColumnCount.reduce((accum, currItem) => {
    const columnId = `item-${currItem}`;
    /* eslint-disable no-param-reassign */
    accum[columnId] = { id: columnId, product: null };

    return accum;
  }, {});
};

export const buildDroppableColumnsAction = (
  count,
  startNumber = 0,
  isSubtracking = false
) => (dispatch, getState) => {
  const {
    curateTheLook: { dndState },
  } = getState();
  let updatedState;

  if (isSubtracking) {
    const currentItemsLength = Object.keys(dndState.dynamicColumns).length;
    const neededLength = currentItemsLength - count;
    const cols = {};
    Object.keys(dndState.dynamicColumns).forEach((cell, idx) => {
      if (idx + 1 <= neededLength) {
        cols[`item-${idx + 1}`] = dndState.dynamicColumns[cell];
      } else {
        // eslint-disable-next-line no-unused-expressions
        dndState.dynamicColumns[cell].product &&
          dispatch(
            removeItemFromCanvasAction(dndState.dynamicColumns[cell].id)
          );
      }
    });
    updatedState = {
      dynamicColumns: {
        ...cols,
      },
    };
  } else {
    const cols = buildEmptyCells(count, startNumber);
    updatedState = {
      dynamicColumns: {
        ...(startNumber > 0 && dndState.dynamicColumns),
        ...cols,
      },
    };
  }

  dispatch({
    type: CTL_UPDATE_DND_STATE,
    payload: updatedState,
  });

  customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
};

export const setImagesAction = (transformedList, list) => (dispatch) => {
  const imageIds = Object.values(list).map(({ id }) => `${id}`);
  dispatch(updateIILibraryAction(transformedList));
  dispatch({
    type: CTL_SET_IMAGES,
    payload: imageIds,
  });
};

export const updateImagesAction = (transformedList, list) => (dispatch) => {
  const imageIds = Object.values(list).map(({ id }) => `${id}`);
  dispatch(updateIILibraryAction(transformedList));
  dispatch({
    type: CTL_UPDATE_IMAGES,
    payload: imageIds,
  });
};

export const setProductsAction = (list) => (dispatch, getState) => {
  const listWithoutRejected = list.filter(
    (elem) => elem.approval !== 'rejected'
  );
  const {
    curateTheLook: {
      productSearchParams: { type },
      dndState: { dynamicColumns },
    },
    product: { createdInstantlyProductIds },
  } = getState();

  const productsInDndState = Object.values(dynamicColumns)
    .map(({ product }) => product?.id)
    .filter((value) => !!value);

  const productIds = removeDuplicates(
    listWithoutRejected.map(({ id }) => id),
    productsInDndState
  );

  const noRepeatedCreatedInstantlyProductIds = removeDuplicates(
    createdInstantlyProductIds,
    productsInDndState
  );

  const transformedList = transformArrayToMap(listWithoutRejected);

  dispatch(updateProductsLibraryAction(transformedList));
  const productsIdsWithInstantly = [
    ...new Set([...noRepeatedCreatedInstantlyProductIds, ...productIds]),
  ];

  dispatch({
    type: CTL_SET_PRODUCTS,
    payload:
      type !== productTypeKeys.liked ? productsIdsWithInstantly : productIds,
  });
};

export const updateProductsAction = (list) => (dispatch, getState) => {
  const {
    curateTheLook: {
      dndState: {
        dynamicColumns,
        productList: { productIds: productList },
      },
    },
  } = getState();

  const productsInDndState = Object.values(dynamicColumns)
    .map(({ product }) => product?.id)
    .filter((value) => !!value);

  const arrayToCheck = [...productsInDndState, ...productList];

  const productIds = removeDuplicates(
    list.map(({ id }) => id),
    arrayToCheck
  );

  if (productIds.length) {
    const transformedList = transformArrayToMap(list);
    dispatch(updateProductsLibraryAction(transformedList));
    dispatch({
      type: CTL_UPDATE_PRODUCTS,
      payload: productIds,
    });
  }
};

export const excludeProductsAction = (productIds) => (dispatch, getState) => {
  const {
    curateTheLook: {
      dndState: {
        productList: { productIds: productList },
      },
    },
  } = getState();

  const filteredList = removeDuplicates(productList, productIds);

  dispatch({
    type: CTL_SET_PRODUCTS,
    payload: filteredList,
  });
};

export const handleDragEndAction = ({ source, destination, draggableId }) => (
  dispatch,
  getState
) => {
  const {
    curateTheLook: { dndState },
    product: { library },
  } = getState();

  if (!destination) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const sourceColumn =
    dndState[source.droppableId] || dndState.dynamicColumns[source.droppableId];

  const destColumn = dndState.dynamicColumns[destination.droppableId];
  const sourceColumnCopy = { ...sourceColumn };
  const destColumnCopy = { ...destColumn };
  const productListCopy = { ...dndState.productList };
  let isProductRejected = false;

  if (sourceColumn.id === 'productList') {
    sourceColumnCopy.productIds.splice(source.index, 1);
    if (destColumn.product) {
      sourceColumnCopy.productIds.unshift(destColumn.product.id);
    }
  } else {
    sourceColumnCopy.product = null;
    if (destColumn.product) {
      productListCopy.productIds.unshift(destColumn.product.id);
    }
  }

  if (destColumnCopy.product?.approval === 'rejected') {
    isProductRejected = true;
  }

  destColumnCopy.product = { ...library[draggableId] };

  let newDndState;

  if (sourceColumn.id === 'productList') {
    newDndState = {
      [sourceColumnCopy.id]: sourceColumnCopy,
      dynamicColumns: {
        ...dndState.dynamicColumns,
        [destColumnCopy.id]: destColumnCopy,
      },
    };
  } else {
    // remove rejected product to productsList
    if (isProductRejected) {
      productListCopy.productIds.shift();
    }
    newDndState = {
      productList: productListCopy,
      dynamicColumns: {
        ...dndState.dynamicColumns,
        [sourceColumnCopy.id]: sourceColumnCopy,
        [destColumnCopy.id]: destColumnCopy,
      },
    };
  }
  const droppedProductIds = getProductIds(newDndState.dynamicColumns);
  const lookBoardData = { products: droppedProductIds };

  dispatch({
    type: CTL_UPDATE_DND_STATE,
    payload: newDndState,
  });
  dispatch(updateLookBoardDataAction(lookBoardData));
};

export const changeTemplateAction = (template) => (dispatch, getState) => {
  dispatch({ type: CTL_SET_SELECTED_TEMPLATE, payload: template });
  const {
    curateTheLook: { dndState },
  } = getState();

  if (template) {
    const lookBoardData = { columns: template.columnCount };
    dispatch(buildDroppableColumnsAction(template.columnCount * 2));

    if (Object.values(dndState.dynamicColumns).some((col) => col.product)) {
      const cols = buildEmptyCells(template.columnCount, 0);
      const stateColumns = { ...dndState.dynamicColumns };
      // eslint-disable-next-line no-restricted-syntax
      for (const key in cols) {
        if (key in stateColumns) delete cols[key];
      }
      dispatch({
        type: CTL_UPDATE_DND_STATE,
        payload: { ...dndState, dynamicColumns: { ...stateColumns, ...cols } },
      });
    }

    dispatch(updateLookBoardDataAction(lookBoardData));
  }
  customEvent.trigger(SET_INITIAL_SCROLLER_HEIGHT);
};

export const changeStepAction = (step) => (dispatch) => {
  dispatch({ type: CTL_SET_STEP, payload: step });
  if (step !== createLookBoardStepKeys.addProducts) {
    dispatch(changeTemplateAction(null));
  }
};

export const updateProductSearchParamsAction = (params) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_PRODUCT_SEARCH_PARAMS, payload: params });
};

export const updateProductFilterValuesAction = (filterValues) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_PRODUCT_FILTER_VALUES, payload: filterValues });
  dispatch(updateProductSearchParamsAction({ offset: 0 }));
};

export const selectImageAction = (
  imgId,
  reqId,
  templateId,
  lookBoardId = null
) => async (dispatch) => {
  let currentTemplate;

  if (templateId) {
    currentTemplate = lookBoardTemplatesConfig[templateId];
  } else {
    currentTemplate = Object.values(
      lookBoardTemplatesConfig
    ).find(({ isDefault }) => Boolean(isDefault));
  }

  const lookBoardData = {
    inspirationImageId: imgId,
    requestId: reqId ?? undefined,
    lookBoardId,
  };

  dispatch(updateLookBoardDataAction(lookBoardData));
  dispatch(changeStepAction(createLookBoardStepKeys.addProducts));
  dispatch(changeTemplateAction(currentTemplate));

  const { result: image } = await inspirationImageService.getImageById(imgId);

  const filters = {
    colors: image.colorsGroup,
    styles: image.styles,
    imageId: image.id,
  };

  dispatch(updateProductFilterValuesAction(filters));
};

export const resetCanvasAction = (columnCount) => (dispatch, getState) => {
  const {
    curateTheLook: { dndState, selectedTemplate },
  } = getState();

  const droppedProductIds = getDroppedProductIds(dndState.dynamicColumns);
  const defaultTemplate = Object.values(lookBoardTemplatesConfig).find(
    (template) => template.isDefault
  );
  if (!selectedTemplate) {
    dispatch(changeTemplateAction(defaultTemplate));
  }
  dispatch(
    buildDroppableColumnsAction(
      selectedTemplate ? selectedTemplate?.columnCount * 2 : 4
    )
  );

  dispatch(
    updateLookBoardDataAction({
      columns:
        selectedTemplate?.columnCount ||
        (columnCount ?? defaultTemplate.columnCount),
    })
  );

  dispatch({ type: CTL_INSERT_PRODUCT_TO_TOP, payload: droppedProductIds });
  dispatch({ type: CTL_RESET_CANVAS });
};

export const unselectImageAction = () => (dispatch) => {
  const lookBoardData = {
    inspirationImageId: null,
    requestId: undefined,
  };

  const filters = {
    colors: [],
    styles: [],
    imageId: null,
  };

  dispatch(updateProductFilterValuesAction(filters));
  dispatch(updateLookBoardDataAction(lookBoardData));
  dispatch(changeStepAction(createLookBoardStepKeys.selectImage));
  dispatch(resetCanvasAction());
  dispatch({ type: CTL_SET_SELECTED_TEMPLATE, payload: null });
};

export const updateIISearchParamsAction = (params) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_II_SEARCH_PARAMS, payload: params });
};

export const saveLookBoardDetailsFormValuesAction = (values) => (dispatch) => {
  dispatch({ type: CTL_SET_FORM_VALUES, payload: values });
};

export const changeLookBoardStatusAction = (value) => (dispatch) => {
  dispatch({ type: CTL_SET_LOOK_BOARD_STATUS, payload: value });
};

export const saveDraftAction = () => async (dispatch, getState) => {
  const {
    curateTheLook: {
      lookBoardData,
      editState: { lookBoardId: editLookBoardId },
    },
  } = getState();

  if (!editLookBoardId) {
    await lookBoardService.createLookBoard(lookBoardData);
    return;
  }

  await lookBoardService.updateLookBoardWithoutRequest(
    editLookBoardId,
    lookBoardData
  );
};

const updateDraftAndSubmit = (id, lookBoardData, updateParams) => async (
  dispatch
) => {
  await lookBoardService.updateLookBoardWithoutRequest(id, lookBoardData);
  const {
    result: { shareUrl },
  } = await lookBoardService.submitLookBoard(id, updateParams);
  dispatch(updateLookBoardDataAction({ shareUrl }));
};

const updateSubmittedLookBoard = (
  id,
  lookBoardData,
  updateParams
) => async () => {
  const { color, products, columns, inspirationImageId } = lookBoardData;
  const updateData = {
    ...updateParams,
    color,
    products,
    columns,
  };

  const updateMethod = inspirationImageId
    ? lookBoardService.updateSubmittedLookBoard
    : lookBoardService.updateLookBoardWithoutRequest;

  await updateMethod(id, updateData);
};

const createAndSubmitLookBoard = (
  lookBoardData,
  updateParams,
  message
) => async (dispatch) => {
  const {
    result: {
      id,
      shareUrl,
      lookBoardSocialImage,
      lookBoardSocialImageFacebook,
    },
  } = await lookBoardService.createLookBoard(lookBoardData);

  const messageWithLinkOnLookBoard = message
    ? `Look Board for you: ${shareUrl} \n${message}`
    : message;

  await lookBoardService.submitLookBoard(id, {
    ...updateParams,
    message: messageWithLinkOnLookBoard,
  });

  dispatch(
    updateLookBoardDataAction({
      id,
      shareUrl,
      lookBoardSocialImage,
      lookBoardSocialImageFacebook,
    })
  );
};

export const saveLookBoardAction = () => async (dispatch, getState) => {
  const {
    curateTheLook: {
      lookBoardData,
      oldLookBoardData,
      lookBoardDetailsFormValues,
      lookBoardDetailsFormValues: {
        title,
        type,
        description,
        styleId,
        roomTypeId,
        itemClassId,
        message,
      },
      lookBoardStatus,
      editState: { lookBoardId: editLookBoardId, isDraft },
    },
  } = getState();

  const objToCompare = { ...lookBoardData, ...lookBoardDetailsFormValues };
  const differentFields = findDifferentFields(objToCompare, oldLookBoardData);

  const optionalParam =
    type === imageTypeKeys.room ? { roomTypeId } : { itemClassId };

  const updateParams = {
    title,
    description,
    public: Number(Boolean(lookBoardStatus === lookBoardStatusKeys.public)),
    styleId,
    ...optionalParam,
  };

  if (editLookBoardId) {
    if (differentFields.length === 1 && differentFields[0] === 'color') {
      lookBoardService.updateLookBoardColor(editLookBoardId, {
        color: lookBoardData.color,
      });
      return;
    }
    const updateFn = isDraft ? updateDraftAndSubmit : updateSubmittedLookBoard;
    await dispatch(updateFn(editLookBoardId, lookBoardData, updateParams));
    return;
  }

  await dispatch(
    createAndSubmitLookBoard(lookBoardData, updateParams, message)
  );
};

export const resetCurateStateAction = () => (dispatch) => {
  dispatch({ type: CTL_RESET_STATE });
};

export const updateCurateStateAction = (payload) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_STATE, payload });
};

export const updateImageFilterValuesAction = (filterValues) => (dispatch) => {
  dispatch({ type: CTL_UPDATE_IMAGE_FILTER_VALUES, payload: filterValues });
  dispatch(updateIISearchParamsAction({ offset: 0 }));
};

export const resetImageFilterValuesAction = () => (dispatch) => {
  dispatch({ type: CTL_RESET_IMAGE_FILTER_VALUES });
};

export const resetProductFilterValuesAction = () => (dispatch) => {
  dispatch({ type: CTL_RESET_PRODUCT_FILTER_VALUES });
  dispatch({ type: CTL_UPDATE_PRODUCT_SEARCH_PARAMS, payload: { offset: 0 } });
};

export const saveInstantlyCreatedProductAction = (product) => (dispatch) => {
  dispatch(updateProductsLibraryAction({ [product.id]: product }));
  dispatch({ type: PRODUCT_UPDATE_INSTANTLY_CREATED, payload: product.id });
};

export const setProductsToCanvasAction = (productIds, cols) => async (
  dispatch
) => {
  const defaultTemplate = Object.values(
    lookBoardTemplatesConfig
  ).find(({ isDefault }) => Boolean(isDefault));
  const columnsCount = cols ?? defaultTemplate.id;
  const removedNullItems = productIds.filter((productId) => productId);
  const productList = await productService.getProductsByIds(removedNullItems);
  const productsMap = transformArrayToMap(productList);
  const sortedList = productIds.map((productId) => productsMap[productId]);
  const cellsWithProducts = sortedList.reduce((acc, product, index) => {
    const accumCopy = { ...acc };
    const key = `item-${index + 1}`;
    accumCopy[key] = { id: key, product };
    return accumCopy;
  }, {});
  const cellCount = sortedList.length;
  // const rowsCount = Math.floor(cellCount / columnsCount) * 2;
  const rowsCount = Math.floor(cellCount / columnsCount);
  const emptyCellCount = columnsCount * rowsCount - cellCount;
  const emptyCells = buildEmptyCells(emptyCellCount, cellCount);

  dispatch(updateProductsLibraryAction(productsMap));
  dispatch({
    type: CTL_UPDATE_DND_STATE,
    payload: {
      dynamicColumns: {
        ...cellsWithProducts,
        ...emptyCells,
      },
    },
  });
};

export const setEditStateAction = (payload) => (dispatch) => {
  dispatch({
    type: CTL_SET_EDIT_STATE,
    payload,
  });
};

export const restoreCurateStateAction = (
  lookBoardData,
  formValues,
  editState
) => async (dispatch) => {
  const defaultTemplate = Object.values(
    lookBoardTemplatesConfig
  ).find(({ isDefault }) => Boolean(isDefault));

  if (!lookBoardData) {
    dispatch({ type: CTL_SET_SELECTED_TEMPLATE, payload: defaultTemplate });
    dispatch(buildDroppableColumnsAction(defaultTemplate.columnCount * 2));
    return;
  }

  const imgId = lookBoardData.inspirationImageId;
  const columnCount = lookBoardData.columns;
  const productIds = lookBoardData.products;

  if (imgId) {
    const { result: image } = await inspirationImageService.getImageById(imgId);
    dispatch(updateIILibraryAction({ [image.id]: image }));
    const filters = {
      colors: image.colorsGroup,
      styles: image.styles,
      subStyles: image.subStyles,
      imageId: image.id,
    };
    dispatch(updateProductFilterValuesAction(filters));
  }

  const currentTemplate = columnCount
    ? lookBoardTemplatesConfig[columnCount]
    : defaultTemplate;

  dispatch({ type: CTL_SET_SELECTED_TEMPLATE, payload: currentTemplate });

  if (editState) {
    dispatch(setEditStateAction(editState));
  }
  if (formValues) {
    dispatch(saveLookBoardDetailsFormValuesAction(formValues));
  }
  dispatch(updateLookBoardDataAction(lookBoardData));

  dispatch(changeStepAction(createLookBoardStepKeys.addProducts));

  if (Array.isArray(productIds) && productIds.length) {
    await dispatch(
      setProductsToCanvasAction(productIds, currentTemplate.columnCount)
    );
  } else {
    dispatch(buildDroppableColumnsAction(currentTemplate.columnCount * 2));
  }
};
