import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './AmbassadorFaqs.module.scss';
import SpriteIcon from '../../../../components/ui/SpriteIcon';

const AccordionItem = ({ question, className, children, onlyOne }) => {
  const contentHeight = useRef();
  const [isOpen, setIsOpen] = useState(null);

  const handleItemClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={clsx(classes.wrapper, className, onlyOne && classes.onlyOne)}
    >
      <button
        className={clsx(classes.questionContainer, isOpen && classes.active)}
        onClick={handleItemClick}
        type="button"
      >
        <p className={classes.questionContent}>{question}</p>
        <SpriteIcon
          name="right-arrow"
          size="md"
          className={clsx(classes.arrow, isOpen && classes.active)}
        />
      </button>

      <div
        ref={contentHeight}
        className={classes.answerContainer}
        style={
          isOpen
            ? { height: contentHeight.current.scrollHeight }
            : { height: '0px' }
        }
      >
        <p className={classes.answerContent}>{children}</p>
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  question: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onlyOne: PropTypes.bool,
};

AccordionItem.defaultProps = {
  className: '',
  onlyOne: false,
};
export default AccordionItem;
