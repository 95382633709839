import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { routesByName } from 'constants/routes';
import { signInFormFields } from 'modules/auth/signIn/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import PasswordInputWrapper from 'components/finalFormWrappers/PasswordInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import customEvent from 'utils/customEvent';
import { OPEN_FORGOT_PASSWORD_MODAL } from 'constants/customEventNames';
import classes from 'modules/auth/signUp/SignUp.module.scss';

const SignInComponent = ({ validateForm, onSubmit }) => {
  const { pathname } = useLocation();

  const handleForgotPassword = useCallback(() => {
    customEvent.trigger(OPEN_FORGOT_PASSWORD_MODAL, { test: 'data' });
  }, []);

  return (
    <>
      <p className={clsx(classes.title, 'mb-2')}>
        Welcome to Pin the Look.
        <br />
        Sign In
      </p>
      <p className={clsx(classes.subTitle, 'mb-3')}>
        New to Pin The Look?{' '}
        <Link
          to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`}
          className="primary-color"
        >
          Sign Up!
        </Link>
      </p>
      <Form
        validate={validateForm}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Field
              required
              label="Email"
              type="email"
              name={signInFormFields.email}
              component={TextInputWrapper}
            />
            <Field
              required
              label="Password"
              type="password"
              name={signInFormFields.password}
              component={PasswordInputWrapper}
            />
            <button
              type="button"
              className={`${classes.link} mb-3`}
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </button>
            <div className="text-center">
              <FinalFormSubmitButton size="sm" inline>
                Submit
              </FinalFormSubmitButton>
            </div>
          </form>
        )}
      />
    </>
  );
};

SignInComponent.propTypes = {
  validateForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SignInComponent;
