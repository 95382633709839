import React, { useState, useCallback } from 'react';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import HowItWorksVideoModal from 'components/modals/HowItWorksVideoModal';
import video from 'assets/video/requestTheLook.mp4';
import posterImg from 'assets/video/requestTheLookPreview.png';
import LeftPanel from 'modules/requestTheLook/createRequest/LeftPanel';
import MainArea from 'modules/requestTheLook/createRequest/MainArea';
import RightPanel from 'modules/requestTheLook/createRequest/RightPanel';

const CreateRequestContainer = () => {
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);

  const handleImgModalOpen = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  return (
    <section className="flex-fill d-flex">
      <LeftPanel onOpenPreview={handleImgModalOpen} />
      <MainArea onOpenPreview={handleImgModalOpen} />
      <RightPanel />
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <HowItWorksVideoModal video={video} posterImg={posterImg} />
    </section>
  );
};

export default CreateRequestContainer;
