import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createLookBoardStepKeys,
  lookBoardTemplatesConfig,
  stepTwoTabKeys,
} from 'modules/curateTheLook/constants';
import {
  changeTemplateAction,
  setProductsAction,
  updateProductsAction,
  updateProductSearchParamsAction,
} from 'modules/curateTheLook/store/actions';
import useCustomEventListener from 'hooks/useCustomEventListener';
import {
  SET_INITIAL_SCROLLER_HEIGHT,
  UPDATE_SCROLLER_HEIGHT,
} from 'constants/customEventNames';
import errorToastr from 'libs/toastr/errorToastr';
import productService from 'modules/product/productService';
import TabSwitch from 'modules/curateTheLook/createLookBoard/components/TabSwitch/TabSwitch';
import ChooseTemplate from 'modules/curateTheLook/createLookBoard/components/ChooseTemplate';
import ProductsSearchParams from 'modules/curateTheLook/createLookBoard/components/ProductsSearchParams/ProductsSearchParams';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import ProductsDatabase from 'modules/curateTheLook/createLookBoard/components/ProductsDatabase/ProductsDatabase';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import useCancelToken from 'hooks/useCancelToken';
import { initialProductFilterValues } from '../store/reducer';
import { getAffiliateSitesAction } from '../../getTheLook/userPage/store/actions';

// TODO: Get limit value from application config
const limit = 10;

const RightPanel = () => {
  const dispatch = useDispatch();
  const enums = useSelector((state) => state.app.enums);
  const {
    currentStep,
    selectedTemplate,
    productSearchParams: searchParams,
    productFilterValues: filterValues,
  } = useSelector((state) => state.curateTheLook);
  const currentUser = useSelector((state) => state.auth.user);

  const containerRef = useRef(null);
  const scrollBarRef = useRef(null);

  const [initialScrollerHeight, setInitialScrollerHeight] = useState(0);
  const [customScrollerHeight, setCustomScrollerHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(stepTwoTabKeys.products);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createCancelToken, isCancelled] = useCancelToken();

  const handleUpdateContainerHeight = useCallback(() => {
    if (containerRef.current) {
      const refHeight = containerRef.current.clientHeight;
      if (Math.round(refHeight) < 500) {
        setCustomScrollerHeight(500);
      } else {
        setCustomScrollerHeight(Math.round(refHeight));
      }
    }
  }, []);

  useCustomEventListener(SET_INITIAL_SCROLLER_HEIGHT, () => {
    setCustomScrollerHeight(initialScrollerHeight);
  });

  useCustomEventListener(UPDATE_SCROLLER_HEIGHT, () => {
    handleUpdateContainerHeight();
  });

  useEffect(() => {
    if (currentStep === createLookBoardStepKeys.addProducts) {
      const refHeight = containerRef.current.clientHeight;
      setInitialScrollerHeight(Math.round(refHeight));
      handleUpdateContainerHeight();
    }
  }, [currentStep, handleUpdateContainerHeight]);

  const handleChangeTab = useCallback(({ currentTarget }) => {
    const tab = currentTarget.getAttribute('data-tab');
    setActiveTab(tab);
    scrollBarRef.current.scrollToTop();
  }, []);

  const handleUpdateSearchParams = useCallback(
    (params) => {
      dispatch(
        updateProductSearchParamsAction({
          ...params,
          offset: 0,
        })
      );

      setHasMore(false);
    },
    [dispatch]
  );

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();

      const colors = Object.values(enums.colors)
        .filter((color) => filterValues.colors.includes(color.color_group_id))
        .map((color) => color.id);

      const list = await productService.getProducts(
        {
          ...searchParams,
          ...(searchParams.search.trim().length
            ? initialProductFilterValues
            : filterValues),
          ...(searchParams.search.trim().length ? {} : { colors }),
        },
        { cancelToken }
      );
      const productArrLength = list.length;

      dispatch(setProductsAction(list));
      if (productArrLength > 0) {
        dispatch(
          updateProductSearchParamsAction({
            offset: searchParams.offset + productArrLength,
          })
        );
      }

      if (currentUser?.hasVerifiedEmail) {
        dispatch(getAffiliateSitesAction());
      }
      setHasMore(productArrLength === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [
    createCancelToken,
    enums.colors,
    searchParams,
    filterValues,
    dispatch,
    currentUser,
    isCancelled,
  ]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();

      const colors = Object.values(enums.colors)
        .filter((color) => filterValues.colors.includes(color.color_group_id))
        .map((color) => color.id);

      const list = await productService.getProducts(
        {
          ...searchParams,
          ...(searchParams.search.trim().length
            ? initialProductFilterValues
            : filterValues),
          ...(searchParams.search.trim().length ? {} : { colors }),
        },
        { cancelToken }
      );
      const productArrLength = list.length;

      dispatch(updateProductsAction(list));
      dispatch(
        updateProductSearchParamsAction({
          offset: searchParams.offset + productArrLength,
        })
      );

      setHasMore(productArrLength === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [
    createCancelToken,
    enums,
    searchParams,
    filterValues,
    dispatch,
    isCancelled,
  ]);

  useEffect(() => {
    if (filterValues === initialProductFilterValues) return;
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(searchParams),
    filterValues,
  ]);

  const handleSelectTemplate = useCallback(
    (templateId) => {
      dispatch(changeTemplateAction(lookBoardTemplatesConfig[templateId]));
    },
    [dispatch]
  );

  return (
    <div className={classes.sidePanel}>
      <div className={`${classes.stepTitle} mb-3`}>
        <span>Step 2: Curate Look Board</span>
      </div>
      {currentStep === createLookBoardStepKeys.addProducts && (
        <>
          <TabSwitch activeTab={activeTab} onChange={handleChangeTab} />
          {activeTab === stepTwoTabKeys.products && (
            <ProductsSearchParams
              currentType={searchParams.type}
              currentSubType={searchParams.affiliateSite}
              searchQuery={searchParams.search}
              onUpdateSearchParams={handleUpdateSearchParams}
            />
          )}
          <div className="flex-fill" ref={containerRef}>
            <CustomScrollBar
              scrollBarRef={scrollBarRef}
              autoHeightMin={customScrollerHeight}
              autoHeightMax={customScrollerHeight}
              renderView={(props) => <div {...props} className="pr-2" />}
            >
              {activeTab === stepTwoTabKeys.templates && (
                <ChooseTemplate
                  templateList={Object.values(lookBoardTemplatesConfig)}
                  selectedTemplate={selectedTemplate}
                  onSelectTemplate={handleSelectTemplate}
                />
              )}
              {activeTab === stepTwoTabKeys.products && (
                <ProductsDatabase
                  scrollerHeight={customScrollerHeight}
                  loading={loading}
                  hasMore={hasMore}
                  loadMore={loadMore}
                />
              )}
            </CustomScrollBar>
          </div>
        </>
      )}
    </div>
  );
};

export default RightPanel;
