import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio } from '@material-ui/core';
import classes from './FilterModal.module.scss';

const labelClasses = {
  label: classes.label,
};

const FilterControlLabel = ({
  label,
  value,
  checked,
  onChange,
  shouldDisableCheckbox,
}) => {
  const disabled = useMemo(() => shouldDisableCheckbox(value), [
    shouldDisableCheckbox,
    value,
  ]);

  const inputProps = useMemo(() => ({ 'data-label': label }), [label]);

  return (
    <FormControlLabel
      classes={labelClasses}
      label={label}
      value={value}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      control={<Radio inputProps={inputProps} color="primary" />}
    />
  );
};

FilterControlLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  shouldDisableCheckbox: PropTypes.func.isRequired,
};

export default FilterControlLabel;
