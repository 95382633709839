import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { routesByName } from 'constants/routes';
import { logoutAction } from 'modules/auth/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import ConfirmModal from 'components/modals/ConfirmModal';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import classes from './HeaderMenu.module.scss';

const HeaderMenu = ({ open, onClose, anchorEl }) => {
  const dispatch = useDispatch();
  const authenticated = Boolean(useSelector((state) => state.auth.user));

  const theme = createMuiTheme({});
  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        onClose(e);
      },
    },
  };

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const handleLogoutModalOpen = useCallback(
    (e) => {
      setLogoutModalOpen(true);
      onClose(e);
    },
    [onClose]
  );

  const handleLogout = useCallback(
    async (confirm) => {
      if (confirm) {
        try {
          await dispatch(logoutAction());
          setLogoutModalOpen(false);
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      } else {
        setLogoutModalOpen(false);
      }
    },
    [dispatch]
  );

  return (
    <ThemeProvider theme={theme}>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        id="headerMenu"
        getContentAnchorEl={null}
        classes={{ list: classes.menuList }}
        PopoverClasses={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onClose} className={classes.menuItem}>
          <Link to={routesByName.about}>About Us</Link>
        </MenuItem>
        <MenuItem onClick={onClose} className={classes.menuItem}>
          <Link to={routesByName.contact}>Contact Us</Link>
        </MenuItem>
        <MenuItem onClick={onClose} className={classes.menuItem}>
          <Link to={routesByName.faqs}>FAQs</Link>
        </MenuItem>
        {authenticated && (
          <MenuItem onClick={handleLogoutModalOpen}>Logout</MenuItem>
        )}
      </Menu>
      <ConfirmModal
        open={logoutModalOpen}
        onClose={handleLogout}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">logout</span> from account?
          </>
        }
      />
    </ThemeProvider>
  );
};

HeaderMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

HeaderMenu.defaultProps = {
  anchorEl: null,
};

export default HeaderMenu;
