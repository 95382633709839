import PropTypes from 'prop-types';

export const requestItemShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  activated: PropTypes.number.isRequired,
  approval: PropTypes.string.isRequired,
  curated: PropTypes.bool.isRequired,
  imageId: PropTypes.number.isRequired,
  priorityItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  publish: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
});
