import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FilterContent from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterContent';
import FilterTabs from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterTabs';
import { filterModalArrayOf } from 'modules/requestTheLook/requestDetails/components/FilterModal/propTypes';

const ItemClassesFilter = ({ options, currentValue, onChange }) => {
  const shouldDisableCheckbox = useCallback(() => {
    return false;
  }, []);

  return (
    <FilterTabs>
      {options.map(({ name, types }) => (
        <FilterContent
          key={name}
          title={name}
          types={types}
          checkedItems={currentValue}
          onChange={onChange}
          shouldDisableCheckbox={shouldDisableCheckbox}
        />
      ))}
    </FilterTabs>
  );
};

ItemClassesFilter.propTypes = {
  options: filterModalArrayOf.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ItemClassesFilter;
