import React, { useCallback, useState, useEffect } from 'react';
import { filter } from 'lodash-es';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';
import FilterTabs from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterTabs';
import FilterContent from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterContent';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';
import { filterModalArrayOf } from 'modules/requestTheLook/requestDetails/components/FilterModal/propTypes';
import classes from './FilterModal.module.scss';

// const limit = 6; // checked items limit

const FilterModal = ({
  open,
  onClose,
  items,
  checkedItems,
  onApply,
  limit,
}) => {
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    const prefilledValues = filter(checkedItems, 'value').map(
      ({ value }) => value
    );
    setCheckedList([...prefilledValues]);
  }, [checkedItems]);

  const handleOnChange = useCallback(({ target: { value, checked } }) => {
    setCheckedList((prev) => {
      return checked
        ? [...prev, Number(value)]
        : filter(prev, (item) => item !== Number(value));
    });
  }, []);

  const shouldDisableCheckbox = useCallback(
    (value) => {
      return (
        checkedList.length >= limit &&
        filter(checkedList, { value }).length === 0
      );
    },
    [checkedList, limit]
  );

  const onApplyHandler = useCallback(() => {
    onApply(checkedList);
  }, [checkedList, onApply]);

  const handleResetList = useCallback(() => {
    setCheckedList([]);
  }, []);

  return (
    <BasicModal open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <h3 className="font-title text-lg text-center mb-3">Filters</h3>
      <div className="mb-3">
        <FilterTabs onReset={handleResetList}>
          {items.map(({ name, types }) => (
            <FilterContent
              key={name}
              title={name}
              types={types}
              checkedItems={checkedList}
              onChange={handleOnChange}
              shouldDisableCheckbox={shouldDisableCheckbox}
            />
          ))}
        </FilterTabs>
      </div>
      <div className="text-center">
        <Button
          onClick={onApplyHandler}
          className={classes.submitBtn}
          inline
          size="sm"
          type="button"
        >
          Apply
        </Button>
      </div>
    </BasicModal>
  );
};

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  items: filterModalArrayOf.isRequired,
  checkedItems: PropTypes.arrayOf(priorityItemShape).isRequired,
  limit: PropTypes.number.isRequired,
};

export default FilterModal;
