import { addAffiliateCodeFields } from 'modules/account/constants';
import { errorMessages } from 'modules/auth/signUp/constants';

const addCodeValidator = ({
  [addAffiliateCodeFields.affiliateCode]: affiliateCode,
  [addAffiliateCodeFields.affiliateSite]: affiliateSite,
}) => {
  const errors = {};

  if (!affiliateSite) {
    errors.affiliateSite = errorMessages.required;
  }

  if (affiliateSite && !affiliateCode) {
    errors.affiliateCode = errorMessages.required;
  }

  return errors;
};

export default addCodeValidator;
