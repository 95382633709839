import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadFirstShowcaseAction,
  loadMoreAmbassadorsAction,
  setAmbassadorsActiveTabAction,
  setSelectedAmbassadorAction,
} from 'modules/getTheLook/ambassadorPage/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import AmbassadorListItem from 'modules/getTheLook/ambassadorPage/AmbassadorListItem';
import { getTheLookAmbassadorsTabKeys } from 'modules/getTheLook/ambassadorPage/config';
import DetailsAmbassadorView from 'modules/getTheLook/ambassadorPage/DetailsAmbassadorView';
import YoutubeVideoModal from '../../../components/modals/YoutubeVideoModal';
import { toggleYoutubeVideoModalAction } from '../../layout/store/actions';

const AmbassadorListView = ({ isFollowed }) => {
  const dispatch = useDispatch();

  const {
    usersList: usersIdList,
    followedUsersList: followedUsersIdList,
    usersLibrary,
    followedUsersLibrary,
    activeTab,
    ambassadorsSearchParams: searchParams,
    loading,
    hasMore,
    selectedAmbassador,
  } = useSelector((state) => state.ambassadorsPage);

  const usersList = useMemo(() => usersIdList.map((id) => usersLibrary[id]), [
    usersIdList,
    usersLibrary,
  ]);

  const followedUsersList = useMemo(
    () => followedUsersIdList.map((id) => followedUsersLibrary[id]),
    [followedUsersIdList, followedUsersLibrary]
  );
  const [videoLink, setVideoLink] = useState(null);

  const handleLoadMore = useCallback(async () => {
    if (!selectedAmbassador) {
      try {
        await dispatch(loadMoreAmbassadorsAction(isFollowed));
      } catch (e) {
        errorToastr('Error', e.message);
      }
    }
  }, [dispatch, isFollowed, selectedAmbassador]);

  const handleSetVideoLink = useCallback(
    async (videoUrl) => {
      setVideoLink(videoUrl);
      dispatch(toggleYoutubeVideoModalAction(true));
    },
    [setVideoLink, dispatch]
  );

  useEffect(() => {
    dispatch(setAmbassadorsActiveTabAction(getTheLookAmbassadorsTabKeys.main));

    return () => {
      dispatch(setSelectedAmbassadorAction(null));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0 && !selectedAmbassador) {
        try {
          await dispatch(loadFirstShowcaseAction());
        } catch (e) {
          errorToastr('Error', e.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  return (
    <>
      <div>
        {activeTab === getTheLookAmbassadorsTabKeys.main && (
          <CustomInfiniteScroll
            isLoading={loading}
            hasMore={hasMore}
            loadMore={handleLoadMore}
            initialLoad={false}
            threshold={500}
          >
            {(isFollowed ? followedUsersList : usersList).map((item, index) => (
              <AmbassadorListItem
                key={Math.random()}
                ambassadorData={
                  isFollowed ? followedUsersList[index] : usersList[index]
                }
                handleSetVideoLink={handleSetVideoLink}
              />
            ))}
          </CustomInfiniteScroll>
        )}
        {/* {activeTab === getTheLookAmbassadorsTabKeys.showcase && ( */}
        {/*  <AmbassadorShowcaseView /> */}
        {/* )} */}
        {activeTab === getTheLookAmbassadorsTabKeys.lookBoards && (
          <DetailsAmbassadorView />
        )}
      </div>
      <YoutubeVideoModal videoUrl={videoLink} />
    </>
  );
};

AmbassadorListView.propTypes = {
  isFollowed: PropTypes.bool,
};

AmbassadorListView.defaultProps = {
  isFollowed: false,
};
export default AmbassadorListView;
