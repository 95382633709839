import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { accountTypes } from 'modules/auth/signUp/constants';
import { navLinksConfig } from 'modules/account/constants';
import SideNav from 'modules/layout/SideNav/SideNav';
import ProfileAndPreferencesContainer from 'modules/account/profileAndPreferences/ProfileAndPreferencesContainer';
import CustomerSupportBanner from 'modules/account/components/CustomerSupportBanner/CustomerSupportBanner';
import TradeOpportunitiesBanner from 'modules/ambassadors/becomeAmbassador/tradeOpportunitiesBanner/TradeOpportunitiesBanner';
import classes from './Account.module.scss';

const AccountContainer = () => {
  const userType = useSelector((state) => state.auth.user.type);

  const { state } = useLocation();

  return (
    <div className="flex-fill d-flex">
      <SideNav navLinks={navLinksConfig} />
      <div className={classes.contentWrapper}>
        <div className={classes.routeContainer}>
          <Switch>
            <Redirect
              exact
              from={routesByName.account.index}
              to={{
                pathname: routesByName.account.profile,
                state,
              }}
            />
            <Route path={routesByName.account.profile}>
              <ProfileAndPreferencesContainer />
            </Route>
            {/* <Route path={routesByName.account.orders}>
            <div>orders</div>
          </Route>
          <Route path={routesByName.account.paymentDetails}>
            <div>paymentDetails</div>
          </Route> */}
          </Switch>
        </div>
        <div className={classes.rightSection}>
          <div className={classes.bannersContainer}>
            <div className="mb-4">
              <CustomerSupportBanner />
            </div>
            {userType === accountTypes.business && <TradeOpportunitiesBanner />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountContainer;
