import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput from 'components/inputs/TextInput/TextInput';
import classes from './AutoCompleteInput.module.scss';

const autoCompleteClasses = {
  root: 'position-relative mt-1',
  inputRoot: classes.inputRoot,
  clearIndicator: classes.clearIndicator,
  option: classes.option,
};

const getOptionLabel = (label) => label;

const AutoCompleteInput = ({
  placeholder,
  label,
  value,
  error,
  options,
  onChange: onChangeFromField,
  helperText,
  ...rest
}) => {
  const onChange = useCallback(
    (_, option) => {
      onChangeFromField(option);
    },
    [onChangeFromField]
  );

  const onChangeInput = useCallback(
    (e) => e && onChangeFromField(e.currentTarget?.value),
    [onChangeFromField]
  );

  return (
    <Autocomplete
      {...rest}
      value={value || ''}
      onChange={onChange}
      onInputChange={onChangeInput}
      classes={autoCompleteClasses}
      fullWidth
      options={options}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextInput
          {...params}
          placeholder={placeholder}
          label={label}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

AutoCompleteInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

AutoCompleteInput.defaultProps = {
  placeholder: null,
  label: null,
  error: null,
  helperText: null,
};

export default AutoCompleteInput;
