import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import buildResourceUrl from 'utils/buildResourceUrl';
import errorToastr from 'libs/toastr/errorToastr';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import {
  setClaimImageIdAction,
  toggleClaimImageModalAction,
} from 'modules/report/store/actions';
import {
  changeIsOwnImageAction,
  updateIIStatusLikeAction,
} from 'modules/inspirationImage/store/actions';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import MoreOptionsPopover from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import {
  resetCanvasAction,
  selectImageAction as selectCTLImageAction,
} from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectRTLImageAction } from 'modules/requestTheLook/store/actions';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import moreOptionsClasses from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover.module.scss';
import classes from './ImageItem.module.scss';
import {
  approvalStatusKeys,
  publishStatusKeys,
} from '../../../../inspirationImage/constants';

const ImageItem = ({
  id,
  userId,
  hash,
  isLiked,
  shareUrl,
  requestId,
  onOpenPreview,
  onSelectImage,
  onLookBoardsView,
  handleBlur,
  imageSource,
  title,
  sourceUrl,
  lookBoardsCount,
  approval,
  publish,
}) => {
  const dispatch = useDispatch();
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const selectedImage = useSelector(
    (state) => state.curateTheLook.lookBoardData.inspirationImageId
  );

  const { pathname } = useLocation();
  const history = useHistory();
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const isGTL = useMemo(
    () => pathname.includes(routesByName.getTheLook.index),
    [pathname]
  );
  const isCTL = useMemo(
    () => pathname.includes(routesByName.curateTheLook.index),
    [pathname]
  );

  const showButton = useMemo(
    () =>
      approval === approvalStatusKeys.approved &&
      publish === publishStatusKeys.publishedPlus,
    [approval, publish]
  );

  const isRTL = useMemo(
    () => pathname.includes(routesByName.requestTheLook.index),
    [pathname]
  );

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, userId, hash),
    [inspirationImageUrl, userId, hash]
  );

  const isMixMatchPage = useMemo(
    () =>
      history.location.pathname.includes(
        routesByName.getTheLook.mixMatch.index
      ),
    [history.location.pathname]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  const handleToggleLike = useCallback(async () => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    try {
      await inspirationImageService.toggleLike(id, Number(!isLiked));
      dispatch(updateIIStatusLikeAction(id, Number(!isLiked)));
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [history, pathname, id, isLiked, dispatch, authenticated]);

  const handleMoreOptionsPopupOpen = useCallback(({ currentTarget }) => {
    setMoreOptionsAnchorEl(currentTarget);
    setIsOptionOpen(true);
    setScrollPosition(window.pageYOffset);
  }, []);

  // set default scroll position after closing "MoreOptionPopup"
  const handleMoreOptionsPopupClose = useCallback(() => {
    setMoreOptionsAnchorEl(null);
    setIsOptionOpen(false);
    handleBlur();
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
  }, [handleBlur, scrollPosition]);

  const handleSelectImage = useCallback(() => {
    dispatch(changeIsOwnImageAction(false));
    onSelectImage(id, requestId);
  }, [onSelectImage, id, requestId, dispatch]);

  const handleOnClaimImage = useCallback(() => {
    dispatch(toggleClaimImageModalAction(true));
    dispatch(setClaimImageIdAction(id));
    handleMoreOptionsPopupClose();
  }, [id, dispatch, handleMoreOptionsPopupClose]);

  const handleMoveToRTL = useCallback(() => {
    dispatch(selectRTLImageAction(id));
    history.push(routesByName.requestTheLook.index);
  }, [dispatch, id, history]);

  const handleMoveToCTL = useCallback(() => {
    if (id !== selectedImage) {
      dispatch(resetCanvasAction());
    }
    if (pathname === routesByName.getTheLook.index) {
      dispatch(selectCTLImageAction(id));
      history.push(`${routesByName.curateTheLook.canvas}/?tips=active`);
    } else {
      dispatch(selectCTLImageAction(id));
      history.push(routesByName.curateTheLook.index);
    }
  }, [selectedImage, dispatch, id, history, pathname]);

  const handleMoveToGTL = useCallback(() => {
    history.push(routesByName.getTheLook.details(id));
  }, [history, id]);

  const handleOpenSharePopover = useCallback(({ currentTarget }) => {
    setShareAnchorEl(currentTarget);
  }, []);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const handleClickLookBoardsView = useCallback(() => {
    onLookBoardsView(id);
  }, [id, onLookBoardsView]);

  return (
    <InspirationImage
      id={id}
      className={classes.root}
      imgUrl={imgUrl}
      imageSource={imageSource}
      title={title}
      hoverOverlay={
        <>
          <div className="h-100 flex-column flex-vertical-center justify-content-between">
            <div />
            {onLookBoardsView && lookBoardsCount > 0 && (
              <Button
                className={`${classes.lookBoardsModalBtn} chrome-picker`}
                size="xs"
                color="secondary"
                onClick={handleClickLookBoardsView}
                inline
              >
                <SpriteIcon name="living-room" size="xs" className="mr-1" />
                <span>View Look Boards</span>
              </Button>
            )}
            <div className="d-flex flex-column align-items-center">
              <IconButton
                color="secondary"
                size="sm"
                onClick={handleOpenPreview}
                className="mb-4"
              >
                <SpriteIcon name="loupe" size="sm" />
              </IconButton>
              <p className={classes.hoverLabel} onClick={handleSelectImage}>
                Select this Style
              </p>
              <div className="d-flex justify-content-between">
                {showButton && !isCTL && !isMixMatchPage && (
                  <Button
                    size="sm"
                    inline
                    className="mt-3 mr-1"
                    onClick={handleMoveToCTL}
                    style={{
                      background: 'rgba(255, 255, 255, 0.3)',
                      backdropFilter: 'blur(2px)',
                      borderRadius: '200px',
                      padding: '12px 16px',
                      border: 'none',
                      fontFamily: 'Walkway UltraBold',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '16px',
                      textTransform: 'uppercase',
                    }}
                  >
                    <SpriteIcon
                      name="update-arrows"
                      size="sm"
                      className="mr-1"
                    />
                    <span>
                      Curate <br /> this Look
                    </span>
                  </Button>
                )}
                {showButton && !isRTL && !isMixMatchPage && (
                  <Button
                    size="sm"
                    inline
                    className="mt-3"
                    onClick={handleMoveToRTL}
                    style={{
                      background: 'rgba(255, 255, 255, 0.3)',
                      backdropFilter: 'blur(2px)',
                      borderRadius: '200px',
                      padding: '12px 16px',
                      border: 'none',
                      fontFamily: 'Walkway UltraBold',
                      fontWeight: '400',
                      fontSize: '12px',
                      lineHeight: '16px',
                      textTransform: 'uppercase',
                    }}
                  >
                    <SpriteIcon name="living-room" size="sm" className="mr-1" />
                    <span>
                      Request <br /> this Look
                    </span>
                  </Button>
                )}
              </div>
            </div>
            <div className="w-100 d-flex align-items-center">
              <div className="flex-fill text-ellipsis">
                {sourceUrl && <ImageLink url={sourceUrl} />}
              </div>
              <IconButton
                variant="inverted-grey"
                size="sm"
                className="mr-1"
                onClick={handleToggleLike}
              >
                <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
              </IconButton>
              <IconButton
                variant="inverted-grey"
                size="sm"
                className="mr-1"
                onClick={handleOpenSharePopover}
              >
                <SpriteIcon name="share" size="sm" />
              </IconButton>
              {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
              <IconButton
                variant="inverted-grey"
                size="sm"
                onClick={handleMoreOptionsPopupOpen}
                onMouseEnter={handleMoreOptionsPopupOpen}
              >
                <SpriteIcon name="more-vertical" size="sm" />
              </IconButton>
            </div>
          </div>
          <MoreOptionsPopover
            open={isOptionOpen}
            onClose={handleMoreOptionsPopupClose}
            anchorEl={moreOptionsAnchorEl}
            scrollPosition={scrollPosition}
          >
            {!isRTL && (
              <Button
                className={`${moreOptionsClasses.btn} mr-1`}
                size="custom"
                color="default"
                onClick={handleMoveToRTL}
              >
                <SpriteIcon name="update-arrows" size="sm" className="mr-1" />
                <span>Request</span>
              </Button>
            )}
            {!isCTL && (
              <Button
                className={`${moreOptionsClasses.btn} mr-1`}
                size="custom"
                color="default"
                onClick={handleMoveToCTL}
              >
                <SpriteIcon name="living-room" size="sm" className="mr-1" />
                <span>Curate</span>
              </Button>
            )}
            {!isGTL && (
              <Button
                className={`${moreOptionsClasses.btn} mr-1`}
                size="custom"
                color="default"
                style={{ minWidth: 110 }}
                onClick={handleMoveToGTL}
              >
                <SpriteIcon name="living-room" size="sm" className="mr-1" />
                <span>Get the Look</span>
              </Button>
            )}
            <Button
              onClick={handleOnClaimImage}
              className={moreOptionsClasses.btn}
              size="custom"
              color="default"
              style={{ minWidth: 90 }}
            >
              <span>Claim Image</span>
            </Button>
          </MoreOptionsPopover>
          <SharePopover
            anchorEl={shareAnchorEl}
            open={Boolean(shareAnchorEl)}
            onClose={handleCloseSharePopover}
            shareUrl={shareUrl}
          />
        </>
      }
    />
  );
};

ImageItem.propTypes = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  requestId: PropTypes.number,
  hash: PropTypes.string.isRequired,
  isLiked: PropTypes.number.isRequired,
  shareUrl: PropTypes.string.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }),
  onLookBoardsView: PropTypes.func,
  handleBlur: PropTypes.func,
  title: PropTypes.string,
  imageSource: PropTypes.string,
  sourceUrl: PropTypes.string,
  lookBoardsCount: PropTypes.number.isRequired,
  approval: PropTypes.string.isRequired,
  publish: PropTypes.string.isRequired,
};

ImageItem.defaultProps = {
  requestId: null,
  onLookBoardsView: null,
  handleBlur: () => {},
  title: null,
  imageSource: null,
  sourceUrl: null,
  inspirationImageUrl: null,
};

export default ImageItem;
