import {
  requestSourceKeys,
  requestTypeKeys,
  requestSortKeys,
} from 'constants/requestSearchParams';
import { publishStatusKeys } from 'modules/request/constants';

export const myRequestsTabsConfig = [
  {
    name: requestSourceKeys.my,
    title: 'My Requests',
  },
];

export const myRequestsAmbassadorTabsConfig = [
  {
    name: requestSourceKeys.pending,
    title: 'Pending Referred Requests',
  },
  {
    name: requestSourceKeys.completed,
    title: 'Completed Requests',
  },
];

export const myRequestsTypeOptions = [
  {
    label: 'All My Requests',
    value: requestTypeKeys.all,
  },
  {
    label: 'Room Image Requests',
    value: requestTypeKeys.room,
  },
  {
    label: 'Single Item Requests',
    value: requestTypeKeys.single,
  },
];

export const myRequestsSortOptions = [
  {
    label: 'Newest',
    value: requestSortKeys.newest,
  },
  {
    label: 'Oldest',
    value: requestSortKeys.oldest,
  },
  {
    label: 'Highest rated',
    value: requestSortKeys.rated,
  },
];

export const myRequestsPublishTypes = {
  [publishStatusKeys.published]: {
    label: 'Standart Request',
    icon: 'exchange',
  },
  [publishStatusKeys.personal]: {
    label: 'Personal Shopper Request',
    icon: 'sale',
  },
  [publishStatusKeys.notPublish]: {
    label: 'Standart Request',
    icon: 'exchange',
  },
};
