import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { imageTypes } from 'modules/dashboard/constants';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import Button from 'components/ui/Button/Button';
import classes from './ImageDetails.module.scss';

const ImageDetails = ({
  title,
  url,
  type,
  description,
  imgType,
  editMode,
  onEdit,
}) => {
  const itemType = useMemo(
    () => (type === imageTypeKeys.room ? 'Room' : 'Item'),
    [type]
  );

  return (
    <>
      <h2 className={`${classes.title} font-title text-uppercase mb-4`}>
        Image information
      </h2>
      <div className="mb-4 text-xs">
        <div className={`${classes.label} mb-2`}>
          Title<span className="secondary-color">*</span>
        </div>
        <div className="font-semi-bold">{title}</div>
      </div>

      {url && (
        <div className="mb-4 text-xs text-ellipsis">
          <div className={`${classes.label} mb-2`}>
            URL<span className="secondary-color">*</span>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            className="font-semi-bold text-nowrap"
          >
            {url}
          </a>
        </div>
      )}

      <div className="mb-4 text-xs">
        <div className={`${classes.label} mb-2`}>
          Image Type<span className="secondary-color">*</span>
        </div>
        <div className="font-semi-bold">{imageTypes[type]}</div>
      </div>

      <div className="mb-4 text-xs">
        <div className={`${classes.label} mb-2`}>
          {itemType} Type<span className="secondary-color">*</span>
        </div>
        <div className="font-semi-bold">{imgType}</div>
      </div>

      {description && (
        <div className="mb-4 text-xs">
          <div className={`${classes.label} mb-2`}>Description (optional)</div>
          <p className={classes.description}>{description}</p>
        </div>
      )}

      {editMode && (
        <div className="mt-auto text-center">
          <Button onClick={onEdit} className={classes.btn} inline>
            Edit
          </Button>
        </div>
      )}
    </>
  );
};

ImageDetails.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  imgType: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};

ImageDetails.defaultProps = {
  url: null,
  description: null,
};

export default ImageDetails;
