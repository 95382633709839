import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import showcaseClasses from 'modules/dashboard/showcase/Showcase.module.scss';
import { showcaseShape } from './propTypes';
import LookBoardItem from '../getTheLook/components/LookBoardItem';
import useMediaQuery from '../../hooks/useMediaQuery';
import { maxWidthMd } from '../../constants/mediaQueries';

const FeaturedLookBoardsSlider = ({
  slidesToShow,
  showcase,
  noArrows,
  onToggleLike,
  onVote,
  lookBoardsLibrary,
}) => {
  const sliderRef = useRef(null);
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleSlideChange = useCallback((oldIndex, newIndex) => {
    setActiveSlideIndex(newIndex);
  }, []);

  return (
    <div className={showcaseClasses.featuredImageSliderContainer}>
      {!noArrows && (
        <IconButton
          size="sm"
          onClick={handleSlidePrev}
          className={showcaseClasses.imagesPrevBtn}
          disabled={activeSlideIndex === 0}
        >
          <SpriteIcon name="prev-icon" size="xs" />
        </IconButton>
      )}
      <Slider
        className={showcaseClasses.featuredImageSlider}
        ref={sliderRef}
        arrows={false}
        slidesToShow={slidesToShow}
        slidesToScroll={1}
        beforeChange={handleSlideChange}
        infinite={false}
        dots
        customPaging={(i) => <SpriteIcon name="dot" size="xs" key={i} />}
      >
        {showcase.featuredLookBoards.slice(0, 10).map((lookBoardId) => (
          <div
            key={lookBoardId}
            data-id={lookBoardId}
            // onClick={goToLookBoardDetailsMobile}
            className="px-1"
          >
            {lookBoardsLibrary[lookBoardId] ? (
              <LookBoardItem
                lookBoard={lookBoardsLibrary[lookBoardId]}
                user={showcase.user}
                products={lookBoardsLibrary[lookBoardId].products}
                onToggleLike={onToggleLike}
                onVote={onVote}
                isFeaturedSlider
              />
            ) : (
              'Loading...'
            )}
          </div>
        ))}
      </Slider>
      {!noArrows && (
        <IconButton
          size="sm"
          onClick={handleSlideNext}
          className={showcaseClasses.imagesNextBtn}
          disabled={
            matchesMediaQuery
              ? activeSlideIndex === showcase.featuredImages.length - 1
              : activeSlideIndex === showcase.featuredImages.length - 4
          }
        >
          <SpriteIcon name="next-icon" size="xs" />
        </IconButton>
      )}
    </div>
  );
};

FeaturedLookBoardsSlider.propTypes = {
  slidesToShow: PropTypes.number.isRequired,
  noArrows: PropTypes.bool,
  onToggleLike: PropTypes.func.isRequired,
  onVote: PropTypes.func.isRequired,
  showcase: showcaseShape.isRequired,
  lookBoardsLibrary: PropTypes.shape({}).isRequired,
};

FeaturedLookBoardsSlider.defaultProps = {
  noArrows: false,
};
export default FeaturedLookBoardsSlider;
