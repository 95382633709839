import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LookBoardItem from 'modules/requestTheLook/recommendations/components/LookBoardItem/LookBoardItem';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import useLoadMoreCallback from 'hooks/useLoadMoreCallback';
import classes from './LookBoardsList.module.scss';

const limit = 6;

const LookBoardsList = ({
  lookBoards,
  users,
  products,
  onToggleLike,
  onRate,
  handleOpenMessageModal,
}) => {
  const [selectedLookBoard, setSelectedLookBoard] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [hasMore, loading, visible, loadMore] = useLoadMoreCallback(
    limit,
    lookBoards.length
  );

  const selectedLookBoardProducts = useMemo(
    () => selectedLookBoard?.products.map((productId) => products[productId]),
    [selectedLookBoard, products]
  );

  const handlePreviewModalOpen = useCallback(
    ({ currentTarget }) => {
      const lookBoardId = currentTarget.getAttribute('data-id');
      const currentLookBoard = lookBoards.find(
        ({ id }) => id === Number(lookBoardId)
      );

      setSelectedLookBoard(currentLookBoard);
      setPreviewModalOpen(true);
    },
    [lookBoards]
  );

  const handlePreviewModalClose = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  return (
    <>
      <CustomInfiniteScroll
        loadMore={loadMore}
        isLoading={loading}
        hasMore={hasMore}
        initialLoad={false}
      >
        <div className={classes.root}>
          {lookBoards.length > 0 &&
            lookBoards.slice(0, visible).map((lookBoard) => (
              <div key={lookBoard.id} className={classes.item}>
                <LookBoardItem
                  lookBoard={lookBoard}
                  user={users[lookBoard.userId]}
                  products={products}
                  onToggleLike={onToggleLike}
                  onRate={onRate}
                  onPreviewOpen={handlePreviewModalOpen}
                  handleOpenMessageModal={handleOpenMessageModal}
                />
              </div>
            ))}
        </div>
      </CustomInfiniteScroll>
      <BasicModal
        open={previewModalOpen}
        onClose={handlePreviewModalClose}
        fullWidth
        maxWidth="md"
        scroll="body"
        classes={{ root: 'p-0' }}
      >
        <LookBoard
          title={selectedLookBoard?.title}
          color={selectedLookBoard?.color}
          columns={selectedLookBoard?.columns}
          items={selectedLookBoardProducts}
          collapsed={false}
          interactive={false}
        />
      </BasicModal>
    </>
  );
};

LookBoardsList.propTypes = {
  lookBoards: PropTypes.arrayOf(PropTypes.shape({})),
  products: PropTypes.shape({}),
  users: PropTypes.shape({}),
  onToggleLike: PropTypes.func.isRequired,
  onRate: PropTypes.func.isRequired,
  handleOpenMessageModal: PropTypes.func.isRequired,
};

LookBoardsList.defaultProps = {
  lookBoards: null,
  products: null,
  users: null,
};

export default LookBoardsList;
