import React from 'react';
import classes from './NoLookBoardsModal.module.scss';

const NoLookBoardsModal = () => (
  <div className={classes.root}>
    <div className={classes.border}/>
    <div className="flex-center flex-column mx-3">
      <p className={`${classes.title} mb-5`}>
        This user has no
        <br/>
        featured Look Boards yet
      </p>
    </div>
    <div className={classes.border}/>
  </div>
);

export default NoLookBoardsModal;
