import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import stylesOptionShape from 'components/PreferenceQuiz/propTypes/stylesOptionShape';
import Button from 'components/ui/Button/Button';
import StylesFilter from 'components/modals/FiltersModal/components/StylesFilter';

const StylesStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSaveStyles,
}) => {
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(currentValue)) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleChange = useCallback(
    (optionValue, checked) => {
      let valueCopy = [...inputValue];

      if (!checked) {
        valueCopy = valueCopy.filter((item) => item !== optionValue);
      } else if (valueCopy.length < 10) {
        valueCopy.push(optionValue);
      }
      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveStyles = useCallback(() => {
    onSaveStyles(inputValue);
  }, [onSaveStyles, inputValue]);

  return (
    <>
      <StylesFilter
        options={options}
        currentValue={inputValue}
        onChange={handleChange}
      />
      <div className="d-flex justify-content-center pt-3">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveStyles}
          disabled={isLoading}
        >
          Next: Sub Styles
        </Button>
        {onCancelEdit && (
          <Button
            inline
            variant="outlined"
            onClick={onCancelEdit}
            disabled={isLoading}
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};

StylesStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(stylesOptionShape).isRequired,
  onCancelEdit: PropTypes.func,
  onSaveStyles: PropTypes.func.isRequired,
};

StylesStep.defaultProps = {
  currentValue: undefined,
  onCancelEdit: undefined,
};

export default StylesStep;
