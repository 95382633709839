import React from 'react';
import AutoCompleteInput from 'modules/requestTheLook/requestDetails/components/AutoCompleteInput/AutoCompleteInput';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import PriorityItemsOption from 'modules/requestTheLook/requestDetails/components/PriorityItemsOption/PriorityItemsOption';
import Button from 'components/ui/Button/Button';
import clsx from 'clsx';
import { priorityItemShape } from 'modules/requestTheLook/requestDetails/requestForm/propTypes';
import classes from '../../requestForm/RequestForm.module.scss';

// Default value for show min tabs
const defaultShowTabsLimit = 12;

const RoomRequest = ({
  requestPriorityItems,
  itemsForTabs,
  handleChangeShowTabsLimit,
  showTabsLimit,
  handleAddPriorityItem,
  addAnotherItemEnable,
  optionsList,
  handleChangeTabOption,
}) => (
  <>
    <div className="mb-5">
      <div className="text-sm font-semi-bold mr-1 ">
        <>
          Select up to 6 Items
          <span className="color-gray-6">
            ({requestPriorityItems.length} Selected)
          </span>
        </>
      </div>
      <div className={clsx(classes.tabOptions, classes.grid4)}>
        {itemsForTabs.map(({ label, value }, index) => {
          const selected = requestPriorityItems.some(
            ({ value: priorityItemValue }) => priorityItemValue === value
          );
          return index < showTabsLimit ? (
            <PriorityItemsOption
              key={value}
              value={value}
              label={label}
              selected={selected}
              onChange={handleChangeTabOption}
            />
          ) : null;
        })}
      </div>
      {itemsForTabs.length > defaultShowTabsLimit && (
        <div className="mt-2">
          <Button
            variant="default"
            size="xs"
            inline
            type="button"
            onClick={handleChangeShowTabsLimit}
          >
            <span className="mr-1">
              View {showTabsLimit === defaultShowTabsLimit ? 'All' : 'Less'}
            </span>
            <SpriteIcon
              name={
                showTabsLimit === defaultShowTabsLimit
                  ? 'right-arrow'
                  : 'left-arrow'
              }
              size="sm"
            />
          </Button>
        </div>
      )}
    </div>
    <div className="row">
      <div className="col-8">
        <div className="d-flex align-items-center mb-2">
          <div className="text-sm mr-1">
            <span className="font-semi-bold">
              If an item is not shown, you can search all items here:
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <AutoCompleteInput
              value={{ label: '', value: 0 }}
              handleChange={handleAddPriorityItem}
              options={optionsList}
              addAnotherItemEnable={addAnotherItemEnable}
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

RoomRequest.propTypes = {
  requestPriorityItems: PropTypes.arrayOf(priorityItemShape).isRequired,
  itemsForTabs: PropTypes.arrayOf(priorityItemShape).isRequired,
  handleChangeShowTabsLimit: PropTypes.func.isRequired,
  showTabsLimit: PropTypes.number.isRequired,
  handleAddPriorityItem: PropTypes.func.isRequired,
  addAnotherItemEnable: PropTypes.bool.isRequired,
  optionsList: PropTypes.arrayOf(priorityItemShape).isRequired,
  handleChangeTabOption: PropTypes.func.isRequired,
};
export default RoomRequest;
