import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const productService = {
  getProducts(params, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/product/search`,
      params,
      config
    ).then(({ result: list }) =>
      list.map((product) => ({ ...product, price: product.price / 100 }))
    );
  },

  getSimilarProducts(products, config = null) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/products/similar`,
      { products },
      config
    ).then(({ result: list }) => {
      const listKeys = Object.keys(list);
      listKeys.map((index) => {
        return list[index].map((obj) => {
          // eslint-disable-next-line
          return (obj.price = obj.price / 100);
        });
      });
      return list;
    });
  },
  getSimilarProductById(id, params) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/products/similar/${id}`,
      params
    ).then(({ result: list }) =>
      list.map((product) => ({
        ...product,
        price: product.price / 100,
      }))
    );
  },

  getProductsByIds(products) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/products`, {
      products,
    }).then(({ result: list }) => {
      return list.map((product) => ({
        ...product,
        price: product.price / 100,
      }));
    });
  },

  getProductsByLookBoardId(products, lookBoardId) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/productslb`, {
      products,
      lookBoardId,
    }).then(({ result: list }) => {
      return list.map((product) => ({
        ...product,
        price: product.price / 100,
      }));
    });
  },

  getProductById(productId) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/product/${productId}`,
      {}
    ).then(({ result: product }) => ({
      ...product,
      price: product.price / 100,
    }));
  },

  getProductByLookBoardId(productId, lookBoardId) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/product/${productId}/lookboard/${lookBoardId}`,
      {}
    ).then(({ result: product }) => ({
      ...product,
      price: product.price / 100,
    }));
  },

  createProduct(data) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/product`, data).then(
      ({ result }) => result
    );
  },

  deleteProduct(productId) {
    return AuthorizedAxiosInstance.delete(
      `${urlPrefix}/product/${productId}`
    ).then(({ result }) => result);
  },

  toggleLike(productId, isLiked) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/product/${productId}/like`,
      { isLiked }
    );
  },

  rate(productId, rate) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/product/${productId}/rate`,
      { rate }
    );
  },
};

export default productService;
