export const reCaptchaActions = {
  createProduct: 'create_product',
  createImage: 'create_image',
  signUp: 'sign_up',
  becomeAmbassadorForm: 'become_ambassador',
  requestInvitation: 'request_invitation',
  createRequest: 'create_request',
  customerSupport: 'customer_support',
  create_notification_email: 'create_notification_email',
};
