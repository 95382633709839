import contemporary from 'assets/img/personas/contemporary.png';
import shabbyChic from 'assets/img/personas/shabby-chic.png';
import industrial from 'assets/img/personas/industrial.png';
import coastal from 'assets/img/personas/coastal.png';
import farmhouse from 'assets/img/personas/farmhouse.png';
import eclectic from 'assets/img/personas/eclectic.png';
import natural from 'assets/img/personas/natural.png';
import modern from 'assets/img/personas/modern.png';
import rustic from 'assets/img/personas/rustic.png';
import french from 'assets/img/personas/french.png';
import transitional from 'assets/img/personas/transitional.png';
import traditional from 'assets/img/personas/traditional.png';

const usePersonas = (styleQuizValues) => {
  if (!Array.isArray(styleQuizValues) || !styleQuizValues.length) {
    return null;
  }
  const [mainStyle] = styleQuizValues;

  const personasConfig = {
    1: traditional,
    2: transitional,
    3: contemporary,
    4: modern,
    5: farmhouse,
    6: eclectic,
    7: industrial,
    8: shabbyChic,
    9: rustic,
    10: natural,
    11: coastal,
    12: french,
  };
  return personasConfig[mainStyle] || null;
};

export default usePersonas;
