export const redeemPointsFieldKeys = {
  swag: 'swag',
  decorDollars: 'decorDollars',
  giftCard: 'giftCard',
  charityMatch: 'charityMatch',
};

export const errorMessages = {
  multipleHundred: ' ',
};
