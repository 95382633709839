import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import findObjectById from 'utils/findObjectById';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import StylesFilter from 'components/modals/FiltersModal/components/StylesFilter';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import classes from './StylesModal.module.scss';

const StylesModal = ({ open, currentValue, onClose, onApply }) => {
  const styles = useSelector((state) => state.app.enums.styles);

  const [filterValue, setFilterValue] = useState(currentValue);

  useEffect(() => {
    if (!open) {
      setFilterValue(currentValue);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleChangeStyles = useCallback(
    (id, checked) => {
      let currentValueCopy = [...filterValue];
      if (checked) {
        currentValueCopy.push(Number(id));
      } else {
        currentValueCopy = currentValueCopy.filter(
          (value) => value !== Number(id)
        );
      }
      setFilterValue(currentValueCopy);
    },
    [filterValue]
  );

  const handleUnselectOption = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;
      const updatedFilterValue = filterValue.filter(
        (value) => value !== Number(id)
      );
      setFilterValue(updatedFilterValue);
    },
    [filterValue]
  );

  const handleResetStyles = useCallback(() => {
    setFilterValue([]);
  }, []);

  const handleApplyStyles = useCallback(() => {
    onApply(filterValue);
  }, [onApply, filterValue]);

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      scroll="body"
    >
      <h3 className="text-lg font-title text-capitalize text-center mb-3">
        Change favorite styles
      </h3>
      <div className="d-flex mb-4">
        <div
          className="flex-fill d-flex flex-wrap mr-5"
          style={{ minHeight: 40 }}
        >
          {filterValue.map((styleId) => (
            <div className={classes.modalOptionChip} key={styleId}>
              <span className="text-sm font-italic mr-2">
                {findObjectById(styleId, styles)?.name}
              </span>
              <SpriteIcon
                name="cross"
                size="xs"
                className="cursor-pointer"
                data-id={styleId}
                onClick={handleUnselectOption}
              />
            </div>
          ))}
        </div>
        <div className={classes.resetArea}>
          <Button variant="outlined" size="sm" onClick={handleResetStyles}>
            <SpriteIcon name="trash" size="sm" className="mr-1" />
            Reset Everything
          </Button>
        </div>
      </div>
      <StylesFilter
        options={Object.values(styles)}
        currentValue={filterValue}
        onChange={handleChangeStyles}
      />
      <div className="text-center mt-4">
        <Button inline size="sm" onClick={handleApplyStyles}>
          Apply
        </Button>
      </div>
    </BasicModal>
  );
};

StylesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export default StylesModal;
