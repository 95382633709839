import React, {
  useCallback,
  useMemo,
  useState,
  createContext,
  useEffect,
} from 'react';
import SpriteIcon from 'components/ui/SpriteIcon';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, filter } from 'lodash-es';
import { routesByName } from 'constants/routes';
import {
  getCommonItemClassesByRoomId,
  getEnumAsSelectOptions,
  getEnumAsSelectOptionsMap,
  getImageType,
  getItemClassesByStructure,
} from 'modules/app/store/selectors';
import { toggleUnderConstructionModalAction } from 'modules/layout/store/actions';
import { requestFormFields } from 'modules/requestTheLook/requestDetails/requestForm/constants';
import {
  updateRequestDataAction,
  resetRequestFormAction,
  updatePriorityItemAction,
  updateItemsForTabsAction,
  deletePriorityItemAction,
} from 'modules/requestTheLook/store/actions';
import requestFormValidator from 'modules/requestTheLook/requestDetails/requestForm/requestFormValidator';
import ConfirmModal from 'components/modals/ConfirmModal';
import RequestFormComponent from 'modules/requestTheLook/requestDetails/requestForm/RequestFormComponent';
import FilterModal from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterModal';
import RequestTypeInfoModal from 'modules/requestTheLook/components/RequestTypeInfoModal/RequestTypeInfoModal';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import classes from './RequestForm.module.scss';
import { resetImageFilterValuesAction } from '../../../curateTheLook/store/actions';
import findObjectById from '../../../../utils/findObjectById';

export const RequestFormContext = createContext(null);

// Default value for show min tabs
const defaultShowTabsLimit = 12;
const allShowTabsLimit = 100;

const RequestFormContainer = () => {
  const dispatch = useDispatch();

  const enums = useSelector((state) => state.app.enums);
  const currentUser = useSelector((state) => state.auth.user);
  const {
    requestType,
    title: requestTitle,
    items: requestPriorityItems,
    itemsForTabs: requestItemsForTabs,
    inspirationImageId,
    roomType: roomTypeId,
    requestColor,
    requestStyle,
  } = useSelector((state) => state.requestTheLook.requestData);
  const library = useSelector((state) => state.inspirationImage.library);
  const itemClassesOptions = useMemo(
    () => getEnumAsSelectOptions(enums.itemClasses),
    [enums.itemClasses]
  );
  const itemClassesOptionsMap = useMemo(
    () => getEnumAsSelectOptionsMap(enums.itemClasses),
    [enums.itemClasses]
  );
  const commonItemClasses = useMemo(
    () => getCommonItemClassesByRoomId(enums.commonRoomClasses),
    [enums.commonRoomClasses]
  );
  const imgType = useMemo(
    () => getImageType(library[inspirationImageId], enums),
    [enums, library, inspirationImageId]
  );
  const filterModalConfig = useMemo(
    () =>
      getItemClassesByStructure({
        categories: enums.categories,
        itemTypes: enums.itemTypes,
        itemClasses: enums.itemClasses,
      }),
    [enums]
  );

  const { itemClasses } = enums;

  const history = useHistory();
  const { pathname } = useLocation();
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [showTabsLimit, setShowTabsLimit] = useState(defaultShowTabsLimit);
  const [stepComplete, setStepComplete] = useState(false);

  const [roomRequest, singleRequest, makeOverRequest] = useMemo(() => {
    const room = requestType === imageTypeKeys.room;
    const single = requestType === imageTypeKeys.single;
    const makeOver = requestType === imageTypeKeys.makeOver;

    return [room, single, makeOver];
  }, [requestType]);

  const initialFormValues = useMemo(
    () => ({
      [requestFormFields.title]: requestTitle,
      [requestFormFields.requestColor]: requestColor,
    }),
    [requestTitle, requestColor]
  );

  const limit = useMemo(() => (roomRequest || makeOverRequest ? 6 : 1), [
    roomRequest,
    makeOverRequest,
  ]);

  const addAnotherItemEnable = useMemo(
    () => requestPriorityItems.length < limit,
    [requestPriorityItems, limit]
  );

  const optionsList = useMemo(() => {
    return filter(itemClassesOptions, (option, index) => {
      return (
        (filter(requestItemsForTabs, { value: option.value }).length === 0 ||
          (index >= 12 && showTabsLimit === defaultShowTabsLimit)) &&
        !requestPriorityItems.some(
          (priorityItem) => priorityItem.value === option.value
        )
      );
    });
  }, [
    itemClassesOptions,
    requestItemsForTabs,
    requestPriorityItems,
    showTabsLimit,
  ]);

  const handleUnderConstructionModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    dispatch(toggleUnderConstructionModalAction(true));
  }, [currentUser, pathname, history, dispatch]);

  const handleResetModalOpen = useCallback(() => {
    setResetModalOpen(true);
  }, []);

  const handleFilterModalOpen = useCallback(() => {
    setFilterModalOpen(true);
  }, []);

  const handleFilterModalClose = useCallback(() => {
    setFilterModalOpen(false);
  }, []);

  const handleResetForm = useCallback(
    (confirm) => {
      if (confirm) {
        dispatch(resetRequestFormAction());
        const { id, name } = imgType;

        if (singleRequest) {
          dispatch(
            updateRequestDataAction({ items: [{ label: name, value: id }] })
          );
        }
        if (roomRequest || makeOverRequest) {
          const itemsForTabsByRoom = commonItemClasses[
            id
          ]?.map(({ itemClassId }) =>
            findObjectById(itemClassId, itemClassesOptionsMap)
          );

          dispatch(
            updateRequestDataAction({
              itemsForTabs: itemsForTabsByRoom ?? [],
              items: [],
              roomType: id,
            })
          );
        }
      }
      setResetModalOpen(false);
    },
    [
      roomRequest,
      makeOverRequest,
      singleRequest,
      dispatch,
      imgType,
      commonItemClasses,
      itemClassesOptionsMap,
    ]
  );

  const onSubmit = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    history.push(routesByName.requestTheLook.submit);
  }, [currentUser, pathname, history]);

  const onFormChange = useCallback(
    ({ values: { title }, valid }) => {
      setStepComplete(valid);
      if (requestTitle === title || title === '') {
        return;
      }
      dispatch(updateRequestDataAction({ title }));
    },
    [requestTitle, dispatch]
  );

  const debouncedOnFormChange = debounce(onFormChange, 500);

  const handleOnApplyFilterModal = useCallback(
    (checked) => {
      const updatedItems = checked.map((itemClassId) => {
        const { id, name } = itemClasses[itemClassId];
        return { label: name, value: id };
      });
      if (roomRequest || makeOverRequest) {
        const commonItems = commonItemClasses[roomTypeId];

        let itemsForTabs = [];

        if (commonItems) {
          itemsForTabs = commonItemClasses[roomTypeId]
            .map(({ itemClassId }) => itemClassesOptionsMap[itemClassId])
            .filter(({ value: tabValue }) => {
              return (
                !requestPriorityItems.some(
                  ({ value: chosenValue }) => chosenValue === tabValue
                ) &&
                !updatedItems.some(
                  ({ value: chosenValue }) => chosenValue === tabValue
                )
              );
            });
        }

        dispatch(
          updateRequestDataAction({
            items: [...updatedItems],
            itemsForTabs: [...updatedItems, ...itemsForTabs],
          })
        );
      }
      if (singleRequest && updatedItems.length === 1) {
        dispatch(updateRequestDataAction({ items: updatedItems }));
      }

      setFilterModalOpen(false);
    },
    [
      roomRequest,
      makeOverRequest,
      itemClasses,
      dispatch,
      singleRequest,
      requestPriorityItems,
      roomTypeId,
      itemClassesOptionsMap,
      commonItemClasses,
    ]
  );

  const handleChangeTabOption = useCallback(
    (tabValue) => {
      const isValueInItems = requestPriorityItems.some(
        ({ value }) => value === tabValue.value
      );

      if (isValueInItems) {
        dispatch(deletePriorityItemAction(tabValue));
        return;
      }
      if (addAnotherItemEnable) {
        dispatch(updatePriorityItemAction(tabValue));
      }
    },
    [requestPriorityItems, addAnotherItemEnable, dispatch]
  );

  const handleChangeSingleType = useCallback(
    (value) => {
      if (value?.value) {
        dispatch(updateRequestDataAction({ items: [value] }));
      }
    },
    [dispatch]
  );

  const handleOpenInfoModal = useCallback(() => {
    setInfoModalOpen(true);
  }, []);

  const handleCloseInfoModal = useCallback(() => {
    setInfoModalOpen(false);
  }, []);

  const handleChangeShowTabsLimit = useCallback(() => {
    if (showTabsLimit === 12) {
      setShowTabsLimit(allShowTabsLimit);
    } else {
      setShowTabsLimit(12);
    }
  }, [showTabsLimit, setShowTabsLimit]);

  const handleChangeRequestColorAndStyle = useCallback(
    ({ colors, styles }) => {
      dispatch(
        updateRequestDataAction({ requestColor: colors, requestStyle: styles })
      );
    },
    [dispatch]
  );

  const handleChangeRoomType = useCallback(
    ({ target: { value } }) => {
      dispatch(updateRequestDataAction({ roomType: value }));

      if (!commonItemClasses[value]) {
        dispatch(
          updateRequestDataAction({ itemsForTabs: requestPriorityItems })
        );
        return;
      }

      let itemsForTabs = commonItemClasses[value]
        .map(({ itemClassId }) => itemClassesOptionsMap[itemClassId])
        .filter(
          ({ value: tabValue }) =>
            !requestPriorityItems.some(
              ({ value: chosenValue }) => chosenValue === tabValue
            )
        );

      itemsForTabs = [...requestPriorityItems, ...itemsForTabs];
      dispatch(updateRequestDataAction({ itemsForTabs }));
    },
    [dispatch, requestPriorityItems, itemClassesOptionsMap, commonItemClasses]
  );

  const handleAddPriorityItem = useCallback(
    (value) => {
      if (value.value) {
        if (requestItemsForTabs.some((item) => item.value === value.value)) {
          dispatch(updatePriorityItemAction(value));
          setShowTabsLimit(allShowTabsLimit);
          return;
        }
        dispatch(updatePriorityItemAction(value));
        dispatch(updateItemsForTabsAction(value));
      }
    },
    [dispatch, setShowTabsLimit, requestItemsForTabs]
  );

  const handleUpdateMessage = useCallback(
    (message) => {
      dispatch(updateRequestDataAction({ message }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (singleRequest && !requestPriorityItems.length) {
      const { id, name } = imgType;
      dispatch(
        updateRequestDataAction({ items: [{ label: name, value: id }] })
      );
    }
    if (roomRequest || makeOverRequest) {
      const { id } = imgType;

      if (!roomTypeId) {
        dispatch(updateRequestDataAction({ roomType: id }));
      }
      if (!requestPriorityItems.length && commonItemClasses[id]) {
        const itemsForTabs = commonItemClasses[id].map(
          ({ itemClassId }) => itemClassesOptionsMap[itemClassId]
        );

        dispatch(updateRequestDataAction({ itemsForTabs }));
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className={clsx(classes.root, 'position-relative')}>
      <div
        className={clsx(
          createLookBoardClasses.stepTitle,
          classes.step,
          'd-inline-flex mb-4'
        )}
      >
        {stepComplete && (
          <SpriteIcon name="checked" size="sm" className="mr-1 primary-color" />
        )}
        <span className="flex-fill mr-1">Step 3: Add Request Details</span>
      </div>
      <RequestFormContext.Provider value={handleFilterModalOpen}>
        <RequestFormComponent
          initialValues={initialFormValues}
          onSubmit={onSubmit}
          onFormChange={debouncedOnFormChange}
          validateForm={requestFormValidator}
          handleUnderConstructionModalOpen={handleUnderConstructionModalOpen}
          handleAddPriorityItem={handleAddPriorityItem}
          updateItemsForTabs={(e) => dispatch(updateItemsForTabsAction(e))}
          handleChangeTabOption={handleChangeTabOption}
          handleChangeSingleType={handleChangeSingleType}
          handleOpenInfoModal={handleOpenInfoModal}
          handleChangeShowTabsLimit={handleChangeShowTabsLimit}
          handleResetModalOpen={handleResetModalOpen}
          roomType={roomTypeId}
          requestColor={requestColor}
          requestStyle={requestStyle}
          showTabsLimit={showTabsLimit}
          imgType={imgType}
          requestType={requestType}
          addAnotherItemEnable={addAnotherItemEnable}
          optionsList={optionsList}
          requestPriorityItems={requestPriorityItems}
          handleChangeRoomType={handleChangeRoomType}
          handleChangeRequestStyleAndColor={handleChangeRequestColorAndStyle}
          onMessageChange={handleUpdateMessage}
          resetImageFilterValues={() =>
            dispatch(resetImageFilterValuesAction())
          }
        />
      </RequestFormContext.Provider>
      <ConfirmModal
        open={resetModalOpen}
        onClose={handleResetForm}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">reset</span> form?
          </>
        }
      />
      <FilterModal
        checkedItems={requestPriorityItems}
        items={filterModalConfig}
        open={filterModalOpen}
        onClose={handleFilterModalClose}
        onApply={handleOnApplyFilterModal}
        limit={limit}
      />
      <RequestTypeInfoModal
        type={requestType}
        open={infoModalOpen}
        onClose={handleCloseInfoModal}
      />
    </div>
  );
};

export default RequestFormContainer;
