import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import {
  getImageType,
  getStyleNames,
  getInspirationImgUrl,
  getRoomTypeName,
  getUserAvatarUrl,
} from 'modules/app/store/selectors';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import buildResourceUrl from 'utils/buildResourceUrl';
import { userShape } from 'modules/currentUser/propTypes';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import useCurrentUserAvatarUrl from 'hooks/useCurrentUserAvatarUrl';
import useItemClassesByIds from 'modules/app/hooks/useItemClassesByIds';
import Button from 'components/ui/Button/Button';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';
import AmbassadorInfo from 'modules/requestTheLook/ambassadorMatch/components/AmbassadorInfo/AmbassadorInfo';
import classes from './AmbassadorMatch.module.scss';
import currentUserService from '../../currentUser/currentUserService';

const AmbassadorMatchComponent = ({
  inspirationImageUrl,
  request: { title, priorityItems, createdAt, message, userId },
  styles,
  onCurate,
  onDecline,
  onMessage,
  inspirationImage,
  user: { firstName, lastName, displayName },
  roomType,
  requesterCountry,
  requesterAvatar,
}) => {
  //  this means that the request was created 24h ago
  const [requestTimeOver, setRequestTimeOver] = useState(false);
  const [requesterFirstName, setRequesterFirstName] = useState('');
  const [requesterLastName, setRequesterLastName] = useState('');
  const [requesterImage, setRequesterImage] = useState(null);

  const avatarUrl = useCurrentUserAvatarUrl();
  const imgUrl = useMemo(
    () =>
      buildResourceUrl(
        inspirationImageUrl.medium,
        inspirationImage?.media.userId,
        inspirationImage?.media.hash
      ),
    [inspirationImageUrl, inspirationImage]
  );

  const priorityItemsList = useItemClassesByIds(priorityItems);
  const itemType = useMemo(
    () => (inspirationImage?.type === imageTypeKeys.room ? 'Room' : 'Item'),
    [inspirationImage]
  );

  useEffect(() => {
    async function getRequestUserById() {
      try {
        const requestUser = await currentUserService.getUserById(userId);
        if (requestUser.avatar) {
          setRequesterImage(
            buildResourceUrl(
              requesterAvatar,
              requestUser.avatar.userId,
              requestUser.avatar.hash
            )
          );
        }
        setRequesterFirstName(requestUser.firstName);
        setRequesterLastName(requestUser.lastName);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    }
    getRequestUserById();
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    setRequestTimeOver(
      (new Date().getTime() - new Date(createdAt).getTime()) / 3600000 > 24
    );
  }, [createdAt]);

  return inspirationImage ? (
    <div className="d-flex">
      <div className="mr-3 d-flex flex-column">
        <h1 className={classes.title}>Get The Look Request</h1>
        <div className={clsx(classes.previewWrapper, 'd-flex flex-fill')}>
          <div className={classes.imageDetailsWrapper}>
            <ImageDetails
              imgUrl={imgUrl}
              url={inspirationImage?.url}
              title={inspirationImage?.title}
              showInfo
            />
          </div>
          <DetailsPreview
            title={title}
            requestType={inspirationImage?.type}
            styles={styles}
            priorityItems={priorityItemsList}
            itemType={itemType}
            roomType={roomType}
            message={message}
            country={requesterCountry}
            requesterFirstName={requesterFirstName}
            requesterLastName={requesterLastName}
            requesterImage={requesterImage}
          >
            {requestTimeOver ? (
              <div className={classes.saveLater}>
                <div className={clsx(classes.saveLaterText, classes.more)}>
                  Recommendations <b>have been sent.</b> You can still curate
                  this image for our <b>Get the Look section.</b>
                </div>
              </div>
            ) : (
              <div className={classes.saveLater}>
                <div className={classes.saveLaterText}>
                  Select “Curate Now” if you are able to fulfill this Request
                  <b> within 24 hours</b>
                </div>
                <div className={classes.saveLaterRight}>
                  <div className={classes.saveLaterDecor}>or</div>
                  <Link to={routesByName.dashboard.requests}>
                    <Button
                      className={clsx(classes.saveLaterBtn, 'mb-1')}
                      size="sm"
                      variant="outlined"
                    >
                      Save For Later
                    </Button>
                  </Link>
                </div>
              </div>
            )}
            <div className="mt-auto">
              <div className="d-flex align-items-center">
                <Button onClick={onCurate} className="mr-2" inline size="sm">
                  Curate Now
                </Button>
                <Button
                  onClick={onDecline}
                  className="mr-2"
                  inline
                  size="sm"
                  variant="outlined"
                >
                  Decline Request
                </Button>
                <IconButton
                  onClick={onMessage}
                  className="mr-1"
                  variant="inverted-grey"
                >
                  <SpriteIcon name="envelope" size="sm" />
                </IconButton>
              </div>
            </div>
          </DetailsPreview>
        </div>
      </div>
      <div className={classes.rightPanel}>
        <h2 className={classes.titleMatch}>You’re a match!</h2>
        <AmbassadorInfo
          firstName={firstName}
          lastName={lastName}
          displayName={displayName}
          avatarUrl={avatarUrl}
        />
      </div>
    </div>
  ) : null;
};

AmbassadorMatchComponent.propTypes = {
  request: PropTypes.shape({
    title: PropTypes.string.isRequired,
    priorityItems: PropTypes.arrayOf(PropTypes.number).isRequired,
    createdAt: PropTypes.string.isRequired,
    message: PropTypes.string,
    userId: PropTypes.number,
  }).isRequired,
  imgType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  styles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCurate: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  inspirationImageUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  inspirationImage: inspirationImageShape,
  user: userShape.isRequired,
  roomType: PropTypes.string,
  requesterCountry: PropTypes.string.isRequired,
  requesterAvatar: PropTypes.string.isRequired,
};

AmbassadorMatchComponent.defaultProps = {
  inspirationImage: null,
  roomType: null,
};

const mapStateToProps = (
  {
    app: { enums, config },
    auth: {
      user: { preferences, ...user },
    },
    inspirationImage: { library },
  },
  { request: { imageId, countryId } }
) => ({
  imgType: getImageType(library[imageId] ?? {}, enums),
  styles: getStyleNames(library[imageId]?.styles ?? [], enums),
  inspirationImageUrl: getInspirationImgUrl(config),
  requesterAvatar: getUserAvatarUrl(config),
  inspirationImage: library[imageId],
  user,
  roomType: getRoomTypeName(library[imageId].roomTypeId, enums),
  requesterCountry: enums.countries[countryId].name,
});

export default connect(mapStateToProps)(AmbassadorMatchComponent);
