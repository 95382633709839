import React from 'react';
import PropTypes from 'prop-types';
import descriptionOptionShape from 'components/PreferenceQuiz/propTypes/descriptionOptionShape';
import ProfessionCard from 'components/modals/FiltersModal/components/ProfessionCard/ProfessionCard';

const ProfessionFilter = ({ currentValue, onChange, options }) => {
  return (
    <>
      <div className="row mb-3">
        {options.map((option) => (
          <div className="col-4" key={option.id}>
            <ProfessionCard
              option={option}
              currentValue={currentValue}
              onSelect={onChange}
            />
          </div>
        ))}
      </div>
    </>
  );
};

ProfessionFilter.propTypes = {
  options: PropTypes.arrayOf(descriptionOptionShape).isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
};

ProfessionFilter.defaultProps = {
  currentValue: undefined,
};

export default ProfessionFilter;
