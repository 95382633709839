import React, { useCallback, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routesByName } from 'constants/routes';
import CopyrightInfringement from 'modules/report/components/CopyrightInfringement/CopyrightInfringement';
import GetCredit from 'modules/report/components/GetCredit/GetCredit';
import errorToastr from 'libs/toastr/errorToastr';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { reportReasonTypes } from 'modules/layout/ClaimImageModal/constants';
import successToastr from 'libs/toastr/successToastr';

const ReportContainer = () => {
  const { reason, subReason, imageId } = useSelector((state) => state.report);
  const library = useSelector((state) => state.inspirationImage.library);
  const currentImage = useMemo(() => library[imageId], [imageId, library]);

  const sendReport = useCallback(
    async (formValues) => {
      if (!reason || !subReason) {
        errorToastr('Error', "Reason or subReason doesn't provided");
        return;
      }

      try {
        await inspirationImageService.reportImage(currentImage.id, {
          [reportReasonTypes.reportReason]: reason,
          [reportReasonTypes.reportSubReason]: subReason,
          ...formValues,
        });
        successToastr('Success', 'You are successfully sent the report');
      } catch (e) {
        errorToastr(e.generalError);

        // eslint-disable-next-line consistent-return
        return { ...e.validationErrors };
      }

      // eslint-disable-next-line consistent-return
      return true;
    },
    [reason, subReason, currentImage]
  );

  return (
    <Switch>
      <Route exact path={routesByName.report.copyrightInfringement}>
        <CopyrightInfringement
          currentImage={currentImage}
          onReport={sendReport}
        />
      </Route>
      <Route exact path={routesByName.report.getCredit}>
        <GetCredit currentImage={currentImage} onReport={sendReport} />
      </Route>
    </Switch>
  );
};

export default ReportContainer;
