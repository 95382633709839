import Badge from '@material-ui/core/Badge';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import {
  navLinksArrayOf,
  infoMenuShape,
  infoMenuItemsArrayOf,
} from 'modules/layout/SideNav/propTypes';
import SpriteIcon from 'components/ui/SpriteIcon';
import InfoMenu from 'modules/layout/SideNav/InfoMenu/InfoMenu';
import classes from './SideNav.module.scss';
import { SidebarTooltip } from './SidebarTooltip';

const SideNav = ({
  navLinks,
  isAmbassador,
  ambassadorNavLinks,
  infoMenuConfig,
  ambassadorInfoMenuItems,
  unreadConversationsCount,
}) => {
  const [infoMenuOpen, setInfoMenuOpen] = useState(false);

  const handleOpenInfoMenu = useCallback(() => {
    setInfoMenuOpen(true);
  }, []);

  const handleCloseInfoMenu = useCallback(() => {
    setInfoMenuOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      {infoMenuConfig && (
        <SidebarTooltip text="Help">
          <div
            className={clsx(classes.navLink, {
              [classes.active]: infoMenuOpen,
            })}
            onClick={handleOpenInfoMenu}
          >
            <SpriteIcon name="info" />
          </div>
        </SidebarTooltip>
      )}
      <div>
        {isAmbassador &&
          ambassadorNavLinks &&
          ambassadorNavLinks.map(({ route, title, miniTitle, icon }) => (
            <SidebarTooltip text={title} key={route}>
              <NavLink
                to={route}
                className={clsx(classes.navLink, classes.ambassadorNavLink)}
                activeClassName={clsx({ [classes.active]: !infoMenuOpen })}
                onClick={handleCloseInfoMenu}
              >
                {miniTitle && (
                  <div className={classes.miniTitle}>{miniTitle}</div>
                )}
                <SpriteIcon name={icon} size="lg" />
              </NavLink>
            </SidebarTooltip>
          ))}
      </div>
      {navLinks.map(({ route, title, icon, miniInfo = false, ...props }) => (
        <SidebarTooltip text={title} key={route}>
          <NavLink
            to={route}
            className={classes.navLink}
            activeClassName={clsx({ [classes.active]: !infoMenuOpen })}
            onClick={handleCloseInfoMenu}
          >
            {miniInfo ? (
              <Badge
                color="error"
                badgeContent={
                  miniInfo === 'messages' ? unreadConversationsCount : null
                }
                showZero={false}
              >
                <SpriteIcon name={icon} size="lg" {...props} />
              </Badge>
            ) : (
              <SpriteIcon name={icon} size="lg" {...props} />
            )}
          </NavLink>
        </SidebarTooltip>
      ))}
      {infoMenuConfig && (
        <InfoMenu
          isAmbassador={isAmbassador}
          config={infoMenuConfig}
          ambassadorConfig={ambassadorInfoMenuItems}
          isOpen={infoMenuOpen}
          onClose={handleCloseInfoMenu}
        />
      )}
    </div>
  );
};

SideNav.propTypes = {
  navLinks: navLinksArrayOf.isRequired,
  ambassadorNavLinks: navLinksArrayOf,
  isAmbassador: PropTypes.bool,
  infoMenuConfig: infoMenuShape,
  ambassadorInfoMenuItems: infoMenuItemsArrayOf,
  unreadConversationsCount: PropTypes.number,
};

SideNav.defaultProps = {
  ambassadorNavLinks: null,
  isAmbassador: false,
  infoMenuConfig: null,
  ambassadorInfoMenuItems: null,
  unreadConversationsCount: null,
};

export default SideNav;
