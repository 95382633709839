export const approvalStatusKeys = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
};

export const publishStatusKeys = {
  notPublish: 'notPublish',
  published: 'published',
  personal: 'personal',
};
