export const LAYOUT_TOGGLE_ADD_IMAGE_MODAL = 'LAYOUT_TOGGLE_ADD_IMAGE_MODAL';
export const LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL =
  'LAYOUT_TOGGLE_UNDER_CONSTRUCTION_MODAL';
export const LAYOUT_TOGGLE_TERMS_MODAL = 'LAYOUT_TOGGLE_TERMS_MODAL';
export const LAYOUT_TOGGLE_CHOOSE_METHOD_MODAL =
  'LAYOUT_TOGGLE_CHOOSE_METHOD_MODAL';
export const LAYOUT_TOGGLE_HOW_IT_WORKS_MODAL =
  'LAYOUT_TOGGLE_HOW_IT_WORKS_MODAL';
export const LAYOUT_TOGGLE_VERIFICATION_MODAL =
  'LAYOUT_TOGGLE_VERIFICATION_MODAL';
export const LAYOUT_TOGGLE_PIN_MEDIA_MODAL = 'LAYOUT_TOGGLE_PIN_MEDIA_MODAL';
export const LAYOUT_SUGGEST_STYLE_QUIZ = 'LAYOUT_SUGGEST_STYLE_QUIZ';
export const LAYOUT_TOGGLE_STYLE_QUIZ = 'LAYOUT_TOGGLE_STYLE_QUIZ';
export const SET_STYLE_QUIZ_DATA = 'SET_STYLE_QUIZ_DATA';
export const LAYOUT_TOGGLE_QUIZ_VIEWED = 'LAYOUT_TOGGLE_QUIZ_VIEWED';
export const LAYOUT_TOGGLE_HOW_IT_WORKS_VIDEO_MODAL =
  'LAYOUT_TOGGLE_HOW_IT_WORKS_VIDEO_MODAL';
export const LAYOUT_TOGGLE_PREFERENCES_REMIND_MODAL =
  'LAYOUT_TOGGLE_PREFERENCES_REMIND_MODAL';
export const LAYOUT_TOGGLE_YOUTUBE_VIDEO_MODAL =
  'LAYOUT_TOGGLE_YOUTUBE_VIDEO_MODAL';
