const findDifferentFields = (obj1, obj2) => {
  const differentFields = [];
  // eslint-disable-next-line
  for (const key in obj1) {
    // eslint-disable-next-line
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        differentFields.push(key);
      }
    }
  }
  // eslint-disable-next-line
  for (const key in obj2) {
    // eslint-disable-next-line
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      differentFields.push(key);
    }
  }

  return differentFields;
};
export default findDifferentFields;
