import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import classes from './DashboardMenu.module.scss';

const DashboardMenu = ({ open, onClose, anchorEl, onMouseEnter }) => {
  const user = useSelector((state) => state.auth.user);
  const isAmbassador = useMemo(() => Boolean(user?.ambassador), [user]);

  const theme = createMuiTheme({});

  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        onClose(e);
      },
      onMouseEnter,
    },
  };

  const menuItems = useMemo(() => {
    return [
      ...(isAmbassador
        ? [
            {
              title: 'Ambassador Info',
              link: routesByName.dashboard.ambassadorManagement,
            },
            {
              title: 'Showcase Setup',
              link: routesByName.dashboard.ambassadorShowcase,
            },
            {
              title: 'Nominees',
              link: routesByName.dashboard.ambassadorTeam,
            },
            {
              title: 'My Points',
              link: routesByName.dashboard.points,
            },
          ]
        : []),
      {
        title: 'My Images',
        link: routesByName.dashboard.images,
      },
      {
        title: 'My Look Boards',
        link: routesByName.dashboard.lookBoards,
      },
      {
        title: 'My Requests',
        link: routesByName.dashboard.requests,
      },
      {
        title: 'My Followers',
        link: routesByName.dashboard.community,
      },
    ];
  }, [isAmbassador]);

  return (
    <ThemeProvider theme={theme}>
      <Menu
        anchorEl={anchorEl}
        open={open}
        id="dashboardMenu"
        onClose={onClose}
        getContentAnchorEl={null}
        classes={{ list: classes.menuList }}
        PopoverClasses={{ paper: classes.menuPaper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          {menuItems.map((item) => (
            <MenuItem
              onClick={onClose}
              className={classes.menuItem}
              key={item.title}
            >
              <Link to={item.link}>{item.title}</Link>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </ThemeProvider>
  );
};

DashboardMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

DashboardMenu.defaultProps = {
  anchorEl: null,
};

export default DashboardMenu;
