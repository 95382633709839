import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import errorToastr from 'libs/toastr/errorToastr';
import UserListItem from './components/UserListItem';
import { loadFirstAction, loadMoreAction } from './store/actions';

const UsersListView = () => {
  const dispatch = useDispatch();
  const { hasMore, loading, usersList, usersSearchParams } = useSelector(
    (state) => state.userPage
  );

  const handleLoadMore = useCallback(async () => {
    try {
      await dispatch(loadMoreAction());
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (usersSearchParams.offset === 0) {
        try {
          await dispatch(loadFirstAction());
        } catch (e) {
          errorToastr('Error', e.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, [usersSearchParams]);

  return (
    <div>
      <CustomInfiniteScroll
        isLoading={loading}
        hasMore={hasMore}
        loadMore={handleLoadMore}
        initialLoad={false}
        threshold={500}
      >
        {usersList.map((item) => (
          <UserListItem key={item.id} user={item} />
        ))}
      </CustomInfiniteScroll>
    </div>
  );
};

export default UsersListView;
