export const createImageFieldKeys = {
  type: 'type',
  title: 'title',
  roomType: 'roomType',
  itemClassId: 'itemClassId',
  mediaId: 'mediaId',
  ownership: 'ownership',
  permissions: 'permissions',
  siteSourceUrl: 'siteSourceUrl',
  sourceUrl: 'sourceUrl',
  description: 'description',
  withDrop: 'withDrop',
};

export const createProductFieldKeys = {
  name: 'name',
  price: 'price',
  itemClassId: 'itemClassId',
  mediaId: 'mediaId',
  url: 'url',
  description: 'description',
  size: 'size',
};

export const imageTypeValuesMap = {
  room: 'room',
  single: 'single',
};

export const ownershipValuesMap = {
  selfOwned: 'selfOwned',
  thirdPartyOwned: 'thirdPartyOwned',
};

export const permissionsValuesMap = {
  open: 'open',
  exclusive: 'exclusive',
};

export const imageTypeOptions = [
  {
    value: imageTypeValuesMap.room,
    label: 'Room',
  },
  {
    value: imageTypeValuesMap.single,
    label: 'Single Item',
  },
];

export const validationMessages = {
  required: 'This field is required',
  urlNotMatch: 'This field must be a valid url',
  maxLength: (maxLength) => `Maximum number of characters ${maxLength}`,
  moreThanZero: 'The price must be more than 0',
  tooHighValue: 'The value is too high',
  shouldBeInteger: 'The value should be integer',
  incorrectValue: 'Incorrect value',
};

export const requestAdminFieldKeys = {
  url: 'url',
  message: 'message',
};

export const extensionAddress = `https://chrome.google.com/webstore/detail/pin-the-look-extension/naancmhnjhhidaeommjadnnjhdbdmcgm`;
