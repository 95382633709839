import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  imageTypeValuesMap,
  createImageFieldKeys,
  ownershipValuesMap,
  permissionsValuesMap,
} from 'modules/pinMedia/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import RadioInputWrapper from 'components/finalFormWrappers/RadioInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import {
  getEnumAsSelectOptions,
  // getItemClassesWithItemTypes,
} from 'modules/app/store/selectors';
import { requestTargetRoomTypes } from 'modules/requestTheLook/requestDetails/requestForm/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import OwnershipInfoPopup from 'modules/pinMedia/OwnershipInfoPopup/OwnershipInfoPopup';
import classes from 'modules/pinMedia/CreateImageForm/CreateImageForm.module.scss';
import checkboxInputWrapper from 'components/finalFormWrappers/CheckboxInputWrapper';

const initialFormValues = {
  [createImageFieldKeys.ownership]: ownershipValuesMap.thirdPartyOwned,
  [createImageFieldKeys.permissions]: permissionsValuesMap.open,
};

const CreateImageForm = ({
  validate,
  onSubmit,
  sourceUrl,
  imageTitle,
  withDrop,
  manualSourceUrl,
  pinImage,
}) => {
  const {
    roomTypes: roomTypesEnum,
    // itemClasses: itemClassesEnum,
    // itemTypes: itemTypesEnum,
  } = useSelector((state) => state.app.enums);
  const roomTypes = useMemo(
    () =>
      getEnumAsSelectOptions(roomTypesEnum)
        .filter((room) => requestTargetRoomTypes.includes(room.label))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [roomTypesEnum]
  );
  // const itemClasses = useMemo(
  //   () =>
  //     getItemClassesWithItemTypes({
  //       itemTypes: itemTypesEnum,
  //       itemClasses: itemClassesEnum,
  //     }),
  //   [itemTypesEnum, itemClassesEnum]
  // );

  // const [autoCompleteValue, setAutoCompleteValue] = useState(itemClasses[0]);
  const [formValues, setFormValues] = useState({
    ...initialFormValues,
    sourceUrl,
  });
  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  useEffect(() => {
    if (!withDrop) {
      setFormValues((prevState) => ({ ...prevState, sourceUrl }));
    }
  }, [sourceUrl, withDrop]);

  const handleOpenInfoPopup = useCallback((event) => {
    event.preventDefault();
    setInfoPopupOpen(true);
  }, []);

  const handleCloseInfoPopup = useCallback(() => {
    setInfoPopupOpen(false);
  }, []);

  // const handleChangeItemClass = useCallback((option, formValuesData) => {
  //   if (option?.value) {
  //     setAutoCompleteValue(option);
  //     setFormValues(() => ({
  //       ...formValuesData,
  //       [createImageFieldKeys.itemClassId]: option.value,
  //     }));
  //   }
  // }, []);

  return (
    <>
      <Form
        initialValues={formValues}
        onSubmit={onSubmit}
        validate={(values) => validate(values, imageTypeValuesMap.room)}
        render={({ handleSubmit, values }) => (
          <form
            className="h-100 d-flex flex-column"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="flex-fill">
              <Field
                label="Title"
                disabled={pinImage}
                required
                name={createImageFieldKeys.title}
                initialValue={imageTitle}
                component={TextInputWrapper}
              />
              {!withDrop && sourceUrl && (
                <Field
                  label="Image URL"
                  disabled
                  name={createImageFieldKeys.sourceUrl}
                  component={TextInputWrapper}
                  InputProps={{
                    classes: { disabled: classes.disabledInput },
                  }}
                />
              )}
              {(withDrop || manualSourceUrl) && (
                <>
                  <Field
                    label="Source URL"
                    type="url"
                    disabled={!withDrop}
                    name={createImageFieldKeys.siteSourceUrl}
                    component={TextInputWrapper}
                    InputProps={{
                      classes: { disabled: classes.disabledInput },
                    }}
                    initialValue={manualSourceUrl}
                  />
                  <Field
                    name={createImageFieldKeys.withDrop}
                    type="checkbox"
                    style={{ display: 'none' }}
                    defaultValue={withDrop}
                    component={checkboxInputWrapper}
                  />
                </>
              )}
              {/* <Field */}
              {/*  name={createImageFieldKeys.type} */}
              {/*  label="Image Type" */}
              {/*  required */}
              {/*  component={SelectInputWrapper} */}
              {/*  options={imageTypeOptions} */}
              {/* /> */}
              {/* {values.type === imageTypeValuesMap.room && ( */}
              <Field
                name={createImageFieldKeys.roomType}
                label="Room Type"
                required
                component={SelectInputWrapper}
                options={roomTypes}
              />
              {/* )} */}
              {/* {values.type === imageTypeValuesMap.single && ( */}
              {/*  <Field */}
              {/*    name={createImageFieldKeys.itemClassId} */}
              {/*    component={() => { */}
              {/*      return ( */}
              {/*        <AutoCompleteInput */}
              {/*          groupBy={(option) => option.typeName} */}
              {/*          value={autoCompleteValue} */}
              {/*          options={itemClasses} */}
              {/*          handleChange={handleChangeItemClass} */}
              {/*          formValues={values} */}
              {/*          placeholder="Item Class" */}
              {/*          label="Item Class (Optional)" */}
              {/*        /> */}
              {/*      ); */}
              {/*    }} */}
              {/*  /> */}
              {/* )} */}
              <Field
                label="Description (optional)"
                name={createImageFieldKeys.description}
                multiline
                rows={5}
                maxLength={160}
                component={TextInputWrapper}
              />
              <FormControl component="fieldset" className="mb-3">
                <RadioGroup>
                  <FormControlLabel
                    style={{ color: 'red' }}
                    label={
                      <div className="d-flex align-items-center">
                        <span className="mr-1">I found this image online</span>
                      </div>
                    }
                    classes={{
                      label: clsx({
                        'font-semi-bold':
                          values.ownership ===
                          ownershipValuesMap.thirdPartyOwned,
                      }),
                    }}
                    control={
                      <Field
                        name={createImageFieldKeys.ownership}
                        type="radio"
                        value={ownershipValuesMap.thirdPartyOwned}
                        component={RadioInputWrapper}
                      />
                    }
                  />
                  <FormControlLabel
                    classes={{
                      label: clsx({
                        'font-semi-bold':
                          values.ownership === ownershipValuesMap.selfOwned,
                      }),
                    }}
                    control={
                      <Field
                        name={createImageFieldKeys.ownership}
                        type="radio"
                        value={ownershipValuesMap.selfOwned}
                        component={RadioInputWrapper}
                      />
                    }
                    label={
                      <div className="d-flex align-items-center">
                        <span className="mr-1">I own this image</span>
                        <SpriteIcon
                          name="info"
                          size="sm"
                          className="secondary-color"
                          onClick={handleOpenInfoPopup}
                        />
                      </div>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {values.ownership === ownershipValuesMap.selfOwned && (
                <FormControl component="fieldset">
                  <FormLabel>
                    <p className={classes.permissionsLabel}>Image Usage</p>
                    <p className={classes.permissionsDescription}>
                      As the image owner, you can choose if you’d like it to be
                      eligible for our Inspiration Image Database or you can use
                      it exclusively for your Look Boards. It’s your choice!
                    </p>
                  </FormLabel>
                  <RadioGroup>
                    <FormControlLabel
                      label={
                        <span className={classes.permissionsLabelDesc}>
                          <b className="text-sm">Open:</b> Eligible to include
                          in the inspiration image database for anyone to curate
                          Look Boards
                        </span>
                      }
                      control={
                        <Field
                          name={createImageFieldKeys.permissions}
                          type="radio"
                          value={permissionsValuesMap.open}
                          component={RadioInputWrapper}
                        />
                      }
                      style={{ padding: '5px 0' }}
                    />
                    <FormControlLabel
                      control={
                        <Field
                          name={createImageFieldKeys.permissions}
                          type="radio"
                          value={permissionsValuesMap.exclusive}
                          component={RadioInputWrapper}
                        />
                      }
                      label={
                        <span className={classes.permissionsLabelDesc}>
                          <b className="text-sm">Exclusive:</b> Do not include
                          in the inspiration database. Only I can curate Look
                          Boards for this image.
                        </span>
                      }
                      style={{ padding: '5px 0 10px' }}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <div className={classes.submitBtnContainer}>
                <FinalFormSubmitButton color="secondary" size="sm">
                  Select
                </FinalFormSubmitButton>
              </div>
            </div>
          </form>
        )}
      />
      <OwnershipInfoPopup open={infoPopupOpen} onClose={handleCloseInfoPopup} />
    </>
  );
};

CreateImageForm.propTypes = {
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sourceUrl: PropTypes.string,
  manualSourceUrl: PropTypes.string,
  imageTitle: PropTypes.string,
  withDrop: PropTypes.bool,
  pinImage: PropTypes.bool,
};

CreateImageForm.defaultProps = {
  sourceUrl: undefined,
  manualSourceUrl: undefined,
  imageTitle: null,
  withDrop: false,
  pinImage: false,
};

export default CreateImageForm;
