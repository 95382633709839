import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { EmailShareButton } from 'react-share';
import SpriteIcon from 'components/ui/SpriteIcon';
import successToastr from 'libs/toastr/successToastr';
import appConfig from 'constants/appConfig';
import HeaderTooltip from 'modules/layout/Header/HeaderTooltip';
import showcaseProfile from 'assets/img/dashboard-page/showcase/pin-card.jpg';
import classes from './LookBoardShare.module.scss';
import { tooltipShareText } from './constants';
import InfoIconPopover from '../ui/InfoIconPopover/InfoIconPopover';
import AmbassadorCard from '../userThumbnails/AmbassadorCard/AmbassadorCard';

const sizeToClass = {
  xs: classes.xs,
  sm: classes.sm,
};

const packageIconSizes = {
  xs: 16,
  sm: 20,
};

const LookBoardShare = ({
  iconSize,
  pageUrl,
  lookBoardId,
  isShowcase,
  showcase,
}) => {
  const handleCopyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(pageUrl);
    successToastr('Success', 'Link was successfully copied');
  }, [pageUrl]);

  const handleDownloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.href = showcaseProfile;
    link.download = 'showcaseProfile.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const handleDownloadImageByLink = useCallback(() => {
    const link = document.createElement('a');
    link.href = `${appConfig.apiUrl}/v1/showcase/download/${showcase.userId}`;
    link.download = `${showcase.pageName}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [showcase]);

  return (
    <div className="d-flex">
      {!isShowcase && (
        <div className={classes.shareBtnContainer}>
          <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
            <a
              href={`${appConfig.apiUrl}/v1/look-board/download/${lookBoardId}`}
            >
              <HeaderTooltip text={tooltipShareText.download} placement="top">
                <SpriteIcon
                  name="download-arrow"
                  width={packageIconSizes[iconSize]}
                  height={packageIconSizes[iconSize] * 1.5}
                />
              </HeaderTooltip>
            </a>
          </div>
        </div>
      )}
      {isShowcase && (
        <>
          <div className={classes.shareBtnContainer}>
            <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
              <HeaderTooltip text={tooltipShareText.pinIcon} placement="top">
                <SpriteIcon
                  name="pin-icon"
                  width={packageIconSizes[iconSize]}
                  height={packageIconSizes[iconSize] * 1.5}
                />
              </HeaderTooltip>
              <InfoIconPopover
                iconProps={{
                  className: 'mb-1 mr-1',
                  variant: 'transparent',
                  size: 'xs',
                  color: 'primary',
                }}
                fullWidth
                vertAlignment="top"
              >
                <img src={showcaseProfile} alt="showcase" />
                <div
                  className={classes.showcasePopOverText}
                  onClick={handleDownloadImage}
                >
                  <SpriteIcon
                    name="download-arrow"
                    width="25"
                    height="25"
                    className={classes.showcasePopOverIcon}
                  />
                  Download Pin the Look Card
                </div>
              </InfoIconPopover>
            </div>
          </div>
          <div className={classes.shareBtnContainer}>
            <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
              <HeaderTooltip
                text={tooltipShareText.profileIcon}
                placement="top"
              >
                <SpriteIcon name="user" size={iconSize} />
              </HeaderTooltip>
              <InfoIconPopover
                iconProps={{
                  className: 'mb-1 mr-1',
                  variant: 'transparent',
                  size: 'xs',
                  color: 'primary',
                }}
                fullWidth
                vertAlignment="top"
              >
                <AmbassadorCard {...showcase} disableHover isShare />
                <div
                  className={classes.showcasePopOverText}
                  onClick={handleDownloadImageByLink}
                >
                  <SpriteIcon
                    name="download-arrow"
                    width="25"
                    height="25"
                    className={classes.showcasePopOverIcon}
                  />
                  Download Showcase Card
                </div>
              </InfoIconPopover>
            </div>
          </div>
        </>
      )}
      <div className={classes.shareBtnContainer}>
        <div
          className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}
          onClick={handleCopyToClipboard}
        >
          <HeaderTooltip text={tooltipShareText.copyLink} placement="top">
            <SpriteIcon name="copy-link" size={iconSize} />
          </HeaderTooltip>
        </div>
      </div>
      <div className={classes.shareBtnContainer}>
        <EmailShareButton
          url={pageUrl}
          subject="Check Out My Pin the Look Look Board"
        >
          <div className={`${classes.shareBtn} ${sizeToClass[iconSize]}`}>
            <HeaderTooltip text={tooltipShareText.emailLink} placement="top">
              <SpriteIcon name="envelope" size={iconSize} />
            </HeaderTooltip>
          </div>
        </EmailShareButton>
      </div>
    </div>
  );
};

LookBoardShare.propTypes = {
  pageUrl: PropTypes.string,
  lookBoardId: PropTypes.number,
  iconSize: PropTypes.oneOf(Object.keys(sizeToClass)),
  isShowcase: PropTypes.bool,
  showcase: PropTypes.string,
};

LookBoardShare.defaultProps = {
  iconSize: 'sm',
  isShowcase: false,
  showcase: null,
  pageUrl: '',
  lookBoardId: null,
};

export default LookBoardShare;
