import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SpeedDial } from '@material-ui/lab';

const CustomSpeedDial = ({ mainIcon, children, customHandler }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (customHandler) {
      customHandler(open);
    }
  }, [customHandler, open]);

  return (
    <SpeedDial
      ariaLabel="SpeedDial"
      icon={mainIcon}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction="down"
    >
      {children}
    </SpeedDial>
  );
};

CustomSpeedDial.propTypes = {
  mainIcon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  customHandler: PropTypes.func,
};

CustomSpeedDial.defaultProps = {
  customHandler: PropTypes.func,
};

CustomSpeedDial.defaultProps = {
  customHandler: null,
};
export default CustomSpeedDial;
