import React, { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateUsersLibraryAction } from 'modules/users/store/actions';
import messagesService from 'modules/dashboard/messages/messagesService';
import {
  setContactListAction,
  updateContactListAction,
} from 'modules/conversation/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import ConversationItem from 'components/conversationThumbnail/ConversationItem';
import CustomInfiniteScroll from 'components/CustomInfiniteScroll';
import useCancelToken from 'hooks/useCancelToken';

const ContactList = ({
  scrollHeight,
  updateScrollHeight,
  handleSelectContact,
  searchParams,
  updateSearchParams,
}) => {
  const dispatch = useDispatch();

  const usersLibrary = useSelector((state) => state.users.library);
  const conversationContactList = useSelector(
    (state) => state.conversation.contactList
  );
  const contactList = useMemo(
    () => conversationContactList.map((id) => usersLibrary[id]),
    [conversationContactList, usersLibrary]
  );

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [createCancelToken, isCancelled] = useCancelToken();

  const loadMore = useCallback(async () => {
    setLoading(true);
    try {
      const cancelToken = createCancelToken();

      const list = await messagesService.getContactList(searchParams, {
        cancelToken,
      });

      const usersIdList = list.map(({ id }) => id);
      dispatch(updateUsersLibraryAction(list));
      dispatch(updateContactListAction(usersIdList));
      setHasMore(list.length === searchParams.limit);

      if (list.length > 0) {
        updateSearchParams({ offset: searchParams.offset + list.length });
      }
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.message);
      }
    }

    setLoading(false);
  }, [
    searchParams,
    dispatch,
    setLoading,
    updateSearchParams,
    createCancelToken,
    isCancelled,
  ]);

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();
      const list = await messagesService.getContactList(searchParams, {
        cancelToken,
      });

      const usersIdList = list.map(({ id }) => id);
      dispatch(updateUsersLibraryAction(list));
      dispatch(setContactListAction(usersIdList));
      setHasMore(list.length === searchParams.limit);

      if (list.length > 0) {
        updateSearchParams({ offset: list.length });
      }
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.message);
      }
    }

    setLoading(false);
    updateScrollHeight();
  }, [
    dispatch,
    setHasMore,
    updateSearchParams,
    setLoading,
    searchParams,
    updateScrollHeight,
    isCancelled,
    createCancelToken,
  ]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  return (
    <CustomInfiniteScroll
      parentHeight={scrollHeight}
      isLoading={loading}
      hasMore={hasMore}
      loadMore={loadMore}
    >
      {contactList.length > 0 &&
        contactList.map((user) => {
          return (
            <div key={user.id}>
              <ConversationItem
                id={user.id}
                user={user}
                handleSelect={handleSelectContact}
              />
            </div>
          );
        })}
      {!contactList.length && !loading ? (
        <div className="text-center mt-2">
          You have not any conversations yet
        </div>
      ) : null}
      {loading && <div className="text-center mt-2">Loading...</div>}
    </CustomInfiniteScroll>
  );
};

ContactList.propTypes = {
  scrollHeight: PropTypes.number.isRequired,
  updateScrollHeight: PropTypes.func.isRequired,
  handleSelectContact: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  updateSearchParams: PropTypes.func.isRequired,
};

export default ContactList;
