import clsx from 'clsx';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { mediaShape } from 'modules/media/propTypes';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import BasicAvatar from 'components/ui/BasicAvatar';
import classes from './Conversation.module.scss';

const ConversationItem = ({
  user: { firstName, lastName, avatar, displayName },
  title,
  handleSelect,
  id,
  selected,
  unread,
  lastMessageDate,
}) => {
  const userAvatarUrl = getUserAvatarUrl(
    useSelector((state) => state.app.config)
  );

  const avatarUrl = useMemo(
    () => avatar && buildResourceUrl(userAvatarUrl, avatar.userId, avatar.hash),
    [userAvatarUrl, avatar]
  );

  const interactionDate = useMemo(
    () => moment(lastMessageDate).format('MMM D, YYYY'),
    [lastMessageDate]
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.selected]: selected,
        [classes.unread]: unread,
      })}
      onClick={handleSelect}
      data-id={id}
    >
      <BasicAvatar
        className={classes.avatar}
        firstName={firstName}
        lastName={lastName}
        src={avatarUrl}
      />
      <div className={classes.titleContainer}>
        {title && <span className={classes.title}>{title}</span>}

        <span className={`${classes.fullName}`}>
          {displayName ?? `${firstName} ${lastName}`}
        </span>
        <span className={classes.date}>{interactionDate}</span>
      </div>
    </div>
  );
};

ConversationItem.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    avatar: mediaShape,
  }),
  title: PropTypes.string,
  id: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  unread: PropTypes.bool,
  lastMessageDate: PropTypes.number.isRequired,
};

ConversationItem.defaultProps = {
  title: null,
  unread: null,
  selected: null,
  user: PropTypes.shape({
    displayName: PropTypes.string,
  }),
};

export default ConversationItem;
