import React from 'react';
import PropTypes from 'prop-types';
import classes from '../Showcase.module.scss';

const SetupShowcaseHint = ({ text }) => {
  return <div className={classes.showcaseHint}>{text}</div>;
};
SetupShowcaseHint.propTypes = {
  text: PropTypes.string.isRequired,
};
export default SetupShowcaseHint;
