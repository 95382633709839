import React from 'react';
import getPreviewImg from 'assets/img/home-page/sections-preview/get.svg';
import requestPreviewImg from 'assets/img/home-page/sections-preview/request.svg';
import curatePreviewImg from 'assets/img/home-page/sections-preview/curate.svg';
import womanImg from 'assets/img/ambassador-page/woman.png';
import interiorOneImg from 'assets/img/unavailable_1.png';
import interiorTwoImg from 'assets/img/unavailable_2.png';
import interiorThreeImg from 'assets/img/unavailable_3.png';
import becomeAmbassadorClasses from 'modules/ambassadors/getStarted/GetStarted.module.scss';
import classes from './UnavailableToolMobilePage.module.scss';

const UnavailableToolMobilePage = () => {
  return (
    <>
      <p className={classes.title}>
        <span>These Tools are only available on</span>{' '}
        <span className="primary-color">our Web Platform</span>
      </p>
      <p className="text-sm font-italic px-2 mb-5">Use Web Platform and Try:</p>
      <div className="px-2 mb-5">
        <div className="d-flex align-items-center mb-2">
          <img className={classes.iconImg} src={getPreviewImg} alt="" />
          <span className="font-title text-lg">Get the Look</span>
        </div>
        <div className="d-flex align-items-center mb-2">
          <img className={classes.iconImg} src={requestPreviewImg} alt="" />
          <span className="font-title text-lg">Request the Look</span>
        </div>
        <div className="d-flex align-items-center">
          <img className={classes.iconImg} src={curatePreviewImg} alt="" />
          <span className="font-title text-lg">Curate the Look</span>
        </div>
      </div>
      <div className="row px-2 mb-5">
        <div className="col-4">
          <img className={classes.interiorImg} src={interiorOneImg} alt="" />
        </div>
        <div className="col-4">
          <img className={classes.interiorImg} src={interiorTwoImg} alt="" />
        </div>
        <div className="col-4">
          <img className={classes.interiorImg} src={interiorThreeImg} alt="" />
        </div>
      </div>
      <div className={`${becomeAmbassadorClasses.banner} mb-5`}>
        <div className={becomeAmbassadorClasses.bannerShape} />
        <div className="position-absolute top-0 left-0 w-100 h-100 z2 d-flex text-center align-items-center px-1">
          <div>
            <div className={becomeAmbassadorClasses.bannerQuote}>
              ‘’Pin the Look makes it <br />
              fun & easy to help <br />
              others get the look <br />
              they love’’
            </div>
            <div className={becomeAmbassadorClasses.bannerAuthor}>
              Janice l. Ambassador
            </div>
          </div>
        </div>
        <img width="55%" src={womanImg} alt="" />
      </div>
    </>
  );
};

export default UnavailableToolMobilePage;
