import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import { routesByName } from 'constants/routes';
import HowItWorksVideoModal from 'components/modals/HowItWorksVideoModal';
import posterImg from 'assets/video/homepage-preview.png';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  aboutUsConfigFirst,
  aboutUsConfigSecond,
} from 'modules/about/constants';
import womanImg from 'assets/img/about-us-page/woman.png';
import roomsImg from 'assets/img/about-us-page/rooms.png';
import { toggleHowItWorksVideoModalAction } from 'modules/layout/store/actions';
import IconButton from 'components/ui/IconButton/IconButton';
import { Link, useHistory } from 'react-router-dom';
import classes from './About.module.scss';

const bannerBgStyle = {
  backgroundImage: `url(${womanImg})`,
};

const AboutContainer = () => {
  const dispatch = useDispatch();
  const videoUrlPlaceholder = useSelector(
    (state) => state.app.config.endpoints.media.videos.standart
  );
  const history = useHistory();
  const handleModalOpen = useCallback(() => {
    dispatch(toggleHowItWorksVideoModalAction(true));
  }, [dispatch]);

  const handleLinkOpen = (link) => {
    history.push(link);
  };

  const videoHomepageLink = useMemo(() => {
    return buildResourceUrl(videoUrlPlaceholder, null, null, 'homepage');
  }, [videoUrlPlaceholder]);

  return (
    <>
      <section className={classes.root}>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h1 className={`${classes.title} font-title text-uppercase`}>
            About Us
          </h1>
          <Button
            onClick={handleModalOpen}
            className={`${classes.btn} d-inline-flex align-items-center `}
            inline
          >
            <SpriteIcon name="play-icon" size="lg" className="mr-1" />
            How &nbsp;<span className="font-semi-bold">It Works</span>
          </Button>
        </div>
        <div className={`${classes.content} background-white`}>
          <div className="row">
            <div className={`${classes.leftColumn} col-7`}>
              <p
                className={`${classes.paragraph} color-gray-6 font-italic pr-5 mb-2`}
              >
                Pin the Look is a social curation platform that streamlines the
                process of decorating your home. Pin the Look offers three ways
                to get curated looks:
              </p>
              <p
                className={`${classes.paragraph} color-gray-6 font-italic pr-5 mb-2`}
              >
                <ul>
                  <li>
                    <span className="fw-600">Get the Look:</span> Browse
                    <span
                      className="cursor-pointer primary-color"
                      onClick={() =>
                        handleLinkOpen(routesByName.getTheLook.index)
                      }
                    >
                      {' '}
                      Curated Looks{' '}
                    </span>
                    of your favorite inspo images and
                    <span
                      className="cursor-pointer primary-color"
                      onClick={() =>
                        handleLinkOpen(routesByName.getTheLook.mixMatch.index)
                      }
                    >
                      {' '}
                      Mix & Match{' '}
                    </span>
                    your favorites. And for truly unique finds, visit our
                    <span
                      className="cursor-pointer primary-color"
                      onClick={() =>
                        handleLinkOpen(
                          routesByName.getTheLook.ambassadors.index
                        )
                      }
                    >
                      {' '}
                      Ambassador Showcases
                    </span>
                    .
                  </li>
                  <li>
                    <span className="fw-600">Request the Look:</span> Get
                    curated looks of your favorite inspo images when you
                    <span
                      className="cursor-pointer primary-color"
                      onClick={() =>
                        handleLinkOpen(routesByName.requestTheLook.index)
                      }
                    >
                      {' '}
                      Request the Look
                    </span>
                    . Your requests will be matched with Ambassadors - home
                    stylists with an eye for design - who will curate Look
                    Boards with products you can buy online.
                  </li>
                  <li>
                    <span className="fw-600">Curate the Look:</span> You can
                    <span
                      className="cursor-pointer primary-color"
                      onClick={() =>
                        handleLinkOpen(routesByName.curateTheLook.canvas)
                      }
                    >
                      {' '}
                      Curate the Look{' '}
                    </span>
                    yourself by dragging and dropping items from our product
                    database into a Look Board template to visualize items
                    together.
                  </li>
                </ul>
              </p>

              <p
                className={`${classes.paragraph} color-gray-6 font-italic pr-5 mb-2`}
              >
                With access to hundreds of curated looks in popular styles,
                easy-to-use curation tools, and personal shopping assistance
                from our Ambassadors, getting the look you love for your home is
                now easier than ever!
              </p>
              <h2
                className={`${classes.paragraph} font-title text-uppercase mt-5`}
              >
                PIN THE LOOK MAIN SECTIONS:
              </h2>
              <div className={`${classes.items} d-flex flex-wrap`}>
                {aboutUsConfigFirst.map(
                  ({ id, title, description, icon, route }) => (
                    <div key={id} className={classes.item}>
                      <h2 className={`${classes.subTitle} font-semi-bold mb-2`}>
                        {title}
                      </h2>
                      <p
                        className={`${classes.paragraph} color-gray-6 font-italic mb-3`}
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                      <IconButton
                        className={classes.icon}
                        component={Link}
                        to={route}
                      >
                        <SpriteIcon name={icon} size="lg" />
                      </IconButton>
                    </div>
                  )
                )}
              </div>
              <h2 className={`${classes.paragraph} font-title text-uppercase`}>
                PIN THE LOOK OFFERS A BETTER WAY TO SHOP:
              </h2>
              <div className={`${classes.items} d-flex flex-wrap`}>
                {aboutUsConfigSecond.map(({ id, title, description, icon }) => (
                  <div key={id} className={classes.item}>
                    <h2 className={`${classes.subTitle} font-semi-bold mb-2`}>
                      {title}
                    </h2>
                    <p
                      className={`${classes.paragraph} color-gray-6 font-italic mb-3`}
                      /* eslint-disable-next-line react/no-danger */
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <span className={`${classes.icon}`}>
                      <SpriteIcon name={icon} size="lg" />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className={`${classes.rightColumn} col-5`}>
              <div className={classes.bannerBg} style={bannerBgStyle} />
              <div className={classes.bannerContent}>
                <img className="w-100" src={roomsImg} alt="" />
                <div className="d-flex justify-content-end">
                  <div className="pl-5">
                    <div
                      className={`${classes.title} font-title text-uppercase mb-3`}
                      style={{ lineHeight: 1.5 }}
                    >
                      ‘’Pin the Look <br /> makes home <br /> decor shopping{' '}
                      <br /> a breeze!’’
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-7" />
            <h2 className="col-5 text-center py-5 font-title primary-color font-semi-bold">
              Let’s Decorate the World!
            </h2>
          </div>
        </div>
      </section>
      <HowItWorksVideoModal video={videoHomepageLink} posterImg={posterImg} />
    </>
  );
};

export default AboutContainer;
