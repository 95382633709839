import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import getCreditValidator from 'modules/report/components/GetCredit/getCreditValidator';
import { reportFormFields } from 'modules/report/constants';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import CheckboxInputWrapper from 'components/finalFormWrappers/CheckboxInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import ReportImageContainer from 'modules/report/components/ReportImageContainer';
import classes from 'modules/report/Report.module.scss';

const GetCredit = ({ currentImage, onReport }) => (
  <div className={classes.contentWrapper}>
    <div className="d-flex">
      <div className="flex-fill background-white p-5">
        <h1 className={`${classes.title} mb-4`}>Get Credit for Image</h1>
        <p className="font-italic mb-5" style={{ lineHeight: 1.6 }}>
          Use this form to get credit for your image and get it redirected ti
          your website
        </p>
        <Form
          validate={getCreditValidator}
          onSubmit={onReport}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <p className={`${classes.subTitle} mb-1`}>Contact Information</p>
              <p className="font-italic mb-3" style={{ lineHeight: 1.6 }}>
                (For person reporting content)
              </p>
              <div className="row mb-3">
                <div className="col-7">
                  <Field
                    required
                    label="Name"
                    name={reportFormFields.name}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-12" />
                <div className="col-3">
                  <Field
                    required
                    label="Street"
                    name={reportFormFields.street}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-3">
                  <Field
                    required
                    label="State"
                    name={reportFormFields.state}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-3">
                  <Field
                    required
                    label="Region"
                    name={reportFormFields.region}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-3">
                  <Field
                    required
                    label="Zip"
                    name={reportFormFields.zip}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Country"
                    name={reportFormFields.country}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Phone"
                    type="tel"
                    name={reportFormFields.phone}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Email"
                    type="email"
                    name={reportFormFields.email}
                    component={TextInputWrapper}
                  />
                </div>
              </div>
              <p className={`${classes.subTitle} mb-3`}>Identify Your Work</p>
              <div className="row mb-3">
                <div className="col-7">
                  <Field
                    required
                    label="Please describe your work"
                    name={reportFormFields.workDescription}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Please tell us where we can see your work on your own website"
                    name={reportFormFields.workUrl}
                    component={TextInputWrapper}
                  />
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Please provide the link you would like this image redirected to"
                    name={reportFormFields.replaceUrl}
                    component={TextInputWrapper}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-7 mb-2">
                  <FormControlLabel
                    label="I uphold that I am the legal owner of this image"
                    classes={{ label: classes.termsLabel }}
                    required
                    control={
                      <Field
                        type="checkbox"
                        color="primary"
                        name={reportFormFields.owner}
                        component={CheckboxInputWrapper}
                      />
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-9">
                  <p className={`${classes.subTitle} mb-3`}>
                    Typing your name in this box acts as your electronic
                    signature
                  </p>
                </div>
                <div className="col-7">
                  <Field
                    required
                    label="Your Name"
                    name={reportFormFields.signature}
                    component={TextInputWrapper}
                  />
                </div>
              </div>
              <FinalFormSubmitButton inline disabled={!values.owner}>
                Submit
              </FinalFormSubmitButton>
            </form>
          )}
        />
      </div>
      <ReportImageContainer currentImage={currentImage} />
    </div>
  </div>
);

GetCredit.propTypes = {
  currentImage: inspirationImageShape,
  onReport: PropTypes.func.isRequired,
};

GetCredit.defaultProps = {
  currentImage: null,
};

export default GetCredit;
