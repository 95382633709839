import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card/Card';
import ImagesTable from 'components/ImagesTable/ImagesTable';

const LookBoardViewTab = ({ items }) => (
  <div className="d-flex justify-content-end">
    {items.map(({ id, title, items: products }) => (
      <div key={id} className="col-3 d-flex">
        <Card classes={{ root: 'flex-fill' }} title={title}>
          <ImagesTable imgUrls={products} />
        </Card>
      </div>
    ))}
  </div>
);

LookBoardViewTab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          url: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default LookBoardViewTab;
