import {
  imageEditFormFields,
  errorMessages,
} from 'modules/dashboard/myImages/components/ImageEditForm/constants';

const imageEditFormValidator = ({ [imageEditFormFields.title]: title }) => {
  const errors = {};

  if (!title) {
    errors.title = errorMessages.required;
  }

  return errors;
};

export default imageEditFormValidator;
