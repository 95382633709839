import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import CreateLookBoardCanvas from 'modules/curateTheLook/createLookBoard/components/CreateLookBoardCanvas/CreateLookBoardCanvas';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import {
  createImageInstantlyForAction,
  updateCreationImageStatusAction,
} from 'modules/inspirationImage/store/actions';
import {
  togglePinMediaModalAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import featureNameKeys from 'constants/featureNames';
import Button from 'components/ui/Button/Button';
import errorToastr from 'libs/toastr/errorToastr';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import LookBoardExamplesModal from 'modules/curateTheLook/createLookBoard/components/LookBoardExamplesModal/LookBoardExamplesModal';
import mergeLookBoardsWithProducts from 'utils/mergeLookBoardsWithProducts';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import ButtonPopover from 'components/ui/ButtonPopover/ButtonPopover';
import useQuery from 'hooks/useQuery';
import TipsModal from './components/TipsModal/TipsModal';
import { localStorageKeys } from '../../app/constants';
import {
  excludeProductsAction,
  restoreCurateStateAction,
} from '../store/actions';
import ButtonHowItWorks from '../../../components/ui/ButtonHowItWorks';
import createLookBoardClasses from './CreateLookBoard.module.scss';
import LookBoardPreview from '../../../components/lookBoardThumbnails/LookBoard/LookBoardPreview';

const MainArea = ({
  onOpenPreview,
  imageProductSize,
  setImageProductSize,
  currentLookBoard,
  onOpenLookBoardDragModal,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const library = useSelector((state) => state.inspirationImage.library);
  const { inspirationImageId, lookBoardId: selectedLookBoardId } = useSelector(
    (state) => state.curateTheLook.lookBoardData
  );
  const selectedImage = useMemo(() => library[inspirationImageId], [
    inspirationImageId,
    library,
  ]);

  const history = useHistory();
  const { pathname } = useLocation();
  const queryParams = useQuery();
  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );
  const [
    lookBoardExamplesModalShowed,
    setLookBoardExamplesModalShowed,
  ] = useState(false);
  const [lookBoardExamplesModalOpen, setLookBoardExamplesModalOpen] = useState(
    false
  );
  const [modalTips, setModalTips] = useState(false);
  const [lookBoardExamples, setLookBoardExamples] = useState([]);
  const [lookBoardExamplesLoading, setLookBoardExamplesLoading] = useState(
    false
  );

  useEffect(() => {
    (async () => {
      if (lookBoardExamplesModalShowed) {
        setLookBoardExamplesLoading(true);
        try {
          const {
            result: { lookBoards, products },
          } = await lookBoardService.getLookBoardExamples();

          const lookBoardList = mergeLookBoardsWithProducts(
            lookBoards,
            products
          );
          setLookBoardExamplesLoading(false);
          setLookBoardExamples(lookBoardList);
        } catch (e) {
          setLookBoardExamplesLoading(false);
          errorToastr('Error', e.message);
        }
      }
    })();
  }, [lookBoardExamplesModalShowed]);

  const handleOpenLookBoardExamplesModal = useCallback(() => {
    setLookBoardExamplesModalOpen(true);
    setLookBoardExamplesModalShowed(true);
  }, []);

  const handleCloseLookBoardExamplesModal = useCallback(() => {
    setLookBoardExamplesModalOpen(false);
  }, []);

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  const handleAddImageModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(createImageInstantlyForAction(featureNameKeys.curate));
  }, [currentUser, dispatch, pathname, history]);

  const handlePinMediaModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }

    dispatch(togglePinMediaModalAction(true));
    dispatch(updateCreationImageStatusAction(featureNameKeys.curate));
  }, [dispatch, currentUser, pathname, history]);

  const handleModalTipsOpen = useCallback(() => {
    setModalTips(true);
  }, []);

  const handleModalTipsClose = useCallback(() => {
    setModalTips(false);
    const status = JSON.parse(
      localStorage.getItem(localStorageKeys.introModalsStatus)
    );
    if (status && !status.curateTheLookTips) {
      status.curateTheLookTips = true;
      localStorage.setItem(
        localStorageKeys.introModalsStatus,
        JSON.stringify(status)
      );
    }
  }, []);

  useEffect(() => {
    const queryTips = queryParams.get('tips');
    if (queryTips === 'active') {
      const status = JSON.parse(
        localStorage.getItem(localStorageKeys.introModalsStatus)
      );
      if (status && !status.curateTheLookTips) {
        setModalTips(true);
      }
    }
  }, [pathname, history, queryParams]);

  // get rejected look board data
  const getLookBoardData = useCallback(async (lookBoardId) => {
    const { result: lookBoard } = await lookBoardService.getLookBoardById(
      lookBoardId
    );
    return lookBoard;
  }, []);

  useEffect(() => {
    const lookBoardId = queryParams.get('look-board');
    if (lookBoardId && currentUser) {
      getLookBoardData(lookBoardId).then((lookBoard) => {
        const lookBoardData = {
          inspirationImageId: lookBoard.inspirationId,
          title: lookBoard.title,
          color: lookBoard.color,
          products: lookBoard.products,
          columns: lookBoard.columns,
          shareUrl: lookBoard.shareUrl,
        };

        const formValues = {
          title: lookBoard.title,
          type: lookBoard.inspirationType,
          itemClassId: lookBoard.itemClasses.length
            ? lookBoard.itemClasses[0]
            : null,
          roomTypeId: lookBoard.roomTypeId,
          styleId: lookBoard.styles.length ? lookBoard.styles[0] : null,
          description: lookBoard.description,
        };

        const editState = {
          lookBoardId: lookBoard.id,
          isDraft: true,
        };
        dispatch(
          restoreCurateStateAction(lookBoardData, formValues, editState)
        ).then(() => {
          dispatch(excludeProductsAction(lookBoard.products));
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.mainAreaWrapper}>
      <div className={classes.mainAreaContent}>
        <div className="d-flex justify-content-between mb-2 align-items-start">
          <div>
            <p
              className="font-title mb-1 text-uppercase"
              style={{ fontSize: 24 }}
            >
              Curate a Look Board
            </p>
            <ButtonPopover
              buttonProps={{
                variant: 'outlined',
                inline: true,
              }}
              buttonText="View Steps"
              size="sm"
            >
              <div className={classes.processDescription}>
                <h3 className={createLookBoardClasses.processTitle}>
                  {' '}
                  <span className="text-color">STEPS</span> TO CURATE A LOOK
                  BOARD
                </h3>
                <p className="primary-color font-semi-bold">How it works:</p>
                <p>
                  <b>Step 1.</b> Select a Look Board Inspiration image{' '}
                  <b className="primary-color">(or skip)</b>
                </p>
                <p>
                  <b>Step 2.</b> Select a Look Board Template and Curate with
                  Products
                </p>
                <p>
                  <b>Step 3.</b> Add Look Board Details on the Next Page
                </p>
              </div>
            </ButtonPopover>
          </div>
          <ButtonHowItWorks text="Curate The Look" />
          {selectedImage && (
            <div>
              <div className={clsx(classes.imgPreview)}>
                <IconButton
                  className={classes.imgPreviewThumbZoom}
                  color="secondary"
                  size="xs"
                  onClick={
                    selectedLookBoardId
                      ? onOpenLookBoardDragModal
                      : handleOpenPreview
                  }
                >
                  <SpriteIcon name="loupe" size="xs" />
                </IconButton>
                {selectedLookBoardId && currentLookBoard && (
                  <div className={classes.lookBoardPreview}>
                    <LookBoardPreview
                      title={currentLookBoard.lookBoard.title}
                      color={currentLookBoard.lookBoard.color}
                      isLiked={currentLookBoard.lookBoard.isLiked}
                      isVoted={currentLookBoard.lookBoard.isVoted}
                      banner={currentLookBoard.lookBoard.banner}
                      products={currentLookBoard.lookBoardProducts}
                      showLike={false}
                    />
                  </div>
                )}
                {!selectedLookBoardId && (
                  <img
                    alt=""
                    src={buildResourceUrl(
                      inspirationImageUrl.thumb,
                      selectedImage.media.userId,
                      selectedImage.media.hash
                    )}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <p className="font-title d-flex align-items-center mb-2">
          <span className="mr-1">Look Board Curation Canvas</span>
          <InfoIconPopover
            iconProps={{
              className: 'ml-1',
              variant: 'inverted-white',
              size: 'xs',
              color: 'secondary',
            }}
            fullWidth
          >
            <p className="font-semi-bold mb-1">Look Board Curation Canvas</p>
            <p className="text-xs">
              Once you select an Inspiration Image, the Canvas will be displayed
              in a Grid format that you can drag & drop products into. You can
              select one to three columns and up to four rows for a maximum of
              12 products. Click on the Products tab to add items to get the
              look of the image. You can either select products from our
              database or click on the PIN icon to add items from online stores.
              You can change the color and text in the banner to customize your
              Look Board. Once you are happy with your Look Board, click on the
              Next button to Add Details and Submit.
            </p>
          </InfoIconPopover>
        </p>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            <IconButton
              variant="inverted-white"
              onClick={handleAddImageModalOpen}
            >
              <SpriteIcon name="image-upload" />
            </IconButton>
            <IconButton
              className="ml-1"
              variant="inverted-white"
              onClick={handlePinMediaModalOpen}
            >
              <SpriteIcon name="pin" />
            </IconButton>
          </div>
          <div className="d-flex">
            <Button
              className="ml-1"
              size="sm"
              inline
              onClick={handleModalTipsOpen}
            >
              Tips
            </Button>
            <Button
              variant="outlined"
              className="ml-1"
              size="sm"
              color="secondary"
              inline
              onClick={handleOpenLookBoardExamplesModal}
            >
              Look Board Examples
            </Button>
          </div>
        </div>
        <CreateLookBoardCanvas
          imageProductSize={imageProductSize}
          setImageProductSize={setImageProductSize}
        />
        <LookBoardExamplesModal
          open={lookBoardExamplesModalOpen}
          onClose={handleCloseLookBoardExamplesModal}
          loading={lookBoardExamplesLoading}
          lookBoards={lookBoardExamples}
          title="Expandable Grid Look Board Examples"
        />
        <TipsModal open={modalTips} onClose={handleModalTipsClose} />
      </div>
    </div>
  );
};

MainArea.propTypes = {
  selectedTemplate: PropTypes.shape({
    columnCount: PropTypes.number.isRequired,
  }),
  onOpenPreview: PropTypes.func.isRequired,
  imageProductSize: PropTypes.number.isRequired,
  setImageProductSize: PropTypes.func.isRequired,
  onOpenLookBoardDragModal: PropTypes.func.isRequired,
  currentLookBoard: PropTypes.shape({
    lookBoardProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    lookBoard: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      isLiked: PropTypes.number.isRequired,
      lookBoardSocialImage: PropTypes.string,
      lookBoardSocialImageFacebook: PropTypes.string,
      isVoted: PropTypes.bool,
      banner: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.object])
      ).isRequired,
      shareUrl: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

MainArea.defaultProps = {
  selectedTemplate: null,
  currentLookBoard: null,
};

export default MainArea;
