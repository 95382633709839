import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput from 'components/inputs/TextInput/TextInput';
import SpriteIcon from 'components/ui/SpriteIcon';
import HelperText from 'components/inputs/HelperText/HelperText';
import classes from './AutoCompleteInput.module.scss';

const autoCompleteClasses = {
  root: 'position-relative mt-1',
  inputRoot: classes.inputRoot,
  input: classes.input,
  clearIndicator: classes.clearIndicator,
  option: classes.option,
};

const getOptionSelected = () => true;

const getOptionLabel = ({ label }) => label;

const AutoCompleteInput = ({
  handleChange,
  value,
  formValues,
  placeholder,
  label,
  error,
  helperText,
  ...rest
}) => {
  const onChange = useCallback(
    (_, option) => {
      handleChange(option, formValues);
    },
    [handleChange, formValues]
  );

  return (
    <Autocomplete
      {...rest}
      value={value.value ? value : undefined}
      onChange={onChange}
      classes={autoCompleteClasses}
      fullWidth
      popupIcon={false}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => (
        <>
          <SpriteIcon
            className={`${classes.icon} primary-color`}
            name="search"
            size="sm"
          />
          <TextInput {...params} placeholder={placeholder} />
          {label && <span className={classes.formLabel}>{label}</span>}

          {helperText && (
            <div className={classes.error}>
              <HelperText isError={error} message={helperText} />
            </div>
          )}
        </>
      )}
    />
  );
};

AutoCompleteInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

AutoCompleteInput.defaultProps = {
  placeholder: null,
  label: null,
  helperText: null,
  error: null,
};

export default AutoCompleteInput;
