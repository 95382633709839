import { routesByName } from '../../../constants/routes';

export const faqAttributes = [
  {
    title: 'Passion for Home Decor:',
    text:
      'You embrace the joy of creating stunning living spaces with genuine love and enthusiasm.',
  },
  {
    title: 'Creative Vision:',
    text:
      'You love to bring spaces to life with your unique and imaginative design perspective, adding style and flair to every project.',
  },
  {
    title: 'Attention to Detail:',
    text:
      'You are able to elevate the aesthetic of rooms by pinpointing and enhancing even the smallest elements, ensuring a cohesive and polished look.\n',
  },
  {
    title: 'Talent for Styling:',
    text:
      'You showcase your natural ability to arrange furniture and decor, effortlessly creating visually captivating spaces.\n',
  },
  {
    title: 'Expertise in Trends:',
    text:
      'You stay ahead of the curve with the latest trends in home decor, infusing fresh ideas and innovation into your designs.',
  },
  {
    title: 'Strong Communication Skills:',
    text:
      'You can articulate your design ideas effectively and offer valuable advice to others, fostering meaningful connections within the community.\n',
  },
  {
    title: 'Entrepreneurial Spirit:',
    text:
      'You desire to turn your passion for decor into a lucrative side hustle, seizing opportunities to monetize your creative talents.\n',
  },
  {
    title: 'Commitment to Excellence:',
    text:
      'You strive for perfection in every project, delivering high-quality work and setting new standards of excellence.',
  },
  {
    title: 'Collaborative Nature:',
    text:
      'You thrive in a supportive community where sharing ideas, collaborating with others, and contributing to collective growth are celebrated.',
  },
  {
    title: 'Adaptability:',
    text:
      'You embrace new ideas and approaches, adjusting your methods to meet the unique requirements of each project.\n',
  },
  {
    title: 'Tech-Savvy:',
    text:
      'You are able to harness the power of technology and online platforms to engage with your audience effectively and showcase your creativity to the world.',
  },
];

export const faqCreativity = [
  {
    title: 'Inspiration Hub:',
    text:
      'Our platform serves as a boundless wellspring of inspiration, featuring a diverse array of images, styles, and trends curated by our community and experts. Ambassadors can explore, discover, and draw inspiration from this wealth of creative content.',
  },
  {
    title: 'Creative Freedom:',
    text:
      "We believe in giving our ambassadors the freedom to express themselves authentically. Whether it's curating Look Boards, showcasing their favorite room images, or sharing design tips, we encourage our ambassadors to let their creativity shine.",
  },
  {
    title: 'Supportive Community:',
    text:
      'Joining Pin the Look means becoming part of a supportive and collaborative community of like-minded individuals. Ambassadors can connect, collaborate, and exchange ideas with fellow decor enthusiasts, fostering a culture of creativity and camaraderie.\n',
  },
  {
    title: 'Exclusive Opportunities:',
    text:
      'We provide our ambassadors with access to exclusive opportunities, such as affiliate programs, special offers, and gift cards for home furnishing products. These perks not only reward creativity but also provide tangible benefits for ambassadors to enjoy.\n',
  },
  {
    title: 'Continuous Learning:',
    text:
      'Through educational resources, webinars, and ongoing support, we ensure that our ambassadors have the tools and knowledge they need to continuously hone their craft and stay at the forefront of home decor trends.\n',
  },
];

export const faqInitialActions = [
  {
    title: 'Set Preferences:',
    text:
      "After being approved as an ambassador for Pin the Look, you'll begin by setting your Ambassador Design Preferences. This involves answering a few questions or using existing preferences to tailor your experience. These preferences ensure you receive requests and opportunities that resonate with your unique design style and expertise.",
    link: routesByName.account.profile,
  },
  {
    title: 'Review Onboarding Videos:',
    text:
      'Your next step toward success as an Ambassador is watching the onboarding videos. These videos offer tips and best practices on picking a compelling theme, pinning images and products that meet our guidelines, setting up a Showcase page, and promoting your Showcase and Look Boards on social media.',
    link: routesByName.dashboard.ambassadorVideos,
  },
  {
    title: 'Join our Affiliate Program:',
    text:
      'As an Ambassador, you can join our company-sponsored Affiliate program on Mylookshops.com. You will want to prioritize registering on MyLookshops.com and joining our Affiliate program. Once you join, you can add your MyLookShops.com Affiliate code to your Pin the Look Profile so that when you add MyLookshops.com products to your Look Boards, your affiliate code is linked.',
    link: routesByName.dashboard.ambassadorPrograms,
  },
  {
    title: 'Set Up and Your Showcase Page:',
    text:
      'Once you have picked a theme you’re excited about, you can start setting up your Showcase page by adding a banner, title, style story, and even a personalized video intro. Next, pin or upload your favorite inspiration images to bring your theme to life and curate Look Boards from those images. Once you have added at least five approved inspiration images and curated at least five approved Look Boards, you can feature your favorites on your Showcase page by dragging and dropping them where you’d like to display them. Once your Showcase is ready for prime time, click the “Activate Showcase” toggle in the upper left to make it go live. To access your Showcase page, hover over your profile image and select “My Showcase” from the dropdown menu.\n',
  },
  {
    title: 'Join our Exclusive Ambassador Facebook Community:',
    text:
      'As an Ambassador, you will receive an invitation to join The Ambassador Inner Circle, an exclusive Facebook group explicitly created for Pin the Look ambassadors. It is a vibrant community hub where ambassadors can connect, collaborate, and exchange ideas.\n',
    link: 'https://www.facebook.com/groups/pinthelookinnercircle',
  },
];

export const faqAmbassadorBenefits = [
  {
    title: 'Beautifying Spaces:',
    text:
      'Ambassadors have the opportunity to help others achieve their ideal home aesthetic.',
  },
  {
    title: 'Community Membership:',
    text:
      'Ambassadors will be invited to join our private Facebook Group, where they can mingle, share, and get tips on promoting their work.\n',
  },
  {
    title: 'Showcase Page:',
    text:
      'Ambassadors get a personalized, theme-based Showcase page that can be set up in minutes. A Showcase Page features their brand elements, favorite inspiration images, curated Look Boards, and links to their socials.',
  },
  {
    title: 'Promoted Content:',
    text:
      'Active Ambassadors will be promoted on Pin the Look’s socials, which will result in gaining more followers and leads.',
  },
  {
    title: 'Home Decor Gift Cards:',
    text:
      'Ambassadors will earn points for adding beautiful inspiration images, curating Look Boards, helping others get the look they love, and more! Points can be redeemed for home furnishing Gift Cards.',
  },
  {
    title: 'Affiliate Commissions:',
    text:
      'Active Ambassadors will be invited to join our affiliate program, enabling them to include commissionable products in Look Boards.',
  },
];

export const faqProcessShowcase = [
  {
    title: 'Showcase Setup Videos:',
    text:
      'Start by immersing yourself in our captivating video series, igniting your journey to set up your Showcase page.\n',
    link: routesByName.dashboard.ambassadorVideos,
  },
  {
    title: 'Inspiration:',
    text:
      'Explore our array of existing Showcases, where inspiration awaits at every turn.',
  },
  {
    title: 'Theme:',
    text:
      'Dive into a world of themes, selecting one that perfectly mirrors your distinctive style.',
  },
  {
    title: 'Inspo Images:',
    text:
      'Fuel your creative fire by uploading or pinning inspirational images, laying the foundation for your design masterpiece.',
  },
  {
    title: 'Pin the Look Pin:',
    text:
      'Streamline your process with the Chrome Pin extension, effortlessly pinning your favorite inspo images and products to curate the look as you search online.',
    link: 'https://pinthelook.com/get-the-pin',
  },
  {
    title: 'Look Boards:',
    text:
      'Craft captivating Look Boards, each a testament to your unrivaled design expertise.',
  },
  {
    title: 'Showcase Set Up:',
    text:
      'Take the final plunge into the Showcase Setup section, revealing your personalized Showcase page to an eagerly awaiting world.',
    link: routesByName.dashboard.ambassadorShowcase,
  },
];

export const faqSupportList = [
  'Select a captivating Showcase theme',
  'Format your personal Showcase page',
  'Pin beautiful inspiration images',
  'Curate unique Look Boards',
  'Curate Look Board Requests',
  'Earn and redeem points',
  'Register as an Affiliate',
];

export const faqAffiliateCommissions = [
  {
    title: 'Promote on Social Media:',
    text:
      "Share your Look Boards and highlight specific MyLookShops.com products on social media. With each sale generated through your posts, you'll earn commissions.",
  },
  {
    title: 'Curate Requests:',
    text:
      "Match Requests with Affiliate products, helping others achieve their desired look. Not only will you gain followers, but you'll also earn commissions when purchases are made.",
  },
  {
    title: 'Platform Visitors:',
    text:
      'Pin the Look will encourage site visitors to browse Ambassadors Showcase pages, which can lead to direct purchases from your Look Boards. The more active you are, the more exposure your Showcase will get on Pin the Look!',
  },
];

export const faqAmbassadorPoints = [
  {
    title: 'Add Inspiration Images:',
    text:
      'Upload or pin high-quality inspiration images and earn points for “Approved” Images.',
  },
  {
    title: 'Curate Look Boards:',
    text:
      'Earn points for each approved Look Board you curate, showcasing your creativity and design expertise.',
  },
  {
    title: 'Curate Look Board Requests:',
    text:
      'Receive points for curating Look Boards for requests, helping users bring their decor visions to life.\n' +
      'Nominate Active Ambassadors: Earn points by nominating friends or colleagues who are approved and become active Ambassadors, contributing to the growth of our community.',
  },
  {
    title: 'Share Showcase & Look Boards:',
    text:
      'Earn points by sharing your Showcase Page and curated Look Boards on your social media channels. Points vary depending on the level of engagement generated.\n',
  },
];

export const ambassadorSectionTitles = {
  embraceYourAmbass: 'EMBRACE YOUR AMBASSADORSHIP',
  launchYourAmbassJourney: 'LAUNCH YOUR AMBASSADOR JOURNEY',
  perksAndOpportunities: 'PERKS AND OPPORTUNITIES',
  connectAndCreate: 'CONNECT AND CREATE',
  supportAndGrowth: 'SUPPORT AND GROWTH',
  amplifyYourImpact: 'AMPLIFY YOUR IMPACT',
  thriveWithAffiliates: 'THRIVE WITH AFFILIATES',
  elevateYourAmbass: 'ELEVATE YOUR AMBASSADORSHIP',
};

export const standardSectionTitles = {
  ambassadorFeatures: 'AMBASSADOR FEATURES & BENEFITS',
  ignitingJourney: 'IGNITING YOUR AMBASSADOR JOURNEY',
  connectCreative: 'CONNECT WITH A CREATIVE COMMUNITY',
  transformPassion: 'TRANSFORM YOUR PASSION INTO PROFIT',
};

export const standardFaqAttributes = [
  {
    title: 'Passion for Home Decor:',
    text:
      'Ambassadors embrace the joy of creating stunning living spaces with genuine love and enthusiasm.\n',
  },
  {
    title: 'Creative Vision:',
    text:
      'Ambassadors bring spaces to life with their unique and imaginative design perspective, adding style and flair to every project.\n',
  },
  {
    title: 'Attention to Detail:',
    text:
      'Ambassadors elevate the aesthetic of rooms by pinpointing and enhancing even the smallest elements, ensuring a cohesive and polished look.\n',
  },
  {
    title: 'Talent for Styling:',
    text:
      'Ambassadors showcase their natural ability to arrange furniture and decor, effortlessly creating visually captivating spaces.\n',
  },
  {
    title: 'Expertise in Trends:',
    text:
      'Ambassadors stay ahead of the curve with the latest trends in home decor, infusing fresh ideas and innovation into your designs.',
  },
  {
    title: 'Strong Communication Skills:',
    text:
      'Ambassadors can articulate their design ideas effectively and offer valuable advice to others, fostering meaningful connections within the community.',
  },
  {
    title: 'Entrepreneurial Spirit:',
    text:
      'Ambassadors desire to turn their passion for decor into a lucrative side hustle, seizing opportunities to monetize their creative talents.',
  },
  {
    title: 'Commitment to Excellence:',
    text:
      'Ambassadors strive for perfection in every project, delivering high-quality work and setting new standards of excellence.',
  },
  {
    title: 'Collaborative Nature:',
    text:
      'Ambassadors thrive in a supportive community where sharing ideas, collaborating with others, and contributing to collective growth are celebrated.',
  },
  {
    title: 'Adaptability:',
    text:
      'Ambassadors embrace new ideas and approaches, adjusting their methods to meet the unique requirements of each project.',
  },
  {
    title: 'Tech-Savvy:',
    text:
      'Ambassadors are able to harness the power of technology and online platforms to engage with their audience effectively and showcase their creativity to the world.',
  },
];

export const faqBenefits = [
  {
    title: 'Beautifying Spaces:',
    text:
      'Ambassadors have the opportunity to help others achieve their ideal home aesthetic.\n',
  },
  {
    title: 'Community Membership:',
    text:
      'Ambassadors will be invited to join our private Facebook Group, where they can mingle, share, and get tips on promoting their work.',
  },
  {
    title: 'Showcase Page:',
    text:
      'Ambassadors get a personalized, theme-based Showcase page that can be set up in minutes. A Showcase Page features their brand elements, favorite inspiration images, curated Look Boards, and links to their socials.\n',
  },
  {
    title: 'Promoted Content:',
    text:
      'Active Ambassadors will be promoted on Pin the Look’s socials, which will result in gaining more followers and leads.',
  },
  {
    title: 'Home Decor Gift Cards:',
    text:
      'Ambassadors will earn points for adding beautiful inspiration images, curating Look Boards, helping others get the look they love, and more! Points can be redeemed for home furnishing Gift Cards.',
  },
  {
    title: 'Affiliate Commissions:',
    text:
      'Active Ambassadors will be invited to join our affiliate program, enabling them to include commissionable products in Look Boards.',
  },
];

export const faqEarnAffiliate = [
  {
    title: 'Promote on Social Media:',
    text:
      "Share your Look Boards and highlight specific MyLookShops.com products on social media. With each sale generated through your posts, you'll earn commissions.",
  },
  {
    title: 'Curate Requests:',
    text:
      "Match Requests with Affiliate products, helping others achieve their desired look. Not only will you gain followers, but you'll also earn commissions when purchases are made.\n",
  },
  {
    title: 'Platform Visitors:',
    text:
      'Pin the Look will encourage site visitors to browse Ambassadors Showcase pages, which can lead to direct purchases from your Look Boards. The more active you are, the more exposure your Showcase will get on Pin the Look!',
  },
];
