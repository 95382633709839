import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { errorMessages } from 'modules/auth/signIn/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import { routesByName } from 'constants/routes';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import comingSoonBg from 'assets/img/coming-soon-bg.png';
import shoppingSpree from 'assets/img/shopping-spree.jpg';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import { ReactComponent as Logo } from 'assets/img/logo.svg';
import classes from './ComingSoon.module.scss';
import { comingSoonFormFields } from './constants';
import comingSoonService from './comingSoonService';
import comingSoonImg from './coming-soon-mobile.jpg';

const emailPattern = /.+@.+..+/;

const ComingSoon = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const onSubmit = useCallback(
    async (values) => {
      try {
        let reCaptchaToken;
        if (!executeRecaptcha) {
          reCaptchaToken = null;
        }
        reCaptchaToken = await executeRecaptcha(
          reCaptchaActions.create_notification_email
        );
        await comingSoonService.sendNotifyEmail({
          zip_code: values.zipCode,
          email: values.email,
          reCaptchaToken,
        });
        successToastr('Success', 'Message successfully sent');
      } catch (e) {
        errorToastr(e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [executeRecaptcha]
  );

  const signInValidator = ({
    [comingSoonFormFields.email]: email,
    [comingSoonFormFields.zipCode]: zipCode,
  }) => {
    const errors = {};

    if (!email) {
      errors.email = errorMessages.required;
    } else if (!emailPattern.test(email)) {
      errors.email = errorMessages.emailInvalid;
    }

    if (!zipCode) {
      errors.zipCode = errorMessages.required;
    }
    return errors;
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${comingSoonBg})` }}
    >
      <Logo
        width={matchesMediaQuery ? 120 : 200}
        // onClick={goToHomePage}
        className={classes.logo}
      />
      <div className={classes.mainBlock}>
        <h1 className={classes.title}>
          Something <br />
          <span className={classes.titleBig}>Beautiful</span> <br />
          is Coming 06.25.24
        </h1>
        <img
          src={shoppingSpree}
          className={classes.shoppingSpree}
          alt="shoppingSpree"
        />
        <img src={comingSoonImg} className={classes.mobileBg} alt="" />
        <div className={classes.formWrapper}>
          <p className={classes.formTitle}>Get Notified & Enter to Win!</p>
          <Form
            validate={signInValidator}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className="d-flex flex-wrap justify-content-between">
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Enter Email"
                      type="email"
                      name={comingSoonFormFields.email}
                      component={TextInputWrapper}
                    />
                  </div>
                  <div className={classes.halfWidthContainer}>
                    <Field
                      required
                      label="Enter Zip Code"
                      name={comingSoonFormFields.zipCode}
                      component={TextInputWrapper}
                    />
                  </div>
                </div>
                <div className="text-center">
                  <FinalFormSubmitButton
                    className={classes.submitBtn}
                    size="lg"
                    square
                  >
                    Notify Me
                  </FinalFormSubmitButton>
                </div>
              </form>
            )}
          />
        </div>
        <div className={classes.blockFooter}>
          <div
            className={classes.footerItem}
            onClick={() => {
              history.push(routesByName.contact);
            }}
          >
            <SpriteIcon name="envelope-2" />
            Contact Us
          </div>
          <p className="text-xs">
            Prize winners must be based in the contiguous U.S.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
