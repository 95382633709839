import React from 'react';
import PropTypes from 'prop-types';
import OverlayLabel from 'components/imageThumbnails/OverlayLabel/OverlayLabel';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';

const RejectedLookBoardOverlay = ({ id, reason, onEdit, onDelete }) => {
  return (
    <div className="h-100 flex-center position-relative">
      <OverlayLabel text="Edits Required" />
      <div className={classes.bottomControls}>
        <IconButton
          className="mr-1"
          size="sm"
          variant="inverted-grey"
          data-show-edit
          onClick={onEdit}
        >
          <SpriteIcon name="edit" size="sm" />
        </IconButton>
        <IconButton
          className="mr-1"
          size="sm"
          variant="inverted-grey"
          data-id={id}
          onClick={onDelete}
        >
          <SpriteIcon name="trash" size="sm" />
        </IconButton>
        <InfoIconPopover
          fullWidth
          iconProps={{
            variant: 'inverted-grey',
          }}
        >
          <p className="font-semi-bold mb-1">Reject reason:</p>
          <p>{reason}</p>
        </InfoIconPopover>
      </div>
    </div>
  );
};

RejectedLookBoardOverlay.propTypes = {
  id: PropTypes.number.isRequired,
  reason: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

RejectedLookBoardOverlay.defaultProps = {
  reason: 'This item was rejected. Please edit information',
};

export default RejectedLookBoardOverlay;
