import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Slider from 'react-slick';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import SubStyleCard from 'components/PreferenceQuiz/SubStyleCard/SubStyleCard';
import classes from './SubStylesSlider.module.scss';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const SubStylesSlider = ({ currentValue, subStyles, onLike }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const handleSlideChange = useCallback((oldIndex, newIndex) => {
    setActiveSlideIndex(newIndex);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleLike = useCallback(
    (id, isLiked) => {
      onLike(id, isLiked);
      handleSlideNext();
    },
    [handleSlideNext, onLike]
  );

  const slides = useMemo(() => {
    return subStyles.length > 3 ? subStyles : [...subStyles, ...subStyles];
  }, [subStyles]);

  const currentSlideNumber = useMemo(() => {
    const number = activeSlideIndex + 1;
    if (subStyles.length > 3 || number <= 3) {
      return number;
    }
    return number - 3;
  }, [subStyles, activeSlideIndex]);

  return (
    <>
      <div className="text-center font-title">
        <span>{currentSlideNumber}/</span>
        <span className="text-gray">{subStyles.length}</span>
      </div>
      <div className="d-flex align-items-center mb-2">
        {!matchesMediaQuery && (
          <>
            <IconButton size="sm" onClick={handleSlidePrev}>
              <SpriteIcon name="prev-icon" size="xs" />
            </IconButton>
          </>
        )}
        <div className="flex-fill" style={{ width: 100 }}>
          <Slider
            ref={sliderRef}
            arrows={false}
            infinite
            slidesToShow={3}
            slidesToScroll={1}
            beforeChange={handleSlideChange}
            centerMode
            centerPadding="0px"
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  className: 'slider variable-width',
                  variableWidth: true,
                },
              },
            ]}
          >
            {slides.map((subStyle, index) => (
              <div
                className={classes.slideWrapper}
                key={subStyle.id}
                style={{ width: 310 }}
              >
                <div
                  className={clsx(classes.slide, {
                    [classes.active]: index === activeSlideIndex,
                  })}
                >
                  <SubStyleCard
                    onLike={handleLike}
                    onSkip={handleSlideNext}
                    selected={currentValue.includes(subStyle.id)}
                    showLike={index === activeSlideIndex}
                    {...subStyle}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {!matchesMediaQuery && (
          <>
            <IconButton size="sm" onClick={handleSlideNext}>
              <SpriteIcon name="next-icon" size="xs" />
            </IconButton>
          </>
        )}
      </div>
      {!matchesMediaQuery && (
        <>
          <div className="text-center font-italic text-lg">
            Scroll through all images and{' '}
            {
              <IconButton className={classes.likeIcon}>
                <SpriteIcon name="like-filled" size="sm" />
              </IconButton>
            }{' '}
            the ones you like and{' '}
            <IconButton className={classes.skipIcon}>Skip</IconButton> the ones
            you don&apos;t.
          </div>
        </>
      )}
    </>
  );
};

SubStylesSlider.propTypes = {
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  subStyles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLike: PropTypes.func.isRequired,
};

export default SubStylesSlider;
