import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import SpriteIcon from 'components/ui/SpriteIcon';
import Button from 'components/ui/Button/Button';
import classes from './AmbassadorSteps.module.scss';
import { stepsConfig } from './stepsConfig';
import { routesByName } from '../../../../constants/routes';

const AmbassadorSteps = () => {
  const history = useHistory();

  const handleVideoOpen = useCallback(
    (videoLink) => {
      history.push({
        pathname: routesByName.dashboard.ambassadorVideos,
        search: `?videoUrl=${videoLink}`,
      });
    },
    [history]
  );

  const handleLinkOpen = (link, isExternal, isVideo) => {
    if (isExternal) {
      window.open(link, '_blank');
    } else if (isVideo) {
      handleVideoOpen(link);
    } else {
      history.push(link);
    }
  };

  return (
    <div className={classes.root}>
      {stepsConfig.map((step) =>
        step.url ? (
          <a href={step.url} className={classes.stepItem} key={step.id}>
            <div className={classes.titleWrapper}>
              <div className={classes.number}>{step.id}</div>
              <div className={classes.title}>{step.title}</div>
            </div>
            <div className={classes.description}>{step.description}</div>
            <div className={classes.image}>
              <img src={step.image} alt="step" />
            </div>
          </a>
        ) : (
          <div className={classes.stepItem} key={step.id}>
            <div className={classes.titleWrapper}>
              <div className={classes.number}>{step.id}</div>
              <div className={classes.title}>{step.title}</div>
            </div>
            <div className={classes.description}>{step.description}</div>
            {step.items?.map((item) => {
              return (
                <div className={classes.setupItem}>
                  <div className={classes.iconWrapper}>
                    <SpriteIcon name={item.icon} size="xl" />
                    <div
                      className={classes.iconPlayVideo}
                      onClick={() => handleVideoOpen(item.videoUrl)}
                    >
                      <SpriteIcon name="play-arrow" size="md" />
                    </div>
                  </div>
                  <div>
                    <p className={classes.stepItemTitle}>{item.title}</p>
                    <Button
                      color="secondary"
                      variant="outlined"
                      size="xs"
                      className={classes.setupItemButton}
                      onClick={() =>
                        handleLinkOpen(item.buttonUrl, item.isExternal)
                      }
                    >
                      {item.buttonText}
                    </Button>
                  </div>
                </div>
              );
            })}
            {!!step.image && (
              <div className={classes.image}>
                <img src={step.image} alt="step" />
              </div>
            )}

            <div className="p-3">
              <Button
                color="primary"
                size="md"
                onClick={() =>
                  handleLinkOpen(step.buttonUrl, step.isExternal, step.isVideo)
                }
              >
                {step.buttonIcon === 'play-arrow' && (
                  <SpriteIcon name={step.buttonIcon} />
                )}
                {step.buttonLabel}
                {step.buttonIcon === 'arrow-forward' && (
                  <SpriteIcon name={step.buttonIcon} />
                )}
              </Button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AmbassadorSteps;
