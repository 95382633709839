import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import FilterInput from 'modules/dashboard/components/FilterInput/FilterInput';
import {
  myImagesSortOptions,
  myImageTypeOptions,
} from 'modules/dashboard/constants';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import AddedImageItem from 'modules/dashboard/myImages/components/AddedImageItem/AddedImageItem';
import classes from 'modules/dashboard/myImages/MyImages.module.scss';
import ConfirmModal from 'components/modals/ConfirmModal';

const MyImagesComponent = ({
  loading,
  hasMore,
  loadMore,
  imgList,
  currentSource,
  currentImgType,
  currentSortType,
  onChangeImgType,
  onChangeSortType,
  onUnlike,
  onDeleteAddedImage,
  onOpenDetail,
}) => {
  const currentUserId = useSelector((state) => state.auth.user.id);
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );

  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleOpenPreview = useCallback(
    ({ currentTarget }) => {
      const userId = currentTarget.getAttribute('data-user-id');
      const hash = currentTarget.getAttribute('data-hash');
      const imgUrl = buildResourceUrl(inspirationImageUrl.medium, userId, hash);

      setImgPreviewUrl(imgUrl);
      setImgModalOpen(true);
    },
    [inspirationImageUrl.medium]
  );

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleLoadMore = useCallback(() => {
    if (!hasMore || loading) {
      return;
    }
    loadMore();
  }, [loading, hasMore, loadMore]);

  const handleClickDelete = useCallback((imgId) => {
    setSelectedImage(imgId);
    setConfirmModalOpen(true);
  }, []);

  const handleDeleteImage = useCallback(
    (confirm) => {
      if (confirm) {
        onDeleteAddedImage(selectedImage);
      }
      setConfirmModalOpen(false);
      setSelectedImage(null);
    },
    [onDeleteAddedImage, selectedImage]
  );

  return (
    <>
      <div className={classes.section}>
        <div className="d-flex justify-content-between mb-3">
          <FilterInput
            className={classes.imgTypeSelect}
            options={myImageTypeOptions}
            currentValue={currentImgType}
            onChange={onChangeImgType}
          />
          <FilterInput
            additionalLabel="Sort by:"
            className={classes.sortTypeSelect}
            options={myImagesSortOptions}
            currentValue={currentSortType}
            onChange={onChangeSortType}
          />
        </div>
        {imgList.length > 0 ? (
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={handleLoadMore}
            initialLoad={false}
          >
            <div className="row">
              {imgList.map(
                ({
                  id,
                  title,
                  url,
                  type,
                  description,
                  roomTypeId,
                  itemClassId,
                  approval,
                  publish,
                  ownership,
                  permission,
                  rejectedReason,
                  editUnderReview,
                  userId: ownerId,
                  media: { userId, hash },
                }) => (
                  <div key={id} className="col-3">
                    <AddedImageItem
                      id={id}
                      title={title}
                      url={url}
                      type={type}
                      description={description}
                      roomTypeId={roomTypeId}
                      itemClassId={itemClassId}
                      userId={userId}
                      hash={hash}
                      currentSource={currentSource}
                      approval={approval}
                      publish={publish}
                      rejectedReason={rejectedReason}
                      ownership={ownership}
                      permission={permission}
                      editUnderReview={Boolean(editUnderReview)}
                      showPermissionSwitch={ownerId === currentUserId}
                      onUnlike={onUnlike}
                      onOpenPreview={handleOpenPreview}
                      onDeleteAddedImage={handleClickDelete}
                      onOpenDetail={onOpenDetail}
                    />
                  </div>
                )
              )}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="flex-fill flex-center font-title text-gray text-lg">
            List is Empty
          </div>
        )}
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <ConfirmModal
        open={confirmModalOpen}
        onClose={handleDeleteImage}
        title={
          <>
            Are you sure you want to <br />
            <span className="primary-color">delete</span> image?
          </>
        }
      />
    </>
  );
};

MyImagesComponent.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  imgList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      media: PropTypes.shape({
        userId: PropTypes.number.isRequired,
        hash: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  currentSource: PropTypes.string.isRequired,
  currentImgType: PropTypes.string.isRequired,
  currentSortType: PropTypes.string.isRequired,
  onChangeImgType: PropTypes.func.isRequired,
  onChangeSortType: PropTypes.func.isRequired,
  onUnlike: PropTypes.func.isRequired,
  onDeleteAddedImage: PropTypes.func.isRequired,
  onOpenDetail: PropTypes.func.isRequired,
};

export default MyImagesComponent;
