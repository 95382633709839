import {
  requestFormFields,
  errorMessages,
} from 'modules/requestTheLook/requestDetails/requestForm/constants';

const maxLengthError = (maxLength) =>
  `Maximum number of characters ${maxLength}`;

const requestFormValidator = ({ [requestFormFields.title]: title }) => {
  const errors = {};

  if (!title) {
    errors.title = errorMessages.required;
  } else if (title.length > 128) {
    errors.title = maxLengthError(128);
  }

  return errors;
};

export default requestFormValidator;
