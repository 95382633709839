import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './FilterInput.module.scss';
import { productTypeKeys } from '../../../product/constants';

const FilterInput = ({
  className,
  additionalLabel,
  options,
  subOptions,
  currentValue,
  onChange,
  ambassador,
  currentSubValue,
}) => {
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(undefined);
  const [subOption, setSubOption] = useState(
    currentValue === productTypeKeys.affiliate
  );

  useEffect(() => {
    const refWidth = inputRef.current.clientWidth;
    setPopoverWidth(refWidth);
  }, []);
  const optionTitle = useMemo(() => {
    const combinedArr = subOptions ? [...options, ...subOptions] : options;
    const currentOption = combinedArr.find(
      ({ value }) => value === currentValue
    );

    return currentOption ? currentOption.label : '';
  }, [subOptions, options, currentValue]);

  const handleOpenDropdown = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleToggleSubTypes = useCallback(() => {
    setSubOption((prevState) => !prevState);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChangeValue = useCallback(
    ({ currentTarget }) => {
      const option = currentTarget.getAttribute('data-option');
      const subOptionTarget = currentTarget.getAttribute('data-sub-option');
      onChange(option, subOptionTarget);
      setAnchorEl(null);
    },
    [onChange]
  );

  return (
    <>
      <div
        className={`${classes.root} ${className}`}
        onClick={handleOpenDropdown}
        ref={inputRef}
      >
        <span>{`${additionalLabel} ${optionTitle}`}</span>
        <SpriteIcon
          name="arrow-down"
          size="sm"
          className={clsx(classes.icon, { [classes.open]: Boolean(anchorEl) })}
        />
      </div>
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleCloseDropdown}
        anchorEl={anchorEl}
        classes={{ paper: classes.optionsPopover }}
        PaperProps={{ style: { width: popoverWidth } }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options.map(({ label, value }) => {
          if (!ambassador && label === 'Requests') {
            return null;
          }
          return value === productTypeKeys.affiliate ? (
            <div
              key={value}
              className={clsx(classes.option, {
                'font-semi-bold': value === currentValue,
              })}
              onClick={handleToggleSubTypes}
            >
              {label}
              <SpriteIcon
                className={clsx(classes.subOptionIcon, {
                  [classes.open]: subOption,
                })}
                name="arrow-down"
                size="xs"
              />
              {subOption ? (
                <div>
                  <div
                    className={clsx(classes.option, classes.subOption, {
                      [classes.subOptionChecked]:
                        currentValue === productTypeKeys.affiliate &&
                        currentSubValue === null,
                    })}
                    data-option={value}
                    data-sub-option={null}
                    onClick={handleChangeValue}
                  >
                    All
                  </div>
                  {subOptions.map(({ label: subLabel, value: subValue }) => {
                    return (
                      <div
                        key={subValue}
                        className={clsx(classes.option, classes.subOption, {
                          [classes.subOptionChecked]:
                            subValue === currentSubValue,
                        })}
                        data-option={value}
                        data-sub-option={subValue}
                        onClick={handleChangeValue}
                      >
                        {subLabel}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          ) : (
            <div
              key={value}
              className={clsx(classes.option, {
                'font-semi-bold': value === currentValue,
              })}
              data-option={value}
              onClick={handleChangeValue}
            >
              {label}
            </div>
          );
        })}
      </Popover>
    </>
  );
};

FilterInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subOptions: PropTypes.arrayOf(PropTypes.shape({})),
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  additionalLabel: PropTypes.string,
  className: PropTypes.string,
  currentSubValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ambassador: PropTypes.number,
};

FilterInput.defaultProps = {
  additionalLabel: '',
  className: '',
  ambassador: 0,
  subOptions: null,
  currentSubValue: null,
};

export default FilterInput;
