import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { professionsMap } from 'modules/account/profileAndPreferences/preferences/constants';
import {
  getFilteredOptionsByPreferences,
  getFilteredOptionsByPreferencesById,
  getStyleImgUrl,
  getSubStyleImgUrl,
  getSubStylesByPreference,
} from 'modules/app/store/selectors';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import transformArrayToMap from 'utils/transformArrayToMap';
import subStylesDescriptionsArray from 'constants/subStylesDescriptions';
import classes from 'modules/account/profileAndPreferences/preferences/Preferences.module.scss';
import Button from 'components/ui/Button/Button';
import { Tooltip } from '@material-ui/core';

const PreferencesView = ({ onEnableEditMode, handleOpenGetStartedModal }) => {
  const {
    config,
    enums: { vibes, styles, subStyles, roomTypes, colors, priceRanges },
  } = useSelector((state) => state.app);
  const { preferences } = useSelector(
    (state) => state.auth.user || { preferences: null }
  );

  const settings = useMemo(
    () => ({
      profession: preferences.profession,
      styles: getFilteredOptionsByPreferencesById(styles, preferences.styles),
      subStyles: getSubStylesByPreference(
        styles,
        subStyles,
        preferences.styles,
        preferences.subStyles
      ),
      roomTypes: getFilteredOptionsByPreferences(
        roomTypes,
        preferences.roomTypes
      ),
      colors: preferences.colors,
      vibes: preferences.vibes,
      vibesEnum: vibes,
      colorsEnum: colors,
      priceRange: priceRanges[preferences.priceRange],
      styleImgUrl: getStyleImgUrl(config),
      subStyleImgUrl: getSubStyleImgUrl(config),
      subStylesDescriptions: transformArrayToMap(subStylesDescriptionsArray),
    }),
    [
      colors,
      config,
      preferences.colors,
      preferences.priceRange,
      preferences.profession,
      preferences.roomTypes,
      preferences.styles,
      preferences.subStyles,
      preferences.vibes,
      priceRanges,
      roomTypes,
      styles,
      subStyles,
      vibes,
    ]
  );

  const colorOption = useMemo(() => {
    return Object.values(settings.colorsEnum)
      .filter((color) =>
        Object.values(settings.colors).includes(color.color_group_id)
      )
      .map((color) => color.id);
  }, [settings.colors, settings.colorsEnum]);

  return (
    <>
      <Button
        size="sm"
        className={classes.getStartedButton}
        onClick={handleOpenGetStartedModal}
        variant="outlined"
      >
        Get Started on Pin the Look
      </Button>
      <Tooltip title="Edit Preferences" leaveDelay={1000}>
        <div className={classes.editBtn}>
          <IconButton color="primary" onClick={onEnableEditMode}>
            <SpriteIcon name="edit" size="sm" />
          </IconButton>
        </div>
      </Tooltip>

      {settings.profession && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Your Description</p>
          <span className="text-uppercase font-title">
            {professionsMap[settings.profession].title}
          </span>
        </div>
      )}

      {settings.vibes?.length > 0 && (
        <div className="mb-4">
          <p className={classes.preferenceTitle}>Your Style Vibe</p>
          <div className="d-flex flex-wrap">
            {settings.vibes.map((id) => (
              <div className={classes.vibeCard} key={id}>
                {settings.vibesEnum[id].publicName}
              </div>
            ))}
          </div>
        </div>
      )}

      {settings.styles.length > 0 && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Favorite Styles</p>
          <div className="d-flex flex-wrap">
            {settings.styles.map(({ id, name, media: { hash } }) => (
              <div className={classes.styleCard} key={id}>
                <div className={classes.stylesImgContainer}>
                  <img
                    src={settings.styleImgUrl.replace(
                      urlPlaceholders.hash,
                      hash
                    )}
                    alt={name}
                  />
                </div>
                <p className="text-ellipsis text-nowrap text-uppercase font-title text-sm p-2">
                  {name}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {settings.subStyles.map(
        ({ id: styleId, name: styleName, selectedSubStyles }) =>
          selectedSubStyles.length > 0 && (
            <div className="mb-5" key={styleId}>
              <p className={classes.preferenceTitle}>
                Selected Substyles for {styleName}
              </p>
              <div className="d-flex flex-wrap">
                {selectedSubStyles.map(({ id, name, media: { hash } }) => (
                  <div className={classes.styleCard} key={id}>
                    <div className={classes.stylesImgContainer}>
                      <img
                        src={settings.subStyleImgUrl.replace(
                          urlPlaceholders.hash,
                          hash
                        )}
                        alt={name}
                      />
                    </div>
                    <div className={classes.subStylesAbout}>
                      <div className="font-semi-bold">
                        {settings.subStylesDescriptions[id]?.name}
                      </div>
                      <div className={classes.subStylesDesc}>
                        {settings.subStylesDescriptions[id]?.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
      )}

      {settings.roomTypes.length > 0 && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Room Types</p>
          <div className="d-flex flex-wrap">
            {settings.roomTypes.map(({ id, name, icon }) => (
              <div
                className="d-flex flex-column align-items-center mr-5 mb-2"
                key={id}
              >
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: icon }}
                  className={classes.roomTypeIcon}
                />
                <p className="text-uppercase font-title text-sm">{name}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {colorOption.length > 0 && (
        <div className="mb-5">
          <p className={classes.preferenceTitle}>Favorite Colors</p>
          <div className="d-flex flex-wrap">
            {colorOption.map((id) => (
              <ColorPoint
                key={id}
                id={id}
                size="xl"
                title={settings.colorsEnum[id].name}
                color={settings.colorsEnum[id].code}
                selected
                className="mr-4 mb-4"
              />
            ))}
          </div>
        </div>
      )}

      {settings.priceRange && (
        <div>
          <p className={classes.preferenceTitle}>Price Range</p>
          <p className="text-uppercase font-title">{`${settings.priceRange.title} ${settings.priceRange.name}`}</p>
        </div>
      )}
    </>
  );
};

PreferencesView.propTypes = {
  onEnableEditMode: PropTypes.func.isRequired,
  handleOpenGetStartedModal: PropTypes.func.isRequired,
};

export default PreferencesView;
