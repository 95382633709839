import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import buildResourceUrl from 'utils/buildResourceUrl';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ButtonHowItWorks from 'components/ui/ButtonHowItWorks';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import Button from 'components/ui/Button/Button';
import ButtonPopover from 'components/ui/ButtonPopover/ButtonPopover';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import {
  createImageInstantlyForAction,
  updateCreationImageStatusAction,
} from 'modules/inspirationImage/store/actions';
import {
  togglePinMediaModalAction,
  toggleVerificationModalAction,
} from 'modules/layout/store/actions';
import featureNameKeys from 'constants/featureNames';
import { routesByName } from 'constants/routes';
import requestExampleImg from 'assets/img/request-the-look-page/request-example.png';
import classes from './CreateRequest.module.scss';

const MainArea = ({ onOpenPreview }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const library = useSelector((state) => state.inspirationImage.library);
  const inspirationImageId = useSelector(
    (state) => state.requestTheLook.requestData.inspirationImageId
  );
  const selectedImage = useMemo(() => library[inspirationImageId], [
    library,
    inspirationImageId,
  ]);

  const history = useHistory();
  const { pathname } = useLocation();
  const [exampleModalOpen, setExampleModalOpen] = useState(false);

  const handleExampleModalOpen = useCallback(() => {
    setExampleModalOpen(true);
  }, []);

  const handleExampleModalClose = useCallback(() => {
    setExampleModalOpen(false);
  }, []);

  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const imgUrlDetail = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrlDetail);
  }, [onOpenPreview, imgUrlDetail]);

  const handleAddImageModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    dispatch(createImageInstantlyForAction(featureNameKeys.request));
  }, [currentUser, dispatch, pathname, history]);

  const handlePinMediaModalOpen = useCallback(() => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }

    dispatch(togglePinMediaModalAction(true));
    dispatch(updateCreationImageStatusAction(featureNameKeys.request));
  }, [dispatch, currentUser, pathname, history]);

  return (
    <div className={createLookBoardClasses.mainAreaWrapper}>
      <div className={classes.mainAreaContent}>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-start">
            <p
              className="font-title text-capitalize mb-1 flex-fill"
              style={{ fontSize: 24 }}
            >
              REQUEST THE LOOK
            </p>
            <ButtonHowItWorks text="Request The Look" />
          </div>
          <div>
            <ButtonPopover
              buttonProps={{
                variant: 'outlined',
                inline: true,
              }}
              buttonText="View Steps"
              size="sm"
            >
              <div className={createLookBoardClasses.processDescription}>
                <h3 className={createLookBoardClasses.processTitle}>
                  {' '}
                  <span className="text-color">STEPS</span> TO REQUEST THE LOOK
                  OF AN IMAGE
                </h3>
                <p className="primary-color font-semi-bold">How it Works:</p>
                <p>
                  <b>Step 1.</b> Select an Inspiration Image
                </p>
                <p>
                  <b>Step 2.</b> Select Request Type at the Right
                </p>
                <p>
                  <b>Step 3.</b> Add Request Details on the Next Page
                </p>
                <p>
                  <b>Step 4.</b> View Recommendations in Your Dashboard
                </p>
              </div>
            </ButtonPopover>
          </div>

          <div className="d-flex align-items-center mb-3 mt-3">
            <IconButton
              variant="inverted-white"
              onClick={handleAddImageModalOpen}
            >
              <SpriteIcon name="image-upload" />
            </IconButton>
            <IconButton
              className="ml-1"
              variant="inverted-white"
              onClick={handlePinMediaModalOpen}
            >
              <SpriteIcon name="pin" />
            </IconButton>
            <button
              className="ml-auto link-underline btn-clear cursor-pointer font-italic text-color text-sm"
              type="button"
              onClick={handleExampleModalOpen}
            >
              View Request Example
            </button>
          </div>
          <div className={classes.imageContainer}>
            {selectedImage ? (
              <div className={classes.imgPreview}>
                <IconButton
                  className={classes.selectedImageZoom}
                  color="secondary"
                  size="sm"
                  onClick={handleOpenPreview}
                >
                  <SpriteIcon name="loupe" size="sm" />
                </IconButton>
                <img
                  className={classes.mainImage}
                  src={imgUrl}
                  alt="mainImage"
                />
              </div>
            ) : (
              <div className={classes.emptyTemplate}>
                <div className="mb-1">
                  <span>Select an Image to Display it Here</span>
                </div>
                <div className="mb-1">or</div>
                <div className="mb-1">
                  <Button
                    className={classes.pinBtn}
                    size="xs"
                    inline
                    style={{ minWidth: 130 }}
                    variant="outlined"
                    onClick={handleAddImageModalOpen}
                  >
                    <div className="d-flex align-items-center">
                      Add New Image
                      <div className={classes.pinBtnIcon}>
                        <SpriteIcon name="pin" size="xs" />
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <ImgPreviewModal
          open={exampleModalOpen}
          onClose={handleExampleModalClose}
          url={requestExampleImg}
        />
      </div>
    </div>
  );
};

MainArea.propTypes = {
  onOpenPreview: PropTypes.func.isRequired,
};

export default MainArea;
