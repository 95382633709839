import { routesByName } from 'constants/routes';

export const aboutUsConfigFirst = [
  {
    id: 1,
    title: 'Get the Look',
    description:
      'Browse curated Look Boards based on popular room images in your style.',
    icon: 'sofa',
    route: routesByName.getTheLook.index,
  },
  {
    id: 2,
    title: 'Request the Look',
    description:
      'Request the Look of your favorite images and get Look Board Recommendations.',
    icon: 'exchange',
    route: routesByName.requestTheLook.index,
  },
  {
    id: 3,
    title: 'Curate the Look',
    description:
      'Use our easy drag & drop design tools to curate the look <span class="text-nowrap">of your favorite room images yourself!</span>',
    icon: 'living-room',
    route: routesByName.curateTheLook.index,
  },
];

export const aboutUsConfigSecond = [
  {
    id: 1,
    title: 'Crowdsourced Ideas',
    description:
      'Get ideas and input from our Ambassadors - decor lovers & skilled online shoppers with a passion for home decorating.',
    icon: 'light-bulb',
  },
  {
    id: 2,
    title: 'Streamlined Process',
    description:
      'Save time with access to curated looks, comparison tools, and vetted products to quickly and easily get the look you love!',
    icon: 'exchange-circle',
  },
  {
    id: 3,
    title: 'Elevated Home Decor',
    description:
      'Beautify your home and dazzle your guests with curated looks based on the world’s most popular room designs and inspirations.',
    icon: 'cart',
  },
];

export default {
  aboutUsConfigFirst,
  aboutUsConfigSecond,
};
