import React, { useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import buildResourceUrl from 'utils/buildResourceUrl';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import posterImg from 'assets/video/ptl-overview-preview.jpg';
import AmbassadorSteps from './AmbassadorSteps/AmbassadorSteps';
import { ambassadorPerks } from './constants';
import classes from './AmbassadorManagement.module.scss';

const AmbassadorManagement = () => {
  const videoRef = useRef();
  const handlePause = useCallback((e) => e.target.blur(), []);
  const videoUrlPlaceholder = useSelector(
    (state) => state.app.config.endpoints.media.videos.standart
  );

  const videoHomepageLink = useMemo(() => {
    return buildResourceUrl(videoUrlPlaceholder, null, null, 'homepage');
  }, [videoUrlPlaceholder]);

  return (
    <section className={classes.root}>
      <h1 className={classes.title}>Ambassador hub</h1>
      <div className={classes.banner}>
        <div className="d-flex align-items-center">
          <h2 className="ml-1 text-md text-uppercase font-semi-bold">
            Ambassador onboarding
          </h2>
        </div>
      </div>
      <div className={classes.mainWrapper}>
        <div className={classes.content}>
          <div className={classes.contentBlock}>
            <div className="d-flex justify-content-between">
              <h2 className={classes.contentTitle}>
                WELCOME HOME, AMBASSADOR!
              </h2>
              <Link to={routesByName.ambassador.faqs}>
                <Button inline variant="outlined" size="sm">
                  Ambassador FAQs
                </Button>
              </Link>
            </div>
            <p className="font-italic mb-3">
              Let’s start decorating the world together!
            </p>
            <p className={classes.contentDescription}>
              With your eye for design and online shopping skills, as a PTL
              Ambassador you can showcase your sense of style while helping
              others get the look they love – and earn rewards in the process!
            </p>
            <h5 className={classes.listTitle}>Ambassador Perks:</h5>
            <ul className={classes.contentList}>
              {ambassadorPerks.map((perk) => (
                <li key={perk}>{perk}</li>
              ))}
            </ul>
            <h5 className={classes.listTitle}>
              Ready to make yourself at home? We’d love to give you a tour!
            </h5>
          </div>
          <div className={classes.videoWrapper}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              ref={videoRef}
              src={videoHomepageLink}
              preload="metadata"
              onPause={handlePause}
              controls
              poster={posterImg}
            />
          </div>
        </div>

        <div className={classes.sectionRoot}>
          <AmbassadorSteps />
        </div>
      </div>
    </section>
  );
};

export default AmbassadorManagement;
