import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './OptionsPopover.module.scss';

const OptionsPopover = ({
  anchorEl,
  open,
  onClose,
  onClickInfo,
  onSendMessage,
}) => {
  return (
    <Popover
      classes={{ paper: classes.root }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <button
        type="button"
        className={`${classes.info} mr-1`}
        onClick={onClickInfo}
      >
        <SpriteIcon name="info" size="sm" />
      </button>
      <Button
        onClick={onSendMessage}
        className={classes.btn}
        size="custom"
        color="default"
        style={{ minWidth: 90 }}
      >
        <span>Send Message</span>
      </Button>
    </Popover>
  );
};

OptionsPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClickInfo: PropTypes.func.isRequired,
  onSendMessage: PropTypes.func.isRequired,
};

OptionsPopover.defaultProps = {
  anchorEl: null,
};

export default OptionsPopover;
