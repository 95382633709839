import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { ChromePicker } from 'react-color';
import Button from 'components/ui/Button/Button';
import classes from './ColorPickerPopover.module.scss';

const ColorPickerPopover = ({ open, anchorEl, onClose, onApply }) => {
  const [currentColor, setCurrentColor] = useState({ hex: '#d9cab6' });

  const handleChangeColor = useCallback((color) => {
    setCurrentColor(color);
  }, []);

  const handleSaveColor = useCallback(() => {
    onApply(currentColor.hex);
  }, [onApply, currentColor]);

  return (
    <Popover
      classes={{ paper: classes.root }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <ChromePicker
        disableAlpha
        color={currentColor}
        onChange={handleChangeColor}
      />
      <div className="flex-center p-2">
        <Button
          inline
          size="sm"
          variant="outlined"
          className="mr-1"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button inline size="sm" onClick={handleSaveColor}>
          Save
        </Button>
      </div>
    </Popover>
  );
};

ColorPickerPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
};

ColorPickerPopover.defaultProps = {
  anchorEl: null,
};

export default ColorPickerPopover;
