import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './Button.module.scss';

const sizeToClass = {
  custom: '',
  xs: classes.xs,
  sm: classes.sm,
  md: classes.md,
  lg: classes.lg,
};

const colors = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  grey: 'grey',
};

const variants = {
  default: 'default',
  contained: 'contained',
  outlined: 'outlined',
};

const Button = ({
  className,
  component: Component,
  children,
  color,
  variant,
  inline,
  square,
  size,
  isLoading,
  disabled,
  ...rest
}) => (
  <Component
    className={clsx(classes.basicButton, className, sizeToClass[size], {
      [classes.primary]: color === colors.primary,
      [classes.secondary]: color === colors.secondary,
      [classes.grey]: color === colors.grey,
      [classes.default]: variant === variants.default,
      [classes.contained]: variant === variants.contained,
      [classes.outlined]: variant === variants.outlined,
      [classes.inline]: inline,
      [classes.square]: square,
    })}
    disabled={isLoading || disabled}
    {...rest}
  >
    {!isLoading ? children : <div>Loading...</div>}
  </Component>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(Object.values(colors)),
  variant: PropTypes.oneOf(Object.values(variants)),
  inline: PropTypes.bool,
  square: PropTypes.bool,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.func,
  ]),
  size: PropTypes.oneOf(Object.keys(sizeToClass)),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  component: 'button',
  color: colors.primary,
  variant: variants.contained,
  inline: false,
  size: 'md',
  isLoading: false,
  disabled: false,
  square: false,
};

export default Button;
