import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash-es/groupBy';
import { useSelector } from 'react-redux';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import { routesByName } from 'constants/routes';
import { useHistory, useLocation } from 'react-router-dom';
import productService from 'modules/product/productService';
import clsx from 'clsx';
import useMediaQuery from 'hooks/useMediaQuery';
import { minWidthLg } from 'constants/mediaQueries';
import MixAndMatchItem from './MixAndMatchItem';
import { productsSortKeys } from '../constants';
import findObjectById from '../../../utils/findObjectById';
import classes from './MixAndMatchView.module.scss';

const limit = 6;

const MixAndMatchView = ({
  products,
  onSave,
  onRateProduct,
  isDetailsPage,
}) => {
  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const itemClasses = useSelector((state) => state.app.enums.itemClasses);
  const { mixMatchType, mixMatchSubtype, mixMatchSort } = useSelector(
    (state) => state.getTheLook
  );

  const isLgScreen = useMediaQuery(minWidthLg);
  const [productGroups, setProductGroups] = useState([]);
  const [similarProductGroups, setSimilarProductGroups] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const groups = groupBy(Object.values(products), 'itemClassId');
    const sortedGroups = Object.entries(groups)
      .sort((a, b) => b[1].length - a[1].length)
      .slice(0, limit);
    const productIds = sortedGroups.map(
      // eslint-disable-next-line no-unused-vars
      ([_, groupProducts]) => groupProducts[0].id
    );

    setSelectedProductIds(productIds);
    setProductGroups(sortedGroups);
  }, [products]);

  const highestRatedItems = useMemo(() => {
    const groups = groupBy(products, 'itemClassId');
    const filteredIds = [];
    // eslint-disable-next-line
    for (const group of Object.values(groups)) {
      let highest = group[0];
      group.forEach((item) => {
        if (Number(item.highestRated) > Number(highest.highestRated))
          highest = item;
      });
      filteredIds.push(highest);
    }
    return filteredIds;
  }, [products]);

  const getSimilarProducts = useCallback(async () => {
    let resultArr = [];
    if (highestRatedItems.length) {
      resultArr = await productService.getSimilarProducts(
        highestRatedItems.map((item) => item.id),
        mixMatchSort
      );
    }
    const similarProducts = Object.values(resultArr).flat();
    const groupedFilteredProducts = groupBy(similarProducts, 'itemClassId');

    const sortedSimilarGroups = Object.entries(groupedFilteredProducts)
      .sort((a, b) => b[1].length - a[1].length)
      .slice(0, limit);
    setSimilarProductGroups(sortedSimilarGroups);
  }, [highestRatedItems, mixMatchSort]);

  const getMoreSimilarProducts = useCallback(
    async (classId) => {
      if (mixMatchType === 'curated') return;
      const searchId = highestRatedItems.find(
        (item) => item.itemClassId === classId
      ).id;
      const productsIdx = similarProductGroups.findIndex(
        ([groupId]) => Number(groupId) === classId
      );
      const [, currentSimilar] = similarProductGroups[productsIdx];
      const res = await productService.getSimilarProductById(searchId, {
        params: { offset: currentSimilar.length },
      });
      setSimilarProductGroups((prev) =>
        prev.map((item) => {
          const [groupId, prods] = item;
          if (Number(groupId) === classId) return [groupId, [...prods, ...res]];
          return item;
        })
      );
    },
    [similarProductGroups, mixMatchType, highestRatedItems]
  );

  useEffect(() => {
    getSimilarProducts().then();
    // eslint-disable-next-line
  }, []);

  const handleChangeProduct = useCallback(
    (groupIndex, productId) => {
      const selectedProductIdsCopy = [...selectedProductIds];

      selectedProductIdsCopy[groupIndex] = productId;
      setSelectedProductIds(selectedProductIdsCopy);
    },
    [selectedProductIds]
  );

  const handleSaveMixAndMatch = useCallback(() => {
    if (!authenticated) {
      const redirectUrl = `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`;
      history.push(redirectUrl, {
        ids: selectedProductIds,
      });
      return;
    }

    onSave(selectedProductIds);
  }, [onSave, selectedProductIds, history, pathname, authenticated]);

  const memoizedSimilar = useMemo(() => {
    return similarProductGroups
      ?.reduce(
        (accum, item) => {
          let res = [...accum];
          const [itemId, similarItems] = item;
          if (res.some(([resId]) => resId === itemId)) {
            res = res.map((el) => {
              const [accumItemId, accumSimilarItems] = el;
              if (accumItemId === itemId) {
                const unicItems = [
                  ...accumSimilarItems,
                  ...similarItems,
                ].filter(
                  (it, index, array) =>
                    index === array.findIndex((x) => x.id === it.id)
                );
                return [accumItemId, unicItems];
              }
              return el;
            });
            return res;
          }
          return [...res, item];
        },
        productGroups.map(([id, items]) => [
          id,
          items.map((product) => ({
            ...product,
            price: product.price / 100,
          })),
        ])
      )
      .slice(0, limit);
  }, [productGroups, similarProductGroups]);

  const columnsCount = useMemo(() => {
    if (mixMatchType === 'curated') {
      return productGroups.length <= 4 ? 2 : 3;
    }
    return memoizedSimilar.length <= 4 ? 2 : 3;
  }, [mixMatchType, productGroups, memoizedSimilar]);

  const reduceItems = useMemo(() => {
    return (
      !isLgScreen &&
      columnsCount === 2 &&
      history.location.pathname.includes(routesByName.getTheLook.index)
    );
  }, [columnsCount, history, isLgScreen]);

  const sortItems = useCallback(
    (productGroupsArr) => {
      switch (mixMatchSort) {
        case productsSortKeys.bestMatch: {
          return productGroupsArr;
        }
        case productsSortKeys.mostLikes: {
          return productGroupsArr.map(([id, items]) => [
            id,
            items.sort((a, b) => b.likesCount - a.likesCount),
          ]);
        }
        case productsSortKeys.priceLowToHigh: {
          return productGroupsArr.map(([id, items]) => [
            id,
            items.sort((a, b) => a.price - b.price),
          ]);
        }
        case productsSortKeys.priceHighToLow: {
          return productGroupsArr.map(([id, items]) => [
            id,
            items.sort((a, b) => b.price - a.price),
          ]);
        }
        default: {
          return productGroupsArr;
        }
      }
    },
    [mixMatchSort]
  );

  const itemsToShow = useMemo(() => {
    if (mixMatchType === 'curated') {
      return mixMatchSubtype === 'all'
        ? sortItems(
            productGroups.map(([id, items]) => [
              id,
              items.map((product) => ({
                ...product,
                price: product.price / 100,
              })),
            ])
          )
        : sortItems(
            productGroups.map(([id, items]) => [
              id,
              items.slice(0, 24).map((product) => ({
                ...product,
                price: product.price / 100,
              })),
            ])
          );
    }
    return mixMatchSubtype === 'all'
      ? sortItems(memoizedSimilar)
      : sortItems(
          memoizedSimilar.map(([id, items]) => [id, items.slice(0, 24)])
        );
  }, [
    mixMatchType,
    mixMatchSubtype,
    sortItems,
    memoizedSimilar,
    productGroups,
  ]);

  const getClassForItem = () => {
    // eslint-disable-next-line no-nested-ternary
    return itemsToShow.length === 2 || itemsToShow.length === 4
      ? 'calc(35% - 16px)'
      : 'calc(30% - 16px)';
  };

  return (
    <div
      className={clsx('row mb-3', !reduceItems && classes.itemsWrapper)}
      style={{
        maxWidth: reduceItems ? 550 : 'initial',
        margin: reduceItems ? '0 auto' : 'initial',
      }}
    >
      {itemsToShow.map(([itemClassId, groupProducts], index) => (
        <div
          className={clsx('mb-2', isDetailsPage ? 'mr-2' : 'col-5')}
          key={itemClassId}
          style={{
            flexBasis: getClassForItem(),
            width: itemsToShow.length === 4 ? '35%' : '25%',
          }}
        >
          <MixAndMatchItem
            index={index}
            title={findObjectById(itemClassId, itemClasses)?.singular}
            products={groupProducts}
            onChangeProduct={handleChangeProduct}
            onRateProduct={onRateProduct}
            onSlideEndProduct={getMoreSimilarProducts}
          />
        </div>
      ))}
      <div className="col-12 d-flex justify-content-center pt-3">
        <Button
          variant="outlined"
          style={{ width: 'auto' }}
          onClick={handleSaveMixAndMatch}
        >
          <SpriteIcon name="dashboard" size="sm" className="mr-1" />
          Add to My Saved Look Boards
        </Button>
      </div>
    </div>
  );
};

MixAndMatchView.propTypes = {
  products: PropTypes.shape({}).isRequired,
  onSave: PropTypes.func.isRequired,
  onRateProduct: PropTypes.func,
  isDetailsPage: PropTypes.bool,
};

MixAndMatchView.defaultProps = {
  onRateProduct: undefined,
  isDetailsPage: false,
};

export default MixAndMatchView;
