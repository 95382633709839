import { filterTabKeys } from 'components/modals/FiltersModal/constants';

export const stepsConfig = {
  [filterTabKeys.vibes]: {
    id: 0,
    value: filterTabKeys.vibes,
    title: 'Vibes',
    nextStep: filterTabKeys.styles,
    prevStep: null,
    prompt: 'Please, select at least one vibe',
  },
  [filterTabKeys.styles]: {
    id: 1,
    value: filterTabKeys.styles,
    title: 'Styles',
    nextStep: filterTabKeys.subStyles,
    prevStep: filterTabKeys.vibes,
    prompt: 'Please, select at least one style',
  },
  [filterTabKeys.subStyles]: {
    id: 2,
    value: filterTabKeys.subStyles,
    title: 'Sub Styles',
    nextStep: filterTabKeys.roomTypes,
    prevStep: filterTabKeys.styles,
    prompt: 'Please, select at least one room image',
  },
  [filterTabKeys.roomTypes]: {
    id: 3,
    value: filterTabKeys.roomTypes,
    title: 'Room Types',
    nextStep: filterTabKeys.colors,
    prevStep: filterTabKeys.subStyles,
  },
  [filterTabKeys.colors]: {
    id: 4,
    value: filterTabKeys.colors,
    title: 'Colors',
    nextStep: null,
    prevStep: filterTabKeys.roomTypes,
  },
};

export const promptConfig = {};
