import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';
import { routesByName } from 'constants/routes';
import classes from './RestrictAddImageModal.module.scss';
import { setDashboardActiveTab } from '../../dashboard/store/actions';
import { myProductsTabKey } from '../../dashboard/constants';

const RestrictAddImageModal = ({ open, onClose, onCloseAddImageModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleOpenPage = () => {
    onClose();
    onCloseAddImageModal();
    dispatch(setDashboardActiveTab(myProductsTabKey));
    history.push(routesByName.dashboard.index);
  };

  return (
    <BasicModal open={open} onClose={onClose} maxWidth="sm">
      <div className={classes.modalContent}>
        <h3 className={classes.titleModal}>Make space</h3>
        <p className={classes.learnMore}>
          Please delete products to make space for additional ones.
        </p>
        <Button inline size="sm" onClick={handleOpenPage}>
          Go to Dashboard
        </Button>
      </div>
    </BasicModal>
  );
};

RestrictAddImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCloseAddImageModal: PropTypes.func.isRequired,
};
export default RestrictAddImageModal;
