import { routesByName } from 'constants/routes';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import currentUserService from 'modules/currentUser/currentUserService';
import nomineesService from 'modules/dashboard/nominees/nomineesService';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';

const useFollowings = ({ followUserId, followUserName }) => {
  const [followed, setFollowed] = useState(false);

  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [
    authenticated,
    hasVerifiedEmail,
  ] = useSelector(({ auth: { user } }) => [
    Boolean(user),
    Boolean(user?.hasVerifiedEmail),
  ]);

  useEffect(() => {
    try {
      (async () => {
        if (authenticated && hasVerifiedEmail && followUserId) {
          const isFollowed = await currentUserService.checkFollowing(
            followUserId
          );
          setFollowed(isFollowed);
        }
      })();
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [followUserId, authenticated, hasVerifiedEmail]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const handleFollowChange = useCallback(
    async (confirm) => {
      if (!authenticated) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      if (!hasVerifiedEmail) {
        dispatch(toggleVerificationModalAction(true));
        return;
      }

      if (followed) {
        if (confirm === true) {
          try {
            await nomineesService.unFollowUser(followUserId);
            setFollowed(false);
            setConfirmModalOpen(false);
          } catch (e) {
            errorToastr('Error', e.generalError);
          }
        } else if (confirmModalOpen) {
          setConfirmModalOpen(false);
        } else {
          setConfirmModalOpen(true);
        }
      } else {
        try {
          await nomineesService.followUser(followUserId);
          setFollowed(true);
          successToastr('Success', `You are now following ${followUserName}`);
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      }
    },
    [
      followed,
      followUserId,
      setFollowed,
      authenticated,
      pathname,
      history,
      followUserName,
      hasVerifiedEmail,
      dispatch,
      confirmModalOpen,
    ]
  );

  return [followed, handleFollowChange, confirmModalOpen];
};

export default useFollowings;
