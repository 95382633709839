import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import buildResourceUrl from 'utils/buildResourceUrl';
import errorToastr from 'libs/toastr/errorToastr';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import {
  setClaimImageIdAction,
  toggleClaimImageModalAction,
} from 'modules/report/store/actions';
import { updateIIStatusLikeAction } from 'modules/inspirationImage/store/actions';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import MoreOptionsPopover from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import { selectImageAction as selectCTLImageAction } from 'modules/curateTheLook/store/actions';
import { selectImageAction as selectRTLImageAction } from 'modules/requestTheLook/store/actions';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import moreOptionsClasses from 'modules/curateTheLook/createLookBoard/components/MoreOptionsPopover/MoreOptionsPopover.module.scss';
import clsx from 'clsx';
import classes from './MobileImageItem.module.scss';

const MobileImageItem = ({
  id,
  url,
  userId,
  hash,
  isLiked,
  shareUrl,
  requestId,
  onOpenPreview,
  onSelectImage,
  handleBlur,
  lookBoardsExist,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const authenticated = Boolean(useSelector((state) => state.auth.user));
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );

  const { pathname } = useLocation();
  const history = useHistory();
  const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, userId, hash),
    [inspirationImageUrl, userId, hash]
  );

  const handleOpenPreview = useCallback(() => {
    onOpenPreview(imgUrl);
  }, [onOpenPreview, imgUrl]);

  const handleToggleLike = useCallback(async () => {
    if (!authenticated) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    try {
      await inspirationImageService.toggleLike(id, Number(!isLiked));
      dispatch(updateIIStatusLikeAction(id, Number(!isLiked)));
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [history, pathname, id, isLiked, dispatch, authenticated]);

  const handleMoreOptionsPopupOpen = useCallback(({ currentTarget }) => {
    setMoreOptionsAnchorEl(currentTarget);
    setIsOptionOpen(true);
    setScrollPosition(window.pageYOffset);
  }, []);

  // set default scroll position after closing "MoreOptionPopup"
  const handleMoreOptionsPopupClose = useCallback(() => {
    setMoreOptionsAnchorEl(null);
    setIsOptionOpen(false);
    handleBlur();
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
  }, [handleBlur, scrollPosition]);

  const handleSelectImage = useCallback(() => {
    onSelectImage(id, requestId);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [onSelectImage, id, requestId]);

  const handleOnClaimImage = useCallback(() => {
    dispatch(toggleClaimImageModalAction(true));
    dispatch(setClaimImageIdAction(id));
    handleMoreOptionsPopupClose();
  }, [id, dispatch, handleMoreOptionsPopupClose]);

  const handleMoveToRTL = useCallback(() => {
    dispatch(selectRTLImageAction(id));
    history.push(routesByName.requestTheLook.index);
  }, [dispatch, id, history]);

  const handleMoveToCTL = useCallback(() => {
    if (pathname === routesByName.getTheLook.index) {
      dispatch(selectCTLImageAction(id));
      history.push(`${routesByName.curateTheLook.canvas}/?tips=active`);
    } else {
      dispatch(selectCTLImageAction(id));
      history.push(routesByName.curateTheLook.index);
    }
  }, [dispatch, id, history, pathname]);

  const handleMoveToGTL = useCallback(() => {
    history.push(routesByName.getTheLook.details(id));
  }, [history, id]);

  const handleOpenSharePopover = useCallback(({ currentTarget }) => {
    setShareAnchorEl(currentTarget);
  }, []);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  return (
    <div className={classes.root}>
      <div onClick={handleOpenPreview} className="position-relative">
        <img alt="" draggable="false" src={imgUrl} className={classes.image} />
        {!lookBoardsExist && (
          <div className={classes.imgMask}>
            {isLoading
              ? 'Loading...'
              : 'There are no Look Boards created for this image yet'}
          </div>
        )}
      </div>
      {isLoading || lookBoardsExist ? (
        <div className="p-2">
          <Button
            onClick={handleSelectImage}
            className={clsx('mb-2', classes.button)}
            size="sm"
          >
            Go to Look Boards
          </Button>
          <div className="w-100 d-flex align-items-center">
            <div className="flex-fill text-ellipsis">
              {url && <ImageLink url={url} />}
            </div>
            <IconButton
              variant="inverted-grey"
              size="sm"
              className="mr-1"
              onClick={handleToggleLike}
            >
              <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
            </IconButton>
            <IconButton
              variant="inverted-grey"
              size="sm"
              className="mr-1"
              onClick={handleOpenSharePopover}
            >
              <SpriteIcon name="share" size="sm" />
            </IconButton>
            {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
            <IconButton
              variant="inverted-grey"
              size="sm"
              onClick={handleMoreOptionsPopupOpen}
              onMouseEnter={handleMoreOptionsPopupOpen}
            >
              <SpriteIcon name="more-vertical" size="sm" />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className="px-2 pb-2">
          <Button
            onClick={handleMoveToCTL}
            className={clsx('mb-2', classes.button)}
            size="sm"
            variant="outlined"
          >
            Create Look Board
          </Button>

          <Button
            onClick={handleMoveToRTL}
            className={classes.button}
            size="sm"
            variant="outlined"
          >
            Request the Look
          </Button>
        </div>
      )}
      <MoreOptionsPopover
        open={isOptionOpen}
        onClose={handleMoreOptionsPopupClose}
        anchorEl={moreOptionsAnchorEl}
        scrollPosition={scrollPosition}
      >
        <Button
          className={`${moreOptionsClasses.btn} mr-1`}
          size="custom"
          color="default"
          style={{ minWidth: 110 }}
          onClick={handleMoveToGTL}
        >
          <SpriteIcon name="living-room" size="sm" className="mr-1" />
          <span>Get the Look</span>
        </Button>
        <Button
          onClick={handleOnClaimImage}
          className={moreOptionsClasses.btn}
          size="custom"
          color="default"
          style={{ minWidth: 90 }}
        >
          <span>Claim Image</span>
        </Button>
      </MoreOptionsPopover>
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        shareUrl={shareUrl}
        directImgUrl={imgUrl}
      />
    </div>
  );
};

MobileImageItem.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string,
  userId: PropTypes.number.isRequired,
  requestId: PropTypes.number,
  hash: PropTypes.string.isRequired,
  isLiked: PropTypes.number.isRequired,
  shareUrl: PropTypes.string.isRequired,
  lookBoardsExist: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  handleBlur: PropTypes.func,
};

MobileImageItem.defaultProps = {
  url: null,
  requestId: null,
  handleBlur: () => {},
};

export default MobileImageItem;
