import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import clsx from 'clsx';
import SliderControls from 'components/MixMatchThumbnail/SliderControls';
import ProductItemDetails from '../product/ProductItemDetails/ProductItemDetails';
import classes from './LookBoardDetails.module.scss';
import SpriteIcon from '../../components/ui/SpriteIcon';

const MobileLookBoard = ({
  items,
  handleClickProduct,
  handleToggleLikeProduct,
}) => {
  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleDotClick = useCallback(({ currentTarget }) => {
    const currentSlideIndex = currentTarget.getAttribute('data-index');
    sliderRef.current.slickGoTo(currentSlideIndex);
  }, []);

  return (
    <div className="position-relative">
      <Slider
        ref={sliderRef}
        arrows={false}
        afterChange={handleSlideChange}
        adaptiveHeight
        dots
        customPaging={(i) => <SpriteIcon name="dot" size="xs" key={i} />}
      >
        {items.map((product) => (
          <div className={clsx(classes.productMobileItem)} key={product.id}>
            <ProductItemDetails
              product={product}
              onToggleLike={handleToggleLikeProduct}
              onClick={handleClickProduct}
            />
          </div>
        ))}
      </Slider>
      <SliderControls
        items={items}
        activeSlideIndex={activeSlideIndex}
        onDotClick={handleDotClick}
        onSlidePrev={handleSlidePrev}
        onSlideNext={handleSlideNext}
        hideArrows
      />
    </div>
  );
};

MobileLookBoard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleClickProduct: PropTypes.func.isRequired,
  handleToggleLikeProduct: PropTypes.func.isRequired,
};
export default MobileLookBoard;
