import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';
import classes from './TipsModal.module.scss';

const TipsModal = ({ open, onClose }) => {
  return (
    <BasicModal open={open} onClose={onClose} maxWidth="lg" scroll="body">
      <div className={classes.wrapper}>
        <h2 className={`${classes.title} mb-4`}>CURATE THE LOOK TIPS</h2>
        <h6 className={`${classes.description} mb-4`}>
          To increase the chance of getting your Look Board approved for the
          “Get the Look” section, follow these tips.
        </h6>
        <ul className={`${classes.tipsContent} d-flex flex-column mb-4`}>
          <li className="mb-2">Pin NEW items from the Web</li>
          <li className="mb-2">Only Pin items on a White Background</li>
          <li className="mb-2">Select items similar to those in the image</li>
          <li>Select items from a variety of online stores</li>
        </ul>
        <Button onClick={onClose}>Got it!</Button>
      </div>
    </BasicModal>
  );
};

TipsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TipsModal;
