import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInspirationImgUrl,
  getRoomTypeName,
  getStyleNames,
} from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import { routesByName } from 'constants/routes';
import { Link, useHistory, Redirect, useLocation } from 'react-router-dom';
import requestService from 'modules/request/requestService';
import Button from 'components/ui/Button/Button';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import DetailsPreview from 'modules/requestTheLook/components/DetailsPreview/DetailsPreview';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import classes from './SubmitRequest.module.scss';

const SubmitRequestContainer = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.user);
  const enums = useSelector((state) => state.app.enums);
  const library = useSelector((state) => state.inspirationImage.library);
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const {
    inspirationImageId,
    title,
    requestType,
    items: priorityItems,
    message,
    requestColor,
    requestStyle,
    roomType: requestRoomType,
  } = useSelector((state) => state.requestTheLook.requestData);

  const selectedImage = useMemo(() => library[inspirationImageId], [
    library,
    inspirationImageId,
  ]);
  const styles = useMemo(
    () => getStyleNames(selectedImage?.styles ?? [], enums),
    [enums, selectedImage]
  );
  const roomType = useMemo(() => getRoomTypeName(requestRoomType, enums), [
    enums,
    requestRoomType,
  ]);
  const { colors, colorGroups, styles: requestStyles } = enums;

  const history = useHistory();
  const { pathname } = useLocation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [submitLoading, setSubmitLoading] = useState(false);

  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  const itemType = useMemo(
    () =>
      requestType === imageTypeKeys.room ||
      requestType === imageTypeKeys.makeOver
        ? 'Room'
        : 'Item',
    [requestType]
  );

  const handleSubmit = useCallback(async () => {
    if (!currentUser) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!currentUser?.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    setSubmitLoading(true);
    try {
      let reCaptchaToken;
      if (!executeRecaptcha) {
        reCaptchaToken = null;
      }
      reCaptchaToken = await executeRecaptcha(reCaptchaActions.createRequest);
      const colorsFromGroups = Object.values(colors)
        .filter((color) => requestColor.includes(color.color_group_id))
        .map((color) => color?.id);
      const data = {
        title,
        imageId: selectedImage?.id,
        items: priorityItems.map(({ value }) => value),
        message,
        reCaptchaToken,
        colors: colorsFromGroups,
        styles: requestStyle,
        roomType,
      };
      await requestService.submitRequest(data);
      history.push(routesByName.requestTheLook.success);
      successToastr('Success', 'Request Successfully submitted');
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
    setSubmitLoading(false);
  }, [
    currentUser,
    history,
    pathname,
    dispatch,
    executeRecaptcha,
    colors,
    title,
    selectedImage,
    priorityItems,
    message,
    requestStyle,
    roomType,
    requestColor,
  ]);

  return selectedImage ? (
    <section className={classes.root}>
      <div className="mb-5">
        <Link to={routesByName.requestTheLook.details}>
          <div className="d-inline-flex align-items-center mb-3">
            <IconButton variant="inverted-white" size="sm">
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="primary-color text-sm ml-1">Back</span>
          </div>
        </Link>
      </div>
      <h1 className={`${classes.title}  mb-4 text-uppercase`}>
        Review & Submit
      </h1>
      <div className="d-flex justify-content-center">
        <ImageDetails
          imgUrl={imgUrl}
          url={selectedImage?.url}
          title={selectedImage?.title}
          showInfo
          editRoute={routesByName.requestTheLook.details}
        />
        <DetailsPreview
          title={title}
          requestType={requestType}
          requestColor={requestColor}
          requestStyle={requestStyle}
          styles={styles}
          priorityItems={priorityItems}
          itemType={itemType}
          roomType={roomType}
          prevStepRoute={routesByName.requestTheLook.details}
          message={message}
          wider
          colors={colors}
          requestStyles={requestStyles}
          colorGroups={colorGroups}
        >
          <Button
            onClick={handleSubmit}
            isLoading={submitLoading}
            className={classes.submitBtn}
            variant="outlined"
            size="lg"
            inline
          >
            Submit
          </Button>
        </DetailsPreview>
      </div>
    </section>
  ) : (
    <Redirect to={routesByName.requestTheLook.index} />
  );
};

export default SubmitRequestContainer;
