import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import requestService from 'modules/request/requestService';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import errorToastr from 'libs/toastr/errorToastr';
import { routesByName } from 'constants/routes';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import productService from 'modules/product/productService';
import {
  contentTitlesConfig,
  recommendationsTabKeys,
  recommendationsTabsConfig,
} from 'modules/requestTheLook/recommendations/constants';
import transformArrayToMap from 'utils/transformArrayToMap';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import RecommendationsComponent from 'modules/requestTheLook/recommendations/RecommendationsComponent';
import RequestDetailsModal from 'modules/requestTheLook/recommendations/components/RequestDetailsModal/RequestDetailsModal';
import TypesInfoModal from 'components/modals/TypesInfoModal/TypesInfoModal';
import mixAndMatchService from 'modules/mixAndMatch/mixAndMatchService';
import successToastr from 'libs/toastr/successToastr';
import HowToViewModal from 'modules/requestTheLook/recommendations/components/HowToViewModal/HowToViewModal';
import womanImg from 'assets/img/ambassador-page/woman.png';
import Button from 'components/ui/Button/Button';
import RequestModal from 'modules/ambassadors/components/RequestModal/RequestModal';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import ambassadorService from 'modules/ambassadors/ambassadorService';
import RequestSentModal from 'modules/ambassadors/components/RequestSentModal/RequestSentModal';
import { localStorageKeys } from 'modules/app/constants';
import classes from './Recommendations.module.scss';
import SendMessageModal from '../../../components/modals/SendMessageModal/SendMessageModal';
import { messageFieldKeys } from '../../../components/modals/SendMessageModal/constants';

const RecommendationsContainer = () => {
  const dispatch = useDispatch();

  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const user = useSelector((state) => state.auth.user);

  const history = useHistory();
  const { pathname } = useLocation();
  const { requestId } = useParams();
  const [activeTab, setActiveTab] = useState(
    recommendationsTabKeys.lookBoardView
  );
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserHaveAccess, setIsUserHaveAccess] = useState(true);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [howToViewModalOpen, setHowToViewModalOpen] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestSentModalOpen, setRequestSentModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageUserData, setMessageUserData] = useState(null);

  const imgUrl = useMemo(
    () =>
      buildResourceUrl(
        inspirationImageUrl.medium,
        state?.inspirationImage?.media.userId,
        state?.inspirationImage?.media.hash
      ),
    [inspirationImageUrl, state]
  );

  const handleChangeActiveTab = useCallback(({ currentTarget }) => {
    const { tab } = currentTarget.dataset;
    setActiveTab(tab);
  }, []);

  const handleToggleLikeLookBoard = useCallback(
    async (lookBoardId, likeStatus) => {
      try {
        await lookBoardService.toggleLike(lookBoardId, likeStatus);
        setState((prevState) => ({
          ...prevState,
          lookBoards: prevState.lookBoards.map((lookBoard) =>
            lookBoard.id === lookBoardId
              ? {
                  ...lookBoard,
                  isLiked: likeStatus,
                }
              : lookBoard
          ),
        }));
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    []
  );

  const handleToggleLikeProduct = useCallback(async (productId, isLiked) => {
    try {
      await productService.toggleLike(productId, isLiked);
      setState((prevState) => ({
        ...prevState,
        products: {
          ...prevState.products,
          [productId]: {
            ...prevState.products[productId],
            isLiked,
          },
        },
      }));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  const handleRateLookBoard = useCallback(async (lookBoardId, rate) => {
    try {
      await lookBoardService.rate(lookBoardId, rate);
      setState((prevState) => ({
        ...prevState,
        lookBoards: prevState.lookBoards.map((lookBoard) =>
          lookBoard.id === lookBoardId
            ? {
                ...lookBoard,
                averageRating: String(rate),
              }
            : lookBoard
        ),
      }));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  const handleRateProduct = useCallback(async (productId, rate) => {
    try {
      await productService.rate(productId, rate);
      setState((prevState) => ({
        ...prevState,
        products: {
          ...prevState.products,
          [productId]: {
            ...prevState.products[productId],
            averageRating: String(rate),
          },
        },
      }));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  const handleOpenDetail = useCallback(() => {
    setDetailModalOpen(true);
  }, []);

  const handleCloseDetail = useCallback(() => {
    setDetailModalOpen(false);
  }, []);

  const handleOpenInfoModal = useCallback(() => {
    setInfoModalOpen(true);
  }, []);

  const handleCloseInfoModal = useCallback(() => {
    setInfoModalOpen(false);
  }, []);

  const handleOpenHowToViewModal = useCallback(() => {
    setHowToViewModalOpen(true);
  }, []);

  const handleCloseHowToViewModal = useCallback(() => {
    setHowToViewModalOpen(false);
  }, []);

  const handleOpenRequestModal = useCallback(() => {
    if (!user) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
      );
      return;
    }
    if (!user?.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    setRequestModalOpen(true);
  }, [user, pathname, history, dispatch]);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleCloseRequestSentModal = useCallback(() => {
    setRequestSentModalOpen(false);
  }, []);

  const handleSendRequest = useCallback(async (values) => {
    try {
      await ambassadorService.requestAccess(values);
      setRequestModalOpen(false);
      setRequestSentModalOpen(true);
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      const {
        request,
        lookBoards,
        products,
        users,
      } = await requestService.getRecommendations(requestId);
      const { result: image } = await inspirationImageService.getImageById(
        request.imageId
      );
      setState({
        request,
        lookBoards,
        products: transformArrayToMap(products),
        users: transformArrayToMap(users),
        inspirationImage: image,
      });
    }

    if (user) {
      fetchData()
        .then(() => {
          setIsUserHaveAccess(true);
        })
        .catch((reason) => {
          if (reason.response.status === 403) {
            setState([]);
            setIsUserHaveAccess(false);
            return;
          }
          errorToastr('Error', reason.generalError);
          history.push(routesByName.home);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      history.push(
        `${routesByName.home}?recommendation=true&requestId=${requestId}`
      );
    }
    // eslint-disable-next-line
  }, [requestId, user]);

  const handleSaveMixAndMatch = useCallback(
    async (productIds) => {
      try {
        const {
          inspirationImage: { id: imageId },
        } = state;
        await mixAndMatchService.saveMixAndMatch(imageId, productIds);
        successToastr('Success', 'Your Mix & Match successfully saved');
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [state]
  );

  useEffect(() => {
    let isViewed = false;

    const watchedRecommendations = localStorage.getItem(
      localStorageKeys.watchedRecommendations
    );

    if (watchedRecommendations) {
      const parsedList = JSON.parse(watchedRecommendations);
      isViewed = parsedList.some((id) => id === requestId);

      if (!isViewed) {
        localStorage.setItem(
          localStorageKeys.watchedRecommendations,
          JSON.stringify([...parsedList, requestId])
        );
      }
    } else {
      localStorage.setItem(
        localStorageKeys.watchedRecommendations,
        JSON.stringify([requestId])
      );
    }

    if (!isViewed) {
      setHowToViewModalOpen(true);
    }
  }, [requestId]);

  const handleOpenMessageModal = useCallback(
    (messageUser) => {
      if (!user) {
        history.push(
          `${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`
        );
        return;
      }
      if (!user?.hasVerifiedEmail) {
        dispatch(toggleVerificationModalAction(true));
        return;
      }
      setMessageUserData(messageUser);
      setMessageModalOpen(true);
    },
    [history, user, pathname, dispatch]
  );

  const handleCloseMessageModal = useCallback(() => {
    setMessageModalOpen(false);
  }, []);

  const initialFormValues = useMemo(
    () => ({
      [messageFieldKeys.name]: messageUserData
        ? messageUserData.displayName ??
          `${messageUserData.firstName} ${messageUserData.lastName}`
        : '',
      [messageFieldKeys.userId]: messageUserData?.id ?? null,
    }),
    [messageUserData]
  );

  return !isLoading ? (
    <>
      {isUserHaveAccess ? (
        <section className={classes.root}>
          <div className="mb-3">
            <Link to={routesByName.dashboard.requests}>
              <div className="d-inline-flex align-items-center">
                <IconButton variant="inverted-white" size="sm">
                  <SpriteIcon name="left-arrow" size="sm" />
                </IconButton>
                <span className="primary-color text-sm ml-1">Back</span>
              </div>
            </Link>
          </div>
          <div className="d-flex">
            <div className={classes.leftPanel}>
              {state.inspirationImage && (
                <>
                  {!user?.ambassador && (
                    <div className={classes.becomeAmbassadorBanner}>
                      <div className={classes.leftSide}>
                        <p
                          className="text-uppercase font-title text-center pl-3 mb-5 mt-4"
                          style={{ lineHeight: 1.4 }}
                        >
                          Become a Pin the <br /> Look Ambassador
                        </p>
                        <Button
                          inline
                          size="xs"
                          onClick={handleOpenRequestModal}
                        >
                          Request an Invitation
                        </Button>
                      </div>
                      <div
                        className="d-flex position-relative"
                        style={{ zIndex: 2 }}
                      >
                        <div
                          className=" position-relative"
                          style={{ zIndex: 2 }}
                        >
                          <img src={womanImg} alt="" />
                        </div>
                        <div className="ml-2">
                          <p className={classes.quote}>
                            ‘’Pin the Look makes it fun & easy to help others
                            get the look they love’’
                          </p>
                          <p className={classes.quoteAuthor}>
                            Janice l. Ambassador
                          </p>
                        </div>
                      </div>
                      <div className={classes.decor} />
                    </div>
                  )}
                  <ImageDetails
                    imgUrl={imgUrl}
                    url={state.inspirationImage.url}
                    title={state.inspirationImage.title}
                    showInfo
                  />
                  <button
                    className={`${classes.detailBtn} mt-3 primary-color text-lg link-underline cursor-pointer`}
                    onClick={handleOpenDetail}
                    type="button"
                  >
                    View Request Details
                  </button>
                </>
              )}
            </div>
            <div className={classes.mainArea}>
              <div className="d-flex align-items-start align-items-xl-center flex-column mb-5">
                <h1 className={`${classes.title} mb-2`}>
                  View Your Recommendations
                </h1>
                <div className="flex-vertical-center mb-2">
                  <InfoIconPopover fullWidth>
                    <div className="text-center">
                      <p className="font-semi-bold mb-1 text-uppercase font-title font-normal">
                        Rate The Match
                      </p>
                      <p className="text-sm">
                        Please rate how well this item matches the similar item
                        in your inspiration image.
                      </p>
                    </div>
                  </InfoIconPopover>
                  <h2 className="ml-1 text-lg font-title primary-color">
                    Rate the Match
                  </h2>
                </div>
                <p className={`${classes.description} font-italic`}>
                  Please Rate How Closely the Product <br />
                  Recommendations Match your Inspiration Image
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-wrap flex-xl-nowrap">
                <h2 className={classes.pageTitle}>
                  {contentTitlesConfig[activeTab]}
                </h2>
                <div className="d-flex mb-2 mb-xl-0">
                  <IconButton
                    onClick={handleOpenInfoModal}
                    variant="inverted-white"
                    className="mr-1"
                  >
                    <SpriteIcon name="info" />
                  </IconButton>
                  {recommendationsTabsConfig.map(({ value, title, icon }) => (
                    <button
                      key={value}
                      type="button"
                      className={clsx(classes.tabBtn, {
                        [classes.active]: activeTab === value,
                      })}
                      data-tab={value}
                      onClick={handleChangeActiveTab}
                    >
                      <SpriteIcon name={icon} size="sm" className="mr-1" />
                      {title}
                    </button>
                  ))}
                </div>
              </div>
              <div className="flex-fill d-flex flex-column">
                <RecommendationsComponent
                  lookBoards={state.lookBoards}
                  products={state.products}
                  users={state.users}
                  handleToggleLikeLookBoard={handleToggleLikeLookBoard}
                  handleToggleLikeProduct={handleToggleLikeProduct}
                  onRateLookBoard={handleRateLookBoard}
                  onRateProduct={handleRateProduct}
                  activeTab={activeTab}
                  onSaveMixMatch={handleSaveMixAndMatch}
                  handleOpenHowToViewModal={handleOpenHowToViewModal}
                  handleOpenMessageModal={handleOpenMessageModal}
                />
              </div>
            </div>
          </div>
          {state.inspirationImage && (
            <RequestDetailsModal
              open={detailModalOpen}
              onClose={handleCloseDetail}
              request={state.request}
              imgUrl={imgUrl}
              requestImage={state.inspirationImage}
            />
          )}
          <TypesInfoModal open={infoModalOpen} onClose={handleCloseInfoModal} />
          <HowToViewModal
            onClose={handleCloseHowToViewModal}
            open={howToViewModalOpen}
          />
          <RequestModal
            onClose={handleCloseRequestModal}
            open={requestModalOpen}
            onSubmit={handleSendRequest}
          />
          <RequestSentModal
            open={requestSentModalOpen}
            onClose={handleCloseRequestSentModal}
          />
          <SendMessageModal
            open={messageModalOpen}
            onClose={handleCloseMessageModal}
            initialValues={initialFormValues}
          />
        </section>
      ) : (
        <div className="text-center p-5">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You don't have enough permission to view this page
        </div>
      )}
    </>
  ) : (
    <div className="text-center p-5">Loading...</div>
  );
};

export default RecommendationsContainer;
