import React from 'react';
import clsx from 'clsx';
import getSectionImg from 'assets/img/home-page/how-it-works/get.png';
import requestSectionImg from 'assets/img/home-page/how-it-works/request.png';
import curateSectionImg from 'assets/img/home-page/how-it-works/curate.png';
import compareSectionImg from 'assets/img/home-page/how-it-works/compare.png';
import shopSectionImg from 'assets/img/home-page/how-it-works/shop.png';
import CompareSectionSliders from 'modules/home/components/HowItWorks/CompareSectionSliders/CompareSectionSliders';
import classes from './HowItWorks.module.scss';
import { routesByName } from '../../../../constants/routes';
import { previewsConfig } from '../../constants';
import Previews from '../GetTheLookYouLove/Previews';

const HowItWorks = () => (
  <section className={classes.root}>
    <div className="d-flex align-items-center text-center flex-column">
      <h2 className={classes.title}>How it Works</h2>
      <p className={classes.description}>
        Pin the Look makes it easy to Get the Look you Love. <br />
        You can browse
        <a className="primary-color" href={routesByName.getTheLook.index}>
          {' '}
          Curated Looks{' '}
        </a>
        in popular styles,
        <a className="primary-color" href={routesByName.requestTheLook.index}>
          {' '}
          Request the Look{' '}
        </a>
        of any image, <br /> or
        <a className="primary-color" href={routesByName.curateTheLook.canvas}>
          {' '}
          Curate the Look{' '}
        </a>
        yourself. It’s fast, fun, and free!
      </p>
    </div>
    <div className="mb-5">
      <Previews previews={previewsConfig} />
    </div>
    <div className="d-flex align-items-center flex-column">
      <div
        id="get-the-look"
        className={clsx([classes.section, classes.sectionGet, 'px-5'])}
      >
        <img src={getSectionImg} alt="" className={classes.image} />
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Get <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Browse Curated Looks and Ambassador Showcase Pages
          </div>
        </div>
      </div>
      <div
        id="request-the-look"
        className={clsx([classes.section, classes.sectionRequest, 'px-5'])}
      >
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Request <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Request the Look of your favorite inspiration images to get Look
            Board recommendations from our Ambassadors
          </div>
        </div>
        <img src={requestSectionImg} className={classes.imageReq} alt="" />
      </div>
      <div
        id="curate-the-look"
        className={clsx([classes.section, classes.sectionCurate, 'px-5'])}
      >
        <img src={curateSectionImg} alt="" />
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Curate <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Use our easy drag & drop design tools <br />
            to curate the look of your favorite <br />
            room images yourself!
          </div>
        </div>
      </div>
      <div
        id="compare-the-look"
        className={clsx([classes.section, classes.sectionCompare, 'px-5'])}
      >
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Compare <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Use our Mix & Match tool to easily <br />
            visualize your favorite items <br />
            together
          </div>
        </div>
        <div className={classes.compareImg}>
          <img src={compareSectionImg} alt="" />
          <div className={classes.compareDecor} />
        </div>
        <CompareSectionSliders />
      </div>
      <div
        id="shop-the-look"
        className={clsx([classes.section, classes.sectionShop, 'px-5'])}
      >
        <img src={shopSectionImg} className={classes.imageShop} alt="" />
        <div className={classes.sectionAbout}>
          <h3 className={classes.sectionTitle}>
            Shop <span>the Look</span>
          </h3>
          <div className={classes.sectionDescription}>
            Compare prices and <br />
            click on the <br /> items you love <br />
            to make purchases online.
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HowItWorks;
