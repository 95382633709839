import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import CreateImageForm from 'modules/pinMedia/CreateImageForm/CreateImageForm';
import createImageValidator from 'modules/pinMedia/CreateImageForm/createImageValidator';
import { Field, Form } from 'react-final-form';
import Button from 'components/ui/Button/Button';
import pinMediaValidator from 'modules/pinMedia/pinMediaValidator';
import CustomTextInputWrapper from 'components/finalFormWrappers/CustomTextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import CreateProductForm from 'modules/pinMedia/CreateProductForm/CreateProductForm';
import mediaTypes from 'constants/mediaTypes';
import createProductValidator from 'modules/pinMedia/CreateProductForm/createProductValidator';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reCaptchaActions } from 'constants/V3CaptchaActions';
import classes from './SaveImageModal.module.scss';
import { routesByName } from '../../../constants/routes';
import { imageTypeValuesMap, ownershipValuesMap } from '../constants';

const SaveImageModal = ({
  open,
  onClose,
  imgUrl,
  imgSourceUrl,
  onCreateMedia,
  onSaveImg,
  setNewImage,
  mediaType,
  withDrop,
  changeIsOwnImage,
}) => {
  const [cancelled, setCancelled] = useState(false);
  const [newImgUrl, setNewImgUrl] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const history = useHistory();

  useEffect(() => {
    if (!withDrop) {
      setNewImgUrl(imgUrl);
    }
  }, [imgUrl, setNewImgUrl, withDrop]);

  const handleSaveOnDropMedia = async (formValues) => {
    const { ownership } = formValues;
    let reCaptchaToken;
    if (!executeRecaptcha) {
      reCaptchaToken = null;
    }
    reCaptchaToken = await executeRecaptcha(
      mediaType === mediaTypes.product
        ? reCaptchaActions.createProduct
        : reCaptchaActions.createImage
    );
    const type =
      mediaType === mediaTypes.inspirationImage
        ? imageTypeValuesMap.room
        : imageTypeValuesMap.single;

    await onSaveImg({
      ...formValues,
      reCaptchaToken,
      type,
    });

    changeIsOwnImage(ownership === ownershipValuesMap.selfOwned);
    const path = history.location.pathname;
    history.push(routesByName.getTheLook.index);
    history.push(path);
  };

  const handleSaveMedia = useCallback(
    async (formValues) => {
      let reCaptchaToken;
      const priceInCents = formValues.price * 100;
      if (!executeRecaptcha) {
        reCaptchaToken = null;
      }
      reCaptchaToken = await executeRecaptcha(
        mediaType === mediaTypes.product
          ? reCaptchaActions.createProduct
          : reCaptchaActions.createImage
      );
      const type =
        mediaType === mediaTypes.inspirationImage
          ? imageTypeValuesMap.room
          : imageTypeValuesMap.single;
      await onCreateMedia({
        mediaUrl: newImgUrl,
        formValues: { ...formValues, price: priceInCents || null, type },
        reCaptchaToken,
      });
      const path = history.location.pathname;
      history.push(routesByName.getTheLook.index);
      history.push(path);
    },
    // eslint-disable-next-line
    [executeRecaptcha, mediaType, onCreateMedia, newImgUrl]
  );

  const handleCancel = useCallback(() => {
    setNewImgUrl(' ');
    setCancelled(true);
  }, []);

  const handleNewImageInputSubmit = useCallback(() => {
    setNewImage(newImgUrl);
    setCancelled(false);
  }, [newImgUrl, setNewImage]);

  return (
    <BasicModal open={open} onClose={onClose} maxWidth="md" scroll="body">
      <p className={classes.modalTitle}>
        Add {mediaType === mediaTypes.product ? 'product' : 'image'} information
      </p>
      <div className="flex-fill d-flex">
        <div className={`${classes.imageBlock} mr-3`}>
          {withDrop || (
            <Form
              onSubmit={handleNewImageInputSubmit}
              validate={pinMediaValidator}
              initialValues={{ url: newImgUrl }}
              render={({ handleSubmit }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <div className={classes.inputsParseUrl}>
                    <Field
                      required
                      className={classes.parseUrlInput}
                      inputWrapperClassName={classes.parseUrlInputWrapper}
                      name="url"
                      type="url"
                      pattern="(http:|https:)+[^\s]+[\w]"
                      component={CustomTextInputWrapper}
                      onChange={(e) => {
                        setNewImgUrl(e.target.value);
                      }}
                    />
                    {cancelled ? (
                      <FinalFormSubmitButton
                        variant="contained"
                        color="secondary"
                        className={classes.urlBtn}
                      >
                        Submit
                      </FinalFormSubmitButton>
                    ) : (
                      <Button
                        className={classes.urlBtn}
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </form>
              )}
            />
          )}
          <div className={classes.currentImageContainer}>
            <img src={newImgUrl} alt="" />
          </div>
        </div>
        <div className={`${classes.formBlock} pr-2`}>
          {mediaType === mediaTypes.product ? (
            <CreateProductForm
              withManual
              validate={createProductValidator}
              onSubmit={withDrop ? handleSaveOnDropMedia : handleSaveMedia}
              sourceUrl={newImgUrl}
            />
          ) : (
            <CreateImageForm
              withDrop={withDrop}
              validate={createImageValidator}
              onSubmit={withDrop ? handleSaveOnDropMedia : handleSaveMedia}
              sourceUrl={newImgUrl}
              manualSourceUrl={imgSourceUrl}
            />
          )}
        </div>
      </div>
    </BasicModal>
  );
};

SaveImageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imgUrl: PropTypes.string,
  imgSourceUrl: PropTypes.string,
  onCreateMedia: PropTypes.func.isRequired,
  setNewImage: PropTypes.func.isRequired,
  onSaveImg: PropTypes.func.isRequired,
  mediaType: PropTypes.string.isRequired,
  withDrop: PropTypes.bool.isRequired,
  changeIsOwnImage: PropTypes.func.isRequired,
};

SaveImageModal.defaultProps = {
  imgUrl: null,
  imgSourceUrl: null,
};

export default SaveImageModal;
