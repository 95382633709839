import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import LinearProgress from 'components/PreferenceQuiz/LinearProgress/LinearProgress';
import { stepsConfig } from 'components/PreferenceQuiz/preferenceQuizConfig';
import DescriptionStep from 'components/PreferenceQuiz/steps/DescriptionStep';
import StylesStep from 'components/PreferenceQuiz/steps/StylesStep';
import RoomTypeStep from 'components/PreferenceQuiz/steps/RoomTypeStep';
import ColorsStep from 'components/PreferenceQuiz/steps/ColorsStep';
import decorLoverImgUrl from 'assets/img/preference-profession-decor-lover.png';
import designerImgUrl from 'assets/img/preference-profession-designer.png';
import { updateUserPreferencesAction } from 'modules/currentUser/actions';
import errorToastr from 'libs/toastr/errorToastr';
import currentUserService from 'modules/currentUser/currentUserService';
import { professionsMap } from 'modules/account/profileAndPreferences/preferences/constants';
import SubStylesStep from 'components/PreferenceQuiz/steps/SubStylesStep';
import { getSubStylesBySelectedStyles } from 'modules/app/store/selectors';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import classes from 'components/PreferenceQuiz/PreferenceQuiz.module.scss';
import Button from 'components/ui/Button/Button';
import SpriteIcon from 'components/ui/SpriteIcon';
import bloggerImg from 'assets/img/ambassador-page/bloggerImg.jpg';

const descriptionOptions = [
  {
    id: 1,
    imgUrl: decorLoverImgUrl,
    title: 'DECOR ENTHUSIASTS',
    description:
      'You have a keen eye for design and love decorating your home and sharing tips with friends.',
    icon: 'plants',
    value: professionsMap.decorLover.value,
  },
  {
    id: 2,
    imgUrl: designerImgUrl,
    title: 'DESIGN PROFESSIONAL',
    description:
      'You are an interior designer, architect, or staging pro with an interest in sharing your designs on a new channel.',
    icon: 'interior-design',
    value: professionsMap.designProfessional.value,
  },
  {
    id: 3,
    imgUrl: bloggerImg,
    title: 'INFLUENCER | BLOGGER',
    description:
      'You are an influencer or blogger with a passion for home decor and sharing design tips with others online.',
    icon: 'monitor',
    value: professionsMap.onlineShopper.value,
  },
];

const PreferenceQuiz = ({ onCancelEdit, onComplete }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const {
    styles: stylesEnum,
    subStyles: subStylesEnum,
    roomTypes: roomTypesEnum,
    colors: colorsEnum,
    colorGroups,
  } = useSelector((state) => state.app.enums);
  const hasVerifiedEmail = useMemo(() => Boolean(user?.hasVerifiedEmail), [
    user,
  ]);
  const currentPreferences = useMemo(() => user?.preferences || {}, [user]);
  const styleOptions = useMemo(() => Object.values(stylesEnum), [stylesEnum]);
  const subStyleOptions = useMemo(
    () =>
      getSubStylesBySelectedStyles(subStylesEnum, user?.preferences?.styles),
    [subStylesEnum, user]
  );
  const roomTypesOptions = useMemo(
    () => Object.values(roomTypesEnum).slice(0, 4),
    [roomTypesEnum]
  );
  const colorsOptions = useMemo(() => Object.values(colorsEnum), [colorsEnum]);

  const [currentStep, setCurrentStep] = useState(stepsConfig[0]);
  const [loading, setLoading] = useState(false);

  const getCurrentSubStyles = useMemo(() => {
    if (!currentPreferences.subStyles) {
      return null;
    }

    return Object.keys(currentPreferences.subStyles).map((subStyleId) =>
      Number.parseInt(subStyleId, 10)
    );
  }, [currentPreferences]);

  const getCurrentColors = useMemo(() => {
    if (!currentPreferences.colors) {
      return null;
    }

    return [...new Set(Object.values(currentPreferences.colors))];
  }, [currentPreferences]);

  const goToNextStep = useCallback(() => {
    const nextStep = stepsConfig[currentStep.number];
    if (nextStep) {
      setCurrentStep(nextStep);
    }
  }, [currentStep]);

  const handleSaveProfession = useCallback(
    async (profession) => {
      if (!profession?.length) {
        errorToastr('Error', 'This field cant be empty');
        return;
      }
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updateProfession(profession);
        dispatch(updateUserPreferencesAction(preferences));
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, dispatch]
  );

  const handleSaveStyles = useCallback(
    async (styles) => {
      if (!styles.length) {
        errorToastr('Error', 'This field cant be empty');
        return;
      }
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updateStyles(styles);
        dispatch(updateUserPreferencesAction(preferences));
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, dispatch]
  );

  const handleSaveSubStyles = useCallback(
    async (subStyles) => {
      if (!subStyles.length) {
        errorToastr('Error', 'This field cant be empty');
        return;
      }
      setLoading(true);
      try {
        const preparedData = subStyles.reduce((accum, currValue) => {
          /* eslint-disable no-param-reassign */
          accum[currValue] = 1;

          return accum;
        }, {});
        const {
          result: { preferences },
        } = await currentUserService.updateSubStyles(preparedData);
        dispatch(updateUserPreferencesAction(preferences));
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, dispatch]
  );

  const handleSaveRoomTypes = useCallback(
    async (roomTypes) => {
      if (!roomTypes.length) {
        errorToastr('Error', 'This field cant be empty');
        return;
      }
      setLoading(true);
      try {
        const {
          result: { preferences },
        } = await currentUserService.updateRoomTypes(roomTypes);
        dispatch(updateUserPreferencesAction(preferences));
        goToNextStep();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [goToNextStep, dispatch]
  );

  const handleSaveColors = useCallback(
    async (colors) => {
      if (!colors.length) {
        errorToastr('Error', 'This field cant be empty');
        return;
      }
      setLoading(true);
      try {
        const col = colorsOptions
          .filter((color) => colors.includes(color.color_group_id))
          .map((color) => color.id);
        const {
          result: { preferences },
        } = await currentUserService.updateColors(col);
        dispatch(updateUserPreferencesAction(preferences));
        onComplete();
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
      setLoading(false);
    },
    [colorsOptions, onComplete, dispatch]
  );

  const handleMoveToPrevStep = useCallback(() => {
    setCurrentStep(stepsConfig[currentStep.number - 2]);
  }, [currentStep]);

  // const handleSavePriceRange = useCallback(
  //   async (priceRange) => {
  //     setLoading(true);
  //     try {
  //       const {
  //         result: { preferences },
  //       } = await currentUserService.updatePriceRange(priceRange);
  //       updateUserPreferences(preferences);
  //       onComplete();
  //     } catch (e) {
  //       setLoading(false);
  //       errorToastr('Error', e.generalError);
  //     }
  //   },
  //   [onComplete, updateUserPreferences]
  // );

  const memoizedRooms = useMemo(
    () => roomTypesOptions.filter((type) => type.id !== 3),
    [roomTypesOptions]
  );

  useEffect(() => {
    return () => {
      if (!hasVerifiedEmail) {
        dispatch(toggleVerificationModalAction(true));
      }
    };
  }, [hasVerifiedEmail, dispatch]);

  const getCurrentStep = useMemo(() => {
    switch (currentStep.number) {
      case 1:
        return (
          <DescriptionStep
            isLoading={loading}
            currentValue={currentPreferences.profession}
            options={descriptionOptions}
            onCancelEdit={onCancelEdit}
            onSaveProfession={handleSaveProfession}
          />
        );
      case 2:
        return (
          <StylesStep
            isLoading={loading}
            currentValue={currentPreferences.styles}
            options={styleOptions}
            onCancelEdit={onCancelEdit}
            onSaveStyles={handleSaveStyles}
          />
        );
      case 3:
        return (
          <SubStylesStep
            isLoading={loading}
            currentValue={getCurrentSubStyles}
            options={subStyleOptions}
            onCancelEdit={onCancelEdit}
            onSaveSubStyles={handleSaveSubStyles}
          />
        );
      case 4:
        return (
          <RoomTypeStep
            isLoading={loading}
            currentValue={currentPreferences.roomTypes}
            options={memoizedRooms}
            onCancelEdit={onCancelEdit}
            onSaveRoomTypes={handleSaveRoomTypes}
          />
        );
      case 5:
        return (
          <ColorsStep
            isLoading={loading}
            currentValue={getCurrentColors}
            options={colorsOptions}
            onCancelEdit={onCancelEdit}
            onSaveColors={handleSaveColors}
            colorGroups={colorGroups}
          />
        );
      // case 6:
      //   return (
      //     <PriceStep
      //       isLoading={loading}
      //       currentValue={currentPreferences.priceRange}
      //       options={priceRangesOptions}
      //       onCancelEdit={onCancelEdit}
      //       onSavePriceRange={handleSavePriceRange}
      //     />
      //   );
      default:
        return null;
    }
  }, [
    currentStep.number,
    loading,
    currentPreferences.profession,
    currentPreferences.styles,
    currentPreferences.roomTypes,
    onCancelEdit,
    handleSaveProfession,
    styleOptions,
    handleSaveStyles,
    getCurrentSubStyles,
    subStyleOptions,
    handleSaveSubStyles,
    memoizedRooms,
    handleSaveRoomTypes,
    getCurrentColors,
    colorsOptions,
    handleSaveColors,
    colorGroups,
  ]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-4">
          <h2 className={classes.quizTitle}>
            {`Step ${currentStep.number}. ${currentStep.subtitle}?`}
          </h2>
        </div>
        <div className="col-8">
          <div>
            <div className="d-flex mb-2">
              {stepsConfig.map(({ number, title }) => (
                <p className={classes.stepTitle} key={number}>
                  <b>Step {number}</b>
                  <br />
                  <i>{title}</i>
                </p>
              ))}
            </div>
            <LinearProgress progress={currentStep.progress} />
          </div>
        </div>
      </div>
      <p className={`${classes.selectDescription} mb-1`}>
        {currentStep.selectDescription}
      </p>
      {currentStep.number !== 1 && (
        <div className="mb-2">
          <Button
            variant="default"
            inline
            onClick={handleMoveToPrevStep}
            className={classes.backButton}
            size="custom"
            color="default"
          >
            <SpriteIcon
              name="left-arrow"
              size="sm"
              className="mr-1 primary-color"
            />
            <span> Back</span>
          </Button>
        </div>
      )}
      <div className={classes.stepContainer}>{getCurrentStep}</div>
    </>
  );
};

PreferenceQuiz.propTypes = {
  onCancelEdit: PropTypes.func,
  onComplete: PropTypes.func.isRequired,
};

PreferenceQuiz.defaultProps = {
  onCancelEdit: undefined,
};

export default PreferenceQuiz;
