import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import MatchRating from 'components/MixMatchThumbnail/MatchRating';
import classes from 'components/MixMatchThumbnail/MixMatchThumbnail.module.scss';

const ImageSlideItem = ({ id, url, averageRating, onClick, onRate, price }) => {
  const handleRatingUpdate = useCallback(
    (event, newValue) => {
      onRate(id, newValue);
    },
    [onRate, id]
  );

  return (
    <div className={classes.slideContainer}>
      <div className={classes.slide} data-id={id} onClick={onClick}>
        <img src={url} alt="" />
        {!!price && <p className={classes.price}>${price}</p>}
      </div>
      {onRate && (
        <>
          <div className="d-flex justify-content-center pt-2">
            <MatchRating
              name={String(id)}
              value={Number(averageRating)}
              onChange={handleRatingUpdate}
            />
          </div>
        </>
      )}
    </div>
  );
};

ImageSlideItem.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  averageRating: PropTypes.string,
  onRate: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  price: PropTypes.number,
};

ImageSlideItem.defaultProps = {
  averageRating: null,
  onRate: undefined,
  price: null,
};

export default ImageSlideItem;
