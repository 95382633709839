const subStylesDescriptions = [
  {
    id: 1,
    styleId: 1,
    name: 'Traditional Classic',
    description:
      'Traditional style reflects a sense of timeless elegance. This style brings together rich wood-stained antiques, classical art, vintage fabrics, and symmetry to create a space that is formal and rich in history, yet effortlessly comfortable.',
  },
  {
    id: 2,
    styleId: 1,
    name: 'Traditional Contemporary',
    description:
      'Traditional Contemporary style reflects a sense of timeless elegance with an appreciation for current trends. This style combines beloved antiques and formal pieces with contemporary accents and lighting for a sophisticated look.',
  },
  {
    id: 3,
    styleId: 1,
    name: 'Victorian',
    description:
      'Victorian style reflects a sense of romance and old world charm. This style brings together opulent antique pieces, richly-colored fabrics, crystal chandeliers, and beautiful Victorian-era artwork and motifs.',
  },
  {
    id: 4,
    styleId: 2,
    name: 'Transitional Classic',
    description:
      'Transitional style reflects a sense of subtle sophistication. This style marries traditional with contemporary for a look of casual elegance. Classic designs are paired with contemporary materials and fabrics for a look that is both practical and stylish.',
  },
  {
    id: 5,
    styleId: 2,
    name: 'Transitional Rustic',
    description:
      'Transitional Rustic style reflects a sense of security, warmth, and strength. This style marries traditional pieces with rustic materials such as leather, metal, and wood for a look that exudes masculine sophistication.',
  },
  {
    id: 6,
    styleId: 2,
    name: 'Transitional Contemporary',
    description:
      'Transitional Contemporary style reflects a sense of self-assured sophistication. This style marries traditional with contemporary, more glitzy pieces. Classic designs are paired with contemporary materials, fabrics and colors for a look that is at once bold and understated.',
  },
  {
    id: 7,
    styleId: 3,
    name: 'Contemporary Classic',
    description:
      'Contemporary style reflects a sense of sleek sophistication. This style showcases the latest trends but is curvier and more glamorous than traditional modern designs. Metal, glass and soft fabrics combine for a feeling of modern luxury.',
  },
  {
    id: 8,
    styleId: 3,
    name: 'Contemporary Glam',
    description:
      "Contemporary Glam style reflects a sense of bold opulence. This glitzy style features pieces from Hollywood's golden age mixed with metallic gold and silver accents and richly colored fabrics for a rich, luxurious look.",
  },
  {
    id: 9,
    styleId: 3,
    name: 'Contemporary Modern',
    description:
      'Contemporary Modern style reflects a sense of sleek, simple sophistication. This style showcases modern, simple furniture designs with an understated touch of glam.',
  },
  {
    id: 10,
    styleId: 4,
    name: 'Modern Classic',
    description:
      'Modern style reflects a love for functional simplicity. This style, introduced as a futuristic design trend in the 1950s, features crisp, clean lines, a simple color palette, and sleek materials, like metal and glass.',
  },
  {
    id: 11,
    styleId: 4,
    name: 'Modern Minimalist',
    description:
      'Modern Minimalism style reflects a love for clean, crisp simplicity. This style features only the bare essentials to create a simple, uncluttered space, characterized by a monochromatic palette with pops of colorful accent pieces.',
  },
  {
    id: 12,
    styleId: 4,
    name: 'Mid Century Modern',
    description:
      'Mid Century Modern style reflects a love for stylish simplicity. This truly timeless style, popularized in the 1940s, is defined by clean lines, organic forms, high functionality, earthy colors, and minimal ornamentation for an ever trendy look.',
  },
  {
    id: 13,
    styleId: 4,
    name: 'Scandinavian Modern',
    description:
      'Scandinavian style reflects a love for natural simplicity. This popular style showcases natural materials in neutral tones against a backdrop of white walls featuring black and white photography, and an abundance of plants.',
  },
  {
    id: 14,
    styleId: 5,
    name: 'Farmhouse Classic',
    description:
      'Farmhouse style reflects a sense of downhome comfort and joy. This style is defined by practicality and comfort, and features warm wood tones, plaid patterned fabric, and a cozy feeling that makes you feel right at home.',
  },
  {
    id: 15,
    styleId: 5,
    name: 'French',
    description:
      'French Farmhouse style reflects a love for old world charm and comfort. This style features rustic French style pieces with colorful floral fabrics, and whimsical farmhouse accents for a warm, cozy feeling.',
  },
  {
    id: 16,
    styleId: 5,
    name: 'Modern Farmhouse',
    description:
      'Modern Farmhouse style reflects a stylish, yet cozy approach to modern living. This style combines sleek lines with a comfy farmhouse aesthetic that mixes wood pieces with black and white decor to create a uniquely fresh approach to farmhouse decorating.',
  },
  {
    id: 17,
    styleId: 5,
    name: 'Shabby Chic',
    description:
      'Shabby Chic Farmhouse style reflects a romantic, yet practical approach to decorating. This style combines oversized plush, white seating with chippy painted furniture pieces and floral farmhouse fabrics.',
  },
  {
    id: 18,
    styleId: 5,
    name: 'Industrial Farmhouse',
    description:
      'Industrial Farmhouse style reflects strength and warmth. This style combines rustic farmhouse pieces with plenty of industrial metal accents that make your farmhouse interior a bit more edgy.',
  },
  {
    id: 19,
    styleId: 6,
    name: 'Eclectic Classic',
    description:
      "Eclectic style is for free spirits with creative flair. This style has no rules or boundaries and mixes current and vintage pieces with anything you happen to love to create a look that's colorful, bold, and highly personal.",
  },
  {
    id: 20,
    styleId: 6,
    name: 'Bohemian Eclectic',
    description:
      'Eclectic Bohemian style is for the gypsy-at-heart who follows their own path. This style brings together colorful eclectic furnishings, wicker chairs, animal print rugs, hanging plants, and anything that creates that special vibe.',
  },
  {
    id: 21,
    styleId: 6,
    name: 'Modern Eclectic',
    description:
      'Eclectic Modern style is for trendsetters with a wild streak. This style combines modern furniture pieces with colorful, eclectic accent rugs, pillows, and decor for a look that is current and uniquely personal.',
  },
  {
    id: 22,
    styleId: 7,
    name: 'Industrial Classic',
    description:
      'Industrial style exudes strength and industriousness. This aesthetic takes cues from old factories and industrial spaces, combining metal, concrete, and warm colors for an edgy and decidedly masculine look.',
  },
  {
    id: 23,
    styleId: 7,
    name: 'Rustic',
    description:
      'Rustic Industrial style exudes strength and rugged self reliance. This style takes cues from both rural and urban worlds, combining metal, leather, and reclaimed wood for a look that is equally at home in the city and mountains.',
  },
  {
    id: 24,
    styleId: 7,
    name: 'Modern Industrial',
    description:
      'Modern Industrial exudes a sense of urban savviness and strength. This style combines modern furniture pieces with metal industrial chic accents for a look that is sleek, edgy, and rustic.',
  },
  {
    id: 25,
    styleId: 7,
    name: 'Eclectic Industrial',
    description:
      'Eclectic Industrial style reflects a fun, vibrant, and hip sensibility. This style combines rustic wood and metal furniture with colorful accent pieces and funky decor for a look that is youthful, trendy and uber cool.',
  },
  {
    id: 26,
    styleId: 8,
    name: 'Shabby Chic Classic',
    description:
      'Shabby Chic style is unpretentious, romantic, and comfy. This style lovingly showcases timeworn chippy furniture pieces, oversized plush slipcover seating, and vintage estate accent pieces for a dreamy, cozy living environment.',
  },
  {
    id: 27,
    styleId: 8,
    name: 'Cottage',
    description:
      'Cottage Shabby Chic style is casual, playful and romantic. This style features distressed furniture items in painted pastel finishes, floral fabrics, and vintage estate sale decorative items for a look that is colorful and fun.',
  },
  {
    id: 28,
    styleId: 8,
    name: 'Vintage Shabby Chic',
    description:
      'Vintage Shabby Chic style is feminine and romantic. This style showcases Victorian era pieces, refurbished with pastel painted finishes and floral fabrics, and accented with lovely vintage decor for a timeless look.',
  },
  {
    id: 29,
    styleId: 8,
    name: 'Farmhouse Shabby Chic',
    description:
      'Shabby Chic Farmhouse style reflects a romantic, yet practical approach to decorating. This style combines oversized plush, white seating with chippy painted furniture pieces and floral farmhouse fabrics.',
  },
  {
    id: 30,
    styleId: 9,
    name: 'Rustic Classic',
    description:
      'Rustic style reflects a sense of strength and heartiness. This style showcases the beauty of subdued warm hues and natural materials and emphasizes casual comfort above all else.',
  },
  {
    id: 31,
    styleId: 9,
    name: 'Southwestern',
    description:
      'Southwestern style reflects an independent, vibrant spirit. This style is known for earth tones that are reminiscent of the wild west. Rich wood pieces combine with native Indian artifacts for a culturally rich and lively environment.',
  },
  {
    id: 32,
    styleId: 9,
    name: 'Rustic Lodge',
    description:
      'Rustic Lodge style is for the bold at heart who love home, family and the great outdoors. This style combines the charm of mountain living with natural elements. Thick wood, patterned fabrics, and iron create a homey look.',
  },
  {
    id: 33,
    styleId: 9,
    name: 'Mission',
    description:
      'Mission Style reflects an affinity for old world charm. This style combines thick wood pieces, rich leather, and gothic accents for a look that is reminiscent of an old world mission that exudes strength and warmth.',
  },
  {
    id: 34,
    styleId: 9,
    name: 'Tuscan',
    description:
      'Tuscan style is for romantics with a sense of flair. Tuscan interiors feature a rustic, sun-baked look characterized by rich wood pieces, terra cotta tiles, elegant iron scrollwork, and old-world murals in sunrise tones.',
  },
  {
    id: 35,
    styleId: 10,
    name: 'Natural Classic',
    description:
      'Natural style reflects a love for nature and an open-minded spirit. This style is casual, earthy, and cozy, bringing together wood furnishings, hues of browns and greens, and an abundance of plants for a zen oasis home.',
  },
  {
    id: 36,
    styleId: 10,
    name: 'Earthy',
    description:
      'Earthy style is for holistic nature lovers, who are most at home co-existing with earthy elements. This style features furnishings made from sustainable materials for a peaceful sense of wellbeing at home.',
  },
  {
    id: 37,
    styleId: 10,
    name: 'Natural Boho',
    description:
      'Natural Boho style reflects an inner-hippie spirit with creative flair. This style combines soft, cozy pieces with wicker, rattan, macrame, and rope materials to create a dreamy retro treehouse style oasis home.',
  },
  // {
  //   id: 38,
  //   styleId: 10,
  //   name: 'Modern',
  //   description:
  //     'Modern Earthy style is for environmentally conscious trendsetters. This style combines modern furnishings in earth tones with natural decor that creates a clean, tranquil aesthetic that promotes a sense of wellbeing.',
  // },
  {
    id: 38,
    name: 'Organic Japandi',
    styleId: 10,
    description:
      'Organic Japandi reflects a love for peaceful simplicity. This style mixes elements from Asian and Scandinavian design to create a minimalist, natural setting that is functional, warm and inviting.',
  },
  {
    id: 39,
    styleId: 11,
    name: 'Coastal Classic',
    description:
      'Coastal style is for beach lovers who yearn for their home to be an oasis. This style brings together coastal colors in hues of blue and beachy elements, like shells and coral, for a dreamy, breezy, clean aesthetic.',
  },
  {
    id: 40,
    styleId: 11,
    name: 'Coastal Cottage',
    description:
      'Coastal Cottage style is reflects a calm, down-to-earth, casual vibe. This style uses natural light, soft tones, and beachy elements to create a cozy, dreamy oasis setting all year-round inside your home.',
  },
  {
    id: 41,
    styleId: 11,
    name: 'Natural Coastal',
    description:
      'Natural Coastal style reflects a sense of dreamy tranquility. This style brings together light wood furniture pieces with natural coastal elements for a spa-like setting that is stylish, yet peaceful and relaxing.',
  },
  {
    id: 42,
    styleId: 11,
    name: 'Nautical',
    description:
      'Nautical Coastal style reflects a sense of calmness and strength. This style includes furnishings in whites and blues, striped fabrics, and nautical shipping helms and porthole decor - perfect for the captain of your home ship.',
  },
  {
    id: 43,
    styleId: 11,
    name: 'Modern Coastal',
    description:
      'Modern Coastal style reflects a calm spirit with a playful side. This style brings together modern pieces and accent decor in coastal colors that pop for a clean aesthetic with a breezy coastal feel all year-round.',
  },
  {
    id: 44,
    styleId: 12,
    name: 'French Classic',
    description:
      'French style reflects a certain je nais sais quoi...classic elegance and romance. Louis and Rococo furniture pieces set the tone, accented with toile fabrics, touches of gold, and vintage crystal chandeliers.',
  },
  {
    id: 45,
    styleId: 12,
    name: 'French Country',
    description:
      'French Country style reflects a romantic spirit rooted in strength and tradition. Rustic old world furniture pieces are combined with painted and distressed colorful accent pieces that create a vibrant, homey, and warm setting.',
  },
  {
    id: 46,
    styleId: 12,
    name: 'Provincial',
    description:
      'French Provincial style reflects a sense of naturally stylish elegance. This style is based on refurbishing Louis-era pieces with grain sack fabrics combined with rustic accents, crystal chandeliers, and vases of lavender.',
  },
  {
    id: 47,
    styleId: 12,
    name: 'Modern Parisian',
    description:
      'Modern Parisian reflects an effortless style that combines the old with the new. This distinctive style combines sophisticated modern pieces with old-world touches for a look of chic timeless elegance.',
  },
  {
    id: 48,
    styleId: 4,
    name: 'Rustic Modern',
    description:
      "Modern Rustic style reflects a love for simplicity and warmth. This style combines modern designs with rustic finishes, fabrics and decor for a look that exudes modern sophistication, but doesn't compromise on comfort.",
  },
  {
    id: 50,
    name: 'Coastal Grandmother',
    styleId: 11,
    description:
      'Coastal Grandmother style reflects a sense of light and airy luxury. This style combines cozy furniture in shades of light blue, beige, and white with rattan seating and lighting and light blue hued artwork.',
  },
];

export default subStylesDescriptions;
