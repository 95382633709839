import { messageFieldKeys } from 'components/modals/SendMessageModal/constants';
import { errorMessages } from 'modules/auth/signUp/constants';

const sendMessageValidator = ({
  [messageFieldKeys.name]: name,
  [messageFieldKeys.subject]: subject,
  [messageFieldKeys.text]: text,
}) => {
  const errors = {};

  if (!name) {
    errors.name = errorMessages.required;
  }

  if (!subject) {
    errors.subject = errorMessages.required;
  }

  if (!text) {
    errors.text = errorMessages.required;
  }

  return errors;
};

export default sendMessageValidator;
