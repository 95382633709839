import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import classes from './FavouriteStyles.module.scss';
import { urlPlaceholders } from '../../../../constants/urlPlaceholders';
import { getStyleImgUrl } from '../../../app/store/selectors';
import findObjectById from '../../../../utils/findObjectById';

export const FavouriteStyles = ({
  styles,
  handleFavouriteStyles,
  selectedStyles,
}) => {
  const styleImgUrl = useSelector(({ app }) => getStyleImgUrl(app.config));

  return (
    <div className={classes.root}>
      {Object.keys(styles).map((style) => {
        return (
          <div
            key={style}
            onClick={() =>
              handleFavouriteStyles(findObjectById(style, styles)?.name)
            }
            className={clsx(classes.favouriteStyleItem, {
              [classes.selected]: selectedStyles.includes(
                findObjectById(style, styles)?.name
              ),
            })}
          >
            <img
              className={classes.styleImage}
              src={styleImgUrl.replace(
                urlPlaceholders.hash,
                findObjectById(style, styles)?.media.hash
              )}
              alt={findObjectById(style, styles)?.name}
            />
            {findObjectById(style, styles)?.name}
          </div>
        );
      })}
    </div>
  );
};

FavouriteStyles.propTypes = {
  styles: PropTypes.shape({}).isRequired,
  handleFavouriteStyles: PropTypes.func.isRequired,
  selectedStyles: PropTypes.arrayOf(PropTypes.string).isRequired,
};
