import loverIcon from 'assets/img/ambassador-page/points-and-badges/lover.png';
import loverInvertedIcon from 'assets/img/ambassador-page/points-and-badges/lover-inverted.png';
import starIcon from 'assets/img/ambassador-page/points-and-badges/star.png';
import divaIcon from 'assets/img/ambassador-page/points-and-badges/diva.png';
import charterIcon from 'assets/img/charter-badge.png';
import { useSelector } from 'react-redux';

const useUserStatus = (badge) => {
  const badgesConfig = useSelector((state) => state.app.enums.badges);

  if (!badge) return null;

  const statusConfig = {
    1: {
      icon: loverIcon,
      invertedIcon: loverInvertedIcon,
      color: '#E18684',
    },
    2: {
      icon: starIcon,
      color: '#D9CAB6',
    },
    3: {
      icon: divaIcon,
      color: '#87BDC3',
    },
    4: {
      icon: charterIcon,
      color: '#000000',
    },
  };
  return { ...statusConfig[badge], ...badgesConfig[badge] };
};

export default useUserStatus;
