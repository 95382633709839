import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import clsx from 'clsx';
import classes from './PriorityItems.module.scss';

const PriorityItemsOption = ({ value, label, selected, onChange }) => {
  const handleChangeItems = useCallback(() => {
    if (onChange) {
      onChange({ label, value });
    }
  }, [onChange, label, value]);
  return (
    <div
      className={clsx(classes.tabOption, 'flex-center', {
        [classes.selected]: selected,
      })}
      onClick={handleChangeItems}
    >
      <span className={label?.length > 15 ? 'text-xs' : null}>{label}</span>
      {selected && (
        <div className={classes.iconArrow}>
          <SpriteIcon name="correct" size="xs" style={{ color: '#fff' }} />
        </div>
      )}
    </div>
  );
};

PriorityItemsOption.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};
PriorityItemsOption.defaultProps = {
  onChange: null,
  value: null,
  label: null,
};

export default PriorityItemsOption;
