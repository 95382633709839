import React from 'react';
import { toLower } from 'lodash-es';
import PropTypes from 'prop-types';
import FilterControlLabel from 'modules/requestTheLook/requestDetails/components/FilterModal/FilterControlLabel';
import { filterModalTypeShape } from 'modules/requestTheLook/requestDetails/components/FilterModal/propTypes';
import clsx from 'clsx';

import classes from './FilterModal.module.scss';

const FilterContent = ({ title, types, checkedItems, ...rest }) => (
  <ul className={classes.content}>
    {types.map(({ name, items }) => (
      <li className={clsx('mb-3', classes.valueList)} key={name}>
        <div className={classes.subCategory}>{toLower(name)}</div>
        <ul className={classes.itemsList}>
          {items.map(({ id: value, name: label }) => (
            <li key={label}>
              <FilterControlLabel
                label={label}
                value={value}
                checked={checkedItems?.includes(value)}
                {...rest}
              />
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
);

FilterContent.propTypes = {
  title: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(filterModalTypeShape).isRequired,
  checkedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FilterContent;
