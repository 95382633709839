import React, { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import PresentationCard from 'modules/home/components/PresentationCard/PresentationCard';
import discoverModalImg from 'assets/img/home-page/discover-modal.png';
import styleQuizImg from 'assets/img/home-page/style-quiz.png';
import { updateFilterValuesAction } from 'modules/getTheLook/store/actions';
import StyleQuizModal from 'components/modals/StyleQuizModal/StyleQuizModal';
import clsx from 'clsx';
import classes from './DiscoverContent.module.scss';

const DiscoverContent = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const quizViewed = useSelector((state) => state.layout.quizViewed);

  const history = useHistory();
  const [styleQuizModalOpen, setStyleQuizModalOpen] = useState(false);

  const handleOpenStyleQuizModal = useCallback(() => {
    if (!quizViewed || !user) history.push(routesByName.styleQuiz);
    setStyleQuizModalOpen(true);
  }, [history, quizViewed, user]);

  const handleCloseStyleQuizModal = useCallback(() => {
    setStyleQuizModalOpen(false);
  }, []);

  const handleApplyStyleQuiz = useCallback(
    (filterValues) => {
      dispatch(updateFilterValuesAction(filterValues));
      setStyleQuizModalOpen(false);
      history.push(routesByName.getTheLook.index);
    },
    [dispatch, history]
  );

  return (
    <>
      <div className={clsx(classes.root, { 'd-none': styleQuizModalOpen })}>
        <div className="d-flex">
          <div className={classes.cardWrapper}>
            <PresentationCard
              title="Get the Look"
              description="Browse Curated Looks and Showcase Pages"
            >
              <div className={classes.cardContent}>
                <div className={classes.shadow}>
                  <img className="w-100" src={discoverModalImg} alt="" />
                </div>
              </div>
            </PresentationCard>
            <div className={classes.btnContainer}>
              <Link to={routesByName.getTheLook.index}>
                <Button size="lg">Get the Look</Button>
              </Link>
            </div>
          </div>
          <div className={classes.cardWrapper}>
            <PresentationCard
              title="Take the Style Quiz"
              description="Get your Style Profile and Curated Looks in your Style"
            >
              <div className={classes.cardContent}>
                <div className={classes.shadow}>
                  <img className="w-100" src={styleQuizImg} alt="" />
                </div>
              </div>
            </PresentationCard>
            <div className={classes.btnContainer}>
              <Link to={routesByName.getTheLook}>
                <Button size="lg" onClick={handleOpenStyleQuizModal}>
                  Take the Style Quiz
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <StyleQuizModal
        open={styleQuizModalOpen}
        onClose={handleCloseStyleQuizModal}
        onApply={handleApplyStyleQuiz}
      />
    </>
  );
};

export default DiscoverContent;
