import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import RequestModal from 'modules/ambassadors/components/RequestModal/RequestModal';
import Button from 'components/ui/Button/Button';
import womanImg from 'assets/img/curate-the-look-page/woman.png';
import ambassadorWomenImg from 'assets/img/dashboard-page/ambassador-overview.jpg';
import classes from './AmbassadorBanner.module.scss';

const AmbassadorBanner = () => {
  const user = useSelector((state) => state.auth.user);
  const authenticated = useMemo(() => Boolean(user), [user]);
  const isAmbassador = useMemo(() => Boolean(user?.ambassador), [user]);

  const { pathname } = useLocation();
  const [requestModalOpen, setRequestModalOpen] = useState(false);

  const handleOpenRequestModal = useCallback(() => {
    setRequestModalOpen(true);
  }, []);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  return (
    <>
      {!isAmbassador ? (
        <aside className={classes.root}>
          <div className={`${classes.content} flex-fill d-flex`}>
            <div className={`${classes.info} flex-fill`}>
              <div className={`${classes.titleSm} font-italic mb-1 text-sm`}>
                Love Decorating?
              </div>
              <h3
                className={`${classes.title} primary-color text-uppercase mb-2 font-semi-bold text-nowrap`}
              >
                Become an Ambassador!
              </h3>
              <ul
                className={`${classes.list} font-italic text-sm p-0 m-0 mb-3`}
              >
                <li className="mb-2">Help others Get the Look They Love</li>
                <li className="mb-2">Get your own Showcase Page</li>
                <li>Get Discounts & Special Offers</li>
              </ul>
              <div className="mb-3">
                {authenticated ? (
                  <Button
                    onClick={handleOpenRequestModal}
                    inline
                    color="secondary"
                    variant="outlined"
                    size="lg"
                  >
                    Request an Invitation
                  </Button>
                ) : (
                  <Link
                    to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
                  >
                    <Button
                      inline
                      color="secondary"
                      variant="outlined"
                      size="lg"
                    >
                      Request an Invitation
                    </Button>
                  </Link>
                )}
              </div>
              <Link
                className="link-underline primary-color text-sm"
                to={routesByName.ambassador.index}
              >
                Learn More
              </Link>
            </div>
            <img src={womanImg} alt="" />
          </div>
          <RequestModal
            open={requestModalOpen}
            onClose={handleCloseRequestModal}
          />
        </aside>
      ) : (
        <aside className={classes.ambassadorRoot}>
          <div className={classes.ambassadorContent}>
            <h2 className={classes.ambassadorTitle}>
              NOMINATE A FRIEND TO JOIN US
            </h2>
            <Link to={routesByName.nominateAmbassador}>
              <Button size="sm">Nominate A Friend</Button>
            </Link>
          </div>
          <img src={ambassadorWomenImg} alt="" />
        </aside>
      )}
    </>
  );
};

export default AmbassadorBanner;
