import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, Form, FormSpy } from 'react-final-form';
import {
  lookBoardDetailsFormFields,
  titleInfoConfig,
} from 'modules/curateTheLook/constants';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import {
  getEnumAsSelectOptions,
  getItemClassesWithItemTypes,
} from 'modules/app/store/selectors';
import { imageTypeKeys } from 'constants/inspirationImageSearchParams';
import SelectInputWrapper from 'components/finalFormWrappers/SelectInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import InfoIconPopover from 'components/ui/InfoIconPopover/InfoIconPopover';
import AutoCompleteInputWrapper from 'components/finalFormWrappers/AutoCompleteWrapper';
import classes from './LookBoardDetails.module.scss';

const LookBoardDetailsForm = ({
  imageType,
  initialValues,
  validate,
  onSubmit,
  autoCompleteValue,
  handleChangeItemClass,
  isRequest,
  onFormChange,
}) => {
  const {
    itemClasses: itemClassesEnum,
    itemTypes: itemTypesEnum,
    roomTypes: roomTypesEnum,
    styles: stylesEnum,
  } = useSelector((state) => state.app.enums);
  const itemClasses = useMemo(
    () =>
      getItemClassesWithItemTypes({
        itemTypes: itemTypesEnum,
        itemClasses: itemClassesEnum,
      }),
    [itemClassesEnum, itemTypesEnum]
  );
  const roomTypes = useMemo(() => getEnumAsSelectOptions(roomTypesEnum), [
    roomTypesEnum,
  ]);
  const styles = useMemo(() => getEnumAsSelectOptions(stylesEnum), [
    stylesEnum,
  ]);

  return (
    <Form
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormSpy onChange={onFormChange} />
          <div className="d-flex align-items-center mb-1">
            <div className="mr-1">
              <span className={`${classes.label} font-semi-bold`}>Title</span>
              <span className="secondary-color">*</span>
            </div>
            <InfoIconPopover
              iconProps={{
                variant: 'inverted-grey',
                size: 'xs',
                color: 'secondary',
              }}
            >
              <p className="font-semi-bold text-sm mb-1">
                {titleInfoConfig.title}
              </p>
              <p className="text-xs mb-2">{titleInfoConfig.description}</p>
              <p className="font-semi-bold text-sm mb-1">
                {titleInfoConfig.examplesTitle}
              </p>
              <p className="text-xs">{titleInfoConfig.examplesDescription}</p>
            </InfoIconPopover>
          </div>
          <Field
            required
            name={lookBoardDetailsFormFields.title}
            component={TextInputWrapper}
          />
          {imageType === imageTypeKeys.room && (
            <>
              <div className="mb-1">
                <span className={`${classes.label} font-semi-bold`}>
                  Room Type
                </span>
                <span className="secondary-color">* </span>
              </div>
              <Field
                name={lookBoardDetailsFormFields.roomTypeId}
                required
                component={SelectInputWrapper}
                options={roomTypes}
              />
            </>
          )}
          {imageType === imageTypeKeys.single && (
            <>
              <div className="mb-1">
                <span className={`${classes.label} font-semi-bold`}>
                  Item Class
                </span>
                <span className="secondary-color">* </span>
              </div>

              <Field
                name={lookBoardDetailsFormFields.itemClassId}
                required
                component={(props) => {
                  return (
                    <AutoCompleteInputWrapper
                      groupBy={(option) => option.typeName}
                      value={autoCompleteValue}
                      options={itemClasses}
                      handleChange={handleChangeItemClass}
                      formValues={values}
                      placeholder="Item Class"
                      {...props}
                    />
                  );
                }}
              />
            </>
          )}
          <div className="mb-1">
            <span className={`${classes.label} font-semi-bold`}>Style</span>
            <span className="secondary-color">* </span>
          </div>
          <Field
            name={lookBoardDetailsFormFields.styleId}
            required
            component={SelectInputWrapper}
            options={styles}
          />
          <div className="d-flex align-items-center">
            <div className="mr-1">
              <span className={`${classes.label} font-semi-bold`}>
                Design Description
              </span>
              <span className="secondary-color">*</span>
            </div>
            <InfoIconPopover
              iconProps={{
                variant: 'inverted-grey',
                size: 'xs',
                color: 'secondary',
              }}
            >
              <p className="text-xs mb-2 mt-2">
                Enter a short description of your Look Board that includes the
                room type, style, and any interesting features about the
                furnishings. A unique “Design Description” will improve the
                search ranking for your Look Board online.
              </p>
            </InfoIconPopover>
          </div>
          <span className="text-sm color-gray-6">(50-150 words)</span>
          <Field
            required
            name={lookBoardDetailsFormFields.description}
            multiline
            rows={4}
            component={TextInputWrapper}
          />
          {isRequest && (
            <>
              <div>
                <span className={`${classes.label} font-semi-bold`}>
                  Send Message to Requestor
                </span>
                <span className="text-sm color-gray-6"> (optional)</span>
              </div>
              <Field
                name={lookBoardDetailsFormFields.message}
                multiline
                rows={4}
                component={TextInputWrapper}
              />
            </>
          )}
          <div className="text-center">
            <FinalFormSubmitButton inline>
              {imageType ? 'Review' : 'Save'}
            </FinalFormSubmitButton>
          </div>
        </form>
      )}
    />
  );
};

LookBoardDetailsForm.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  validate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  imageType: PropTypes.string,
  autoCompleteValue: PropTypes.shape({}).isRequired,
  handleChangeItemClass: PropTypes.func.isRequired,
  isRequest: PropTypes.bool.isRequired,
  onFormChange: PropTypes.func.isRequired,
};

LookBoardDetailsForm.defaultProps = {
  imageType: null,
};

export default React.memo(LookBoardDetailsForm);
