import { errorMessages } from 'modules/auth/signUp/constants';
import { pageDataFormFields } from 'modules/dashboard/showcase/constants';

const metaDescriptionMaxLength = 800;
const metaTitleMaxLength = 150;
const pageNameMaxLength = 30;
const subtitleMaxLength = 60;

const maxLengthError = (maxLength) =>
  `Maximum number of characters ${maxLength}`;

const addPageDataModalValidator = ({
  [pageDataFormFields.pageName]: pageName,
  [pageDataFormFields.subtitle]: subtitle,
  [pageDataFormFields.urlKey]: urlKey,
  [pageDataFormFields.metaDescription]: metaDescription,
  [pageDataFormFields.metaTitle]: metaTitle,
}) => {
  const errors = {};

  if (!pageName) {
    errors.pageName = errorMessages.required;
  } else if (pageName.length > pageNameMaxLength) {
    errors.pageName = maxLengthError(pageNameMaxLength);
  }

  if (!subtitle) {
    errors.subtitle = errorMessages.required;
  } else if (subtitle.length > subtitleMaxLength) {
    errors.subtitle = maxLengthError(subtitleMaxLength);
  }

  if (!urlKey) {
    errors.urlKey = errorMessages.required;
  }

  if (!metaDescription) {
    errors.metaDescription = errorMessages.required;
  } else if (metaDescription.length > metaDescriptionMaxLength) {
    errors.metaDescription = maxLengthError(metaDescriptionMaxLength);
  }

  if (!metaTitle) {
    errors.metaTitle = errorMessages.required;
  } else if (metaTitle.length > 150) {
    errors.metaTitle = maxLengthError(metaTitleMaxLength);
  }

  return errors;
};

export default addPageDataModalValidator;
