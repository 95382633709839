import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import classes from 'components/colorSelectors/ColorPicker/ColorPicker.module.scss';

const ColorPicker = ({ colorList, selectedColor, onChange }) => {
  const customList = useMemo(() => {
    const newList = colorList;
    // eslint-disable-next-line
    newList[8] = colorList.pop();
    // eslint-disable-next-line
    newList[7] = colorList.pop();
    return newList;
  }, [colorList]);

  return (
    <div className={clsx('d-flex flex-wrap mb-2', classes.root)}>
      {customList.map(({ id, name, code }) => (
        <ColorPoint
          key={id}
          id={id}
          title={name}
          interactive
          selected={id === selectedColor || selectedColor === code}
          className={classes.colorPoint}
          onChange={onChange}
          color={code}
        />
      ))}
    </div>
  );
};

ColorPicker.propTypes = {
  colorList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      code: PropTypes.string,
    })
  ).isRequired,
  selectedColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
  selectedColor: null,
};

export default ColorPicker;
