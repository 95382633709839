import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InspirationImage from 'components/imageThumbnails/InspirationImage/InspirationImage';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { publishStatusKeys } from 'modules/inspirationImage/constants';
import { routesByName } from 'constants/routes';
import buildResourceUrl from 'utils/buildResourceUrl';
import classes from './SimilarImageItem.module.scss';

const SimilarImageItem = ({
  id,
  userId,
  hash,
  onSelectImage,
  title,
  imageSource,
  sourceUrl,
  publish,
  slug,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );

  const imgUrl = useMemo(
    () => buildResourceUrl(inspirationImageUrl.medium, userId, hash),
    [inspirationImageUrl, userId, hash]
  );

  const handleSelectImage = useCallback(() => {
    if (pathname === routesByName.curateTheLook.canvas) {
      onSelectImage(id, slug);
      return;
    }

    if (publish && publish === publishStatusKeys.publishedPlus) {
      onSelectImage(id, slug);
    } else {
      history.push(routesByName.inspirationImage.details(id, slug));
    }
  }, [pathname, onSelectImage, id, publish, history, slug]);

  return (
    <InspirationImage
      id={id}
      imgUrl={imgUrl}
      imageSource={imageSource}
      title={title}
      publish={publish}
      hoverOverlay={
        <div className="h-100 d-flex flex-column justify-content-between">
          <div />
          <p className={classes.hoverLabel} onClick={handleSelectImage}>
            Select this Style
          </p>
          <div className="text-ellipsis">
            {sourceUrl && <ImageLink url={sourceUrl} />}
            <span
              className={clsx(classes.selectedImageInfoTitle, 'text-ellipsis')}
            >
              {title}
            </span>
            <div className={classes.selectedImageInfoSubTitle}>
              {imageSource}
            </div>
          </div>
        </div>
      }
    />
  );
};

SimilarImageItem.propTypes = {
  id: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  title: PropTypes.string,
  imageSource: PropTypes.string,
  sourceUrl: PropTypes.string,
  publish: PropTypes.string,
  slug: PropTypes.string,
};

SimilarImageItem.defaultProps = {
  title: null,
  imageSource: null,
  sourceUrl: null,
  publish: null,
  slug: '',
};

export default SimilarImageItem;
