import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import SocialShare from 'components/SocialShare/SocialShare';
import LookBoardShare from 'components/LookBoardShare/LookBoardShare';
import classes from './SharePopover.module.scss';

const SharePopover = ({
  anchorEl,
  open,
  onClose,
  placement,
  shareUrl,
  directImgUrl,
  directImgUrlFacebook,
  onShareClick,
  isLookBoard,
  lookBoardId,
  isShowcase,
  showcase,
}) => {
  return (
    <Popover
      classes={{
        paper: clsx(
          classes.root,
          placement === 'top' ? classes.top : classes.bottom
        ),
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: placement,
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: placement === 'top' ? 'bottom' : 'top',
        horizontal: 'right',
      }}
    >
      {isLookBoard ? (
        <LookBoardShare
          pageUrl={shareUrl}
          lookBoardId={lookBoardId}
          isShowcase={isShowcase}
          showcase={showcase}
        />
      ) : (
        <SocialShare
          pageUrl={shareUrl}
          directImgUrl={directImgUrl}
          directImgUrlFacebook={directImgUrlFacebook}
          onShareClick={onShareClick}
        />
      )}
    </Popover>
  );
};

SharePopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onShareClick: PropTypes.func,
  placement: PropTypes.oneOf(['top', 'bottom']),
  shareUrl: PropTypes.string,
  directImgUrlFacebook: PropTypes.string,
  directImgUrl: PropTypes.string,
  isLookBoard: PropTypes.bool,
  lookBoardId: PropTypes.number,
  isShowcase: PropTypes.bool,
  showcase: PropTypes.bool,
};

SharePopover.defaultProps = {
  anchorEl: null,
  placement: 'top',
  directImgUrl: undefined,
  directImgUrlFacebook: undefined,
  isLookBoard: false,
  lookBoardId: null,
  isShowcase: false,
  showcase: null,
  shareUrl: '',
  onShareClick: () => null,
};

export default SharePopover;
