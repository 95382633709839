import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import { routesByName } from 'constants/routes';
import { Link, Redirect } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ImageDetails from 'modules/requestTheLook/components/ImageDetails/ImageDetails';
import RequestFormContainer from 'modules/requestTheLook/requestDetails/requestForm/RequestFormContainer';
import classes from './RequestDetails.module.scss';

const RequestDetailsContainer = () => {
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const library = useSelector((state) => state.inspirationImage.library);
  const inspirationImageId = useSelector(
    (state) => state.requestTheLook.requestData.inspirationImageId
  );
  const selectedImage = useMemo(() => library[inspirationImageId], [
    inspirationImageId,
    library,
  ]);

  const imgUrl = useMemo(
    () =>
      selectedImage &&
      buildResourceUrl(
        inspirationImageUrl.medium,
        selectedImage.media.userId,
        selectedImage.media.hash
      ),
    [inspirationImageUrl, selectedImage]
  );

  return selectedImage ? (
    <section className={classes.root}>
      <div className="mb-5">
        <Link to={routesByName.requestTheLook.index}>
          <div className="d-inline-flex align-items-center mb-3">
            <IconButton variant="inverted-white" size="sm">
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="primary-color text-sm ml-1">Back</span>
          </div>
        </Link>
      </div>
      <h1 className={`${classes.title} mb-4`}>Add Request Details</h1>
      <div className="d-flex">
        <ImageDetails
          imgUrl={imgUrl}
          url={selectedImage?.url}
          title={selectedImage?.title}
          editRoute={routesByName.requestTheLook.index}
          showInfo={false}
        />
        <RequestFormContainer />
      </div>
    </section>
  ) : (
    <Redirect to={routesByName.requestTheLook.index} />
  );
};

export default RequestDetailsContainer;
