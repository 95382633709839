import { routesByName } from 'constants/routes';

export const faqsConfig = [
  {
    id: 1,
    question: 'What is a Pin the Look Ambassador?',
    answer: [
      {
        id: 1,
        paragraph: `A  <a class="primary-color link-underline" href="${routesByName.ambassador.index}">Pin the Look Ambassador</a> is a member of our online community of decor lovers who have a special interest in helping others get the look they love. Our Ambassadors are equipped with special tips and training on searching for home furnishing items to get the look of Inspiration Images.`,
      },
    ],
  },
  {
    id: 2,
    question: 'Who can become an Ambassador?',
    answer: [
      {
        id: 1,
        paragraph:
          'Any decor lover with an interest in helping others get the look they love can become an Ambassador. There are two ways to become on an Ambassador:<br>' +
          ' - Get an invitation from a current Ambassador<br>' +
          ` - <a class="primary-color link-underline" href="${routesByName.ambassador.index}">Request an invitation</a>`,
      },
      {
        id: 2,
        paragraph:
          'All decor lovers are invited to Request an Invitation and tell us about your home decorating interests and online shopping skills. We will review all requests and get back to you with a response within one week.',
      },
    ],
  },
  {
    id: 3,
    question: 'What are the benefits of becoming an Ambassador?',
    answer: [
      {
        id: 1,
        paragraph:
          ` The benefits of <a class="primary-color link-underline" href="${routesByName.ambassador.index}">becoming an Ambassador</a> include:<br>` +
          ' - Helping others find the look they love for their home<br>' +
          " - Creating your own showcase page, featuring your favorite room images and selected Look Boards you've created<br>" +
          ' - Sharing your showcase page and curated Look Boards with friends on social media (bragging rights)<br>' +
          ' - Getting discounts on home furnishings and special offers',
      },
    ],
  },
  {
    id: 4,
    question: "How do Ambassador's help others Get the Look?",
    answer: [
      {
        id: 1,
        paragraph: `When users <a class="primary-color link-underline" href="${routesByName.requestTheLook.index}">Request the Look</a> of an image, the Request will be sent to Ambassadors with matching style preferences. Ambassadors can opt to curate the request, with the goal of fulfilling it within 48 hours. To fulfill a request, Ambassadors will use our easy drag & drop tools in the <a class="primary-color link-underline" href="${routesByName.curateTheLook.canvas}">Curate the Look section</a> to create Look Boards with products similar to those found in the image. Products can be either pinned from the Web or selected from our product database (products cannot be uploaded). Ambassadors can create up to five Look Boards per request. Each Look Board will be sent to the Requester for review.`,
      },
    ],
  },
  {
    id: 5,
    question: 'What is a Showcase Page?',
    answer: [
      {
        id: 1,
        paragraph: `Ambassadors can create a <a class="primary-color link-underline" href="${routesByName.dashboard.ambassadorShowcase}">Showcase page</a> featuring their favorite room images  (from inspiration images you have added) and Look Boards you have created. A Showcase page enables Ambassadors to promote their love for interior decorating and desire to help others. Each Showcase page has a unique URL that can be shared with friends and promoted on social media.`,
      },
    ],
  },
  {
    id: 6,
    question: 'How do Ambassadors get discounts on home furnishings?',
    answer: [
      {
        id: 1,
        paragraph:
          'Active Ambassadors who have curated at least 10 Look Boards will be eligible for discounts on home furnishing products and receive special offers from our partners. The more active an Ambassador is, the greater the discounts and special offers.',
      },
    ],
  },
];

export const nominateAmbassadorFieldKeys = {
  fromFirst: 'fromFirst',
  fromLast: 'fromLast',
  toFirst: 'toFirst',
  toLast: 'toLast',
  email: 'email',
  confirmEmail: 'confirmEmail',
  subject: 'subject',
  message: 'message',
};
