import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { codeModalFields } from 'modules/ambassadors/components/CodeModal/constants';
import codeModalValidator from 'modules/ambassadors/components/CodeModal/codeModalValidator';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './CodeModal.module.scss';

const CodeModal = ({ open, onClose, initialValues, onSubmit }) => {
  return (
    <BasicModal open={open} onClose={onClose} fullWidth maxWidth="sm">
      <div className={classes.root}>
        <div className="flex-center flex-column text-center">
          <h2 className={classes.title}>ACTIVATE CODE</h2>
          <p className={classes.description}>
            Please activate your Ambassador account by entering your code or, if
            the code is shown, by clicking on the{' '}
            <span className="fw-600">Get Started Now</span> button
          </p>
          <Form
            initialValues={initialValues}
            validate={codeModalValidator}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Field
                  className={classes.codeField}
                  required
                  name={codeModalFields.code}
                  component={TextInputWrapper}
                />
                <div>
                  <FinalFormSubmitButton
                    className="mt-4"
                    variant="outlined"
                    inline
                  >
                    Get Started Now!
                  </FinalFormSubmitButton>
                </div>
              </form>
            )}
          />
        </div>
      </div>
    </BasicModal>
  );
};

CodeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CodeModal;
