import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import classes from 'modules/getTheLook/GetTheLook.module.scss';
import useCallbackRef from 'hooks/useCallbackRef';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import errorToastr from 'libs/toastr/errorToastr';
import LookBoardItem from '../../components/LookBoardItem';
import UserCard from './UserCard';
import NoLookBoardsModal from './NoLookBoardsModal/NoLookBoardsModal';
import GTLSlider from '../../components/GTLSlider';
import {
  setSelectedUserAction,
  toggleLikeAction,
  voteAction,
} from '../store/actions';

const UserListItem = ({ user }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const lookBoardsData = useSelector((state) => state.userPage.lookBoards);
  const appUser = useSelector((state) => state.auth.user);

  const [sliderNode, sliderRef] = useCallbackRef();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleViewAllLookBoards = useCallback(() => {
    dispatch(setSelectedUserAction(user));
    history.push(routesByName.getTheLook.users.details(user.id));
  }, [dispatch, history, user]);

  const handleToggleLikeLookBoard = useCallback(
    (lookBoardId, likeStatus) => {
      if (!appUser) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
        return;
      }
      try {
        dispatch(toggleLikeAction(lookBoardId, likeStatus));
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch, appUser, history]
  );

  const handleVoteLookBoard = useCallback(
    (lookBoardId, voteValue) => {
      if (!appUser) {
        history.push(`?${routesByName.auth.key}=${routesByName.auth.signIn}`);
        return;
      }
      try {
        dispatch(voteAction(lookBoardId, voteValue));
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch, appUser, history]
  );

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderNode.slickPrev();
  }, [sliderNode]);

  const handleSlideNext = useCallback(() => {
    sliderNode.slickNext();
  }, [sliderNode]);

  const hasLookBoards = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const id of user.lookBoards) {
      if (lookBoardsData[id]) return true;
    }
    return false;
  }, [lookBoardsData, user.lookBoards]);

  return (
    <div
      className={clsx(
        'd-flex position-relative mb-3 pt-2 pb-5 align-items-center'
      )}
    >
      <div className={classes.leftPanelWrapper}>
        <UserCard user={user} />
      </div>
      <div className={classes.mainContainer}>
        {!hasLookBoards && <NoLookBoardsModal />}
        {hasLookBoards && (
          <GTLSlider
            sliderRef={sliderRef}
            slideLength={user.lookBoards.length}
            activeSlideIndex={activeSlideIndex}
            onSlidePrev={handleSlidePrev}
            onSlideNext={handleSlideNext}
            onSlideChange={handleSlideChange}
            onViewAll={handleViewAllLookBoards}
          >
            {user.lookBoards.map(
              (lookBoardId) =>
                lookBoardsData[lookBoardId] && (
                  <div key={lookBoardId} className="px-1">
                    <LookBoardItem
                      lookBoard={lookBoardsData[lookBoardId]}
                      products={lookBoardsData[lookBoardId].products}
                      user={user}
                      onToggleLike={handleToggleLikeLookBoard}
                      onVote={handleVoteLookBoard}
                    />
                  </div>
                )
            )}
          </GTLSlider>
        )}
      </div>
    </div>
  );
};

UserListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ambassador: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    lookBoards: PropTypes.arrayOf(PropTypes.number),
    styles: PropTypes.arrayOf(PropTypes.number),
    avatar: PropTypes.shape({
      userId: PropTypes.number,
      hash: PropTypes.string,
      type: PropTypes.string,
    }),
  }).isRequired,
};

export default UserListItem;
