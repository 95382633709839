import React from 'react';
import classes from './AmbassadorRewards.module.scss';
import swagImg from '../../../../assets/img/ambassador-page/rewards/swag.svg';
import decorImg from '../../../../assets/img/ambassador-page/rewards/decor.svg';
import giftImg from '../../../../assets/img/ambassador-page/rewards/gift.svg';
import charityImg from '../../../../assets/img/ambassador-page/rewards/charity.svg';

const AmbassadorRewards = () => {
  return (
    <>
      <h3 className={classes.sectionTitle}>rewards</h3>
      <p className={classes.sectionDescription}>
        While decorating the world, you will earn points that can be redeemed
        for rewards, including home decor, gift cards, and swag. You can also
        use your points to donate to our charity match program.
      </p>

      <div className={classes.rewardsContainer}>
        <div className={classes.rewardsItem}>
          <img src={swagImg} alt="swag" />
          <span>Swag</span>
        </div>
        <div className={classes.rewardsItem}>
          <img src={decorImg} alt="decor" />
          <span>Decor</span>
        </div>
        <div className={classes.rewardsItem}>
          <img src={giftImg} alt="gift" />
          <span>Gift Card</span>
        </div>
        <div className={classes.rewardsItem}>
          <img src={charityImg} alt="charity" />
          <span>Charity</span>
        </div>
      </div>
    </>
  );
};

export default AmbassadorRewards;
