import React from 'react';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './InfoMenu.module.scss';
import Button from '../../../../components/ui/Button/Button';

const InfoMenuItem = ({ title, description, icon, buttonLabel }) => (
  <div className="mb-5">
    {buttonLabel ? (
      <Button
        variant="contained"
        color="primary"
        size="xs"
        className={classes.button}
      >
        {buttonLabel}
      </Button>
    ) : (
      <div className="d-flex align-items-center mb-2">
        <div className={classes.icon}>
          <SpriteIcon name={icon} />
        </div>
        {title}
      </div>
    )}

    <p className={classes.itemDescription}>{description}</p>
  </div>
);

InfoMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
};

InfoMenuItem.defaultProps = {
  buttonLabel: null,
};
export default InfoMenuItem;
