import React from 'react';
import { stepsBannerCongif } from 'modules/curateTheLook/constants';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './StepsBanner.module.scss';

const StepsBanner = () => (
  <div className={classes.root}>
    <h2 className={classes.title}>What Happens Next</h2>
    <div className="d-flex">
      {stepsBannerCongif.map(({ icon, title, action }) => (
        <div key={action} className={`${classes.step}`}>
          <div className={classes.icon}>
            <SpriteIcon name={icon} size="lg" />
          </div>
          <div className={`${classes.stepTitle} font-italic text-sm`}>
            {title}
          </div>
          <div className={`${classes.stepTitle} font-italic text-sm`}>
            {action}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default StepsBanner;
