import { shareViaEmailFormFields } from 'modules/dashboard/showcase/constants';
import { errorMessages } from 'modules/curateTheLook/constants';

const shareViaEmailValidator = ({
  [shareViaEmailFormFields.firstName]: firstName,
  [shareViaEmailFormFields.lastName]: lastName,
  [shareViaEmailFormFields.toFirstName]: toFirstName,
  [shareViaEmailFormFields.toEmailAddress]: toEmailAddress,
  [shareViaEmailFormFields.subject]: subject,
}) => {
  const errors = {};

  if (!firstName) {
    errors.firstName = errorMessages.required;
  }

  if (!lastName) {
    errors.lastName = errorMessages.required;
  }

  if (!toFirstName) {
    errors.toFirstName = errorMessages.required;
  }

  if (!toEmailAddress) {
    errors.toEmailAddress = errorMessages.required;
  }

  if (!subject) {
    errors.subject = errorMessages.required;
  }

  return errors;
};

export default shareViaEmailValidator;
