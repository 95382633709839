import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfileComponent from 'modules/account/profileAndPreferences/profile/ProfileComponent';
import { getEnumAsSelectOptions } from 'modules/app/store/selectors';
import { transformUserToProfileFormValues } from 'modules/account/profileAndPreferences/profile/transformers';
import {
  changeEmailAction,
  deactivateAccountAction,
  updateUserProfileAction,
} from 'modules/currentUser/actions';
import errorToastr from 'libs/toastr/errorToastr';
import successToastr from 'libs/toastr/successToastr';
import useCurrentUserAvatarUrl from 'hooks/useCurrentUserAvatarUrl';
import mediaTypes from 'constants/mediaTypes';
import mediaService from 'modules/media/mediaService';
import authService from 'modules/auth/authService';
import currentUserService from 'modules/currentUser/currentUserService';
import VerificationLinkModal from 'modules/account/profileAndPreferences/profile/VerificationLinkModal/VerificationLinkModal';
import CustomerSupportModal from 'modules/account/components/CustomerSupportModal/CustomerSupportModal';
import supportService from 'modules/support/supportService';
import { supportFormFields, supportFormTypes } from 'modules/support/constants';
import { toggleVerificationModalAction } from 'modules/layout/store/actions';
import { useHistory } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import { refreshCurrentUserDataAction } from 'modules/auth/store/actions';
import {
  addAffiliateCodeAction,
  deleteAffiliateCodeAction,
  editAffiliateCodeAction,
  getAffiliateSitesAction,
} from 'modules/getTheLook/userPage/store/actions';
import multipleErrorToastr from '../../../../libs/toastr/multipleErrorToastr';

const ProfileContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const affiliateSites = useSelector((state) => state.userPage.affiliateSites);
  const { countries: countriesEnum, languages: languagesEnum } = useSelector(
    (state) => state.app.enums
  );
  const countries = useMemo(() => getEnumAsSelectOptions(countriesEnum), [
    countriesEnum,
  ]);
  const languages = useMemo(() => getEnumAsSelectOptions(languagesEnum), [
    languagesEnum,
  ]);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [isAddAffiliateCode, setIsAddAffiliateCode] = useState(false);
  const [profileFormValues, setProfileFormValue] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [changeEmailModalOpen, setChangeEmailModal] = useState(false);
  const [changeEmailFormValues, setChangeEmailFormValues] = useState(null);
  const history = useHistory();

  const confirmChangeEmailOpen = useCallback((formValues) => {
    setChangeEmailModal(true);
    setChangeEmailFormValues(formValues);
  }, []);

  const newImageUrl = useMemo(() => {
    return newImage ? URL.createObjectURL(newImage) : newImage;
  }, [newImage]);

  const currentAvatarUrl = useCurrentUserAvatarUrl();

  const initialSupportFormValues = useMemo(
    () => ({ name: `${user.firstName} ${user.lastName}`, email: user.email }),
    [user]
  );

  useEffect(() => {
    const profileData = transformUserToProfileFormValues(user);
    setProfileFormValue(profileData);
  }, [user]);

  useEffect(() => {
    if (user?.hasVerifiedEmail) dispatch(getAffiliateSitesAction('profile'));
    // eslint-disable-next-line
  }, []);

  const handleEnableEditMode = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleEnableEditEmailMode = useCallback(() => {
    setIsEditEmail(true);
  }, []);

  const handleEnableEditPasswordMode = useCallback(() => {
    setIsEditPassword(true);
  }, []);

  const handleToggleAddAffiliateCodeMode = useCallback(() => {
    if (!user?.hasVerifiedEmail) {
      dispatch(toggleVerificationModalAction(true));
      return;
    }
    setIsAddAffiliateCode((prevState) => !prevState);
  }, [dispatch, user]);

  const handleChangeProfile = useCallback(
    async (formValues) => {
      try {
        let avatarId = null;
        if (newImage) {
          const formData = new FormData();

          formData.append('type', mediaTypes.userAvatar);
          formData.append('file', newImage);

          const {
            result: { id },
          } = await mediaService.uploadMedia(formData);
          avatarId = id;
        }

        await dispatch(updateUserProfileAction({ ...formValues, avatarId }));
        setNewImage(null);
        setIsEdit(false);
        successToastr('Success', 'Profile was successfully updated');
      } catch (e) {
        errorToastr('Profile was not updated', e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [dispatch, newImage]
  );

  const handleSelectNewImage = useCallback(({ target: { files } }) => {
    if (!files.length) return;
    if (files[0].type.startsWith('image')) {
      const imageSize = files[0].size / (1024 * 1024);
      if (imageSize > 4) {
        errorToastr('Error', 'Max image size is 4MB');
        return;
      }
      setNewImage(files[0]);
    }
  }, []);

  const handleCancelEditProfile = useCallback(() => {
    const profileData = transformUserToProfileFormValues(user);
    setProfileFormValue(profileData);
    setIsEdit(false);
    setNewImage(null);
  }, [user]);

  const handleChangeEmail = useCallback(
    async (confirm) => {
      if (confirm) {
        try {
          await dispatch(changeEmailAction(changeEmailFormValues));
          successToastr('Success', 'Email successfully changed');
          setIsEditEmail(false);
          setChangeEmailModal(false);
        } catch (e) {
          errorToastr('Error', e.generalError);
          return { ...e.validationErrors };
        }
      }
      setChangeEmailModal(false);
      return true;
    },
    [changeEmailFormValues, dispatch]
  );

  const handleChangePassword = useCallback(
    async (formValues) => {
      if (!user.hasVerifiedEmail) {
        dispatch(toggleVerificationModalAction(true));
        return;
      }
      try {
        await authService.changePassword(formValues);
        successToastr('Success', 'Password successfully changed');
        setIsEditPassword(false);
      } catch (e) {
        errorToastr('Error', e.generalError);
        // eslint-disable-next-line consistent-return
        return { ...e.validationErrors };
      }
      // eslint-disable-next-line consistent-return
      return true;
    },
    [dispatch, user.hasVerifiedEmail]
  );

  const handleCancelEditEmail = useCallback(() => {
    setIsEditEmail(false);
  }, []);

  const handleSendVerificationLink = useCallback(async () => {
    await currentUserService.sendVerificationLink();
    setVerificationModalOpen(true);
  }, []);

  const handleCloseVerificationModal = useCallback(() => {
    setVerificationModalOpen(false);
  }, []);

  const handleCancelEditPassword = useCallback(() => {
    setIsEditPassword(false);
  }, []);

  const handleDeactivateUser = useCallback(async () => {
    try {
      await dispatch(deactivateAccountAction());
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
  }, [dispatch]);

  const handleContactSupport = useCallback(() => {
    setVerificationModalOpen(false);
    setSupportModalOpen(true);
  }, []);

  const handleCloseSupportModal = useCallback(() => {
    setSupportModalOpen(false);
  }, []);

  // TODO: Send reCaptcha token to backend
  const reCaptchaVerify = useCallback(() => {
    setDisableSubmit(false);
  }, []);

  const handleSendSupportMessage = useCallback(async (formValues) => {
    try {
      await supportService.sendSupportMessage({
        [supportFormFields.type]: supportFormTypes.support,
        ...formValues,
      });
      successToastr('Success', 'Message successfully sent');
      setSupportModalOpen(false);
      setDisableSubmit(true);
    } catch (e) {
      errorToastr('Error', e.generalError);
      return { ...e.validationErrors };
    }
    return true;
  }, []);

  const handleChangeMembership = useCallback(() => {
    history.push(
      user.ambassador
        ? routesByName.nominateAmbassador
        : routesByName.ambassador.index
    );
  }, [user, history]);

  useEffect(() => {
    dispatch(refreshCurrentUserDataAction());
    //  eslint-disable-next-line
  }, []);
  const handleAddNewCodeItem = useCallback(
    async (formValues, form) => {
      const { affiliateCode, affiliateSite, affiliateCouponCode } = formValues;
      const newCode = {
        affiliate_code: affiliateCode,
        site: affiliateSite,
        coupon_code: affiliateCouponCode,
        user_id: user.id,
      };
      try {
        await dispatch(addAffiliateCodeAction(newCode));
        setIsEdit(false);
        successToastr('Success', 'Code has been successfully added');
      } catch (e) {
        const validationArrayErrors = Object.values(e.validationErrors);
        if (validationArrayErrors.length) {
          multipleErrorToastr(validationArrayErrors, 'Code was not added');
        } else {
          errorToastr('Code was not added', e.generalError);
        }
        return { ...e.validationErrors };
      }

      setTimeout(() => {
        form.reset();
        handleToggleAddAffiliateCodeMode();
      });
      return true;
    },
    [dispatch, handleToggleAddAffiliateCodeMode, user.id]
  );

  const handleEditAffiliateCode = useCallback(
    async (data, id) => {
      try {
        await dispatch(editAffiliateCodeAction(data, id));
        setIsEdit(false);
        successToastr('Success', 'Code has been successfully updated');
      } catch (e) {
        const validationArrayErrors = Object.values(e.validationErrors);
        if (validationArrayErrors.length) {
          multipleErrorToastr(validationArrayErrors, 'Code was not updated');
        } else {
          errorToastr('Code was not updated', e.generalError);
        }
        return { ...e.validationErrors };
      }
      return true;
    },
    [dispatch]
  );
  return (
    <>
      <ProfileComponent
        isEdit={isEdit}
        isEditEmail={isEditEmail}
        isEditPassword={isEditPassword}
        isAddAffiliateCode={isAddAffiliateCode}
        onEnableEditMode={handleEnableEditMode}
        onEnableEditEmailMode={handleEnableEditEmailMode}
        onEnableEditPasswordMode={handleEnableEditPasswordMode}
        onToggleAddAffiliateCodeMode={handleToggleAddAffiliateCodeMode}
        profileValues={profileFormValues}
        user={user}
        avatarUrl={newImageUrl || currentAvatarUrl}
        countriesOptions={countries}
        languagesOptions={languages}
        onChangeProfile={handleChangeProfile}
        onChangeEmail={handleChangeEmail}
        onChangePassword={handleChangePassword}
        onCancelEditProfile={handleCancelEditProfile}
        onCancelEditEmail={handleCancelEditEmail}
        onSendVerificationLink={handleSendVerificationLink}
        onCancelEditPassword={handleCancelEditPassword}
        onSelectNewImage={handleSelectNewImage}
        onDeactivateUser={handleDeactivateUser}
        confirmChangeEmailOpen={confirmChangeEmailOpen}
        changeEmailModalOpen={changeEmailModalOpen}
        handleChangeMembership={handleChangeMembership}
        affiliateSites={affiliateSites}
        addAffiliateCode={handleAddNewCodeItem}
        editAffiliateCode={handleEditAffiliateCode}
        deleteAffiliateCode={(e) => dispatch(deleteAffiliateCodeAction(e))}
      />
      <VerificationLinkModal
        open={verificationModalOpen}
        onClose={handleCloseVerificationModal}
        email={user.email}
        onContactSupport={handleContactSupport}
      />
      <CustomerSupportModal
        open={supportModalOpen}
        onClose={handleCloseSupportModal}
        initialValues={initialSupportFormValues}
        onSubmit={handleSendSupportMessage}
        disableSubmit={disableSubmit}
        reCaptchaVerify={reCaptchaVerify}
      />
    </>
  );
};
export default ProfileContainer;
