import React, { useCallback, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import CreateLookBoardContainer from 'modules/curateTheLook/createLookBoard/CreateLookBoardContainer';
import LookBoardDetailsContainer from 'modules/curateTheLook/lookBoardDetails/LookBoardDetailsContainer';
import SubmitLookBoardContainer from 'modules/curateTheLook/submitLookBoard/SubmitLookBoardContainer';
import LookBoardSuccessContainer from 'modules/curateTheLook/lookBoardSuccess/LookBoardSuccessContainer';
import useMediaQuery from 'hooks/useMediaQuery';
import { maxWidthMd } from 'constants/mediaQueries';
import curateSectionImg from 'assets/img/home-page/how-it-works/curate.png';
import { useSelector } from 'react-redux';
import RequestDetailsModal from 'modules/curateTheLook/components/RequestDetailsModal/RequestDetailsModal';
import classes from '../../components/StubMobilePage/StubMobilePage.module.scss';
import Button from '../../components/ui/Button/Button';
import { useRegisterEffect } from '../../hooks/useRegisterEffect';

const CurateTheLookContainer = () => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);
  const { pathname } = useLocation();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.user);

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);

  const handleDetailModalOpen = useCallback(() => {
    setDetailsModalOpen(true);
  }, []);

  const handleDetailModalClose = useCallback(() => {
    setDetailsModalOpen(false);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (pathname !== routesByName.curateTheLook.canvas) {
      history.replace(routesByName.curateTheLook.canvas);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentUser && !history.location.search) {
      history.push(
        `${pathname}?${routesByName.auth.key}=${routesByName.auth.signUp}`,
        { title: 'REGISTER NOW' }
      );
    }
  }, [history, history.location.search, pathname, currentUser]);

  const isRequest = useSelector(
    ({ curateTheLook }) => curateTheLook.lookBoardData.requestId
  );

  useRegisterEffect();

  return matchesMediaQuery ? (
    <>
      <div className="d-flex flex-fill flex-column h-100 justify-content-center">
        <div className="py-3 px-3">
          <div className="text-center mb-2">
            <h1 className={classes.title}>Curate the Look</h1>
            <p className={classes.subTitle}>of your Favorite Room Images</p>
          </div>
          <div className={classes.imageWrapper}>
            <img className="mb-3" src={curateSectionImg} alt="" />
          </div>
        </div>
        <div className={classes.useDesktop}>— Use PC to Curate the Look —</div>
        <div className="px-3">
          <Button
            className="mb"
            component={Link}
            to={routesByName.ambassador.index}
          >
            Become an Ambassador
          </Button>
        </div>
      </div>
    </>
  ) : (
    <>
      <Switch>
        <Route exact path={routesByName.curateTheLook.canvas}>
          <CreateLookBoardContainer
            onRequestDetailsPreview={handleDetailModalOpen}
          />
        </Route>
        <Route exact path={routesByName.curateTheLook.details}>
          <LookBoardDetailsContainer
            onRequestDetailsPreview={handleDetailModalOpen}
          />
        </Route>
        <Route exact path={routesByName.curateTheLook.submit}>
          <SubmitLookBoardContainer
            onRequestDetailsPreview={handleDetailModalOpen}
          />
        </Route>
        <Route exact path={routesByName.curateTheLook.success}>
          <LookBoardSuccessContainer />
        </Route>
      </Switch>
      {isRequest && (
        <RequestDetailsModal
          open={detailsModalOpen}
          onClose={handleDetailModalClose}
        />
      )}
    </>
  );
};

export default CurateTheLookContainer;
