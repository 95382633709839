import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

const DatePickerInputWrapper = ({ input, ...rest }) => (
  <DatePicker {...input} {...rest} />
);

DatePickerInputWrapper.propTypes = {
  input: PropTypes.shape({}).isRequired,
};

export default DatePickerInputWrapper;
