import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getImageType } from 'modules/app/store/selectors';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import ImageDetails from 'modules/dashboard/myImages/components/ImageDetails/ImageDetails';
import ImageEditForm from 'modules/dashboard/myImages/components/ImageEditForm/ImageEditForm';
import classes from './ImageDetailsModal.module.scss';

const ImageDetailsModal = ({
  open,
  onClose,
  title,
  url,
  type,
  description,
  imgUrl,
  imgType,
  showEdit,
  editMode,
  onSave,
}) => {
  const [editEnable, setEditEnable] = useState(showEdit);
  const initialFormValues = useMemo(() => ({ title, description }), [
    title,
    description,
  ]);

  const handleEditEnable = useCallback(() => {
    setEditEnable(true);
  }, []);

  const handleEditDisable = useCallback(() => {
    setEditEnable(false);
  }, []);

  return (
    <BasicModal
      classes={{ root: 'p-0' }}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <div className="d-flex">
        <div className="col-6 p-0">
          <img className="w-100" src={imgUrl} alt="" />
        </div>
        <div className={`${classes.detailsContainer} col-6 d-flex flex-column`}>
          {editEnable ? (
            <ImageEditForm
              initialValues={initialFormValues}
              onCancel={handleEditDisable}
              onSubmit={onSave}
            />
          ) : (
            <ImageDetails
              title={title}
              url={url}
              type={type}
              description={description}
              imgType={imgType.name}
              editMode={editMode}
              onEdit={handleEditEnable}
            />
          )}
        </div>
      </div>
    </BasicModal>
  );
};

ImageDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
  roomTypeId: PropTypes.number, // eslint-disable-line
  itemClassId: PropTypes.number, // eslint-disable-line
  imgUrl: PropTypes.string.isRequired,
  imgType: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  showEdit: PropTypes.bool,
  editMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

ImageDetailsModal.defaultProps = {
  url: null,
  description: null,
  roomTypeId: null,
  itemClassId: null,
  showEdit: false,
};

const mapStateToProps = (
  { app: { enums } },
  { roomTypeId, itemClassId, type }
) => ({
  imgType: getImageType({ roomTypeId, itemClassId, type }, enums),
});

export default connect(mapStateToProps)(ImageDetailsModal);
