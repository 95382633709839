import PropTypes from 'prop-types';
import React from 'react';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import previewImg from 'assets/img/dashboard-page/showcase/showcasePageTitlePreview.jpg';

const PageTitlePreviewModal = ({ open, onClose }) => (
  <BasicModal onClose={onClose} open={open} fullWidth maxWidth="md">
    <div>
      <h2 className="text-uppercase mb-3 font-title">
        Sample Showcase Page Name
      </h2>
      <div>
        <img className="w-100" src={previewImg} alt="Title Preview" />
      </div>
    </div>
  </BasicModal>
);

PageTitlePreviewModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PageTitlePreviewModal;
