import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import { getProductImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import { productShape } from 'modules/product/propTypes';
import { routesByName } from 'constants/routes';
import MatchRating from 'components/MixMatchThumbnail/MatchRating';
import productThumbnailClasses from 'components/ProductThumbnail/ProductThumbnail.module.scss';
import classes from './RecommendationsProductItem.module.scss';
import findObjectById from '../../../../../utils/findObjectById';

const RecommendationsProductItem = ({
  className,
  product: {
    id,
    name,
    media,
    itemClassId, // eslint-disable-line
    price,
    isLiked,
    shareUrl,
    averageRating,
  },
  onToggleLike,
  onRate,
  productImgUrl,
  itemClass,
}) => {
  const history = useHistory();
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  const [hover, setHover] = useState(false);
  const [isRatingHover, setIsRatingHover] = useState(false);

  const imgUrl = useMemo(
    () => buildResourceUrl(productImgUrl.medium, media.userId, media.hash),
    [media, productImgUrl]
  );

  const productPrice = useMemo(() => {
    const calculatedPrice = price / 100;
    return Number.isNaN(calculatedPrice) ? 0 : calculatedPrice;
  }, [price]);

  const handleHover = useCallback(() => {
    setHover(true);
  }, []);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  const handleOpenSharePopover = useCallback(({ currentTarget }) => {
    setShareAnchorEl(currentTarget);
  }, []);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const moveToDetails = useCallback(() => {
    history.push(routesByName.product.details(id));
  }, [history, id]);

  const handleRatingHover = useCallback(() => {
    setIsRatingHover(true);
  }, []);

  const handleRatingBlur = useCallback(() => {
    setIsRatingHover(false);
  }, []);

  const handleRatingUpdate = useCallback(
    (event, newValue) => {
      onRate(id, newValue);
    },
    [onRate, id]
  );

  const handleToggleLike = useCallback(() => {
    onToggleLike(id, Number(!isLiked));
  }, [onToggleLike, id, isLiked]);

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <div
          className="background-white position-relative flex-fill flex-center"
          onMouseEnter={handleHover}
          onMouseLeave={handleBlur}
        >
          <img
            alt={name}
            draggable="false"
            src={imgUrl}
            className={clsx({ [classes.blurred]: hover })}
          />
          <div
            className={clsx(classes.hoverOverlay, { [classes.hover]: hover })}
          >
            <div className="w-100 d-flex justify-content-end">
              <IconButton
                variant="inverted-grey"
                size="sm"
                onClick={handleOpenSharePopover}
              >
                <SpriteIcon name="share" size="sm" />
              </IconButton>
            </div>
            <div className="flex-fill flex-center">
              <span
                className="text-capitalize text-center cursor-pointer"
                onClick={moveToDetails}
              >
                Click to view
                <br />
                details
              </span>
            </div>
          </div>
        </div>
        <div className="px-1 background-white">
          <div
            className={clsx(
              'd-flex justify-content-between align-items-center',
              classes.productLabel
            )}
          >
            <div className="py-1 text-ellipsis">
              <p
                title={name}
                className={`${productThumbnailClasses.productName} text-ellipsis text-sm text-gray font-semi-bold mb-1`}
              >
                {name}
              </p>
              <i className="text-xs text-gray text-ellipsis text-nowrap">
                {itemClass}
              </i>
            </div>
            <span className="primary-color text-sm font-semi-bold">{`$${productPrice}`}</span>
          </div>

          <div
            className="position-relative d-flex justify-content-between align-items-center py-1"
            onMouseEnter={handleRatingHover}
            onMouseLeave={handleRatingBlur}
          >
            <MatchRating
              name={String(id)}
              value={Number(averageRating)}
              onChange={handleRatingUpdate}
            />
            <IconButton
              size="sm"
              variant="inverted-grey"
              onClick={handleToggleLike}
            >
              <SpriteIcon name={isLiked ? 'like-filled' : 'like'} size="sm" />
            </IconButton>
          </div>
        </div>
        <div
          className={clsx(classes.hoverRating, {
            [classes.hover]: isRatingHover,
          })}
        >
          Please rate how well this item matches the similar item in your
          inspiration image
        </div>
      </div>
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        placement="bottom"
        shareUrl={shareUrl}
      />
    </>
  );
};

RecommendationsProductItem.propTypes = {
  className: PropTypes.string,
  product: productShape.isRequired,
  onToggleLike: PropTypes.func.isRequired,
  onRate: PropTypes.func.isRequired,
  productImgUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  itemClass: PropTypes.string.isRequired,
};

RecommendationsProductItem.defaultProps = {
  className: '',
};

const mapStateToProps = (
  {
    app: {
      config,
      enums: { itemClasses },
    },
  },
  { product: { itemClassId } }
) => ({
  productImgUrl: getProductImgUrl(config),
  itemClass: findObjectById(itemClassId, itemClasses).name,
});

export default connect(mapStateToProps)(RecommendationsProductItem);
