import React, { useCallback, useEffect, useRef, useState } from 'react';
import { messagesTabKeys } from 'modules/dashboard/messages/constants';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import messagesService from 'modules/dashboard/messages/messagesService';
import errorToastr from 'libs/toastr/errorToastr';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import ConversationArea from 'modules/dashboard/messages/components/ConversationArea/ConversationArea';
import accountClasses from 'modules/account/Account.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  setMessagesByIdAction,
  pushMessageByIdAction,
  updateMessagesByIdAction,
  setCurrentConversationAction,
  setConversationOnTopAction,
} from 'modules/conversation/store/actions';
import useConnectToEchoServer from 'libs/echo/useConnectToEchoServer';
import channelsByName from 'libs/echo/channels';
import { MESSAGE_SENT } from 'libs/echo/events';
import clsx from 'clsx';
import Button from 'components/ui/Button/Button';
import ConversationList from 'modules/dashboard/messages/ConversationList';
import CustomScrollBar from 'components/ui/CustomScrollbar/CustomScrollBar';
import ContactList from 'modules/dashboard/messages/ContactList';
import useCancelToken from 'hooks/useCancelToken';
import classes from './Messages.module.scss';

const messagesLimit = 20;
const conversationsLimit = 15;
const contactUsersLimit = 15;

const initialConversationsSearchParams = {
  offset: 0,
  limit: conversationsLimit,
  name: '',
  interlocutor: '',
};

const initialContactsSearchParams = {
  offset: 0,
  limit: contactUsersLimit,
  name: '',
};

const MessagesContainer = () => {
  const dispatch = useDispatch();

  const usersLibrary = useSelector((state) => state.users.library);
  const {
    conversations: conversationsById,
    messagesByConversationId,
    metaByConversationId,
    currentConversationId,
  } = useSelector((state) => state.conversation);

  const inputRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const scrollBarRef = useRef(null);
  const [createCancelToken, isCancell] = useCancelToken();

  const [activeTab, setActiveTab] = useState(messagesTabKeys.conversations);
  const [scrollHeight, setScrollHeight] = useState(0);
  // const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [contactsSearchQuery, setContactsSearchQuery] = useState('');
  const [conversationsSearchQuery, setConversationsSearchQuery] = useState('');

  const [conversationsSearchParams, setConversationsSearchParams] = useState(
    initialConversationsSearchParams
  );

  const [contactsSearchParams, setContactsSearchParams] = useState(
    initialContactsSearchParams
  );

  const handleUpdateContactsSearchParams = useCallback((newParams) => {
    setContactsSearchParams((prevState) => ({ ...prevState, ...newParams }));
  }, []);

  const handleUpdateConversationsSearchParams = useCallback((newParams) => {
    setConversationsSearchParams((prevState) => ({
      ...prevState,
      ...newParams,
    }));
  }, []);

  const handleChangeTab = useCallback(({ currentTarget }) => {
    const { tab } = currentTarget.dataset;
    setActiveTab(tab);
  }, []);

  // const handleOpenMessageModal = useCallback(() => {
  //   setMessageModalOpen(true);
  // }, []);

  // const handleCloseMessageModal = useCallback(() => {
  //   setMessageModalOpen(false);
  // }, []);

  const handleChangeSearchQuery = useCallback(
    (query) => {
      if (activeTab === messagesTabKeys.contacts) {
        setContactsSearchQuery(query);
      } else {
        setConversationsSearchQuery(query);
      }
      if (query.length < 3 && query.length !== 0) {
        return;
      }
      if (activeTab === messagesTabKeys.contacts) {
        handleUpdateContactsSearchParams({ name: query, offset: 0 });
      } else {
        handleUpdateConversationsSearchParams({ name: query, offset: 0 });
      }
    },
    [
      handleUpdateConversationsSearchParams,
      handleUpdateContactsSearchParams,
      activeTab,
    ]
  );

  const clearSearchInput = useCallback(() => {
    if (activeTab === messagesTabKeys.contacts) {
      setContactsSearchQuery('');
      handleUpdateContactsSearchParams({ name: '', offset: 0 });
    } else {
      setConversationsSearchQuery('');
      handleUpdateConversationsSearchParams({ name: '', offset: 0 });
    }
  }, [
    handleUpdateConversationsSearchParams,
    handleUpdateContactsSearchParams,
    activeTab,
  ]);

  const handleSelectConversation = useCallback(
    ({ currentTarget }) => {
      const convId = currentTarget.dataset.id;

      if (convId === currentConversationId) {
        return;
      }
      dispatch(setCurrentConversationAction(Number(convId)));
    },
    [dispatch, currentConversationId]
  );

  const handleSelectContact = useCallback(
    ({ currentTarget }) => {
      const { id } = currentTarget.dataset;

      setConversationsSearchQuery('');

      handleUpdateConversationsSearchParams({
        name: '',
        interlocutor: Number(id),
        offset: 0,
      });
      setActiveTab(messagesTabKeys.conversations);
    },
    [setActiveTab, handleUpdateConversationsSearchParams]
  );

  const handleViewAllConversations = useCallback(() => {
    setConversationsSearchQuery('');
    handleUpdateConversationsSearchParams(initialConversationsSearchParams);
  }, [handleUpdateConversationsSearchParams]);

  const handleSendMessage = useCallback(
    (messageText) => {
      (async () => {
        try {
          const message = await messagesService.sendMessage(
            currentConversationId,
            messageText
          );

          dispatch(pushMessageByIdAction(currentConversationId, message));
          dispatch(
            setConversationOnTopAction(message.conversationId, message.sentAt)
          );
        } catch (e) {
          errorToastr('Error', e.message);
        }
      })();
      // setMessageModalOpen(false);
    },
    [currentConversationId, dispatch]
  );

  const handleLoadMoreMessages = useCallback(async () => {
    try {
      const cancelToken = createCancelToken();

      const messages = await messagesService.getMessagesByConversationId(
        {
          id: currentConversationId,
          limit: messagesLimit,
          since: messagesByConversationId[currentConversationId][0]?.sentAt,
        },
        { cancelToken }
      );

      const hasMoreMessages = messages.length === messagesLimit;

      dispatch(
        updateMessagesByIdAction({
          conversationId: currentConversationId,
          messages,
          hasMore: hasMoreMessages,
        })
      );
    } catch (e) {
      if (!isCancell(e)) {
        errorToastr('Error', e.message);
      }
    }
  }, [
    currentConversationId,
    messagesByConversationId,
    dispatch,
    createCancelToken,
    isCancell,
  ]);

  const handleUpdateScrollHeight = useCallback(() => {
    if (scrollContainerRef.current?.clientHeight) {
      const refHeight = scrollContainerRef.current.clientHeight;
      setScrollHeight(Math.round(refHeight));
    }
  }, [setScrollHeight, scrollContainerRef]);

  const handleReceivedMessage = useCallback(
    (data) => {
      const { message } = data;
      const transformedMessage = { ...message, userId: message.senderId };
      dispatch(
        pushMessageByIdAction(message.conversationId, transformedMessage)
      );
      dispatch(
        setConversationOnTopAction(message.conversationId, message.sentAt)
      );
    },
    [dispatch]
  );

  useConnectToEchoServer({
    channelName: channelsByName.conversationById(Number(currentConversationId)),
    events: {
      [MESSAGE_SENT]: handleReceivedMessage,
    },
    namespace: 'DNC.Messenger.Laravel.Events.Broadcast',
  });

  useEffect(() => {
    if (currentConversationId) {
      (async () => {
        try {
          const cancelToken = createCancelToken();

          const messages = await messagesService.getMessagesByConversationId(
            {
              id: currentConversationId,
              limit: messagesLimit,
              since: 0,
            },
            { cancelToken }
          );
          const hasMoreMessages = messages.length === messagesLimit;

          dispatch(
            setMessagesByIdAction(
              currentConversationId,
              messages,
              hasMoreMessages
            )
          );
        } catch (e) {
          if (!isCancell(e)) {
            errorToastr('Error', e.message);
          }
        }
      })();
    }
  }, [currentConversationId, dispatch, createCancelToken, isCancell]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentConversationAction(null));
    };
  }, [dispatch]);
  return (
    <>
      <div className={clsx(classes.root, 'h-100 d-flex flex-column')}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <p className={accountClasses.title}>Messages</p>
        </div>
        <div className="flex-fill d-flex">
          <div className={classes.listContainer}>
            <div className="d-flex p-3">
              <IconButton
                className="mr-1"
                variant={
                  activeTab !== messagesTabKeys.conversations
                    ? 'inverted-white'
                    : undefined
                }
                data-tab={messagesTabKeys.conversations}
                onClick={handleChangeTab}
              >
                <SpriteIcon name="speech-bubble" />
              </IconButton>
              <IconButton
                className="mr-1"
                variant={
                  activeTab !== messagesTabKeys.contacts
                    ? 'inverted-white'
                    : undefined
                }
                data-tab={messagesTabKeys.contacts}
                onClick={handleChangeTab}
              >
                <SpriteIcon name="user" size="sm" />
              </IconButton>
              <SearchInput
                inputRef={inputRef}
                inputClasses={{ wrapper: classes.searchInput }}
                value={
                  activeTab === messagesTabKeys.contacts
                    ? contactsSearchQuery
                    : conversationsSearchQuery
                }
                placeholder="Search by keywords (at least 3 characters)"
                onChange={handleChangeSearchQuery}
                onCrossClick={clearSearchInput}
              />
            </div>
            <div className="flex-center justify-content-between mb-3">
              <h3
                className="font-title text-sm text-uppercase px-3 "
                style={{ lineHeight: '33px' }}
              >
                {activeTab}{' '}
                {activeTab === messagesTabKeys.conversations &&
                  conversationsSearchParams.interlocutor && (
                    <span>
                      with{' '}
                      {
                        usersLibrary[conversationsSearchParams.interlocutor]
                          .firstName
                      }{' '}
                      {
                        usersLibrary[conversationsSearchParams.interlocutor]
                          .lastName
                      }
                    </span>
                  )}
              </h3>
              {activeTab === messagesTabKeys.conversations &&
                conversationsSearchParams.interlocutor && (
                  <Button
                    inline
                    size="xs"
                    variant="default"
                    onClick={handleViewAllConversations}
                  >
                    View All
                  </Button>
                )}
            </div>
            <div className="flex-fill mb-3" ref={scrollContainerRef}>
              <CustomScrollBar
                scrollBarRef={scrollBarRef}
                autoHeightMin={scrollHeight}
                autoHeightMax={scrollHeight}
                renderView={(props) => <div {...props} className="pr-1" />}
              >
                {activeTab === messagesTabKeys.conversations ? (
                  <ConversationList
                    scrollHeight={scrollHeight}
                    handleSelectConversation={handleSelectConversation}
                    updateScrollHeight={handleUpdateScrollHeight}
                    searchParams={conversationsSearchParams}
                    conversationSearchName={conversationsSearchQuery}
                    updateSearchParams={handleUpdateConversationsSearchParams}
                  />
                ) : (
                  <ContactList
                    scrollHeight={scrollHeight}
                    updateScrollHeight={handleUpdateScrollHeight}
                    handleSelectContact={handleSelectContact}
                    contactSearchName={contactsSearchQuery}
                    searchParams={contactsSearchParams}
                    updateSearchParams={handleUpdateContactsSearchParams}
                  />
                )}
              </CustomScrollBar>
            </div>
            {/* <button
              type="button"
              className={classes.sendMessageBtn}
              onClick={handleOpenMessageModal}
            >
              Write New Message
            </button> */}
          </div>
          <div className="flex-fill">
            <ConversationArea
              messages={messagesByConversationId[currentConversationId] ?? []}
              sendMessage={handleSendMessage}
              isConversationChoosen={Boolean(currentConversationId)}
              subjectTitle={
                conversationsById[currentConversationId]?.name ?? ''
              }
              hasMore={
                metaByConversationId[currentConversationId]?.hasMore ?? false
              }
              loadMore={handleLoadMoreMessages}
              activeTab={activeTab}
            />
          </div>
        </div>
      </div>
      {/* <SendMessageModal
        open={messageModalOpen}
        onClose={handleCloseMessageModal}
        onSubmit={handleSendMessage}
      /> */}
    </>
  );
};
export default MessagesContainer;
