import React from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import classes from './MoreOptionsPopover.module.scss';

const MoreOptionsPopover = ({ anchorEl, open, onClose, children }) => {
  const theme = createMuiTheme({});
  theme.props = {
    MuiList: {
      onMouseLeave: (e) => {
        onClose(e);
      },
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        id="moreOptionPopover"
        getContentAnchorEl={null}
        classes={{ list: classes.root, paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem style={{ background: 'transparent' }}>{children}</MenuItem>
      </Menu>
    </ThemeProvider>
  );
};

MoreOptionsPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([PropTypes.object]),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

MoreOptionsPopover.defaultProps = {
  anchorEl: null,
};

export default MoreOptionsPopover;
