import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import useCancelToken from 'hooks/useCancelToken';
import errorToastr from 'libs/toastr/errorToastr';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { routesByName } from 'constants/routes';
import {
  imagePageTypes,
  imageSourceKeys,
  imageTypeKeys,
} from 'constants/inspirationImageSearchParams';
import {
  loadFirstAction,
  loadMoreAction,
  updateSearchParamsAction,
  updateSelectedImageAction,
} from './store/actions';
import ImageItem from '../curateTheLook/createLookBoard/components/ImageItem/ImageItem';
import classes from './GetTheLook.module.scss';
import CustomScrollBar from '../../components/ui/CustomScrollbar/CustomScrollBar';
import MixMatchThumbnail from '../../components/MixMatchThumbnail/MixMatchThumbnail';
import LookBoardItem from './components/LookBoardItem';
import { subTypeImageOptions } from '../requestTheLook/constants';
import FilterInput from '../dashboard/components/FilterInput/FilterInput';

const MixMatch = ({ isLookBoards }) => {
  const dispatch = useDispatch();
  const mainContainerRef = useRef(null);

  const user = useSelector((state) => state.auth.user);
  const {
    imageList: imageIDs,
    searchParams,
    loading,
    hasMore,
    filterValues,
  } = useSelector((state) => state.getTheLook);
  const library = useSelector((state) => state.inspirationImage.library);

  const history = useHistory();
  const scrollBarRef = useRef(null);
  const intersectingRef = useRef(null);
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  };

  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [lookBoardsList, setLookBoardsList] = useState([]);
  const [activeTypeTab, setActiveTypeTab] = useState(imageSourceKeys.all);
  const [subTypeImage, setSubTypeImage] = useState(imageSourceKeys.all);
  const [scrollHeight, setScrollHeight] = useState(0);

  const imageList = useMemo(
    () =>
      imageIDs
        .filter((id) => library[id] !== undefined)
        .map((id) => library[id]),
    [library, imageIDs]
  );

  const [createCancelToken, isCancelled] = useCancelToken();
  const mockMatches = [
    { id: 0, url: '' },
    { id: 1, url: '' },
    { id: 2, url: '' },
    { id: 3, url: '' },
  ];

  const handleImgModalOpen = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleUpdateImage = useCallback(
    (img) => {
      dispatch(updateSelectedImageAction(library[img]));
      history.push(routesByName.getTheLook.mixMatch.details(library[img].id));
    },
    [history, library, dispatch]
  );

  const handleIntersection = useCallback(
    (entries) => {
      const [entry] = entries;
      if (!hasMore || loading || !entry.isIntersecting) {
        return;
      }
      dispatch(loadMoreAction());
    },
    [hasMore, dispatch, loading]
  );

  const getListOfLookBoards = () => {
    const lookBoardsDataArr = Object.keys(library).map(
      (key) => library[key].firstLookBoard
    );
    setLookBoardsList(lookBoardsDataArr);
  };

  useEffect(() => {
    if (isLookBoards && Object.keys(library).length) {
      // getAllLookBoardsData(imageIDs);
      getListOfLookBoards();
    }
    // eslint-disable-next-line
  }, [imageIDs]);

  useEffect(() => {
    if (user) {
      dispatch(
        updateSearchParamsAction({
          ...searchParams,
          source: imageSourceKeys.all,
          offset: 0,
          lbCount: 2,
          pageType: imagePageTypes.mixMatch,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0 && searchParams.lbCount !== null) {
        try {
          await dispatch(loadFirstAction(createCancelToken));
        } catch (e) {
          if (!isCancelled(e)) {
            errorToastr('Error', e.message);
          }
        }
      }
    })();
    // eslint-disable-next-line
  }, [
    searchParams.offset,
    searchParams.source,
    searchParams.lbCount,
    filterValues,
  ]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, options);
    const currentRef = intersectingRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [handleIntersection, intersectingRef, options]);

  useEffect(() => {
    if (mainContainerRef.current) {
      const refHeight = mainContainerRef.current.clientHeight;
      setScrollHeight(refHeight + 50);
    }
  }, []);

  const moveToDetails = useCallback(
    (id) => {
      history.push(routesByName.getTheLook.mixMatch.lookBoards.details(id));
    },
    [history]
  );

  const isTabActive = useCallback(
    (tab) => {
      return activeTypeTab === tab;
    },
    [activeTypeTab]
  );

  const handleChangeSubType = useCallback(
    (subType) => {
      setActiveTypeTab(imageSourceKeys.all);
      setSubTypeImage(subType);
      dispatch(
        updateSearchParamsAction({
          source: subType,
          type: imageTypeKeys.room,
          search: '',
          offset: 0,
        })
      );
    },
    [dispatch]
  );

  const handleChangeActiveTab = async (tab) => {
    setActiveTypeTab(tab);
    dispatch(
      updateSearchParamsAction({
        source: tab,
        type: imageTypeKeys.room,
        search: '',
        offset: 0,
      })
    );
  };

  return (
    <div className="pt-4">
      <div className="d-flex">
        <div className={classes.leftPanelWrapper}>
          {!isLookBoards && user && (
            <div className={classes.myAndLikedImages}>
              <FilterInput
                className={clsx(classes.sortTypeSelect, {
                  [classes.active]: isTabActive(imageSourceKeys.all),
                })}
                options={subTypeImageOptions}
                currentValue={subTypeImage}
                onChange={handleChangeSubType}
              />
              <div
                className={clsx(
                  'd-flex flex-column align-items-center',
                  classes.tabBtnWrapper
                )}
              >
                <button
                  type="button"
                  className={clsx(classes.tabBtn, {
                    [classes.active]: isTabActive(imageSourceKeys.my),
                  })}
                  data-tab={imageSourceKeys.my}
                  onClick={() => handleChangeActiveTab(imageSourceKeys.my)}
                >
                  My Added Images
                </button>
              </div>
            </div>
          )}
          <CustomScrollBar
            scrollBarRef={scrollBarRef}
            autoHeightMin={scrollHeight}
            autoHeightMax={scrollHeight}
            renderView={(props) => <div {...props} className="pr-2" />}
          >
            {isLookBoards && (
              <div className="position-relative">
                {Object.keys(library).length
                  ? lookBoardsList.map((lookBoard) => {
                      return (
                        <div className="mb-2">
                          <LookBoardItem
                            lookBoard={lookBoard}
                            user={lookBoard.user}
                            userId={lookBoard.userId}
                            products={lookBoard.products}
                            hideLikeVote
                            onToggleLike={() => {}}
                            onVote={() => {}}
                            onClick={() => moveToDetails(lookBoard.id)}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            )}
            {!isLookBoards && (
              <div className="position-relative">
                {imageList.map(
                  ({
                    id,
                    url,
                    isLiked,
                    shareUrl,
                    media: { userId, hash },
                    title,
                    image_source_name: imageSourceName,
                    source_url: sourceUrl,
                    lookBoardsCount,
                    approval,
                    publish,
                  }) => (
                    <ImageItem
                      key={id}
                      id={id}
                      url={url}
                      userId={userId}
                      hash={hash}
                      isLiked={isLiked}
                      shareUrl={shareUrl}
                      onOpenPreview={handleImgModalOpen}
                      onSelectImage={handleUpdateImage}
                      imageSource={imageSourceName}
                      title={title}
                      sourceUrl={sourceUrl}
                      lookBoardsCount={lookBoardsCount}
                      approval={approval}
                      publish={publish}
                    />
                  )
                )}
                <div
                  ref={intersectingRef}
                  className="position-absolute"
                  style={{ height: 20, bottom: 100 }}
                />
              </div>
            )}
          </CustomScrollBar>
        </div>
        <div
          className={clsx(classes.mainContainer)}
          style={{ paddingTop: 80 }}
          ref={mainContainerRef}
        >
          <div className="row mb-3">
            {new Array(6).fill(null).map(() => (
              <div className="col-4 mb-2" key={Math.random()}>
                <MixMatchThumbnail title="" imgUrls={mockMatches} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
    </div>
  );
};

MixMatch.propTypes = {
  isLookBoards: PropTypes.bool,
};

MixMatch.defaultProps = {
  isLookBoards: false,
};

export default MixMatch;
