import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import TextInputWrapper from 'components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from 'components/finalFormWrappers/FinalFormSubmitButton';
import { supportFormFields } from 'modules/support/constants';
import supportFormValidator from 'modules/support/supportForm/supportFormValidator';

const SupportForm = ({ onSubmit, userEmail, userName }) => (
  <Form
    validate={supportFormValidator}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} noValidate>
        <Field
          required
          name={supportFormFields.name}
          initialValue={userName || ''}
          label="Name"
          component={TextInputWrapper}
        />
        <Field
          required
          initialValue={userEmail || ''}
          label="Email"
          type="email"
          name={supportFormFields.email}
          component={TextInputWrapper}
        />
        <Field
          required
          label="Subject"
          name={supportFormFields.subject}
          className="mb-5"
          component={TextInputWrapper}
        />
        <Field
          required
          name={supportFormFields.message}
          label="Message"
          multiline
          rows={4}
          component={TextInputWrapper}
        />
        <div className="d-flex justify-content-center">
          <div className="text-center">
            <FinalFormSubmitButton inline>Submit</FinalFormSubmitButton>
          </div>
        </div>
      </form>
    )}
  />
);

SupportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  userEmail: PropTypes.string,
  userName: PropTypes.string,
};

SupportForm.defaultProps = {
  initialValues: {
    name: null,
    email: null,
  },
  userName: null,
  userEmail: null,
};

export default SupportForm;
