import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory, useParams } from 'react-router-dom';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getProductImgUrl } from 'modules/app/store/selectors';
import { routesByName } from 'constants/routes';
import classes from './ItemThumbnail.module.scss';
import SpriteIcon from '../../ui/SpriteIcon';
import IconButton from '../../ui/IconButton/IconButton';
import Button from '../../ui/Button/Button';

const ItemThumbnail = ({
  id,
  src: { userId, hash },
  name,
  price,
  url,
  withHover,
  height,
  className,
  productImgUrl,
  isPublished,
  isMainLookBoard,
}) => {
  const history = useHistory();
  const { lookBoardId } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [hover, setHover] = useState(false);
  const [hovered, setHovered] = useState(false);

  const skeletonSize = useMemo(() => {
    return height - 16; // 16 = 2 * default spacing
  }, [height]);

  const imgUrl = useMemo(
    () => buildResourceUrl(productImgUrl.medium, userId, hash),
    [userId, hash, productImgUrl]
  );

  const handleImageLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  const handleHover = useCallback(() => {
    if (withHover) {
      setHover(true);
      setHovered(true);
    }
  }, [withHover]);

  const handleBlur = useCallback(() => {
    setHover(false);
  }, []);

  const goToDetails = useCallback(() => {
    if (isPublished && isMainLookBoard) {
      history.push(routesByName.product.details(id), { lookBoardId });
    } else if (isPublished) {
      history.push(routesByName.product.details(id));
    }
  }, [history, id, isMainLookBoard, isPublished, lookBoardId]);

  const openNewTabDetails = useCallback(
    (e) => {
      e.stopPropagation();
      if (isPublished) {
        history.push(routesByName.product.details(id), { lookBoardId });
      } else if (url) {
        window.open(url, '_blank');
      }
    },
    [history, id, isPublished, lookBoardId, url]
  );

  return (
    <div
      className={clsx(classes.root, className)}
      style={{ height }}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}
    >
      <img
        src={imgUrl}
        alt=""
        className={classes.image}
        style={loaded ? {} : { display: 'none' }}
        onLoad={handleImageLoad}
      />
      {withHover && (
        <div
          className={clsx(classes.hoverProduct, {
            [classes.active]: hover,
            [classes.hovered]: hovered,
          })}
          onClick={goToDetails}
        >
          <img src={imgUrl} alt="" className={classes.image} />
          {isPublished ? (
            <div className="p-1 d-flex">
              <div className="flex-fill text-ellipsis mr-1">
                <p className="text-ellipsis text-nowrap text-sm text-gray font-semi-bold">
                  {name}
                </p>
                <span className="primary-color text-sm font-semi-bold">{`$${price}`}</span>
              </div>
              <Button
                variant="contained"
                inline
                size="sm"
                onClick={openNewTabDetails}
              >
                <span className="px-2">VIEW</span>
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center p-2 secondary-color">
              <IconButton
                color="secondary"
                variant="inverted-white"
                onClick={openNewTabDetails}
              >
                <SpriteIcon name="external-link" size="lg" />
              </IconButton>
            </div>
          )}
        </div>
      )}
      {!loaded && (
        <Skeleton
          animation="wave"
          variant="rect"
          width={skeletonSize}
          height={skeletonSize}
        />
      )}
    </div>
  );
};

ItemThumbnail.propTypes = {
  id: PropTypes.number.isRequired,
  src: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    hash: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string,
  price: PropTypes.number,
  withHover: PropTypes.bool,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
  productImgUrl: PropTypes.shape({
    medium: PropTypes.string.isRequired,
  }).isRequired,
  isPublished: PropTypes.bool,
  isMainLookBoard: PropTypes.bool,
  url: PropTypes.string,
};

ItemThumbnail.defaultProps = {
  className: '',
  name: undefined,
  price: undefined,
  withHover: false,
  isPublished: true,
  url: null,
  isMainLookBoard: false,
};

const mapStateToProps = (
  {
    app: {
      config,
      enums: { itemClasses },
    },
  },
  { itemClassId }
) => ({
  productImgUrl: getProductImgUrl(config),
  itemClass: itemClasses[itemClassId]?.name,
});

export default connect(mapStateToProps)(ItemThumbnail);
