import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classes from './InspirationImagesModal.module.scss';
import BasicModal from '../../../../components/modals/BasicModal/BasicModal';
import buildResourceUrl from '../../../../utils/buildResourceUrl';

const InspirationImagesModal = ({ visible, onClose, imageList }) => {
  const inspirationImageUrl = useSelector(
    (state) => state.app.config.endpoints.media.inspirationImage
  );
  const getImgUrl = (userId, hash) => {
    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  };
  return (
    <BasicModal
      open={visible}
      onClose={onClose}
      fullWidth
      classes={{ root: classes.modalRoot }}
    >
      <div className={classes.modalContent}>
        <h3 className={classes.modalTitle}>Inspirations</h3>
        <div className={classes.imageList}>
          {imageList
            ?.slice(0, 5)
            .map(
              ({
                media: { userId, hash },
                title,
                image_source_name: imageSource,
              }) => {
                return (
                  <div className={classes.imageWrapper} key={hash}>
                    <img
                      alt=""
                      draggable="false"
                      src={getImgUrl(userId, hash)}
                      className={classes.mainImage}
                    />
                    <div className={classes.imageInfo}>
                      <p className={classes.imageTitle}>{title}</p>
                      <p className={classes.imageSubTitle}>{imageSource}</p>
                    </div>
                  </div>
                );
              }
            )}
        </div>
      </div>
    </BasicModal>
  );
};

InspirationImagesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  imageList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default InspirationImagesModal;
