import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectImageAction as selectImageCTL,
  updateCurateStateAction,
  updateLookBoardDataAction,
  resetCanvasAction,
} from 'modules/curateTheLook/store/actions';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import requestService from 'modules/request/requestService';
import inspirationImageService from 'modules/inspirationImage/inspirationImageService';
import { routesByName } from 'constants/routes';
import { Link, useParams, useHistory } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import ConfirmModal from 'components/modals/ConfirmModal';
import AmbassadorMatchComponent from 'modules/requestTheLook/ambassadorMatch/AmbassadorMatchComponent';
import { messageFieldKeys } from 'components/modals/SendMessageModal/constants';
import currentUserService from 'modules/currentUser/currentUserService';
import SendMessageModal from 'components/modals/SendMessageModal/SendMessageModal';
import classes from './AmbassadorMatch.module.scss';

const AmbassadorMatchContainer = () => {
  const dispatch = useDispatch();
  const mainUser = useSelector((state) => state.auth.user);
  const selectedImage = useSelector(
    (state) => state.curateTheLook.lookBoardData.inspirationImageId
  );

  const history = useHistory();
  const [request, setRequest] = useState(null);
  const [requester, setRequester] = useState(null);
  const { requestId } = useParams();
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [isUserHaveAccess, setIsUserHaveAccess] = useState(true);

  const initialFormValues = useMemo(
    () => ({
      [messageFieldKeys.name]: requester
        ? `${requester.firstName} ${requester.lastName}`
        : '',
      [messageFieldKeys.userId]: requester?.id ?? null,
    }),
    [requester]
  );

  const handleCloseMessageModal = useCallback(() => {
    setMessageModalOpen(false);
  }, []);

  const handleOpenMessageModal = useCallback(() => {
    setMessageModalOpen(true);
  }, []);

  const checkingForAccess = (usersWithAccess, currentUser) => {
    let result = false;
    for (let i = 0; i < usersWithAccess.length; i += 1) {
      if (usersWithAccess[i] === currentUser.id) {
        result = true;
        break;
      }
      result = false;
    }
    return result;
  };

  useEffect(() => {
    async function fetchRequest() {
      try {
        const data = await requestService.getRequestById(requestId);

        const isHaveAccess = checkingForAccess(data.requestedUsers, mainUser);
        setIsUserHaveAccess(isHaveAccess);

        const { result: image } = await inspirationImageService.getImageById(
          data.imageId
        );
        dispatch(updateIILibraryAction({ [image.id]: image }));
        setRequest({
          ...data,
        });

        const user = await currentUserService.getUserById(data.userId);
        setRequester(user);
      } catch (e) {
        errorToastr('Error', e.generalError);
        history.push(routesByName.home);
      }
    }
    fetchRequest();
    // eslint-disable-next-line
  }, [requestId]);

  const handleOpenDeclineModal = useCallback(() => {
    setDeclineModalOpen(true);
  }, []);

  const handleDeclineRequest = useCallback(
    async (confirm) => {
      if (confirm) {
        try {
          await requestService.deletePendingRequest(requestId);
          successToastr('Info', 'The Request Have Been Declined');
          history.push(routesByName.dashboard.requests);
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      }
      setDeclineModalOpen(false);
    },
    [history, requestId]
  );

  const handleOnCurate = useCallback(() => {
    if (request.imageId !== selectedImage) {
      dispatch(resetCanvasAction());
    }
    dispatch(selectImageCTL(request.imageId));
    dispatch(updateLookBoardDataAction({ requestId: request.id }));
    dispatch(
      updateCurateStateAction({
        requestInfo: {
          title: request.title,
          priorityItems: request.priorityItems,
          message: request.message,
          requesterId: requester.id,
        },
      })
    );
    history.push(routesByName.curateTheLook.index);
  }, [history, request, dispatch, requester, selectedImage]);

  return request ? (
    <>
      {isUserHaveAccess ? (
        <section className={classes.root}>
          <div className="mb-5">
            <Link to={routesByName.dashboard.requests}>
              <div className="d-inline-flex align-items-center">
                <IconButton variant="inverted-white" size="sm">
                  <SpriteIcon name="left-arrow" size="sm" />
                </IconButton>
                <span className="primary-color text-sm ml-1">Back</span>
              </div>
            </Link>
          </div>
          <AmbassadorMatchComponent
            request={request}
            onCurate={handleOnCurate}
            onDecline={handleOpenDeclineModal}
            onMessage={handleOpenMessageModal}
          />
          <ConfirmModal
            open={declineModalOpen}
            onClose={handleDeclineRequest}
            title={
              <>
                Are you sure you want to <br />
                <span className="primary-color">to decline</span> request?
              </>
            }
          />
          <SendMessageModal
            open={messageModalOpen}
            onClose={handleCloseMessageModal}
            initialValues={initialFormValues}
          />
        </section>
      ) : (
        <div className="text-center p-5">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You don't have enough permission to view this page
        </div>
      )}
    </>
  ) : (
    <div className="text-center p-5">Loading...</div>
  );
};

export default AmbassadorMatchContainer;
