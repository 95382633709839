import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import SquareModal from 'components/modals/SquareModal/SquareModal';
import DiscoverContent from 'modules/home/components/DiscoverContent/DiscoverContent';
import PresentationCard from 'modules/home/components/PresentationCard/PresentationCard';
import requestModalImg from 'assets/img/home-page/detail-modal/request-modal-img.png';
import curateSectionImg from 'assets/img/home-page/how-it-works/curate.png';
import classes from './DetailModal.module.scss';

const DetailModal = ({ open, onClose }) => {
  const settings = useMemo(
    () => ({
      title: 'Get the Look Your Way',
      description: 'Getting the Look you Love is now easier than ever!',
    }),
    []
  );

  return (
    <SquareModal open={open} onClose={onClose} scroll="body" {...settings}>
      <h2 className={classes.title}>Get the Look</h2>
      <DiscoverContent />
      <div className="mt-5">
        <h2 className={classes.title}>Request & Curate the Look</h2>
        <div className={classes.content}>
          <div className="d-flex mb-3">
            <div className={classes.cardWrapper}>
              <PresentationCard
                title="Request the Look"
                description="Get Look Board ideas for your favorite Inspiration images"
              >
                <div className={classes.curateImg}>
                  <img src={requestModalImg} alt="" />
                </div>
              </PresentationCard>
              <div className="mt-3 d-flex justify-content-center">
                <Link to={routesByName.requestTheLook.index}>
                  <Button size="lg">Request the Look</Button>
                </Link>
              </div>
            </div>
            <div className={classes.cardWrapper}>
              <PresentationCard
                title="Curate the Look"
                description="Use our easy drag & drop design tools to Curate the Look of your favorite room images"
              >
                <div className={classes.curateImg}>
                  <img src={curateSectionImg} alt="" />
                </div>
              </PresentationCard>
              <div className="mt-3 d-flex justify-content-center">
                <Link to={routesByName.curateTheLook.index}>
                  <Button size="lg">Curate the Look</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SquareModal>
  );
};

DetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DetailModal;
