import currentUserService from 'modules/currentUser/currentUserService';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import transformArrayToMap from 'utils/transformArrayToMap';
import {
  AFFILIATE_SITES,
  GTL_SET_MORE_USERS,
  GTL_SET_SELECTED_USER,
  GTL_SET_USERS,
  GTL_SET_USERS_LOADING,
  GTL_SET_USERS_LOOKBOARDS,
  GTL_SET_USERS_SEARCH_PARAMS,
  GTL_TOGGLE_FOLLOWED_USER,
  GTL_TOGGLE_USER_LOOKBOARD_LIKE,
  GTL_UPDATE_HAS_MORE_STATUS_USERS,
  GTL_VOTE_USER_LOOKBOARD,
} from './constants';
import { professionOptions } from '../../ambassadorPage/config';
import { AUTH_LOGIN } from '../../../auth/store/constants';

export const loadFirstAction = () => async (dispatch, getState) => {
  dispatch({
    type: GTL_SET_USERS_LOADING,
    payload: true,
  });
  const {
    userPage: { usersSearchParams },
    ambassadorsPage: { ambassadorsFilterValues, limit },
  } = getState();
  try {
    const profession =
      ambassadorsFilterValues.profession.map(
        (id) => professionOptions.find((item) => item.id === id).value
      ) || '';

    const users = await currentUserService.searchRegularUsers({
      ...usersSearchParams,
      ...ambassadorsFilterValues,
      profession,
    });
    dispatch({
      type: GTL_SET_USERS,
      payload: Object.values(users).map((user) => ({
        ...user,
        isFollowed: Boolean(user.isFollowed),
      })),
    });
    const lookBoardIds = Object.values(users).map((user) => user.lookBoards);

    // eslint-disable-next-line no-restricted-syntax
    for await (const idArray of lookBoardIds) {
      const res = await lookBoardService.getLookBoardsById(
        idArray.slice(0, 100)
      );
      dispatch({
        type: GTL_SET_USERS_LOOKBOARDS,
        payload: transformArrayToMap(res),
      });
    }

    if (users.length === limit) {
      dispatch({
        type: GTL_SET_USERS_SEARCH_PARAMS,
        payload: {
          ...usersSearchParams,
          offset: usersSearchParams.offset + 10,
        },
      });
    }
    dispatch({
      type: GTL_UPDATE_HAS_MORE_STATUS_USERS,
      payload: users.length === limit,
    });
  } finally {
    dispatch({
      type: GTL_SET_USERS_LOADING,
      payload: false,
    });
  }
};

export const loadMoreAction = () => async (dispatch, getState) => {
  dispatch({
    type: GTL_SET_USERS_LOADING,
    payload: true,
  });
  const {
    userPage: { usersSearchParams },
    ambassadorsPage: { ambassadorsFilterValues, limit },
  } = getState();
  try {
    const profession =
      ambassadorsFilterValues.profession.map(
        (id) => professionOptions.find((item) => item.id === id).value
      ) || '';
    const users = await currentUserService.searchRegularUsers({
      ...usersSearchParams,
      ...ambassadorsFilterValues,
      profession,
    });
    dispatch({
      type: GTL_SET_MORE_USERS,
      payload: Object.values(users).map((user) => ({
        ...user,
        isFollowed: Boolean(user.isFollowed),
      })),
    });
    const lookBoardIds = Object.values(users).map((user) => user.lookBoards);

    // eslint-disable-next-line no-restricted-syntax
    for await (const idArray of lookBoardIds) {
      const res = await lookBoardService.getLookBoardsById(idArray);
      dispatch({
        type: GTL_SET_USERS_LOOKBOARDS,
        payload: transformArrayToMap(res),
      });
    }

    if (users.length === limit) {
      dispatch({
        type: GTL_SET_USERS_SEARCH_PARAMS,
        payload: {
          ...usersSearchParams,
          offset: usersSearchParams.offset + 10,
        },
      });
    }
    dispatch({
      type: GTL_UPDATE_HAS_MORE_STATUS_USERS,
      payload: users.length === limit,
    });
  } finally {
    dispatch({
      type: GTL_SET_USERS_LOADING,
      payload: false,
    });
  }
};

export const updateUserSearchAction = (payload) => ({
  type: GTL_SET_USERS_SEARCH_PARAMS,
  payload,
});

export const toggleUserFollowedAction = (payload) => ({
  type: GTL_TOGGLE_FOLLOWED_USER,
  payload,
});

export const setSelectedUserAction = (payload) => ({
  type: GTL_SET_SELECTED_USER,
  payload,
});

export const findUserAction = (userId) => async (dispatch) => {
  const user = await currentUserService.getUserById(userId);
  // TODO: remove search users, get lookboard ids from user above
  const users = await currentUserService.searchRegularUsers({
    colors: [],
    offset: 0,
    profession: '',
    roomTypes: [],
    search: `${user.firstName} ${user.lastName}`,
    styles: [],
    subStyles: {},
  });

  const foundUser = users.find((us) => us.id === Number(user.id));

  dispatch(setSelectedUserAction(foundUser));

  const res = await lookBoardService.getLookBoardsById(
    foundUser.lookBoards.slice(0, 99)
  );
  dispatch({
    type: GTL_SET_USERS_LOOKBOARDS,
    payload: transformArrayToMap(res),
  });
};

export const toggleLikeAction = (lookBoardId, likeStatus) => async (
  dispatch
) => {
  await lookBoardService.toggleLike(lookBoardId, likeStatus);
  dispatch({
    type: GTL_TOGGLE_USER_LOOKBOARD_LIKE,
    payload: {
      id: lookBoardId,
      status: likeStatus,
    },
  });
};
export const voteAction = (lookBoardId, voteValue) => async (dispatch) => {
  await lookBoardService.voteHandler(lookBoardId, voteValue);
  dispatch({
    type: GTL_VOTE_USER_LOOKBOARD,
    payload: {
      id: lookBoardId,
      status: voteValue,
    },
  });
};
export const getAffiliateSitesAction = (type) => async (dispatch) => {
  const affiliateSites = await currentUserService.getAffiliateSitesList(type);
  dispatch({ type: AFFILIATE_SITES, payload: affiliateSites });
};
export const addAffiliateCodeAction = (data) => async (dispatch) => {
  const updatedUser = await currentUserService.addAffiliateCode(data);
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};
export const editAffiliateCodeAction = (data, id) => async (dispatch) => {
  const updatedUser = await currentUserService.editAffiliateCode(data, id);
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};
export const deleteAffiliateCodeAction = (id) => async (dispatch) => {
  const updatedUser = await currentUserService.deleteAffiliateCode(id);
  dispatch({ type: AUTH_LOGIN, payload: updatedUser });
};
