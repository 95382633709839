import React, { useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from './CompareSectionSliders.module.scss';

const CompareSliderContent = ({ products }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleSlideChange = useCallback((index) => {
    setActiveSlideIndex(index);
  }, []);

  const sliderRef = useRef(null);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleDotClick = useCallback(({ currentTarget }) => {
    const currentSlideIndex = currentTarget.getAttribute('data-index');
    sliderRef.current.slickGoTo(currentSlideIndex);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className={classes.compareSliderContent}>
        <button
          type="button"
          className={classes.compareSliderBtn}
          onClick={handleSlidePrev}
        >
          <SpriteIcon name="prev-icon" size="xs" />
        </button>
        <Slider
          ref={sliderRef}
          className={classes.compareSlider}
          afterChange={handleSlideChange}
          {...settings}
        >
          {products.map(({ id, imgUrl }) => {
            return (
              <div key={id} className={classes.compareSlide}>
                <img className={classes.compareSlideImg} src={imgUrl} alt="" />
              </div>
            );
          })}
        </Slider>

        <button
          type="button"
          className={classes.compareSliderBtn}
          onClick={handleSlideNext}
        >
          <SpriteIcon name="next-icon" size="xs" />
        </button>
      </div>
      <div className="flex-center">
        {products.map(({ id }, index) => (
          <div
            key={id}
            className={clsx(classes.dot, {
              [classes.active]: index === activeSlideIndex,
            })}
            data-index={index}
            onClick={handleDotClick}
          />
        ))}
      </div>
    </>
  );
};

CompareSliderContent.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default CompareSliderContent;
