import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance';

const urlPrefix = '/v1';

const currentUserService = {
  getCurrentUser() {
    return AuthorizedAxiosInstance.get(`${urlPrefix}/user`).then(
      ({ result: user }) => user
    );
  },

  getUserActivities() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/activities`).then(
      ({ result: activities }) => activities
    );
  },

  getRewards() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/rewards`).then(
      ({ result: rewards }) => rewards
    );
  },

  getUserStats() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/stats`).then(
      ({ result: stats }) => ({
        remainingPoints: stats.user_point,
        pointsLeft: stats.point_left,
        pointsRedeemed: stats.total_redeem_point_sum,
        moneyRedeemed: stats.total_redeem_money_sum,
      })
    );
  },

  redeemPoints(body) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/user/redeem-point`,
      body
    ).then(({ result: rewards }) => rewards);
  },

  getUserById(userId, config = null) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/user/${userId}`,
      config
    ).then(({ result: user }) => user);
  },

  getUsersById(usersIdArray, config) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/users`,
      {
        users: usersIdArray,
      },
      config
    ).then(({ result }) => result);
  },

  updateProfile(profileData) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user`, profileData).then(
      ({ result: user }) => user
    );
  },

  confirmDetails(profileData) {
    return AuthorizedAxiosInstance.put(
      `${urlPrefix}/user/confirm`,
      profileData
    ).then(({ result: user }) => user);
  },

  updateProfession(profession) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/profession`, {
      profession,
    });
  },

  updateVibes(vibes) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/vibes`, { vibes });
  },
  updateStyles(styles) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/styles`, { styles });
  },

  updateSubStyles(subStyles) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/sub-styles`, {
      subStyles,
    });
  },

  updateRoomTypes(roomTypes) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/room-types`, {
      roomTypes,
    });
  },

  updateColors(colors) {
    return AuthorizedAxiosInstance.put(`${urlPrefix}/user/colors`, {
      colors,
    });
  },

  // updatePriceRange(priceRange) {
  //   return AuthorizedAxiosInstance.put(`${urlPrefix}/user/price-range`, {
  //     priceRangeId: priceRange,
  //   });
  // },

  updateAvatar(mediaId) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/update-avatar`, {
      mediaId,
    });
  },

  deactivateUser() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/deactivate`);
  },

  getRequests(params, cancelToken = null) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/requests`, params, {
      cancelToken,
    }).then(({ result }) => result);
  },

  changeEmail(emailData) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/user/update-email`,
      emailData
    );
  },

  sendVerificationLink() {
    const currentUrl = window.location.href;
    const domainName = new URL(currentUrl).hostname;

    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/user/send-verify-email-link`,
      {
        domain: domainName,
      }
    );
  },

  sendTradeOpportunities(data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/trade-opportunities`,
      data
    );
  },

  finishPreferences() {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/user/finish-preferences`);
  },

  checkFollowing(userId) {
    return AuthorizedAxiosInstance.get(
      `${urlPrefix}/followers/follow/${userId}/check`
    ).then(({ result }) => Boolean(result));
  },

  searchRegularUsers(params) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/users/regular`,
      params
    ).then(({ result }) => result);
  },

  getAffiliateSitesList(type) {
    return AuthorizedAxiosInstance.post(`${urlPrefix}/affiliate-sites`, {
      type: type ?? null,
    }).then(({ result: affiliateSites }) => affiliateSites);
  },

  addAffiliateCode(data) {
    return AuthorizedAxiosInstance.post(
      `${urlPrefix}/affiliate-code`,
      data
    ).then(({ result: user }) => user);
  },

  editAffiliateCode(data, id) {
    return AuthorizedAxiosInstance.put(
      `${urlPrefix}/affiliate-code/${id}`,
      data
    ).then(({ result: user }) => user);
  },

  deleteAffiliateCode(id) {
    return AuthorizedAxiosInstance.delete(
      `${urlPrefix}/affiliate-code/delete/${id}`
    ).then(({ result: user }) => user);
  },
};
export default currentUserService;
