import React from 'react';
import loverImg from 'assets/img/ambassador-page/points-and-badges/lover.png';
import startImg from 'assets/img/ambassador-page/points-and-badges/star.png';
import diveImg from 'assets/img/ambassador-page/points-and-badges/diva.png';

export const pointsConfig = [
  {
    id: 1,
    title: 'Activate Showcase Page',
    points: 20,
    description: (
      <>
        Requires adding 5+ Approved Inspiration Images and <br /> 5+ Approved
        Look Boards.
      </>
    ),
  },
  {
    id: 2,
    title: 'Add Room Image',
    points: 1,
    description: null,
  },
  {
    id: 3,
    title: 'Curate Look Board',
    points: 2,
    description: null,
  },
  {
    id: 4,
    title: 'Fulfill User Request',
    points: 3,
    description: null,
  },
  {
    id: 5,
    title: 'Nominate Active Ambassador',
    points: 10,
    description: null,
  },
  {
    id: 6,
    title: 'Share Your Look Boards',
    points: 1,
    description: null,
  },
  {
    id: 7,
    title: 'Vote on Look Boards',
    points: '+1',
    description: (
      <>
        *Points are earned for approved images and look boards, and nominated
        ambassadors who have an activated showcase page.
      </>
    ),
  },
];

export const badgesConfig = [
  {
    id: 1,
    image: loverImg,
    title: 'Decor Lover',
    points: 100,
  },
  {
    id: 2,
    image: startImg,
    title: 'Decor Star',
    points: 250,
  },
  {
    id: 3,
    image: diveImg,
    title: 'Decor Diva',
    points: 500,
  },
];
