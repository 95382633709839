import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { urlPlaceholders } from 'constants/urlPlaceholders';
import stylesOptionShape from 'components/PreferenceQuiz/propTypes/stylesOptionShape';
import {
  getVibeImgPreviewUrl,
  getVibeImgUrl,
} from 'modules/app/store/selectors';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from 'components/PreferenceQuiz/VibesCard/VibesCard.module.scss';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const VibesCard = ({
  option: {
    id,
    name,
    media: { hash },
  },
  selected,
  onChange,
  className,
  onOpenPreviewModal,
}) => {
  const config = useSelector((state) => state.app.config);
  const vibeImgUrl = useMemo(() => getVibeImgUrl(config), [config]);
  const vibeImgPreviewUrl = useMemo(() => getVibeImgPreviewUrl(config), [
    config,
  ]);

  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const handleSelect = useCallback(() => {
    onChange(id, !selected);
  }, [onChange, id, selected]);

  const handleOpenPreview = useCallback(() => {
    const previewUrl = vibeImgPreviewUrl.replace(urlPlaceholders.hash, hash);
    onOpenPreviewModal(previewUrl);
  }, [onOpenPreviewModal, vibeImgPreviewUrl, hash]);

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.selected]: selected,
      })}
    >
      <div className={classes.imgContainer} onClick={handleSelect}>
        <img src={vibeImgUrl.replace(urlPlaceholders.hash, hash)} alt={name} />
      </div>
      <div
        className={clsx('d-flex align-items-center', {
          'p-3': !matchesMediaQuery,
          'p-2': matchesMediaQuery,
        })}
        onClick={handleSelect}
      >
        <div className="mr-2">
          {selected ? (
            <SpriteIcon name="checked" size="md" />
          ) : (
            <div className={classes.unselectedIcon} />
          )}
        </div>
        <p className="text-ellipsis text-nowrap text-sm">{name}</p>
      </div>
      <IconButton
        className={classes.previewBtn}
        type="button"
        onClick={handleOpenPreview}
      >
        <SpriteIcon name="loupe" size="sm" />
      </IconButton>
    </div>
  );
};

VibesCard.propTypes = {
  option: stylesOptionShape.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpenPreviewModal: PropTypes.func.isRequired,
  className: PropTypes.string,
};

VibesCard.defaultProps = {
  className: '',
};

export default VibesCard;
