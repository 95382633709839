import shabyChair from 'assets/img/home-page/shaby-collection/chair.png';
import shabyTable from 'assets/img/home-page/shaby-collection/table.png';
import shabyLamp from 'assets/img/home-page/shaby-collection/lamp.png';
import shabyVase from 'assets/img/home-page/shaby-collection/vase.png';

import roomModern from 'assets/img/home-page/room-types/modern.jpg';
import roomFarm from 'assets/img/home-page/room-types/farmhouse.jpg';
import roomBoho from 'assets/img/home-page/room-types/boho.jpg';
import roomContem from 'assets/img/home-page/room-types/contemporary.jpg';

import getPreviewImg from 'assets/img/home-page/sections-preview/get.svg';
import requestPreviewImg from 'assets/img/home-page/sections-preview/request.svg';
import curatePreviewImg from 'assets/img/home-page/sections-preview/curate.svg';
import comparePreviewImg from 'assets/img/home-page/sections-preview/compare.svg';
import shopPreviewImg from 'assets/img/home-page/sections-preview/shop.svg';

import showcase1 from 'assets/img/home-page/ambassadors-slider/showcase1.jpg';
import showcase2 from 'assets/img/home-page/ambassadors-slider/showcase2.jpg';
import showcase3 from 'assets/img/home-page/ambassadors-slider/showcase3.jpg';
import showcase4 from 'assets/img/home-page/ambassadors-slider/showcase4.jpg';
import showcase5 from 'assets/img/home-page/ambassadors-slider/showcase5.jpg';
import showcase6 from 'assets/img/home-page/ambassadors-slider/showcase6.jpg';
import showcase7 from 'assets/img/home-page/ambassadors-slider/showcase7.jpg';

import stool1 from 'assets/img/home-page/furniture/stool-1.png';
import stool2 from 'assets/img/home-page/furniture/stool-2.png';
import stool3 from 'assets/img/home-page/furniture/stool-3.png';
import stool4 from 'assets/img/home-page/furniture/stool-4.png';

import product1 from 'assets/img/home-page/furniture/product1.jpg';
import product2 from 'assets/img/home-page/furniture/product2.jpg';
import product3 from 'assets/img/home-page/furniture/product3.jpg';
import product4 from 'assets/img/home-page/furniture/product4.jpg';
import product5 from 'assets/img/home-page/furniture/product5.jpg';
import product6 from 'assets/img/home-page/furniture/product6.jpg';
import product7 from 'assets/img/home-page/furniture/product7.jpg';
import product8 from 'assets/img/home-page/furniture/product8.jpg';
import product9 from 'assets/img/home-page/furniture/product9.jpg';
import product10 from 'assets/img/home-page/furniture/product10.jpg';
import product11 from 'assets/img/home-page/furniture/product11.jpg';
import product12 from 'assets/img/home-page/furniture/product12.jpg';

import table1 from 'assets/img/home-page/furniture/table-1.png';
import table2 from 'assets/img/home-page/furniture/table-2.png';
import table3 from 'assets/img/home-page/furniture/table-3.png';
import table4 from 'assets/img/home-page/furniture/table-4.png';
import table5 from 'assets/img/home-page/furniture/table-5.png';

import sofa1 from 'assets/img/home-page/furniture/sofa-1.png';
import sofa2 from 'assets/img/home-page/furniture/sofa-2.png';
import sofa3 from 'assets/img/home-page/furniture/sofa-3.png';
import sofa4 from 'assets/img/home-page/furniture/sofa-4.png';

import chair1 from 'assets/img/home-page/furniture/chair-1.png';
import chair2 from 'assets/img/home-page/furniture/chair-2.png';
import chair3 from 'assets/img/home-page/furniture/chair-3.png';
import chair4 from 'assets/img/home-page/furniture/chair-4.png';
import chair5 from 'assets/img/home-page/furniture/chair-5.png';
import chair6 from 'assets/img/home-page/furniture/chair-6.png';

export const imagesConfig = {
  ovalShabyCollection: [
    {
      id: 1,
      url: shabyChair,
    },
    {
      id: 2,
      url: shabyTable,
    },
    {
      id: 3,
      url: shabyLamp,
    },
    {
      id: 4,
      url: shabyVase,
    },
  ],
};

export const roomTypesConfig = [
  {
    id: 1,
    type: 'Contemporary',
    imgUrl: roomContem,
  },
  {
    id: 2,
    type: 'Modern',
    imgUrl: roomModern,
  },
  {
    id: 3,
    type: 'Boho',
    imgUrl: roomBoho,
  },
  {
    id: 4,
    type: 'Farmhouse',
    imgUrl: roomFarm,
  },
];

export const previewsConfig = [
  {
    title: 'Get the Look',
    description: 'View Curated Looks & Showcase Pages',
    imgUrl: getPreviewImg,
    targetId: 'get-the-look',
  },
  {
    title: 'Request the Look',
    description: 'Get Look Board Recommendations for Your Favorite Images',
    imgUrl: requestPreviewImg,
    targetId: 'request-the-look',
  },
  {
    title: 'Curate the Look',
    description: 'Create Look Boards With Easy Drag & Drop Tools',
    imgUrl: curatePreviewImg,
    targetId: 'curate-the-look',
  },
  {
    title: 'Compare the Look',
    description: 'Mix & Match Look Board Items',
    imgUrl: comparePreviewImg,
    targetId: 'compare-the-look',
  },
  {
    title: 'Shop the Look',
    description: 'Follow Product Links to Purchase Anywhere Online',
    imgUrl: shopPreviewImg,
    targetId: 'shop-the-look',
  },
];

export const ambassadorsSliderConfig = [
  {
    id: 1,
    imgUrl: showcase3,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 2,
    imgUrl: showcase2,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 3,
    imgUrl: showcase1,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 4,
    imgUrl: showcase4,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 5,
    imgUrl: showcase5,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 6,
    imgUrl: showcase6,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
  {
    id: 7,
    imgUrl: showcase7,
    text: 'So Many Possibilities! Can’t Wait',
    name: 'Janice l. Ambassador',
  },
];

export const compareSlidersConfig = [
  {
    id: 1,
    title: 'Sofa',
    items: [
      {
        id: 1,
        imgUrl: sofa1,
      },
      {
        id: 2,
        imgUrl: sofa2,
      },
      {
        id: 3,
        imgUrl: sofa3,
      },
      {
        id: 4,
        imgUrl: sofa4,
      },
    ],
  },
  {
    id: 2,
    title: 'Table',
    items: [
      {
        id: 1,
        imgUrl: table1,
      },
      {
        id: 2,
        imgUrl: table2,
      },
      {
        id: 3,
        imgUrl: table3,
      },
      {
        id: 4,
        imgUrl: table4,
      },
    ],
  },
  {
    id: 3,
    title: 'Stool',
    items: [
      {
        id: 1,
        imgUrl: stool1,
      },
      {
        id: 2,
        imgUrl: stool2,
      },
      {
        id: 3,
        imgUrl: stool3,
      },
      {
        id: 4,
        imgUrl: stool4,
      },
    ],
  },
  {
    id: 4,
    title: 'Chair',
    items: [
      {
        id: 1,
        imgUrl: chair1,
      },
      {
        id: 2,
        imgUrl: chair2,
      },
      {
        id: 3,
        imgUrl: chair5,
      },
      {
        id: 4,
        imgUrl: chair4,
      },
    ],
  },
];

export const visualizeSectionConfig = {
  // colors: ['#A0ADB2', '#E2DFDA', '#FFFFFF'],
  lookBoardTabData: [
    {
      id: 1,
      title: 'Get the Look',
      items: [
        {
          id: 1,
          url: product1,
        },
        {
          id: 2,
          url: product2,
        },
        {
          id: 3,
          url: product3,
        },
        {
          id: 4,
          url: product4,
        },
      ],
    },
    {
      id: 2,
      title: 'Get the Look',
      items: [
        {
          id: 1,
          url: product5,
        },
        {
          id: 2,
          url: product6,
        },
        {
          id: 3,
          url: product7,
        },
        {
          id: 4,
          url: product8,
        },
      ],
    },
    {
      id: 3,
      title: 'Get the Look',
      items: [
        {
          id: 1,
          url: product9,
        },
        {
          id: 2,
          url: product10,
        },
        {
          id: 3,
          url: product11,
        },
        {
          id: 4,
          url: product12,
        },
      ],
    },
  ],
  mixMatchData: [
    {
      id: 1,
      title: 'Dining Tables',
      items: [
        {
          id: 1,
          url: product3,
        },
        {
          id: 2,
          url: product7,
        },
        {
          id: 3,
          url: product11,
        },
      ],
    },
    {
      id: 2,
      title: 'Dining Chairs',
      items: [
        {
          id: 1,
          url: product4,
        },
        {
          id: 2,
          url: product8,
        },
        {
          id: 3,
          url: product12,
        },
      ],
    },
    {
      id: 3,
      title: 'Chandeliers & Pendants',
      items: [
        {
          id: 1,
          url: product1,
        },
        {
          id: 2,
          url: product5,
        },
        {
          id: 3,
          url: product9,
        },
      ],
    },
    {
      id: 4,
      title: 'Wall Art',
      items: [
        {
          id: 1,
          url: product2,
        },
        {
          id: 2,
          url: product6,
        },
        {
          id: 3,
          url: product10,
        },
      ],
    },
  ],
  productStreamData: [
    {
      id: 1,
      title: 'Accent Chairs',
      items: [
        {
          id: 1,
          imgUrl: chair6,
          price: 1260,
        },
        {
          id: 2,
          imgUrl: chair1,
          price: 1480,
        },
        {
          id: 3,
          imgUrl: chair2,
          price: 1696,
        },
        {
          id: 4,
          imgUrl: chair3,
          price: 2260,
        },
        {
          id: 5,
          imgUrl: chair4,
          price: 2460,
        },
      ],
    },
    {
      id: 2,
      title: 'Coffee tables',
      items: [
        {
          id: 1,
          imgUrl: table3,
          price: 1260,
        },
        {
          id: 2,
          imgUrl: table1,
          price: 1480,
        },
        {
          id: 3,
          imgUrl: table2,
          price: 1696,
        },
        {
          id: 4,
          imgUrl: table5,
          price: 2260,
        },
        {
          id: 5,
          imgUrl: table4,
          price: 2460,
        },
      ],
    },
  ],
};
