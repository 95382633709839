import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/ui/Button/Button';
import { routesByName } from 'constants/routes';
import {
  lookBoardSortKeys,
  lookBoardSourceKeys,
  lookBoardTypeKeys,
} from 'constants/lookBoardSearchParams';
import {
  myLookBoardsTabsConfig,
  myMixAndMatchTabKey,
} from 'modules/dashboard/constants';
import lookBoardService from 'modules/lookBoard/lookBoardService';
import infoToastr from 'libs/toastr/infoToastr';
import errorToastr from 'libs/toastr/errorToastr';
import Tab from 'components/ui/Tab/Tab';
import MyLookBoardsComponent from 'modules/dashboard/myLookBoards/MyLookBoardsComponent';
import MixAndMatchContainer from 'modules/dashboard/myMixAndMatches/MixAndMatchContainer';
import accountClasses from 'modules/account/Account.module.scss';
import useCancelToken from 'hooks/useCancelToken';

// TODO: Get limit value from application config
const limit = 10;

const initialSearchParams = {
  type: lookBoardTypeKeys.all,
  source: lookBoardSourceKeys.my,
  sort: lookBoardSortKeys.newest,
  draft: 0,
  offset: 0,
};

const MyLookBoardsContainer = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(lookBoardSourceKeys.my);
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [lookBoardList, setLookBoardList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createCancelToken, isCancelled] = useCancelToken();

  const loadFirst = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();

      const preparedSearchParams = {
        ...searchParams,
        type:
          searchParams.type === lookBoardTypeKeys.all
            ? undefined
            : searchParams.type,
      };
      const { result: list } = await lookBoardService.getLookBoards(
        preparedSearchParams,
        {
          cancelToken,
        }
      );

      setLookBoardList(list);
      if (list.length > 0) {
        setSearchParams((prevState) => ({
          ...prevState,
          offset: prevState.offset + list.length,
        }));
      }
      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [searchParams, isCancelled, createCancelToken]);

  const loadMore = useCallback(async () => {
    setLoading(true);

    try {
      const cancelToken = createCancelToken();

      const preparedSearchParams = {
        ...searchParams,
        type:
          searchParams.type === lookBoardTypeKeys.all
            ? undefined
            : searchParams.type,
      };
      const { result: list } = await lookBoardService.getLookBoards(
        preparedSearchParams,
        {
          cancelToken,
        }
      );

      setLookBoardList((prevState) => [...prevState, ...list]);
      setSearchParams((prevState) => ({
        ...prevState,
        offset: prevState.offset + list.length,
      }));
      setHasMore(list.length === limit);
      setLoading(false);
    } catch (e) {
      if (!isCancelled(e)) {
        errorToastr('Error', e.generalError);
      }
    }
  }, [searchParams, isCancelled, createCancelToken]);

  useEffect(() => {
    (async () => {
      if (searchParams.offset === 0) {
        await loadFirst();
      }
    })();
    // eslint-disable-next-line
  }, [searchParams]);

  const handleCreateLookBoard = useCallback(() => {
    history.push(routesByName.curateTheLook.canvas);
  }, [history]);

  const handleTabChange = useCallback(
    (tab) => {
      setActiveTab(tab);
      if (tab !== myMixAndMatchTabKey && tab !== searchParams.source) {
        setSearchParams((prevState) => ({
          ...prevState,
          source: tab,
          offset: 0,
        }));
      }
    },
    [searchParams.source]
  );

  const handleChangeSearchParams = useCallback((params) => {
    setSearchParams((prevState) => ({
      ...prevState,
      ...params,
      offset: 0,
    }));
  }, []);

  const handleDeleteLookBoard = useCallback(
    async (lookBoardId) => {
      try {
        await lookBoardService.deleteLookBoard(lookBoardId);
        const updatedLookBoardList = lookBoardList.filter(
          ({ id }) => id !== Number.parseInt(lookBoardId, 10)
        );
        setLookBoardList(updatedLookBoardList);
        infoToastr('Info', 'Look Board successfully deleted');
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [lookBoardList]
  );

  const handleUnlikeLookBoard = useCallback(
    async (lookBoardId) => {
      try {
        await lookBoardService.toggleLike(lookBoardId, 0);
        const updatedLookBoardList = lookBoardList.filter(
          ({ id }) => id !== Number.parseInt(lookBoardId, 10)
        );
        setLookBoardList(updatedLookBoardList);
      } catch (e) {
        errorToastr('Error', e.generalError);
      }
    },
    [lookBoardList]
  );
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className={accountClasses.title}>My Look Boards</p>
        <Button size="sm" inline onClick={handleCreateLookBoard}>
          Create New Look Board
        </Button>
      </div>
      <div className="d-flex">
        {myLookBoardsTabsConfig.map(({ name, title }) => (
          <Tab
            key={name}
            title={title}
            isActive={name === activeTab}
            name={name}
            onToggle={handleTabChange}
          />
        ))}
      </div>
      {activeTab === myMixAndMatchTabKey ? (
        <MixAndMatchContainer />
      ) : (
        <MyLookBoardsComponent
          loading={loading}
          hasMore={hasMore}
          loadMore={loadMore}
          lookBoardList={lookBoardList}
          currentSource={searchParams.source}
          currentType={searchParams.type}
          currentSort={searchParams.sort}
          currentStatus={searchParams.draft}
          onChangeSearchParams={handleChangeSearchParams}
          onDeleteLookBoard={handleDeleteLookBoard}
          onUnlikeLookBoard={handleUnlikeLookBoard}
        />
      )}
    </>
  );
};

MyLookBoardsContainer.propTypes = {};

export default MyLookBoardsContainer;
