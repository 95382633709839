import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash-es';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import buildResourceUrl from 'utils/buildResourceUrl';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import LookBoardDetailsForm from 'modules/curateTheLook/lookBoardDetails/LookBoardDetailsForm';
import {
  getInspirationImgUrl,
  getItemClassesWithItemTypes,
} from 'modules/app/store/selectors';
import lookBoardDetailsValidator from 'modules/curateTheLook/lookBoardDetails/lookBoardDetailsValidator';
import {
  resetCurateStateAction,
  saveLookBoardAction,
  saveLookBoardDetailsFormValuesAction,
  updateLookBoardDataAction,
} from 'modules/curateTheLook/store/actions';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import Button from 'components/ui/Button/Button';
import { lookBoardDetailsFormFields } from 'modules/curateTheLook/constants';
import createLookBoardClasses from 'modules/curateTheLook/createLookBoard/CreateLookBoard.module.scss';
import classes from 'modules/curateTheLook/lookBoardDetails/LookBoardDetails.module.scss';

const LookBoardDetailsContainer = ({ onRequestDetailsPreview }) => {
  const dispatch = useDispatch();

  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const { itemClasses: itemClassesEnum, itemTypes } = useSelector(
    (state) => state.app.enums
  );
  const products = useSelector((state) => state.product.library);
  const curateTheLook = useSelector((state) => state.curateTheLook);
  const inspirationImageLibrary = useSelector(
    (state) => state.inspirationImage.library
  );
  const selectedImage = useMemo(
    () =>
      inspirationImageLibrary[curateTheLook.lookBoardData.inspirationImageId],
    [curateTheLook.lookBoardData.inspirationImageId, inspirationImageLibrary]
  );
  const requestTitle = useMemo(
    () => curateTheLook?.requestInfo?.title ?? null,
    [curateTheLook]
  );
  const itemClasses = useMemo(
    () =>
      getItemClassesWithItemTypes({ itemTypes, itemClasses: itemClassesEnum }),
    [itemClassesEnum, itemTypes]
  );
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const [stepComplete, setStepComplete] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [autoCompleteValue, setAutoCompleteValue] = useState(itemClasses[0]);
  const handleChangeItemClass = useCallback((option, formValues) => {
    if (option?.value) {
      setAutoCompleteValue(option);
      setInitialFormValues(() => ({
        ...formValues,
        [lookBoardDetailsFormFields.itemClassId]: option.value,
      }));
    }
  }, []);

  const imgUrl = useMemo(() => {
    if (!selectedImage) {
      return null;
    }
    const {
      media: { userId, hash },
    } = selectedImage;
    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [inspirationImageUrl, selectedImage]);

  const productList = useMemo(() => {
    if (!curateTheLook.lookBoardData || !products) {
      return [];
    }

    return curateTheLook.lookBoardData.products.map((id) => products[id]);
  }, [curateTheLook.lookBoardData, products]);

  useEffect(() => {
    if (!curateTheLook.lookBoardData) {
      return;
    }

    let values = {};

    if (selectedImage) {
      const { type, itemClassId, roomTypeId, styles } = selectedImage;

      values = {
        ...values,
        type,
        itemClassId,
        roomTypeId,
        styleId: styles.length ? styles[0] : null,
      };

      if (itemClassId) {
        const currentItemClass = itemClasses.find(
          ({ value }) => value === itemClassId
        );
        setAutoCompleteValue(currentItemClass);
      }
    }

    const preparedFormValues =
      curateTheLook.lookBoardDetailsFormValues ?? values;

    setInitialFormValues({
      ...preparedFormValues,
      title: curateTheLook.lookBoardData.title,
    });
  }, [
    curateTheLook.lookBoardDetailsFormValues,
    curateTheLook.lookBoardData,
    selectedImage,
    itemClasses,
  ]);

  const goBack = useCallback(() => {
    history.push(routesByName.curateTheLook.canvas);
  }, [history]);

  const handleToggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const handleFormSubmit = useCallback(
    async (formValues) => {
      dispatch(saveLookBoardDetailsFormValuesAction(formValues));
      dispatch(
        updateLookBoardDataAction({
          title: formValues.title,
        })
      );
      if (selectedImage) {
        history.push(routesByName.curateTheLook.submit);
      } else {
        try {
          await dispatch(saveLookBoardAction());
          dispatch(resetCurateStateAction());
          successToastr('Success', 'Look Board Successfully submitted');
          history.push(routesByName.dashboard.lookBoards);
        } catch (e) {
          errorToastr('Error', e.generalError);
        }
      }
    },
    [dispatch, selectedImage, history]
  );

  const onFormChange = useCallback(
    throttle(({ valid }) => {
      setStepComplete(valid);
    }, 250),
    []
  );

  return curateTheLook.lookBoardData ? (
    <div className={classes.root}>
      <div className="d-flex align-items-center mb-3">
        <IconButton variant="inverted-white" size="sm" onClick={goBack}>
          <SpriteIcon name="left-arrow" size="sm" />
        </IconButton>
        <span className="primary-color text-sm ml-1">Back</span>
      </div>
      <div className="d-flex">
        <div className={`${classes.lookBoardSection} mr-4`}>
          <h3 className={classes.sectionTitle}>Your look board</h3>
          <p className={classes.sectionSubTitle}>
            <b>Only the first two rows of products</b> will show in the Get the
            Look section
          </p>
          <div style={{ maxHeight: '100%' }}>
            <LookBoard
              title={curateTheLook.lookBoardData.title}
              color={curateTheLook.lookBoardData.color}
              columns={curateTheLook.lookBoardData.columns}
              items={productList}
              collapsed={collapsed}
              onToggleCollapse={handleToggleCollapse}
              onEdit={goBack}
            />
          </div>
        </div>
        <div className="flex-fill">
          <div
            className={`${createLookBoardClasses.stepTitle} d-inline-flex mb-3`}
          >
            {stepComplete && (
              <SpriteIcon
                name="checked"
                size="sm"
                className="mr-1 primary-color"
              />
            )}
            <span className="flex-fill mr-1">
              Step 3: Add Look Board Details
            </span>
          </div>
          <p className={classes.sectionSubTitle}>
            This information will be shown for all users
          </p>
          <div className={classes.detailsSection}>
            <div className={clsx('flex-fill p-4', classes.detailsAdaptiveForm)}>
              <h4
                className="font-title text-capitalize mb-3"
                style={{ fontSize: 24 }}
              >
                <span className="primary-color">
                  {requestTitle && 'Request |'}
                </span>{' '}
                Look board details
              </h4>
              <LookBoardDetailsForm
                imageType={selectedImage?.type}
                initialValues={initialFormValues}
                validate={lookBoardDetailsValidator}
                onSubmit={handleFormSubmit}
                autoCompleteValue={autoCompleteValue}
                handleChangeItemClass={handleChangeItemClass}
                isRequest={Boolean(requestTitle)}
                onFormChange={onFormChange}
              />
            </div>
            <div className={classes.selectedImgContainer}>
              {selectedImage && (
                <>
                  <div className={classes.imgWrapper}>
                    <img alt={selectedImage.title} src={imgUrl} />
                    <div className={classes.imgTitle}>
                      <div className={classes.label}>
                        <div className={classes.marker} />
                        <span>Inspiration image</span>
                      </div>
                      <span
                        className={clsx('text-xs text-nowrap text-ellipsis', {
                          [classes.selectedImageTitle]: requestTitle,
                        })}
                      >
                        {selectedImage.title}
                      </span>
                      {requestTitle && (
                        <Button
                          inline
                          size="xs"
                          color="secondary"
                          variant="default"
                          onClick={onRequestDetailsPreview}
                          className={clsx(
                            'link-underline text-xs primary-color p-0',
                            classes.viewRequestBtn
                          )}
                        >
                          View Request
                        </Button>
                      )}
                    </div>
                  </div>
                  {requestTitle && (
                    <div className={classes.requestInfo}>
                      <div>
                        <b>Request Title: </b> {requestTitle}
                      </div>
                      <div>
                        <b>Request Type: </b> Look of the{' '}
                        <span className="text-capitalize">
                          {selectedImage.type}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

LookBoardDetailsContainer.propTypes = {
  onRequestDetailsPreview: PropTypes.func.isRequired,
};

export default LookBoardDetailsContainer;
