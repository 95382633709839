import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  syncShowcaseAction,
  updateLookboardsLibraryAction,
  updateShowcaseBannerAction,
  updateShowcaseDescriptionAction,
  updateShowcasePageDataAction,
  updateShowcaseStylesAction,
  updateVideoLinkAction,
} from 'modules/showcase/store/actions';
import errorToastr from 'libs/toastr/errorToastr';
import transformArrayToMap from 'utils/transformArrayToMap';
import Button from 'components/ui/Button/Button';
import AddPageDataModal from 'modules/dashboard/showcase/components/AddPageDataModal/AddPageDataModal';
import BannerModal from 'modules/dashboard/showcase/components/BannerModal/BannerModal';
import ShareModal from 'modules/dashboard/showcase/components/ShareModal/ShareModal';
import ShareViaEmailModal from 'modules/dashboard/showcase/components/ShareViaEmailModal/ShareViaEmailModal';
import StylesModal from 'modules/dashboard/showcase/components/StylesModal/StylesModal';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import SampleModal from 'modules/dashboard/showcase/components/SampleModal';
import DigitalBusinessModal from 'modules/dashboard/showcase/components/DigitalBusinessModal';
import StyleStoryModal from 'modules/dashboard/showcase/components/StyleStoryModal';
import accountClasses from 'modules/account/Account.module.scss';
import showcaseService from 'modules/showcase/showcaseService';
import ShowcaseHeader from 'modules/dashboard/showcase/ShowcaseHeader';
import FeaturedImages from 'modules/dashboard/showcase/FeaturedImages';
import ShowcaseLookBoards from 'modules/dashboard/showcase/ShowcaseLookBoards';
import useCancelToken from 'hooks/useCancelToken';
import PageTitlePreviewModal from 'modules/dashboard/showcase/components/PageTitlePreviewModal';
import { updateIILibraryAction } from 'modules/inspirationImage/store/actions';
import successToastr from 'libs/toastr/successToastr';
import SpriteIcon from 'components/ui/SpriteIcon';
import posterImg from 'assets/img/affiliate-posters/poster4.png';
import HowItWorksVideoModal from 'components/modals/HowItWorksVideoModal';
import { toggleHowItWorksVideoModalAction } from 'modules/layout/store/actions';
import SetupShowcaseHint from './components/SetupShowcaseHint';
import classes from './Showcase.module.scss';
import { updateUserProfileAction } from '../../currentUser/actions';
import buildResourceUrl from '../../../utils/buildResourceUrl';

const ShowcaseContainer = () => {
  const dispatch = useDispatch();
  const videoUrlPlaceholder = useSelector(
    (state) => state.app.config.endpoints.media.videos.standart
  );
  const showcase = useSelector((state) => state.showcase.current);
  const bannerUrlPlaceholders = useSelector(
    (state) => state.app.config.endpoints.media.showcaseBanner
  );
  const user = useSelector((state) => state.auth.user);
  const styles = useSelector((state) => state.app.enums.styles);
  const library = useSelector((state) => state.inspirationImage.library);
  const lookBoardsLibrary = useSelector(
    (state) => state.showcase.lookBoardsLibrary
  );
  const featuredImagesDndState = useSelector(
    (state) => state.showcase.dndState.featuredImages
  );
  const featuredLookBoardsDndState = useSelector(
    (state) => state.showcase.dndState.featuredLookBoards
  );

  const [createCancelToken, isCancelled] = useCancelToken();
  const [addPageDataModalOpen, setAddPageDataModalOpen] = useState(false);
  const [bannerModalOpen, setBannerModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareViaEmailModalOpen, setShareViaEmailModalOpen] = useState(false);
  const [stylesModalOpen, setStylesModalOpen] = useState(false);
  const [sampleModalOpen, setSampleModalOpen] = useState(false);
  const [titlePreviewModalOpen, setTitlePreviewModalOpen] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState('');
  const [digitalBusinessModalOpen, setDigitalBusinessModalOpen] = useState(
    false
  );
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [styleStoryModalOpen, setStyleStoryModalOpen] = useState(false);

  const handleOPenTitlePreviewModal = useCallback(() => {
    setTitlePreviewModalOpen(true);
  }, []);

  const handleCloseTitlePreviewModal = useCallback(() => {
    setTitlePreviewModalOpen(false);
  }, []);

  const handleOpenAddPageDataModal = useCallback(() => {
    setAddPageDataModalOpen(true);
  }, []);

  const handleCloseAddPageDataModal = useCallback(() => {
    setAddPageDataModalOpen(false);
  }, []);

  const handleUpdatePageData = useCallback(
    async ({
      facebook,
      instagram,
      linkedin,
      pinterest,
      twitter,
      isSubtitleActive,
      displayName,
      ...rest
    }) => {
      const socialLinks = { facebook, instagram, linkedin, pinterest, twitter };

      const validSocialLinks = Object.values(socialLinks).map((link) => {
        if (!link || link?.length < 0 || link?.startsWith('https')) {
          return link;
        }
        return `https://${link}`;
      });

      const newData = {
        ...Object.keys(socialLinks)?.reduce(
          (accum, currValue, index) => ({
            ...accum,
            [currValue]: validSocialLinks[index],
          }),
          {}
        ),
        isSubtitleActive: !isSubtitleActive,
        ...rest,
      };

      try {
        const updateShowcasePagePromise = dispatch(
          updateShowcasePageDataAction(newData)
        );
        const updateUserProfilePromise = dispatch(
          updateUserProfileAction({ ...user, displayName })
        );

        await Promise.all([
          updateShowcasePagePromise,
          updateUserProfilePromise,
        ]);
        setAddPageDataModalOpen(false);
      } catch (e) {
        errorToastr('Error', e.message);
        return { ...e.validationErrors };
      }
      return null;
    },
    [dispatch, user]
  );

  const handleLinkInput = useCallback((value) => setYoutubeLink(value), []);

  const handleUpdateLink = useCallback(async () => {
    try {
      await dispatch(updateVideoLinkAction(youtubeLink));
      successToastr('Success', 'Youtube video link has been updated');
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [dispatch, youtubeLink]);

  const handleOpenBannerModal = useCallback(() => {
    setBannerModalOpen(true);
  }, []);

  const handleCloseBannerModal = useCallback(() => {
    setBannerModalOpen(false);
  }, []);

  const handleUpdateBanner = useCallback(
    async (banner) => {
      try {
        await dispatch(updateShowcaseBannerAction(banner));
        setBannerModalOpen(false);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch]
  );

  const handleOpenShareModal = useCallback(() => {
    setShareModalOpen(true);
  }, []);

  const handleCloseShareModal = useCallback(() => {
    setShareModalOpen(false);
  }, []);

  const handleOpenShareViaEmailModal = useCallback(() => {
    setShareModalOpen(false);
    setShareViaEmailModalOpen(true);
  }, []);

  const handleCloseShareViaEmailModal = useCallback(() => {
    setShareViaEmailModalOpen(false);
  }, []);

  const handleShareViaEmail = useCallback((formValues) => {
    // eslint-disable-next-line
    console.log('Submit share via email form:', formValues);
    setShareViaEmailModalOpen(false);
  }, []);

  const handleOpenStylesModal = useCallback(() => {
    setStylesModalOpen(true);
  }, []);

  const handleCloseStylesModal = useCallback(() => {
    setStylesModalOpen(false);
  }, []);

  const handleUpdateStyles = useCallback(
    async (selectedStyles) => {
      try {
        await dispatch(updateShowcaseStylesAction(selectedStyles));
        setStylesModalOpen(false);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch]
  );

  const handleOpenPreviewModal = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleOpenSampleModal = useCallback(() => {
    setSampleModalOpen(true);
  }, []);

  const handleCloseSampleModal = useCallback(() => {
    setSampleModalOpen(false);
  }, []);

  const handleOpenDigitalBusinessModal = useCallback(() => {
    setDigitalBusinessModalOpen(true);
  }, []);

  const handleCloseDigitalBusinessModal = useCallback(() => {
    setDigitalBusinessModalOpen(false);
  }, []);

  const handleOpenStyleStoryModal = useCallback(() => {
    setStyleStoryModalOpen(true);
  }, []);

  const handleCloseStyleStoryModal = useCallback(() => {
    setStyleStoryModalOpen(false);
  }, []);

  const handleSaveStyleStory = useCallback(
    async ({ description }) => {
      try {
        await dispatch(updateShowcaseDescriptionAction(description));
        setStyleStoryModalOpen(false);
      } catch (e) {
        errorToastr('Error', e.message);
      }
    },
    [dispatch]
  );

  const updateLookBoardsLibrary = useCallback(
    (lookBoardsMap) => {
      dispatch(updateLookboardsLibraryAction(lookBoardsMap));
    },
    [dispatch]
  );

  const loadFeaturedLookBoards = useCallback(async () => {
    try {
      const lookBoardsIds = showcase.featuredLookBoards?.filter(
        (item) => !!item
      );
      const showcaseId = showcase.id;

      if (!lookBoardsIds.length) return;
      const lookBoardsList = await showcaseService.getLookBoards(
        showcaseId,
        lookBoardsIds
      );

      const lookBoardsListMap = transformArrayToMap(lookBoardsList);
      updateLookBoardsLibrary(lookBoardsListMap);
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [showcase, updateLookBoardsLibrary]);

  const loadFeaturedImages = useCallback(async () => {
    try {
      const imageIds = showcase.featuredImages?.filter((item) => !!item);
      const showcaseId = showcase.id;
      if (!imageIds.length) return;
      const imageList = await showcaseService.getImages(showcaseId, imageIds);

      const imageListMap = transformArrayToMap(imageList);
      dispatch(updateIILibraryAction(imageListMap));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [showcase, dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    (async () => {
      try {
        const cancelToken = createCancelToken();
        await dispatch(syncShowcaseAction(cancelToken));
      } catch (e) {
        if (!isCancelled(e)) {
          errorToastr('Error', e.message);
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  const syncInitialLoadFirstData = useCallback(async () => {
    await loadFeaturedImages();
    await loadFeaturedLookBoards();
  }, [loadFeaturedLookBoards, loadFeaturedImages]);

  useEffect(() => {
    (async () => {
      try {
        if (showcase) {
          await syncInitialLoadFirstData();
        }
      } catch (e) {
        errorToastr('Error', e.message);
      }
    })();
    // eslint-disable-next-line
  }, [showcase?.id]);

  useEffect(() => {
    (async () => {
      if (showcase?.styles?.length === 0 && user?.preferences?.styles?.length) {
        try {
          await dispatch(updateShowcaseStylesAction(user?.preferences.styles));
          await dispatch(syncShowcaseAction());
        } catch (e) {
          errorToastr('Error', e.message);
        }
      }
      setYoutubeLink(showcase?.videoUrl);
    })();
    //  eslint-disable-next-line
  }, [showcase]);

  const handleOpenHowItWorksModal = useCallback(() => {
    dispatch(toggleHowItWorksVideoModalAction(true));
  }, [dispatch]);

  //  for future dev
  // const personalShopper = null;

  const videoHomepageLink = useMemo(() => {
    return buildResourceUrl(
      videoUrlPlaceholder,
      null,
      null,
      'step_4_setting_up_showcase_page'
    );
  }, [videoUrlPlaceholder]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-start mb-1 position-relative">
        <div>
          <h1 className={accountClasses.title}>Setup or Edit Showcase Page</h1>
          <div className="d-flex align-items-center py-2">
            <button
              type="button"
              className={clsx(classes.link, 'mr-3')}
              onClick={handleOpenSampleModal}
            >
              Sample showcase page
            </button>
            <Button size="xs" inline onClick={handleOpenHowItWorksModal}>
              Setup Video
              <SpriteIcon name="play-btn-filled" className="ml-1" />
            </Button>
          </div>
        </div>
        <Button
          inline
          variant="outlined"
          size="sm"
          onClick={handleOpenAddPageDataModal}
          disabled={!showcase}
        >
          Add/Edit Showcase Page Data
        </Button>
        <div className={classes.step2}>
          <SetupShowcaseHint text="STEP 2: Edit your Showcase Title & Metadata." />
        </div>
      </div>
      {showcase && (
        <>
          <ShowcaseHeader
            showcase={showcase}
            styles={styles}
            user={user}
            handleOpenShareModal={handleOpenShareModal}
            handleOpenBannerModal={handleOpenBannerModal}
            handleOpenAddPageDataModal={handleOpenAddPageDataModal}
            handleOpenStylesModal={handleOpenStylesModal}
            handleOpenStyleStoryModal={handleOpenStyleStoryModal}
            handleOpenDigitalBusinessModal={handleOpenDigitalBusinessModal}
            bannerUrlPlaceholders={bannerUrlPlaceholders}
            handleLinkInput={handleLinkInput}
            handleUpdateLink={handleUpdateLink}
            youtubeLink={youtubeLink}
          />
          <FeaturedImages
            handleOpenPreviewModal={handleOpenPreviewModal}
            imageLibrary={library}
            dndState={featuredImagesDndState}
          />
          <ShowcaseLookBoards
            lookBoardsLibrary={lookBoardsLibrary}
            dndState={featuredLookBoardsDndState}
          />
          <div>*Updates Will be saved automatically</div>

          <AddPageDataModal
            open={addPageDataModalOpen}
            currentData={showcase}
            onClose={handleCloseAddPageDataModal}
            onApply={handleUpdatePageData}
            openTitlePreviewModal={handleOPenTitlePreviewModal}
            user={user}
          />
          <BannerModal
            open={bannerModalOpen}
            onClose={handleCloseBannerModal}
            currentValue={showcase.banner}
            onApply={handleUpdateBanner}
          />
          <ShareModal
            open={shareModalOpen}
            onClose={handleCloseShareModal}
            shareUrl={showcase.shareUrl}
            onShareViaEmail={handleOpenShareViaEmailModal}
          />
          <ShareViaEmailModal
            open={shareViaEmailModalOpen}
            onClose={handleCloseShareViaEmailModal}
            onSubmit={handleShareViaEmail}
          />
          <StylesModal
            open={stylesModalOpen}
            currentValue={showcase.styles}
            onClose={handleCloseStylesModal}
            onApply={handleUpdateStyles}
          />
          <ImgPreviewModal
            open={imgModalOpen}
            onClose={handleImgModalClose}
            url={imgPreviewUrl}
          />
          <PageTitlePreviewModal
            open={titlePreviewModalOpen}
            onClose={handleCloseTitlePreviewModal}
          />
          <SampleModal
            open={sampleModalOpen}
            onClose={handleCloseSampleModal}
          />
          <DigitalBusinessModal
            open={digitalBusinessModalOpen}
            onClose={handleCloseDigitalBusinessModal}
          />
          <StyleStoryModal
            open={styleStoryModalOpen}
            onClose={handleCloseStyleStoryModal}
            description={showcase.description}
            onApply={handleSaveStyleStory}
          />
          <HowItWorksVideoModal
            video={videoHomepageLink}
            posterImg={posterImg}
          />
        </>
      )}
    </>
  );
};

export default ShowcaseContainer;
