import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import BasicAvatar from 'components/ui/BasicAvatar';
import useCurrentUserAvatarUrl from 'hooks/useCurrentUserAvatarUrl';
import classes from './AmbassadorEdit.module.scss';

const AmbassadorEdit = () => {
  const { firstName, lastName, ambassador, displayName } = useSelector(
    (state) => state.auth.user
  );

  const avatarUrl = useCurrentUserAvatarUrl();
  return (
    <aside className={`${classes.root} px-3 py-5 mb-3`}>
      <h2 className={`${classes.title} text-center text-uppercase mb-3`}>
        {ambassador ? 'Ambassador' : 'User'}
      </h2>
      <div className="flex-center mb-3">
        <BasicAvatar
          className={classes.avatar}
          firstName={firstName}
          lastName={lastName}
          src={avatarUrl}
        />
        <p className={`text-sm font-semi-bold ${classes.textBreak}`}>
          {displayName}
        </p>
      </div>
      <div className="flex-center">
        <Link to={routesByName.account.profile}>
          <Button variant="outlined" size="lg">
            Edit Profile
          </Button>
        </Link>
      </div>
    </aside>
  );
};

export default AmbassadorEdit;
