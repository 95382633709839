import React from 'react';

const Page404 = () => (
  <div className="flex-fill flex-center title">404 Page not found</div>
);

Page404.propTypes = {};

Page404.defaultProps = {};

export default Page404;
