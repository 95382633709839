import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import SpriteIcon from 'components/ui/SpriteIcon';
import roomTypesOptionShape from 'components/PreferenceQuiz/propTypes/roomTypesOptionShape';
import classes from 'components/PreferenceQuiz/RoomTypeOption/RoomTypeOption.module.scss';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { maxWidthMd } from '../../../constants/mediaQueries';

const RoomTypeOption = ({ option: { id, name, icon }, selected, onChange }) => {
  const matchesMediaQuery = useMediaQuery(maxWidthMd);

  const handleSelect = useCallback(() => {
    onChange(id, !selected);
  }, [onChange, id, selected]);

  return (
    <div
      className={clsx('align-items-center cursor-pointer', {
        [classes.selected]: selected,
        'd-inline-flex flex-column': !matchesMediaQuery,
        'd-flex w-100 px-2': matchesMediaQuery,
      })}
      onClick={handleSelect}
    >
      {matchesMediaQuery ? (
        <>
          <div>
            {selected ? (
              <SpriteIcon
                name="rounded-check"
                size="sm"
                className={classes.checkIcon}
              />
            ) : (
              <div className={classes.unselectedItem} />
            )}
          </div>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: icon }}
            className={classes.icon}
          />
          <p className="d-flex align-items-center">
            <span className={classes.title}>{name}</span>
          </p>
        </>
      ) : (
        <>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: icon }}
            className={classes.icon}
          />
          <p className="d-flex align-items-center">
            {selected && (
              <SpriteIcon
                name="rounded-check"
                size="sm"
                className={classes.checkIcon}
              />
            )}
            <span className={classes.title}>{name}</span>
          </p>
        </>
      )}
    </div>
  );
};

RoomTypeOption.propTypes = {
  option: roomTypesOptionShape.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RoomTypeOption;
