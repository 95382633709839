import transformArrayToMap from 'utils/transformArrayToMap';

const mergeLookBoardsWithProducts = (lookBoards, products) => {
  const productsMap = transformArrayToMap(products);
  return lookBoards.map((lookBoard) => ({
    ...lookBoard,
    products: lookBoard.products.map((productId) => ({
      ...productsMap[productId],
    })),
  }));
};

export default mergeLookBoardsWithProducts;
