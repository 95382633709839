import faqIcon1 from 'assets/img/faqs-page/faq-icon1.png';
import faqIcon2 from 'assets/img/faqs-page/faq-icon2.png';
import faqIcon3 from 'assets/img/faqs-page/faq-icon3.png';
import faqIcon4 from 'assets/img/faqs-page/faq-icon4.png';
import faqIcon5 from 'assets/img/faqs-page/faq-icon5.png';
import faqIcon6 from 'assets/img/faqs-page/faq-icon6.png';

export const faqContribute = [
  {
    title: 'Add Inspiration Images: ',
    text:
      'Upload or pin room images that may be showcased in our Get the Look section, which features many of the world’s most beautiful Inspo images.',
    img: faqIcon1,
  },
  {
    title: 'Curate Look Boards: ',
    text:
      'Curate the look to offer your unique take on getting the look of beautiful Inspo images.',
    img: faqIcon2,
  },
  {
    title: 'Vote on Look Boards: ',
    text:
      'Vote on Look Boards to make them stand out as the Best Match, Most Stylish, Most Unique, or Best Combo.',
    img: faqIcon3,
  },
  {
    title: 'Like Images: ',
    text:
      'Like your favorite images and Look Boards to boost their visibility and rankings on Pin the Look.',
    img: faqIcon4,
  },
  {
    title: 'Follow Contributors: ',
    text:
      'Follow your favorite contributors and Ambassadors to receive notifications when they add or curate images.',
    img: faqIcon5,
  },
  {
    title: 'Share on Social Media: ',
    text:
      'Share your favorite Look Boards and Ambassador showcase cards by clicking the “Share” icon to download social-media-ready images and their page links.',
    img: faqIcon6,
  },
];

export const faqDashboard = [
  {
    title: 'My Images: ',
    text:
      'This section includes room and product images that you personally added to Pin the Look. It also includes Look Boards from Pin the Look you “liked.”',
    img: 'image',
  },
  {
    title: 'My Look Boards: ',
    text:
      'This section includes Look Boards that you personally curated, Mix-and-Match Look Boards, and Look Boards created by others on Pin the Look that you “liked.”',
    img: 'faq-look-boards',
  },
  {
    title: 'My Requests: ',
    text:
      'This section includes your Requests to get the look of room images. In addition, it contains links to “Recommendations,” which are pages showing all Look Boards curated for a specific Request.',
    img: 'reload',
  },
  {
    title: 'Community: ',
    text:
      'This section includes your Pin the Look followers and those following you.',
    img: 'faq-community',
  },
  {
    title: 'Messages: ',
    text: 'This section includes messages related to Requests and Look Boards.',
    img: 'faq-messages',
  },
];

export const sectionTitles = {
  aboutPinTheLook: 'About Pin The Look',
  registration: 'Registration',
  profileDashboard: 'Profile Dashboard',
  pinningImages: 'Pinning Images',
  inspirationImages: 'Inspiration Images',
  styleQuiz: 'Style Quiz',
  getTheLook: 'Get The Look',
  requestTheLook: 'Request The Look',
  curateTheLook: 'Curate The Look',
  ambassadors: 'Ambassadors',
  imageOwnership: 'Image Ownership',
};
