import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import classes from './ComingSoon.module.scss';
import { comingSoonFormFields } from './constants';
import TextInputWrapper from '../../../components/finalFormWrappers/TextInputWrapper';
import FinalFormSubmitButton from '../../../components/finalFormWrappers/FinalFormSubmitButton';
import { errorMessages } from '../../auth/signIn/constants';
import comingSoonService from './comingSoonService';
import errorToastr from '../../../libs/toastr/errorToastr';
import { routesByName } from '../../../constants/routes';

const ComingSoonPassword = () => {
  const history = useHistory();

  const signInValidator = ({ [comingSoonFormFields.password]: password }) => {
    const errors = {};

    if (!password) {
      errors.password = errorMessages.required;
    }
    return errors;
  };

  const onSubmit = useCallback(
    async (values) => {
      try {
        const { result } = await comingSoonService.checkPasswordComingSoon({
          password: values.password,
        });
        if (result) {
          localStorage.setItem('isAccess', 'true');
          history.push(routesByName.getTheLook.index);
        } else {
          errorToastr('Error', 'Password is incorrect');
        }
      } catch (e) {
        errorToastr(e.generalError);
        return { ...e.validationErrors };
      }
      return true;
    },
    [history]
  );

  return (
    <div className={classes.formPasswordWrapper}>
      <Form
        validate={signInValidator}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Field
              required
              label="Enter Password"
              type="password"
              name={comingSoonFormFields.password}
              component={TextInputWrapper}
            />
            <div className="text-center">
              <FinalFormSubmitButton
                className={classes.submitBtn}
                size="lg"
                square
              >
                Click
              </FinalFormSubmitButton>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default ComingSoonPassword;
