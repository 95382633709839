/* eslint-disable */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import transformArrayToMap from 'utils/transformArrayToMap';
import findObjectById from 'utils/findObjectById';
import { routesByName } from 'constants/routes';
import { bannerTypeKeys } from 'modules/dashboard/showcase/constants';
import showcaseService from 'modules/showcase/showcaseService';
import currentUserService from 'modules/currentUser/currentUserService';
import buildResourceUrl from 'utils/buildResourceUrl';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import BasicAvatar from 'components/ui/BasicAvatar';
import Button from 'components/ui/Button/Button';
import FeaturedImage from 'modules/dashboard/showcase/components/FeaturedImage';
import LookBoardPreview from 'components/lookBoardThumbnails/LookBoard/LookBoardPreview';
import errorToastr from 'libs/toastr/errorToastr';
import ImgPreviewModal from 'components/modals/ImgPreviewModal/ImgPreviewModal';
import SharePopover from 'components/modals/SharePopover/SharePopover';
import showcaseClasses from 'modules/dashboard/showcase/Showcase.module.scss';
import Slider from 'react-slick';
import InfiniteScroll from 'react-infinite-scroller';
import classes from './ShowcaseDetails.module.scss';
import useQuery from 'hooks/useQuery';
import { setPreviewUrlKeyAction } from './store/actions';

const imagesToShow = 4;
const lookBoardsLimit = 10;

const ShowcasePreview = () => {
  const bannerUrlPlaceholder = useSelector(
    (state) => state.app.config.endpoints.media.showcaseBanner.large
  );
  const userAvatarThumbUrl = useSelector(
    (state) => state.app.config.endpoints.media.userAvatar.thumb
  );
  const styles = useSelector((state) => state.app.enums.styles);
  const urlKey = useSelector((state) => state.showcase.previewUrlKey);

  const descriptionRef = useRef(null);
  const descriptionBtnRef = useRef(null);
  const queryParams = useQuery();
  const queryUrlKey = queryParams.get('key');
  const history = useHistory();
  const dispatch = useDispatch();
  const [showcase, setShowcase] = useState(null);
  const [followed, setFollowed] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [styleStoryOpen, setStyleStoryOpen] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(null);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [urlForShare, setUrlForShare] = useState('');
  const [lookBoardsLibrary, setLookBoardsLibrary] = useState({});
  const [lookBoardsId, setLookBoardsId] = useState(null);

  const [lookBoardsHasMore, setHasMoreLookBoards] = useState(true);
  const [lookBoardsLoading, setLookBoardsLoading] = useState(false);
  const [lookBoardsOffset, setLookBoardsOffset] = useState(0);

  const [showcaseImagesMap, setShowcaseImagesMap] = useState({});

  const loadMoreLookBoards = useCallback(async () => {
    setLookBoardsLoading(true);
    const lookBoardsIds = showcase.lookBoards.filter(
      (id, index) => index < lookBoardsOffset + lookBoardsLimit
    );
    if (!lookBoardsIds.length) return;
    try {
      const lookBoards = await showcaseService.getLookBoards(
        showcase.id,
        lookBoardsIds
      );

      setHasMoreLookBoards(lookBoards.length === lookBoardsLimit);
      setLookBoardsOffset((prevState) => prevState + lookBoards.length);

      setLookBoardsLibrary((prevState) => ({
        ...prevState,
        ...transformArrayToMap(lookBoards),
      }));
    } catch (e) {
      errorToastr('Error', e.message);
    }
    setLookBoardsLoading(false);
  }, [lookBoardsOffset, showcase]);

  const handleLoadMoreLookBoards = useCallback(async () => {
    if (lookBoardsHasMore && !lookBoardsLoading && showcase) {
      await loadMoreLookBoards();
    }
  }, [lookBoardsHasMore, lookBoardsLoading, showcase, loadMoreLookBoards]);
  const sliderRef = useRef(null);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (!queryUrlKey && !urlKey) handleGoBack();
    if (!urlKey) {
      dispatch(setPreviewUrlKeyAction(queryUrlKey));
      return;
    }
    (async () => {
      try {
        const result = await showcaseService.getShowcaseByUrlKey(urlKey);
        const showcaseUser = await currentUserService.getUserById(
          result.userId
        );
        const currentShowcase = { ...result, user: showcaseUser };
        setShowcase(currentShowcase);
      } catch (e) {
        errorToastr('Error', e.message);
        history.push(routesByName.dashboard.ambassadorShowcase);
      }
    })();
  }, [urlKey, handleGoBack, dispatch, queryUrlKey, history]);

  const avatarUrl = useMemo(() => {
    if (showcase && showcase.user.avatar) {
      const { userId: showcaseUserId, hash: avatarHash } = showcase.user.avatar;
      return buildResourceUrl(userAvatarThumbUrl, showcaseUserId, avatarHash);
    }
    return null;
  }, [showcase, userAvatarThumbUrl]);

  const bannerUrl = useMemo(() => {
    if (!showcase) return null;
    const {
      banner: { media, type: bannerType },
    } = showcase;
    return bannerType === bannerTypeKeys.image && media
      ? buildResourceUrl(bannerUrlPlaceholder, media.userId, media.hash)
      : null;
  }, [bannerUrlPlaceholder, showcase]);

  const loadFeaturedImages = useCallback(async () => {
    try {
      const imagesIds = showcase.featuredImages;
      const showcaseId = showcase.id;
      if (!imagesIds.length) return;
      const imagesList = await showcaseService.getImages(showcaseId, imagesIds);
      setShowcaseImagesMap(transformArrayToMap(imagesList));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [showcase]);

  const loadFeaturedLookBoards = useCallback(async () => {
    try {
      const lookBoardsIds = showcase.featuredLookBoards;
      const showcaseId = showcase.id;
      if (!lookBoardsIds.length) return;
      const lookBoardsList = await showcaseService.getLookBoards(
        showcaseId,
        lookBoardsIds
      );
      setLookBoardsLibrary((prevState) => ({
        ...prevState,
        ...transformArrayToMap(lookBoardsList),
      }));
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, [showcase]);

  const initialLoadData = useCallback(async () => {
    await loadFeaturedImages();
    await loadFeaturedLookBoards();
    await loadMoreLookBoards();
  }, [loadFeaturedImages, loadFeaturedLookBoards, loadMoreLookBoards]);

  useEffect(() => {
    if (showcase) {
      (async () => {
        await initialLoadData();
      })();
    }
    //  eslint-disable-next-line
  }, [showcase]);

  const handleFollowAction = useCallback(() => {
    setFollowed((prev) => !prev);
  }, []);

  const goToLookBoardDetails = useCallback(
    ({ currentTarget }) => {
      const { id, slug } = currentTarget.dataset;
      history.push(routesByName.lookBoard.details(id, slug));
    },
    [history]
  );

  const handleOpenPreviewModal = useCallback((imgUrl) => {
    setImgPreviewUrl(imgUrl);
    setImgModalOpen(true);
  }, []);

  const handleImgModalClose = useCallback(() => {
    setImgModalOpen(false);
  }, []);

  const handleOpenSharePopover = useCallback(
    ({ currentTarget }) => {
      if (currentTarget.dataset.showcase) {
        setLookBoardsId(showcase?.id);
        setUrlForShare(showcase?.shareUrl ?? '');
      }
      if (currentTarget.dataset.id) {
        const lookBoard = lookBoardsLibrary[currentTarget.dataset.id];
        setLookBoardsId(lookBoard?.id);
        setUrlForShare(lookBoard?.shareUrl);
      }
      setShareAnchorEl(currentTarget);
    },
    [lookBoardsLibrary, showcase]
  );

  const handleCloseStyleStory = useCallback(() => {
    setStyleStoryOpen(false);
  }, []);

  const handleOpenStyleStory = useCallback(() => {
    setStyleStoryOpen(true);
  }, []);

  const handleCloseSharePopover = useCallback(() => {
    setShareAnchorEl(null);
  }, []);

  const handleSlideChange = useCallback((oldIndex, newIndex) => {
    setActiveSlideIndex(newIndex);
  }, []);

  const handleSlidePrev = useCallback(() => {
    sliderRef.current.slickPrev();
  }, []);

  const handleSlideNext = useCallback(() => {
    sliderRef.current.slickNext();
  }, []);

  const handleClickOutDescription = useCallback((e) => {
    if (
      e.composedPath().includes(descriptionRef.current) ||
      e.composedPath().includes(descriptionBtnRef.current)
    )
      return;
    handleCloseStyleStory();
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutDescription);
    return () =>
      document.removeEventListener('click', handleClickOutDescription);
  }, []);

  return (
    <>
      <div className={classes.contentWrapper}>
        <div className={classes.showcaseContainer}>
          <div className="d-flex align-items-center mb-3">
            <IconButton
              variant="inverted-white"
              size="sm"
              onClick={handleGoBack}
            >
              <SpriteIcon name="left-arrow" size="sm" />
            </IconButton>
            <span className="primary-color text-sm ml-1">Back</span>
          </div>
          <h1 className={`${classes.title} mb-3`}>Showcase Page</h1>
          {showcase && (
            <>
              <div
                className={showcaseClasses.bannerContainer}
                style={{
                  background:
                    showcase.banner.type === bannerTypeKeys.color
                      ? showcase.banner.color
                      : 'none',
                }}
              >
                <div className={showcaseClasses.mask} />
                {showcase.banner.type === bannerTypeKeys.image && (
                  <img src={bannerUrl} alt="" />
                )}
                <div className={showcaseClasses.crown}>
                  <SpriteIcon name="crown" size="lg" />
                </div>
                <div className={showcaseClasses.bannerTitleBlock}>
                  <h2 className={`${showcaseClasses.mainTitle} mb-3`}>
                    {showcase.pageName}
                  </h2>
                  {showcase.isSubtitleActive && (
                    <p className={showcaseClasses.subtitle}>
                      {showcase.subtitle}
                    </p>
                  )}
                </div>
              </div>
              <div className={showcaseClasses.userCard}>
                <div className="d-flex">
                  <div className="flex-fill text-ellipsis pl-3 pr-1">
                    <div className="d-flex align-items-center">
                      <BasicAvatar
                        firstName={showcase.user.firstName}
                        lastName={showcase.user.lastName}
                        src={avatarUrl}
                        className={showcaseClasses.avatar}
                      />
                      <p
                        className={`${showcaseClasses.fullName} flex-fill text-ellipsis text-nowrap mr-1`}
                      >
                        {`${showcase.user.firstName} ${showcase.user.lastName}`}
                      </p>
                    </div>
                    <div className="d-flex" style={{ paddingLeft: 80 }}>
                      {showcase.facebook && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={showcase.facebook}
                          className={classes.shareBtnLink}
                        >
                          <IconButton size="sm" className={classes.shareBtn}>
                            <SpriteIcon name="facebook" size="sm" />
                          </IconButton>
                        </a>
                      )}
                      {showcase.twitter && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={showcase.twitter}
                          className={classes.shareBtnLink}
                        >
                          <IconButton size="sm" className={classes.shareBtn}>
                            <SpriteIcon name="twitter" size="sm" />
                          </IconButton>
                        </a>
                      )}
                      {showcase.linkedin && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={showcase.linkedin}
                          className={classes.shareBtnLink}
                        >
                          <IconButton size="sm" className={classes.shareBtn}>
                            <SpriteIcon name="linkedin" size="sm" />
                          </IconButton>
                        </a>
                      )}
                      {showcase.pinterest && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={showcase.pinterest}
                          className={classes.shareBtnLink}
                        >
                          <IconButton size="sm" className={classes.shareBtn}>
                            <SpriteIcon name="pinterest" size="sm" />
                          </IconButton>
                        </a>
                      )}
                      {showcase.instagram && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={showcase.instagram}
                          className={classes.shareBtnLink}
                        >
                          <IconButton size="sm" className={classes.shareBtn}>
                            <SpriteIcon name="instagram" size="sm" />
                          </IconButton>
                        </a>
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="text-md font-semi-bold mr-1 text-ellipsis d-inline">
                        Favorite Styles:
                      </p>
                      {showcase.styles.slice(0, 3).map((styleId) => (
                        <div
                          key={styleId}
                          className={showcaseClasses.styleChip}
                        >
                          {findObjectById(styleId, styles)?.name}
                        </div>
                      ))}
                      {showcase.styles.length > 3 && (
                        <SpriteIcon size="sm" color="#87bdc3" name="plus" />
                      )}
                    </div>
                    <div className="d-flex flex-column align-items-center w-100">
                      <div ref={descriptionBtnRef} className="mt-2">
                        <Button inline size="sm" onClick={handleOpenStyleStory}>
                          My Style Story
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="pr-2">
                    {showcase.youtubeLink && (
                      <video
                        src={showcase.youtubeLink}
                        preload="metadata"
                        // onPause={handlePause}
                        controls
                        className={showcaseClasses.video}
                        // poster={posterImg}
                        autoPlay
                      />
                    )}
                  </div>
                  <div className="d-flex flex-column justify-content-between align-items-center">
                    <IconButton
                      variant="inverted-grey"
                      color="secondary"
                      size="sm"
                      className="mb-2"
                      onClick={() => {}}
                    >
                      <SpriteIcon name="envelope" size="sm" />
                    </IconButton>
                    <IconButton
                      variant="inverted-grey"
                      color="secondary"
                      size="sm"
                      className="mb-2"
                      data-showcase
                      onClick={handleOpenSharePopover}
                    >
                      <SpriteIcon name="share" size="sm" />
                    </IconButton>
                    <Button
                      variant={followed ? 'contained' : 'outlined'}
                      className={clsx(classes.followBtn, {
                        [classes.followed]: followed,
                      })}
                      size="xs"
                      onClick={handleFollowAction}
                    >
                      {followed && (
                        <SpriteIcon
                          name="rounded-check"
                          size="sm"
                          className="mr-1"
                        />
                      )}
                      {followed ? 'Following' : 'Follow'}
                    </Button>
                  </div>
                </div>

                {styleStoryOpen && (
                  <div
                    ref={descriptionRef}
                    className={showcaseClasses.styleStoryPopup}
                  >
                    <h2
                      className={`${showcaseClasses.mainTitle} mb-3 text-center`}
                    >
                      My Style Story
                    </h2>
                    <p className="text-gray">{showcase.description}</p>
                  </div>
                )}
              </div>
              {/*<div className="background-white px-5 py-3 mb-5">*/}
              {/*  <div className="d-flex mb-3">*/}
              {/*    <div style={{ minWidth: 250 }}>*/}
              {/*      <p className={`${classes.title} mb-1`}>My style story:</p>*/}
              {/*      <p className="font-italic primary-color">*/}
              {/*        150 words or less*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*    <p className="text-gray" style={{ lineHeight: 1.5 }}>*/}
              {/*      {showcase.description}*/}
              {/*    </p>*/}
              {/*  </div>*/}
              {/*  <div className="d-flex">*/}
              {/*    <p*/}
              {/*      className="text-lg font-semi-bold"*/}
              {/*      style={{ minWidth: 250 }}*/}
              {/*    >*/}
              {/*      Favorite Styles:*/}
              {/*    </p>*/}
              {/*    <div className="d-flex flex-wrap align-items-center">*/}
              {/*      {showcase.styles.map((styleId) => (*/}
              {/*        <div key={styleId} className={showcaseClasses.styleChip}>*/}
              {/*          {styles[styleId].name}*/}
              {/*        </div>*/}
              {/*      ))}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="background-white p-5 mb-5">
                <p className={`${classes.title} mb-3`}>Featured room images</p>
                <div className="p-0">
                  {showcase.featuredImages.length > 0 ? (
                    <div>
                      {showcase.featuredImages.length > imagesToShow ? (
                        <div
                          className={
                            showcaseClasses.featuredImageSliderContainer
                          }
                        >
                          <IconButton
                            size="sm"
                            onClick={handleSlidePrev}
                            className={showcaseClasses.imagesPrevBtn}
                            disabled={activeSlideIndex === 0}
                          >
                            <SpriteIcon name="prev-icon" size="xs" />
                          </IconButton>
                          <Slider
                            className={showcaseClasses.featuredImageSlider}
                            ref={sliderRef}
                            arrows={false}
                            slidesToShow={imagesToShow}
                            slidesToScroll={1}
                            beforeChange={handleSlideChange}
                            infinite={false}
                          >
                            {showcase.featuredImages.map((imageId) => {
                              const imageMap = showcaseImagesMap;
                              return (
                                <div key={imageId} className="px-1">
                                  {imageMap[imageId] ? (
                                    <FeaturedImage
                                      media={imageMap[imageId]?.media}
                                      onOpenPreview={handleOpenPreviewModal}
                                      title={imageMap[imageId]?.title}
                                      sourceName={
                                        //  eslint-disable-next-line
                                        imageMap[imageId]?.image_source_name
                                      }
                                    />
                                  ) : (
                                    'Loading...'
                                  )}
                                </div>
                              );
                            })}
                          </Slider>
                          <IconButton
                            size="sm"
                            onClick={handleSlideNext}
                            className={showcaseClasses.imagesNextBtn}
                            disabled={
                              activeSlideIndex ===
                              showcase.featuredImages.length - 4
                            }
                          >
                            <SpriteIcon name="next-icon" size="xs" />
                          </IconButton>
                        </div>
                      ) : (
                        <div
                          className={showcaseClasses.featuredImagesContainer}
                        >
                          {showcase.featuredImages.length <= imagesToShow &&
                            showcase.featuredImages.map((imageId) => (
                              <div key={imageId} className="px-1">
                                {showcaseImagesMap[imageId] ? (
                                  <FeaturedImage
                                    media={showcaseImagesMap[imageId]?.media}
                                    onOpenPreview={handleOpenPreviewModal}
                                  />
                                ) : (
                                  'Loading...'
                                )}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="flex-center font-title text-gray text-lg"
                      style={{ height: 250 }}
                    >
                      List is Empty
                    </div>
                  )}
                </div>
              </div>
              <div className="background-white p-5 mb-5">
                <p className={`${classes.title} mb-3`}>Look Boards</p>
                <div
                  className={clsx(
                    showcaseClasses.featuredLookBoards,
                    'row mb-3 py-1'
                  )}
                >
                  {showcase.featuredLookBoards.length > 0 ? (
                    showcase.featuredLookBoards.map((lookBoardId) => (
                      <div key={lookBoardId} className="col-3 py-1">
                        {lookBoardsLibrary[lookBoardId] ? (
                          <LookBoardPreview
                            title={lookBoardsLibrary[lookBoardId].title}
                            color={lookBoardsLibrary[lookBoardId].color}
                            showLike={false}
                            products={lookBoardsLibrary[lookBoardId].products}
                            shortTitle
                            hoverOverlay={
                              <div
                                className={`${showcaseClasses.hoverOverlay}`}
                              >
                                <div className="flex-fill flex-center px-4">
                                  <p
                                    className="text-center cursor-pointer"
                                    data-id={lookBoardId}
                                    data-slug={
                                      lookBoardsLibrary[lookBoardId].slug
                                    }
                                    onClick={goToLookBoardDetails}
                                  >
                                    Click to view look board details
                                  </p>
                                </div>
                                <IconButton
                                  className={showcaseClasses.shareLookboard}
                                  data-id={lookBoardId}
                                  variant="inverted-grey"
                                  size="sm"
                                  onClick={handleOpenSharePopover}
                                >
                                  <SpriteIcon name="share" size="sm" />
                                </IconButton>
                              </div>
                            }
                          />
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <div
                      className="flex-center font-title text-gray text-lg mb-3  w-100"
                      style={{ height: 250 }}
                    >
                      No Featured Look Boards
                    </div>
                  )}
                </div>

                {showcase.lookBoards.length > 0 && (
                  <InfiniteScroll
                    hasMore={lookBoardsHasMore}
                    loadMore={handleLoadMoreLookBoards}
                    initialLoad={false}
                    threshold={1000}
                  >
                    <div className="row mb-3">
                      {showcase.lookBoards.map((lookBoardId) => (
                        <div key={lookBoardId} className="col-3 py-1">
                          {lookBoardsLibrary[lookBoardId] ? (
                            <LookBoardPreview
                              title={lookBoardsLibrary[lookBoardId].title}
                              color={lookBoardsLibrary[lookBoardId].color}
                              showLike={false}
                              products={lookBoardsLibrary[lookBoardId].products}
                              shortTitle
                              hoverOverlay={
                                <div
                                  className={`${showcaseClasses.hoverOverlay}`}
                                >
                                  <div className="flex-fill flex-center px-4">
                                    <p
                                      className="text-center cursor-pointer"
                                      data-id={lookBoardId}
                                      data-slug={
                                        lookBoardsLibrary[lookBoardId].slug
                                      }
                                      onClick={goToLookBoardDetails}
                                    >
                                      Click to view look board details
                                    </p>
                                  </div>

                                  <IconButton
                                    className={showcaseClasses.shareLookboard}
                                    data-id={lookBoardId}
                                    variant="inverted-grey"
                                    size="sm"
                                    onClick={handleOpenSharePopover}
                                  >
                                    <SpriteIcon name="share" size="sm" />
                                  </IconButton>
                                </div>
                              }
                            />
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <ImgPreviewModal
        open={imgModalOpen}
        onClose={handleImgModalClose}
        url={imgPreviewUrl}
      />
      <SharePopover
        anchorEl={shareAnchorEl}
        open={Boolean(shareAnchorEl)}
        onClose={handleCloseSharePopover}
        placement="bottom"
        shareUrl={urlForShare}
        isLookBoard
        lookBoardId={lookBoardsId}
      />
    </>
  );
};

export default ShowcasePreview;
