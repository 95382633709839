import React from 'react';
import { Rnd } from 'react-rnd';
import PropTypes from 'prop-types';
import SpriteIcon from 'components/ui/SpriteIcon';
import IconButton from 'components/ui/IconButton/IconButton';
import classes from './LookBoardDragModal.module.scss';

// eslint-disable-next-line
const LookBoardDragModal = ({ open, onClose, lookBoardData }) => {
  const defaultWidth = 700;
  return (
    open && (
      <Rnd
        style={{ zIndex: 9999, position: 'fixed' }}
        default={{
          x: 20,
          y: -120,
          width: defaultWidth,
        }}
        minWidth="300px"
        minHeight="300px"
        lockAspectRatio
      >
        <div className={classes.root}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeBtn}
          >
            <SpriteIcon name="cross" size="sm" />
          </IconButton>
          <img
            src={lookBoardData.lookBoard.lookBoardSocialImage}
            alt=""
            className={classes.img}
          />
          <SpriteIcon
            name="expand-icon"
            size="md"
            className={classes.resizeIcon}
          />
        </div>
      </Rnd>
    )
  );
};

LookBoardDragModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lookBoardData: PropTypes.shape({
    lookBoardProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    lookBoard: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      isLiked: PropTypes.number.isRequired,
      lookBoardSocialImage: PropTypes.string,
      lookBoardSocialImageFacebook: PropTypes.string,
      isVoted: PropTypes.bool,
      banner: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.object])
      ).isRequired,
      shareUrl: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

LookBoardDragModal.defaultProps = {
  lookBoardData: null,
};
export default LookBoardDragModal;
