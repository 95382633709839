import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import {
  ambassadorNavLinksConfig,
  navLinksConfig,
} from 'modules/dashboard/constants';
import useCurrentUserAvatarUrl from 'hooks/useCurrentUserAvatarUrl';
import BasicAvatar from 'components/ui/BasicAvatar';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/account/Account.module.scss';

const DashboardMobilePage = () => {
  const { firstName, lastName, email } = useSelector(
    (state) => state.auth.user
  );
  const isAmbassador = useMemo(() => Boolean(email), [email]);

  const avatarUrl = useCurrentUserAvatarUrl();
  return (
    <div className="py-3 px-2">
      <div className={classes.mobileCard}>
        <h2 className="text-lg font-title text-uppercase text-center pt-2 mb-2">
          {isAmbassador ? 'Ambassador' : 'User'}
        </h2>
        <div className="d-flex justify-content-center align-items-center mb-3">
          <BasicAvatar
            className={classes.avatar}
            firstName={firstName}
            lastName={lastName}
            src={avatarUrl}
          />
          <p className="text-sm font-semi-bold text-ellipsis text-nowrap">
            {email}
          </p>
        </div>
      </div>
      {isAmbassador &&
        ambassadorNavLinksConfig.map(({ icon, title }) => (
          <Link key={title} to={routesByName.unavailableTool}>
            <div className={`${classes.mobileCard} d-flex align-items-center`}>
              <div className={`${classes.mobileIconContainer} mr-2`}>
                <SpriteIcon name={icon} />
              </div>
              <p className="font-title">{title}</p>
            </div>
          </Link>
        ))}
      {navLinksConfig.map(({ icon, title }) => (
        <Link key={title} to={routesByName.unavailableTool}>
          <div className={`${classes.mobileCard} d-flex align-items-center`}>
            <div className={`${classes.mobileIconContainer} mr-2`}>
              <SpriteIcon name={icon} />
            </div>
            <p className="font-title">{title}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default DashboardMobilePage;
