import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import Button from 'components/ui/Button/Button';

const InactiveShowcaseModal = ({ open, onClose }) => {
  return (
    <BasicModal open={open} onClose={onClose} fullWidth>
      <p
        className="font-title text-lg text-uppercase text-center mb-3 mt-2"
        style={{ lineHeight: 1.4 }}
      >
        This showcase is deactivated. Only its owner can have access to it.
      </p>
      <div className="d-flex justify-content-center mb-1">
        <Button type="button" inline size="sm" onClick={onClose}>
          Go to the Get The Look
        </Button>
      </div>
    </BasicModal>
  );
};

InactiveShowcaseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InactiveShowcaseModal;
