import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import SupportForm from 'modules/support/supportForm/SupportForm';
import classes from './CustomerSupportModal.module.scss';

const CustomerSupportModal = ({ open, onClose, initialValues, onSubmit }) => (
  <BasicModal
    open={open}
    onClose={onClose}
    classes={{ root: classes.root }}
    fullWidth
    maxWidth="md"
  >
    <p className={classes.title}>Contact Customer Support</p>
    <SupportForm initialValues={initialValues} onSubmit={onSubmit} />
  </BasicModal>
);

CustomerSupportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomerSupportModal;
