import React, { useMemo } from 'react';
import classes from 'modules/report/Report.module.scss';
import ImageLink from 'components/imageThumbnails/ImageLink/ImageLink';
import buildResourceUrl from 'utils/buildResourceUrl';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import { useSelector } from 'react-redux';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';

const ReportImageContainer = ({ currentImage }) => {
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );

  const imgUrl = useMemo(() => {
    if (!currentImage) {
      return null;
    }
    const {
      media: { userId, hash },
    } = currentImage;
    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [currentImage, inspirationImageUrl]);

  return (
    <div className={classes.imageContainer}>
      {currentImage && (
        <>
          <div className={`${classes.selectedImage} mb-3`}>
            <img alt="" draggable="false" src={imgUrl} />
          </div>
          <div className="text-ellipsis mb-3">
            <ImageLink url={currentImage.url} />
          </div>
        </>
      )}
    </div>
  );
};

ReportImageContainer.propTypes = {
  currentImage: inspirationImageShape,
};

ReportImageContainer.defaultProps = {
  currentImage: null,
};

export default ReportImageContainer;
