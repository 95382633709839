import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Popover } from '@material-ui/core';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './ButtonPopover.module.scss';
import Button from '../Button/Button';

const sizeToClass = {
  xs: classes.xs,
  sm: classes.sm,
  md: classes.md,
  lg: classes.lg,
  xl: classes.xl,
};

const defaultIconProps = {
  variant: 'default',
  size: 'sm',
};

const ButtonPopover = ({
  size,
  fullWidth,
  children,
  buttonProps,
  buttonText,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Button {...defaultIconProps} {...buttonProps} onClick={handleOpen}>
        {buttonText}
      </Button>
      <BasicModal
        component={Popover}
        classes={{
          root: clsx(classes.popover, sizeToClass[size], {
            [classes.fullWidth]: fullWidth,
          }),
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children}
      </BasicModal>
    </>
  );
};

ButtonPopover.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(sizeToClass)),
  buttonProps: PropTypes.shape({}),
  buttonText: PropTypes.string.isRequired,
};

ButtonPopover.defaultProps = {
  fullWidth: false,
  size: 'xs',
  buttonProps: {
    ...defaultIconProps,
  },
};

export default ButtonPopover;
