import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ColorPoint from 'components/colorSelectors/ColorPoint/ColorPoint';
import colorsOptionShape from 'components/PreferenceQuiz/propTypes/colorsOptionShape';
import Button from 'components/ui/Button/Button';
import clsx from 'clsx';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import classes from '../../colorSelectors/ColorSelect.module.scss';

const ColorsStep = ({
  isLoading,
  currentValue,
  options,
  onCancelEdit,
  onSaveColors,
  colorGroups,
}) => {
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(currentValue)) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleChange = useCallback(
    (optionValue) => {
      let valueCopy = [...inputValue];
      const isOptionExist = inputValue.includes(optionValue);

      if (isOptionExist) {
        valueCopy = valueCopy.filter((item) => item !== optionValue);
      } else if (valueCopy.length < 5) {
        valueCopy.push(optionValue);
      }

      setInputValue(valueCopy);
    },
    [inputValue]
  );

  const handleSaveColors = useCallback(() => {
    onSaveColors(inputValue);
  }, [onSaveColors, inputValue]);

  return (
    <>
      <div className="d-flex flex-wrap">
        {Object.values(colorGroups).map((group) => {
          const colors = options.filter(
            (color) => color.color_group_id === group.id
          );
          if (!colors.length) return <></>;
          return (
            <div
              className={clsx(
                'd-flex flex-column align-items-center',
                classes.group
              )}
            >
              <FormControlLabel
                className="flex-column mx-0"
                control={
                  <h3 className="d-flex align-items-center mb-1">
                    <Checkbox
                      id={String(group.id)}
                      checked={inputValue.includes(group.id)}
                      onChange={() => handleChange(group.id)}
                      size="medium"
                    />
                    <span className={classes.title}>{group.name}</span>
                  </h3>
                }
                label={
                  <div className="d-flex">
                    {colors.map(({ id, code }, idx) => (
                      <ColorPoint
                        color={code}
                        id={id}
                        size="xl"
                        className={clsx(idx !== colors.length - 1 && 'mr-1')}
                      />
                    ))}
                  </div>
                }
              />
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-center">
        <Button
          inline
          className="mr-2"
          onClick={handleSaveColors}
          disabled={isLoading}
        >
          Submit
        </Button>
        {onCancelEdit && (
          <Button
            inline
            variant="outlined"
            onClick={onCancelEdit}
            disabled={isLoading}
          >
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};

ColorsStep.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number),
  options: PropTypes.arrayOf(colorsOptionShape).isRequired,
  colorGroups: PropTypes.shape({}).isRequired,
  onCancelEdit: PropTypes.func,
  onSaveColors: PropTypes.func.isRequired,
};

ColorsStep.defaultProps = {
  currentValue: undefined,
  onCancelEdit: undefined,
};

export default ColorsStep;
