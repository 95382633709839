import React from 'react';
import PropTypes from 'prop-types';
import SubStylesSliderForGrid from 'components/modals/FiltersModal/components/SubStylesSliderForGrid';

const SubStylesGrid = ({ options, currentValue, onChange }) => (
  <div style={{ minHeight: 500 }}>
    {options.length > 0 ? (
      options.map(({ id, name, subStyles }) => (
        <div key={id}>
          <p className="font-semi-bold mb-2">{name}</p>
          <SubStylesSliderForGrid
            currentValue={currentValue}
            subStyles={subStyles}
            onLike={onChange}
            styleName={name}
          />
        </div>
      ))
    ) : (
      <div
        className="flex-fill flex-center font-title text-gray text-lg text-center"
        style={{ height: 500, lineHeight: 1.4 }}
      >
        Any style selected.
        <br/>
        To see sub styles options
        <br/>
        please select some styles
      </div>
    )}
  </div>
);

SubStylesGrid.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentValue: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SubStylesGrid;
