import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routesByName } from 'constants/routes';
import Button from 'components/ui/Button/Button';
import PresentationCard from 'modules/home/components/PresentationCard/PresentationCard';
import modernRoom1Img from 'assets/img/home-page/detail-modal/modern-room-1.png';
import curateSectionImg from 'assets/img/home-page/how-it-works/curate.png';
import discoverModalImg from 'assets/img/home-page/discover-modal.png';
import modernRoomItemsImg from 'assets/img/home-page/detail-modal/modern-room-items.png';
import BasicModal from 'components/modals/BasicModal/BasicModal';
import classes from './GetStartedModal.module.scss';

const GetStartedModal = ({ open, onClose }) => (
  <BasicModal
    open={open}
    onClose={onClose}
    scroll="body"
    classes={{ root: classes.root }}
  >
    <div className="flex-column">
      <h1 className="flex-center font-semi-bold font-title">
        Get Started on Pin the Look
      </h1>
      <p className="flex-center primary-color my-2">
        Get the Look your way! You can browse curated looks, request the look,
        or curate the look. Let&apos;s go!
      </p>
    </div>

    <div className={classes.content}>
      <div className={classes.cardWrapper}>
        <PresentationCard
          title="Get the Look"
          description="Go to the Get the Look section to browse the look you love."
        >
          <div className={classes.cardContent}>
            <div className={classes.shadow}>
              <img className="w-100" src={discoverModalImg} alt="" />
            </div>
          </div>
        </PresentationCard>
        <div className={classes.btnContainer}>
          <Link to={routesByName.getTheLook.index}>
            <Button size="lg">Get the Look</Button>
          </Link>
        </div>
      </div>
      <div className={classes.cardWrapper}>
        <PresentationCard
          title="Request the Look"
          description="Quickly find the look of room and product images you love!"
        >
          <div className="text-center">
            <img
              src={modernRoom1Img}
              className={classes.modernRoomImg}
              alt=""
            />
            <div className="flex-center mt-2">
              <h4 className={classes.titleSm}>Look Board Recommendation</h4>
            </div>
            <div className={classes.shadow}>
              <img src={modernRoomItemsImg} alt="" />
            </div>
          </div>
        </PresentationCard>
        <div className="mt-3 d-flex justify-content-center">
          <Link to={routesByName.requestTheLook.index}>
            <Button size="lg">Request the Look</Button>
          </Link>
        </div>
      </div>
      <div className={classes.cardWrapper}>
        <PresentationCard
          title="Curate the Look"
          description="Use our easy drag & drop design tools to Curate the Look of your favorite room images"
        >
          <div className={classes.curateImg}>
            <img src={curateSectionImg} alt="" />
          </div>
        </PresentationCard>
        <div className="mt-3 d-flex justify-content-center">
          <Link to={routesByName.curateTheLook.index}>
            <Button size="lg">Curate the Look</Button>
          </Link>
        </div>
      </div>
    </div>
  </BasicModal>
);

GetStartedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GetStartedModal;
