import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from 'components/ui/IconButton/IconButton';
import SpriteIcon from 'components/ui/SpriteIcon';
import LookBoard from 'components/lookBoardThumbnails/LookBoard/LookBoard';
import { getInspirationImgUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import {
  changeLookBoardStatusAction,
  saveLookBoardAction,
} from 'modules/curateTheLook/store/actions';
import {
  lookBoardDetailsFormFields,
  lookBoardStatusRadioOptions,
} from 'modules/curateTheLook/constants';
import classes from 'modules/curateTheLook/lookBoardDetails/LookBoardDetails.module.scss';
import successToastr from 'libs/toastr/successToastr';
import errorToastr from 'libs/toastr/errorToastr';
import { routesByName } from 'constants/routes';
import LookBoardInfo from 'modules/curateTheLook/components/LookBoardInfo/LookBoardInfo';

const SubmitLookBoardContainer = ({ onRequestDetailsPreview }) => {
  const dispatch = useDispatch();

  const { colors, roomTypes, itemClasses, styles } = useSelector(
    (state) => state.app.enums
  );
  const inspirationImageUrl = getInspirationImgUrl(
    useSelector((state) => state.app.config)
  );
  const products = useSelector((state) => state.product.library);
  const curateTheLook = useSelector((state) => state.curateTheLook);
  const inspirationImageLibrary = useSelector(
    (state) => state.inspirationImage.library
  );
  const selectedImage = useMemo(
    () =>
      inspirationImageLibrary[curateTheLook.lookBoardData.inspirationImageId],
    [curateTheLook.lookBoardData.inspirationImageId, inspirationImageLibrary]
  );
  const requestTitle = useMemo(
    () => curateTheLook?.requestInfo?.title ?? null,
    [curateTheLook]
  );

  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    lookBoardDetailsFormFields.description
  );

  const imgUrl = useMemo(() => {
    if (!selectedImage) {
      return null;
    }
    const {
      media: { userId, hash },
    } = selectedImage;
    return buildResourceUrl(inspirationImageUrl.medium, userId, hash);
  }, [inspirationImageUrl, selectedImage]);

  const productList = useMemo(() => {
    if (!curateTheLook.lookBoardData || !products) {
      return [];
    }
    return curateTheLook.lookBoardData.products.map((id) => products[id]);
  }, [curateTheLook.lookBoardData, products]);

  const colorList = useMemo(
    () => (selectedImage ? selectedImage.colors.map((id) => colors[id]) : []),
    [selectedImage, colors]
  );

  const goBack = useCallback(() => {
    history.push(routesByName.curateTheLook.details);
  }, [history]);

  const handleToggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const handleEditLookBoard = useCallback(() => {
    history.push(routesByName.curateTheLook.canvas);
  }, [history]);

  const handleChangeStatus = useCallback(
    ({ target: { value: status } }) => {
      dispatch(changeLookBoardStatusAction(status));
    },
    [dispatch]
  );

  const handleChangeTab = useCallback(
    ({ currentTarget }) => {
      const { tab } = currentTarget.dataset;
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
    },
    [activeTab]
  );

  const handleEditDetails = useCallback(() => {
    history.push(routesByName.curateTheLook.details);
  }, [history]);

  const handleSubmitLookBoard = useCallback(async () => {
    setSubmitLoading(true);
    try {
      await dispatch(saveLookBoardAction());
      history.push(routesByName.curateTheLook.success);
      successToastr('Success', 'Look Board Successfully submitted');
    } catch (e) {
      errorToastr('Error', e.generalError);
    }
    setSubmitLoading(false);
  }, [dispatch, history]);

  return curateTheLook.lookBoardDetailsFormValues ? (
    <div className={classes.root}>
      <div className="d-flex align-items-center mb-3">
        <IconButton variant="inverted-white" size="sm" onClick={goBack}>
          <SpriteIcon name="left-arrow" size="sm" />
        </IconButton>
        <span className="primary-color text-sm ml-1">Back</span>
      </div>
      <h3 className={classes.sectionTitle}>Review & Submit Look Board</h3>
      <div className="d-flex">
        <div className={`${classes.lookBoardSection} mr-4`}>
          <LookBoard
            title={curateTheLook.lookBoardData.title}
            color={curateTheLook.lookBoardData.color}
            columns={curateTheLook.lookBoardData.columns}
            items={productList}
            collapsed={collapsed}
            onToggleCollapse={handleToggleCollapse}
            onEdit={handleEditLookBoard}
          />
        </div>
        <div className="flex-fill">
          <LookBoardInfo
            selectedImage={selectedImage}
            lookBoardDetailsFormValues={
              curateTheLook.lookBoardDetailsFormValues
            }
            imgUrl={imgUrl}
            roomTypes={roomTypes}
            itemClasses={itemClasses}
            styles={styles}
            colorList={colorList}
            productList={productList}
            lookBoardStatus={curateTheLook.lookBoardStatus}
            onChangeStatus={handleChangeStatus}
            onSubmitLookBoard={handleSubmitLookBoard}
            onEditDetails={handleEditDetails}
            radioOptions={lookBoardStatusRadioOptions}
            requestTitle={requestTitle}
            requestId={curateTheLook.lookBoardData.requestId}
            onDetailsPreview={onRequestDetailsPreview}
            loading={submitLoading}
            activeTab={activeTab}
            onChangeTab={handleChangeTab}
          />
        </div>
      </div>
    </div>
  ) : null;
};

SubmitLookBoardContainer.propTypes = {
  onRequestDetailsPreview: PropTypes.func.isRequired,
};

export default SubmitLookBoardContainer;
