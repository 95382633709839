import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import BasicAvatar from 'components/ui/BasicAvatar';
import { getUserAvatarUrl } from 'modules/app/store/selectors';
import buildResourceUrl from 'utils/buildResourceUrl';
import classes from './Message.module.scss';

const Message = ({ data: { body, sentAt, userId } }) => {
  const currentUser = useSelector((state) => state.auth.user);
  const usersLibrary = useSelector((state) => state.users.library);
  const userAvatarUrl = getUserAvatarUrl(
    useSelector((state) => state.app.config)
  );

  const user = useMemo(
    () => (userId === currentUser.id ? currentUser : usersLibrary[userId]),
    [currentUser, usersLibrary, userId]
  );
  const avatarUrl = useMemo(
    () =>
      user.avatar &&
      buildResourceUrl(userAvatarUrl, user.avatar.userId, user.avatar.hash),
    [userAvatarUrl, user]
  );

  const interactionDate = useMemo(() => moment(sentAt).format('hh:mm a'), [
    sentAt,
  ]);

  const preparedMessageBody = useMemo(() => {
    const re = /(https?:\/\/[^\s]+)/g;
    const copy = body;
    const parts = copy.split(re);

    for (let i = 1; i < copy.length; i += 2) {
      if (parts[i]) {
        parts[i] = (
          <a key={`link${i}`} href={parts[i]}>
            {parts[i]}
          </a>
        );
      }
    }
    return parts;
  }, [body]);

  return (
    <div className="px-3">
      <div className="d-flex align-items-center mb-1">
        <BasicAvatar
          className={classes.avatar}
          firstName={user.firstName}
          lastName={user.lastName}
          src={avatarUrl}
        />
        <div className="d-flex flex-column">
          <span className={`${classes.fullName} text-ellipsis`}>
            {user.displayName ?? `${user.firstName} ${user.lastName}`}
          </span>
          <span className={classes.date}>{interactionDate}</span>
        </div>
      </div>
      <p className={classes.messageText}>{preparedMessageBody}</p>
    </div>
  );
};

Message.propTypes = {
  data: PropTypes.shape({
    body: PropTypes.string,
    conversationId: PropTypes.number,
    id: PropTypes.number,
    sentAt: PropTypes.number,
    userId: PropTypes.number,
  }).isRequired,
};

export default Message;
