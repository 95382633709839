import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { routesByName } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import { inspirationImageShape } from 'modules/inspirationImage/propTypes';
import SpriteIcon from 'components/ui/SpriteIcon';
import classes from 'modules/requestTheLook/createRequest/CreateRequest.module.scss';
import IconButton from 'components/ui/IconButton/IconButton';
import Button from 'components/ui/Button/Button';
import { updateRequestDataAction } from '../../store/actions';

const RequestType = ({
  type,
  title,
  description,
  imgUrl,
  selectedImage,
  onOpenModal,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const history = useHistory();
  const handleRequestNext = () => {
    if (!user) {
      const redirectUrl = `?${routesByName.auth.key}=${routesByName.auth.signUp}`;
      history.push(redirectUrl);
      return;
    }
    dispatch(updateRequestDataAction({ requestType: type }));
    history.push(routesByName.requestTheLook.details);
  };

  return (
    <div
      className={clsx(classes.requestType, 'mb-2', {
        [classes.active]: !!selectedImage,
        [classes.selected]: true,
      })}
    >
      <img src={imgUrl} alt="" />
      <div className={classes.requestTypeContent}>
        <h4 className={classes.requestTypeTitle}>
          <span className={classes.requestTypeTitleCircle}>
            <SpriteIcon name="correct" className="primary-color" />
          </span>
          {title}
        </h4>
        <p className={classes.requestTypeDescription}>{description}</p>
        {!!selectedImage && (
          <Button
            inline
            size="sm"
            className={classes.requestTypeBtn}
            onClick={handleRequestNext}
          >
            Next
          </Button>
        )}
        <IconButton
          color="grey"
          size="xs"
          onClick={onOpenModal}
          className={classes.infoBtn}
        >
          <SpriteIcon name="info" size="sm" />
        </IconButton>
      </div>
    </div>
  );
};

RequestType.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  selectedImage: inspirationImageShape,
  onOpenModal: PropTypes.func.isRequired,
};

RequestType.defaultProps = {
  selectedImage: null,
};

export default RequestType;
