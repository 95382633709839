import React, { useCallback, useState } from 'react';
import debounce from 'lodash-es/debounce';
import PropTypes from 'prop-types';
import classes from './RequestMessage.module.scss';

const RequestMessage = ({ onChange, placeholder, messageText }) => {
  const [inputText, setInputText] = useState(messageText);

  const triggerOnChange = useCallback(
    debounce((message) => onChange(message), 500),
    [onChange]
  );

  const onInputChange = useCallback(
    ({ target: { value } }) => {
      setInputText(value);
      triggerOnChange(value);
    },
    [triggerOnChange]
  );
  return (
    <div className={classes.root}>
      <textarea
        rows={5}
        value={inputText}
        onChange={onInputChange}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};
RequestMessage.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  messageText: PropTypes.string.isRequired,
};

export default RequestMessage;
