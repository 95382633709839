import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import becomeAmbassadorClasses from 'modules/ambassadors/getStarted/GetStarted.module.scss';
import classes from 'components/UnavailableToolMobilePage/UnavailableToolMobilePage.module.scss';
import roomsImg from 'assets/img/about-us-page/rooms.png';
import womanImg from 'assets/img/ambassador-page/woman.png';
import rectangleImg from 'assets/img/ambassador-page/rectangle.png';
import Button from 'components/ui/Button/Button';
import { routesByName } from 'constants/routes';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import RequestModal from './components/RequestModal/RequestModal';
import ambassadorService from './ambassadorService';
import errorToastr from '../../libs/toastr/errorToastr';
import RequestSentModal from './components/RequestSentModal/RequestSentModal';

const AmbassadorMobilePage = () => {
  const user = useSelector((state) => state.auth.user);

  const { pathname } = useLocation();

  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestSentModalOpen, setRequestSentModalOpen] = useState(false);

  const handleOpenRequestModal = useCallback(() => {
    // if (!hasVerifiedEmail) {
    //   toggleVerificationModal(true);
    //   return;
    // }
    setRequestModalOpen(true);
  }, []);

  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);

  const handleSendRequest = useCallback(async (values) => {
    try {
      await ambassadorService.requestAccess(values);
      setRequestModalOpen(false);
      setRequestSentModalOpen(true);
    } catch (e) {
      errorToastr('Error', e.message);
    }
  }, []);

  const handleCloseRequestSentModal = useCallback(() => {
    setRequestSentModalOpen(false);
  }, []);

  const isAmbassador = useMemo(() => Boolean(user?.ambassador), [user]);
  const authenticated = useMemo(() => Boolean(user), [user]);

  return (
    <>
      <div className="px-2 py-2" style={{ lineHeight: 1.3 }}>
        <h1 className={classes.ambassadorTitle}>Become an Ambassador</h1>
        <p className={`${classes.ambassadorSubtitle} mb-3`}>
          Help Others Get the Look
        </p>
        <div className="row px-2 mb-5">
          <img className={classes.interiorImg} src={roomsImg} alt="roomsImg" />
        </div>
        <div className={`${becomeAmbassadorClasses.banner} mb-2`}>
          <img
            className={clsx('position-absolute', classes.backgroundRectangle)}
            src={rectangleImg}
            alt=""
          />
          <div className={becomeAmbassadorClasses.bannerShape} />
          <div className="position-absolute top-0 left-0 w-100 h-100 z2 px-1">
            <h2 className={becomeAmbassadorClasses.title}>
              What ambassadors do
            </h2>
            <ul className={`${becomeAmbassadorClasses.list} text-sm`}>
              <li>Pin the World’s Most Inspiring Images</li>
              <li>Showcase Unique Ways to Get the Look</li>
              <li>Curate Look Board Requests</li>
              <li>Share Inspirations on Social Media</li>
            </ul>
          </div>
          <div
            className={clsx(
              'position-absolute w-100 h-100 z2 px-1',
              classes.mobileSecondText
            )}
          >
            <h2 className={becomeAmbassadorClasses.title}>
              WHAT AMBASSADORS GET
            </h2>
            <ul className={`${becomeAmbassadorClasses.list} text-sm`}>
              <li>Community Membership</li>
              <li>Showcase Page on Pin the Look</li>
              <li>Featured Content on Social Media</li>
              <li>Reward Points Redeemable for Gift Cards</li>
              <li>Exclusive Access to our Affiliate Programs</li>
            </ul>
          </div>
          <img style={{ width: '45%' }} src={womanImg} alt="" />
        </div>
        {!isAmbassador && (
          <>
            <div className="d-flex mb-2">
              {authenticated ? (
                <Button
                  onClick={handleOpenRequestModal}
                  inline
                  className="w-100"
                  style={{ padding: '8px 16px' }}
                  color="secondary"
                >
                  Apply to become an Ambassador
                </Button>
              ) : (
                <Link
                  className="w-100"
                  to={`${pathname}?${routesByName.auth.key}=${routesByName.auth.signIn}`}
                >
                  <Button
                    className="w-100"
                    inline
                    style={{ padding: '8px 16px' }}
                    color="secondary"
                  >
                    Apply to become an Ambassador
                  </Button>
                </Link>
              )}
            </div>
            <div className="d-flex">
              <Link className="w-100" to={routesByName.getTheLook.index}>
                <Button
                  className="w-100"
                  inline
                  style={{ padding: '8px 16px' }}
                >
                  View Look Boards
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>

      <RequestModal
        open={requestModalOpen}
        onClose={handleCloseRequestModal}
        onSubmit={handleSendRequest}
      />
      <RequestSentModal
        open={requestSentModalOpen}
        onClose={handleCloseRequestSentModal}
      />
    </>
  );
};

export default AmbassadorMobilePage;
